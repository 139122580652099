import { store } from '../store/Store';
import { sendMessageToApp, ValidMessages } from './MessageHelper';

export const openPdf = (pdf: string) => {
  const { isExternal } = store.getState().token;
  if (isExternal) {
    window.open(pdf, '_blank');
  } else {
    sendMessageToApp(ValidMessages.pdfFromURL, pdf);
  }
};

import { RentServiceState } from '../state/RentServiceState';
import { RentServiceAction, RentServiceActionTypes } from '../actions/RentServiceActions';
import { GetRentServiceResponse } from '../../services/rentService/getRentService/GetRentServiceResponse.data';
import { PostRentServiceResponse } from '../../services/rentService/postRentService/PostRentService.data';

const rentServiceInitialState: RentServiceState = {
  service: [],
  postRentServiceResponse: undefined,
  loading: false,
  error: undefined,
  postServiceabilityResponse: undefined,
  propertyId: undefined,
};

// tslint:disable-next-line:cyclomatic-complexity
export function rentServiceReducer(
  state = rentServiceInitialState,
  action: RentServiceAction,
): RentServiceState {
  switch (action.type) {
    case RentServiceActionTypes.GetRentServiceStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case RentServiceActionTypes.GetRentServiceSuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        service: action.data as GetRentServiceResponse[],
      };
    case RentServiceActionTypes.GetRentServiceError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    case RentServiceActionTypes.PostRentServiceStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case RentServiceActionTypes.PostRentServiceSuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        postRentServiceResponse: action.data as PostRentServiceResponse,
      };
    case RentServiceActionTypes.PostRentServiceError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    case RentServiceActionTypes.PostServiceabilityStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case RentServiceActionTypes.PostServiceabilitySuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        postServiceabilityResponse: action.data as any,
      };
    case RentServiceActionTypes.PostServiceabilityError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    case RentServiceActionTypes.SavePropertyId:
      return {
        ...state,
        propertyId: action.data as number,
      };
    default:
      return state;
  }
}

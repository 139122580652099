import { all } from 'redux-saga/effects';
import leaseWatcher from './store/sagas/LeaseSaga';
import paymentWatcher from './store/sagas/PaymentSaga';
import summaryWatcher from './store/sagas/SummarySaga';
import planWatcher from './store/sagas/PlanSaga';
import electricityServiceWatcher from './store/sagas/ElectricityServiceSaga';
import commonServiceWatcher from './store/sagas/CommonServiceSaga';
import broadbandServiceWatcher from './store/sagas/BroadbandServiceSaga';
import rentServiceWatcher from './store/sagas/RentServiceSaga';
import walletWatcher from './store/sagas/WalletSaga';
import editPaymentWatcher from './store/sagas/EditPaymentSaga';
import leaveListWatcher from './store/sagas/LeaveListSaga';
import planSummaryWatcher from './store/sagas/PlanSummarySaga';
import propertyWatcher from './store/sagas/PropertyOnboardingSaga';
import checkoutWatcher from './store/sagas/CheckoutSaga';
import tokenWatcher from './store/sagas/TokenSaga';
import fileUploadWatcher from './store/sagas/FileUploadSaga';

export default function* sagas() {
  yield all([
    // add sagas/watchers
    leaseWatcher(),
    paymentWatcher(),
    summaryWatcher(),
    planWatcher(),
    commonServiceWatcher(),
    electricityServiceWatcher(),
    broadbandServiceWatcher(),
    rentServiceWatcher(),
    walletWatcher(),
    editPaymentWatcher(),
    leaveListWatcher(),
    planSummaryWatcher(),
    propertyWatcher(),
    checkoutWatcher(),
    tokenWatcher(),
    fileUploadWatcher(),
  ]);
}

import * as React from 'react';
import { Snackbar, Typography, withStyles } from '@material-ui/core';
import errorIcon from '../../assets/error-outline-24-px.svg';
import doneIcon from '../../assets/done.svg';

import { SnackbarComponentStyles, Styles } from './SnackbarComponentStyles';

interface PropsFromParent {
  message: string;
  type?: 'error' | 'success';
}

type OwnProps = PropsFromParent & SnackbarComponentStyles;

function SnackbarComponent(props: OwnProps) {
  const [state, setState] = React.useState({
    open: true,
  });
  const { open } = state;
  const { classes, type } = props;
  function handleClose() {
    setState({ open: false });
  }
  return (
    <Snackbar
      open={open}
      className={classes.root}
      onClose={handleClose}
      message={
        <div className={classes.messageBoxStyle}>
          <img src={type === 'success' ? doneIcon : errorIcon} className={classes.iconStyle} />
          <Typography variant="body2" className={classes.textStyle}>
            {props.message}
          </Typography>
        </div>
      }
      autoHideDuration={4000}
    />
  );
}

export default withStyles(Styles)(SnackbarComponent);

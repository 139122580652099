import { color } from '../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  root: {
    'paddingTop': '48px',
    'backgroundColor': color.secondary,
    'height': '100%',
    'boxSizing': 'border-box',
    'scrollbarWidth': 'none',
    'msOverflowStyle': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  sectionContainer: {
    marginTop: 15,
    backgroundColor: color.light,
    padding: 15,
    borderRadius: 8,
  },
  sectionTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
  },
  sectionTitle: {
    marginRight: 'auto',
    fontFamily: 'GT Walsheim',
    fontSize: '20px',
    color: color.black87,
  },
  sectionIconContainer: {
    width: 22,
    height: 22,
  },
  sectionIcon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  scrollContainer: {
    paddingBottom: '90px',
  },
});

export interface CheckoutFinalizeStyles extends WithStyles<typeof styles> {}

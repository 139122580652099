import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { GetRentServiceResponse } from '../services/rentService/getRentService/GetRentServiceResponse.data';
import { ApplicationState } from '../store/RootReducers';

export const useGetLeaseData = (): GetRentServiceResponse | undefined => {
  const rentServiceReducer = useSelector((state: ApplicationState) => state.rentService);
  const { propertyId, service } = rentServiceReducer;
  let lease = useMemo<GetRentServiceResponse | undefined>(() => {
    if (!propertyId && service && service.length > 0) {
      return service[0];
    } else if (propertyId && service && service.length > 0) {
      return service.findIndex((serviceObj) => serviceObj.property!.id === propertyId) !== -1
        ? service.find((serviceObj) => serviceObj.property!.id === propertyId)!
        : service[0];
    } else {
      return undefined;
    }
  }, [propertyId, service]);
  return lease;
};

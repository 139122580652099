import React, { FC } from 'react';
import {
  withStyles,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  Grid,
  Select,
} from '@material-ui/core';
import { ValidationSchema } from './ManualEntryConstants';
import { Formik } from 'formik';
import { styles, ManualEntryStyles } from './ManualEntryStyles';
import {
  AddressComponents,
  OnboardingPropertyDetails,
  SetOnboardingPropertyDetails,
} from '../../../../models/property/Property.data';
import { History } from 'history';
import { StateList, StreetTypeList } from '../../PropertyOnboardingConstants';

interface ManualEntryProps extends ManualEntryStyles {
  history: History;
  propertyOnboardingState: OnboardingPropertyDetails;
  setPropertyDetails: (data: SetOnboardingPropertyDetails) => void;
  goToNextPage: () => void;
}

const ManualEntry: FC<ManualEntryProps> = ({
  classes,
  history,
  propertyOnboardingState: { address },
  setPropertyDetails,
  goToNextPage,
}) => {
  const { country, streetNumber, suburb, streetType, streetName, unitNumber, postcode, state } =
    address;

  const initialValues: AddressComponents = {
    unitNumber: unitNumber || '',
    streetNumber: streetNumber || '',
    streetName: streetName || '',
    streetType: streetType || '',
    suburb: suburb || '',
    postcode: postcode || '',
    state: state || '',
    country: country || 'Australia',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        setPropertyDetails({ field: 'address', value: values });
        goToNextPage();
      }}
      validationSchema={ValidationSchema}
      render={({ values, setFieldValue, handleSubmit, errors, touched }) => (
        <div className={classes.inputContainer}>
          <Grid container>
            <Grid item xs={6}>
              <FormControl className={classes.inputGrid}>
                <InputLabel>Unit No</InputLabel>
                <Input
                  name="unitNumber"
                  type="text"
                  onChange={(event) => setFieldValue('unitNumber', event.target.value)}
                  value={values.unitNumber}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                className={classes.inputGrid}
                error={!!errors.streetNumber && !!touched.streetNumber}
              >
                <InputLabel>Street No</InputLabel>
                <Input
                  name="streetNumber"
                  type="text"
                  onChange={(event) => setFieldValue('streetNumber', event.target.value)}
                  value={values.streetNumber}
                />
              </FormControl>
            </Grid>
          </Grid>
          <FormControl
            error={!!errors.streetName && !!touched.streetName}
            className={classes.input}
          >
            <InputLabel>Street Name</InputLabel>
            <Input
              name="streetName"
              type="text"
              onChange={(event) => setFieldValue('streetName', event.target.value)}
              value={values.streetName}
            />
          </FormControl>
          <FormControl
            error={!!errors.streetType && !!touched.streetType}
            className={classes.input}
          >
            <InputLabel>Street Type</InputLabel>
            <Select
              name="streetType"
              onChange={(event) => setFieldValue('streetType', event.target.value)}
              value={values.streetType}
            >
              {StreetTypeList.map((t) => (
                <MenuItem value={t.abbreviation}>{t.street_type}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl error={!!errors.suburb && !!touched.suburb} className={classes.input}>
            <InputLabel>Suburb</InputLabel>
            <Input
              name="suburb"
              type="text"
              onChange={(event) => setFieldValue('suburb', event.target.value)}
              value={values.suburb}
            />
          </FormControl>
          <Grid container>
            <Grid item xs={6}>
              <FormControl
                error={!!errors.postcode && !!touched.postcode}
                className={classes.inputGrid}
              >
                <InputLabel>Post Code</InputLabel>
                <Input
                  name="postcode"
                  type="text"
                  onChange={(event) => setFieldValue('postcode', event.target.value)}
                  value={values.postcode}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl error={!!errors.state && !!touched.state} className={classes.inputGrid}>
                <InputLabel>State</InputLabel>
                <Select
                  name="streetType"
                  onChange={(event) => setFieldValue('state', event.target.value)}
                  value={values.state}
                >
                  {StateList.map((t) => (
                    <MenuItem value={t}>{t}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <FormControl error={!!errors.country} className={classes.input}>
            <InputLabel>Country</InputLabel>
            <Input
              name="country"
              disabled={true}
              type="text"
              onChange={(event) => setFieldValue('country', event.target.value)}
              value={values.country}
            />
          </FormControl>
          <div className={classes.footerButtonsContainer}>
            <div className={classes.footerButton} onClick={() => handleSubmit()}>
              Next
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default withStyles(styles)(ManualEntry);

import { createStyles, WithStyles } from '@material-ui/core';
import { color } from '../../../../theme/Theme';

export const Styles = createStyles({
  cardType: {
    margin: '20px 0px',
  },
  cardToggleContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    height: 40,
    borderRadius: 2,
    margin: '10px 0px',
    boxShadow: 'none',
    borderRight: `1px solid ${color.lightBlue}`,
  },
  toggleButton: {
    flex: 1,
    color: color.lightBlue,
    borderTop: `1px solid ${color.lightBlue}`,
    borderBottom: `1px solid ${color.lightBlue}`,
    borderLeft: `1px solid ${color.lightBlue}`,
    fontSize: 15,
    height: '100%',
  },
  toggleButtonActive: {
    flex: 1,
    background: `${color.lightBlue} !important`,
    color: `#fff !important`,
    height: '100%',
  },
});

export interface ConcessionCardDetailStyles extends WithStyles<typeof Styles> {}

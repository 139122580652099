import { createStyles, WithStyles } from '@material-ui/core';
import { color } from '../../../../theme/Theme';

export const Styles = createStyles({
  root: {
    padding: '16px 16px 50px',
    marginTop: 48,
  },
  formRoot: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  formBox: {
    display: 'flex',
    flexDirection: 'row',
  },
  selectFormControlStyle: {
    display: 'flex',
    flex: 1,
    marginTop: 16,
    marginBottom: 8,
  },
  inputLabelStyle: {
    marginLeft: 16,
    color: color.primary,
    fontWeight: 'lighter',
    fontSize: '16px',
  },
  noInputLabelStyle: {
    marginLeft: 16,
    color: color.secondary300,
    fontWeight: 'lighter',
    fontSize: '16px',
  },
  selectStyle: { paddingBottom: 8, paddingLeft: 16 },
  inputFormControlStyle: {
    display: 'flex',
    flex: 1,
    marginTop: 16,
    marginBottom: 16,
  },
  inputStyle: { paddingBottom: 8, paddingLeft: 16 },
  leftBoxStyle: {
    marginRight: 8,
  },
  rightBoxStyle: {
    marginLeft: 8,
  },
  error: {
    color: '#ff525b',
  },
  errorMessage: {
    color: '#ff525b',
    fontSize: 13,
    paddingLeft: 16,
    marginTop: 3,
    fontfamily: 'GTWalsheim',
    fontWeight: 500,
    lineHeight: '1.18',
    letterSpacing: '0.4px',
  },
});

export interface AddressFormStyles extends WithStyles<typeof Styles> {}

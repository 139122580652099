import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { BroadbandPlan } from '../../models/plan/broadband/getPlanDetails/BroadbandPlan.data';
import { BroadBandTypeStyle, Styles } from './BroadBandTypeStyles';
import locationIcon from '../../assets/location.png';
import sorryIcon from '../../assets/wifi-off.svg';
import serviceAvailableIcon from '../../assets/internet.svg';
import serviceUnavailableIcon from '../../assets/light-internet.svg';
import { Typography, Card, Button } from '@material-ui/core';
import * as labels from './BroadBandTypeConstants';
import Footer from '../footer/FooterComponent';
import { History } from 'history';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';

interface PropsFromParent {
  type: BroadbandPlan;
  handleNext: (choice: string) => void;
  handleBackHome: () => void;
  handleEdit: () => void;
}
interface PropsFormRoute {
  history?: History;
}
type OwnProps = BroadBandTypeStyle & PropsFromParent & PropsFormRoute;
class BroadBandType extends React.Component<OwnProps> {
  public render() {
    const { classes, type } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <img
            src={type.NBN !== null || type.ADSL !== null ? locationIcon : sorryIcon}
            className={classes.mainIcon}
          />
          <Typography variant="h5" className={classes.mainHeading}>
            {type.NBN !== null || type.ADSL !== null ? 'Property located!' : 'Sorry!'}
          </Typography>
          <Typography variant="h5" className={classes.subheading}>
            {type.NBN !== null || type.ADSL !== null
              ? 'Great news! We found your place and can get broadband sorted for you'
              : 'Unfortunately Broadband is not yet available at your address.'}
          </Typography>
        </div>
        <div>{this.renderCardList(type)}</div>
        {this.renderBottom(type)}
      </div>
    );
  }

  private renderCardList(type: BroadbandPlan) {
    const { classes } = this.props;
    if (type.NBN === null && type.ADSL === null) {
      return (
        <div className={classes.cardList}>
          <Card
            className={classes.cardBody}
            onClick={() => {
              this.props.handleEdit();
            }}
          >
            <img className={classes.cardIcon} src={locationIcon} />
            <Typography className={classes.text}>{labels.noService}</Typography>
            <Typography className={classes.text}>{labels.edit}</Typography>
          </Card>
        </div>
      );
    } else if (type.NBN !== null) {
      return (
        <div className={classes.cardList}>
          {this.renderCard(labels.nbnService, serviceAvailableIcon, labels.available)}
        </div>
      );
    } else {
      return (
        <div className={classes.cardList}>
          {this.renderCard(labels.adslService, serviceAvailableIcon, labels.available)}
          {this.renderCard(labels.nbnService, serviceUnavailableIcon, labels.unavailable)}
        </div>
      );
    }
  }
  private renderCard(cardTitle: string, icon: string, subString: string) {
    const { classes } = this.props;
    return (
      <Card className={classes.cardBody}>
        <img className={classes.cardIcon} src={icon} />
        <Typography
          className={subString === labels.unavailable ? classes.unavailableText : classes.text}
        >
          {cardTitle}
        </Typography>
        <Typography
          className={
            subString === labels.unavailable
              ? classes.redText
              : subString === labels.available
              ? classes.blueText
              : classes.text
          }
        >
          {subString}
        </Typography>
      </Card>
    );
  }
  private renderBottom(type: BroadbandPlan) {
    const { classes } = this.props;
    if (type.NBN !== null || type.ADSL !== null) {
      let choice: string;
      if (type.NBN !== null) {
        choice = 'NBN';
      } else {
        choice = 'ADSL';
      }
      return <Footer buttonText={labels.next} onClick={() => this.props.handleNext(choice)} />;
    } else {
      return (
        <Footer
          buttonText={labels.backButton}
          onClick={() => {
            this.props.handleBackHome();
          }}
        >
          <Button className={classes.button} onClick={this.startChat}>
            <Typography className={classes.buttonText}>{labels.chatButton}</Typography>
          </Button>
        </Footer>
      );
    }
  }
  private startChat = () => {
    sendMessageToApp(ValidMessages.Chat);
  };
}
export default withStyles(Styles)(BroadBandType);

import React, { FC, useEffect, useState, useMemo } from 'react';
import { withStyles } from '@material-ui/core';
import { styles, AddressHeaderStyles } from './AddressHeaderStyles';
import { LABELS } from './AddressHeaderConstants';
import MainContainer from '../mainContainer/MainContainer';

interface AddressHeaderProps extends AddressHeaderStyles {
  address?: string;
  disabled?: boolean;
  loading?: boolean;
}

const AddressHeader: FC<AddressHeaderProps> = ({ classes, address, disabled, loading }) => {
  const addressString = address || '';
  const addressEllipsis =
    addressString.length > 23 ? `${addressString.substring(0, 23)}...` : addressString;
  return (
    <div className={classes.addressContainer}>
      <MainContainer width100>
        <div className={classes.addressWrapper}>
          <div className={classes.addressTitle}>{LABELS.ADDRESS}</div>
          <div className={classes.addressBar}>
            <div className={`${classes.addressText} ${classes.disabled}`}>
              {loading ? 'Loading your property' : addressString}
            </div>
          </div>
        </div>
      </MainContainer>
    </div>
  );
};

export default withStyles(styles)(AddressHeader);

import * as React from 'react';
import TermsAndConditionsComponent from '../../../../component/services/termsAndConditions/TermsAndConditions';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/RootReducers';
import {
  BroadbandServiceDetails,
  BroadbandPlanSubmit,
  Modem,
  ServiceDetails,
  ProductDetails,
} from '../../../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import {
  PersonalDetails,
  ServiceAccount,
} from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { BroadbandServiceActions } from '../../../../store/actions/BroadbandServiceActions';
import { AnyAction } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import { History } from 'history';
import { routes } from '../../../../Routes';
import { ServiceType, getContractInfo, getNoContractInfo } from '../../ServiceConstants';
import { BackHelper } from '../../../../helper/BackHelper';
import { PlanSummaryActions } from '../../../../store/actions/PlanSummaryActions';
interface OwnState {
  termsDrawer: boolean;
  planDetailsDrawer: boolean;
  personalDetailsDrawer: boolean;
}

interface PropsFromState {
  service: BroadbandServiceDetails;
  accessToken: string | boolean | null;
  serviceAccountId: number | undefined;
}

interface PropsFromRoute {
  history: History;
}

interface PropsFromDispatch {
  postTermsAndConditions: (
    propertyId: string,
    serviceAccountId: string,
    data: BroadbandServiceDetails,
    onSuccess: () => void,
  ) => void;
  getBroadbandService: (propertyId: string) => void;
  getPlanSummary: (
    serviceAccountId: string,
    serviceSummary: string,
    onSuccess: (v1: boolean) => void,
    onError: () => void,
  ) => void;
}
export interface PropsObjectType {
  key: string;
  value: string;
}

export interface ModalPlanDetails {
  title: string;
  value: string | PropsObjectType[];
}

type OwnProps = PropsFromState &
  PropsFromDispatch &
  PropsFromRoute &
  RouteComponentProps<{
    propertyId: string;
    serviceSummary: string;
    paymentRefId: string;
  }>;

class TermsAndConditions extends React.Component<OwnProps, OwnState> {
  public waitForAccesstoken = 0;

  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      termsDrawer: false,
      planDetailsDrawer: false,
      personalDetailsDrawer: false,
    };
  }

  public componentDidMount() {
    const { accessToken, serviceAccountId } = this.props;
    const { propertyId, paymentRefId } = this.props.match.params;
    if (serviceAccountId) {
      BackHelper.saveParams({
        propertyId,
        paymentRefId,
        serviceAccountId: serviceAccountId.toString(),
      });
    }
    if (accessToken) {
      this.getBroadbandServiceData();
    }
  }

  public getBroadbandServiceData = () => {
    const { serviceSummary, propertyId } = this.props.match.params;
    this.waitForAccesstoken = 1;
    if (serviceSummary && serviceSummary === ServiceType.BroadbandSummary) {
      this.props.getBroadbandService(propertyId);
    }
  };

  public componentDidUpdate() {
    const { accessToken, serviceAccountId } = this.props;
    const { propertyId, paymentRefId } = this.props.match.params;
    if (serviceAccountId) {
      BackHelper.saveParams({
        propertyId,
        paymentRefId,
        serviceAccountId: serviceAccountId.toString(),
      });
    }
    if (accessToken && this.waitForAccesstoken === 0) {
      this.getBroadbandServiceData();
    }
  }
  public render() {
    const { service } = this.props;
    const { serviceSummary } = this.props.match.params;
    const documents = service && service.serviceDetails.broadbandPlanDetails!.documents;
    const { termsDrawer, planDetailsDrawer, personalDetailsDrawer } = this.state;
    const cardsPersonalDetails = service && this.getCardsPersonalDetails();
    const modalPersonalDetails = service && this.getModalPersonalDetails();
    const cardsPlanDetails = service && this.getCardsPlanDetails();
    const modalPlanDetails = service && this.getModalPlanDetails();
    const termsAndConditionsUrl =
      service &&
      ((service.serviceDetails.broadbandPlanDetails as BroadbandPlanSubmit).termsUrl as string);
    return service ? (
      <TermsAndConditionsComponent
        summary={serviceSummary}
        handleSubmit={this.handleSubmit}
        serviceDetails={service && service.serviceDetails}
        cardsPersonalDetails={cardsPersonalDetails}
        modalPersonalDetails={modalPersonalDetails}
        cardsPlanDetails={cardsPlanDetails}
        modalPlanDetails={modalPlanDetails}
        planModalSubtext=""
        documents={documents}
        termsAndConditionsUrl={termsAndConditionsUrl}
        toggleDrawer={this.toggleDrawer}
        termsDrawer={termsDrawer}
        planDetailsDrawer={planDetailsDrawer}
        personalDetailsDrawer={personalDetailsDrawer}
      />
    ) : (
      <div />
    );
  }
  private handleSubmit = () => {
    const { propertyId } = this.props.match.params;
    const { serviceAccountId } = this.props;
    const data: BroadbandServiceDetails = {
      serviceDetails: {
        acceptTermAndCondition: true,
      },
      step: 'ACCEPT_TERMS',
    };
    this.props.postTermsAndConditions(
      propertyId,
      (serviceAccountId as number).toString(),
      data,
      this.onSuccess,
    );
  };

  private onSuccess = () => {
    const { propertyId } = this.props.match.params;
    BackHelper.saveCount(-1);
    this.props.history.push(routes.payment.list.saved(propertyId, ServiceType.Broadband));
  };
  private getCardsPersonalDetails = (): PropsObjectType[] => {
    const { service } = this.props;
    const personalDetails = service && (service.serviceDetails.personalDetails as PersonalDetails);
    const cardList = [
      { key: 'Name', value: personalDetails.name },
      { key: 'Email', value: personalDetails.email },
      { key: 'Phone', value: personalDetails.phone },
    ];
    if (personalDetails.dob) {
      cardList.push({ key: 'D.O.B', value: personalDetails.dob });
    }
    return cardList;
  };

  private getModalPersonalDetails = (): PropsObjectType[] => {
    const { service } = this.props;
    const personalDetails = service && (service.serviceDetails.personalDetails as PersonalDetails);
    const cardList = [
      { key: 'Email address', value: personalDetails.email },
      { key: 'Title', value: personalDetails.salutation as string },
      { key: 'First name', value: personalDetails.name },
      { key: 'Last name', value: personalDetails.surname },
      { key: 'Mobile number', value: personalDetails.phone },
    ];
    if (personalDetails.dob) {
      cardList.push({
        key: 'D.O.B',
        value: personalDetails.dob,
      });
    }
    return cardList;
  };

  // tslint:disable-next-line:cyclomatic-complexity
  private getCardsPlanDetails = (): PropsObjectType[] => {
    const { service } = this.props;
    const planDetails =
      service && (service.serviceDetails.broadbandPlanDetails as BroadbandPlanSubmit);
    const contract = planDetails.contract
      ? getContractInfo(planDetails.minimumCost as number)
      : getNoContractInfo(planDetails.minimumCost as number);
    const plan = [
      {
        key: 'Contract',
        value: contract,
      },
    ];
    if (planDetails.modem) {
      if (planDetails.modem.productName) {
        plan.push({
          key: 'Modem',
          value: planDetails.modem.productName,
        });
      }
    } else {
      plan.push({
        key: 'Modem',
        value: 'BYO',
      });
    }
    if (planDetails.speedDetails && planDetails.speedDetails.dataSpeedTag) {
      const downloadSpeed = planDetails.speedDetails.dataSpeedTag;
      plan.push({
        key: 'Download speed',
        value: downloadSpeed,
      });
    }
    if (planDetails.dataSize) {
      const dataSize = planDetails.dataSize === '0GB' ? 'Unlimited' : planDetails.dataSize;
      plan.push({ key: 'Data', value: dataSize });
    }
    if (planDetails.broadbandType) {
      const broadbandType = planDetails.broadbandType.toUpperCase();
      plan.push({
        key: 'Connection',
        value: broadbandType,
      });
    }
    return plan.reverse();
  };

  // tslint:disable-next-line:cyclomatic-complexity
  private getModalPlanDetails = (): ModalPlanDetails[] => {
    const { service } = this.props;
    const planDetails =
      service && (service.serviceDetails.broadbandPlanDetails as BroadbandPlanSubmit);
    const contractComponent: PropsObjectType[] = [];
    if (planDetails.contract) {
      contractComponent.push({
        key: '12 months',
        value: getContractInfo(planDetails.minimumCost as number),
      });
      if (planDetails.earlyTerminationFeeContractYes) {
        contractComponent.push({
          key: 'Early Termination Charge: ',
          value: planDetails.earlyTerminationFeeContractYes,
        });
      }
    } else {
      contractComponent.push({
        key: 'None',
        value: getNoContractInfo(planDetails.minimumCost as number),
      });
    }

    const voice = planDetails.hasVoice ? 'Included' : 'Not included';
    const priceComponent = [
      {
        key: 'Monthly plan',
        value: (planDetails.productDetails as ProductDetails).monthlyCost
          ? `$${(planDetails.productDetails as ProductDetails).monthlyCost}`
          : '',
      },
      {
        key: 'Modem + delivery',
        value: planDetails.modem ? `$${planDetails.modem.monthlyCost}` : 'Not applicable',
      },
    ];
    const contractFee =
      planDetails.contract && parseInt(planDetails.setupFeeContractYes as string, 10);
    if (contractFee) {
      priceComponent.push({
        key: 'Setup fee',
        value: `$${planDetails.setupFeeContractYes}`,
      });
    }
    const noContractFee =
      !planDetails.contract && parseInt(planDetails.setupFeeContractNo as string, 10);
    if (noContractFee) {
      priceComponent.push({
        key: 'Setup fee',
        value: `$${planDetails.setupFeeContractNo}`,
      });
    }
    if (planDetails.connectionFeeAmount) {
      priceComponent.push({
        key: 'Connection fee',
        value: `$${planDetails.connectionFeeAmount}`,
      });
    }

    const plan: ModalPlanDetails[] = [
      {
        title: 'Pricing',
        value: priceComponent.reverse(),
      },
    ];
    if (planDetails.broadbandType) {
      plan.push({
        title: 'Connection type',
        value: planDetails.broadbandType.toUpperCase(),
      });
      if (planDetails.dataSize) {
        const dataSize = planDetails.dataSize === '0GB' ? 'Unlimited' : planDetails.dataSize;
        plan.push({
          title: 'Data',
          value: dataSize,
        });
      }
    }
    if (planDetails.speedDetails && planDetails.speedDetails.downloadSpeedText) {
      plan.push({
        title: 'Speed',
        value: planDetails.speedDetails.downloadSpeedText,
      });
    }
    if (planDetails.modem) {
      if (planDetails.modem.productName) {
        plan.push({
          title: 'Modem',
          value: planDetails.modem.productName,
        });
      }
    } else {
      plan.push({
        title: 'Modem',
        value: 'BYO',
      });
    }
    plan.push(
      {
        title: 'Voice',
        value: voice,
      },
      {
        title: 'Contract',
        value: contractComponent,
      },
    );
    plan.push({
      title: 'Voice Plan Details',
      value: (planDetails.voicePlanDetails && planDetails.voicePlanDetails.join('\n')) || '',
    });
    return plan;
  };

  private toggleDrawer = (drawerType: string, toggle: boolean) => {
    const { serviceSummary, propertyId } = this.props.match.params;
    if (
      drawerType === 'planDetailsDrawer' &&
      toggle &&
      serviceSummary === ServiceType.BroadbandSummary
    ) {
      this.props.getPlanSummary(
        propertyId,
        serviceSummary,
        this.handlePlanSummarySuccess,
        this.handlePlanSummaryError,
      );
    } else {
      this.setState((prevState) => ({
        ...prevState,
        [drawerType]: toggle,
      }));
    }
  };

  private handlePlanSummarySuccess = (v1: boolean) => {
    const { propertyId, serviceSummary } = this.props.match.params;
    if (v1) {
      this.props.history.push(routes.service.planSummary(propertyId, serviceSummary));
    }
    this.setState({ planDetailsDrawer: true });
  };

  private handlePlanSummaryError = () => {
    this.setState({ planDetailsDrawer: true });
  };
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>): PropsFromDispatch {
  return {
    postTermsAndConditions: (
      propertyId: string,
      serviceAccountId: string,
      data: BroadbandServiceDetails,
      onSuccess: () => void,
    ) => {
      dispatch(
        BroadbandServiceActions.postBroadbandServiceStart({
          propertyId,
          serviceAccountId,
          data,
          onSuccess,
        }),
      );
    },
    getBroadbandService: (propertyId: string) => {
      dispatch(BroadbandServiceActions.getBroadbandServiceStart(propertyId));
    },
    getPlanSummary: (
      serviceAccountId: string,
      serviceSummary: string,
      onSuccess: (v1: boolean) => void,
      onError: () => void,
    ) =>
      dispatch(
        PlanSummaryActions.getPlanSummaryStart({
          serviceAccountId,
          serviceSummary,
          onSuccess,
          onError,
        }),
      ),
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  const service: BroadbandServiceDetails = state.broadbandService
    .service as BroadbandServiceDetails;
  return {
    service,
    accessToken: state.token.accessToken,
    serviceAccountId:
      state.broadbandService &&
      state.broadbandService.service &&
      ((state.broadbandService.service.serviceAccount as ServiceAccount).id as number),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);

import { color } from '../../../theme/Theme';
import { WithStyles, createStyles } from '@material-ui/core';

export const Styles = createStyles({
  root: {
    padding: 5,
  },
  horizontalBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    color: color.dark,
    opacity: 1,
  },
  hint: {
    height: 24,
    width: 24,
  },
  buttonBox: {
    marginTop: 11,
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    '&:last-child': {
      borderRightWidth: 1,
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
    '&:first-child': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    'display': 'flex',
    'width': '100vw',
    'borderColor': color.lightBlue,
    'borderWidth': '1px 0 1px 1px',
    'height': 40,
    'borderRadius': 0,
  },
  buttonSelected: {
    '&:hover': {
      backgroundColor: color.lightBlue,
    },
    'backgroundColor': color.lightBlue,
  },
  buttonUnselected: {
    '&:hover': {
      backgroundColor: color.light,
    },
    'backgroundColor': color.light,
  },
  buttonText: {
    height: 16,
    fontFamily: 'GT Walsheim',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.14,
    letterSpacing: 1.1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  buttonTextSelected: {
    color: color.light,
  },
  buttonTextUnselected: {
    color: color.lightBlue,
  },
  info: {
    marginTop: 16,
    fontFamily: 'GT Walsheim light',
    color: color.dark,
  },
  infoList: {
    fontFamily: 'GT Walsheim light',
    color: color.dark,
  },
});

export interface PlanButtonStyles extends WithStyles<typeof Styles> {}

import { color } from '../../../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  serviceHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: -5,
    marginLeft: 5,
  },
  serviceTypeIconContainer: {
    width: 24,
    height: 24,
  },
  serviceTypeIcon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  serviceTypeTitle: {
    marginRight: 'auto',
    marginLeft: '15px',
    fontFamily: 'GT Walsheim',
    fontSize: '18px',
    color: color.black87,
  },
  cartContainer: {
    marginTop: 15,
  },
});

export interface CartItemStyles extends WithStyles<typeof styles> {}

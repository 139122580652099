import { match } from 'react-router';
import {
  LABELS,
  blockedCountUrls,
  commonServiceUrls,
  dashboardUrls,
  getBackUrls,
  stepRetention,
} from './HeaderConstants';
import { routes } from '../../Routes';
import { store } from '../../store/Store';
import { getCartCount } from '../../helper/CheckoutHelper';
import { History } from 'history';
import { BackHelper } from '../../helper/BackHelper';
import { ValidMessages, sendMessageToApp } from '../../helper/MessageHelper';
import { ServiceType } from '../../containers/services/ServiceConstants';
import { Params } from '../../store/state/HeaderState';

export const getHeaderLabel = (match: match, isTradeRequest?: boolean): string => {
  const count = getCartCount(store.getState().checkout.availableServices);
  const property = store.getState().checkout.property;
  switch (match.url) {
    // case routes.checkout.select:
    //   return LABELS.CHECKOUT.SELECT;
    case routes.checkout.configure:
      return isTradeRequest
        ? LABELS.CHECKOUT.CUSTOMISE_TRADE_REQUEST
        : count === 1
        ? LABELS.CHECKOUT.CUSTOMISE_ONE
        : LABELS.CHECKOUT.CUSTOMISE;
    case routes.checkout.summary:
      return LABELS.CHECKOUT.SUMMARY;
    case routes.checkout.finalize:
      return LABELS.CHECKOUT.FINALIZE;
    default:
      return '';
  }
};

export const goBack = (
  match: match<{}>,
  history: History,
  params: Params,
  propertyId: string,
  count: number,
  saveCount: (value: number) => void,
) => {
  // On header back button click rediect to dashboard from overview screen.
  const serviceName = match.url.split('/')[3];
  const isSolarAndCleaningOnBoardingUrl =
    (serviceName === ServiceType.Solar || serviceName === ServiceType.Cleaning) &&
    match.url ===
      routes.commonService.new(
        serviceName,
        match.url.split('/')[5],
        match.url.split('/')[6],
        match.url.split('/')[8],
        match.url.split('/')[9],
        match.url.split('/')[10],
      );

  if (
    stepRetention(match).includes(match.url) &&
    serviceName !== ServiceType.Solar &&
    serviceName !== ServiceType.Cleaning
  ) {
    history.push(
      routes.commonService.address.overview(
        match.url.split('/')[5],
        match.url.split('/')[3],
        match.url.split('/')[8],
        match.url.split('/')[9],
        match.url.split('/')[6],
        match.url.split('/')[10],
      ),
    );
  } else if (
    dashboardUrls(propertyId, params).includes(match.url) ||
    commonServiceUrls(match).includes(match.url) ||
    match.url.includes('/kanoppi') ||
    match.url.includes('/info') ||
    match.url.includes('/overview') ||
    match.url.includes('/first') ||
    isSolarAndCleaningOnBoardingUrl
  ) {
    BackHelper.saveParams({
      propertyId: '',
      paymentRefId: '',
      serviceAccountId: '',
      agencyName: '',
    });
    sendMessageToApp(ValidMessages.Dashboard);
  } else if (getBackUrls(propertyId, params).includes(match.url)) {
    const backValue = count - 1;
    if (backValue === 0) {
      history.goBack();
    } else {
      saveCount(-count);
      history.go(backValue);
    }
  } else {
    if (count && !blockedCountUrls(params).includes(match.url)) {
      saveCount(1);
    }
    history.goBack();
  }
};

import * as React from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/RootReducers';
import { BroadbandServiceDetails } from '../../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
// import OverviewScreenComponent from "../../../component/services/electricityService/OverView";
import { History } from 'history';
import { RouteComponentProps } from 'react-router-dom';
import { routes } from '../../../Routes';
import { HeaderState, Params } from '../../../store/state/HeaderState';
import { BackHelper } from '../../../helper/BackHelper';
import Overview from '../../../component/services/otherService/Overview';
import { PlanSummaryActions } from '../../../store/actions/PlanSummaryActions';
import { ServiceSummaryResponse } from './OtherServicesSummary.data';
import { hasUpdatedApp } from '../../../helper/AuthHelper';

interface PropsFromDispatch {
  getServiceSummary: (
    serviceType: string,
    propertyId: string,
    providerName: string,
    history: History,
  ) => void;
}

interface PropsFromState {
  service: BroadbandServiceDetails;
  accessToken: string | boolean | null;
  params: Params;
  serviceSummary: ServiceSummaryResponse;
}

interface PropsFromRoute {
  history: History;
}

type OwnProps = PropsFromDispatch &
  PropsFromState &
  PropsFromRoute &
  RouteComponentProps<{
    propertyId: string;
    serviceSummary: string;
    paymentRefId: string;
    serviceAccountId: string;
    serviceName: string;
    providerName: string;
  }>;

class OverviewScreen extends React.Component<OwnProps, {}> {
  private waitForAccessToken = 0;
  private waitForServiceId = 0;
  public constructor(props: OwnProps) {
    super(props);
  }
  public componentDidMount() {
    const { serviceName, propertyId, providerName } = this.props.match.params;

    const paymentRefId = '';
    const serviceAccountId = '';

    const { accessToken, history } = this.props;
    if (accessToken && serviceName) {
      this.props.getServiceSummary(serviceName, propertyId, providerName, history);
    }
    if (this.waitForServiceId === 0) {
      this.waitForServiceId = 1;
      BackHelper.saveParams({
        propertyId,
        paymentRefId,
        serviceAccountId,
      });
    }
  }

  public componentDidUpdate() {
    const { serviceName, propertyId, providerName } = this.props.match.params;
    const { accessToken, history } = this.props;
    const paymentRefId = '';
    const serviceAccountId = '';

    if (accessToken && this.waitForAccessToken === 0 && serviceName && !this.props.serviceSummary) {
      this.props.getServiceSummary(serviceName, propertyId, providerName, history);
    }
    if (serviceAccountId && this.waitForServiceId === 0) {
      this.waitForServiceId = 1;
      BackHelper.saveParams({
        propertyId,
        paymentRefId,
        serviceAccountId,
      });
    }
  }

  public render() {
    const { serviceSummary } = this.props;
    return (
      <Overview
        serviceName={this.props.match.params.serviceName}
        onBtnClick={this.redirectTo}
        serviceSummary={serviceSummary}
      />
    );
  }

  public redirectTo = () => {
    this.props.history.push(this.handleRedirecionUrl());
  };

  public handleRedirecionUrl = () => {
    const { propertyId, serviceName } = this.props.match.params;
    return routes.otherService.summary(serviceName, propertyId);
  };
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>): PropsFromDispatch {
  return {
    getServiceSummary: (
      serviceType: string,
      propertyId: string,
      providerName: string,
      history: History,
    ) => {
      if (hasUpdatedApp(history)) {
        dispatch(
          PlanSummaryActions.getServiceSummaryStart({ serviceType, propertyId, providerName }),
        );
      } else {
        history.push('/onboarding/serviceError');
      }
    },
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  const service: BroadbandServiceDetails = state.broadbandService
    .service as BroadbandServiceDetails;
  return {
    service,
    accessToken: state.token.accessToken,
    params: (state.headerParams as HeaderState).params,
    serviceSummary: state.planSummary.serviceSummary,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(OverviewScreen);

import * as React from 'react';
import { Typography, withStyles, Divider, Drawer, Button } from '@material-ui/core';
import PlanButton from '../PlanButtons';
import PlanFooter from '../PlanFooter';
import {
  PLAN_LABELS,
  PLAN_MODAL_DATA,
  DEFAULT_SELECTED_BUTTON_INDEX,
  EMPTY_ARRAY_LENGTH,
  FOOTER_VALUE_NA,
} from './Constants';
import { Styles, PlanScreenStyles } from './Styles';
import { PlanOptions } from '../../../../models/plan/electricity/getPlanDetails/PlanOptions.data';
import { routes } from '../../../../Routes';
import { PlanDetails } from '../../../../models/plan/electricity/getPlanDetails/PlanDetails.data';

interface PropsFromRoute {
  readonly history?: any;
}

interface PropsFromParent {
  readonly planDetails: PlanDetails;
  readonly handleNext: (choice: number) => void;
  readonly averageUsage: string[];
}

interface PlanState {
  choice: number;
  showModal: boolean;
}

type OwnProps = PlanScreenStyles & PropsFromRoute & PropsFromParent;

class PlanScreen extends React.Component<OwnProps, PlanState> {
  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      choice: DEFAULT_SELECTED_BUTTON_INDEX,
      showModal: false,
    };
  }

  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <div className={classes.top}>
            <Typography className={classes.title} variant="h5">
              {PLAN_LABELS.title}
            </Typography>
            <PlanButton
              options={this.filterOptions()}
              label={PLAN_LABELS.buttonsHeading}
              info={[this.getPlanAverageUsage(), PLAN_LABELS.planInfo]}
              showHint
              onChange={this.handleOptionChange}
              onHintClick={this.handleHelpClick}
              selected={this.state.choice}
            />
            {this.renderContract()}
          </div>
          <PlanFooter amount={this.filterFooterValue()} onButtonClick={this.handleFooterClick} />
        </div>
        {this.renderModal()}
      </div>
    );
  }

  private getPlanAverageUsage = (): string => {
    const { choice } = this.state;
    const { averageUsage } = this.props;
    switch (choice) {
      case 0:
        return PLAN_LABELS.lowUsageInfo(averageUsage[choice]);
      case 1:
        return PLAN_LABELS.moderateUsageInfo(averageUsage[choice]);
      case 2:
        return PLAN_LABELS.highUsageInfo(averageUsage[choice]);
      default:
        return '';
    }
  };

  private renderContract = () => {
    const { classes } = this.props;
    return (
      <div>
        <Divider className={classes.divider} />
        <Typography className={classes.contract} variant="subtitle1">
          {PLAN_LABELS.contract}
        </Typography>
        <Typography className={classes.contractInfo} variant="body2">
          {PLAN_LABELS.contractInfo}
        </Typography>
      </div>
    );
  };

  private filterOptions = (): string[] => {
    const options: string[] = [];
    const { planDetails } = this.props;
    if (planDetails && planDetails.plans) {
      planDetails.plans.map((data: PlanOptions) => {
        options.push(data.usage);
      });
    }
    return options;
  };

  private filterFooterValue = (): number | string => {
    const { planDetails } = this.props;
    return planDetails && planDetails.plans && planDetails.plans.length > EMPTY_ARRAY_LENGTH
      ? planDetails.plans[this.state.choice].monthlyCost
      : FOOTER_VALUE_NA;
  };

  private handleOptionChange = (choice: number) => {
    this.setState({ choice });
  };

  private handleFooterClick = () => {
    this.props.history.push(
      routes.service.plan.support((this.props as any).match.params.propertyId),
    );
    this.props.handleNext(this.state.choice);
  };

  private handleHelpClick = () => {
    this.toggleModal(true);
  };

  private toggleModal = (toggle: boolean) => {
    this.setState({ showModal: toggle });
  };

  private renderModal = () => {
    const { classes } = this.props;
    return (
      <Drawer
        anchor="bottom"
        open={this.state.showModal}
        classes={{
          paper: classes.bottomDrawerContainer,
        }}
        onClose={this.toggleModal.bind(this, false)}
      >
        <div className={classes.bottomDrawer}>
          <Typography className={classes.bottomDrawerTitle}>{PLAN_LABELS.drawerTitle}</Typography>
          <Divider className={classes.divider} />
          <Typography className={classes.terms}>{PLAN_MODAL_DATA.firstPara}</Typography>
          {this.renderModalList(PLAN_MODAL_DATA.list)}
          <Typography className={classes.terms}>{PLAN_MODAL_DATA.endPara}</Typography>
          <Button
            fullWidth
            onClick={this.toggleModal.bind(this, false)}
            className={classes.drawerButtons}
          >
            <Typography variant="body2">{PLAN_LABELS.drawerButton}</Typography>
          </Button>
        </div>
      </Drawer>
    );
  };

  private renderModalList = (data: string[]) => {
    const { classes } = this.props;
    return data.map((instruction: string, index: number) => (
      <Typography key={index} className={classes.termsList}>
        {instruction}
      </Typography>
    ));
  };
}

export default withStyles(Styles)(PlanScreen);

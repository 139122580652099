import React, { FC, useEffect, useState, useMemo } from 'react';
import { withStyles, Dialog } from '@material-ui/core';
import { styles, HowItWorksStyles } from './HowItWorksStyles';
import { getCarouselItems, LABELS } from './HowItWorksConstants';

interface HowItWorksProps extends HowItWorksStyles {
  closePopup: () => void;
}

const HowItWorks: FC<HowItWorksProps> = ({ classes, closePopup }) => {
  const [activeStep, setActiveStep] = useState<number>(0);

  const content = getCarouselItems();

  const onNext = () => {
    if (activeStep === content.length - 1) {
      closePopup();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={content[activeStep].image} alt="" />
      </div>
      <div className={classes.dotsContainer}>
        {content.map((_, idx) => (
          <div
            className={idx === activeStep ? classes.dotActive : classes.dot}
            onClick={() => setActiveStep(idx)}
          />
        ))}
      </div>
      <div className={classes.content}>
        <div className={classes.title}>{content[activeStep].title} </div>
        <div className={classes.text}>{content[activeStep].text} </div>
        <div className={classes.buttonsContainer}>
          <div className={classes.secondaryButton} onClick={() => closePopup()}>
            {LABELS.REMIND}
          </div>
          <div className={classes.primaryButton} onClick={() => onNext()}>
            {activeStep === content.length - 1 ? LABELS.GET_STARTED : LABELS.NEXT}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(HowItWorks);

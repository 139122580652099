import ApiServiceBase from '../ApiServiceBase';
import { ServiceType } from '../ServiceType.data';
import { AppSettings } from '../../AppSettings';
import ApiServiceMock from '../__mocks__/ApiServiceMock';
import ApiService from '../ApiService';
import { PostServicePayload } from '../../store/actions/BroadbandServiceActions';

const serviceType = ServiceType.Service;

export class BroadbandService {
  private readonly service: ApiServiceBase = AppSettings.currentTestSetting.testing
    ? new ApiServiceMock(serviceType)
    : new ApiService(serviceType);
  public getBroadbandService(serviceAccountId: string): any {
    return this.service.get({
      route: ['service-account', serviceAccountId],
    });
  }

  public postBroadbandService(service: PostServicePayload): any {
    const { data, propertyId, serviceAccountId } = service;
    return this.service.post(
      {
        route: ['service-account/broadband', propertyId],
        query: {
          serviceAccountId,
        },
      },
      data,
    );
  }
  public deleteBroadbandService(serviceAccountId: string): any {
    return this.service.delete({
      route: ['service-account', serviceAccountId],
    });
  }
}

const broadbandService = new BroadbandService();
export default broadbandService;

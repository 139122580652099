import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../Routes';
import AddPayments from '../../containers/payment/addPayment/AddPayment';
import AddBank from '../../containers/payment/addPayment/addBank/AddBank';
import PaymentDetails from '../../containers/payment/selectPayment/PaymentDetails';
import PaymentDetailsMock from '../../containers/payment/planSelectionPaymentDetail';
import CardForm from '../../containers/payment/addPayment/addCard/AddCard';

export default class PaymentLayout extends React.Component {
  public render() {
    return (
      <Switch>
        <Route
          path={routes.payment.list.empty(
            ':propertyId',
            ':serviceType',
            ':paymentRefId?',
            ':serviceAccountId?',
            ':agencyName?',
          )}
          exact
          component={AddPayments}
        />
        <Route
          path={routes.payment.new.bank(
            ':propertyId',
            ':serviceType',
            ':paymentRefId?',
            ':serviceAccountId?',
          )}
          exact
          component={AddBank}
        />
        <Route
          path={routes.payment.new.card(
            ':propertyId',
            ':serviceType',
            ':paymentRefId?',
            ':serviceAccountId?',
          )}
          exact
          component={CardForm}
        />
        <Route
          path={routes.payment.list.saved(
            ':propertyId',
            ':serviceType',
            ':paymentRefId?',
            ':serviceAccountId?',
            ':agencyName?',
          )}
          component={PaymentDetails}
        />
        <Route
          path={routes.payment.list.commonServiceSaved(
            ':propertyId',
            ':serviceType',
            ':paymentRefId?',
            ':serviceAccountId?',
            ':agencyName?',
          )}
          component={PaymentDetailsMock}
        />
      </Switch>
    );
  }
}

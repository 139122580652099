import * as React from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/RootReducers';
import { BroadbandServiceDetails } from '../../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import { History } from 'history';
import { RouteComponentProps } from 'react-router-dom';
import { routes } from '../../../Routes';
import { HeaderState, Params } from '../../../store/state/HeaderState';
import { BackHelper } from '../../../helper/BackHelper';
import { PlanSummaryActions } from '../../../store/actions/PlanSummaryActions';
import Summary from '../../../component/services/otherService/Summary';
import { get } from 'lodash';
import { PostSummaryRequest } from './OtherServicesSummary.data';

interface PropsFromDispatch {
  postServiceSummary: (
    serviceType: string,
    propertyId: string,
    body: PostSummaryRequest,
    onSuccess: () => void,
  ) => void;
}

interface PropsFromState {
  service: BroadbandServiceDetails;
  accessToken: string | boolean | null;
  params: Params;
  serviceSummary: any;
}

interface PropsFromRoute {
  history: History;
}

type OwnProps = PropsFromDispatch &
  PropsFromState &
  PropsFromRoute &
  RouteComponentProps<{
    propertyId: string;
    serviceSummary: string;
    paymentRefId: string;
    serviceAccountId: string;
    serviceName: string;
  }>;

class SummaryScreen extends React.Component<OwnProps, {}> {
  private waitForServiceId = 0;
  public constructor(props: OwnProps) {
    super(props);
  }
  public componentDidMount() {
    const { serviceAccountId, propertyId, paymentRefId } = this.props.match.params;
    if (serviceAccountId && this.waitForServiceId === 0) {
      this.waitForServiceId = 1;
      BackHelper.saveParams({
        propertyId,
        paymentRefId,
        serviceAccountId,
      });
    }
  }

  public componentDidUpdate() {
    const { serviceAccountId, propertyId, paymentRefId } = this.props.match.params;
    if (serviceAccountId && this.waitForServiceId === 0) {
      this.waitForServiceId = 1;
      BackHelper.saveParams({
        propertyId,
        paymentRefId,
        serviceAccountId,
      });
    }
  }

  public render() {
    const { serviceSummary } = this.props;
    return (
      <Summary
        serviceSummary={serviceSummary}
        serviceName={this.props.match.params.serviceName}
        onBtnClick={this.postData}
      />
    );
  }

  public postData = () => {
    const { serviceSummary } = this.props;

    const userId = get(serviceSummary, 'userResponse.id');
    const userName = get(serviceSummary, 'userResponse.name');
    const userSurname = get(serviceSummary, 'userResponse.surname');
    const userEmail = get(serviceSummary, 'userResponse.email');
    const userPhone = get(serviceSummary, 'userResponse.phone');
    const providerId = get(serviceSummary, 'serviceProvider[0].SupplierId');

    const { propertyId, serviceName } = this.props.match.params;
    const postBody: PostSummaryRequest = {
      serviceDetails: {
        personalDetailsStep: {
          step: 'PERSONAL_DETAILS',
          completed: false,
          mandatory: true,
          updateable: false,
        },
        personalDetails: {
          id: userId,
          name: userName,
          surname: userSurname,
          email: userEmail,
          phone: userPhone,
        },
        referenceNumberStep: {
          step: 'REFERENCE_NUMBER',
          completed: false,
          mandatory: false,
          updateable: false,
        },
        referenceNumber: null,
        providerId: providerId,
      },
      step: 'PERSONAL_DETAILS',
    };
    this.props.postServiceSummary(serviceName, propertyId, postBody, () =>
      this.props.history.push(routes.setup.base('otherServices')),
    );
  };

  public redirectTo = (stepCode: number) => {
    const stepUrls = this.handleRedirecionUrl();
    this.props.history.push(stepUrls[stepCode]);
  };

  public handleRedirecionUrl = () => {
    const { propertyId, serviceName } = this.props.match.params;

    return routes.otherService.summary(serviceName, propertyId);
  };
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>): PropsFromDispatch {
  return {
    postServiceSummary: (
      serviceType: string,
      propertyId: string,
      body: PostSummaryRequest,
      onSuccess: () => void,
    ) => {
      dispatch(
        PlanSummaryActions.postServiceSummaryStart({ serviceType, propertyId, body, onSuccess }),
      );
    },
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  const service: BroadbandServiceDetails = state.broadbandService
    .service as BroadbandServiceDetails;
  return {
    service,
    accessToken: state.token.accessToken,
    params: (state.headerParams as HeaderState).params,
    serviceSummary: state.planSummary.serviceSummary,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SummaryScreen);

import * as React from 'react';
import { History } from 'history';
import { RouteComponentProps } from 'react-router-dom';
import PowerScreen from '../../../../component/services/electricityService/powerConnection/PowerConnection';
import { ElectricityServiceActions } from '../../../../store/actions/ElectricityServiceActions';
import {
  ElectricityServiceDetails,
  StepDetails,
  ServiceAccount,
} from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/RootReducers';
import { ConnectionDateState } from '../../../../store/state/ConnectionDateState';
import { routes } from '../../../../Routes';
import { PlanState } from '../../../../store/state/PlanState';
import { PlanDetails } from '../../../../models/plan/electricity/getPlanDetails/PlanDetails.data';
import { format } from 'date-fns';
import { DATE_FORMAT } from '../../../../component/services/electricityService/connectionDate/DateConfirmationConstants';
import {
  createPostPlanObject,
  choiceValue,
  hourValue,
} from '../../../../component/services/electricityService/powerConnection/Constants';
import uuidv4 from 'uuid/v4';
import { BackHelper } from '../../../../helper/BackHelper';

interface PropsFromState {
  connectionDate: ConnectionDateState;
  plan: PlanState;
  serviceAccountId: number;
}

interface OwnState {
  choice: string;
}

interface PropsFromDispatch {
  postConnectionDate: (
    propertyId: number,
    serviceAccountId: number,
    serviceSummary: string,
    data: ElectricityServiceDetails,
    props: OwnProps,
  ) => void;
  postPlanDetails: (
    propertyId: number,
    serviceAccountId: number,
    data: ElectricityServiceDetails,
    onSuccess: () => void,
  ) => void;
}
interface PropsFromRoute {
  history: History;
}

type OwnProps = PropsFromState &
  PropsFromDispatch &
  PropsFromRoute &
  RouteComponentProps<{ propertyId: string; serviceSummary: string }>;

class PowerConnection extends React.Component<OwnProps, OwnState> {
  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      choice: 'yes',
    };
  }

  public render() {
    return <PowerScreen handleNext={this.handlePlanSubmit} />;
  }

  public handlePlanSubmit = (choice: string) => {
    const { plan, serviceAccountId } = this.props;
    const quoteValue = this.getQuoteValue()
      ? (plan.planDetails as PlanDetails).costBusinessHours
      : (plan.planDetails as PlanDetails).costAfterHours;
    const { propertyId } = this.props.match.params;
    this.setState({ choice });
    const data: ElectricityServiceDetails = createPostPlanObject(
      plan,
      true,
      quoteValue,
      uuidv4(),
      (plan.planDetails as PlanDetails).connectionFee,
    );
    this.props.postPlanDetails(
      parseInt(propertyId, 10),
      serviceAccountId,
      data,
      this.handleConnectionDateSubmit,
    );
  };

  public handleConnectionDateSubmit = () => {
    const { connectionDate, moving } = this.props.connectionDate;
    const { serviceAccountId } = this.props;
    const { propertyId, serviceSummary } = this.props.match.params;
    const formattedDate = format(connectionDate as Date, DATE_FORMAT);
    const powerOn = this.state.choice === choiceValue;
    const data: ElectricityServiceDetails = {
      serviceDetails: {
        connectionDateDetails: {
          connectionDate: formattedDate,
          moving,
          powerOn,
        },
      },
      step: 'CONNECTION_DATE',
    };
    BackHelper.saveCount(-1);
    this.props.postConnectionDate(
      parseInt(propertyId, 10),
      serviceAccountId,
      serviceSummary,
      data,
      this.props,
    );
  };

  private getQuoteValue = (): boolean => {
    const { connectionDate } = this.props.connectionDate;
    const formattedSelectedDate = format(connectionDate as Date, DATE_FORMAT);
    const formattedCurrentDate = format(new Date(), DATE_FORMAT);
    if (formattedSelectedDate === formattedCurrentDate) {
      return new Date().getHours() < hourValue;
    } else {
      return false;
    }
  };
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>): PropsFromDispatch {
  return {
    postPlanDetails: (
      propertyId: number,
      serviceAccountId: number,
      data: ElectricityServiceDetails,
      onSuccess: () => void,
    ) =>
      dispatch(
        ElectricityServiceActions.postElectricityServiceStart({
          propertyId,
          serviceAccountId,
          data,
          onSuccess: () => onSuccess(),
        }),
      ),
    postConnectionDate: (
      propertyId: number,
      serviceAccountId: number,
      serviceSummary: string,
      data: ElectricityServiceDetails,
      props: OwnProps,
    ) => {
      dispatch(
        ElectricityServiceActions.postElectricityServiceStart({
          propertyId,
          serviceAccountId,
          data,
          onSuccess: (service) => {
            const { serviceDetails } = service as ElectricityServiceDetails;
            if ((serviceDetails.personalDetailsStep as StepDetails).completed) {
              props.history.push(
                routes.service.termsAndConditions.new(`${propertyId}`, serviceSummary),
              );
            } else {
              props.history.push(routes.service.personalDetails.showDetails(`${propertyId}`));
            }
          },
        }),
      );
    },
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    connectionDate: state.connectionDate,
    plan: state.plan,
    serviceAccountId: (
      (state.electricityService.service as ElectricityServiceDetails)
        .serviceAccount as ServiceAccount
    ).id as number,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PowerConnection);

import * as React from 'react';
import WalletHeaderComponent from '../../../component/wallet/walletHeader/WalletHeader';
import { History } from 'history';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { routes } from '../../../Routes';
import { sendMessageToApp, ValidMessages } from '../../../helper/MessageHelper';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/RootReducers';
import { headerUrl } from '../../../component/wallet/WalletConstants';
import { ServiceType } from '../../services/ServiceConstants';

interface PropsFromRoute {
  history: History;
}

interface PropsFromState {
  label?: string;
}

interface PropsFromParent {
  handleAnimation: () => void;
}

type OwnProps = PropsFromRoute & PropsFromParent & RouteComponentProps & PropsFromState;

class WalletHeader extends React.Component<OwnProps, {}> {
  public render() {
    const urlCheck = headerUrl.find((url) => url === this.props.match.url);
    return (
      <WalletHeaderComponent
        handleGoBack={this.handleGoBack}
        handleChat={this.handleChat}
        urlCheck={!!urlCheck}
        label={this.props.label!}
      />
    );
  }
  public handleGoBack = () => {
    const { history, handleAnimation, match } = this.props;
    history.goBack();
    handleAnimation();
    // On header back button click rediect to dashboard from overview screen.
    if (match.url === routes.wallet.paymentOptions(ServiceType.Wallet)) {
      sendMessageToApp(ValidMessages.Dashboard);
    } else {
      history.goBack();
    }
  };
  public handleChat = () => {
    sendMessageToApp(ValidMessages.Chat);
  };
}
function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    label: state.wallet.headerLabel,
  };
}

export default withRouter(connect(mapStateToProps, null)(WalletHeader));

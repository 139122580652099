import { RadioElements } from '../../checkoutConfigure/components/SelectOptions/components/questionTypes/RadioButtonOptions/RadioButtonOptionsConstants';

export interface FeatureDetails {
  type?: string;
  label?: string;
  labelId?: string;
  value?: string;
  valueId?: string;
  isMandatory?: boolean;
  componentType?: string | null;
  parentGroup?: string;
  cost?: number | null;
}

export interface AddressFound {
  AddressId: string | null;
  AddressLine1: string | null;
  AddressLine2: string | null;
  AddressLine3: string | null;
  Barcode: string | null;
  Country: string | null;
  DPID: string | null;
  FloorType: string | null;
  FloorUnitNumber: string;
  FullAddressDisplay: string;
  KeyIdentifier: string;
  LotNo: string;
  MIRN: string;
  MeterSerialNumber: string | null;
  NMI: string | null;
  PoBoxNumber: string | null;
  PoBoxType: string | null;
  Postcode: string;
  StateCode: string;
  StreetName: string;
  StreetNoSuffix: string;
  StreetNumber: string;
  StreetNumber1: string;
  StreetNumber2: string;
  StreetSuffix: string;
  StreetType: string;
  StreetTypeSuffix: string | null;
  SubAddress: string | null;
  SubAddressLine: string | null;
  Suburb: string;
  TiabAddressSearchPayload: string | null;
  UnitNumber: string;
  UnitNumber1: string;
  UnitNumber2: string | null;
  UnitType: string;
}
export interface Logo {
  Active: string | null;
  Hero: string | null;
  Inactive: string | null;
}
export interface Supplier {
  Description: string | null;
  DescriptionBanner: string | null;
  DescriptionFooter: string | null;
  Logo: Logo;
  PageTitle: string | null;
  StartingPrice: string;
  SupplierId: string;
  SupplierLabel: string;
  SupplierName: string;
}
export interface AddressLookUp {
  AddressFound: AddressFound;
  AddressesSearchable: AddressFound[];
  Attributes: any;
  IsServiceable: boolean;
  KeyIdentifier: string;
  Postcode: string;
  ServiceType: string;
  State: string;
  Supplier: Supplier;
}

export interface ProductDetails {
  productName?: string;
  monthlyCost?: number | string | Options;
  dailyCost?: number | string;
  productCode?: string;
  productId?: string;
  productDescription?: string;
  productType?: string;
  cost?: number | string;
  costUnit?: string;
  provider?: string;
  IsProduct?: boolean;
}
export interface ServiceDetail {
  planDetails?: PlanDetail;
  partialCompletion?: boolean;
}

export interface QuotesV3 {
  Response: Plan;
}

export interface Plan {
  Plans: ProviderPlan[];
  ServiceabilityDetails?: any;
}
export interface Headline {
  Banner?: string | null;
  Description?: string | null;
  PlanId?: string | null;
  Title?: string | null;
}
export interface Link {
  Label?: string | null;
  Link?: string;
  Name?: string | null;
  Type?: string | null;
}
export interface BenefitsObject {
  Body: string;
  Heading: string;
}
export interface Features {
  Body: string;
  Heading: string;
}
export interface Settings {
  Name: string;
  Value: string;
}
export interface FeeItems {
  Label: string;
  Links: Link[] | [];
  Name: string;
  OrderBy: number;
  Rate: number;
  Rule: string;
  Type: string;
  Unit: string;
}

export interface Fees {
  FeeItems: FeeItems[];
  Heading: string;
  HelpText: string;
  Id: string;
  LinkItems: Link[];
  Name: string;
  Type: string;
  ServiceType?: string;
  ChargesFeesText?: string;
}
export interface Options {
  BasePriceDaily: number;
  BasePriceMonthly: number;
  Default: boolean;
  FeeId: string[];
  HelpText: string;
  ComponentText: string;
  IsProduct: boolean;
  Label: string;
  MinimumContract: number;
  OrderBy: number;
  PricingHelpText: string;
  ProductId: string;
  SinglePrice: number | null;
  StepPriceDaily: string | null;
  StepPriceMonthly: string | null;
  PlanName: string;
}
export interface PlanOption {
  Footer: string;
  Group: string;
  Description: string;
  Heading: string;
  Options: Options[];
  OrderBy: number;
  Type: string;
  ProductId: string;
}
export interface Rates {
  Fees: Fees[];
  FooterText: string | null;
  HeaderText: string | null;
  Links: Link[];
  OrderBy: number;

  Rates: Rate[];
}
export interface Rate {
  Label: string;
  Links: Link[];
  Name: string;
  OrderBy: number;
  Rate: number;
  Rule: string;
  Type: string;
  Unit: string;
}

export interface FooterPricing {
  PricingType: string;
  UnitOfMeasure: string;
  PriceField: string;
  UnitOfMeasureLabel: string;
}
export interface PlanDetailObject {
  BasePriceDaily: number;
  BasePriceMonthly: number;
  Benefits: BenefitsObject[];
  BasePrice: number;
  Description: string;
  DescriptionFooter: string;
  EndDate: string | Date | null;
  Features: Features[];
  Fees: Fees[];
  Group: string;
  Headlines: BenefitsObject[];
  Inclusions: BenefitsObject[];
  Links: Link[];
  MinimumContract: number;
  Name: string;
  Options: PlanOption[];
  OrderBy: number;
  PaymentCardRequired: boolean;
  PaymentInformation: PaymentInformation;
  ProductId: string;
  Rates: Rates[];
  StartDate: Date | null | string;
  Type: string;
  data: KeyValueData[];
  FooterPricing: FooterPricing[];
}

export interface PaymentInformation {
  PaymentMethods: string[];
  CheckoutText: string;
  PaymentGateway: PaymentGateway;
  Required: boolean;
  SignupPayments: {
    InstantPay: boolean;
    SaveToDb: boolean;
  };
  ChargesFeesText: string;
}

export enum PaymentGateway {
  STRIPE = 'stripe',
  BPOINT = 'bpoint',
  SPLIT = 'split',
  WESTPAC_QUICK = 'westpac-quickstream',
}
export interface PostAgreementSummary {
  Body: string;
  Footer: string;
  Heading: string;
  Title: string;
}

export interface AcceptanceChecks {
  ComponentText: string;
  ComponentType: string;
  IsMandatory: boolean;
}

export interface PreAgreementSummary {
  AcceptanceChecks: AcceptanceChecks[];
  Body: string;
  Footer: string;
  Heading: string;
  LinkItems: Link[];
  Title: string;
}

export interface AttributeSection {
  Control: string;
  Type: string;
  Section: string;
  Name: string;
  Value: string;
  LogoIconDisplay: boolean;
}

export interface UIAttributes {
  PlansSelector: AttributeSection;
  Benefit: AttributeSection;
  Links: AttributeSection;
  TermsAccept: AttributeSection;
  ViewPlanOptions: AttributeSection;
  Summary: AttributeSection;
}

export interface ProviderPlan {
  CustomerType: string;
  Description: string;
  DescriptionBanner: string;
  DescriptionFooter: string;
  HeadlinePromo: Headline;
  Iframe: string;
  LinkItems: Link[];
  Plans: PlanDetailObject[];
  PostAgreementSummary: PostAgreementSummary;
  UiModelAttributes: UIAttributes;
  PreAgreementSummary: PreAgreementSummary;
  ProductId: string;
  PublicHolidays: string[];
  ServiceCategoryId: string;
  ServiceCategoryName: string;
  ServiceClass: string;
  ServiceName: string;
  ServiceType: string;
  Settings: Settings[];
  SignUpType: string;
  SupplierBackgroundLogo: string;
  SupplierId: string;
  SupplierLogo: string;
  SupplierLogoHero: string;
  SupplierName: string;
  SupplierOptions: SupplierOptions[];
  SupplierTagLine: string;
  SupplierTitle: string;
  ProductType: string;
  SupplierIntroductionButtonLabel: string;
}

export interface ComponentOptions {
  Name: string;
  Value: string;
}
export interface SupplierOptions {
  ChatEnabled: boolean;
  ComponentDateExcludeWeekend: boolean;
  ComponentDateExcludeCurrentDay: boolean;
  ComponentDateMaxFutureDays: number;
  ComponentId: string | null;
  ComponentText: string | null;
  ComponentType: string | null;
  ComponentOptions: ComponentOptions[];
  Default: boolean;
  Description: string;
  FooterText: string | null;
  Group: string;
  Heading: string;
  IncludedInContractPricing: boolean;
  Links: Link[];
  MandatoryOptionProductId: string | null;
  Options: SupplierOption[];
  OrderBy: number;
  Page: number;
  ProductId: string | null;
  PublicHolidayExclusion: boolean;
  Type: string;
  value?: string | Date;
  ComponentIsMandatory: boolean;
  PlanName: string;
}

export interface SupplierOption {
  BasePriceDaily: number;
  BasePriceMonthly: number;
  Default: boolean;
  HasPrice: boolean;
  HelpText: string;
  ComponentText: string;
  Label: string;
  Option: SupplierOptions;
  OrderBy: number;
  PricingHelpText: string;
  ProductId: string;
  SinglePrice: number;
  StopSaleProcess: boolean;
  selected?: boolean;
  ComponentIsMandatory: boolean;
  ImageGallery: string[];
  LinkMoreInfo: string;
  UiElements: RadioElements;
  PlanName: string;
}

export interface CommonSummary {
  serviceDetails: ServiceDetail;
  step: string;
  propertyId?: string;
  serviceType?: string;
  providerId?: string;
  serviceAccountId?: string;
}

export interface PlanDetail {
  featureDetails?: FeatureDetails[];
  summaryDetails: SummaryDetail[];
  productDetails?: ProductDetails | null;
  attributes: any;
  concessionCard?: ConcessionCard | null;
  isAcceptTermAndCondition: boolean;
  transactionId: string;
  skipPaymentStep: boolean;
  paymentType: string | null;
  serviceabilityDetails?: any;
}

export interface SummaryDetail {
  sectionTitle: string;
  data: KeyValueData[] | {};
}
export interface KeyValueData {
  key: string;
  value: any;
}

export interface ConcessionCard {
  name?: string;
  cardNumber?: string;
  expiryMonth?: string;
  expiryYear?: string;
  cardType?: string;
}

import React, { PureComponent, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { routes } from '../../Routes';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  Typography,
  withStyles,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Drawer,
} from '@material-ui/core';
import Lightning from '../../assets/lightning.svg';
import ForwardArrow from '../../assets/arrow-forward.svg';
import BroadBand from '../../assets/internet.svg';
import { ServicePlanStyle, Styles } from './ServicePlanStyles';
import { connect } from 'react-redux';

interface PropsFromRoute {
  //TODO: Resolve any
  readonly history?: any;
}

export interface CardDetails {
  readonly type: 'BROADBAND' | 'ELECTRICITY';
  readonly serviceType: string;
  readonly footerData: {
    userData: string;
    remainingData: string;
  };
}

const labels = {
  walletTitle: 'Wallet',
  walletSubtitle: 'Available',
  modalTitle: 'Be back soon!',
  modalSubtitle1:
    'Sorry, this feature is currently under development and is temporarily disabled while we make improvements.',
  modalSubtitle2:
    'You can still spend and cashout credits by chatting to one of our helpful team members. Would you like to chat now?',
  modalCancelButtonText: 'CANCEL',
  modalChatButtonTex: 'CHAT',
};

interface PropsFromParent {
  readonly propertyId: number;
  readonly serviceAccountId: number;
}

interface State {
  value: number;
  cardDetails: CardDetails;
  paymentDetails: {
    payment: number;
    nextPaymentDate: string;
    creditCardDetails: string;
  };
  //TODO: Resolve any
  invoicesData: any;
  adjustModalOpen: boolean;
}

//TODO: Resolve any
function TabContainer(props: any) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

type OwnProps = PropsFromParent & ServicePlanStyle & PropsFromRoute;

class ServicePlan extends PureComponent<OwnProps, State> {
  constructor(props: OwnProps) {
    super(props);

    const cardDetails: CardDetails = {
      type: 'ELECTRICITY',
      serviceType: 'Sorted Services',
      footerData: {
        userData: '457 kW',
        remainingData: '1500kW',
      },
    };
    const paymentDetails = {
      payment: 400,
      nextPaymentDate: '14/02/2020',
      creditCardDetails: '123***',
    };
    //TODO: Resolve any
    const invoicesData: any = {};

    this.state = {
      value: 0,
      cardDetails,
      paymentDetails,
      invoicesData,
      adjustModalOpen: false,
    };
  }

  //TODO: Resolve any
  public handleChange = (event: any, value: number) => {
    this.setState({ value });
  };

  //TODO: Resolve any
  public handleAdjustClick = (event: any) => {
    event.preventDefault();
    this.setState({ adjustModalOpen: true });
  };

  //TODO: Resolve any
  public handleEditClick = (event: any) => {
    event.preventDefault();
    const { propertyId, serviceAccountId } = this.props;
    const urlServiceType = 'Electricity';
    const paymentRefId = '00049683';
    this.props.history.push(
      routes.payment.list.saved(
        urlServiceType,
        propertyId.toString(),
        paymentRefId,
        serviceAccountId.toString(),
        'SORTED',
      ),
    );
  };

  public renderContent = () => {
    const { value, cardDetails, paymentDetails, adjustModalOpen } = this.state;
    const { classes } = this.props;
    return (
      <div>
        {value === 0 && (
          <TabContainer>
            <Typography variant="h5" className={classes.paddedHeading}>
              Your current plan
            </Typography>
            <Card elevation={0} style={{ border: '1px solid #eeeeee' }}>
              <CardHeader
                avatar={<img src={cardDetails.type === 'BROADBAND' ? BroadBand : Lightning} />}
                action={
                  <img
                    src={ForwardArrow}
                    style={{ paddingTop: 16, fontWeight: 600 }}
                    onClick={() => {
                      this.props.history.push(
                        routes.service.commonTermsAndConditions.new(
                          //TODO: Resolve any
                          (this.props as any).match.params.propertyId,
                          //TODO: Resolve any
                          (this.props as any).match.params.serviceAccountId,
                        ),
                      );
                    }}
                    height="20"
                    width="20"
                  />
                }
                title={
                  <Typography variant="overline" className={classes.headingContent}>
                    {cardDetails.type}
                  </Typography>
                }
                subheader={
                  <Typography variant="h5" className={classes.valueContent}>
                    {cardDetails.serviceType}
                  </Typography>
                }
              />
              <CardContent className={classes.cardContent}>
                <Grid container justify={'space-between'}>
                  <Grid item xs={6}>
                    {this.titleAndSubtitle('DATA USED', cardDetails.footerData.userData)}
                  </Grid>
                  <Grid item xs={6}>
                    {this.titleAndSubtitle('DATA REMAINING', cardDetails.footerData.remainingData)}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Typography variant="h5" className={classes.paddedHeading}>
              Payment details
            </Typography>
            <Card elevation={0} style={{ border: '1px solid #eeeeee' }}>
              <CardContent className={classes.cardContent}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justify={'space-between'}>
                      <Grid item xs={10}>
                        {this.titleAndSubtitle('PAYMENTS', `$${paymentDetails.payment} per month`)}
                      </Grid>
                      <Grid item xs={2} style={{ alignSelf: 'center' }}>
                        <Typography
                          variant={'subtitle2'}
                          color={'error'}
                          onClick={this.handleAdjustClick}
                        >
                          Adjust
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    {this.titleAndSubtitle(
                      'NEXT PAYMENT DATE',
                      `${paymentDetails.nextPaymentDate}`,
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container justify={'space-between'}>
                      <Grid item xs={10} md={10}>
                        {this.titleAndSubtitle(
                          'CREDIT CARD DETAILS',
                          `${paymentDetails.creditCardDetails}`,
                        )}
                      </Grid>
                      <Grid item xs={2} md={2} style={{ alignSelf: 'center' }}>
                        <Typography
                          variant={'subtitle2'}
                          color={'error'}
                          onClick={this.handleEditClick}
                        >
                          Edit
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Drawer
              anchor="bottom"
              classes={{
                paper: `${classes.bottomDrawerContainer} ${classes.planDetailsRoot}`,
              }}
              open={adjustModalOpen}
              onClose={() => this.setState({ adjustModalOpen: false })}
            >
              <div className={classes.drawerStyle}>
                <Typography variant={'h5'}>{labels.modalTitle}</Typography>
                <Typography variant={'body1'} className={classes.drawerBodyStyle}>
                  <Typography paragraph={true}>{labels.modalSubtitle1}</Typography>
                  <Typography paragraph={true}>{labels.modalSubtitle2}</Typography>
                </Typography>
              </div>
            </Drawer>
          </TabContainer>
        )}
        {value === 1 && <TabContainer>The invoices data go here</TabContainer>}
      </div>
    );
  };

  public titleAndSubtitle = (heading: string, value: string) => {
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={12} className={classes.titleGrid}>
          <Typography variant="overline" className={classes.headingContent}>
            {heading}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.titleGrid}>
          <Typography variant="body1" className={classes.valueContent}>
            {value}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  public render(): ReactNode {
    const { classes } = this.props;
    const { value } = this.state;
    return (
      <div className={classes.root}>
        <AppBar position="static" className={classes.topAppBar}>
          <Tabs
            value={value}
            centered
            onChange={this.handleChange}
            className={classes.topTabs}
            classes={{
              indicator: classes.indicator,
            }}
          >
            <Tab label="PLAN" className={classes.tabs} />
            <Tab label="INVOICES" className={classes.tabs} />
          </Tabs>
        </AppBar>
        {this.renderContent()}
      </div>
    );
  }
}

export default connect(null, null)(withStyles(Styles)(ServicePlan));

import { ID_TYPES } from '../../../../component/services/personalDetails/PersonalDetailConstants';
import { PersonalDetails } from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';

export const isPersonalDetailsCompleted = (personalDetails: PersonalDetails) => {
  const isBasicInfoCompleted = !!(
    personalDetails.salutation &&
    personalDetails.dob &&
    personalDetails.phone
  );
  const isIdDetailsCompleted = personalDetails.idType
    ? isPersonalIdDetailsCompleted(personalDetails)
    : false;
  return !!(isBasicInfoCompleted && isIdDetailsCompleted);
};

export const isPersonalIdDetailsCompleted = (personalDetails: PersonalDetails) => {
  switch (personalDetails.idType) {
    case 'DriversLicence':
      return !!(
        personalDetails.idDetails &&
        personalDetails.idDetails.dlStateIssue &&
        personalDetails.idNumber &&
        personalDetails.idExpiry
      );
    case 'Medicare':
      return !!(
        personalDetails.idDetails &&
        personalDetails.idDetails.medicareRefNumber &&
        personalDetails.idDetails.medicareCardColor &&
        personalDetails.idNumber &&
        personalDetails.idExpiry
      );
    case 'Passport':
      return !!(
        personalDetails.idDetails &&
        personalDetails.idDetails.passportCountry &&
        personalDetails.idNumber &&
        personalDetails.idExpiry
      );
    default:
      return false;
  }
};

export const getDefaultIdExpiry = () => {
  const currentYear = new Date().getFullYear();
  const date = new Date(currentYear + 2, 0, 1);
  return date;
};

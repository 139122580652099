import { AGENCIES, APP } from '../../helper/AppNameHelper';
import stressFreeNax from '../../assets/carousel/service-setup-nax.png';
import stressFreeSorted from '../../assets/carousel/service-setup-sorted.png';
import maintenance from '../../assets/carousel/maintenance.png';
import onePlaceNax from '../../assets/carousel/one-place-nax.png';
import onePlaceSorted from '../../assets/carousel/one-place-sorted.png';

export const LABELS = {
  STRESS_FREE_TITLE: 'Stress-free services setup',
  STRESS_FREE_TEXT: (external?: boolean) =>
    `Get all of your home services setup in just a few steps through ${
      external
        ? 'your app.'
        : APP === AGENCIES.NAX
        ? 'the Nelson Alexander App.'
        : 'the Sorted App.'
    }`,
  SIMPLE_MAINTENANCE_TITLE: 'Simple maintenance',
  SIMPLE_MAINTENANCE_TEXT: 'Easily request maintenance or repair jobs for your new home or rental',
  ONE_PLACE_TITLE: 'Leave it to us!',
  ONE_PLACE_TEXT:
    'We will pass on the relevant information for you. For utilities, we will contact you if there is anything else required, if requesting a quote, expect the service provider to get in touch with you!',
  REMIND: 'Close',
  NEXT: 'Next',
  GET_STARTED: 'Get started',
  CHOOSE_YOUR_SERVICES: 'Simply scroll through the list of retailers and press "add to cart"',
  ANSWER_FEW_QUESTIONS: 'Answer a few questions',
  ANSWER_FEW_QUESTIONS_TEXT:
    'We have most of the information required to choose a retailer, however, we may need to ask a few more questions.',
};

export const getCarouselItems = (external?: boolean) => {
  const sortedCarouselItems = [
    {
      title: LABELS.STRESS_FREE_TITLE,
      image: stressFreeSorted,
      text: LABELS.CHOOSE_YOUR_SERVICES,
    },
    {
      title: LABELS.ANSWER_FEW_QUESTIONS,
      image: maintenance,
      text: LABELS.ANSWER_FEW_QUESTIONS_TEXT,
    },
    {
      title: LABELS.ONE_PLACE_TITLE,
      image: onePlaceSorted,
      text: LABELS.ONE_PLACE_TEXT,
    },
  ];

  const naxCarouselItems = [
    {
      title: LABELS.STRESS_FREE_TITLE,
      image: stressFreeNax,
      text: LABELS.CHOOSE_YOUR_SERVICES,
    },
    {
      title: LABELS.ANSWER_FEW_QUESTIONS,
      image: maintenance,
      text: LABELS.ANSWER_FEW_QUESTIONS_TEXT,
    },
    {
      title: LABELS.ONE_PLACE_TITLE,
      image: onePlaceNax,
      text: LABELS.ONE_PLACE_TEXT,
    },
  ];

  return APP === AGENCIES.NAX ? naxCarouselItems : sortedCarouselItems;
};

import { ServiceTypes } from '../../models/checkout/Checkout';

export const LABELS = {
  BUTTONTEXT: 'FINISH',
  HEADINGTEXT: ' setup complete!',
  RENT_SORTED:
    'Nice work —  now it’s time to book a mover and get all your home services connected in under 5 minutes. Don’t forget about your $25 wallet credit to spend on your first Sorted service!',
  RENT_NAX:
    'Nice work —  now it’s time to book a mover and get your belongings covered with contents insurance all in under 5 minutes. ',
  SERVICESUBHEADINGTEXT: `Track the connection progress of your service from the services tab in your home screen. Did you know you’ve unlocked your unique referral code? Tap on wallet and "Refer a Friend" —  you'll both get $25 credit to spend on your Sorted services!`,
  ROUTECHECK: 'rent',
  OTHERSERVICEHEADING: 'All done!',
  OTHERSERVICETEXT:
    'Nice work, we’ve sent your details over to the supplier —  you’ll hear from them soon. Time to get sorted with more services!',
  KEEP_GOING: 'Keep going',
  FINISH: 'Finish',
  LATER: "I'll do it later",
  SERVICE_SETUP: 'Browse marketplace services',
  NAX_SERVICE_SETUP: 'Browse services',
};

export const rentDefaultServiceTypes = [
  ServiceTypes.Rent,
  ServiceTypes.Electricity,
  ServiceTypes.Broadband,
  ServiceTypes.Gas,
];

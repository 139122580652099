import * as React from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  withStyles,
  Drawer,
} from '@material-ui/core';
import { PaymentStyle, styles } from './PaymentDetailsStyles';
import { PAYMENT_LABELS, transactionFeeType, modalLables } from './PaymentdetailsConstants';
import ADD_NEW_ICON from '../../assets/images/payment/filled.png';
import { routes } from '../../Routes';
import { GetPaymentMethodsResponse } from '../../services/payment/getPaymentMethods/GetPaymentMethods.data';
import selectedIcon from '../../assets/images/payment/filled.svg';
import { BackHelper } from '../../helper/BackHelper';
import { GetPaymentTypeResponse } from '../../services/payment/getPaymentTypes/GetPaymentTypesResponse.data';
import { paymentTypes } from '../wallet/savedPaymentMethods/SavedPaymentMethodsConstants';

interface OwnStates {
  payment: GetPaymentMethodsResponse | boolean;
  openModal: boolean;
  paymentMethodList: {
    maxHeight: number;
    overflow: string;
  };
}

interface PropsFromRoute {
  history: any;
}

interface PropsFromParent {
  saveSelectedMethod: (payment: GetPaymentMethodsResponse) => void;
  handleNext: (payment: GetPaymentMethodsResponse) => void;
  paymentMethods: GetPaymentMethodsResponse[];
  propertyId: string;
  serviceType: string;
  leaseId: string;
  agencyName: string;
  paymentRefId: string;
  index: boolean | number;
  selectedPaymentIndex: number;
  handleIndex: (index: number) => void;
  serviceAccountId: string;
  paymentTypes: GetPaymentTypeResponse[];
}

type OwnProps = PropsFromRoute & PropsFromParent & PaymentStyle;

class PaymentDetails extends React.Component<OwnProps, OwnStates> {
  constructor(props: any) {
    super(props);
    this.state = {
      payment: false,
      openModal: false,
      paymentMethodList: {
        maxHeight: 0,
        overflow: 'scroll',
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.renderSelectedMethodTextValue = this.renderSelectedMethodTextValue.bind(this);
    this.renderSavedItems = this.renderSavedItems.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.handlePaymentMethodId = this.handlePaymentMethodId.bind(this);
  }

  public componentDidMount() {
    this.setState({
      paymentMethodList: {
        maxHeight: window.innerHeight - 200,
        overflow: 'scroll',
      },
    });
  }
  public componentDidUpdate() {
    const maxHeight = window.innerHeight - 200;
    const minHeight = window.innerHeight - 300;
    if (
      this.state.paymentMethodList.maxHeight < maxHeight &&
      this.state.paymentMethodList.maxHeight !== minHeight
    ) {
      this.setState({
        paymentMethodList: {
          maxHeight: window.innerHeight - 200,
          overflow: 'scroll',
        },
      });
    }
  }

  public render() {
    const { classes, paymentMethods } = this.props;
    const { paymentMethodList } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <div>
            <Typography variant="h5" className={classes.paymentLabelStyle}>
              {PAYMENT_LABELS.paymentLabel}
            </Typography>
            <Typography variant="subtitle1" gutterBottom className={classes.savedPaymentLabelStyle}>
              {PAYMENT_LABELS.savedPaymentLabel}
            </Typography>
          </div>
          <List className={classes.listStyle} style={paymentMethodList}>
            {/* TODO PAYMENT_LABLES are replaced by saved payment api data */}
            {paymentMethods &&
              paymentMethods.map((paymentMethod: GetPaymentMethodsResponse, index: number) =>
                this.renderSavedItems(paymentMethod, index),
              )}
            <ListItem button className={classes.listItemStyle} onClick={this.openAddPayments}>
              <ListItemAvatar className={classes.iconStyle}>
                <Avatar alt="Add New" src={ADD_NEW_ICON} />
              </ListItemAvatar>
              <ListItemText
                className={classes.itemTextStyle}
                primary={
                  <Typography variant="subtitle1" className={classes.listItemSubtitle}>
                    {PAYMENT_LABELS.addNewLabel}
                  </Typography>
                }
              />
            </ListItem>
          </List>
          {this.renderFooter()}
        </div>
        <Drawer
          anchor="bottom"
          classes={{
            paper: classes.bottomDrawerContainer,
          }}
          className={classes.bottomDrawerContainer}
          open={this.state.openModal}
          onClose={this.toggleDrawer.bind(this, false)}
        >
          {this.renderPaymentTypeDisabledDrawer()}
        </Drawer>
      </div>
    );
  }

  public renderPaymentTypeDisabledDrawer = () => {
    const { classes } = this.props;
    return (
      <div className={classes.bottomDrawer}>
        <Typography className={classes.bottomDrawerTitle}>{modalLables.title}</Typography>
        <Typography variant="body2" className={classes.disabledMessage}>
          {modalLables.subTitle}
        </Typography>
        <Button
          onClick={this.toggleDrawer.bind(this, false)}
          className={classes.buttonPaymentDisabled}
          variant="outlined"
        >
          <Typography variant="button">{modalLables.buttonLabel}</Typography>
        </Button>
      </div>
    );
  };

  public renderFooter() {
    const { classes, selectedPaymentIndex, index } = this.props;
    const disableButton = selectedPaymentIndex === index;
    const disabledClass =
      disableButton || index === false
        ? classes.disableButtonTextStyle
        : classes.activeButtonTextStyle;
    return (
      <div className={classes.messageBox}>
        {this.renderSelectedMethodTextValue()}
        <Button
          fullWidth
          color="primary"
          className={classes.buttonStyle}
          disabled={disableButton || index === false}
          onClick={this.handleNext}
        >
          <Typography variant="button" className={disabledClass}>
            {PAYMENT_LABELS.buttonLabel}
          </Typography>
        </Button>
      </div>
    );
  }

  public renderSavedItems(payment: GetPaymentMethodsResponse, index: number) {
    const { classes } = this.props;
    const paymentMethodDetails =
      payment.paymentMethod === paymentTypes.Bank
        ? PAYMENT_LABELS.paymentMethodDetails.Bank
        : PAYMENT_LABELS.paymentMethodDetails.Credit.find(
            (data: any) => data.cardType === payment.cardType,
          );
    return (
      <ListItem
        button
        onClick={() => this.handleChange(payment, index)}
        className={classes.listItemStyle}
      >
        <ListItemAvatar
          className={
            payment.paymentMethod === paymentTypes.Bank ? classes.iconStyle : classes.cardIconStyle
          }
        >
          <img src={paymentMethodDetails.logo} />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          className={classes.itemTextStyle}
          primary={
            <Typography variant="caption" className={classes.listItemTitle}>
              {paymentMethodDetails.name}
            </Typography>
          }
          secondary={
            <Typography variant="subtitle1" className={classes.listItemSubtitle}>
              {this.handlePaymentMethodId(payment)}
            </Typography>
          }
        />
        <ListItemSecondaryAction>
          {index === this.props.index && <img src={selectedIcon} />}
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  public handlePaymentMethodId(payment: GetPaymentMethodsResponse) {
    switch (payment.paymentMethod) {
      case 'Bank': {
        return `${payment.bsb} - ${payment.accountNumber}`;
      }
      case 'Credit': {
        return `${payment.cardNumber.replace(/\./g, '*')}`;
      }
      default: {
        return '**** **** **** 1234';
      }
    }
  }

  public renderSelectedMethodTextValue() {
    const { classes } = this.props;
    const payment = this.state.payment as GetPaymentMethodsResponse;
    const paymentMethodEnabled = this.props.paymentTypes.find(
      (paymentType: GetPaymentTypeResponse) => paymentType.paymentMethod === payment.paymentMethod,
    );
    if (paymentMethodEnabled) {
      const paymentMethod = paymentMethodEnabled;
      const paymentMethodDetails = PAYMENT_LABELS.extraPayText.find(
        (data: any) => data.type === paymentMethod.paymentMethod,
      );
      if (!paymentMethodEnabled.transactionFeeValue) {
        return (
          <div className={classes.transactionFeeContainerStyle}>
            <Typography variant="body2" className={classes.transactionFeeStyle}>
              {paymentMethodDetails && paymentMethodDetails.zeroValueMessage}
            </Typography>
          </div>
        );
      } else {
        const transactionFee =
          paymentMethod.transactionFeeType === transactionFeeType.Flat
            ? `$${paymentMethod.transactionFeeValue}`
            : `${paymentMethod.transactionFeeValue}%`;
        return (
          <div className={classes.transactionFeeContainerStyle}>
            <Typography variant="body2" className={classes.transactionFeeStyle}>
              {paymentMethodDetails && paymentMethodDetails.value(transactionFee)}
            </Typography>
          </div>
        );
      }
    } else {
      return <div />;
    }
  }

  public handleChange(payment: GetPaymentMethodsResponse, index: number) {
    const isPaymentMethodEnabled = this.props.paymentTypes.find(
      (paymentType: GetPaymentTypeResponse) => paymentType.paymentMethod === payment.paymentMethod,
    );
    if (isPaymentMethodEnabled) {
      this.props.handleIndex(index);
      this.setState({ payment });
    } else {
      this.toggleDrawer(true);
    }
    this.setState({
      payment,
      paymentMethodList: {
        maxHeight: window.innerHeight - 300,
        overflow: 'scroll',
      },
    });
  }

  private openAddPayments = () => {
    const { propertyId, serviceType, paymentRefId, serviceAccountId, agencyName } = this.props;
    BackHelper.saveCount(0);
    this.props.history.push(
      routes.payment.list.empty(
        propertyId,
        serviceType,
        paymentRefId,
        serviceAccountId,
        agencyName,
      ),
    );
  };

  private handleNext = () => {
    this.props.handleNext(this.state.payment as GetPaymentMethodsResponse);
  };

  private toggleDrawer(openModal: boolean) {
    this.setState({ openModal });
  }
}

export default withStyles(styles)(PaymentDetails);

import { color } from '../../theme/Theme';
import { WithStyles, createStyles } from '@material-ui/core';

export const FooterStyle = createStyles({
  footer: {
    bottom: 0,
    left: 0,
    right: 0,
    padding: '16px 16px 16px 16px',
    position: 'fixed',
    backgroundColor: color.light,
  },
  button: {
    'height': 45,
    'background': color.buttonBackground,
    '&:hover': {
      backgroundColor: color.buttonBackground,
    },
  },
  buttonText: {
    color: color.light,
  },
  disabledButtonText: {
    color: color.secondary300,
  },
});

export interface FooterComponentStyle extends WithStyles<typeof FooterStyle> {}

import * as React from 'react';
import MaskedInput from 'react-text-mask';

interface CustomInputProps {
  readonly inputRef: (ref: HTMLInputElement | null) => void;
}

type patternType = string | RegExp;

/**
 * @author Dhruv Soni
 * @name CustomInput
 * @description Use this component to mask your input in specific format. ex : date, credit-card number
 * @param {patternType[]} pattern - ex: [/\d/,/\d/,/\d/,"-",/\d/,/\d/,/\d/] for /^\d{3}-?\d{3}$/
 * @link https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#guide
 * @returns JSX.element
 */
function CustomInput(pattern: patternType[]) {
  return (props: CustomInputProps) => {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref: any) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={pattern}
        guide={false}
      />
    );
  };
}

export default CustomInput;

import { TokenState } from '../state/TokenState';
import { TokenAction, TokenActionTypes } from '../actions/TokenActions';

const initialState: TokenState = {
  accessToken: false,
  refreshToken: false,
  appName: 'SORTED',
  userId: '',
  isExternal: false,
};

export const tokenReducer = (state = initialState, action: TokenAction) => {
  switch (action.type) {
    case TokenActionTypes.SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.data as string,
      };
    case TokenActionTypes.SET_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.data as string,
      };
    case TokenActionTypes.SET_APP_NAME:
      return {
        ...state,
        appName: action.data as string,
      };
    case TokenActionTypes.SET_USER_ID:
      return {
        ...state,
        userId: action.data as string,
      };
    case TokenActionTypes.SET_EXTERNAL:
      return {
        ...state,
        isExternal: action.data as boolean,
      };

    default:
      return state;
  }
};

import * as React from 'react';
import InputComponent from './InputComponent';
import SelectComponent from './SelectComponent';
import { Formik } from 'formik';
import { states, streetTypes, validationSchema } from './AddressFormConstants';
import FooterComponent from '../../../footer/FooterComponent';
import { Typography, withStyles } from '@material-ui/core';
import { Styles, AddressFormStyles } from './AddressFormStyles';
import { PropertyDetails } from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';

interface PropsFormParent {
  propertyDetails: PropertyDetails;
  handleSubmit: (property: initialValuesState) => void;
}
export interface initialValuesState {
  unitNumber: string;
  streetNumber: string;
  streetName: string;
  streetType: string;
  suburb: string;
  postcode: string;
  state: string;
  country: string;
}
type OwnPorps = AddressFormStyles & PropsFormParent;
class AddressForm extends React.Component<OwnPorps> {
  public render() {
    const { classes, propertyDetails } = this.props;
    const initialValues: initialValuesState = {
      unitNumber: propertyDetails.unitNumber || '',
      streetNumber: propertyDetails.streetNumber || '',
      streetName: propertyDetails.streetName || '',
      streetType: propertyDetails.streetType || '',
      suburb: propertyDetails.suburb || '',
      postcode: propertyDetails.postcode || '',
      state: propertyDetails.state || '',
      country: 'Australia',
    };

    return (
      <div className={classes.root}>
        <Typography variant="h5">Enter address</Typography>
        <Formik
          initialValues={initialValues}
          isInitialValid
          onSubmit={(values) => this.props.handleSubmit(values)}
          validationSchema={validationSchema}
          render={({ values, setFieldValue, isValid, handleSubmit }) => (
            <div className={classes.formRoot}>
              <div className={classes.formBox}>
                <InputComponent
                  label="Unit no"
                  value={values.unitNumber}
                  setFieldValue={setFieldValue}
                  name="unitNumber"
                  type="text"
                  style={classes.leftBoxStyle}
                />
                <InputComponent
                  label="Street no"
                  value={values.streetNumber}
                  setFieldValue={setFieldValue}
                  name="streetNumber"
                  type="text"
                  style={classes.rightBoxStyle}
                />
              </div>
              <InputComponent
                label="Street name"
                value={values.streetName}
                setFieldValue={setFieldValue}
                name="streetName"
                type="text"
              />
              <SelectComponent
                label="Street type"
                name="streetType"
                selectValues={streetTypes}
                value={values.streetType}
                setFieldValue={setFieldValue}
              />
              <InputComponent
                label="Suburb"
                value={values.suburb}
                setFieldValue={setFieldValue}
                name="suburb"
                type="text"
              />
              <div className={classes.formBox}>
                <InputComponent
                  label="Postcode"
                  value={values.postcode}
                  setFieldValue={setFieldValue}
                  name="postcode"
                  type="text"
                  inputProps={{
                    maxLength: 4,
                  }}
                  style={classes.leftBoxStyle}
                />
                <SelectComponent
                  label="State"
                  name="state"
                  selectValues={states}
                  value={values.state}
                  setFieldValue={setFieldValue}
                  style={classes.rightBoxStyle}
                />
              </div>
              <InputComponent
                label="Country"
                value={values.country}
                setFieldValue={setFieldValue}
                name="country"
                type="text"
              />
              <FooterComponent buttonText="Next" disabled={!isValid} onClick={handleSubmit} />
            </div>
          )}
        />
      </div>
    );
  }
}

export default withStyles(Styles)(AddressForm);

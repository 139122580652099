import { createStyles, WithStyles } from '@material-ui/core';
import { color } from '../../theme/Theme';

export const Styles = () =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: 'white',
      paddingTop: 50,
    },
    paddedHeading: {
      fontSize: '1.2em',
      padding: '1em 0 1em 0',
    },
    bottomDrawerContainer: {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    planDetailsRoot: {
      backgroundColor: color.secondary,
    },
    cardContent: {
      padding: '11px !important',
      paddingLeft: '15px !important',
    },
    drawerStyle: {
      padding: '1em',
      height: 300,
    },
    drawerBodyStyle: {
      padding: '50 0 50 0',
    },
    topAppBar: {
      boxShadow: 'none',
      backgroundColor: 'white',
      width: '100%',
    },
    topTabs: {
      backgroundColor: 'white',
      color: '#2D1B49',
      fontFamily: 'GT Walsheim',
      fontWeight: 600,
    },
    titleGrid: {
      height: '2em',
    },
    headingContent: {
      fontSize: '0.9em !important',
      color: 'darkgrey',
      fontWeight: 500,
    },
    valueContent: {
      fontSize: '1.17em !important',
    },
    tabs: {
      minWidth: 150,
      width: 150,
    },
    indicator: {
      backgroundColor: '#2D1B49',
    },
  });

export interface ServicePlanStyle extends WithStyles<typeof Styles> {}

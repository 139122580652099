import { color } from '../../../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  cantFindText: {
    margin: '142px 0px 0px 12px',
    color: color.lightBlue,
    cursor: 'pointer',
  },
  hide: {
    display: 'none',
  },
});

export interface AddPropertyStyles extends WithStyles<typeof styles> {}

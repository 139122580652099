export default interface Property {
  address?: string;
  country?: string;
  id?: number;
  latitude?: string;
  longitude?: string;
  postCode?: string;
  propertyType?: string;
  state?: string;
  streetName?: string;
  streetNumber?: string;
  streetType?: string;
  suburb?: string;
  unitNumber?: string;
  name?: string;
  agencyCode?: string;
}

export interface AddressComponents {
  unitNumber: string;
  streetNumber: string;
  streetName: string;
  streetType: string;
  suburb: string;
  postcode: string;
  state: string;
  country: string;
}

export enum PropertyType {
  Apartment = 'Apartment & Unit',
  Villa = 'Villa',
  Home = 'Home',
  Townhouse = 'Townhouse',
}

export enum PropertyClass {
  Tenant = 'Tenant',
  Owner = 'Owner occupier',
  Landlord = 'Landlord',
  Accomodation = 'Accomodation',
}

export interface PostRequest {
  selectedClass: PropertyClass;
  selectedType: PropertyType;
  address: AddressComponents;
  userId: string;
}

export interface OnboardingPropertyDetails {
  address: AddressComponents;
  selectedType: PropertyType | undefined;
  selectedClass: PropertyClass | undefined;
}
export interface SetOnboardingPropertyDetails {
  field: string;
  value: AddressComponents | PropertyClass | PropertyType;
}

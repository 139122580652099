import { History } from 'history';
import { BackHelper } from '../../../helper/BackHelper';
import { sendMessageToApp, ValidMessages } from '../../../helper/MessageHelper';
import { routes } from '../../../Routes';
import { ServiceType } from '../../services/ServiceConstants';
import { MatchParams } from './PaymentDetailsConstants';

export const handlePaymentTypeSuccess = (
  isPaymentMethods: boolean,
  history: History,
  matchParams: MatchParams,
) => {
  const { propertyId, serviceType, agencyName, paymentRefId, serviceAccountId } = matchParams;

  if (!isPaymentMethods) {
    history.replace(
      routes.payment.list.empty(
        propertyId,
        serviceType,
        paymentRefId,
        serviceAccountId,
        agencyName,
      ),
    );
  }
};

export const handlePaymentSuccess = (history: History, matchParams: MatchParams) => {
  const { serviceType } = matchParams;

  switch (serviceType) {
    case ServiceType.Electricity:
      history.push(routes.setup.electricity);
      break;
    case ServiceType.Broadband:
      history.push(routes.setup.broadband);
      break;
    case ServiceType.EditRent:
      sendMessageToApp(ValidMessages.Dashboard);
      break;
    case ServiceType.EditElectricity:
      sendMessageToApp(ValidMessages.Dashboard);
      break;
    case ServiceType.EditBroadband:
      sendMessageToApp(ValidMessages.Dashboard);
      break;

    default:
      break;
  }
};

export const saveUrlParams = (
  electricityServiceId: number | undefined,
  broadbandServiceAccountId: number | undefined,
  matchParams: MatchParams,
) => {
  const { propertyId, paymentRefId, serviceAccountId, serviceType, agencyName } = matchParams;
  if (!serviceAccountId && serviceType === ServiceType.Electricity) {
    BackHelper.saveParams({
      propertyId,
      paymentRefId,
      serviceAccountId: (electricityServiceId as number).toString(),
    });
  } else if (!serviceAccountId && serviceType === ServiceType.Broadband) {
    BackHelper.saveParams({
      propertyId,
      paymentRefId,
      serviceAccountId: (broadbandServiceAccountId as number).toString(),
    });
  } else {
    BackHelper.saveParams({
      propertyId,
      paymentRefId,
      serviceAccountId,
      agencyName,
    });
  }
};

export const openAddPayments = (history: History, matchParams: MatchParams) => {
  const { propertyId, serviceType, paymentRefId, serviceAccountId, agencyName } = matchParams;
  BackHelper.saveCount(0);
  history.push(
    routes.payment.list.empty(propertyId, serviceType, paymentRefId, serviceAccountId, agencyName),
  );
};

import * as React from 'react';
import PlanScreenComponent from '../../../../component/services/plan/electricity/PlanScreen';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/RootReducers';
import { Dispatch, AnyAction } from 'redux';
import { PlanState } from '../../../../store/state/PlanState';
import { PlanActions } from '../../../../store/actions/PlanActions';
import { PlanDetailsSubmit } from '../../../../models/plan/electricity/postPlanDetails/PlanDetailsSubmit.data';
import { PlanDetails } from '../../../../models/plan/electricity/getPlanDetails/PlanDetails.data';
import { PlanRequest } from '../../../../services/plan/getPlanDetails/GetPlanDetails.data';
import {
  ElectricityServiceDetails,
  PropertyDetails,
  ElectricityPlanDetails,
  ServiceAccount,
} from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { createElectricityPlanRequest, createPlanDetailsSubmitObject } from './PlanConstants';
import { BackHelper } from '../../../../helper/BackHelper';
import { RouteComponentProps } from 'react-router';

interface PropsFromState {
  plan: PlanState;
  property: PropertyDetails;
  electricityPlan: ElectricityPlanDetails;
  serviceAccountId: number;
}

interface PropsFromDispatch {
  getPlanDetails: (plan: PlanRequest) => void;
  savePlan: (data: PlanDetailsSubmit) => void;
}

type OwnProps = PropsFromDispatch &
  PropsFromState &
  RouteComponentProps<{
    propertyId: string;
    paymentRefId: string;
  }>;

class PlanScreen extends React.Component<OwnProps> {
  public componentDidMount() {
    const { propertyId, paymentRefId } = this.props.match.params;
    const { property, serviceAccountId } = this.props;
    BackHelper.saveParams({
      propertyId,
      paymentRefId,
      serviceAccountId: serviceAccountId.toString(),
    });
    const plan: PlanRequest = createElectricityPlanRequest(
      property.postcode,
      property.keyIdentifier,
    );
    this.props.getPlanDetails(plan);
  }

  public render() {
    return (
      <PlanScreenComponent
        {...this.props}
        planDetails={this.props.plan.planDetails as PlanDetails}
        handleNext={this.handleNext}
        averageUsage={this.getAverageUsage()}
      />
    );
  }

  private handleNext = (choice: number) => {
    const details = this.props.plan.planDetails as PlanDetails;
    const averageUsage = this.getAverageUsage();
    const { electricityPlan } = this.props;
    const data: PlanDetailsSubmit = createPlanDetailsSubmitObject(
      details,
      choice,
      electricityPlan,
      averageUsage[choice],
    );
    BackHelper.saveCount(-1);
    this.props.savePlan(data);
  };

  private getAverageUsage = () => {
    const { planDetails } = this.props.plan;
    const annualUsage = parseFloat((planDetails as PlanDetails).annualUsage);
    const lowAverageUsage = ((annualUsage * 0.7) / 12).toFixed(2);
    const moderateAverageUsage = (annualUsage / 12).toFixed(2);
    const highAverageUsage = ((annualUsage * 1.3) / 12).toFixed(2);
    return [lowAverageUsage, moderateAverageUsage, highAverageUsage];
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    plan: state.plan,
    property: (state.electricityService.service as ElectricityServiceDetails).serviceDetails
      .property as PropertyDetails,
    electricityPlan: (state.electricityService.service as ElectricityServiceDetails).serviceDetails
      .electricityPlanDetails as ElectricityPlanDetails,
    serviceAccountId: (
      (state.electricityService.service as ElectricityServiceDetails)
        .serviceAccount as ServiceAccount
    ).id as number,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): PropsFromDispatch {
  return {
    getPlanDetails: (plan: PlanRequest) => {
      dispatch(PlanActions.getPlanDetailsStart(plan));
    },
    savePlan: (data: PlanDetailsSubmit) => dispatch(PlanActions.saveChoosenPlan(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanScreen);

import ApiServiceBase from '../ApiServiceBase';
import { ServiceType } from '../ServiceType.data';
import { AppSettings } from '../../AppSettings';
import ApiServiceMock from '../__mocks__/ApiServiceMock';
import ApiService from '../ApiService';
import {
  AddressLookup,
  BroadbandAddressLookup,
} from '../broadbandService/getBroadbandAddressLookup/GetBroadbandAddressLookup.data';
import { ElectricityAddressLookup } from '../electricityService/getElectricityAddressLookup/GetElectricityAddressLookup.data';

const serviceType = ServiceType.AddressLookup;

export class AddressLookupService {
  private readonly service: ApiServiceBase = AppSettings.currentTestSetting.testing
    ? new ApiServiceMock(serviceType)
    : new ApiService(serviceType);
  public postAddressLookupBroadbandService(
    data: AddressLookup,
  ): Promise<BroadbandAddressLookup> | BroadbandAddressLookup {
    return this.service.post(
      {
        route: ['broadband/addresses'],
      },
      data,
    );
  }
  public postAddressLookupElectricityService(
    property: AddressLookup,
  ): Promise<ElectricityAddressLookup> | ElectricityAddressLookup {
    const data = property;
    return this.service.post(
      {
        route: ['electricity/nmi'],
      },
      data,
    );
  }

  public postAddressLookupElectricityServiceCommon(
    property: AddressLookup,
  ): Promise<ElectricityAddressLookup> | ElectricityAddressLookup {
    const data = property;
    return this.service.post(
      {
        route: ['/lookup-service-address'],
      },
      data,
    );
  }
}

const addressLookupService = new AddressLookupService();
export default addressLookupService;

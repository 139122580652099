import { WithStyles, createStyles } from '@material-ui/core';

export const styles = createStyles({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginTop: 124,
    padding: 16,
    height: '100vh',
  },
  accountNameContainer: {
    marginTop: 16,
  },
  inputContainer: {
    marginTop: 36,
  },
});

export interface BankDetailsStyles extends WithStyles<typeof styles> {}

import * as React from 'react';
import { FormControl, InputLabel, Input, InputAdornment, Typography } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import * as Labels from './ConcessionCardFormConstants';
import { Styles } from './ConcessionCardFormStyles';
import { Formik } from 'formik';
import errorIcon from '../../../assets/error-outline-24-px.svg';
import cardIcon from '../../../assets/credit-card-24-px.svg';
import calendarIcon from '../../../assets/outlined.svg';
import Footer from '../../footer/FooterComponent';
import { routes } from '../../../Routes';
import { dateValidator } from '../../../helper/DateValidator';
import CustomInput from '../../customInput/CustomInput';
import { ConcessionCardDetails } from '../../../services/electricityService/getElectricityService/GetElectricityService.data';

interface OwnStates {
  nameError: boolean;
  cardNumberError: boolean;
  expiryError: boolean;
}
interface InitialValues {
  readonly name: string;
  readonly cardNumber: string;
  readonly expiry: string;
}

interface PropsFromParent {
  handleSubmit: (concessionCard: ConcessionCardDetails) => void;
  cardType: string;
  propertyId: string;
}
export interface Props extends WithStyles<typeof Styles> {}

type OwnProps = Props & PropsFromParent;

class ConcessionCardForm extends React.Component<OwnProps, OwnStates> {
  private cardExpiryValidator = CustomInput(Labels.expiryDatePattern);

  constructor(props: OwnProps) {
    super(props);
    this.state = {
      nameError: false,
      cardNumberError: false,
      expiryError: false,
    };
  }
  public render() {
    const { classes, cardType } = this.props;
    const { nameError, cardNumberError, expiryError } = this.state;
    const initialValues: InitialValues = {
      name: '',
      cardNumber: '',
      expiry: '',
    };
    const concessionCard = Labels.concessionCardTitle.find(
      (data: Labels.ConcessionCardTitle) => data.type === cardType,
    ) as Labels.ConcessionCardTitle;
    return (
      <div className={classes.root}>
        <div className={classes.titleStyle}>
          <Typography variant="h5">{concessionCard.title}</Typography>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => this.handleSubmit(values)}
          validationSchema={Labels.Validation}
          render={({ values, setFieldValue, handleSubmit, isValid }) => (
            <div className={classes.form}>
              <FormControl className={classes.inputs} error={nameError}>
                <InputLabel>Name on card</InputLabel>
                <Input
                  name="name"
                  type="text"
                  onChange={(event) => setFieldValue('name', event.target.value)}
                  value={values.name}
                />
              </FormControl>
              <FormControl className={classes.inputs} error={cardNumberError}>
                <InputLabel>Card Number</InputLabel>
                <Input
                  name="cardNumber"
                  onChange={(event) => setFieldValue('cardNumber', event.target.value)}
                  value={values.cardNumber}
                  endAdornment={
                    <InputAdornment position="end">
                      <img src={cardNumberError ? errorIcon : cardIcon} />
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl className={classes.inputs} error={expiryError}>
                <InputLabel>Expiry Date MM/YY</InputLabel>
                <Input
                  name="expiry"
                  value={values.expiry}
                  inputComponent={this.cardExpiryValidator as any}
                  onChange={(event) => {
                    this.expiryDateValidation(event.target.value);
                    setFieldValue('expiry', event.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <img src={calendarIcon} />
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Footer
                disabled={
                  this.state.expiryError ||
                  this.state.cardNumberError ||
                  this.state.nameError ||
                  !isValid
                }
                buttonText="Save"
                redirectLink={routes.summary.new(this.props.propertyId, 'electricity')}
                onClick={handleSubmit}
              />
            </div>
          )}
        />
      </div>
    );
  }
  private expiryDateValidation = (date: string) => {
    this.setState({ expiryError: dateValidator(date, 'concessionCard') });
  };
  private handleSubmit = (values: InitialValues) => {
    let cardType = '';
    switch (this.props.cardType) {
      case Labels.CardType.healthCare:
        cardType = Labels.CardTypeValue.healthCareCard;
        break;
      case Labels.CardType.pensionerCard:
        cardType = Labels.CardTypeValue.pensionerCard;
        break;
      case Labels.CardType.glodCard:
        cardType = Labels.CardTypeValue.glodCard;
        break;
    }
    const expiry = values.expiry.split('/');
    const requestData: ConcessionCardDetails = {
      cardType,
      name: values.name,
      cardNumber: values.cardNumber,
      expiryMonth: expiry[0],
      expiryYear: `20${expiry[1]}`,
    };

    this.props.handleSubmit(requestData);
  };
}

export default withStyles(Styles)(ConcessionCardForm);

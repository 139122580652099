import { takeEvery, put } from 'redux-saga/effects';
import { LeaseAction, LeaseActions, LeaseActionTypes } from '../actions/LeaseAction';
import leaseService from '../../services/lease/LeaseService';
import { SpinnerActions } from '../actions/SpinnerActions';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';

export function* getLeaseSaga(action: LeaseAction): IterableIterator<any> {
  try {
    yield put(SpinnerActions.show());
    const response = yield leaseService.getLease();
    yield put(LeaseActions.getLeaseSuccess(response));
  } catch (error) {
    yield put(SpinnerActions.hide());
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(LeaseActions.getLeaseError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

function* leaseWatcher(): IterableIterator<any> {
  yield takeEvery(LeaseActionTypes.GET_LEASE_REQUEST, (action) =>
    getLeaseSaga({
      type: action.type,
    }),
  );
}
export default leaseWatcher;

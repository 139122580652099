import React, { FC, useEffect, useState, useMemo, useRef } from 'react';
import { withStyles } from '@material-ui/core';
import clock from '../../../../assets/error-bold.png';
import tick from '../../../../assets/radio-filled.png';
import tickBlack from '../../../../assets/radio-filled-black.png';
import downArrow from '../../../../assets/downArrow.png';
import { styles, PaymentItemStyles } from './PaymentItemStyles';
import { LABELS } from './PaymentItemConstants';
import { AvailableServicesResponse } from '../../../../models/checkout/Checkout';
import PaymentSection from '../PaymentSection/PaymentSection';
import { PaymentGateway } from '../../../services/commonServices/CommonPlanSelectionInterface';
import StripeWrapper from '../StripeSection/StripeWrapper';
import { AGENCIES, APP } from '../../../../helper/AppNameHelper';

interface PaymentItemProps extends PaymentItemStyles {
  activeTab: number;
  cartItem: AvailableServicesResponse;
  selectedPaymentRefId: string;
  paymentType: PaymentGateway | string | null;
  setPaymentMethod: (refId: string | null) => void;
  validate: boolean;
}

const PaymentItem: FC<PaymentItemProps> = ({
  classes,
  activeTab,
  cartItem,
  selectedPaymentRefId,
  paymentType,
  setPaymentMethod,
  validate,
}) => {
  const paymentItemRef = useRef<HTMLDivElement>(null);
  const [paymentItemHeight, setPaymentItemHeight] = useState<number>(0);
  const paymentCompleted = !!cartItem.complete && !!cartItem.complete.payment;

  return (
    <div className={`${classes.mainContainer} ${validate && !paymentCompleted ? 'errorCard' : ''}`}>
      <div className={classes.serviceHeader} onClick={() => null}>
        <div className={classes.serviceTypeIconContainer}>
          <img className={classes.serviceTypeIcon} src={cartItem.logo} alt="service type" />
        </div>
        <div className={classes.serviceTypeTitle}>{cartItem.type}</div>
        <div className={classes.cardIconContainer}>
          <img
            className={classes.cardIcon}
            src={paymentCompleted ? (APP === AGENCIES.NAX ? tickBlack : tick) : clock}
            alt="status"
          />
        </div>
      </div>

      <div
        className={classes.content}
        ref={paymentItemRef}
        style={{
          maxHeight: `${paymentItemHeight}px`,
          opacity: paymentItemHeight > 0 ? 1 : 0,
        }}
      >
        {paymentType === PaymentGateway.STRIPE ? (
          <StripeWrapper
            cartItem={cartItem}
            key={`payment_section_${activeTab}`}
            setPaymentMethod={(refId: string) => {
              setPaymentItemHeight(0);
              setPaymentMethod(refId);
            }}
          />
        ) : (
          <PaymentSection
            serviceType={cartItem.type}
            setPaymentMethod={(refId: string) => {
              setPaymentItemHeight(0);
              setPaymentMethod(refId);
            }}
            cartItem={cartItem}
            selectedPaymentRefId={selectedPaymentRefId}
            key={`payment_section_${activeTab}`}
            setHeightOnAddNew={() => {
              setPaymentItemHeight(paymentItemRef.current!.scrollHeight);
            }}
            setPaymentRefIdOnAddNew={() => {
              setPaymentMethod(null);
            }}
          />
        )}
      </div>
      <div
        className={classes.actionContainer}
        onClick={() => {
          setPaymentItemHeight(paymentItemHeight === 0 ? paymentItemRef.current!.scrollHeight : 0);
        }}
      >
        <div className={classes.actionTitle}>
          {paymentItemHeight > 0 ? LABELS.HIDE : paymentCompleted ? LABELS.SHOW : LABELS.SELECT}
        </div>
        <div
          className={`${classes.actionIconContainer} ${paymentItemHeight > 0 && classes.rotate}`}
        >
          <img className={classes.actionIcon} src={downArrow} alt="status" />
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(PaymentItem);

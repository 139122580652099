import { GetLeaseResponse } from '../../services/lease/getLease/GetLeaseResponse.data';

export enum LeaseActionTypes {
  GET_LEASE_REQUEST = 'GET_LEASE_REQUEST',
  GET_LEASE_SUCCESS = 'GET_LEASE_SUCCESS',
  GET_LEASE_ERROR = 'GET_LEASE_ERROR',
}

export interface LeaseAction {
  type: LeaseActionTypes;
  payload?: GetLeaseResponse | Error;
}

export class LeaseActions {
  public static getLeaseRequest(): LeaseAction {
    return {
      type: LeaseActionTypes.GET_LEASE_REQUEST,
    };
  }
  public static getLeaseSuccess(property: any): LeaseAction {
    return {
      type: LeaseActionTypes.GET_LEASE_SUCCESS,
      payload: property,
    };
  }
  public static getLeaseError(error: Error): LeaseAction {
    return {
      type: LeaseActionTypes.GET_LEASE_ERROR,
      payload: error,
    };
  }
}

import * as React from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/RootReducers';
import { History } from 'history';
import KannopiOverview from '../../../../component/services/commonService/kannopiService/KannopiService';
import { RouteComponentProps } from 'react-router-dom';
import { get } from 'lodash';
import { PlanSummaryActions } from '../../../../store/actions/PlanSummaryActions';
import { FC, useEffect } from 'react';
import {
  ServiceIframe,
  ServiceIframeInProgress,
  ServiceProvider,
} from '../../../../component/services/commonService/kannopiService/KanopiInterface';

interface Props {
  history: History;
  quotes: ServiceProvider;
  accessToken: string | boolean | null;
  getCommonQuotes: (serviceType: string, propertyId: string, providerName: string) => void;
  setServiceId: (body: ServiceIframeInProgress | ServiceIframe, onSuccess: () => void) => void;
}

type OwnProps = Props &
  RouteComponentProps<{
    propertyId: string;
    addressConfirmed: string;
    serviceName: string;
    providerName: string;
    lookupRequired: string;
    serviceAccountId: string;
  }>;

const KannopiScreen: FC<OwnProps> = ({
  accessToken,
  getCommonQuotes,
  setServiceId,
  history,
  quotes,
  match,
}) => {
  // const waitForAccessToken: number = 0;

  useEffect(() => {
    const { serviceName, propertyId, providerName } = match.params;
    if (accessToken && serviceName !== undefined) {
      getCommonQuotes(serviceName, propertyId, providerName);
    }
  }, [accessToken]);

  const redirectTo = (route: string, data: ServiceIframeInProgress | ServiceIframe) => {
    setServiceId(data, () => {
      history.push(route);
    });
  };
  const { serviceName, propertyId, providerName, serviceAccountId } = match.params;

  return (
    <KannopiOverview
      quotes={get(quotes, 'serviceProvider.0', null)}
      propertyId={propertyId}
      serviceName={serviceName}
      providerName={providerName}
      serviceAccountId={serviceAccountId}
      onBtnClick={redirectTo}
    />
  );
};

const mapDispatchToProps = (dispatch: React.Dispatch<AnyAction>) => {
  return {
    getCommonQuotes: (serviceType: string, propertyId: string, providerName: string) =>
      dispatch(
        PlanSummaryActions.getServiceSummaryStart({ serviceType, propertyId, providerName }),
      ),
    setServiceId: (body: ServiceIframeInProgress | ServiceIframe, onSuccess: () => void) =>
      dispatch(PlanSummaryActions.startServiceId({ body, onSuccess })),
  };
};

const mapStateToProps = (state: ApplicationState) => {
  // const serviceData = state.commonSummary.serviceSignupData[serviceTypes[props.match.params.serviceName]];
  return {
    accessToken: state.token.accessToken,
    quotes: state.planSummary.serviceSummary,
    state,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(KannopiScreen);

import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
  streetNumber: Yup.string().required('Required'),
  streetType: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  suburb: Yup.string().required('Required'),
  streetName: Yup.string().required('Required'),
  postcode: Yup.string().required('Required'),
});

export const LABELS = {
  ADDRESS: 'Address',
  PROPERTY_TYPE: 'Property type',
  OCCUPANCY_TYPE: 'Occupancy type',
};

import { WithStyles, createStyles } from '@material-ui/core';
import { color } from '../../../theme/Theme';

export const styles = createStyles({
  root: {
    marginTop: 124,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingLeft: 4,
  },
  paymentListContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 26,
  },
  listitemStyle: {
    height: 56,
    marginTop: 10,
  },
  payIdContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 26,
    marginLeft: 20,
    marginBottom: -10,
  },
  payIdImage: { height: 16, width: 34 },
  marginColumn: { marginLeft: 40, flexDirection: 'column' },
  payIdHeading: { color: color.primary, fontSize: 14, fontWeight: 500 },
  payIdSubheading: { color: color.black60, fontSize: 16 },
  itemTextStyle: {
    marginLeft: 23,
  },
  imageStyle: {
    width: 24,
    height: 24,
    margin: 6,
  },
  cardImageStyle: {
    width: 36,
    height: 24,
  },
  textStyle: {
    color: color.dark,
    opacity: 0.6,
    fontFamily: 'GT Walsheim',
  },
});

export interface SavedPaymentMethodsStyles extends WithStyles<typeof styles> {}

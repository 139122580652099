import React, { FC } from 'react';
import { withStyles, MenuItem } from '@material-ui/core';
import { styles, SelectPropertyTypeStyles } from './SelectPropertyTypeStyles';
import {
  OnboardingPropertyDetails,
  PropertyType,
  SetOnboardingPropertyDetails,
} from '../../../../models/property/Property.data';
import homeOutline from '../../../../assets/houses/house4.png';
import homeSolid from '../../../../assets/houses/house3.png';
import tick from '../../../../assets/radio-filled.png';
import tickBlack from '../../../../assets/radio-filled-black.png';
import apartment from '../../../../assets/houses/house1.png';
import villa from '../../../../assets/houses/house2.png';
import { LABELS } from './SelectPropertyTypeConstants';
import { AGENCIES, APP } from '../../../../helper/AppNameHelper';

interface SelectPropertyTypeProps extends SelectPropertyTypeStyles {
  propertyOnboardingState: OnboardingPropertyDetails;
  setPropertyDetails: (data: SetOnboardingPropertyDetails) => void;
}

const SelectPropertyType: FC<SelectPropertyTypeProps> = ({
  classes,
  propertyOnboardingState: { selectedType },
  setPropertyDetails,
}) => {
  return (
    <div className={classes.contentContainer}>
      <MenuItem
        onClick={() => {
          setPropertyDetails({ field: 'selectedType', value: PropertyType.Apartment });
        }}
        className={classes.menuItem}
      >
        <div className={classes.menuRow}>
          <div className={classes.houseContainer}>
            <img className={classes.houseImage} src={apartment} />
          </div>

          <span className={classes.text}>{LABELS.APARTMENT}</span>
        </div>
        <div className={classes.tickContainer}>
          <img
            src={APP === AGENCIES.NAX ? tickBlack : tick}
            className={`${
              selectedType === PropertyType.Apartment ? classes.tick : classes.hideItem
            }`}
          />
        </div>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setPropertyDetails({ field: 'selectedType', value: PropertyType.Villa });
        }}
        className={classes.menuItem}
      >
        <div className={classes.menuRow}>
          <div className={classes.houseContainer}>
            <img className={classes.houseImage} src={villa} />
          </div>

          <span className={classes.text}>{LABELS.VILLA}</span>
        </div>
        <div className={classes.tickContainer}>
          <img
            src={APP === AGENCIES.NAX ? tickBlack : tick}
            className={`${selectedType === PropertyType.Villa ? classes.tick : classes.hideItem}`}
          />
        </div>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setPropertyDetails({ field: 'selectedType', value: PropertyType.Home });
        }}
        className={classes.menuItem}
      >
        <div className={classes.menuRow}>
          <div className={classes.houseContainer}>
            <img className={classes.houseImage} src={homeSolid} />
          </div>

          <span className={classes.text}>{LABELS.HOUSE}</span>
        </div>
        <div className={classes.tickContainer}>
          <img
            src={APP === AGENCIES.NAX ? tickBlack : tick}
            className={`${selectedType === PropertyType.Home ? classes.tick : classes.hideItem}`}
          />
        </div>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setPropertyDetails({ field: 'selectedType', value: PropertyType.Townhouse });
        }}
        className={classes.menuItem}
      >
        <div className={classes.menuRow}>
          <div className={classes.houseContainer}>
            <img className={classes.houseImage} src={homeOutline} />
          </div>

          <span className={classes.text}>{LABELS.TOWNHOUSE}</span>
        </div>
        <div className={classes.tickContainer}>
          <img
            src={APP === AGENCIES.NAX ? tickBlack : tick}
            className={`${
              selectedType === PropertyType.Townhouse ? classes.tick : classes.hideItem
            }`}
          />
        </div>
      </MenuItem>
    </div>
  );
};

export default withStyles(styles)(SelectPropertyType);

import * as React from 'react';
import AddressFormComponent, {
  initialValuesState,
} from '../../../../component/services/electricityService/addressForm/AddressForm';
import { ApplicationState } from '../../../../store/RootReducers';
import { connect } from 'react-redux';
import {
  ElectricityServiceDetails,
  PropertyDetails,
} from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { Dispatch, AnyAction } from 'redux';
import { routes } from '../../../../Routes';
import { RouteComponentProps } from 'react-router-dom';
import { PlanCommonSummaryActions } from '../../../../store/actions/CommonSummaryActions';
import { BackHelper } from '../../../../helper/BackHelper';
import { PostCommonServiceAddressSaveRequest } from '../../../../services/commonService/getCommonService/GetCommonService.data';
import { PropertyObject } from '../../../../objectFactory/PropertyObjectFactory';
import { AddressListLabels } from '../../../../component/services/electricityService/addressForm/AddressFormConstants';
import { isNil } from 'lodash';
import { ServiceType } from '../../ServiceConstants';

interface PropsFromDispatch {
  saveProperty: (property: PropertyDetails) => void;
  getAddress: (propertyId: string) => void;
  setStopCall: (data: number) => void;
  getCommonAddressLookup: (
    propertyId: string,
    serviceName: string,
    providerName: string,
    onSuccess: () => void,
    onError: (error: string) => void,
  ) => void;
  createPropertyDetail: () => void;
  postProperty: (
    data: PostCommonServiceAddressSaveRequest,
    onSuccess: (response: any) => void,
  ) => void;
}
interface PropsFromState {
  service: ElectricityServiceDetails;
  property: PropertyDetails;
  //TODO: Resolve any
  propertyList: any;
  accessToken: string | boolean | null;
  //TODO: Resolve any
  state: any;
}
interface PropsFromRoute {
  //TODO: Resolve any
  history?: any;
  serviceName: string;
}
type OwnProps = PropsFromState &
  PropsFromDispatch &
  PropsFromRoute &
  RouteComponentProps<{
    propertyId: string;
    serviceName: string;
    providerName: string;
    lookupRequired: string;
    addressConfirmed: string;
    serviceAccountId: string;
    first?: string;
  }>;
class CommonAddressForm extends React.Component<OwnProps> {
  private waitForAccessToken = 0;
  private stopApiCall = 0;
  private waitNow = 0;

  public componentDidMount = async () => {
    const { match, accessToken } = this.props;
    const { serviceName, propertyId, addressConfirmed, lookupRequired } = match.params;

    if (
      accessToken &&
      this.waitForAccessToken === 0 &&
      serviceName !== undefined &&
      addressConfirmed !== undefined &&
      lookupRequired !== undefined
    ) {
      this.waitForAccessToken = 1;
      await this.props.getAddress(propertyId);
    }
  };

  public componentDidUpdate = async () => {
    const { match, accessToken } = this.props;
    const { serviceName, propertyId, addressConfirmed, lookupRequired } = match.params;
    if (
      accessToken &&
      this.waitForAccessToken === 0 &&
      serviceName !== undefined &&
      addressConfirmed !== undefined &&
      lookupRequired !== undefined
    ) {
      this.waitForAccessToken = 1;
      await this.props.getAddress(propertyId);
      if (!isNil(this.props.match.params.first) && this.props.property) {
        if (isNil(this.props.propertyList)) {
          this.handleSubmit(this.props.property);
        }
      }
    }
  };

  public render() {
    if (
      !isNil(this.props.match.params.first) &&
      this.props.property &&
      this.waitNow === 0 &&
      isNil(this.props.propertyList)
    ) {
      this.waitNow = 1;
      this.handleSubmit(this.props.property);
    }
    const { property } = this.props;

    if (property) {
      return <AddressFormComponent propertyDetails={property} handleSubmit={this.handleSubmit} />;
    } else {
      return <div />;
    }
  }
  public handleSubmit = (property: initialValuesState) => {
    const propertyDetails = this.props.property;
    const { propertyId, serviceName, providerName } = this.props.match.params;

    const address: PropertyDetails = {
      ...property,
      address: propertyDetails.address,
      id: propertyDetails.id,
      photos: propertyDetails.photos,
      type: propertyDetails.type,
      nmi: '',
      nmiMeterNumbers: null,
      keyIdentifier: '',
      meterSerialNumber: null,
      addressLine1: null,
      addressLine2: null,
      addressLine3: null,
    };
    const propetyObject = new PropertyObject({});
    const addressData = propetyObject.createCommonServicePropertyDetailsObject(
      address,
      AddressListLabels.stepName,
      propertyId,
      serviceName,
      providerName,
      null,
    );
    const { postProperty, getCommonAddressLookup } = this.props;
    postProperty(addressData, () => {
      if (this.props.match.params.lookupRequired === 'true') {
        getCommonAddressLookup(propertyId, serviceName, providerName, this.onSuccess, this.onError);
      } else {
        const { propertyId, serviceName, providerName, lookupRequired, serviceAccountId } =
          this.props.match.params;
        this.props.createPropertyDetail();
        const { propertyList } = this.props;
        const propetyObject = new PropertyObject({});
        const serviceDetail =
          serviceAccountId === 'null' ||
          serviceAccountId.length === 0 ||
          serviceAccountId === 'undefined'
            ? null
            : serviceAccountId;
        const lookupObject = propertyList ? propertyList.Attributes : {};
        const lookupData = propetyObject.createCommonLookupAddressObject(
          lookupObject,
          'LOOKUP_DETAILS',
          propertyId,
          serviceName,
          providerName,
          propertyList ? propertyList.Attributes : {},
          serviceDetail,
        );
        this.waitForAccessToken = 1;
        this.props.postProperty(lookupData, (response: any) => {
          // In case of solar and cleaning, first redirect to overview screen
          if (serviceName === ServiceType.Solar || serviceName === ServiceType.Cleaning) {
            this.props.history.push(
              routes.commonService.address.overview(
                propertyId,
                serviceName,
                'false',
                lookupRequired,
                providerName,
                response.serviceAccountResponse.id,
              ),
            );
          } else {
            this.props.history.push(
              routes.commonService.new(
                serviceName,
                propertyId,
                providerName,
                'true',
                lookupRequired,
                serviceDetail,
              ),
            );
          }
        });
      }
    });
  };

  private onSuccess = () => {
    const { propertyId, serviceName, providerName, lookupRequired, serviceAccountId } =
      this.props.match.params;

    const { propertyList } = this.props;
    if (propertyList) {
      const fileteredAddressSearch = propertyList.AddressesSearchable.length >= 0;
      if (fileteredAddressSearch) {
        this.props.setStopCall(0);
        if (this.stopApiCall === 0) {
          this.stopApiCall = 1;
          this.props.history.push(
            routes.commonService.address.list(
              propertyId,
              serviceName,
              providerName,
              lookupRequired,
              serviceAccountId,
            ),
          );
        }
      }
    }
  };
  private onError = (error: string) => {
    const { propertyId, serviceName, providerName, lookupRequired, serviceAccountId } =
      this.props.match.params;
    BackHelper.saveCount(-1);
    this.props.history.push(
      routes.commonService.address.list(
        propertyId,
        serviceName,
        providerName,
        lookupRequired,
        serviceAccountId,
      ),
    );
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): PropsFromDispatch {
  return {
    createPropertyDetail: () => {
      dispatch(PlanCommonSummaryActions.createPropertySelected(null));
    },
    saveProperty: (property: PropertyDetails) => {
      dispatch(PlanCommonSummaryActions.saveProperty(property));
    },
    getAddress: (propertyId: string) => {
      dispatch(PlanCommonSummaryActions.getAddressStart(propertyId));
    },
    postProperty: (
      data: PostCommonServiceAddressSaveRequest,
      onSuccess: (response: any) => void,
    ) => {
      dispatch(PlanCommonSummaryActions.setPropertyAddressData({ data, onSuccess }));
    },
    setStopCall: (data: number) => {
      dispatch(PlanCommonSummaryActions.setStopCall({ data }));
    },
    getCommonAddressLookup: (
      propertyId: string,
      serviceName: string,
      providerName: string,
      onSuccess: () => void,
      onError: (error: string) => void,
    ) => {
      dispatch(
        PlanCommonSummaryActions.getAddressLookupStart({
          propertyId,
          serviceName,
          providerName,
          onSuccess,
          onError,
        }),
      );
    },
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    service: state.electricityService.service as ElectricityServiceDetails,
    property: state.commonSummary.commonServiceAddress as PropertyDetails,
    propertyList: state.commonSummary.commonSummaryAddressLookup,
    accessToken: state.token.accessToken,
    state,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CommonAddressForm);

import { color } from '../../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';
import { AGENCIES, APP } from '../../../helper/AppNameHelper';

export const styles = createStyles({
  root: {
    backgroundColor: color.secondary,
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 48px)',
  },
  body: {
    padding: '12px',
  },
  text: {
    color: color.black87,
    fontSize: '20px',
    fontWeight: 500,
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: color.light,
    padding: '16px',
    alignItems: 'center',
    fontWeight: 600,
    color: color.black87,
    borderRadius: 8,
    border: `${color.borderColor} 2px solid`,
    marginBottom: '12px',
    minHeight: '40px',
  },
  menuRow: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    width: '26px',
    height: '26px',
    marginRight: '16px',
  },
  iconImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  optionContainer: {
    marginRight: 'auto',
  },
  optionTitle: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: '16px',
    fontWeight: 500,
  },
  optionSubtitle: {
    fontFamily: 'GT Walsheim',
    marginTop: 4,
    color: color.black38,
    fontSize: '13px',
    fontWeight: 500,
  },
  tickContainer: {
    width: '22px',
    height: '22px',
    marginLeft: 'auto',
    marginRight: '12px',
  },
  tick: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    filter: APP !== AGENCIES.SORTED ? 'grayscale(100%)' : '',
  },
  hideItem: {
    display: 'none',
  },
  footerButtonsContainer: {
    bottom: 12,
    left: 12,
    right: 12,
    position: 'fixed',
  },
  footerButton: {
    padding: '16px 16px 16px 16px',
    backgroundColor: color.buttonBackgroundSecondary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.light,
    fontWeight: 600,
    borderRadius: 6,
  },
  footerButtonSecondary: {
    marginTop: 12,
    padding: '16px 16px 16px 16px',
    backgroundColor: color.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.black87,
    fontWeight: 600,
    borderRadius: 6,
    border: `${color.borderColor} 2px solid`,
  },
  disabled: {
    padding: '16px 16px 16px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    borderRadius: 6,
    backgroundColor: color.disabled,
    color: color.black38,
  },
  title: {
    paddingTop: 70,
  },
  paymentTypes: {
    marginTop: '25px',
  },
  paymentType: {
    padding: '16px 8px',
    cursor: 'pointer',
  },
  paymentTypeText: {
    color: '#212121',
    fontSize: '16px',
    lineHeight: 1.5,
    letterSpacing: '0.1px',
  },
  bottomDrawer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 16px 18px 16px',
  },
  bottomDrawerContainer: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  bottomDrawerTitle: {
    fontSize: 30,
    letterSpacing: 0.3,
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontWeight: 500,
  },
  disabledMessage: {
    fontFamily: 'GT Walsheim',
    color: color.black38,
    fontSize: '16px',
    fontWeight: 500,
    marginTop: 6,
    marginBottom: 36,
  },
  terms: {
    fontFamily: 'GT Walsheim Light',
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: '20px',
    marginBottom: '24px',
    marginTop: 4,
  },
  downloadButtonText: {
    fontFamily: 'GT Walsheim',
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    textTransform: 'capitalize',
    color: color.link,
    textDecoration: 'underline',
    marginBottom: '36px',
    cursor: 'pointer',
  },
  downloadButton: {
    display: 'flex',
    flexDirection: 'row',
    padding: '32px 16px 16px 0',
  },
  checkContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-11px',
    marginBottom: '16px',
  },
  checkboxText: {
    marginTop: 2,
    cursor: 'pointer',
    userSelect: 'none',
  },
  drawerButtons: {
    'background': color.primary,
    '&:hover': {
      backgroundColor: color.primary,
    },
    '& > span > p': {
      color: 'white',
    },
    'height': 36,
  },
  drawerFooter: {},
  buttonPaymentDisabled: {
    width: '100%',
    height: 36,
    borderRadius: 0,
    marginTop: 100,
  },
  messageBox: {
    display: 'flex',
    flexDirection: 'column',
    bottom: 140,
    right: 0,
    left: 0,
    padding: 16,
    position: 'fixed',
    textAlign: 'center',
  },
  buttonStyle: {
    height: 36,
  },
  disableButtonTextStyle: {
    color: color.secondary300,
  },
  activeButtonTextStyle: {
    color: color.light,
  },
  transactionFeeContainerStyle: {
    paddingBottom: 32,
  },
  transactionFeeStyle: {
    fontFamily: 'GT Walsheim',
    color: color.black38,
    fontSize: '13px',
    fontWeight: 500,
  },
  button: {
    height: 36,
  },
  acceptButtonText: {
    fontSize: 14,
    color: color.light,
  },
  cancelButton: {
    fontSize: 14,
    color: color.dark,
  },
});

export interface AddPaymentStyles extends WithStyles<typeof styles> {}

import { createStyles, WithStyles } from '@material-ui/core';
import { color } from '../../theme/Theme';

export const Styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 16px 0 16px',
    height: '100vh',
    marginTop: '48px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainIcon: {
    width: '72px',
    height: '72px',
    marginTop: '110px',
  },
  mainHeading: {
    fontSize: '27.2px',
    marginTop: '38px',
  },
  subheading: {
    textAlign: 'center',
    fontSize: '16.3px',
    marginTop: '16px',
  },
  cardList: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '48px',
  },
  cardBody: {
    display: 'flex',
    marginTop: '16px',
    justifyContent: 'space-between',
    padding: '20px',
  },
  unavailableText: {
    fontSize: '15px',
    letterSpacing: '0.4px',
    color: color.secondary400,
  },
  cardIcon: {
    width: '20px',
    height: '20px',
  },
  cardIconLight: {
    width: '20px',
    height: '20px',
    opacity: 0.3,
  },
  text: {
    fontSize: '15px',
    letterSpacing: '0.4px',
    color: '#000000',
  },
  blueText: {
    fontSize: '15px',
    letterSpacing: '0.4px',
    color: '#1fcee6',
  },
  redText: {
    fontSize: '15px',
    letterSpacing: '0.4px',
    color: '#ff525b',
  },
  bottomText: {
    display: 'flex',
    fontSize: '14px',
    color: 'black-87',
    letterSpacing: 0.15,
    marginRight: '17px',
    marginLeft: '17px',
    marginBottom: '32px',
    textAlign: 'center',
    justifyContent: 'center',
  },
  button: {
    height: 36,
    width: '100%',
    marginBottom: '16px',
    border: '1px solid grey',
  },
  buttonText: {
    color: color.primary,
  },
});

export interface BroadBandTypeStyle extends WithStyles<typeof Styles> {}

import { color } from '../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';
import { SIZES } from '../../Constants';

export const styles = createStyles({
  popupContainer: {
    backgroundColor: color.secondary,
    height: '100%',
  },
  popupHeader: {
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'start',
    alignItems: 'center',
    paddingLeft: 12,
    paddingRight: 12,
    height: '48px',
    backgroundColor: color.headerColor,
    position: 'relative',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  popupLabel: {
    position: 'absolute',
    left: 0,
    right: 0,
    textAlign: 'center',
    margin: 'auto',
    color: color.black87,
    fontFamily: 'GT Walsheim',
    fontSize: 15,
    pointerEvents: 'none',
  },
  popupActionContainer: {
    width: 25,
    height: 25,
  },
  popupAction: {
    width: '100%',
    height: '100%',
    objectFix: 'contain',
    objectPosition: 'center',
  },
  informationContainer: {
    display: 'flex',
    overflow: 'scroll',
    width: '100%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },
  compareItem: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    marginTop: '-15px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      minWidth: '92vw',
    },
    minWidth: '350px',
    width: '100%',
  },
  marginLeft: {
    marginLeft: '-15px',
  },
  closeContainer: {
    width: 30,
    height: 30,
    cursor: 'pointer',
  },
  closeIcon: {
    width: '100%',
    height: '100%',
    objectFix: 'contain',
    objectPosition: 'center',
  },
});

export interface CheckoutCompareStyles extends WithStyles<typeof styles> {}

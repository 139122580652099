import { color } from '../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  root: {
    'paddingTop': '48px',
    'backgroundColor': color.secondary,
    'boxSizing': 'border-box',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },
  popup: {
    backgroundColor: color.light,
    position: 'fixed',
    bottom: '0 !important',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    overflow: 'hidden',
    width: '100%',
    boxShadow:
      '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
  },
  scrollContainer: {
    paddingBottom: '135px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },
  popupContainer: {
    backgroundColor: color.secondary,
    height: '100vh',
  },
  noServiceContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: color.black38,
    textAlign: 'center',
    height: '40vh',
    flexDirection: 'column',
  },
  noServiceImage: {
    width: 80,
    heigh: 65,
  },
  noServiceTitleStyle: {
    color: color.black87,
    textAlign: 'center',
    fontFamily: 'GT Walsheim',
    fontSize: 16,
    marginTop: 24,
  },
  noServiceSubtextSyle: {
    color: color.black87,
    textAlign: 'center',
    fontFamily: 'GT Walsheim',
    fontSize: 14,
    marginTop: 12,
  },
  contactSupportButtonStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 312,
    height: 40,
    color: color.black87,
    textAlign: 'center',
    fontFamily: 'GT Walsheim',
    fontSize: 15,
    border: `${color.borderColor} 2px solid`,
    borderRadius: 6,
    marginTop: 32,
    cursor: 'pointer',
  },
  title: {
    color: color.black87,
    fontFamily: 'GT Walsheim',
    fontSize: 24,
    marginTop: 20,
    marginBottom: 25,
  },
});

export interface CheckoutSelectStyles extends WithStyles<typeof styles> {}

import * as React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import PlanButton from '../PlanButtons';
import PlanFooter from '../PlanFooter';
import {
  LABELS_DOWNLOAD,
  DEFAULT_SELECTED_BUTTON_INDEX,
  FOOTER_VALUE_NA,
  getDataExtraInfo,
} from './Constants';
import { Styles, PlanScreenStyles } from '../electricity/Styles';
import { routes } from '../../../../Routes';
import { BroadbandPlanDetails } from '../../../../models/plan/broadband/getPlanDetails/BroadbandPlan.data';
import PlanHelper from '../../../../helper/PlanHelper';
import {
  BroadbandPlanSubmit,
  SpeedDetails,
} from '../../../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import { BroadbandTypes } from '../../../../containers/services/ServiceConstants';

interface PropsFromRoute {
  readonly history?: any;
}

interface PropsFromParent {
  readonly planDetails: BroadbandPlanDetails;
  readonly handleNext: (choice: string) => void;
  readonly dataValue: string;
}

interface PlanState {
  choice: number;
}

type OwnProps = PlanScreenStyles & PropsFromRoute & PropsFromParent;

class DownloadScreen extends React.Component<OwnProps, PlanState> {
  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      choice: DEFAULT_SELECTED_BUTTON_INDEX,
    };
  }

  public render() {
    const { classes } = this.props;
    const { choice } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <div className={classes.top}>
            <Typography className={classes.title} variant="h5">
              {LABELS_DOWNLOAD.title}
            </Typography>
            <PlanButton
              options={LABELS_DOWNLOAD.buttonLabels}
              label={LABELS_DOWNLOAD.buttonsHeading}
              onChange={this.handleOptionChange}
              selected={choice}
              info={this.getDownloadSpeedInfo()}
            />
          </div>
          <PlanFooter
            amount={this.filterFooterValue()}
            extraInfo={this.getExtraInfo()}
            onButtonClick={this.handleFooterClick}
          />
        </div>
      </div>
    );
  }

  private getDownloadSpeedInfo = (): string => {
    const productName = PlanHelper.getProductName(
      this.props.planDetails.productNames,
      this.props.dataValue,
      LABELS_DOWNLOAD.buttonLabels[this.state.choice],
      false,
    );
    const productInfo: BroadbandPlanSubmit = PlanHelper.getProductDetails(
      this.props.planDetails.plan,
      productName,
    );
    return (
      productInfo &&
      (productInfo.speedDetails as SpeedDetails) &&
      ((productInfo.speedDetails as SpeedDetails).downloadSpeedText as string)
    );
  };
  private getExtraInfo = () => {
    const { planDetails } = this.props;
    const productName = PlanHelper.getProductName(
      planDetails.productNames,
      this.props.dataValue,
      LABELS_DOWNLOAD.buttonLabels[this.state.choice],
      false,
    );
    const productInfo: BroadbandPlanSubmit = PlanHelper.getProductDetails(
      planDetails.plan,
      productName,
    );
    let setupFee = null;
    if (planDetails.broadbandType === BroadbandTypes.adsl) {
      setupFee = getDataExtraInfo(`${productInfo && productInfo.setupFeeContractYes}`);
    } else if (planDetails.telcoService.nbnDevelopmentFee && productInfo.connectionFeeAmount) {
      setupFee = getDataExtraInfo(productInfo.connectionFeeAmount.toString());
    }
    return setupFee;
  };

  private filterFooterValue = (): string => {
    const productName = PlanHelper.getProductName(
      this.props.planDetails.productNames,
      this.props.dataValue,
      LABELS_DOWNLOAD.buttonLabels[this.state.choice],
      false,
    );
    const productInfo: BroadbandPlanSubmit = PlanHelper.getProductDetails(
      this.props.planDetails.plan,
      productName,
    );
    return productInfo && productInfo.productDetails
      ? `${productInfo.productDetails.monthlyCost}`
      : FOOTER_VALUE_NA;
  };

  private handleOptionChange = (choice: number) => {
    this.setState({ choice });
  };

  private handleFooterClick = () => {
    this.props.history.push(
      routes.broadbandService.plan.modemSelection((this.props as any).match.params.propertyId),
    );
    this.props.handleNext(LABELS_DOWNLOAD.buttonLabels[this.state.choice]);
  };
}

export default withStyles(Styles)(DownloadScreen);

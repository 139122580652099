import { StoreAction } from '../StoreHelper';

export enum ConnectionDateActionTypes {
  SaveConnectionDate = 'SAVE_CONNECTION_DATE',
}

export interface ConnectionDateStepPayload {
  connectionDate: string | Date;
  moving: boolean;
}

export type ConnectionDatePayload = ConnectionDateStepPayload | any;

export type ConnectionDateAction = StoreAction<ConnectionDateActionTypes, ConnectionDatePayload>;

export class ConnectionDateActions {
  public static saveConnectionDate(data: ConnectionDateStepPayload): ConnectionDateAction {
    return {
      type: ConnectionDateActionTypes.SaveConnectionDate,
      data,
    };
  }
}

import { takeEvery, put } from 'redux-saga/effects';
import { SpinnerActions } from '../actions/SpinnerActions';
import {
  WalletAction,
  WalletActions,
  WalletActionTypes,
  DeletePaymentRequest,
} from '../actions/WalletActions';
import { GetPaymentMethodsResponse } from '../../services/payment/getPaymentMethods/GetPaymentMethods.data';
import paymentService from '../../services/payment/PaymentService';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';

export function* getPaymenyMethodsSaga(action: WalletAction): IterableIterator<any> {
  try {
    yield put(SpinnerActions.show());
    const response: GetPaymentMethodsResponse[] = yield paymentService.getPaymentMethods();
    yield put(WalletActions.getPaymentMethodsSuccess(response));
  } catch (error) {
    yield put(SpinnerActions.hide());
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(WalletActions.getPaymentMethodsError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* deletePaymenySaga(action: WalletAction): IterableIterator<any> {
  try {
    yield put(SpinnerActions.show());
    const { paymentRefId, onSuccess } = action.data as DeletePaymentRequest;
    const response: string[] = yield paymentService.deletePayment(paymentRefId);
    yield put(WalletActions.deletePaymentSuccess(response));
    if (onSuccess) {
      onSuccess(response);
    }
  } catch (error) {
    yield put(SpinnerActions.hide());
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(WalletActions.deletePaymentError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

function* walletWatcher(): IterableIterator<any> {
  yield takeEvery(WalletActionTypes.GetPaymentMethodsStart, (action) =>
    getPaymenyMethodsSaga({
      type: action.type,
      data: (action as WalletAction).data,
    }),
  );
  yield takeEvery(WalletActionTypes.DeletePaymentStart, (action) =>
    deletePaymenySaga({
      type: action.type,
      data: (action as WalletAction).data,
    }),
  );
}
export default walletWatcher;

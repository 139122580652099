import * as React from 'react';
import { BroadbandServiceActions } from '../../../../store/actions/BroadbandServiceActions';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/RootReducers';
import {
  BroadbandServiceDetails,
  ServiceDetails,
} from '../../../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import {
  statusCurrent,
  statusPending,
  electricityServiceSteps,
  statusDone,
  broadbandTitle,
  ServiceType,
} from '../../ServiceConstants';
import OverviewScreenComponent from '../../../../component/services/electricityService/OverView';
import { History } from 'history';
import { RouteComponentProps } from 'react-router-dom';
import { StepDetails } from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { routes } from '../../../../Routes';
import { HeaderState, Params } from '../../../../store/state/HeaderState';
import { BackHelper } from '../../../../helper/BackHelper';
import { BroadbandPlanActions } from '../../../../store/actions/BroadbandPlanAction';

interface PropsFromDispatch {
  getBroadbandService: (serviceAccountId: string) => void;
  onClear: (serviceAccountId: string, onSuccess: () => void) => void;
  clearPlanSelection: () => void;
}

interface PropsFromState {
  service: BroadbandServiceDetails;
  accessToken: string | boolean | null;
  params: Params;
}

interface PropsFromRoute {
  history: History;
}

type OwnProps = PropsFromDispatch &
  PropsFromState &
  PropsFromRoute &
  RouteComponentProps<{
    propertyId: string;
    serviceSummary: string;
    paymentRefId: string;
    serviceAccountId: string;
  }>;

class OverviewScreen extends React.Component<OwnProps, {}> {
  private waitForAccessToken = 0;
  private waitForServiceId = 0;
  public constructor(props: OwnProps) {
    super(props);
  }
  public componentDidMount() {
    const { serviceAccountId, propertyId, paymentRefId } = this.props.match.params;
    const { accessToken, params } = this.props;
    if (accessToken && (serviceAccountId || params.serviceAccountId)) {
      this.getBroadband();
    }
    if (serviceAccountId && this.waitForServiceId === 0) {
      this.waitForServiceId = 1;
      BackHelper.saveParams({
        propertyId,
        paymentRefId,
        serviceAccountId,
      });
    }
  }

  public componentDidUpdate() {
    const { serviceAccountId, propertyId, paymentRefId } = this.props.match.params;
    const { accessToken, params } = this.props;
    if (
      accessToken &&
      this.waitForAccessToken === 0 &&
      (serviceAccountId || params.serviceAccountId)
    ) {
      this.getBroadband();
    }
    if (serviceAccountId && this.waitForServiceId === 0) {
      this.waitForServiceId = 1;
      BackHelper.saveParams({
        propertyId,
        paymentRefId,
        serviceAccountId,
      });
    }
  }

  public render() {
    const { serviceAccountId } = this.props.match.params;
    const { params } = this.props;
    const stepStatus =
      serviceAccountId || params.serviceAccountId
        ? this.getStepsStatus()
        : [statusCurrent, statusPending, statusPending, statusPending];
    return (
      <OverviewScreenComponent
        overViewSteps={electricityServiceSteps}
        serviceTitle={broadbandTitle}
        stepStatus={stepStatus}
        redirectTo={this.redirectTo}
        handleClear={this.handleClear}
      />
    );
  }

  public redirectTo = (stepCode: number) => {
    const stepUrls = this.handleRedirecionUrl();
    this.props.history.push(stepUrls[stepCode]);
  };

  public handleRedirecionUrl = () => {
    const { propertyId, serviceSummary, serviceAccountId } = this.props.match.params;
    if (serviceAccountId) {
      const { service } = this.props;
      const planDetailsStep =
        service &&
        service.serviceDetails &&
        (service.serviceDetails.planDetailsStep as StepDetails).completed;
      let planUrl = routes.broadbandService.personalDetails.showDetails(propertyId);
      if (!planDetailsStep) {
        planUrl = routes.broadbandService.type(propertyId);
      }
      return [
        routes.broadbandService.address.form(propertyId),
        planUrl,
        routes.broadbandService.termsAndConditions(propertyId, serviceSummary),
        routes.payment.list.saved(propertyId, ServiceType.Broadband),
      ];
    } else {
      return [
        routes.broadbandService.address.form(propertyId),
        routes.broadbandService.type(propertyId),
        routes.broadbandService.termsAndConditions(propertyId, serviceSummary),
        routes.payment.list.saved(propertyId, ServiceType.Broadband),
      ];
    }
  };

  // tslint:disable-next-line:cyclomatic-complexity
  public getStepsStatus = () => {
    const { service } = this.props;
    const serviceDetail = service && service.serviceDetails;
    const confirmAddressStep =
      serviceDetail && !(serviceDetail.confirmAddressStep as StepDetails).completed;
    const planDetailsStep =
      serviceDetail &&
      !(
        (serviceDetail.planDetailsStep as StepDetails).completed &&
        (serviceDetail.personalDetailsStep as StepDetails).completed
      );
    const acceptTermsStep =
      serviceDetail && !(serviceDetail.acceptTermsStep as StepDetails).completed;
    const paymentStep =
      serviceDetail && !(serviceDetail.paymentDetailsStep as StepDetails).completed;
    if (confirmAddressStep) {
      return [statusCurrent, statusPending, statusPending, statusPending];
    } else if (planDetailsStep) {
      return [statusDone, statusCurrent, statusPending, statusPending];
    } else if (acceptTermsStep) {
      return [statusDone, statusDone, statusCurrent, statusPending];
    } else if (paymentStep) {
      return [statusDone, statusDone, statusDone, statusCurrent];
    } else {
      return [statusDone, statusDone, statusDone, statusDone];
    }
  };

  private getBroadband() {
    const { serviceAccountId } = this.props.match.params;
    const { params } = this.props;
    const broadbandServiceId = serviceAccountId ? serviceAccountId : params.serviceAccountId;
    this.props.getBroadbandService(broadbandServiceId);
    this.waitForAccessToken = 1;
  }

  private handleClear = () => {
    const { serviceAccountId } = this.props.match.params;
    const { params } = this.props;
    const broadbandServiceId = serviceAccountId ? serviceAccountId : params.serviceAccountId;
    this.props.onClear(broadbandServiceId, this.handleClearSuccess);
  };

  private handleClearSuccess = () => {
    const { propertyId } = this.props.match.params;
    BackHelper.saveParams({
      propertyId,
      paymentRefId: '',
      serviceAccountId: '',
    });
    this.props.clearPlanSelection();
    this.props.history.replace(routes.broadbandService.new(propertyId));
  };
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>): PropsFromDispatch {
  return {
    getBroadbandService: (serviceAccountId: string) => {
      dispatch(BroadbandServiceActions.getBroadbandServiceStart(serviceAccountId));
    },
    onClear: (serviceAccountId: string, onSuccess: () => void) => {
      dispatch(
        BroadbandServiceActions.deleteBroadbandServiceStart({
          data: serviceAccountId,
          onSuccess,
        }),
      );
    },
    clearPlanSelection: () => dispatch(BroadbandPlanActions.clearPlanSelection()),
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  const service: BroadbandServiceDetails = state.broadbandService
    .service as BroadbandServiceDetails;
  return {
    service,
    accessToken: state.token.accessToken,
    params: (state.headerParams as HeaderState).params,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(OverviewScreen);

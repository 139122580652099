import { StoreAction } from '../StoreHelper';
import { PostOnboardingStatusRequest } from '../../services/summary/postOnboardingStatus/PostOnboardingStatusRequest.data';

export enum SummaryActionTypes {
  PostOnboardingStatusStart = 'POST_ONBOARDING_STATUS_START',
  PostOnboardingStatusSuccess = 'POST_ONBOARDING_STATUS_SUCCESS',
  PostOnboardingStatusError = 'POST_ONBOARDING_STATUS_ERROR',
}

export interface SummaryErrorPayload {
  readonly error: Error | any;
}

export type SummaryPayload = PostOnboardingStatusRequest | SummaryErrorPayload | null;

export type SummaryAction = StoreAction<SummaryActionTypes, SummaryPayload>;

export class SummaryActions {
  public static postOnboardingStatusStart(data: PostOnboardingStatusRequest): SummaryAction {
    return { type: SummaryActionTypes.PostOnboardingStatusStart, data };
  }
  public static postOnboardingStatusSuccess(): SummaryAction {
    return { type: SummaryActionTypes.PostOnboardingStatusSuccess, data: null };
  }
  public static postOnboardingStatusError(data: SummaryErrorPayload): SummaryAction {
    return { type: SummaryActionTypes.PostOnboardingStatusSuccess, data };
  }
}

import { StoreAction } from '../StoreHelper';
import { BroadbandPlan } from '../../models/plan/broadband/getPlanDetails/BroadbandPlan.data';
import { PlanRequest } from '../../services/plan/getPlanDetails/GetPlanDetails.data';

export enum BroadbandPlanActionTypes {
  GetBroadbandPlanStart = 'GET_BROADBAND_PLAN_START',
  GetBroadbandPlanSuccess = 'GET_BROADBAND_PLAN_SUCCESS',
  GetBroadbandPlanError = 'GET_BROADBAND_PLAN_ERROR',
  SaveBroadbandChoice = 'SAVE_BROADBAND_CHOICE',
  SaveDataChoice = 'SAVE_DATA_CHOICE',
  SaveDownloadChoice = 'SAVE_DOWNLOAD_CHOICE',
  SaveModemChoice = 'SAVE_MODEM_CHOICE',
  SaveVoiceChoice = 'SAVE_VOICE_CHOICE',
  SaveContractChoice = 'SAVE_CONTRACT_CHOICE',
  ClearPlanSelection = 'CLEAR_PLAN_SELECTION',
}

export type BroadbandPlanPayload = BroadbandPlan | Error | string | any | PlanRequest;

export type BroadbandPlanAction = StoreAction<BroadbandPlanActionTypes, BroadbandPlanPayload>;

export class BroadbandPlanActions {
  public static getPlanDetailsStart(data: PlanRequest): BroadbandPlanAction {
    return {
      type: BroadbandPlanActionTypes.GetBroadbandPlanStart,
      data,
    };
  }

  public static getPlanDetailsSuccess(data: BroadbandPlan): BroadbandPlanAction {
    return {
      type: BroadbandPlanActionTypes.GetBroadbandPlanSuccess,
      data,
    };
  }

  public static getPlanDetailsError(data: Error): BroadbandPlanAction {
    return {
      type: BroadbandPlanActionTypes.GetBroadbandPlanError,
      data,
    };
  }

  public static saveBroadbandChoice(data: string): BroadbandPlanAction {
    return {
      type: BroadbandPlanActionTypes.SaveBroadbandChoice,
      data,
    };
  }

  public static saveDataChoice(data: string): BroadbandPlanAction {
    return {
      type: BroadbandPlanActionTypes.SaveDataChoice,
      data,
    };
  }

  public static saveDownloadChoice(data: string): BroadbandPlanAction {
    return {
      type: BroadbandPlanActionTypes.SaveDownloadChoice,
      data,
    };
  }

  public static saveModemChoice(data: string): BroadbandPlanAction {
    return {
      type: BroadbandPlanActionTypes.SaveModemChoice,
      data,
    };
  }

  public static saveVoiceChoice(data: string): BroadbandPlanAction {
    return {
      type: BroadbandPlanActionTypes.SaveVoiceChoice,
      data,
    };
  }

  public static saveContractChoice(data: string): BroadbandPlanAction {
    return {
      type: BroadbandPlanActionTypes.SaveContractChoice,
      data,
    };
  }
  public static clearPlanSelection(): BroadbandPlanAction {
    return {
      type: BroadbandPlanActionTypes.ClearPlanSelection,
      data: null,
    };
  }
}

import * as React from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/RootReducers';
import { BroadbandServiceDetails } from '../../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import { History } from 'history';
import { RouteComponentProps } from 'react-router-dom';
import { HeaderState, Params } from '../../../store/state/HeaderState';
import { BackHelper } from '../../../helper/BackHelper';
import { PlanSummaryActions } from '../../../store/actions/PlanSummaryActions';
import InfoPage from '../../../component/services/otherService/OtherServicesInfo';

interface PropsFromDispatch {
  getServiceInfo: (serviceAccountId: string) => void;
}

interface PropsFromState {
  service: BroadbandServiceDetails;
  accessToken: string | boolean | null;
  params: Params;
  serviceSummary: any;
}

interface PropsFromRoute {
  history: History;
}

type OwnProps = PropsFromDispatch &
  PropsFromState &
  PropsFromRoute &
  RouteComponentProps<{
    propertyId: string;
    serviceSummary: string;
    paymentRefId: string;
    serviceAccountId: string;
    serviceName: string;
  }>;

class InfoScreen extends React.Component<OwnProps, {}> {
  private waitForServiceId = 0;
  private waitForAccessToken = 0;
  public constructor(props: OwnProps) {
    super(props);
  }
  public componentDidMount() {
    const { serviceAccountId, propertyId, paymentRefId } = this.props.match.params;
    if (serviceAccountId && this.waitForServiceId === 0) {
      this.waitForServiceId = 1;
      BackHelper.saveParams({
        propertyId,
        paymentRefId,
        serviceAccountId,
      });
    }
  }

  public componentDidUpdate() {
    const { serviceAccountId, propertyId, paymentRefId } = this.props.match.params;
    const { accessToken, serviceSummary } = this.props;
    if (accessToken && this.waitForAccessToken === 0 && serviceAccountId && !serviceSummary) {
      this.waitForAccessToken = 1;
      this.props.getServiceInfo(serviceAccountId);
    }
    if (serviceAccountId && this.waitForServiceId === 0) {
      this.waitForServiceId = 1;
      BackHelper.saveParams({
        propertyId,
        paymentRefId,
        serviceAccountId,
      });
    }
  }

  public render() {
    const { serviceSummary } = this.props;
    return (
      <div>
        <InfoPage
          serviceName={this.props.match.params.serviceName}
          serviceSummary={serviceSummary}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>): PropsFromDispatch {
  return {
    getServiceInfo: (serviceAccountId: string) => {
      dispatch(PlanSummaryActions.getServiceInfoStart({ serviceAccountId }));
    },
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  const service: BroadbandServiceDetails = state.broadbandService
    .service as BroadbandServiceDetails;
  return {
    service,
    accessToken: state.token.accessToken,
    params: (state.headerParams as HeaderState).params,
    serviceSummary: state.planSummary.serviceInfo,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(InfoScreen);

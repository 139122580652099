import {
  BroadbandPlanSubmit,
  TelcoService,
} from '../../../../services/broadbandService/getBroadbandService/GetBroadbandService.data';

export enum BroadbandProducts {
  ADSL = 'ADSL',
  NBN = 'NBN',
}

export interface BroadbandPlan {
  NBN: BroadbandPlanDetails | null;
  ADSL: BroadbandPlanDetails | null;
}

export interface BroadbandPlanDetails {
  plan: BroadbandPlanSubmit[];
  labels: string[];
  productNames: string[];
  telcoService: TelcoService;
  broadbandType: string;
  productType: string;
  modemLabel: string;
}

export interface DataDetails {
  usage: string;
  monthlyCost: number;
}

export interface DownloadDetails {
  speed: string;
  peaktime: string;
  average: string;
  cost: number;
}

export interface ModemDetails {
  option: string;
  cost: number;
}

export interface VoiceDetails {
  cost: number;
  frequency: string;
}

export interface ContractDetails {
  setupCost: number;
  totalCost: number;
  contractLength: string;
}

import { color } from '../../../../../../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 20,
  },
  inputs: {
    width: '100%',
    marginTop: 10,
  },
  cardStyle: {
    marginTop: 20,
  },
  seperator: {
    height: 1,
    margin: 4,
    marginBottom: 12,
    background: '#eee',
  },
});

export interface ConcessionCardFormStyles extends WithStyles<typeof styles> {}

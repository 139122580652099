/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { takeEvery, put } from 'redux-saga/effects';
import { Logging } from '../../helper/Logging';
import { CheckoutAction, CheckoutActionTypes, CheckoutActions } from '../actions/CheckoutActions';
import {
  AddAndUpdateCartActionRequest,
  AvailableServicesRequest,
  AvailableServicesResponse,
  CheckoutRequest,
  CheckoutResponse,
  DeleteCartItemRequest,
  DeleteCartItemActionRequest,
  GetCartResponse,
  RemindMeLaterRequest,
  AddAndUpdateCartResponse,
} from '../../models/checkout/Checkout';
import CheckoutService from '../../services/checkoutService/CheckoutService';
import { getCardRequest, updateCartItemId } from '../../helper/CheckoutHelper';
import { SpinnerActions } from '../actions/SpinnerActions';

function* getAvailableServices(action: CheckoutAction) {
  try {
    if (!(action.data as AvailableServicesRequest).hideLoading) {
      yield put(SpinnerActions.show());
    }
    const { propertyId, requiredServiceTypes } = action.data as AvailableServicesRequest;
    const response: GetCartResponse = yield CheckoutService.getAvailableServices(
      propertyId,
      requiredServiceTypes,
    );
    console.log('response', response);
    yield put(CheckoutActions.getAvailableServicesSuccess(response));
  } catch (error) {
    Logging.error('CheckoutWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(CheckoutActions.getAvailableServicesError(error as Error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

function* addAndUpdateCard(action: CheckoutAction) {
  try {
    const { availableServices, propertyId, onSuccess } =
      action.data as AddAndUpdateCartActionRequest;

    const requestBody = getCardRequest(availableServices);
    const response: AddAndUpdateCartResponse = yield CheckoutService.addAndUpdateCart(
      propertyId,
      'APP',
      requestBody,
    );

    let updatedId = availableServices;
    response.cartResponse.cartItems.map((cartItem) => {
      updatedId = updateCartItemId(
        updatedId,
        cartItem.serviceType,
        cartItem.supplierId,
        cartItem.planId,
        cartItem.id,
      );
    });

    yield put(CheckoutActions.updateCartSuccess(updatedId));

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    Logging.error('CheckoutWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(CheckoutActions.getAvailableServicesError(error as Error));
  }
}

function* postCheckout(action: CheckoutAction) {
  try {
    const { propertyId, onSuccess, checkoutServiceTypes } = action.data as CheckoutRequest;
    yield put(SpinnerActions.show());
    const response: CheckoutResponse = yield CheckoutService.checkout(propertyId, checkoutServiceTypes);
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    Logging.error('CheckoutWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    const { onError } = action.data as CheckoutRequest;

    if (onError) {
      onError();
    }
    yield put(CheckoutActions.getAvailableServicesError(error as Error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

function* deleteCartItem(action: CheckoutAction) {
  try {
    const { propertyId, serviceType, planId, supplierId } = (
      action.data as DeleteCartItemActionRequest
    ).deleteCartItemRequest;
    const response: CheckoutResponse = yield CheckoutService.deleteCartItem(
      propertyId,
      serviceType,
      planId,
      supplierId,
    );
  } catch (error) {
    Logging.error('CheckoutWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(CheckoutActions.getAvailableServicesError(error as Error));
  }
}

function* remindMeLater(action: CheckoutAction) {
  try {
    yield CheckoutService.remindMeLater(action.data as RemindMeLaterRequest);
  } catch (error) {
    Logging.error('CheckoutWorker', {
      error,
      type: action.type,
      data: action.data,
    });
  }
}

function* checkoutWatcher(): IterableIterator<any> {
  yield takeEvery(CheckoutActionTypes.GET_AVAILABLE_SERVICES_REQUEST, (action: CheckoutAction) =>
    getAvailableServices({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(CheckoutActionTypes.ADD_AND_UPDATE_CART_REQUEST, (action: CheckoutAction) =>
    addAndUpdateCard({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(CheckoutActionTypes.CHECKOUT_REQUEST, (action: CheckoutAction) =>
    postCheckout({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(CheckoutActionTypes.DELETE_CART_ITEM_REQUEST, (action: CheckoutAction) =>
    deleteCartItem({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(CheckoutActionTypes.REMIND_ME_LATER, (action: CheckoutAction) =>
    remindMeLater({
      type: action.type,
      data: action.data,
    }),
  );
}

export default checkoutWatcher;

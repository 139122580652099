import { createStyles, WithStyles } from '@material-ui/core';

export const Styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    marginTop: 48,
  },
  serviceHeader: {
    display: 'flex',
    paddingTop: 15,
  },
  serviceHeaderText: {
    padding: 5,
  },
  serviceContent: {
    padding: 15,
    marginTop: 20,
  },
  serviceCard: {
    width: '100%',
    marginTop: -10,
    zIndex: 999,
    borderRadius: 8,
    position: 'absolute',
    paddingBottom: 130,
  },
  serviceHeaderImg: {
    height: 250,
    width: '100%',
  },
  serviceLogo: {
    height: 60,
    width: 60,
    marginLeft: 10,
    marginRight: 5,
    borderRadius: 30,
  },
  colorBlack: {
    color: 'black',
  },
  key: {
    fontSize: 13,
    color: 'rgba(0, 0, 0, 0.38)',
  },
  value: {
    fontFamily: 'GT Walsheim Regular',
    fontSize: 17,
  },
  item: {
    margin: '10px 20px 20px 20px',
  },
  section: {
    borderRadius: 8,
    border: '1px solid rgba(0, 0, 0, 0.1)',
    marginBottom: 25,
  },
  sectionTitle: {
    fontFamily: 'GT Walsheim Regular',
    margin: '10px 20px',
    fontSize: 17,
  },
  infoContainer: {
    padding: 15,
    marginTop: -5,
    background: 'white',
    width: '92%',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    position: 'relative',
  },
  infoHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '91%',
    position: 'absolute',
    padding: '10px 20px',
    marginTop: -70,
  },
  serviceInfoLogo: {
    height: 40,
    width: 40,
  },
  serviceInfoName: {
    color: 'white',
    fontSize: 29,
  },
  vignette: {
    boxShadow: '0 0 100px rgba(0,0,0,0.9) inset',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  providerName: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
  },
  serviceProviderLogo: {
    height: 40,
    width: 40,
    marginRight: 15,
  },
});

export interface OverViewStyles extends WithStyles<typeof Styles> {}

import { color } from '../../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';
import { AGENCIES, APP } from '../../../helper/AppNameHelper';

export const styles = createStyles({
  root: {
    backgroundColor: color.secondary,
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 48px)',
  },
  body: {
    padding: '12px',
  },
  text: {
    color: color.black87,
    fontSize: '20px',
    fontWeight: 500,
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: color.light,
    padding: '16px',
    alignItems: 'center',
    fontWeight: 600,
    color: color.black87,
    borderRadius: 8,
    border: `${color.borderColor} 2px solid`,
    marginBottom: '12px',
    minHeight: '40px',
  },
  menuRow: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    width: '26px',
    height: '26px',
    marginRight: '16px',
  },
  iconImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  optionContainer: {
    marginRight: 'auto',
  },
  optionTitle: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: '16px',
    fontWeight: 500,
  },
  optionSubtitle: {
    fontFamily: 'GT Walsheim',
    marginTop: 4,
    color: color.black38,
    fontSize: '13px',
    fontWeight: 500,
  },
  tickContainer: {
    width: '22px',
    height: '22px',
    marginLeft: 'auto',
    marginRight: '12px',
  },
  tick: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    filter: APP !== AGENCIES.SORTED ? 'grayscale(100%)' : '',
  },
  hideItem: {
    display: 'none',
  },
  messageBox: {
    // backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    bottom: 140,
    right: 0,
    left: 0,
    padding: 16,
    position: 'fixed',
    textAlign: 'center',
  },
  paymentLabelStyle: {
    marginTop: 65,
    marginBottom: '4vh',
  },
  savedPaymentLabelStyle: {
    color: color.dark,
  },
  iconStyle: {
    width: 24,
    height: 24,
    margin: 6,
  },
  cardIconStyle: {
    width: 36,
    height: 24,
  },
  listStyle: {
    marginBottom: '2vh',
  },
  listItemStyle: {
    paddingLeft: 0,
    marginTop: 10,
    marginBottom: '2vh',
  },
  itemTextStyle: {
    marginLeft: 23,
  },
  listItemTitle: {
    fontSize: 14,
  },
  listItemSubtitle: {
    fontSize: 16,
  },
  payTextStyle: {
    fontFamily: 'GT Walsheim',
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 1.43,
    color: color.dark,
    marginBottom: 32,
  },
  buttonStyle: {
    height: 36,
  },
  disableButtonTextStyle: {
    color: color.secondary300,
  },
  activeButtonTextStyle: {
    color: color.light,
  },
  bottomDrawer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 16px 18px 16px',
  },
  bottomDrawerContainer: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  bottomDrawerTitle: {
    fontSize: 24,
    letterSpacing: 0.3,
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontWeight: 500,
  },
  disabledMessage: {
    fontFamily: 'GT Walsheim',
    color: color.black38,
    fontSize: '16px',
    fontWeight: 500,
    marginTop: 4,
    marginBottom: 36,
  },
  buttonPaymentDisabled: {
    flex: 1,
    borderRadius: 0,
    marginTop: 100,
  },
  transactionFeeStyle: {
    fontFamily: 'GT Walsheim',
    color: color.black38,
    fontSize: '13px',
    fontWeight: 500,
  },
  footerButtonsContainer: {
    bottom: 12,
    left: 12,
    right: 12,
    position: 'fixed',
  },
  footerButton: {
    padding: '16px 16px 16px 16px',
    backgroundColor: color.buttonBackgroundSecondary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.light,
    fontWeight: 600,
    borderRadius: 6,
  },
  footerButtonSecondary: {
    marginTop: 12,
    padding: '16px 16px 16px 16px',
    backgroundColor: color.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.black87,
    fontWeight: 600,
    borderRadius: 6,
    border: `${color.borderColor} 2px solid`,
  },
  disabled: {
    padding: '16px 16px 16px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    borderRadius: 6,
    backgroundColor: color.disabled,
    color: color.black38,
  },
});

export interface PaymentDetailStyles extends WithStyles<typeof styles> {}

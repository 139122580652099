import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceMock from '../__mocks__/ApiServiceMock';
import { AppSettings } from '../../AppSettings';
import ApiServiceBase from '../ApiServiceBase';
import { PostCommonServiceAddressSaveRequest } from './getCommonService/GetCommonService.data';
import { PropertyDetails } from '../electricityService/getElectricityService/GetElectricityService.data';
import { ServiceDetail } from '../../containers/services/commonServices/CommonPlanSelectionInterface';
import { Referal } from '../../component/services/commonService/kannopiService/KanopiInterface';

const addressServiceType = ServiceType.GetAddress;
const bffServiceType = ServiceType.bffCommonService;

export class CommonServiceService {
  private readonly bffService: ApiServiceBase = AppSettings.currentTestSetting.testing
    ? new ApiServiceMock(bffServiceType)
    : new ApiService(bffServiceType);

  private readonly addressService: ApiServiceBase = AppSettings.currentTestSetting.testing
    ? new ApiServiceMock(addressServiceType)
    : new ApiService(addressServiceType);

  public getCommonServiceAddress(propertyId: string): Promise<PropertyDetails> | PropertyDetails {
    return this.addressService.get({
      route: ['property', propertyId],
    });
  }

  public getCommonServiceAddressLookup(
    propertyId: string,
    serviceType: string,
    providerName: string,
    //TODO: Resolve any
  ): Promise<any> | any {
    return this.bffService.get({
      route: ['lookup-service-address'],
      query: { propertyId, serviceType, supplierId: providerName },
    });
  }

  public postCommonServiceAddressSave(
    data: PostCommonServiceAddressSaveRequest,
    //TODO: Resolve any
  ): Promise<any> | any {
    return this.addressService.post(
      {
        route: ['service-account/usage'],
      },
      data,
    );
  }

  //TODO: Resolve any
  public postCommonPlanSummarySave(data: ServiceDetail): Promise<any> | any {
    return this.addressService.post(
      {
        route: ['service-account/usage'],
      },
      data,
    );
  }

  public postCommonPersonalDetailsSave(
    data: ServiceDetail,
    //TODO: Resolve any
  ): Promise<any> | any {
    return this.addressService.post(
      {
        route: ['service-account/usage'],
      },
      data,
    );
  }

  //TODO: Resolve any
  public postGetCommonQuotes(data: any): Promise<any> | any {
    return this.bffService.post(
      {
        route: ['quotesV3'],
      },
      data,
    );
  }
  public postGetIframeUrl(data: Referal, url: string, token: string): Promise<any> | any {
    return this.bffService.postOtherService(url, data, token);
  }
}

const commonServiceService = new CommonServiceService();
export default commonServiceService;

import React, { FC } from 'react';
import { withStyles } from '@material-ui/core';
import { styles, MainContainerStyles } from './MainContainerStyles';

interface MainContainerProps extends MainContainerStyles {
  width100?: boolean;
  height100?: boolean;
  noPadding?: boolean;
  noMaxWidth?: boolean;
}

const MainContainer: FC<MainContainerProps> = ({
  children,
  classes,
  height100,
  width100,
  noPadding,
  noMaxWidth,
}) => {
  return (
    <div
      className={`
        ${classes.paddingContainer} 
        ${width100 ? classes.width100 : undefined} 
        ${noPadding ? classes.noPadding : undefined}
        ${height100 ? classes.height100 : undefined}
      `}
    >
      <div
        className={`
          ${classes.mainContainer}
          ${height100 ? classes.height100 : undefined}
          ${noMaxWidth ? classes.noMaxWidth : undefined}
        `}
      >
        {children}
      </div>
    </div>
  );
};
export default withStyles(styles)(MainContainer);

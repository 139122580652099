import { HeaderState, Params } from '../state/HeaderState';
import { HeaderAction, HeaderActionTypes } from '../actions/HeaderActions';

const initialState: HeaderState = {
  count: 0,
  params: {
    propertyId: '',
    paymentRefId: '',
    serviceAccountId: '',
    agencyName: '',
  },
};

export const headerReducer = (state = initialState, action: HeaderAction) => {
  switch (action.type) {
    case HeaderActionTypes.SaveCount:
      return {
        ...state,
        count: state.count + (action.data as number),
      };
    case HeaderActionTypes.SaveParams:
      return {
        ...state,
        params: action.data as Params,
      };
    default:
      return state;
  }
};

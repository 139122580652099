import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import backArrow from '../../../assets/arrow-back-black.svg';
import chatMessage from '../../../assets/chat-black.svg';
import { styles, WalletHeaderStyles } from './WalletHeaderStyles';
import { Typography } from '@material-ui/core';

interface PropsFromParent {
  handleGoBack: () => void;
  handleChat: () => void;
  urlCheck: boolean;
  label: string;
}

type OwnProps = PropsFromParent & WalletHeaderStyles;

class WalletHeader extends React.Component<OwnProps, {}> {
  public render() {
    const { classes, handleChat, handleGoBack, urlCheck } = this.props;
    return !urlCheck ? (
      <div />
    ) : (
      <AppBar position="fixed" className={classes.root}>
        <div className={classes.imageContainer}>
          <img src={backArrow} onClick={handleGoBack} className={classes.logoStyle} />
          <img src={chatMessage} onClick={handleChat} className={classes.logoStyle} />
        </div>
        <div>
          <Typography variant="h5" className={classes.labelStyle}>
            {this.props.label}
          </Typography>
        </div>
      </AppBar>
    );
  }
}

export default withStyles(styles)(WalletHeader);

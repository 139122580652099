import BANK_ACCOUNT_LOGO from '../../../assets/images/payment/account-balance-24-px.svg';
import MASTER_CARD_LOGO from '../../../assets/images/payment/mc-symbol.svg';
import VISA_CARD_LOGO from '../../../assets/images/payment/visa-credit-card.svg';
export interface PaymentMethod {
  name: string;
  cardType?: string;
  logo: any;
}
export interface PaymentMethods {
  Bank: PaymentMethod;
  Credit: PaymentMethod[];
}
export const paymentMethod: PaymentMethods = {
  Bank: {
    name: 'Bank Account',
    logo: BANK_ACCOUNT_LOGO,
  },
  Credit: [
    {
      name: 'Credit Card',
      cardType: 'Mastercard',
      logo: MASTER_CARD_LOGO,
    },
    {
      name: 'Credit Card',
      cardType: 'Visa',
      logo: VISA_CARD_LOGO,
    },
  ],
};

export enum paymentTypes {
  Bank = 'Bank',
  Credit = 'Credit',
}
export const deletePaymentSuccessMessage = 'Payment type deleted';

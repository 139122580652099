import * as redux from 'redux';
import createSagaMiddleware from 'redux-saga';
import sagas from '../Sagas';
import reducers, { ApplicationState } from './RootReducers';
import LogRocket from 'logrocket';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

function saveToLocalStorage(state: ApplicationState) {
  try {
    const serialisedState = JSON.stringify(state);
    localStorage.setItem('state', serialisedState);
  } catch (e) {
    console.log(e);
  }
}

function loadFromLocalStorage() {
  try {
    const serialisedState = localStorage.getItem('state');
    if (serialisedState === null) {
      return undefined;
    }
    return JSON.parse(serialisedState);
  } catch (e) {
    console.log(e);
    return undefined;
  }
}

const persistConfig = {
  key: 'token',
  storage: storage,
  whitelist: ['token'], // which reducer want to store
};

const persistReducers = persistReducer(persistConfig, reducers);

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || redux.compose;
const sagaMiddleware = createSagaMiddleware();

// Creates store and sets it into singleton (Store.ts/store)
const createAppStore = (testing?: boolean | undefined | null) => {
  const persistedStore = loadFromLocalStorage();

  store = redux.createStore(
    persistReducers,
    // persistedStore,
    composeEnhancers(redux.applyMiddleware(sagaMiddleware, LogRocket.reduxMiddleware())),
  );

  store.subscribe(() => saveToLocalStorage(store.getState()));

  sagaMiddleware.run(sagas);

  const persistor = persistStore(store);
  return { persistor, store };
};

export type AppStore = redux.Store<ApplicationState>;
export let store: AppStore;

// initialize
createAppStore();

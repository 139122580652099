/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, ReactNode, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { styles, NavBarStyles } from './NavBarStyles';
import MainContainer from '../mainContainer/MainContainer';

interface NavBarProps extends NavBarStyles {
  subTitle?: string;
  subText?: string;
  showRightContent?: boolean;
}

const NavBar: FC<NavBarProps> = ({ classes, subTitle, subText, showRightContent, children }) => {
  return (
    <>
      <div className={classes.navContainer}>
        <MainContainer>
          {!!subTitle && (
            <div className={classes.bottomNavContainer}>
              <div className={classes.titleContainer}>
                <div className={classes.bottomTitle}>{subTitle}</div>
                {!!subText && <div className={classes.bottomSubtitle}>{subText}</div>}
              </div>
              {showRightContent && <div className={classes.bottomRightContent}>{children}</div>}
            </div>
          )}
        </MainContainer>
      </div>
    </>
  );
};

export default withStyles(styles)(NavBar);

import * as Yup from 'yup';
export const expiryDateError = 'Invalid date';
export const expiryDatePattern = [/[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/];
export const cardNumberPattern = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  ' ',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];
export const errorMessages = {
  required: 'Required field',
};
export const Validation = Yup.object().shape({
  name: Yup.string()
    .required(errorMessages.required)
    .max(20)
    .matches(/^(\w+\s*\w*)$/, 'Please enter only alphabetical letters'),
  cardNumber: Yup.string().required(errorMessages.required),
  expiry: Yup.string().required(errorMessages.required).min(5).max(5),
});

export enum CardType {
  healthCare = 'healthCare',
  pensionerCard = 'pensionerCard',
  glodCard = 'goldCard',
}

export enum CardTypeValue {
  healthCareCard = 'HealthCareCard',
  pensionerCard = 'PensionerConcessionCard',
  glodCard = 'GoldCard',
}

export interface ConcessionCardTitle {
  type: string;
  title: string;
}
export const concessionCardTitle: ConcessionCardTitle[] = [
  {
    type: 'healthCare',
    title: 'Health Care Card',
  },
  {
    type: 'pensionerCard',
    title: "Pensioner's Concession Card",
  },
  {
    type: 'goldCard',
    title: 'Gold Card',
  },
];

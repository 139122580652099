import React, { Component } from 'react';
import {
  Card,
  Typography,
  withStyles,
  MobileStepper,
  Button,
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  FormControl,
  InputLabel,
  Input,
} from '@material-ui/core';
import { Styles, PlanSelectionStyles } from './PlanSelectionStyles';
import { ServiceSummaryResponse } from '../../../containers/services/otherServices/OtherServicesSummary.data';
import SwipeableViews from 'react-swipeable-views';
import gasIcon from '../../../assets/gas-fill.svg';
import * as Labels from './PlanSelectionUtil';
import calendarIcon from '../../../assets/outlined.svg';
import cardIcon from '../../../assets/credit-card-24-px.svg';
import arrowForward from '../../../assets/arrow-forward.svg';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { get, isNil, isUndefined } from 'lodash';
import ReactHTMLParser from 'react-html-parser';
import { Formik } from 'formik';
import { History } from 'history';
import CustomInput from '../../customInput/CustomInput';
import {
  FeatureDetails,
  ConcessionCard,
  ProductDetails,
  BenefitsObject,
  QuotesV3,
  PlanDetailObject,
  Fees,
  PlanOption,
  Options,
  SupplierOptions,
  SupplierOption,
  Features,
  Rate,
  Link,
  FooterPricing,
} from '../../../containers/services/commonServices/CommonPlanSelectionInterface';
import { dollarFormatter } from '../../../helper/CostHelper';
import { ApplicationState } from '../../../store/RootReducers';
import { connect } from 'react-redux';
import ToggleButtons from './ToggleButtons';
import { AnyAction } from 'redux';
import { PlanCommonSummaryActions } from '../../../store/actions/CommonSummaryActions';
import { sendMessageToApp, ValidMessages } from '../../../helper/MessageHelper';
import ConcessionCardDetail from './concessionCard/ConcessionCard';
import CheckBoxComponent from './CheckBox';
import TextBoxComponent from './TextBox';
import DropDownComponent from './DropDown';
import RadioButtonComponent from './RadioButton';

interface PropsFromPage {
  readonly onBtnClick: (
    planSummary: FeatureDetails[],
    productDetail: ProductDetails | null,
    concessionCard: ConcessionCard | null,
    paymentCardRequired: boolean,
    benefits: PlanDetailObject,
  ) => void;
  readonly serviceSummary: ServiceSummaryResponse;
  readonly serviceName: string;
  readonly planIndex: string;
}

interface PropsFromRoute {
  history: History;
}

interface CustomInputProps {
  readonly inputRef: (ref: HTMLInputElement | null) => void;
}

interface PropsFromDispatch {
  storePlanSummaryData: (
    serviceDetails: FeatureDetails[],
    productDetails: ProductDetails | null,
    concessionCard: ConcessionCard | null,
    paymentCardRequired: boolean,
    benefits: PlanDetailObject,
  ) => void;
}

interface State {
  activePlanIndex: number;
  selections: FeatureDetails[];
  selectedDate: string;
  concessionDetail: boolean;
  cardType: string;
  stopSaleProcess: boolean;
  divHeight: number;
  swipeIndex: number;
  disableMoveForward: boolean;
  questionAnswered: number;
  totalQuestions: number;
  removeQuestionAnswered: number;
  optionProductId: string;
  cardName: string;
  stopSaleList: string[];
  selectionAns: Map<string, FeatureDetails[]> | {};
  toggleSelection: number | null;
  productDetails: ProductDetails | null;
  caardNumber: string;
  costEstimationDisplay: boolean;
  expiry: string;
  monthlyCost: PlanDetailObject;
  pricingLabel: string;
  additionalCost: number;
  costHelpText: string | undefined;
  optionWidgets: Map<string, FeatureDetails[]> | {};
  frequency: string | unknown;
}

interface PropsFromState {
  quotes?: QuotesV3;
}

type OwnProps = PlanSelectionStyles &
  PropsFromPage &
  PropsFromRoute &
  PropsFromState &
  PropsFromDispatch;

class PlanSelection extends Component<OwnProps, State> {
  private cardExpiryValidator = CustomInput(Labels.expiryDatePattern);

  public constructor(props: OwnProps) {
    super(props);
    const selectedIndex = parseInt(props.planIndex, 10);
    const { quotes } = this.props;
    this.cardDetails.bind(this);

    this.state = {
      activePlanIndex: !isNaN(selectedIndex) ? selectedIndex : 0,
      selectionAns: {},
      questionAnswered: 0,
      totalQuestions: 0,
      removeQuestionAnswered: 0,
      toggleSelection: null,
      disableMoveForward: true,
      divHeight: 0,
      optionProductId: '',
      swipeIndex: 0,
      stopSaleList: [],
      monthlyCost: (quotes as QuotesV3).Response.Plans[0].Plans[selectedIndex],
      pricingLabel: (quotes as QuotesV3).Response.Plans[0].Plans[selectedIndex].FooterPricing[0]
        .UnitOfMeasureLabel,
      costEstimationDisplay: true,
      selectedDate: '',
      cardName: '',
      optionWidgets: {},
      stopSaleProcess: false,
      caardNumber: '',
      productDetails: {
        productName: '',
        monthlyCost: '',
        dailyCost: '',
        productCode: '',
        productId: '',
        productDescription: '',
        productType: (quotes as QuotesV3).Response.Plans[0].ProductType,
      },
      expiry: '',
      cardType: '',
      concessionDetail: false,
      additionalCost: 0,
      costHelpText: undefined,
      selections: [],
      frequency: (quotes as QuotesV3).Response.Plans[0].Plans[selectedIndex].FooterPricing[0]
        .PriceField,
    };

    this.disableWeekends = this.disableWeekends.bind(this);
  }

  public componentDidUpdate() {
    this.updateCosts();
  }

  public updateCosts = () => {
    const { activePlanIndex } = this.state;
    const { quotes } = this.props;
    const serviceSummary = quotes;
    const selectedPlan = get(serviceSummary, `Plans.0.Plans.${activePlanIndex}`, {});
    const options = get(selectedPlan, `Options`, []);
    const fees = get(selectedPlan, `Fees`, []);

    if (options.length) {
      options.forEach((option: PlanDetailObject) => {
        if (get(option, 'Options', []).length === 1) {
          const feeId = get(option, 'Options.0.FeeId', []);
          const additionalCost = get(
            fees.filter((f: Fees) => f.Id === feeId[0]),
            '0.FeeItems.0.Rate',
            undefined,
          );
          const costHelpText = get(
            fees.filter((f: Fees) => f.Id === feeId[0]),
            '0.FeeItems.0.Label',
            undefined,
          );

          this.setState({
            monthlyCost: selectedPlan,
            additionalCost,
            costHelpText,
          });
        }
      });
    }
  };

  public componentDidMount() {
    window.scrollTo(0, 0);
  }

  public saveSelection = (
    type: string,
    label: string,
    labelId: string,
    value: string,
    valueId: string,
  ) => {
    const obj = {
      type,
      label,
      value,
      valueId,
      labelId,
    };
    const { selections } = this.state;
    const newObj = selections.filter((a: FeatureDetails) => a.labelId !== labelId);
    newObj.push(obj);
    this.setState({ selections: newObj });
  };

  public getValue = (labelId: string) => {
    const { selections } = this.state;
    const filteredObj = selections.filter((a: FeatureDetails) => a.labelId === labelId)[0];
    return filteredObj ? filteredObj.valueId || null : null;
  };

  public getToggleValue = (labelId: string) => {
    const { selections } = this.state;
    const filteredObj = selections.filter((a: FeatureDetails) => a.labelId === labelId)[0];
    return filteredObj ? filteredObj.value || null : null;
  };

  public getFooterValue = (labelId: string | null, optionObj: Options[]) => {
    if (optionObj && optionObj.length) {
      const filteredObj = optionObj.filter((a: Options) => a.ProductId === labelId)[0];
      return filteredObj && filteredObj.HelpText
        ? ReactHTMLParser(filteredObj.HelpText) || ''
        : filteredObj && filteredObj.ComponentText
        ? ReactHTMLParser(filteredObj.ComponentText) || ''
        : '';
    } else {
      return '';
    }
  };

  public renderKeyValue = (key: string, value: string) => {
    const { classes } = this.props;
    return (
      <div className={classes.keyValueContainer}>
        <Typography variant="caption" className={classes.keyText}>
          {ReactHTMLParser(key)}:
        </Typography>
        <Typography variant="h1" className={`${classes.value} ${classes.gtwLight}`}>
          {ReactHTMLParser(value)}
        </Typography>
      </div>
    );
  };

  public renderKeyValueColumn = (key: string, value: string) => {
    const { classes } = this.props;
    return (
      <div className={classes.keyValueContainerColumn}>
        <Typography variant="caption" className={classes.keyTextColumn}>
          {ReactHTMLParser(key)}:
        </Typography>
        <Typography variant="h1" className={`${classes.value} ${classes.gtwLight}`}>
          {ReactHTMLParser(value)}
        </Typography>
      </div>
    );
  };

  public disableWeekends(date: Date, option: SupplierOptions) {
    let flag = 0;
    const { quotes } = this.props;
    if (option.PublicHolidayExclusion) {
      const checkDate =
        date.getDate() < 9
          ? date.getFullYear().toString() +
            '-' +
            (date.getMonth() + 1).toString() +
            '-0' +
            date.getDate().toString()
          : date.getFullYear().toString() +
            '-' +
            (date.getMonth() + 1).toString() +
            '-' +
            date.getDate().toString();
      if ((quotes as QuotesV3).Response.Plans[0].PublicHolidays.includes(checkDate)) {
        flag = 1;
      }
    }
    if (option.ComponentDateExcludeWeekend) {
      if (date.getDay() === 0 || date.getDay() === 6) {
        flag = 1;
      }
    }
    if (flag === 1) {
      return true;
    } else {
      return false;
    }
  }

  public optionElement = (
    option: SupplierOptions,
    featureId: string,
    index: number,
    activePlanIndex: number,
    selection: SupplierOption,
  ) => {
    const { classes } = this.props;
    if (option.Group === 'ConcessionDetailsAgreement') {
      this.setState({ totalQuestions: this.state.totalQuestions + 3 });
      this.setState({ concessionDetail: true });
    }
    return (
      <div>
        {option.Group === 'ConcessionDetailsAgreement' && this.cardDetails()}
        <ToggleButtons
          index={index}
          activeIndex={this.state.swipeIndex}
          classes={classes}
          option={option}
          getValue={this.getValue}
          // tslint:disable-next-line:cyclomatic-complexity
          callBackHandler={async (optionSelection, widgetIndex: number, date?: Date) => {
            const filterselectionAns = this.state.selectionAns[featureId].filter(
              (item: FeatureDetails) => {
                return item.labelId === get(option, 'Group', '');
              },
            );
            if (
              (isUndefined(this.state.optionWidgets[option.Group]) ||
                get(option, 'ComponentType', '') === 'Date') &&
              filterselectionAns.length === 0
            ) {
              await this.setState({
                questionAnswered: this.state.questionAnswered + 1,
              });
              this.setState({
                disableMoveForward: !(this.state.questionAnswered === this.state.totalQuestions),
              });
            } else if (date === null && filterselectionAns.length > 0) {
              await this.setState({
                questionAnswered: this.state.questionAnswered - 1,
              });
              this.setState({
                disableMoveForward: !(this.state.questionAnswered === this.state.totalQuestions),
              });
            }

            if (this.state.questionAnswered === this.state.totalQuestions) {
              this.setState({ disableMoveForward: false });
            }
            if (isNil(date) && get(option, 'ComponentType', '') !== 'Date') {
              if (
                !isNil((optionSelection as SupplierOption).Option) &&
                !isNil((optionSelection as SupplierOption).Option.Group)
              ) {
                if (
                  (optionSelection as SupplierOption).Option.Group === 'ConcessionDetailsAgreement'
                ) {
                  this.setState({ concessionDetail: true });
                  const dummy = {
                    [featureId]: [this.state.optionWidgets[featureId], this.cardDetails()],
                  };
                  this.setState({
                    optionWidgets: { ...this.state.optionWidgets, ...dummy },
                  });
                } else {
                  this.optionWidget(
                    (optionSelection as SupplierOption).Option,
                    featureId,
                    widgetIndex + 1,
                    this.state.swipeIndex,
                    optionSelection as SupplierOption,
                  );
                }
              } else {
                const newOptionWidget = this.state.optionWidgets[featureId];
                const newSelectionAns = this.state.selectionAns[featureId];

                const questionRemovedLength = newOptionWidget.length - widgetIndex - 1;
                await this.setState({
                  totalQuestions: this.state.totalQuestions - questionRemovedLength,
                });
                newOptionWidget.splice(widgetIndex + 1);
                newSelectionAns.splice(widgetIndex + 2);

                const dummy = { [featureId]: [...newOptionWidget] };
                const selectionObj = { [featureId]: [...newSelectionAns] };
                this.setState(
                  {
                    optionWidgets: { ...this.state.optionWidgets, ...dummy },
                    selectionAns: { ...this.state.selectionAns, ...selectionObj },
                  },
                  () => {},
                );
              }
            } else {
              const monthForReview =
                date !== null
                  ? parseInt(((date as Date).getMonth() + 1).toString(), 10) < 10
                    ? '0' + parseInt(((date as Date).getMonth() + 1).toString(), 10)
                    : parseInt(((date as Date).getMonth() + 1).toString(), 10)
                  : '';

              const dateReview =
                date !== null
                  ? (date as Date).getDate() < 10
                    ? '0' + (date as Date).getDate()
                    : (date as Date).getDate()
                  : '';

              const optionAnsObject = {
                type: 'answers',
                label:
                  option.Group === 'Concession'
                    ? `${get(option, 'Heading', '')}`
                    : `${
                        get(optionSelection, 'Heading', '')
                          ? ReactHTMLParser(get(optionSelection, 'Heading', ''))
                          : ReactHTMLParser(get(optionSelection, 'Heading', ''))
                      }`,
                labelId: get(option, 'Group', ''),
                value:
                  date !== null
                    ? (date as Date).getFullYear() + '-' + monthForReview + '-' + dateReview
                    : '',
                valueId:
                  date !== null
                    ? (date as Date).getFullYear() + '-' + monthForReview + '-' + dateReview
                    : '',
              };
              const optionSelcetObject = {
                [featureId]: [...this.state.selectionAns[featureId], optionAnsObject],
              };
              this.state.selectionAns[featureId].indexOf(filterselectionAns);
              if (filterselectionAns.length === 0)
                this.setState({
                  selectionAns: {
                    ...this.state.selectionAns,
                    ...optionSelcetObject,
                  },
                });
              else if (date !== null) {
                const selectionValue = this.state.selectionAns;
                selectionValue[featureId].map((item: FeatureDetails) => {
                  if (item.labelId === get(option, 'Group', '')) {
                    item.valueId = optionAnsObject.valueId;
                    item.value = optionAnsObject.value;
                  }
                });
                this.setState({ selectionAns: selectionValue });
              } else {
                const selection = this.state.selectionAns;
                selection[featureId] = selection[featureId].filter((item: FeatureDetails) => {
                  return item.labelId !== get(option, 'Group', '');
                });
                this.setState({ selectionAns: selection });
              }
              if (!isUndefined((optionSelection as SupplierOption).Option)) {
                this.optionWidget(
                  (optionSelection as SupplierOption).Option,
                  featureId,
                  widgetIndex + 1,
                  this.state.swipeIndex,
                  optionSelection as SupplierOption,
                );
              }
            }
          }}
        />
      </div>
    );
  };

  // tslint:disable-next-line:cyclomatic-complexity
  public optionWidget = async (
    option: SupplierOptions,
    featureId: string,
    index: number,
    activePlanIndex: number,
    selection: SupplierOption,
  ) => {
    if (this.state.optionWidgets[featureId] !== undefined) {
      let optionAnsObject: FeatureDetails = {};
      if (
        option &&
        option.ComponentId !== 'ConnectionDateInput' &&
        option.Group !== 'ConcessionDetailsAgreement'
      ) {
        optionAnsObject = {
          type: 'answers',
          label:
            option.Group === 'Concession'
              ? `${get(option, 'Heading', '')}`
              : `${
                  get(option, 'Heading', '')
                    ? ReactHTMLParser(get(option, 'Heading', ''))
                    : ReactHTMLParser(get(option, 'Heading', ''))
                }`,
          labelId: get(option, 'Group', ''),
          value: `${
            get(option, 'ComponentType', '') === 'Date' ? '21/08/2020' : get(selection, 'Label', '')
          }`,
          valueId: `${
            get(option, 'ComponentType', '') === 'Date'
              ? '21/08/2020'
              : get(selection, 'ProductId', '')
              ? get(selection, 'ProductId', '')
              : get(selection, 'Label', '')
          }`,
        };
      }
      const optionSelcetObject =
        option &&
        option.ComponentId !== 'ConnectionDateInput' &&
        option.Group !== 'ConcessionDetailsAgreement'
          ? {
              [featureId]: [...this.state.selectionAns[featureId], optionAnsObject],
            }
          : {};
      await this.setState({ totalQuestions: this.state.totalQuestions + 1 });
      if (this.state.questionAnswered === this.state.totalQuestions) {
        this.setState({ disableMoveForward: false });
      } else {
        this.setState({ disableMoveForward: true });
      }
      const dummy = {
        [featureId]: [
          this.state.optionWidgets[featureId],
          this.optionElement(option, featureId, index, this.state.swipeIndex, selection),
        ],
      };
      this.setState({
        optionWidgets: { ...this.state.optionWidgets, ...dummy },
        selectionAns: { ...this.state.selectionAns, ...optionSelcetObject },
      });
    } else {
      const optionAnsObject: FeatureDetails =
        option &&
        option.ComponentId !== 'ConnectionDateInput' &&
        option.Group !== 'ConcessionDetailsAgreement'
          ? {
              type: 'answers',
              label:
                option.Group === 'ConcessionDetailsAgreement'
                  ? `${get(option, 'Heading', '')}`
                  : `${
                      get(option, 'Heading', '')
                        ? ReactHTMLParser(get(option, 'Heading', ''))
                        : ReactHTMLParser(get(option, 'Heading', ''))
                    }`,
              labelId: get(option, 'Group', ''),
              value: `${
                get(option, 'ComponentType', '') === 'Date'
                  ? '21/08/2020'
                  : get(selection, 'Label', '')
              }`,
              valueId: `${
                get(option, 'ComponentType', '') === 'Date'
                  ? '21/08/2020'
                  : get(selection, 'ProductId', '')
                  ? get(selection, 'ProductId', '')
                  : get(selection, 'Label', '')
              }`,
            }
          : {};

      const optionSelcetObject =
        option &&
        option.ComponentId !== 'ConnectionDateInput' &&
        option.Group !== 'ConcessionDetailsAgreement'
          ? { [featureId]: [optionAnsObject] }
          : {};

      await this.setState({ totalQuestions: this.state.totalQuestions + 1 });
      if (this.state.questionAnswered === this.state.totalQuestions) {
        this.setState({ disableMoveForward: false });
      } else {
        this.setState({ disableMoveForward: true });
      }

      const dummy = {
        [featureId]: [
          this.optionElement(option, featureId, index, this.state.swipeIndex, selection),
        ],
      };

      this.setState({
        optionWidgets: { ...dummy },
        selectionAns: { ...this.state.selectionAns, ...optionSelcetObject },
      });
    }
    return <div />;
  };
  public func = (option: SupplierOptions) => {
    return (
      <div>
        {this.state.optionWidgets[option.Group].length > 0 &&
          this.state.optionWidgets[option.Group].map((item: Component) => item)}
      </div>
    );
  };

  public incrementCount = async (increment: boolean, removeQuestion: boolean) => {
    await this.setState({
      questionAnswered: increment
        ? this.state.questionAnswered + 1
        : this.state.questionAnswered - 1,
      removeQuestionAnswered: removeQuestion
        ? this.state.removeQuestionAnswered + 1
        : this.state.removeQuestionAnswered - 1,
    });
    if (this.state.questionAnswered === this.state.totalQuestions) {
      this.setState({ disableMoveForward: false });
    } else {
      this.setState({ disableMoveForward: true });
    }

    if (this.state.questionAnswered === this.state.totalQuestions) {
      this.setState({ disableMoveForward: false });
    }
  };

  public cardDetails = () => {
    const { classes } = this.props;
    const initialValues = {
      name: this.state.cardName,
      expiry: this.state.expiry,
      cardNumber: this.state.caardNumber,
    };
    return (
      <div className={classes.cardStyle}>
        <Typography variant="h6">Add card details</Typography>
        <div className={classes.seperator} />
        <Formik
          initialValues={initialValues}
          // tslint:disable-next-line:no-empty
          onSubmit={(values) => () => {}}
          validationSchema={Labels.Validation}
          render={({ values, setFieldValue, handleSubmit, isValid }) => (
            <div className={classes.form}>
              <ConcessionCardDetail
                incrementCount={this.incrementCount}
                setValue={(value: string) => {
                  this.setState({ cardType: value });
                }}
              />
              <FormControl className={classes.inputs} error={false}>
                <InputLabel>Card Holder Name</InputLabel>
                <Input
                  name="name"
                  onChange={(event) => {
                    if (event.target.value.length === 0) {
                      this.incrementCount(false, true);
                    } else if (event.target.value.length > 0 && this.state.cardName.length === 0) {
                      this.incrementCount(true, true);
                    }
                    this.setState({ cardName: event.target.value });
                    return setFieldValue('name', event.target.value);
                  }}
                  value={values.name}
                />
              </FormControl>
              <FormControl className={classes.inputs} error={false}>
                <InputLabel>Card Number</InputLabel>
                <Input
                  endAdornment={
                    <InputAdornment position="end">
                      <img src={cardIcon} />
                    </InputAdornment>
                  }
                  name="cardNumber"
                  onChange={(event) => {
                    if (event.target.value.length === 0) {
                      this.incrementCount(false, true);
                    } else if (
                      event.target.value.length > 0 &&
                      this.state.caardNumber.length === 0
                    ) {
                      this.incrementCount(true, true);
                    }
                    this.setState({ caardNumber: event.target.value });
                    return setFieldValue('cardNumber', event.target.value);
                  }}
                  value={values.cardNumber}
                />
              </FormControl>
              <FormControl className={classes.inputs} error={false}>
                <InputLabel>Expiry Date MM/YY</InputLabel>
                <Input
                  name="expiry"
                  value={values.expiry}
                  inputComponent={this.cardExpiryValidator as (props: CustomInputProps) => void}
                  onChange={(event) => {
                    if (
                      event.target.value.length === 0 &&
                      this.state.expiry !== event.target.value
                    ) {
                      this.incrementCount(false, true);
                    } else if (event.target.value.length > 0 && this.state.expiry.length === 0) {
                      this.incrementCount(true, true);
                    }
                    this.setState({ expiry: event.target.value });

                    setFieldValue('expiry', event.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <img src={calendarIcon} />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
          )}
        />
      </div>
    );
  };

  public async handleChange(option: SupplierOptions, value: string | Date) {
    if (`${value}`.length && value !== null) {
      if (get(option, 'ComponentType', '') !== 'Date') {
        const optionAnsObject: FeatureDetails = {
          type: 'answers',
          label:
            option.Group === 'Concession'
              ? `${get(option, 'Heading', '')}`
              : get(option, 'Heading', ''),
          labelId: get(option, 'Group', ''),
          value: value as string,
          valueId: value as string,
        };

        const optionSelcetObject = {
          [option.Group]: [optionAnsObject],
        };
        if (get(option.Options[0], 'StopSaleProcess', false)) {
          this.state.stopSaleList.push(get(option, 'Group', ''));
        } else {
          const newList = this.state.stopSaleList.filter(
            (value) => value !== get(option, 'Group', ''),
          );
          this.setState({ stopSaleList: newList });
        }
        this.setState({
          selectionAns: {
            ...this.state.selectionAns,
            ...optionSelcetObject,
          },
          stopSaleProcess: get(option.Options[0], 'StopSaleProcess', false),
        });
        if (!isNil(option.Options[0].Option) && !isNil(option.Options[0].Option.Group)) {
          if (
            !isUndefined(this.state.optionWidgets[option.Group]) &&
            this.state.selectionAns[option.Group].length > 1
          ) {
            await this.setState({
              totalQuestions: this.state.totalQuestions - 1,
              questionAnswered:
                this.state.questionAnswered - this.state.selectionAns[option.Group].length + 1,
            });
            if (this.state.concessionDetail) {
              await this.setState({
                concessionDetail: false,
                cardType: '',
                cardName: '',
                caardNumber: '',
                expiry: '',
                totalQuestions: this.state.totalQuestions - 3,
                questionAnswered: this.state.questionAnswered - this.state.removeQuestionAnswered,
              });
              await this.setState({ removeQuestionAnswered: 0 });
            }
            if (this.state.questionAnswered === this.state.totalQuestions) {
              this.setState({
                disableMoveForward: false,
              });
            } else {
              this.setState({ disableMoveForward: true });
            }
          }
          this.setState({
            selectionAns: {
              ...this.state.selectionAns,
              ...optionSelcetObject,
            },
          });
        } else {
          if (!isUndefined(this.state.optionWidgets[option.Group])) {
            this.setState({ stopSaleProcess: false });
          }
          if (
            !isUndefined(this.state.optionWidgets[option.Group]) &&
            this.state.optionWidgets[option.Group].length > 0
          ) {
            await this.setState({
              totalQuestions: this.state.totalQuestions - 1,
              questionAnswered:
                this.state.questionAnswered - this.state.selectionAns[option.Group].length + 1,
            });
            if (this.state.concessionDetail) {
              await this.setState({
                concessionDetail: false,
                cardType: '',
                cardName: '',
                caardNumber: '',
                expiry: '',
                totalQuestions: this.state.totalQuestions - 3,
                questionAnswered: this.state.questionAnswered - this.state.removeQuestionAnswered,
              });
              await this.setState({ removeQuestionAnswered: 0 });
            }
            if (this.state.questionAnswered === this.state.totalQuestions) {
              this.setState({
                disableMoveForward: false,
              });
            } else {
              this.setState({ disableMoveForward: true });
            }
          }
          const newOPtionWidget = {
            [option.Group]: [],
          };

          this.setState({
            optionWidgets: {
              ...this.state.optionWidgets,
              ...newOPtionWidget,
            },
          });
        }
      } else {
        const monthForReview =
          parseInt(((value as Date).getMonth() + 1).toString(), 10) < 10
            ? '0' + parseInt(((value as Date).getMonth() + 1).toString(), 10)
            : parseInt(((value as Date).getMonth() + 1).toString(), 10);

        const dateReview =
          (value as Date).getDate() < 10
            ? '0' + (value as Date).getDate()
            : (value as Date).getDate();

        this.saveSelection(
          'answers',
          option.Group === 'Concession'
            ? `${get(option, 'Heading', '')}`
            : `${
                get(option, 'Heading', '')
                  ? ReactHTMLParser(get(option, 'Heading', ''))
                  : ReactHTMLParser(get(option, 'Heading', ''))
              }`,
          get(option, 'Group', ''),
          value !== null
            ? (value as Date).getFullYear() + '-' + monthForReview + '-' + dateReview
            : 'hh',
          value !== null
            ? (value as Date).getFullYear() + '-' + monthForReview + '-' + dateReview
            : 'hh',
        );
      }
      if (
        isUndefined(this.state.selectionAns[option.Group]) ||
        get(option, 'ComponentType', '') === 'Date'
      ) {
        await this.setState({
          questionAnswered: this.state.questionAnswered + 1,
        });
      }

      if (this.state.questionAnswered === this.state.totalQuestions) {
        this.setState({ disableMoveForward: false });
      } else {
        this.setState({ disableMoveForward: true });
      }
    } else {
      const selectionObj = this.state.selectionAns;
      delete selectionObj[option.Group];
      await this.setState({
        selectionAns: selectionObj,
        questionAnswered: this.state.questionAnswered - 1,
        disableMoveForward: true,
      });
    }
  }

  public renderCustomSupplierOption(option: SupplierOptions) {
    switch (option.Options[0].Option.ComponentType) {
      case 'Date':
        return (
          <Card
            elevation={2}
            className={`${this.props.classes.detailCard} ${this.props.classes.planCardContainer}`}
          >
            <ToggleButtons
              index={0}
              activeIndex={this.state.swipeIndex}
              classes={this.props.classes}
              option={option.Options[0].Option}
              getValue={this.getValue}
              // tslint:disable-next-line: cyclomatic-complexity
              callBackHandler={async (selection, index: number, date?: Date) => {
                this.handleChange(selection as SupplierOptions, date as Date);
              }}
            />
          </Card>
        );
      case 'Text':
        return (
          <TextBoxComponent
            optionValue={option.Options[0].Option}
            classes={this.props.classes}
            callBackHandler={(value) => {
              this.handleChange(option, value);
            }}
          />
        );
      case 'Dropdown':
        return (
          <DropDownComponent
            optionValue={option.Options[0].Option}
            classes={this.props.classes}
            callBackHandler={(value) => {
              this.handleChange(option, value);
            }}
          />
        );
      case 'MultiCheckbox':
        return (
          <CheckBoxComponent
            optionValue={option.Options[0].Option}
            classes={this.props.classes}
            callBackHandler={(value) => {
              this.handleChange(option, value);
            }}
          />
        );
      case 'Radio':
        return (
          <RadioButtonComponent
            optionValue={option.Options[0].Option}
            classes={this.props.classes}
            callBackHandler={(value) => {
              this.handleChange(option, value);
            }}
          />
        );
    }
    return <div />;
  }

  // tslint:disable-next-line:cyclomatic-complexity
  public render() {
    const { classes, onBtnClick, quotes } = this.props;
    const { activePlanIndex, selections, costHelpText, frequency } = this.state;

    const serviceSummary = get(quotes, 'Response', []);
    const iconMock =
      'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTobYhqImF6VoPTWr08U3Oqq2dV6pDl-TyaL8E1VrCWKSobONi2&usqp=CAU';

    const icon = get(serviceSummary, 'Plans.0.SupplierLogo', iconMock);
    const allPlans = get(serviceSummary, `Plans.0.Plans`, []);
    const serviceTypeLogo = get(serviceSummary, `Plans.0.ServiceTypeLogo`, gasIcon);
    const selectedPlan = get(serviceSummary, `Plans.0.Plans.${activePlanIndex}`, {});
    const rates = get(selectedPlan, `Rates.Rates`, []);
    const headlinePromo = get(selectedPlan, `HeadlinePromo`, null);
    const features = get(selectedPlan, `Features`, []);
    const inclusions = get(selectedPlan, `Inclusions`, []);
    const benefits = get(selectedPlan, `Benefits`, []);
    const headlines = get(selectedPlan, `Headlines`, []);
    const supplierOptions = get(serviceSummary, `Plans.0.SupplierOptions`, []);
    const options = get(selectedPlan, 'Options.0.Options', []);
    if (this.state.totalQuestions === 0) {
      this.setState({ totalQuestions: supplierOptions.length });
    }
    if (this.state.toggleSelection === null) {
      if (options.length > 1) {
        options.map((item: any, index: number) => {
          if (item.Default) {
            this.setState({
              toggleSelection: index,
              optionProductId: item.IsProduct ? item.ProductId : '',
            });
            const optionAns: FeatureDetails = {
              type: 'first',
              label: get(selectedPlan, 'Options.0.Heading', ''),
              labelId: get(selectedPlan, 'Options.0.Heading', ''),
              value: item.Label,
              valueId: item.Label,
            };
            const optionSelcetObject = {
              [`${get(selectedPlan, 'Options.0.Group', '')}`]: [optionAns],
            };
            this.setState({
              selectionAns: {
                ...this.state.selectionAns,
                ...optionSelcetObject,
              },
            });
          }
        });
      } else if (options[0].Default) {
        this.setState({
          toggleSelection: 0,
          optionProductId: options[0].IsProduct ? options[0].ProductId : '',
        });
        const optionAns: FeatureDetails = {
          type: 'first',
          label: get(selectedPlan, 'Options.0.Heading', ''),
          labelId: get(selectedPlan, 'Options.0.Heading', ''),
          value: options[0].Label,
          valueId: options[0].Label,
        };
        const optionSelcetObject = {
          [`${get(selectedPlan, 'Options.0.Group', '')}`]: [optionAns],
        };
        this.setState({
          selectionAns: {
            ...this.state.selectionAns,
            ...optionSelcetObject,
          },
        });
      } else {
        this.setState({
          toggleSelection: 0,
          optionProductId: options[0].IsProduct ? options[0].ProductId : '',
        });
      }
    }
    const productDetailsDummy: ProductDetails = {
      productName: get(
        allPlans[activePlanIndex].Options[0].Options[this.state.toggleSelection as number],
        'Label',
        '',
      ),
      monthlyCost:
        allPlans[activePlanIndex].Options[0].Options[this.state.toggleSelection as number] !== 0
          ? allPlans[activePlanIndex].Options[0].Options[this.state.toggleSelection as number]
          : this.state.monthlyCost,
      productCode: get(
        allPlans[activePlanIndex].Options[0].Options[this.state.toggleSelection as number],
        'ProductId',
        '',
      ),
      productId:
        this.state.optionProductId.length > 0
          ? this.state.optionProductId
          : get(allPlans[activePlanIndex], 'ProductId', ''),
      productDescription: get(quotes, 'Response.Plans.0.Plans.0.Description', ''),
      productType: (quotes as QuotesV3).Response.Plans[0].ProductType,
    };

    const showProviderImage = get(
      quotes,
      'Response.Plans.0.UiModelAttributes.PlansSelector.LogoIconDisplay',
      false,
    );
    const showLabel = get(quotes, 'Response.Plans.0.UiModelAttributes.PlansSelector', false);
    const expandable =
      get(quotes, 'Response.Plans.0.Plans.0.FooterPricing.0.PricingType', true) !== null;
    const headerLabel = get(quotes, 'Response.Plans.0.UiModelAttributes.PlansSelector.Value', '');

    return (
      <div>
        {true && this.state.toggleSelection !== null && (
          <div className={classes.planRoot}>
            <Card elevation={3} className={classes.selectPlanCard}>
              {showProviderImage && <img src={icon} className={classes.providerLogo} />}
              {showLabel ? (
                <Typography className={classes.selectPlanText}>{headerLabel}</Typography>
              ) : (
                <Typography className={classes.selectPlanText}>
                  Select a {(quotes as QuotesV3).Response.Plans[0].ProductType.toLowerCase()}
                </Typography>
              )}
            </Card>
            <div
              className={classes.planContainer}
              style={
                this.state.costEstimationDisplay ? { paddingBottom: 100 } : { paddingBottom: 250 }
              }
            >
              <SwipeableViews
                index={activePlanIndex}
                onChangeIndex={(activeIndex: number) =>
                  this.setState({
                    activePlanIndex: activeIndex,
                    swipeIndex: activeIndex,
                    monthlyCost: allPlans[activeIndex],
                    selectionAns: {},
                    questionAnswered: 0,
                    totalQuestions: supplierOptions.length,
                    disableMoveForward: true,
                    toggleSelection: null,
                    costEstimationDisplay: true,
                    selectedDate: '',
                    cardName: '',
                    removeQuestionAnswered: 0,
                    optionWidgets: {},
                    stopSaleProcess: false,
                    stopSaleList: [],
                    optionProductId: '',
                    caardNumber: '',
                    productDetails: {
                      productName: '',
                      monthlyCost: '',
                      dailyCost: '',
                      productCode: '',
                      productId: '',
                      productDescription: '',
                      productType: (quotes as QuotesV3).Response.Plans[0].ProductType,
                    },
                    expiry: '',
                    cardType: '',
                    concessionDetail: false,
                    additionalCost: 0,
                    costHelpText: undefined,
                    selections: [],
                    frequency: selectedPlan.FooterPricing[0].PriceField,
                  })
                }
                resistance
                enableMouseEvents
                style={{ paddingRight: allPlans.length > 1 ? 20 : 0 }}
                slideStyle={{ padding: '-10px' }}
              >
                {allPlans.length &&
                  allPlans.map((plan: PlanDetailObject) => (
                    <Card
                      key={`plans-${plan.Name}`}
                      elevation={3}
                      style={this.state.divHeight > 0 ? { height: this.state.divHeight + 40 } : {}}
                      className={`${classes.planCard} ${classes.planCardContainer}`}
                    >
                      <div className={classes.planCardHeader}>
                        <img className={classes.serviceLogo} src={serviceTypeLogo} />
                        <Typography variant="h6">{get(plan, 'Name', '')}</Typography>
                      </div>
                      <div
                        ref={(refValue: any) => {
                          if (!refValue) {
                            return;
                          }
                          if (refValue.getBoundingClientRect().height > this.state.divHeight) {
                            this.setState({ divHeight: refValue.getBoundingClientRect().height });
                          }
                        }}
                      >
                        <div
                          className={`${classes.headerText1} ${classes.gtwLight}`}
                          dangerouslySetInnerHTML={{
                            __html:
                              plan.Options[0].Description && plan.Options[0].Description.length > 0
                                ? plan.Options[0].Description
                                : plan.Options[0].Options[this.state.toggleSelection as number]
                                    .HelpText,
                          }}
                        />
                        <div
                          className={`${classes.headerText1} ${classes.gtwLight}`}
                          dangerouslySetInnerHTML={{
                            __html: get(
                              plan.Options[0].Options[this.state.toggleSelection as number],
                              'PricingHelpText',
                              'Customise your package below',
                            ),
                          }}
                        />
                      </div>
                      <div />
                    </Card>
                  ))}
              </SwipeableViews>
              {allPlans.length > 1 && (
                <MobileStepper
                  activeStep={activePlanIndex}
                  steps={allPlans.length}
                  classes={{
                    dotActive: classes.dotActive,
                    dot: classes.dot,
                  }}
                  className={classes.stepper}
                  nextButton={<span />}
                  backButton={<span />}
                  position="static"
                />
              )}
              <div style={{ marginTop: 30 }}>
                {!!headlinePromo && (
                  <Card elevation={2} className={classes.headerCard}>
                    <div className={classes.switchContainer}>
                      <Typography variant="h6">{headlinePromo.Title}</Typography>
                    </div>

                    <div className={classes.seperator} />
                    {!!rates.length && (
                      <div className={classes.section}>
                        <Typography variant="subtitle1">{headlinePromo.Description}</Typography>
                      </div>
                    )}
                    {/* <div className={classes.section}>
                    <Typography className={`${classes.keyText} ${classes.blueText}`}>{get(rates, "FooterText", "(All prices listed above include GST)")}</Typography><br />
                  </div> */}
                  </Card>
                )}
                {headlines.length > 0 ? (
                  <Card
                    elevation={2}
                    className={`${classes.detailCard} ${classes.planCardContainer}`}
                  >
                    {!!headlines.length && (
                      <div>
                        {headlines.map((headline: BenefitsObject) => (
                          <div className={classes.section} key={`headline-${headline.Heading}`}>
                            <Typography variant="caption" className={classes.keyText}>
                              {get(headline, 'Heading', '')}
                            </Typography>
                            <Typography
                              variant="h1"
                              className={`${classes.headerText2} ${classes.gtwLight}`}
                            >
                              {ReactHTMLParser(get(headline, 'Body', ''))}
                            </Typography>
                          </div>
                        ))}
                      </div>
                    )}
                    {!!features.length && (
                      <div>
                        {features.map((feature: Features) => (
                          <div className={classes.section} key={`features-${feature.Heading}`}>
                            <Typography variant="caption" className={classes.keyText}>
                              {get(feature, 'Heading', '')}
                            </Typography>
                            <Typography
                              variant="h1"
                              className={`${classes.headerText2} ${classes.gtwLight}`}
                            >
                              {ReactHTMLParser(get(feature, 'Body', ''))}
                            </Typography>
                          </div>
                        ))}
                      </div>
                    )}
                  </Card>
                ) : undefined}
                {!!rates.length && (
                  <Card elevation={2} className={classes.detailCard}>
                    <div className={classes.switchContainer}>
                      <Typography variant="h6">Rates</Typography>
                    </div>

                    <div className={classes.seperator} />
                    {!!rates.length && (
                      <div className={classes.section}>
                        {rates.map((rate: Rate) => (
                          <>
                            <div>
                              {this.renderKeyValueColumn(
                                get(rate, 'Label', ''),
                                `${get(rate, 'Rate', '')}${get(rate, 'Unit', '')}`,
                              )}
                            </div>
                            {get(rate, 'Links', []) && get(rate, 'Links', []).length > 0 ? (
                              <div className={classes.section}>
                                {rate.Links.map((link: Link) => (
                                  <div
                                    key={`rate-${link.Label}`}
                                    onClick={() => {
                                      sendMessageToApp(
                                        ValidMessages.OpenUrl,
                                        get(link, 'Link', ''),
                                      );
                                    }}
                                  >
                                    <Typography
                                      variant="h1"
                                      className={`${classes.keyText} ${classes.blueText}`}
                                    >
                                      {get(link, 'Label', '')}
                                    </Typography>
                                  </div>
                                ))}
                              </div>
                            ) : undefined}
                          </>
                        ))}
                      </div>
                    )}
                    {/* <div className={classes.section}>
                    <Typography className={`${classes.keyText} ${classes.blueText}`}>{get(rates, "FooterText", "(All prices listed above include GST)")}</Typography><br />
                  </div> */}
                  </Card>
                )}
                {inclusions.length > 0 || benefits.length > 0 ? (
                  <Card elevation={2} className={classes.detailCard}>
                    <Typography variant="h6">
                      {(quotes as QuotesV3).Response.Plans[0].UiModelAttributes.Benefit.Value}
                    </Typography>
                    <div className={classes.seperator} />
                    {!!inclusions.length && (
                      <div>
                        {inclusions.map((inclusion: BenefitsObject) => (
                          <div className={classes.section}>
                            <Typography variant="caption" className={classes.keyText}>
                              {get(inclusion, 'Heading', '')}
                            </Typography>
                            <Typography
                              variant="h1"
                              className={`${classes.headerText2} ${classes.gtwLight}`}
                            >
                              {ReactHTMLParser(get(inclusion, 'Body', ''))}
                            </Typography>
                          </div>
                        ))}
                      </div>
                    )}
                    {!!benefits.length && (
                      <div className={classes.section}>
                        {benefits.map((benefit: BenefitsObject) =>
                          this.renderKeyValue(
                            get(benefit, 'Heading', ''),
                            get(benefit, 'Body', ''),
                          ),
                        )}
                      </div>
                    )}
                  </Card>
                ) : undefined}
                {selectedPlan.Links && selectedPlan.Links.length > 0 ? (
                  <Card elevation={2} className={classes.detailCard}>
                    {(quotes as QuotesV3).Response.Plans[0].UiModelAttributes.Links.Value ? (
                      <div className={classes.switchContainer}>
                        <Typography className={classes.linkHeader} variant="h6">
                          {(quotes as QuotesV3).Response.Plans[0].UiModelAttributes.Links.Value}
                        </Typography>
                      </div>
                    ) : undefined}
                    <div className={classes.seperator} />
                    <div className={classes.section}>
                      {selectedPlan.Links.map((link: Link) => (
                        <>
                          <div className={classes.section}>
                            <div
                              key={`rate-${link.Label}`}
                              onClick={() => {
                                sendMessageToApp(ValidMessages.OpenUrl, get(link, 'Link', ''));
                              }}
                            >
                              <Typography
                                variant="h1"
                                className={`${classes.keyText} ${classes.blueText}`}
                              >
                                {get(link, 'Label', '')}
                              </Typography>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </Card>
                ) : undefined}
                {!!(selectedPlan.Options && selectedPlan.Options.length) &&
                  selectedPlan.Options.map((option: PlanOption) => (
                    <>
                      {!!(option.Options && option.Options.length > 1) && (
                        <Card
                          elevation={2}
                          className={`${classes.detailCard} ${classes.planCardContainer}`}
                        >
                          <Typography variant="h6">{get(option, 'Heading', '')}</Typography>
                          <div className={classes.seperator} />
                          <Typography
                            variant="h1"
                            className={`${classes.headerText2} ${classes.gtwLight}`}
                          >
                            {ReactHTMLParser(get(option, 'Description', ''))}
                          </Typography>
                          <ToggleButtonGroup
                            className={classes.toggleContainer}
                            value={option.Options[this.state.toggleSelection as number].Label}
                            exclusive
                          >
                            {option.Options &&
                              option.Options &&
                              option.Options.map((selection: Options, index: number) => (
                                <ToggleButton
                                  classes={{
                                    selected: classes.toggleButtonActive,
                                  }}
                                  value={get(selection, 'Label', '')}
                                  className={classes.toggleButton}
                                  onClick={() => {
                                    const productDetailsDummy: ProductDetails = {
                                      productName: get(selection, 'Label', ''),
                                      monthlyCost: selection,
                                      productCode: selection.IsProduct
                                        ? selection.ProductId
                                        : get(selection, 'ProductId', ''),
                                      productId: get(selection, 'ProductId', ''),
                                      productDescription: get(
                                        quotes,
                                        'Response.Plans.0.Plans.0.Description',
                                        '',
                                      ),
                                      productType: (quotes as QuotesV3).Response.Plans[0]
                                        .ProductType,
                                    };
                                    this.setState({
                                      productDetails: productDetailsDummy,
                                      toggleSelection: index,
                                    });
                                    const optionAns: FeatureDetails = {
                                      type: 'answers',
                                      label:
                                        option.Group === 'Concession'
                                          ? `${get(option, 'Heading', '')}`
                                          : `${
                                              get(option, 'Heading', '')
                                                ? ReactHTMLParser(get(option, 'Heading', ''))
                                                : ReactHTMLParser(get(option, 'Heading', ''))
                                            }`,
                                      labelId: get(option, 'Group', ''),
                                      value: `${
                                        get(option, 'ComponentType', '') === 'Date'
                                          ? '21/08/2020'
                                          : get(selection, 'Label', '')
                                      }`,
                                      valueId: `${
                                        get(option, 'ComponentType', '') === 'Date'
                                          ? '21/08/2020'
                                          : get(selection, 'ProductId', '')
                                          ? get(selection, 'ProductId', '')
                                          : get(selection, 'Label', '')
                                      }`,
                                    };
                                    const optionSelcetObject = {
                                      [`${get(selectedPlan, 'Options.0.Group', '')}`]: [optionAns],
                                    };
                                    this.setState({
                                      selectionAns: {
                                        ...this.state.selectionAns,
                                        ...optionSelcetObject,
                                      },
                                    });
                                  }}
                                >
                                  {get(selection, 'Label', '')}
                                </ToggleButton>
                              ))}
                          </ToggleButtonGroup>
                          <Typography
                            variant="h1"
                            className={`${classes.headerText2} ${classes.gtwLight}`}
                          >
                            {this.getFooterValue(
                              option.Options[this.state.toggleSelection as number].ProductId,
                              option.Options,
                            )}
                          </Typography>
                        </Card>
                      )}
                    </>
                  ))}
                {!!(supplierOptions && supplierOptions.length) &&
                  supplierOptions.map((option: SupplierOptions) => (
                    <div>
                      {option.Options.length > 1 ? (
                        <Card
                          elevation={2}
                          className={`${classes.detailCard} ${classes.planCardContainer}`}
                        >
                          <ToggleButtons
                            index={0}
                            activeIndex={this.state.swipeIndex}
                            classes={classes}
                            option={option}
                            getValue={this.getValue}
                            // tslint:disable-next-line: cyclomatic-complexity
                            callBackHandler={async (selection, index: number, date?: Date) => {
                              if (get(option, 'ComponentType', '') !== 'Date') {
                                const optionAnsObject: FeatureDetails = {
                                  type: 'answers',
                                  label:
                                    option.Group === 'Concession'
                                      ? `${get(option, 'Heading', '')}`
                                      : get(option, 'Heading', ''),
                                  labelId: get(option, 'Group', ''),
                                  value: get(selection, 'Label', ''),
                                  valueId: get(selection as SupplierOption, 'ProductId', ''),
                                };

                                const optionSelcetObject = {
                                  [option.Group]: [optionAnsObject],
                                };
                                if (get(selection, 'StopSaleProcess', false)) {
                                  this.state.stopSaleList.push(get(option, 'Group', ''));
                                } else {
                                  const newList = this.state.stopSaleList.filter(
                                    (value) => value !== get(option, 'Group', ''),
                                  );
                                  this.setState({ stopSaleList: newList });
                                }
                                this.setState({
                                  selectionAns: {
                                    ...this.state.selectionAns,
                                    ...optionSelcetObject,
                                  },
                                  stopSaleProcess: get(selection, 'StopSaleProcess', false),
                                });
                                if (
                                  !isNil((selection as SupplierOption).Option) &&
                                  !isNil((selection as SupplierOption).Option.Group)
                                ) {
                                  if (
                                    !isUndefined(this.state.optionWidgets[option.Group]) &&
                                    this.state.selectionAns[option.Group].length > 1
                                  ) {
                                    await this.setState({
                                      totalQuestions: this.state.totalQuestions - 1,
                                      questionAnswered:
                                        this.state.questionAnswered -
                                        this.state.selectionAns[option.Group].length +
                                        1,
                                    });
                                    if (this.state.concessionDetail) {
                                      await this.setState({
                                        concessionDetail: false,
                                        cardType: '',
                                        cardName: '',
                                        caardNumber: '',
                                        expiry: '',
                                        totalQuestions: this.state.totalQuestions - 3,
                                        questionAnswered:
                                          this.state.questionAnswered -
                                          this.state.removeQuestionAnswered,
                                      });
                                      await this.setState({ removeQuestionAnswered: 0 });
                                    }
                                    if (this.state.questionAnswered === this.state.totalQuestions) {
                                      this.setState({
                                        disableMoveForward: false,
                                      });
                                    } else {
                                      this.setState({ disableMoveForward: true });
                                    }
                                  }
                                  this.setState(
                                    {
                                      selectionAns: {
                                        ...this.state.selectionAns,
                                        ...optionSelcetObject,
                                      },
                                    },
                                    () => {
                                      this.optionWidget(
                                        (selection as SupplierOption).Option,
                                        option.Group,
                                        index + 1,
                                        this.state.swipeIndex,
                                        selection as SupplierOption,
                                      );
                                    },
                                  );
                                } else {
                                  if (!isUndefined(this.state.optionWidgets[option.Group])) {
                                    this.setState({ stopSaleProcess: false });
                                  }
                                  if (
                                    !isUndefined(this.state.optionWidgets[option.Group]) &&
                                    this.state.optionWidgets[option.Group].length > 0
                                  ) {
                                    await this.setState({
                                      totalQuestions: this.state.totalQuestions - 1,
                                      questionAnswered:
                                        this.state.questionAnswered -
                                        this.state.selectionAns[option.Group].length +
                                        1,
                                    });
                                    if (this.state.concessionDetail) {
                                      await this.setState({
                                        concessionDetail: false,
                                        cardType: '',
                                        cardName: '',
                                        caardNumber: '',
                                        expiry: '',
                                        totalQuestions: this.state.totalQuestions - 3,
                                        questionAnswered:
                                          this.state.questionAnswered -
                                          this.state.removeQuestionAnswered,
                                      });
                                      await this.setState({ removeQuestionAnswered: 0 });
                                    }
                                    if (this.state.questionAnswered === this.state.totalQuestions) {
                                      this.setState({
                                        disableMoveForward: false,
                                      });
                                    } else {
                                      this.setState({ disableMoveForward: true });
                                    }
                                  }
                                  const newOPtionWidget = {
                                    [option.Group]: [],
                                  };

                                  this.setState({
                                    optionWidgets: {
                                      ...this.state.optionWidgets,
                                      ...newOPtionWidget,
                                    },
                                  });
                                }
                              } else {
                                const monthForReview =
                                  parseInt(((date as Date).getMonth() + 1).toString(), 10) < 10
                                    ? '0' + parseInt(((date as Date).getMonth() + 1).toString(), 10)
                                    : parseInt(((date as Date).getMonth() + 1).toString(), 10);
                                const dateReview =
                                  (date as Date).getDate() < 10
                                    ? '0' + (date as Date).getDate()
                                    : (date as Date).getDate();
                                this.saveSelection(
                                  'answers',
                                  option.Group === 'Concession'
                                    ? `${get(option, 'Heading', '')}`
                                    : `${
                                        get(option, 'Heading', '')
                                          ? ReactHTMLParser(get(option, 'Heading', ''))
                                          : ReactHTMLParser(get(option, 'Heading', ''))
                                      }`,
                                  get(option, 'Group', ''),
                                  date !== null
                                    ? (date as Date).getFullYear() +
                                        '-' +
                                        monthForReview +
                                        '-' +
                                        dateReview
                                    : 'hh',
                                  date !== null
                                    ? (date as Date).getFullYear() +
                                        '-' +
                                        monthForReview +
                                        '-' +
                                        dateReview
                                    : 'hh',
                                );
                              }

                              if (
                                isUndefined(this.state.optionWidgets[option.Group]) ||
                                get(option, 'ComponentType', '') === 'Date'
                              ) {
                                await this.setState({
                                  questionAnswered: this.state.questionAnswered + 1,
                                });
                              }

                              if (this.state.questionAnswered === this.state.totalQuestions) {
                                this.setState({ disableMoveForward: false });
                              } else {
                                this.setState({ disableMoveForward: true });
                              }
                            }}
                          />
                          {this.state.optionWidgets[option.Group] !== undefined
                            ? this.func(option)
                            : undefined}{' '}
                        </Card>
                      ) : (
                        this.renderCustomSupplierOption(option)
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <Card elevation={3} className={classes.fixedCard}>
              <div className={classes.expand}>
                {expandable && (
                  <div
                    className={classes.arrow}
                    onClick={() => {
                      this.setState({
                        costEstimationDisplay: !this.state.costEstimationDisplay,
                      });
                    }}
                  >
                    <img
                      src={arrowForward}
                      className={classes.arrowImage}
                      style={
                        this.state.costEstimationDisplay
                          ? { transform: 'rotate(270deg)' }
                          : { transform: 'rotate(90deg)' }
                      }
                    />
                  </div>
                )}
                <div className={classes.bottomCard}>
                  {!this.state.costEstimationDisplay ? (
                    <div className={classes.expandedCost}>
                      <div>
                        <Typography className={classes.costAmount} variant="h6">
                          {dollarFormatter(this.state.monthlyCost[this.state.frequency as string])}
                        </Typography>
                      </div>
                      {selectedPlan.FooterPricing.length > 1 ? (
                        <Select
                          className={classes.selectFrequency}
                          input={<OutlinedInput labelWidth={0} />}
                          onChange={(e) => {
                            console.log(e);
                            setTimeout(() => {
                              (document!.activeElement! as HTMLInputElement).blur();
                            }, 0);
                            {
                              selectedPlan.FooterPricing.map((footerPricing: FooterPricing) => {
                                if (footerPricing.PriceField === e.target.value) {
                                  this.setState({
                                    frequency: e.target.value,
                                    monthlyCost: this.state.monthlyCost,
                                    pricingLabel: footerPricing.UnitOfMeasureLabel,
                                  });
                                }
                              });
                            }
                          }}
                          value={frequency}
                        >
                          {selectedPlan.FooterPricing.map((footerPricing: FooterPricing) => {
                            return (
                              <MenuItem value={footerPricing.PriceField}>
                                {footerPricing.UnitOfMeasure}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      ) : undefined}
                    </div>
                  ) : undefined}
                </div>
                {!this.state.costEstimationDisplay ? (
                  <div className={classes.bottomText}>
                    <Typography variant="caption" className={classes.keyText}>
                      {costHelpText}
                    </Typography>
                    <div
                      className={`${classes.keyText} ${classes.gtwLight}`}
                      dangerouslySetInnerHTML={{
                        __html: get(allPlans[activePlanIndex], 'DescriptionFooter', ''),
                      }}
                    />
                  </div>
                ) : undefined}
                <br />
                <div className={classes.bottomText}>
                  {this.state.costEstimationDisplay ? (
                    <div className={classes.buttonfolded}>
                      <div className={classes.collapsedCost}>
                        {this.state.monthlyCost[this.state.frequency as string] > 0 &&
                          dollarFormatter(this.state.monthlyCost[this.state.frequency as string]) +
                            this.state.pricingLabel +
                            ' estimated'}
                      </div>
                      <Button
                        className={classes.buttonFolded}
                        onClick={() => {
                          let concessionCard: ConcessionCard | null;
                          if (
                            this.state.cardName.length > 0 &&
                            this.state.caardNumber.length > 0 &&
                            this.state.cardType.length > 0 &&
                            this.state.expiry.length > 0
                          ) {
                            concessionCard = {
                              name: this.state.cardName,
                              cardNumber: this.state.caardNumber,
                              cardType: this.state.cardType,
                              expiryMonth: this.state.expiry.split('/')[0],
                              expiryYear: '20' + this.state.expiry.split('/')[1],
                            };
                          } else {
                            concessionCard = null;
                          }
                          Object.keys(this.state.selectionAns).map((keyName, keyIndex) => {
                            this.state.selectionAns[keyName].map((val: FeatureDetails) => {
                              const newObj = this.state.selections;
                              newObj.push(val);
                              this.setState({ selections: newObj });
                            });
                          });
                          if ((this.state.productDetails as ProductDetails).dailyCost === '') {
                            this.setState(
                              {
                                productDetails: productDetailsDummy,
                              },
                              () => {
                                this.props.storePlanSummaryData(
                                  selections,
                                  this.state.productDetails,
                                  concessionCard,
                                  get(allPlans[activePlanIndex], 'PaymentCardRequired', false),
                                  allPlans[activePlanIndex],
                                );
                                onBtnClick(
                                  selections,
                                  this.state.productDetails,
                                  concessionCard,
                                  get(allPlans[activePlanIndex], 'PaymentCardRequired', false),
                                  allPlans[activePlanIndex],
                                );
                              },
                            );
                          } else {
                            this.props.storePlanSummaryData(
                              selections,
                              this.state.productDetails,
                              concessionCard,
                              get(allPlans[activePlanIndex], 'PaymentCardRequired', false),
                              allPlans[activePlanIndex],
                            );
                            onBtnClick(
                              selections,
                              this.state.productDetails,
                              concessionCard,
                              get(allPlans[activePlanIndex], 'PaymentCardRequired', false),
                              allPlans[activePlanIndex],
                            );
                          }
                        }}
                        disabled={
                          this.state.stopSaleList.length > 0 || this.state.disableMoveForward
                        }
                        color={
                          this.state.stopSaleList.length > 0 || this.state.disableMoveForward
                            ? 'secondary'
                            : 'inherit'
                        }
                        variant="contained"
                      >
                        <Typography
                          className={
                            this.state.stopSaleList.length > 0 || this.state.disableMoveForward
                              ? classes.disabledButtonText
                              : classes.buttonText
                          }
                        >
                          {this.state.monthlyCost[this.state.frequency as string] === 0
                            ? 'Continue'
                            : 'Select'}
                        </Typography>
                      </Button>
                    </div>
                  ) : (
                    <Button
                      className={classes.button}
                      onClick={() => {
                        let concessionCard: ConcessionCard | null;
                        if (
                          this.state.cardName.length > 0 &&
                          this.state.caardNumber.length > 0 &&
                          this.state.cardType.length > 0 &&
                          this.state.expiry.length > 0
                        ) {
                          concessionCard = {
                            name: this.state.cardName,
                            cardNumber: this.state.caardNumber,
                            cardType: this.state.cardType,
                            expiryMonth: this.state.expiry.split('/')[0],
                            expiryYear: '20' + this.state.expiry.split('/')[1],
                          };
                        } else {
                          concessionCard = null;
                        }
                        Object.keys(this.state.selectionAns).map((keyName, keyIndex) => {
                          this.state.selectionAns[keyName].map((val: FeatureDetails) => {
                            const newObj = this.state.selections;
                            newObj.push(val);
                            this.setState({ selections: newObj });
                          });
                        });
                        if ((this.state.productDetails as ProductDetails).dailyCost === '') {
                          this.setState(
                            {
                              productDetails: productDetailsDummy,
                            },
                            () => {
                              this.props.storePlanSummaryData(
                                selections,
                                this.state.productDetails,
                                concessionCard,
                                get(allPlans[activePlanIndex], 'PaymentCardRequired', false),
                                allPlans[activePlanIndex],
                              );
                              onBtnClick(
                                selections,
                                this.state.productDetails,
                                concessionCard,
                                get(allPlans[activePlanIndex], 'PaymentCardRequired', false),
                                allPlans[activePlanIndex],
                              );
                            },
                          );
                        } else {
                          this.props.storePlanSummaryData(
                            selections,
                            this.state.productDetails,
                            concessionCard,
                            get(allPlans[activePlanIndex], 'PaymentCardRequired', false),

                            allPlans[activePlanIndex],
                          );
                          onBtnClick(
                            selections,
                            this.state.productDetails,
                            concessionCard,
                            get(allPlans[activePlanIndex], 'PaymentCardRequired', false),
                            allPlans[activePlanIndex],
                          );
                        }
                      }}
                      disabled={this.state.stopSaleList.length > 0 || this.state.disableMoveForward}
                      color={
                        this.state.stopSaleList.length > 0 || this.state.disableMoveForward
                          ? 'secondary'
                          : 'inherit'
                      }
                      variant="contained"
                    >
                      <Typography
                        className={
                          this.state.stopSaleList.length > 0 || this.state.disableMoveForward
                            ? classes.disabledButtonText
                            : classes.buttonText
                        }
                      >
                        Select {(quotes as QuotesV3).Response.Plans[0].ProductType}
                      </Typography>
                    </Button>
                  )}
                </div>
              </div>
            </Card>
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>): PropsFromDispatch {
  return {
    storePlanSummaryData: (
      serviceDetails: FeatureDetails[],
      productDetails: ProductDetails | null,
      concessionCard: ConcessionCard | null,
      paymentCardRequired: boolean,
      benefits: PlanDetailObject,
    ) =>
      dispatch(
        PlanCommonSummaryActions.storePlanSummaryData({
          data: {
            serviceDetails,
            productDetails,
            concessionCard,
            paymentCardRequired,
            benefits,
          },
        }),
      ),
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    quotes: state.commonSummary.quotes,
  };
}

export default withStyles(Styles)(connect(mapStateToProps, mapDispatchToProps)(PlanSelection));

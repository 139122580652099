import React, { FC, useEffect, useState, useMemo } from 'react';
import { LABELS, links } from './AbandonCartConstants';
import { styles, AbandonCartStyles } from './AbandonCartStyles';
import { withRouter, RouteComponentProps } from 'react-router';
import { Paper, withStyles } from '@material-ui/core';
import AppleStore from '../../assets/applestore.png';
import PlayStore from '../../assets/PlayStore.png';
import Oval from '../../assets/oval.png';
import AllDoneImg from '../../assets/alldone-img.png';
import AllDoneImgBlack from '../../assets/alldone-img-black.png';
import MainContainer from '../../component/mainContainer/MainContainer';
import Complete from '../../assets/abandon.png';
import { getQueryParams } from '../../helper/QueryHelper';
import { routes } from '../../Routes';
import { AGENCIES, APP } from '../../helper/AppNameHelper';

interface AbandonCartProps extends AbandonCartStyles, RouteComponentProps<{}> {}

const AbandonCart: FC<AbandonCartProps> = ({ classes, history, location }) => {
  const noServiceURL = getQueryParams(location, 'noService');

  return (
    <div className={classes.root}>
      <div className={classes.backgroundContainer}>
        <div className={classes.backgroundIconWrapper}>
          <img src={Complete} className={classes.backgroundIcon} alt="icon" />
        </div>
      </div>
      <MainContainer>
        <div className={classes.contentContainer}>
          <Paper className={classes.paperBack} style={{ marginTop: '-80px' }}>
            <div className={classes.cardContentContainer}>
              <div className={classes.title}>{noServiceURL ? LABELS.TRY : LABELS.COMPLETE}</div>
              <div className={classes.text}>{noServiceURL ? LABELS.SUBTEXT2 : LABELS.SUBTEXT}</div>
              <div
                className={classes.primaryButton}
                onClick={() => window.open(links.try, '_blank')}
              >
                {LABELS.DOWNLOAD}
              </div>
              {!noServiceURL && (
                <div className={classes.back} onClick={() => history.goBack()}>
                  {LABELS.BACK}
                </div>
              )}
            </div>
          </Paper>
          <Paper className={classes.paperBack} style={{ marginTop: '20px' }}>
            <div className={classes.cardContentContainer} style={{ paddingBottom: 0 }}>
              <div className={classes.successTitle}>{LABELS.DOWNLOAD}</div>
              <div className={classes.successTitle}>{LABELS.LIFE}</div>
            </div>
            <div className={classes.bottomContentContainer}>
              <div className={classes.leftButtonsContainer}>
                <img
                  src={AppleStore}
                  className={classes.button}
                  alt="app store"
                  onClick={() =>
                    window.open(
                      APP === AGENCIES.NAX ? links.appleStoreNAX : links.appleStore,
                      '_blank',
                    )
                  }
                />
                <img
                  src={PlayStore}
                  className={classes.button}
                  alt="app store"
                  onClick={() =>
                    window.open(
                      APP === AGENCIES.NAX ? links.playstoreNAX : links.playstore,
                      '_blank',
                    )
                  }
                />
              </div>
              <div className={classes.rightContent}>
                <div className={classes.rightBgImgContainer}>
                  <img src={Oval} className={classes.rightBgImg} alt="bg" />
                </div>
                <div className={classes.rightImgContainer}>
                  <img
                    src={APP === AGENCIES.NAX ? AllDoneImgBlack : AllDoneImg}
                    className={classes.rightImg}
                    alt="bg"
                  />
                </div>
              </div>
            </div>
          </Paper>
        </div>
      </MainContainer>
    </div>
  );
};
export default withStyles(styles)(withRouter(AbandonCart));

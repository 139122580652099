export const LABELS = {
  planDetails: 'Plan details',
  Documents: 'Documents',
  infoCardSubHeading: 'Sorted Services is a registered trading name of Energy Locals',
  inclusiveOfGST: 'All prices are inclusive of GST unless stated.',
  DirectDebitServiceAgreement: 'Direct debit service agreement',
};
export const debitServiceAgrementDocument = {
  DocumentName: LABELS.DirectDebitServiceAgreement,
  DocumentId: 0,
  UserId: 0,
  docLibPath: 'https://sortedservices.com/s/Direct-Debit-Request-Service-Agreement.pdf',
};

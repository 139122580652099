import * as React from 'react';
import { Button, Typography, withStyles, WithStyles } from '@material-ui/core';
import { FooterStyle, FooterComponentStyle } from './FooterComponentStyles';

interface FooterComponentProps {
  redirectLink?: string;
  buttonText: string;
  history?: any;
  onClick?: any;
  disabled?: boolean;
}
type OwnProps = FooterComponentProps & FooterComponentStyle;

export interface PropsStyles extends WithStyles<typeof FooterStyle> {}

export class FooterComponent extends React.Component<OwnProps> {
  public render() {
    const { classes, buttonText, onClick, disabled } = this.props;
    const classDisabled = disabled ? classes.disabledButtonText : classes.buttonText;
    return (
      <div className={classes.footer}>
        {this.props.children}
        <Button
          className={classes.button}
          fullWidth
          onClick={onClick || this.handleClick}
          disabled={disabled}
          color={disabled ? 'secondary' : 'inherit'}
          variant="contained"
        >
          <Typography className={classDisabled}>{buttonText}</Typography>
        </Button>
      </div>
    );
  }
  private handleClick = () => {
    const { history, redirectLink } = this.props;
    history.push(redirectLink);
  };
}

export default withStyles(FooterStyle)(FooterComponent);

import { PlanRequest } from '../../../../services/plan/getPlanDetails/GetPlanDetails.data';
import { PlanDetails } from '../../../../models/plan/electricity/getPlanDetails/PlanDetails.data';
import { PlanOptions } from '../../../../models/plan/electricity/getPlanDetails/PlanOptions.data';
import { ElectricityPlanDetails } from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';

export const getExtraInfoLabel = (amount: string) =>
  `(Plus a one-off connection fee of $${amount})`;

export const createElectricityPlanRequest = (
  postcode: string,
  keyIdentifier: string,
): PlanRequest => {
  return {
    ServiceType: 'Electricity',
    Postcode: postcode,
    KeyIdentifier: keyIdentifier,
  };
};

export const createPlanDetailsSubmitObject = (
  details: PlanDetails,
  choice: number,
  electricityPlan: ElectricityPlanDetails,
  averageUsage: string,
) => {
  const plan = details.plans[choice];
  return {
    usage: plan.usage,
    productDetails: {
      productName: plan.productName,
      monthlyCost: plan.monthlyCost,
      dailyCost: plan.dailyCost,
      productCode: plan.productCode,
      productId: plan.productId,
      productDescription: plan.productDescription,
      productType: plan.productType,
    },
    annualUsage: details.annualUsage,
    distributor: details.distributor,
    connectionFee: details.connectionFee,
    termsUrl: details.termsUrl,
    averageUsage,
    meterType: electricityPlan.meterType as string | null,
    nextScheduledRead: electricityPlan.nextScheduledRead as string | null,
    serviceClassesAvailable: electricityPlan.serviceClassesAvailable as string | null,
  };
};

import * as React from 'react';
import { ElectricityService } from '../../../../services/electricityService/getElectricityAddressLookup/GetElectricityAddressLookup.data';
import sortedBackground from '../../../../assets/images/sorted-background.jpg';
import {
  Button,
  Typography,
  withStyles,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { History } from 'history';
import locationIcon from '../../../../assets/location.png';
import selectedIcon from '../../../../assets/images/payment/filled.svg';
import FooterComponent from '../../../footer/FooterComponent';
import { Styles, OverViewStyles } from '../OverviewStyles';
import ReactHTMLParser from 'react-html-parser';
import { isNil } from 'lodash';

interface PropsFromRoute {
  history: History;
}

interface State {
  selectedIndex: string | null;
  //TODO: Resolve any
  selectedAddress: any;
}

interface PropsFromParent {
  //TODO: Resolve any
  propertyList: any;
  serviceName: string;
  handleNext: (selectedProperty: ElectricityService) => void;
  handleSkip: () => void;
}

type OwnProps = OverViewStyles & PropsFromRoute & PropsFromParent;

class CommonAddressList extends React.Component<OwnProps, State> {
  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      selectedIndex: null,
      selectedAddress: null,
    };
  }

  public render() {
    const { selectedAddress } = this.state;
    const { classes, propertyList } = this.props;
    const {
      Supplier: {
        SupplierName,
        SupplierLabel,
        Description,
        DescriptionBanner,
        Logo: { Active, Hero },
      },
    } = propertyList;
    const tagline = 'Lighting the way';
    const descriptionMock =
      "At Energy Australia, we know Australians value fairness. In everything we do, we consider the impact on our customers. We know there's no one size fits all and that all our customers have different needs.";
    const descriptionBannerMock =
      "As our customer, you deserve fair and transparent energy plans that suit your lifestyle and that's what we're focused on. <br/><br/> And whether you're a new or existing customer, you can access all our promoted deals.";
    return (
      <React.Fragment>
        <img
          src={Hero || sortedBackground}
          className={classes.serviceHeaderImg}
          alt="Service Header Image"
        />
        <div className={classes.serviceHeader}>
          {Active && <img src={Active} alt="Service Logo" className={classes.serviceLogo1} />}
          <div className={classes.serviceHeaderText}>
            <Typography variant="h5" style={{ fontSize: 21 }}>
              {SupplierName}
            </Typography>
            <Typography variant="subtitle1">{SupplierLabel || tagline}</Typography>
          </div>
        </div>
        <div className={classes.serviceDescriptionText}>
          <Typography variant="body2">
            <span className={classes.colorBlack}>
              {ReactHTMLParser(Description || descriptionMock)}
            </span>
            <br />
            <span className={classes.colorBlack}>
              {ReactHTMLParser(DescriptionBanner || descriptionBannerMock)}
            </span>
          </Typography>
        </div>
        <div className={classes.serviceContent}>
          <List className={classes.addressList}>{this.renderExactMatchAddress()}</List>
          <Grid container justify="center" style={{ width: '100%' }}>
            <Button
              variant="text"
              onClick={this.props.handleSkip}
              className={classes.addressNotShownButton}
            >
              {"MY ADDRESS ISN'T LISTED HERE"}
            </Button>
          </Grid>
        </div>
        <FooterComponent
          buttonText="Next"
          disabled={isNil(selectedAddress)}
          onClick={() => this.props.handleNext(selectedAddress)}
        />
        {/* </Card> */}
      </React.Fragment>
    );
  }

  private renderExactMatchAddress = () => {
    const { classes, propertyList } = this.props;
    return (
      <div>
        {propertyList &&
          propertyList.AddressesSearchable &&
          propertyList.AddressesSearchable.map(
            (
              //TODO: Resolve any
              listItem: any,
            ) => (
              <div className={classes.list}>
                <ListItem
                  button
                  onClick={() =>
                    this.setState({
                      selectedIndex: listItem.KeyIdentifier,
                      selectedAddress: listItem,
                    })
                  }
                >
                  <ListItemAvatar>
                    <img src={locationIcon} className={classes.locationIcon} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography className={classes.primaryText} variant="caption">
                        {listItem.FullAddressDisplay}
                      </Typography>
                    }
                  />
                  {this.state.selectedIndex === listItem.KeyIdentifier && (
                    <ListItemSecondaryAction>
                      <img src={selectedIcon} />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
                <div className={classes.nmiLabel}>{listItem.KeyIdentifierLabel}</div>
              </div>
            ),
          )}
      </div>
    );
  };
}

export default withStyles(Styles)(CommonAddressList);

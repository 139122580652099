import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceMock from '../__mocks__/ApiServiceMock';
import { AppSettings } from '../../AppSettings';
import ApiServiceBase from '../ApiServiceBase';
import { EditPaymentRequset } from './postEditPayment/PostEditPayment.data';

const serviceType = ServiceType.EditPayment;

export class EditPaymentService {
  private readonly service: ApiServiceBase = AppSettings.currentTestSetting.testing
    ? new ApiServiceMock(serviceType)
    : new ApiService(serviceType);

  public postEditPaymentService(data: EditPaymentRequset): any {
    return this.service.put(
      {
        route: ['service/payment-method'],
      },
      data,
    );
  }
}

const editPaymentService = new EditPaymentService();
export default editPaymentService;

import { StoreAction } from '../StoreHelper';
import { GetPaymentTypeResponse } from '../../services/payment/getPaymentTypes/GetPaymentTypesResponse.data';
import { PostPaymentUUIDRequest } from '../../services/payment/postPaymentUUID/PostPaymentUUIDRequest.data';
import { PostPaymentUUIDResponse } from '../../services/payment/postPaymentUUID/PostPaymentUUIDResponse.data';
import { GetPaymentMethodsResponse } from '../../services/payment/getPaymentMethods/GetPaymentMethods.data';
import { MaskDetails } from '../../containers/payment/addPayment/AddPaymentUtils';
import { GetPaymentAuthKey } from '../../services/payment/getPaymentAuthKey/GetPaymentAuthKey.data';
import { CreditCardRequest, BankAccountRequest } from '../../models/payment/PaymentRequest.data';
import {
  PostPaymentToken,
  PostPaymentTokenResponse,
} from '../../services/payment/postPaymentToken/PostPyamentToken.data';
import { GetPaymentType } from '../../services/payment/getPaymentTypes/GetPaymentTypes.data';
import {
  GetFastPayResponse,
  PayIdPayLoad,
} from '../../services/payment/getFastPayIDResponse/GetFastPayIdResponse';
import {
  CheckoutStripeToken,
  GetStripePaymentIntentRequest,
} from '../../services/payment/stripeCheckout/StripeCheckout';
import { PaymentGateway } from '../../containers/services/commonServices/CommonPlanSelectionInterface';

export enum PaymentActionTypes {
  GetPaymentTypesStart = 'GET_AVAILABLE_PAYMENT_TYPES_START',
  GetPaymentTypesSuccess = 'GET_AVAILABLE_PAYMENT_TYPES_SUCCESS',
  GetPaymentTypesError = 'GET_AVAILABLE_PAYMENT_TYPES_ERROR',
  PostPaymentMethodUUIDStart = 'POST_PAYMENT_METHOD_UUID_START',
  PostPaymentMethodUUIDSuccess = 'POST_PAYMENT_METHOD_UUID_SUCCESS',
  PostPaymentMethodUUIDError = 'POST_PAYMENT_METHOD_UUID_ERROR',
  GetPaymentMethodsStart = 'GET_SAVED_PAYMENT_METHODS_START',
  GetPaymentMethodsSuccess = 'GET_SAVED_PAYMENT_METHODS_SUCCESS',
  GetPaymentPayIdSuccess = 'GET_PAYMENT_PAYID_SUCCESS',
  GetPaymentMethodsError = 'GET_SAVED_PAYMENT_METHODS_ERROR',
  GetPaymentPayIdError = 'GET_PAYMENT_PAYID_ERROR',
  SaveSelectedPaymentMethod = 'SAVE_SELECTED_PAYMENT_METHOD',
  GetPaymentAuthKeyStart = 'GET_PAYMENT_Auth_Key_START',
  GetPaymentAuthKeySuccess = 'GET_PAYMENT_Auth_Key_SUCCESS',
  GetPaymentAuthKeyError = 'GET_PAYMENT_Auth_Key_ERROR',
  PostPaymentTokenStart = 'POST_PAYMENT_TOKEN_START',
  PostPaymentTokenSuccess = 'POST_PAYMENT_TOKEN_SUCCESS',
  RequestFastPayIdDetails = 'REQUEST_FAST_PAYID_DETAILS',
  PostPaymentTokenError = 'POST_PAYMENT_TOKEN_ERROR',
  GET_STRIPE_CHECKOUT_TOKEN_REQUEST = 'GET_STRIPE_CHECKOUT_TOKEN_REQUEST',
  GET_STRIPE_CHECKOUT_TOKEN_SUCCESS = 'GET_STRIPE_CHECKOUT_TOKEN_SUCCESS',
  GET_STRIPE_CHECKOUT_TOKEN_ERROR = 'GET_STRIPE_CHECKOUT_TOKEN_ERROR',

  GET_REFID_REQUEST = 'GET_REFID_REQUEST',
  GET_REFID_SUCCESS = 'GET_REFID_SUCCESS',
  GET_REFID_ERROR = 'GET_REFID_ERROR',
}

export interface PaymentErrorPayload {
  readonly error: Error | any;
}
export interface SaveSelectedPaymentMethodPayload {
  maskedDetails: MaskDetails;
  paymentMethodId: string;
}
export interface GetPaymentMethodsPayLoad {
  onSuccess: (response: GetPaymentMethodsResponse[]) => void;
}

export interface GetPaymentTypes {
  data: GetPaymentType;
  onSuccess: () => void;
}

export interface GetPaymentAuthKeyRequest {
  data: CreditCardRequest | BankAccountRequest;
  paymentGateway?: PaymentGateway;
  providerId?: string;
  onSuccess: (token: string) => void;
  onError: (error: PaymentErrorPayload) => void;
}

export interface GetPaymentRefId {
  token: string;
  providerId?: string;
  transactionId?: string;
  onSuccess: (refId: string) => void;
}

export interface PostPaymentRequest {
  data: PostPaymentToken;
  onSuccess: (response: GetPaymentMethodsResponse) => void;
}

export type PaymentPayload =
  | GetPaymentTypeResponse[]
  | PayIdPayLoad
  | GetFastPayResponse
  | PaymentErrorPayload
  | PostPaymentUUIDRequest
  | PostPaymentUUIDResponse
  | GetPaymentMethodsResponse[]
  | SaveSelectedPaymentMethodPayload
  | PostPaymentRequest
  | GetPaymentAuthKey
  | GetPaymentAuthKeyRequest
  | PostPaymentTokenResponse
  | string
  | GetPaymentTypes
  | number
  | null
  | GetPaymentMethodsPayLoad
  | GetStripePaymentIntentRequest
  | Error
  | GetPaymentRefId
  | CheckoutStripeToken;

export type PaymentAction = StoreAction<PaymentActionTypes, PaymentPayload>;

export class PaymentActions {
  public static getPaymentTypesStart(data: GetPaymentTypes): PaymentAction {
    return {
      type: PaymentActionTypes.GetPaymentTypesStart,
      data,
    };
  }
  public static saveSelectedPaymentMethod(data: SaveSelectedPaymentMethodPayload): PaymentAction {
    return {
      type: PaymentActionTypes.SaveSelectedPaymentMethod,
      data,
    };
  }
  public static getPaymentTypesSuccess(data: GetPaymentTypeResponse[]): PaymentAction {
    return {
      type: PaymentActionTypes.GetPaymentTypesSuccess,
      data,
    };
  }
  public static getPaymentTypesError(error: PaymentErrorPayload): PaymentAction {
    return {
      type: PaymentActionTypes.GetPaymentTypesError,
      data: error,
    };
  }
  public static postPaymentMethodUUIDStart(data: PostPaymentUUIDRequest): PaymentAction {
    return {
      type: PaymentActionTypes.PostPaymentMethodUUIDStart,
      data,
    };
  }
  public static postPaymentMethodUUIDSuccess(data: PostPaymentUUIDResponse): PaymentAction {
    return {
      type: PaymentActionTypes.PostPaymentMethodUUIDSuccess,
      data,
    };
  }
  public static getPaymentMethodsStart(data: GetPaymentMethodsPayLoad): PaymentAction {
    return {
      type: PaymentActionTypes.GetPaymentMethodsStart,
      data,
    };
  }
  public static getPaymentPayIdSuccess(data: GetFastPayResponse): PaymentAction {
    return {
      type: PaymentActionTypes.GetPaymentPayIdSuccess,
      data,
    };
  }
  public static getPaymentPayIdError(error: PaymentErrorPayload): PaymentAction {
    return {
      type: PaymentActionTypes.GetPaymentPayIdError,
      data: error,
    };
  }
  public static getPaymentMethodsSuccess(data: GetPaymentMethodsResponse[]): PaymentAction {
    return {
      type: PaymentActionTypes.GetPaymentMethodsSuccess,
      data,
    };
  }
  public static getPaymentMethodsError(error: PaymentErrorPayload): PaymentAction {
    return {
      type: PaymentActionTypes.GetPaymentMethodsError,
      data: error,
    };
  }
  public static getPaymentAuthKeyStart(data: GetPaymentAuthKeyRequest): PaymentAction {
    return {
      type: PaymentActionTypes.GetPaymentAuthKeyStart,
      data,
    };
  }
  public static getPaymentAuthKeySuccess(data: GetPaymentAuthKey): PaymentAction {
    return {
      type: PaymentActionTypes.GetPaymentAuthKeySuccess,
      data,
    };
  }
  public static getPaymentAuthKeyError(error: PaymentErrorPayload): PaymentAction {
    return {
      type: PaymentActionTypes.GetPaymentAuthKeyError,
      data: error,
    };
  }
  public static postPaymentTokenStart(data: PostPaymentRequest): PaymentAction {
    return {
      type: PaymentActionTypes.PostPaymentTokenStart,
      data,
    };
  }
  public static postPaymentTokenSuccess(data: PostPaymentTokenResponse): PaymentAction {
    return {
      type: PaymentActionTypes.PostPaymentTokenSuccess,
      data,
    };
  }
  public static requestFastPayIdDetails(data: PayIdPayLoad): PaymentAction {
    return {
      type: PaymentActionTypes.RequestFastPayIdDetails,
      data,
    };
  }
  public static postPaymentTokenError(error: PaymentErrorPayload): PaymentAction {
    return {
      type: PaymentActionTypes.PostPaymentTokenSuccess,
      data: error,
    };
  }

  // Checkout ---------------------------------------------------------------------- //
  public static getStripeCheckoutTokenRequest(data: GetStripePaymentIntentRequest): PaymentAction {
    return {
      type: PaymentActionTypes.GET_STRIPE_CHECKOUT_TOKEN_REQUEST,
      data,
    };
  }

  public static getStripeCheckoutTokenSuccess(data: CheckoutStripeToken): PaymentAction {
    return {
      type: PaymentActionTypes.GET_STRIPE_CHECKOUT_TOKEN_SUCCESS,
      data,
    };
  }

  public static getStripeCheckoutTokenError(data: Error): PaymentAction {
    return {
      type: PaymentActionTypes.GET_STRIPE_CHECKOUT_TOKEN_ERROR,
      data,
    };
  }

  public static getRefIdRequest(data: GetPaymentRefId): PaymentAction {
    return {
      type: PaymentActionTypes.GET_REFID_REQUEST,
      data,
    };
  }

  public static getRefIdSuccess(data: any): PaymentAction {
    return {
      type: PaymentActionTypes.GET_REFID_SUCCESS,
      data,
    };
  }

  public static getRefIdError(data: PaymentErrorPayload): PaymentAction {
    return {
      type: PaymentActionTypes.GET_REFID_ERROR,
      data,
    };
  }
}

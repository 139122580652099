import * as React from 'react';
import PlanSummaryComponent from '../../../../component/planSummary/PlanSummary';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/RootReducers';
import {
  ElectricityPlanSummary,
  BroadbandPlanSummary,
} from '../../../../services/planSummary/getPlanSummary/GetPlanSummary.data';
import { RouteComponentProps } from 'react-router';

interface PropsFromState {
  planSummary: ElectricityPlanSummary | BroadbandPlanSummary;
}

type OwnProps = PropsFromState &
  RouteComponentProps<{
    serviceSummary: string;
  }>;
export class PlanSummary extends React.Component<OwnProps, {}> {
  public render() {
    if (this.props.planSummary) {
      return (
        <PlanSummaryComponent
          planSummary={this.props.planSummary}
          serviceSummary={this.props.match.params.serviceSummary}
        />
      );
    } else {
      return <div />;
    }
  }
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    planSummary: state.planSummary.plan as ElectricityPlanSummary | BroadbandPlanSummary,
  };
}

export default connect(mapStateToProps, null)(PlanSummary);

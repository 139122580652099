import { takeEvery, put } from 'redux-saga/effects';
import { SpinnerActions } from '../actions/SpinnerActions';
import broadbandService from '../../services/broadbandService/BroadbandService';
import addressLookupService from '../../services/addressLookup/AddressLookupService';
import {
  BroadbandServiceActions,
  BroadbandServiceActionTypes,
  BroadbandServiceAction,
  PostServicePayload,
  GetBroadbandAddressLookup,
  DeleteBroadbandService,
} from '../actions/BroadbandServiceActions';
import { AddressLookup } from '../../services/broadbandService/getBroadbandAddressLookup/GetBroadbandAddressLookup.data';
import { BroadbandServiceDetails } from '../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import addressService from '../../services/address/GetAddressService';

import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';
export function* getBroadbandServiceSaga(action: BroadbandServiceAction): IterableIterator<any> {
  try {
    yield put(SpinnerActions.show());
    const response = yield broadbandService.getBroadbandService(action.data as string);
    const responseData: BroadbandServiceDetails = {
      serviceAccount: response.serviceAccount,
      serviceDetails: {
        ...response.serviceDetails,
        property: response.serviceDetails.serviceabilityAddress,
      },
    };
    yield put(BroadbandServiceActions.getBroadbandServiceSuccess(responseData));
  } catch (error) {
    yield put(SpinnerActions.hide());
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(BroadbandServiceActions.getBroadbandServiceError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* getBroadbandAddressLookupSaga(
  action: BroadbandServiceAction,
): IterableIterator<any> {
  try {
    yield put(SpinnerActions.show());
    const { data, onSuccess } = action.data as GetBroadbandAddressLookup;
    const response = yield addressLookupService.postAddressLookupBroadbandService(data);
    yield put(BroadbandServiceActions.getBroadbandServiceAddressLookupSuccess(response));
    if (onSuccess) {
      onSuccess(response);
    }
  } catch (error) {
    const { onError } = action.data as GetBroadbandAddressLookup;
    yield put(BroadbandServiceActions.getBroadbandServiceAddressLookupError(error));
    if (onError) {
      onError();
    }
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* postBroadbandServiceSaga(action: BroadbandServiceAction): IterableIterator<any> {
  try {
    const { onSuccess } = action.data as PostServicePayload;
    yield put(SpinnerActions.show());
    const response = yield broadbandService.postBroadbandService(action.data as PostServicePayload);
    const responseData: BroadbandServiceDetails = {
      serviceAccount: response.serviceAccount,
      serviceDetails: {
        ...response.serviceDetails,
        property: response.serviceDetails.serviceabilityAddress,
      },
    };
    yield put(BroadbandServiceActions.postBroadbandServiceSuccess(responseData));
    if (onSuccess) {
      onSuccess(response);
    }
  } catch (error) {
    yield put(SpinnerActions.hide());
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(BroadbandServiceActions.postBroadbandServiceError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* deleteBroadbandServiceSaga(action: BroadbandServiceAction): IterableIterator<any> {
  try {
    yield put(SpinnerActions.show());
    const { data, onSuccess } = action.data as DeleteBroadbandService;
    const response = yield broadbandService.deleteBroadbandService(data);
    if (onSuccess) {
      onSuccess();
    }
    yield put(BroadbandServiceActions.deleteBroadbandServiceSuccess(response));
  } catch (error) {
    yield put(SpinnerActions.hide());
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(BroadbandServiceActions.deleteBroadbandServiceError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* getBroadbandAddressServiceSaga(
  action: BroadbandServiceAction,
): IterableIterator<any> {
  try {
    yield put(SpinnerActions.show());
    const response = yield addressService.getAddressService(action.data as string);
    yield put(BroadbandServiceActions.getBroadbandAddressSuccess(response));
  } catch (error) {
    yield put(BroadbandServiceActions.getBroadbandAddressError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

function* broadbandServiceWatcher(): IterableIterator<any> {
  yield takeEvery(BroadbandServiceActionTypes.GetBroadbandServiceStart, (action) =>
    getBroadbandServiceSaga({
      type: action.type,
      data: (action as BroadbandServiceAction).data,
    }),
  );
  yield takeEvery(BroadbandServiceActionTypes.GetBroadbandServiceAddressLookupStart, (action) =>
    getBroadbandAddressLookupSaga({
      type: action.type,
      data: (action as BroadbandServiceAction).data,
    }),
  );
  yield takeEvery(BroadbandServiceActionTypes.PostBroadbandServiceStart, (action) =>
    postBroadbandServiceSaga({
      type: action.type,
      data: (action as BroadbandServiceAction).data,
    }),
  );
  yield takeEvery(BroadbandServiceActionTypes.DeleteBroadbandServiceStart, (action) =>
    deleteBroadbandServiceSaga({
      type: action.type,
      data: (action as BroadbandServiceAction).data,
    }),
  );
  yield takeEvery(BroadbandServiceActionTypes.GetBroadbandAddressStart, (action) =>
    getBroadbandAddressServiceSaga({
      type: action.type,
      data: (action as BroadbandServiceAction).data,
    }),
  );
}
export default broadbandServiceWatcher;

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { withStyles } from '@material-ui/core';
import React, { FC } from 'react';
import CheckBoxComponent from '../../../../../../../component/services/commonService/CheckBox';
import DropDownComponent from '../../../../../../../component/services/commonService/DropDown';
import RadioButtonComponent from '../../../../../../../component/services/commonService/RadioButton';
import TextBoxComponent from '../../../../../../../component/services/commonService/TextBox';
import { quotesResponse } from '../../../../../../../services/checkoutService/CheckoutMockResponse';
import {
  FeatureDetails,
  QuotesV3,
  SupplierOptions,
} from '../../../../../../services/commonServices/CommonPlanSelectionInterface';
import { styles, SelectOptionsStyles } from '../../../../SelectOptions/SelectOptionsStyles';
import { getDefaultValue } from '../../../../SelectOptions/SelectOptionsUtils';
import ToggleButtons from '../ToggleButtons/ToggleButtons';

interface SingleSupplierOptionsProps extends SelectOptionsStyles {
  option: SupplierOptions;
  handleSingleOptionChange: (option: SupplierOptions, value?: string | Date) => void;
  supplierSelectionAns: Map<string, FeatureDetails> | {};
  publicHolidays?: string[];
}

const SingleSupplierOptions: FC<SingleSupplierOptionsProps> = ({
  classes,
  option,
  handleSingleOptionChange,
  supplierSelectionAns,
  publicHolidays,
}) => {
  switch (option.ComponentType) {
    case 'Date':
      return (
        <div style={{ marginRight: 16, marginLeft: 16 }}>
          <ToggleButtons
            activeIndex={0}
            classes={classes}
            option={option}
            publicHolidays={publicHolidays}
            getValue={() => ''}
            // tslint:disable-next-line: cyclomatic-complexity
            callBackHandler={async (selection, date?: Date) => {
              const monthForReview =
                parseInt(((date as Date).getMonth() + 1).toString(), 10) < 10
                  ? '0' + parseInt(((date as Date).getMonth() + 1).toString(), 10)
                  : parseInt(((date as Date).getMonth() + 1).toString(), 10);
              const dateReview =
                (date as Date).getDate() < 10
                  ? '0' + (date as Date).getDate()
                  : (date as Date).getDate();
              const dateValue =
                date !== null
                  ? (date as Date).getFullYear() + '-' + monthForReview + '-' + dateReview
                  : 'hh';
              handleSingleOptionChange(option, dateValue);
            }}
            defaultValue={getDefaultValue(supplierSelectionAns, option.Group)}
          />
        </div>
      );
    case 'Text':
      return (
        <TextBoxComponent
          optionValue={option}
          classes={classes}
          callBackHandler={(value) => {
            handleSingleOptionChange(option, value);
          }}
          defaultValue={getDefaultValue(supplierSelectionAns, option.Group)}
        />
      );
    case 'Dropdown':
      return (
        <DropDownComponent
          optionValue={option}
          classes={classes}
          callBackHandler={(value) => {
            handleSingleOptionChange(option, value);
          }}
          defaultValue={getDefaultValue(supplierSelectionAns, option.Group)}
        />
      );
    case 'MultiCheckbox':
      return (
        <CheckBoxComponent
          optionValue={option}
          classes={classes}
          callBackHandler={(value) => {
            handleSingleOptionChange(option, value);
          }}
          defaultValue={getDefaultValue(supplierSelectionAns, option.Group)}
        />
      );
    case 'Radio':
      return (
        <RadioButtonComponent
          optionValue={option}
          classes={classes}
          callBackHandler={(value) => {
            handleSingleOptionChange(option, value);
          }}
          defaultValue={getDefaultValue(supplierSelectionAns, option.Group)}
        />
      );
  }
  return <div />;
};

export default withStyles(styles)(SingleSupplierOptions);

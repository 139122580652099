export const rentBaseUrl = '/onboarding/rent';
export const checkoutBaseUrl = '/onboarding/checkout';
export const electricityBaseUrl = '/onboarding/electricity';
export const broadbandBaseUrl = '/onboarding/broadband';
export const commonSummary = '/onboarding/commonSummary';
export const otherServicesBaseUrl = '/onboarding/otherService';
export const commonServicesBaseUrl = '/onboarding/commonService';
export const walletBaseUrl = '/onboarding';
export const propertyOnboardBaseUrl = '/onboarding/propertyOnboard';
export const routes = {
  welcome: `${rentBaseUrl}`,
  property: {
    new: `${rentBaseUrl}/property`,
  },
  checkout: {
    select: `${checkoutBaseUrl}/select`,
    configure: `${checkoutBaseUrl}/configure`,
    summary: `${checkoutBaseUrl}/summary`,
    finalize: `${checkoutBaseUrl}/finalize`,
    abandonCart: `${checkoutBaseUrl}/abandonCart`,
    completeCart: `${checkoutBaseUrl}/completeCart`,
  },
  payment: {
    new: {
      card: (
        propertyId: string,
        serviceType: string,
        paymentRefId?: string,
        serviceAccountId?: string,
      ) =>
        handlePaymentUrl(propertyId, serviceType, PaymentUrl.Card, paymentRefId, serviceAccountId),
      bank: (
        propertyId: string,
        serviceType: string,
        paymentRefId?: string,
        serviceAccountId?: string,
      ) =>
        handlePaymentUrl(propertyId, serviceType, PaymentUrl.Bank, paymentRefId, serviceAccountId),
    },
    list: {
      empty: (
        propertyId: string,
        serviceType: string,
        paymentRefId?: string,
        serviceAccountId?: string,
        agencyName?: string,
      ) =>
        handlePaymentUrl(
          propertyId,
          serviceType,
          PaymentUrl.Types,
          paymentRefId,
          serviceAccountId,
          agencyName,
        ),
      saved: (
        propertyId: string,
        serviceType: string,
        paymentRefId?: string,
        serviceAccountId?: string,
        agencyName?: string,
      ) =>
        handlePaymentUrl(
          propertyId,
          serviceType,
          PaymentUrl.List,
          paymentRefId,
          serviceAccountId,
          agencyName,
        ),
      commonServiceSaved: (
        propertyId: string,
        serviceType: string,
        paymentRefId?: string,
        serviceAccountId?: string,
        agencyName?: string,
      ) =>
        handlePaymentUrl(
          propertyId,
          serviceType,
          PaymentUrl.CommonList,
          paymentRefId,
          serviceAccountId,
          agencyName,
        ),
    },
  },
  summary: {
    new: (propertyId: string, serviceType: string) =>
      `${rentBaseUrl}/${propertyId}/${serviceType}/summary`,
  },
  serviceability: `${rentBaseUrl}/serviceability`,
  setup: {
    base: (setupType: string, propertyId?: string) =>
      propertyId ? `/setup/${setupType}/${propertyId}` : `/setup/${setupType}`,
    rent: `/setup/rent`,
    electricity: `/setup/electricity`,
    broadband: `/setup/broadband`,
  },
  service: {
    new: (propertyId: string, serviceAccountId?: string) =>
      serviceAccountId
        ? `${electricityBaseUrl}/${propertyId}/overview/${serviceAccountId}`
        : `${electricityBaseUrl}/${propertyId}/overview`,
    address: {
      form: (propertyId: string) => `${electricityBaseUrl}/${propertyId}/address`,
      list: (propertyId: string) => `${electricityBaseUrl}/${propertyId}/addressList`,
      emptyList: (propertyId: string) => `${electricityBaseUrl}/${propertyId}/emptyList`,
    },
    personalDetails: {
      showDetails: (propertyId: string) => `${electricityBaseUrl}/${propertyId}/personalDetails`,
    },
    plan: {
      selection: (propertyId: string) => `${electricityBaseUrl}/${propertyId}/plan/selection`,
      support: (propertyId: string) => `${electricityBaseUrl}/${propertyId}/plan/support`,
      concession: (propertyId: string) => `${electricityBaseUrl}/${propertyId}/plan/concession`,
    },
    power: {
      show: (propertyId: string) => `${electricityBaseUrl}/${propertyId}/power`,
    },
    concessionCard: {
      base: (propertyId: string) => `${electricityBaseUrl}/${propertyId}/concessionCard`,
      form: (propertyId: string, type: string) =>
        `${electricityBaseUrl}/${propertyId}/concessionCardForm/${type}`,
    },
    connectionDate: {
      saved: (propertyId: string) => `${electricityBaseUrl}/${propertyId}/dateConfirmation`,
      new: (propertyId: string) => `${electricityBaseUrl}/${propertyId}/connectionDate`,
    },
    termsAndConditions: {
      new: (propertyId: string, serviceSummary?: string) =>
        serviceSummary
          ? `${electricityBaseUrl}/${propertyId}/termsAndConditions/${serviceSummary}`
          : `${electricityBaseUrl}/${propertyId}/termsAndConditions`,
    },
    commonTermsAndConditions: {
      new: (propertyId: string, serviceAccountId?: string) =>
        `${commonSummary}/${propertyId}/termsAndConditions/${serviceAccountId}`,
    },
    planSummary: (serviceAccountId: string, serviceSummary: string) =>
      `${electricityBaseUrl}/${serviceAccountId}/planSummary/${serviceSummary}`,
    commonServiceSummary: (propertyId: string, serviceAccountId: string) =>
      `${commonSummary}/${propertyId}/summary/${serviceAccountId}`,
  },
  broadbandService: {
    new: (propertyId: string, serviceAccountId?: string) =>
      serviceAccountId
        ? `${broadbandBaseUrl}/${propertyId}/overview/${serviceAccountId}`
        : `${broadbandBaseUrl}/${propertyId}/overview`,
    type: (propertyId: string) => `${broadbandBaseUrl}/${propertyId}/type`,
    address: {
      form: (propertyId: string) => `${broadbandBaseUrl}/${propertyId}/addressForm`,
      list: (propertyId: string) => `${broadbandBaseUrl}/${propertyId}/addressList`,
      emptyList: (propertyId: string) => `${broadbandBaseUrl}/${propertyId}/emptyList`,
    },
    plan: {
      dataSelection: (propertyId: string) => `${broadbandBaseUrl}/${propertyId}/plan/data`,
      downloadSpeedSelection: (propertyId: string) =>
        `${broadbandBaseUrl}/${propertyId}/plan/downloadSpeed`,
      modemSelection: (propertyId: string) => `${broadbandBaseUrl}/${propertyId}/plan/modem`,
      voiceSelection: (propertyId: string) => `${broadbandBaseUrl}/${propertyId}/plan/voice`,
      contractSelection: (propertyId: string) => `${broadbandBaseUrl}/${propertyId}/plan/contract`,
    },
    personalDetails: {
      showDetails: (propertyId: string) => `${broadbandBaseUrl}/${propertyId}/personalDetails`,
    },
    termsAndConditions: (propertyId: string, serviceSummary?: string) =>
      serviceSummary
        ? `${broadbandBaseUrl}/${propertyId}/termsAndConditions/${serviceSummary}`
        : `${broadbandBaseUrl}/${propertyId}/termsAndConditions`,
    planSummary: (serviceAccountId: string, serviceSummary: string) =>
      `${electricityBaseUrl}/${serviceAccountId}/planSummary/${serviceSummary}`,
  },
  wallet: {
    paymentOptions: (serviceType: string) => `${walletBaseUrl}/${serviceType}/paymentoptions`,
    bankDetails: (serviceType: string) => `${walletBaseUrl}/${serviceType}/bankdetails`,
    cardDetails: (serviceType: string) => `${walletBaseUrl}/${serviceType}/carddetails`,
  },
  otherService: {
    new: (serviceName: string, propertyId: string, providerName: string) =>
      `${otherServicesBaseUrl}/${serviceName}/${propertyId}/overview/${providerName}`,
    summary: (serviceName: string, propertyId: string, serviceAccountId?: string) =>
      serviceAccountId
        ? `${otherServicesBaseUrl}/${serviceName}/${propertyId}/summary/${serviceAccountId}`
        : `${otherServicesBaseUrl}/${serviceName}/${propertyId}/summary`,
    info: (serviceName: string, propertyId: string, serviceAccountId?: string) =>
      serviceAccountId
        ? `${otherServicesBaseUrl}/${serviceName}/${propertyId}/info/${serviceAccountId}`
        : `${otherServicesBaseUrl}/${serviceName}/${propertyId}/info`,
  },
  appUpdate: {
    new: () => `onboarding/updateApp`,
  },
  commonService: {
    new: (
      serviceType: string,
      propertyId: string,
      providerName: string,
      adressConfirmed: string,
      lookupRequired: string,
      serviceAccountId: string | null,
    ) =>
      `${commonServicesBaseUrl}/${serviceType}/Summary/${propertyId}/${providerName}/addressConfirmed/${adressConfirmed}/${lookupRequired}/${serviceAccountId}`,
    newOther: (
      serviceType: string,
      propertyId: string,
      providerName: string,
      adressConfirmed: string,
      lookupRequired: string,
      serviceAccountId: string | null,
    ) =>
      `${commonServicesBaseUrl}/${serviceType}/Summary/${propertyId}/${providerName}/kanoppi/${adressConfirmed}/${lookupRequired}/${serviceAccountId}`,
    openNewWebPage: (provider: string, property: string, serviceName: string) =>
      `${commonServicesBaseUrl}/newPage/${provider}/${property}/${serviceName}`,
    plans: (serviceName: string, propertyId: string, providerName: string, index: string) =>
      `${commonServicesBaseUrl}/${serviceName}/${propertyId}/plans/${providerName}/${index}`,
    commonPersonalDetails: (
      propertyId: string,
      serviceName: string,
      providerId: string,
      serviceAccountId: string,
    ) =>
      `${commonServicesBaseUrl}/service/${serviceName}/${propertyId}/${providerId}/${serviceAccountId}/personalDetails`,
    termsAndConditions: (serviceName: string, propertyId: string, providerName: string) =>
      `${commonServicesBaseUrl}/${serviceName}/${propertyId}/terms/${providerName}`,
    summary: (serviceName: string, propertyId: string, providerName: string) =>
      `${commonServicesBaseUrl}/${serviceName}/${propertyId}/summary/${providerName}`,
    address: {
      overview: (
        propertyId: string,
        serviceType: string,
        addressConfirmed: string,
        lookupRequired: string,
        providerName: string,
        serviceAccountId?: any | null,
      ) =>
        serviceAccountId
          ? `${commonServicesBaseUrl}/${propertyId}/overview/${serviceType}/${addressConfirmed}/${lookupRequired}/${providerName}/${serviceAccountId}`
          : `${commonServicesBaseUrl}/${propertyId}/overview/${serviceType}/${addressConfirmed}/${lookupRequired}/${providerName}`,
      form: (
        serviceName: string,
        propertyId: string,
        providerName: string,
        adressConfirmed: string,
        lookupRequired: string,
        serviceAccountId: string,
        first?: string,
      ) =>
        first
          ? `${commonServicesBaseUrl}/service/${serviceName}/${propertyId}/address/${providerName}/${adressConfirmed}/${lookupRequired}/${serviceAccountId}/${first}`
          : `${commonServicesBaseUrl}/service/${serviceName}/${propertyId}/address/${providerName}/${adressConfirmed}/${lookupRequired}/${serviceAccountId}`,
      list: (
        propertyId: string,
        serviceName: string,
        providerName: string,
        lookupRequired: string,
        serviceAccountId: string,
      ) =>
        `${commonServicesBaseUrl}/service/${serviceName}/${propertyId}/addressList/${providerName}/${lookupRequired}/${serviceAccountId}`,
      empty: (
        propertyId: string,
        serviceName: string,
        providerName: string,
        lookupRequired: string,
        serviceAccountId: string,
      ) =>
        `${commonServicesBaseUrl}/service/${serviceName}/${propertyId}/addressEmpty/${providerName}/${lookupRequired}/${serviceAccountId}`,
    },
  },
  planSuccessSignUp: {
    new: (propertyId: string) => `${commonServicesBaseUrl}/${propertyId}/planSuccessSignUp`,
  },
  propertyOnboarding: {
    new: () => `${propertyOnboardBaseUrl}`,
  },
};

export enum PaymentUrl {
  List = 'saved',
  CommonList = 'commonSaved',
  Card = 'addCard',
  Bank = 'addBank',
  Types = 'addnew',
}
export const handlePaymentUrl = (
  propertyId: string,
  serviceType: string,
  screenName: string,
  paymentRefId?: string,
  serviceAccountId?: string,
  agencyName?: string,
) => {
  if (!paymentRefId && !serviceAccountId) {
    return `/payment/${propertyId}/${serviceType}/${screenName}`;
  } else if (paymentRefId && serviceAccountId) {
    if (agencyName) {
      return `/payment/${propertyId}/${serviceType}/${screenName}/${paymentRefId}/${serviceAccountId}/${agencyName}`;
    } else {
      return `/payment/${propertyId}/${serviceType}/${screenName}/${paymentRefId}/${serviceAccountId}`;
    }
  } else {
    return '/';
  }
};

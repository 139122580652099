import { takeEvery, put, takeLatest } from 'redux-saga/effects';
import { SpinnerActions } from '../actions/SpinnerActions';
import {
  PlanCommonSummaryActionTypes,
  PlanCommonSummaryAction,
  PlanCommonSummaryActions,
} from '../actions/CommonSummaryActions';
import commonServiceService from '../../services/commonService/CommonServiceService';
import { PostCommonServiceAddressSaveRequest } from '../../services/commonService/getCommonService/GetCommonService.data';
import { CommonServiceAddressLookup } from '../../services/commonService/getCommonServiceAddressLookup/GetCommonAddressLookup.data';
import { ServiceDetail } from '../../containers/services/commonServices/CommonPlanSelectionInterface';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';

export function* getElectricityAddressLookupSaga(
  //TODO: Resolve any
  action: any,
  //TODO: Resolve any
): IterableIterator<any> {
  try {
    yield put(SpinnerActions.show());
    const { data } = action;
    const response = yield commonServiceService.getCommonServiceAddressLookup(
      data.propertyId,
      data.serviceName,
      data.providerName,
    );
    yield put(PlanCommonSummaryActions.getAddressLookupSuccess(response));
    if (data.onSuccess) {
      data.onSuccess(response);
    }
  } catch (error) {
    const { onError } = action.data;
    if (onError) {
      onError();
    }
    yield put(PlanCommonSummaryActions.getAddressLookupError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* getCommonServiceAddressLookupWorker(
  action: PlanCommonSummaryAction,
  //TODO: Resolve any
): IterableIterator<any> {
  try {
    const { propertyId, serviceName, onSuccess, providerName } = action.data;
    yield put(SpinnerActions.show());
    const responseFromAPI = yield commonServiceService.getCommonServiceAddressLookup(
      propertyId as string,
      serviceName as string,
      providerName as string,
    );
    const response: CommonServiceAddressLookup = responseFromAPI as CommonServiceAddressLookup;
    yield put(PlanCommonSummaryActions.getAddressLookupSuccess(response));

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const { onError } = action.data;
    yield put(PlanCommonSummaryActions.getAddressError(error));
    if (onError) {
      onError(error);
    } else {
      sendMessageToApp(ValidMessages.ErrorScreen);
    }
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* getAddressServiceWorker(
  action: PlanCommonSummaryAction,
  //TODO: Resolve any
): IterableIterator<any> {
  try {
    yield put(SpinnerActions.show());
    const response = yield commonServiceService.getCommonServiceAddress(action.data as string);
    yield put(PlanCommonSummaryActions.getAddressSuccess(response));
  } catch (error) {
    yield put(PlanCommonSummaryActions.getAddressError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* getCommonQuotesWorker(
  action: PlanCommonSummaryAction,
  //TODO: Resolve any
): IterableIterator<any> {
  try {
    const { onSuccess, data } = action.data;
    yield put(SpinnerActions.show());
    const response = yield commonServiceService.postGetCommonQuotes(data);
    yield put(PlanCommonSummaryActions.getCommonQuoteSuccess(response));
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const { onError } = action.data;
    yield put(PlanCommonSummaryActions.getCommonQuoteError(error));
    if (onError) {
      onError(error);
    }
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* getIframeUrlWorker(action: PlanCommonSummaryAction): IterableIterator<any> {
  try {
    const { onSuccess, data, url, token } = action.data;
    yield put(SpinnerActions.show());
    const response = yield commonServiceService.postGetIframeUrl(data, url, token);
    yield put(PlanCommonSummaryActions.getIframeUrlSuccess(response));
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const { onError } = action.data;
    yield put(PlanCommonSummaryActions.getCommonQuoteError(error));
    if (onError) {
      onError(error);
    }
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* postPersonalDetailsWorker(
  action: PlanCommonSummaryAction,
  //TODO: Resolve any
): IterableIterator<any> {
  try {
    const { onSuccess, data } = action.data;
    yield put(SpinnerActions.show());
    const response = yield commonServiceService.postCommonPersonalDetailsSave(data);
    yield put(PlanCommonSummaryActions.postPersonalDetailsSuccess(response));
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const { onError } = action.data;
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(PlanCommonSummaryActions.postPersonalDetailsError(error));
    if (onError) {
      onError(error);
    }
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* saveCommonServicePropertyAddressWorker(
  action: PlanCommonSummaryAction,
  //TODO: Resolve any
): IterableIterator<any> {
  try {
    const { data, onSuccess } = action.data;
    yield put(SpinnerActions.show());
    const response = yield commonServiceService.postCommonServiceAddressSave(
      data as PostCommonServiceAddressSaveRequest,
    );
    yield put(PlanCommonSummaryActions.setPropertyAddressDataSuccess(response));
    if (onSuccess) {
      onSuccess(response);
    }
  } catch (error) {
    const { onError } = action.data;
    yield put(PlanCommonSummaryActions.setPropertyAddressDataError(error));
    if (onError) {
      onError(error);
    }
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* saveCommonPlanSummaryWorker(
  action: PlanCommonSummaryAction,
  //TODO: Resolve any
): IterableIterator<any> {
  try {
    const { data, onSuccess } = action.data;
    yield put(SpinnerActions.show());
    const response = yield commonServiceService.postCommonPlanSummarySave(data as ServiceDetail);
    yield put(PlanCommonSummaryActions.setCommonServiceDataSuccess(response));
    if (onSuccess) {
      onSuccess(response);
    }
  } catch (error) {
    const { onError } = action.data;
    yield put(PlanCommonSummaryActions.setCommonServiceDataError(error));
    if (onError) {
      onError(error);
    }
  } finally {
    yield put(SpinnerActions.hide());
  }
}

//TODO: Resolve any
function* commonServiceWatcher(): IterableIterator<any> {
  yield takeEvery(PlanCommonSummaryActionTypes.GetCommonAddressLookupStart, (action) =>
    getCommonServiceAddressLookupWorker({
      type: action.type,
      data: (action as PlanCommonSummaryAction).data,
    }),
  );
  yield takeEvery(PlanCommonSummaryActionTypes.GetAddressStart, (action) =>
    getAddressServiceWorker({
      type: action.type,
      data: (action as PlanCommonSummaryAction).data,
    }),
  );
  yield takeLatest(PlanCommonSummaryActionTypes.GetCommonQuotesStart, (action) =>
    getCommonQuotesWorker({
      type: action.type,
      data: (action as PlanCommonSummaryAction).data,
    }),
  );
  yield takeLatest(PlanCommonSummaryActionTypes.GetIframeUrlStart, (action) =>
    getIframeUrlWorker({
      type: action.type,
      data: (action as PlanCommonSummaryAction).data,
    }),
  );
  yield takeLatest(PlanCommonSummaryActionTypes.PostPersonalDetails, (action) =>
    postPersonalDetailsWorker({
      type: action.type,
      data: (action as PlanCommonSummaryAction).data,
    }),
  );

  yield takeLatest(PlanCommonSummaryActionTypes.PostCommonSummaryData, (action) =>
    saveCommonPlanSummaryWorker({
      type: action.type,
      data: (action as PlanCommonSummaryAction).data,
    }),
  );

  yield takeLatest(PlanCommonSummaryActionTypes.SavePropertyAddressStart, (action) =>
    saveCommonServicePropertyAddressWorker({
      type: action.type,
      data: (action as PlanCommonSummaryAction).data,
    }),
  );
}
export default commonServiceWatcher;

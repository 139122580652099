export const LABELS = {
  TITLE: 'Your cart',
  ESTIMATED_MONTHLY: 'Estimated monthly cost',
  TOTAL_UPFRONT: 'Total upfront cost',
  CHECKOUT: 'Checkout',
  NEED_SUPPORT: 'Need support?',
  CHAT: 'Chat now',
  EMPTY_CART_TITLE: 'Your cart is empty',
  EMPTY_CART_TEXT: 'Add services from the marketplace',
  ESTIMATED: 'Show estimated prices',
  SHOW: 'Show details',
  HIDE: 'Hide details',
};

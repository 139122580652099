import { color } from '../../../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';
import { AGENCIES, APP } from '../../../../helper/AppNameHelper';

export const styles = createStyles({
  serviceTabContainer: {
    height: '65px',
    width: 'calc(100% - 30px)',
    backgroundColor: color.light,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  serviceTab: {
    position: 'relative',
    borderRadius: '50%',
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 45,
    width: 45,
    backgroundColor: color.secondary,
    marginRight: 20,
    border: `${color.secondary} 1.5px solid`,
    cursor: 'pointer',
  },
  blueTab: {
    position: 'relative',
    borderRadius: '50%',
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 45,
    width: 45,
    backgroundColor: color.fadedBlue,
    marginRight: 20,
    border: `${color.fadedBlue} 1.5px solid`,
    cursor: 'pointer',
  },
  border: {
    border: `${color.lightBlue} 1.5px solid`,
  },
  iconContainer: {
    width: 22,
    height: 22,
  },
  icon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    filter:
      APP === AGENCIES.NAX
        ? 'grayscale(100%) brightness(0)'
        : 'brightness(0) invert(95%) sepia(5%) saturate(572%) hue-rotate(187deg) brightness(87%) contrast(89%)',
  },
  activeIcon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    filter:
      APP === AGENCIES.NAX
        ? 'grayscale(100%) brightness(0)'
        : 'brightness(0) invert(52%) sepia(98%) saturate(349%) hue-rotate(142deg) brightness(90%) contrast(88%)',
  },
  statusContainer: {
    position: 'absolute',
    bottom: 3,
    right: 1,
    borderRadius: '50%',
    width: 13,
    height: 13,
  },
  status: {
    width: '100%',
    height: '100%',
    backgroundColor: color.light,
    borderRadius: '50%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
});

export interface ServiceTabsStyles extends WithStyles<typeof styles> {}

import { color } from '../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  addressContainer: {
    height: 40,
    backgroundColor: color.headerColor,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 2,
  },
  addressWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: color.light,
    position: 'absolute',
    borderRadius: 6,
    width: 'calc(100% - 30px)',
    padding: 15,
    top: '-12px',
    zIndex: 2,
    border: `${color.borderColor} 2px solid`,
  },
  addressTitle: {
    fontFamily: 'GT Walsheim',
    fontSize: '18px',
    color: color.black87,
  },
  addressBar: {
    borderRadius: 5000,
    height: 35,
    maxWidth: 'max-content',
    width: '100%',
    overflow: 'hidden',
    paddingLeft: 15,
    paddingRight: 15,
    marginLeft: 15,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: color.secondary,
  },
  addressText: {
    fontFamily: 'GT Walsheim',
    fontSize: '14px',
    color: color.black87,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  disabled: {
    color: color.black38,
  },
});

export interface AddressHeaderStyles extends WithStyles<typeof styles> {}

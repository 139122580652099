import React, { FC } from 'react';
import { withStyles, MenuItem } from '@material-ui/core';
import { styles, SelectRoleStyles } from './SelectRoleStyles';
import {
  OnboardingPropertyDetails,
  PropertyClass,
  SetOnboardingPropertyDetails,
} from '../../../../models/property/Property.data';
import tick from '../../../../assets/radio-filled.png';
import tickBlack from '../../../../assets/radio-filled-black.png';
import { LABELS } from './SelectRoleConstants';
import { AGENCIES, APP } from '../../../../helper/AppNameHelper';

interface SelectRoleProps extends SelectRoleStyles {
  propertyOnboardingState: OnboardingPropertyDetails;
  setPropertyDetails: (data: SetOnboardingPropertyDetails) => void;
}

const SelectRole: FC<SelectRoleProps> = ({
  classes,
  propertyOnboardingState: { selectedClass },
  setPropertyDetails,
}) => {
  return (
    <div className={classes.contentContainer}>
      <MenuItem
        onClick={() => {
          setPropertyDetails({ field: 'selectedClass', value: PropertyClass.Tenant });
        }}
        className={classes.menuItem}
      >
        <div className={classes.menuRow}>
          <span className={classes.text}>{LABELS.RENTING}</span>
        </div>
        <div className={classes.tickContainer}>
          <img
            src={APP === AGENCIES.NAX ? tickBlack : tick}
            className={`${
              selectedClass === PropertyClass.Tenant ? classes.tick : classes.hideItem
            }`}
          />
        </div>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setPropertyDetails({ field: 'selectedClass', value: PropertyClass.Owner });
        }}
        className={classes.menuItem}
      >
        <div className={classes.menuRow}>
          <span className={classes.text}>{LABELS.OWNER}</span>
        </div>
        <div className={classes.tickContainer}>
          <img
            src={APP === AGENCIES.NAX ? tickBlack : tick}
            className={`${selectedClass === PropertyClass.Owner ? classes.tick : classes.hideItem}`}
          />
        </div>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setPropertyDetails({ field: 'selectedClass', value: PropertyClass.Landlord });
        }}
        className={classes.menuItem}
      >
        <div className={classes.menuRow}>
          <span className={classes.text}>{LABELS.LANDLORD}</span>
        </div>
        <div className={classes.tickContainer}>
          <img
            src={APP === AGENCIES.NAX ? tickBlack : tick}
            className={`${
              selectedClass === PropertyClass.Landlord ? classes.tick : classes.hideItem
            }`}
          />
        </div>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setPropertyDetails({ field: 'selectedClass', value: PropertyClass.Accomodation });
        }}
        className={classes.menuItem}
      >
        <div className={classes.menuRow}>
          <span className={classes.text}>{LABELS.HOLIDAY}</span>
        </div>
        <div className={classes.tickContainer}>
          <img
            src={APP === AGENCIES.NAX ? tickBlack : tick}
            className={`${
              selectedClass === PropertyClass.Accomodation ? classes.tick : classes.hideItem
            }`}
          />
        </div>
      </MenuItem>
    </div>
  );
};

export default withStyles(styles)(SelectRole);

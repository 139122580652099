import * as React from 'react';
import { AddressLookup } from '../services/broadbandService/getBroadbandAddressLookup/GetBroadbandAddressLookup.data';
import { initialValuesState } from '../component/services/electricityService/addressForm/AddressForm';
import {
  PropertyDetails,
  ElectricityServiceDetails,
} from '../services/electricityService/getElectricityService/GetElectricityService.data';
import { AddressResult } from '../services/electricityService/getElectricityAddressLookup/GetElectricityAddressLookup.data';
import { BroadbandServiceDetails } from '../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import { PostCommonServiceAddressSaveRequest } from '../services/commonService/getCommonService/GetCommonService.data';

export class PropertyObject extends React.Component {
  public static createNmiPropertyObject(
    nmiAddress: AddressResult,
    property: PropertyDetails,
    nmiMeterNumbers: string | null,
  ) {
    const data: PropertyDetails = {
      id: property.id,
      address: (nmiAddress.FullAddressDisplay as string) || property.address,
      type: property.type,
      streetNumber: (nmiAddress.StreetNumber1 as string) || property.streetNumber,
      unitNumber: (nmiAddress.UnitNumber1 as string) || property.unitNumber,
      streetName: (nmiAddress.StreetName as string) || property.streetName,
      streetType: (nmiAddress.StreetType as string) || property.streetType,
      suburb: (nmiAddress.Suburb as string) || property.suburb,
      postcode: (nmiAddress.Postcode as string) || property.postcode,
      state: (nmiAddress.StateCode as string) || property.state,
      country: property.country || property.country,
      photos: property.photos,
      nmi: nmiAddress.NMI,
      nmiMeterNumbers,
      keyIdentifier: nmiAddress.KeyIdentifier as string,
      meterSerialNumber: nmiAddress.MeterSerialNumber,
      addressLine1: nmiAddress.AddressLine1,
      addressLine2: nmiAddress.AddressLine2,
      addressLine3: nmiAddress.AddressLine3,
    };
    return data;
  }
  public static createBroadbandPropertyDetailsObject(
    property: PropertyDetails,
    step: string,
  ): BroadbandServiceDetails {
    const data: BroadbandServiceDetails = {
      serviceDetails: {
        serviceabilityAddress: {
          ...property,
        },
      },
      step,
    };
    return data;
  }

  public createCommonServicePropertyDetailsObject(
    property: PropertyDetails,
    step: string,
    propertyId: string,
    serviceType: string,
    providerId: string,
    Attributes: any,
    serviceAccountId?: string,
  ): PostCommonServiceAddressSaveRequest {
    const data: PostCommonServiceAddressSaveRequest = {
      serviceDetails: {
        serviceabilityAddress: {
          ...property,
        },
      },
      step,
      propertyId,
      Attributes,
      serviceType,
      providerId,
      serviceAccountId: serviceAccountId ? serviceAccountId : '',
    };
    return data;
  }

  public createCommonLookupAddressObject(
    //TODO: Resolve any
    lookup: any,
    step: string,
    propertyId: string,
    serviceType: string,
    providerId: string,
    Attributes: any,
    serviceAccountId?: string | null,
  ): PostCommonServiceAddressSaveRequest {
    //TODO: Resolve any
    const data: any = {
      serviceDetails: {
        lookupDetails: {
          ...lookup,
        },
      },
      step,
      propertyId,
      Attributes,
      serviceType,
      providerId,
      serviceAccountId: serviceAccountId ? serviceAccountId : '',
    };
    return data;
  }

  public createAddresLookupPropertyObject(
    property: initialValuesState,
    serviceType: string,
  ): AddressLookup;

  public createAddresLookupPropertyObject(
    property: initialValuesState,
    serviceType: string,
  ): AddressLookup {
    const addressResult: AddressLookup = {
      ServiceType: serviceType,
      Address: {
        UnitType: '',
        StreetType: property.streetType,
        UnitNumber1: property.unitNumber,
        StreetNumber1: property.streetNumber,
        StreetName: property.streetName,
        Suburb: property.suburb,
        StateCode: property.state,
        Postcode: property.postcode,
        Country: property.country,
        KeyIdentifier: '',
      },
    };
    return addressResult;
  }

  public createPropertyDetailsObject(
    property: PropertyDetails,
    step: string,
    meterType: string | null,
    nextScheduledRead: string | null,
    serviceClassesAvailable: string | null,
  ): ElectricityServiceDetails;

  public createPropertyDetailsObject(
    property: PropertyDetails,
    step: string,
    meterType: string | null,
    nextScheduledRead: string | null,
    serviceClassesAvailable: string | null,
  ): ElectricityServiceDetails {
    const data: ElectricityServiceDetails = {
      serviceDetails: {
        serviceabilityAddress: {
          ...property,
        },
        electricityPlanDetails: {
          meterType,
          nextScheduledRead,
          serviceClassesAvailable,
        },
      },
      step,
    };
    return data;
  }
}

import React, { FC, useEffect, useState } from 'react';
import { withRouter, RouteComponentProps, Route, Switch } from 'react-router-dom';
import { Location } from 'history';
import './App.css';
import RentLayout from './routes/rentLayout/RentLayout';
import ElectricityServiceLayout from './routes/electricityServiceLayout/ElectricityServiceLayout';
import CommonSummaryLayout from './routes/commonSummaryLayout/CommonSummaryLayout';
import PaymentLayout from './routes/paymentLayout/PaymentLayout';
import CommonLayout from './routes/commonLayout/CommonLayout';
import BroadbandLayout from './routes/broadbandLayout/BroadbandLayout';
import PaymentOptionsLayout from './routes/paymentOptionsLayout/PaymentOptionsLayout';
import CommonServicesLayout from './routes/commonServiceLayout/CommonServiceLayout';
import AppUpdateLayout from './routes/appUpdateLayout/AppUpdateLayout';
import OtherServiceLayout from './routes/otherServiceLayout/OtherServiceLayout';
import Header from './component/header/Header';
import { TransitionGroup } from 'react-transition-group';
import { saveAuthDetails } from './helper/AuthHelper';
import WalletHeader from './containers/wallet/walletHeader/WalletHeader';
import Spinner from './containers/spinnerComponent/Spinner';
import PropertyOnboarding from './containers/propertyOnboarding/PropertyOnboarding';
import ServiceError from './component/serviceError/ServiceError';
import LogRocket from 'logrocket';
import { AppSettings } from './AppSettings';
import compareVersions from 'compare-versions';
import { ApplicationState } from './store/RootReducers';
import { connect } from 'react-redux';
import CheckoutLayout from './routes/checkoutLayout/CheckoutLayout';
import { getQueryParams } from './helper/QueryHelper';
import { Dispatch } from 'redux';
import { TokenActions } from './store/actions/TokenActions';
import { RentServiceActions } from './store/actions/RentServiceActions';
import { SIZES, intercomChatScript } from './Constants';
import { useIntercom } from 'react-use-intercom';
import { AGENCIES, APP } from './helper/AppNameHelper';
import { sendMessageToApp, ValidMessages } from './helper/MessageHelper';

interface AuthDetail {
  data: string;
}
interface AppProps extends RouteComponentProps {
  accessToken: string | boolean | null;
  saveTokenDetails: (accessToken: string, refreshToken: string, appName: string) => void;
  setExternal: (value: boolean) => void;
  savePropertyId: (value: number) => void;
  getSessionToken: (value: string) => void;
}

const App: FC<AppProps> = ({
  accessToken,
  location,
  history,
  saveTokenDetails,
  setExternal,
  savePropertyId,
  getSessionToken,
}) => {
  const { boot } = useIntercom();
  const [backAnimation, setBackAnimation] = useState<boolean>(false);
  const accessTokenURL = getQueryParams(location, 'accessToken');
  const refreshTokenURL = getQueryParams(location, 'refreshToken');
  const appNameURL = getQueryParams(location, 'appName');
  const isExternalURL = getQueryParams(location, 'isExternal');
  const propertyIdURL = getQueryParams(location, 'propertyId');
  const sessionURL = getQueryParams(location, 'session');

  useEffect(() => {
    if (accessTokenURL && refreshTokenURL && appNameURL) {
      saveTokenDetails(accessTokenURL, refreshTokenURL, appNameURL);
    }
  }, [accessTokenURL, refreshTokenURL, appNameURL]);

  useEffect(() => {
    if (sessionURL) {
      getSessionToken(sessionURL);
      const newURL = location.search
        .split('&')
        .filter((p) => !p.includes('session'))
        .join('&');

      history.push(location.pathname + newURL);
    }
  }, [sessionURL]);

  useEffect(() => {
    if (isExternalURL) {
      setExternal(true);
      if (APP === AGENCIES.SORTED || APP === AGENCIES.NAX || APP === AGENCIES.SWITCH) {
        boot();
      }
      // const intercomChat = document.createElement('script');
      // intercomChat.type = 'text/javascript';
      // intercomChat.appendChild(document.createTextNode(intercomChatScript));
      // document.head.appendChild(intercomChat);
    }
  }, [isExternalURL]);

  useEffect(() => {
    const resize = () => {
      const isMobileSize = window.innerWidth <= SIZES.md;

      // This handles onMount
      window.intercomSettings = {
        ...window.intercomSettings,
        vertical_padding: isMobileSize ? 80 : 20,
      };

      // This handles resizing (doesn't work onMount, hence above)
      document
        .querySelectorAll<HTMLElement>('.intercom-lightweight-app-launcher')
        .forEach((element) => {
          element.style.bottom = `${isMobileSize ? '80px' : '20px'}`;
        });
      document.querySelectorAll<HTMLElement>('.intercom-launcher-frame').forEach((element) => {
        element.style.bottom = `${isMobileSize ? '80px' : '20px'}`;
      });
      document.querySelectorAll<HTMLElement>('.intercom-messenger-frame').forEach((element) => {
        element.style.bottom = `${isMobileSize ? '160px' : '100px'}`;
      });
    };
    resize();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  useEffect(() => {
    if (propertyIdURL) {
      savePropertyId(Number(propertyIdURL));
    }
  }, [propertyIdURL]);
  useEffect(() => {
    sendMessageToApp(ValidMessages.token);
  }, []);

  if (!accessToken) {
    document.addEventListener('message', (data) => {
      saveAuthDetails((data as unknown as AuthDetail).data);
      try {
        LogRocket.identify(JSON.parse((data as unknown as AuthDetail).data).userId, {
          email: JSON.parse((data as unknown as AuthDetail).data).email,
          name: JSON.parse((data as unknown as AuthDetail).data).name,
        });
      } catch (_) {}
    });
    window.addEventListener('message', (data) => {
      saveAuthDetails((data as unknown as AuthDetail).data);
      try {
        LogRocket.identify(JSON.parse((data as unknown as AuthDetail).data).userId, {
          email: JSON.parse((data as unknown as AuthDetail).data).email,
          name: JSON.parse((data as unknown as AuthDetail).data).name,
        });
      } catch (_) {}
    });
  }
  useEffect(() => {
    LogRocket.init(AppSettings.logRocket.group, {});
    const bpointScript = document.createElement('script');
    const bpointScriptSrc = window.location.href.includes('prodau')
      ? process.env.BPOINT_PROD
      : process.env.BPOINT_UAT;
    bpointScript.type = 'text/javascript';
    bpointScript.src = bpointScriptSrc as string;
    document.head.appendChild(bpointScript);

    const westpacQuickStream = document.createElement('script');
    const westpacQuickStreamSrc = window.location.href.includes('prodau')
      ? process.env.WESTPAC_QUICKSTREAM_PROD
      : 'https://api.quickstream.support.qvalent.com/rest/v1/quickstream-api-1.0.min.js';
    westpacQuickStream.type = 'text/javascript';
    westpacQuickStream.src = westpacQuickStreamSrc as string;
    document.head.appendChild(westpacQuickStream);

    return () => {
      document.removeEventListener('message', (data) => {
        saveAuthDetails((data as unknown as AuthDetail).data);
      });
      window.removeEventListener('message', (data) => {
        saveAuthDetails((data as unknown as AuthDetail).data);
      });
    };
  }, []);

  const backAnimationToggle = (toggle: boolean) => {
    setBackAnimation(toggle);
  };

  const handleUrlParams = (location: Location) => {
    const appVersion = getQueryParams(location, 'appVer');
    try {
      if (!!appVersion && !compareVersions.compare(appVersion, '3.6.0', '>=')) {
        return <AppUpdateLayout />;
      }
    } catch (err) {
      return <AppUpdateLayout />;
    }
    const url = location.pathname.split('/')[2];
    switch (url) {
      case 'commonSummary':
        return <CommonSummaryLayout />;
      case 'rent':
        return <RentLayout />;
      case 'electricity':
        return <ElectricityServiceLayout />;
      case 'broadband':
        return <BroadbandLayout />;
      case 'wallet':
        return <PaymentOptionsLayout />;
      case 'otherService':
        return <OtherServiceLayout />;
      case 'commonService':
        return <CommonServicesLayout />;
      case 'checkout':
        return <CheckoutLayout />;
      default:
        return <PaymentLayout />;
    }
  };

  return (
    <div style={{ backgroundColor: '#fafafa', height: window.innerHeight }}>
      {accessToken ? (
        <Switch>
          <Route exact path="/onboarding/serviceError" component={ServiceError} />
          <Route
            path="/onboarding/propertyOnboard/:pageId?"
            render={() => (
              <>
                <Header handleAnimation={() => backAnimationToggle(true)} />
                <TransitionGroup>
                  <PropertyOnboarding />
                </TransitionGroup>
              </>
            )}
            exact
          />
          <Route
            path="*"
            render={({ location }) => (
              <>
                <Header handleAnimation={() => backAnimationToggle(true)} />
                <WalletHeader handleAnimation={() => backAnimationToggle(true)} />
                {handleUrlParams(location)}
                <CommonLayout />
              </>
            )}
          />
        </Switch>
      ) : (
        <Spinner overrideLoading />
      )}
      <Spinner />
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  accessToken: state.token.accessToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveTokenDetails: (accessToken: string, refreshToken: string, appName: string) => {
    dispatch(TokenActions.setAccessToken(accessToken));
    dispatch(TokenActions.setRefreshToken(refreshToken));
    dispatch(TokenActions.setAppName(appName));
  },
  setExternal: (value: boolean) => dispatch(TokenActions.setExternal(value)),
  savePropertyId: (value: number) => dispatch(RentServiceActions.savePropertyId(value)),
  getSessionToken: (value: string) => dispatch(TokenActions.getSessionTokenRequest(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));

import * as React from 'react';
import {
  withStyles,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { AddressListStyles, Styles } from './AddressListStyle';
import FooterComponent from '../../../footer/FooterComponent';
import {
  BroadbandAddressLookup,
  AddressResult,
} from '../../../../services/broadbandService/getBroadbandAddressLookup/GetBroadbandAddressLookup.data';
import locationIcon from '../../../../assets/location.png';
import selectedIcon from '../../../../assets/images/payment/filled.svg';
import { primaryAndSecondaryAddress } from '../../../../helper/PrimaryAndSecondaryAddress';
interface PropsFromParent {
  propertyList: BroadbandAddressLookup;
  handleNext: (index: number) => void;
  handleSkip: () => void;
}

interface OwnState {
  selectedIndex: number | null;
  addressList: {
    maxHeight: number;
    overflow: string;
  };
}

type OwnProps = AddressListStyles & PropsFromParent;

class AddressList extends React.Component<OwnProps, OwnState> {
  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      selectedIndex: null,
      addressList: {
        maxHeight: 0,
        overflow: 'scroll',
      },
    };
  }
  public componentDidMount() {
    this.setState({
      addressList: {
        maxHeight: window.innerHeight - 300,
        overflow: 'scroll',
      },
    });
  }
  public render() {
    const { classes, propertyList } = this.props;
    const { addressList } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <Typography variant="h5" className={classes.mainHeading}>
            Confirm address
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            Select your property below
          </Typography>
          <List style={addressList}>
            {propertyList &&
              propertyList.AddressSearch.map((property: AddressResult, index: number) =>
                this.renderList(property, index, this.state.selectedIndex === index),
              )}
          </List>
          <Button
            variant="outlined"
            onClick={this.props.handleSkip}
            className={classes.addressNotShownButton}
          >
            MY ADDRESS ISN'T SHOWN
          </Button>
        </div>
        <FooterComponent
          buttonText="NEXT"
          disabled={this.state.selectedIndex === null}
          onClick={() => this.props.handleNext(this.state.selectedIndex as number)}
        />
      </div>
    );
  }
  private renderList = (property: AddressResult, index: number, isSelected: boolean) => {
    const { classes } = this.props;
    const address = primaryAndSecondaryAddress(property.FullAddressDisplay as string);
    const primaryText = address[0];
    const secondaryText = address[1];
    return (
      <ListItem
        className={classes.list}
        button
        onClick={() => this.setState({ selectedIndex: index })}
      >
        <ListItemAvatar>
          <img src={locationIcon} className={classes.locationIcon} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography className={classes.primaryText} variant="caption">
              {primaryText}
            </Typography>
          }
          secondary={
            <Typography className={classes.secondaryText} variant="subtitle1">
              {secondaryText}
            </Typography>
          }
        />
        {isSelected && (
          <ListItemSecondaryAction>
            <img src={selectedIcon} />
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  };
}

export default withStyles(Styles)(AddressList);

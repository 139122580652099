import { createStyles, WithStyles } from '@material-ui/core';
import { color } from '../../theme/Theme';

export const Styles = createStyles({
  root: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 40,
    paddingBottom: 14,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: color.secondary,
  },
  titleStyle: {
    marginTop: 28,
    paddingTop: 24,
    marginLeft: 8,
    fontSize: '23px',
    letterSpacing: '0.25px',
    color: color.dark,
  },
  personalDetailCard: { justifyContent: 'space-between', display: 'flex', flexDirection: 'row' },
  snackRoot: {
    margin: 8,
  },
  snackMessageBoxStyle: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
  },
  iconStyle: {
    marginRight: 12,
    height: 24,
    width: 24,
  },
  snackTextStyle: {
    color: color.light,
  },
  cardStyle: {
    borderRadius: 8,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 16,
    border: `1px solid ${color.darkerWhite}`,
    backgroundColor: color.light,
  },
  cardStylePlan: {
    borderRadius: 8,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 0,
    border: `1px solid ${color.darkerWhite}`,
    backgroundColor: color.light,
  },
  cardContentStyle: {
    'display': 'flex',
    'flexDirection': 'column',
    '&:last-child': {
      paddingBottom: 12,
    },
  },
  cardHeaderStyle: {
    height: 48,
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    padding: 16,
    alignItems: 'center',
    borderBottom: `1px solid ${color.darkerWhite}`,
  },
  headingText: {
    fontSize: '17px',
    fontFamily: 'GT Walsheim',
    color: color.dark,
    letterSpacing: '0.1px',
  },
  cardTextStyle: {
    fontFamily: 'GT Walsheim Light',
    fontSize: '16px',
    marginTop: 16,
    letterSpacing: 0.15,
    lineHeight: 1.43,
    whiteSpace: 'pre-line',
    color: color.dark,
  },
  subTitleStyle: {
    fontFamily: 'GT Walsheim Regular',
    color: color.dark,
    padding: '0 16px 0 16px',
  },
  GSTinfo: {
    fontFamily: 'GT Walsheim',
    color: color.loaderBackground,
    fontSize: '14px',
    marginTop: '16px',
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: '0.15px',
  },
  tickImage: {
    height: 18,
    width: 18,
    display: 'flex',
    paddingLeft: 3,
    paddingTop: 3,
  },
  acceptTermsdefault: {
    height: 20,
    width: 20,
    border: '2px solid #D3D3D3',
  },
  flexRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  termsSection: {
    marginRight: 20,
    paddingTop: 6,
    paddingLeft: 10,
  },
  footerSection: {
    bottom: 77,
    left: 0,
    right: 0,
    position: 'fixed',
    padding: '16px 16px 16px, 16px',
    backgroundColor: color.light,
  },
  acceptedTerms: {
    height: 24,
    width: 24,
    backgroundColor: '#2d1b49',
  },
  textStyle: {
    textAlign: 'center',
    fontSize: 16.3,
    marginTop: 32,
    fontFamily: 'GT Walsheim light',
    color: color.dark,
  },
  downloadContentStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  documentText: {
    marginTop: '16px',
    fontSize: 16,
    fontFamily: 'GT Walsheim',
    marginLeft: '16px',
  },
  downloadButton: {
    display: 'flex',
    flexDirection: 'row',
    height: 45,
    alignItems: 'center',
    margin: '16px 6px 16px 18px',
  },
  downloadImage: {
    height: 24,
    width: 24,
  },
  downloadText: {
    fontFamily: 'GT Walsheim',
    fontSize: 16,
    letterSpacing: 0.1,
    marginLeft: 16,
    color: 'rgba(0,0,0, 0.87)',
    flex: 1,
  },
  keyValueContainer: {
    display: 'flex',
    margin: '5px 0',
    alignItems: 'flex-end',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  keyValueContainerColumn: {
    display: 'flex',
    margin: '5px 0',
    alignItems: 'flex-start',
    width: '100%',
    flexDirection: 'column',
  },
  detailCard: {
    minHeight: 50,
    padding: 15,
    margin: 10,
    marginBottom: 18,
    borderRadius: 10,
  },
  seperator: {
    height: 1,
    margin: 4,
    marginBottom: 20,
    background: '#eee',
  },
  bottomText: {
    padding: '0px 0px',
  },
  section: {
    marginBottom: 20,
  },
  keyText: {
    fontSize: 16,
  },
  value: {
    fontSize: 16,
    paddingTop: 10,
  },
  gtwLight: {
    fontFamily: 'GT Walsheim Light',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  summaryInfo: {
    fontFamily: 'GT Walsheim',
    color: color.loaderBackground,
    fontSize: '16px',
    marginTop: '16px',
    marginBottom: '5px',
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: '0.15px',
  },
  button: {
    'height': 45,
    'width': '100%',
    'background': color.buttonBackground,
    '&:hover': {
      backgroundColor: color.buttonBackground,
    },
  },
  buttonText: {
    color: color.light,
  },
  disabledButtonText: {
    color: color.secondary300,
  },
});

export interface PlanSummaryStyle extends WithStyles<typeof Styles> {}

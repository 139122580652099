import { ElectricityServiceState } from '../state/ElectricityServiceState';
import {
  ElectricityServiceAction,
  ElectricityServiceActionTypes,
} from '../actions/ElectricityServiceActions';
import {
  ElectricityServiceDetails,
  PropertyDetails,
} from '../../services/electricityService/getElectricityService/GetElectricityService.data';
import { ElectricityAddressLookup } from '../../services/electricityService/getElectricityAddressLookup/GetElectricityAddressLookup.data';

const getElectricityServiceInitialState: ElectricityServiceState = {
  service: undefined,
  error: undefined,
  electricityAddressLookup: undefined,
  property: undefined,
  loading: false,
  serviceAddress: undefined,
};

// tslint:disable-next-line:cyclomatic-complexity
export function electricityServiceReducer(
  state = getElectricityServiceInitialState,
  action: ElectricityServiceAction,
): ElectricityServiceState {
  switch (action.type) {
    case ElectricityServiceActionTypes.GetElectricityServiceStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case ElectricityServiceActionTypes.GetElectricityServiceSuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        service: action.data as ElectricityServiceDetails,
      };
    case ElectricityServiceActionTypes.GetElectricityServiceError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    case ElectricityServiceActionTypes.GetElectricityServiceAddressLookupStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case ElectricityServiceActionTypes.GetElectricityServiceAddressLookupSuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        electricityAddressLookup: action.data as ElectricityAddressLookup,
      };
    case ElectricityServiceActionTypes.GetElectricityServiceAddressLookupError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    case ElectricityServiceActionTypes.SaveProperty:
      return {
        ...state,
        property: action.data as PropertyDetails,
      };
    case ElectricityServiceActionTypes.PostElectricityServiceStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case ElectricityServiceActionTypes.PostElectricityServiceSuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        service: action.data as ElectricityServiceDetails,
      };
    case ElectricityServiceActionTypes.PostElectricityServiceError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    case ElectricityServiceActionTypes.DeleteElectricityServiceStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case ElectricityServiceActionTypes.DeleteElectricityServiceSuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        service: action.data as ElectricityServiceDetails,
      };
    case ElectricityServiceActionTypes.DeleteElectricityServiceError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    case ElectricityServiceActionTypes.GetAddressStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case ElectricityServiceActionTypes.GetAddressSuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        serviceAddress: action.data as PropertyDetails,
      };
    case ElectricityServiceActionTypes.GetAddressError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    default:
      return state;
  }
}

import { color } from '../../theme/Theme';
import { createMuiTheme, createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  root: {
    backgroundColor: color.secondary,
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 48px)',
  },
  body: {
    padding: '12px',
  },
  footerButtonsContainer: {
    bottom: 12,
    left: 12,
    right: 12,
    position: 'fixed',
  },
  footerButton: {
    padding: '16px 16px 16px 16px',
    backgroundColor: color.buttonBackgroundSecondary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.light,
    fontWeight: 600,
    borderRadius: 6,
  },
  footerButtonSecondary: {
    marginTop: 12,
    padding: '16px 16px 16px 16px',
    backgroundColor: color.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.black87,
    fontWeight: 600,
    borderRadius: 6,
    border: `${color.borderColor} 2px solid`,
  },
  disabled: {
    padding: '16px 16px 16px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    borderRadius: 6,
    backgroundColor: color.disabled,
    color: color.black38,
  },
  titleStyle: {
    paddingTop: 70,
    font: 'GT Walsheim',
    marginBottom: 45,
  },
  subTitleStyle: {
    paddingBottom: 12,
  },
  doneIconStyle: {
    width: 24,
    height: 24,
    marginRight: 16,
  },
  subTextStyle: {
    padding: 16,
    color: color.dark,
  },
  switchStyle: {
    color: '#009fb3',
  },
  imageStyle: {
    width: 24,
    height: 24,
  },
  defaultIconStyle: {
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: color.secondary400,
  },
  listItemStyle: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  emptyCardStyle: {
    height: 64,
    backgroundColor: '#f9f9f9',
    display: 'flex',
    paddingLeft: 24,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  textStyle: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 14,
    fontWeight: 300,
  },
  optInSectionStyle: {
    marginBottom: 64,
  },
  card: {
    backgroundColor: color.light,
    padding: '12px',
    borderRadius: 8,
    border: `${color.borderColor} 2px solid`,
    marginBottom: '12px',
  },
});

export const theme = createMuiTheme({
  palette: {
    primary: { main: color.titleColor },
  },
});

export interface LeaseServiceabilityStyles extends WithStyles<typeof styles> {}

import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import AbandonCart from '../../containers/abandonCart/AbandonCart';
import CheckoutComplete from '../../containers/checkoutComplete/CheckoutComplete';
import CheckoutConfigure from '../../containers/checkoutConfigure/CheckoutConfigure';
import CheckoutFinalize from '../../containers/checkoutFinalize/CheckoutFinalize';
import CheckoutSelect from '../../containers/checkoutSelect/CheckoutSelect';
import CheckoutSummary from '../../containers/checkoutSummary/CheckoutSummary';
import { routes } from '../../Routes';

export default class CheckoutLayout extends React.Component {
  public render() {
    return (
      <Switch>
        <Route path={routes.checkout.select} component={CheckoutSelect} />
        <Route path={routes.checkout.configure} component={CheckoutConfigure} />
        <Route path={routes.checkout.summary} component={CheckoutSummary} />
        <Route path={routes.checkout.finalize} component={CheckoutFinalize} />
        <Route path={routes.checkout.abandonCart} component={AbandonCart} />
        <Route path={routes.checkout.completeCart} component={CheckoutComplete} />
      </Switch>
    );
  }
}

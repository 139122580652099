import { color } from '../../../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  selectedPlanContainer: {
    marginTop: 15,
    padding: 15,
    backgroundColor: color.light,
    borderRadius: 8,
  },
  serviceHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: -5,
    marginLeft: 5,
  },
  serviceTypeIconContainer: {
    width: 24,
    height: 24,
  },
  serviceTypeIcon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  serviceTypeTitle: {
    marginRight: 'auto',
    marginLeft: '15px',
    fontFamily: 'GT Walsheim',
    fontSize: '18px',
    color: color.black87,
  },
  card: {
    borderRadius: 6,
    border: `${color.borderColor} 2px solid`,
    marginTop: 15,
    overflow: 'hidden',
  },
  cardHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: 15,
    marginRight: 15,
    borderBottom: `${color.borderColor} 2px solid`,
  },
  borderBottom: {
    borderBottom: `${color.borderColor} 2px solid`,
  },
  noBorder: {
    borderBottom: 'none',
  },
  cardTitle: {
    marginRight: 'auto',
    fontFamily: 'GT Walsheim',
    fontSize: '16px',
    color: color.black87,
  },
  cardIconContainer: {
    marginLeft: 'auto',
    width: 20,
    height: 20,
  },
  cardIcon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  content: {
    overflow: 'hidden',
    transition: 'all 0.3s ease-out',
    height: '100%',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 15px',
    backgroundColor: color.secondary,
    borderTop: `${color.borderColor} 2px solid`,
    cursor: 'pointer',
  },
  actionTitle: {
    marginRight: 'auto',
    fontFamily: 'GT Walsheim',
    fontSize: '14px',
    color: color.black87,
  },
  actionIconContainer: {
    marginLeft: 'auto',
    width: 16,
    height: 16,
    transition: 'all 0.3s ease-out',
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
  actionIcon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  planHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 15px',
  },
  logoContainer: {
    height: '40px',
    width: '40px',
  },
  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  titleContainer: {
    marginLeft: 15,
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    paddingBottom: 3,
  },
  title: {
    fontFamily: 'GT Walsheim',
    fontSize: '12px',
    color: color.black38,
    textTransform: 'uppercase',
    marginBottom: 4,
  },
  description: {
    fontFamily: 'GT Walsheim',
    fontSize: '15px',
    color: color.black87,
  },
  editContainer: {
    marginLeft: 'auto',
    width: 18,
    height: 18,
    opacity: 0.5,
    cursor: 'pointer',
  },
  editIcon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
});

export interface SelectedPlanStyles extends WithStyles<typeof styles> {}

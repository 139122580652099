import { color } from '../../theme/Theme';
import { WithStyles, createStyles } from '@material-ui/core';

export const Styles = createStyles({
  root: {
    margin: 8,
  },
  messageBoxStyle: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
  },
  iconStyle: {
    marginRight: 12,
    height: 24,
    width: 24,
  },
  textStyle: {
    color: color.light,
  },
});

export interface SnackbarComponentStyles extends WithStyles<typeof Styles> {}

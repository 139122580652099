import { RentalFrequency } from '../services/lease/getLease/GetLeaseResponse.data';

const FORTNIGHT_MULTIPLIER = 168 / 365;
const WEEKLY_MULTIPLIER = 84 / 365;
/**
 * Converts given monthly account to specified frequency based on multipliers
 * defined on confluence.
 * @param frequency Cost frequency based on defined enum
 * @param monthlyAmount
 * @param returnOnlyAmount pass it as true if only numeric value is required
 * @param toDecimals decimal precision for number, default 2
 */
export const calculateCost = (
  frequency: RentalFrequency,
  monthlyAmount: number,
  returnOnlyAmount?: boolean,
  toDecimals = 2,
): string => {
  let calculatedAmount = 0;
  switch (frequency) {
    case RentalFrequency.Monthly:
      calculatedAmount = monthlyAmount;
      break;
    case RentalFrequency.Fortnightly:
      calculatedAmount = monthlyAmount * FORTNIGHT_MULTIPLIER;
      break;
    case RentalFrequency.Weekly:
      calculatedAmount = monthlyAmount * WEEKLY_MULTIPLIER;
      break;
    default:
      calculatedAmount = monthlyAmount;
  }
  const finalAmount = `$${Number.parseFloat(calculatedAmount.toString()).toFixed(toDecimals)}`;

  if (returnOnlyAmount) {
    return finalAmount;
  } else {
    return `${finalAmount} per ${getFrequency(frequency)}`;
  }
};

const getFrequency = (frequency: RentalFrequency) => {
  switch (frequency) {
    case RentalFrequency.Monthly:
      return 'month';
    case RentalFrequency.Fortnightly:
      return 'fortnight';
    case RentalFrequency.Weekly:
      return 'week';
    default:
      return 'month';
  }
};
//TODO: Resolve any
export function dollarFormatter(amount: any) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  return amount ? formatter.format(amount) : 'N/A';
}

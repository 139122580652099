export const TITLE = 'Add card details';
export const CARD_NUMBER = 'Card number';
export const EXPIRY = 'Expiry';
export const CVV = 'CVV';
export const CARD_HOLDER_NAME = 'Card holder name';
export const SECURE_CONNECTION = 'Secure connection';
export const BUTTON_TEXT = 'SAVE';
export const expiryDateError = 'Invalid date';
export const expiryDateLength = 5;
export const cardHolderNameRegex = /^[a-zA-Z]+((\s[a-zA-Z]+)+)?$/;
export const cardHolderNameMaxLength = 20;
export const cardNumberLength = 19;
export const nameMaxLengthError = 'Cannot exceed 20 characters';
export const nameFormatError = 'Only alphabets allowed';
export const invalidCardError = 'Invalid card number';
export const cardTypeNotSupportedError = 'card type is not supported';
export const requiredFieldError = 'Required field';
export const expiryDatePattern = [/[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/];
export const cardNumberPattern = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  ' ',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  ' ',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  ' ',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];
export enum CardType {
  Visa = 'visa',
  MasterCard = 'mastercard',
}

export const LABELS = {
  NAV_TITLE: 'Add credit card',
  NAV_SUBTITLE: 'Please add in your card details below — we’ll safely store these details for you.',
};

export interface MatchParams {
  propertyId: string;
  serviceType: string;
  serviceAccountId: string;
  paymentRefId: string;
}

export interface InitialCardValues {
  cardHolderName: string;
  cardNumber: string;
  expiryDate: string;
  cvv: string;
}

export const initialCardValues: InitialCardValues = {
  cardHolderName: '',
  cardNumber: '',
  expiryDate: '',
  cvv: '',
};

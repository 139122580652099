import * as React from 'react';
import AddressListComponent from '../../../../component/services/broadbandService/addressList/AddressList';
// import { AnyAction } from "redux";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/RootReducers';
import {
  BroadbandAddressLookup,
  AddressResult,
} from '../../../../services/broadbandService/getBroadbandAddressLookup/GetBroadbandAddressLookup.data';
import { History } from 'history';
import { RouteComponentProps } from 'react-router-dom';
import { routes } from '../../../../Routes';
import { BroadbandServiceActions } from '../../../../store/actions/BroadbandServiceActions';
import { BroadbandServiceDetails } from '../../../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import { AnyAction } from 'redux';
import { PropertyDetails } from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { PropertyObject } from '../../../../objectFactory/PropertyObjectFactory';
import { AddressListLabels } from '../../../../component/services/electricityService/addressForm/AddressFormConstants';
import { BackHelper } from '../../../../helper/BackHelper';

interface PropsFromState {
  properties: BroadbandAddressLookup;
  property: PropertyDetails;
}

interface PropsFromDispatch {
  postProperty: (propertyId: string, data: BroadbandServiceDetails, onSuccess: () => void) => void;
}

interface PropsFromRoute {
  history: History;
}
type OwnProps = PropsFromState &
  PropsFromDispatch &
  PropsFromRoute &
  RouteComponentProps<{ propertyId: string }>;

class AddressList extends React.Component<OwnProps, {}> {
  public render() {
    const { properties } = this.props;
    return (
      <AddressListComponent
        propertyList={properties}
        handleSkip={this.handleSkip}
        handleNext={this.handleNext}
      />
    );
  }
  private handleSkip = () => {
    const { propertyId } = this.props.match.params;
    BackHelper.saveCount(-1);
    this.props.history.push(routes.broadbandService.address.emptyList(propertyId));
  };
  private handleNext = (index: number) => {
    const { propertyId } = this.props.match.params;
    const { property } = this.props;
    const results = this.props.properties.AddressSearch;
    const nmiAddress = PropertyObject.createNmiPropertyObject(results[index], property, null);
    const addressData = PropertyObject.createBroadbandPropertyDetailsObject(
      nmiAddress,
      AddressListLabels.stepName,
    );
    this.props.postProperty(propertyId, addressData, this.onSuccess);
  };
  private onSuccess = () => {
    const { propertyId } = this.props.match.params;
    BackHelper.saveCount(-1);
    this.props.history.push(routes.broadbandService.type(propertyId));
  };
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>): PropsFromDispatch {
  return {
    postProperty: (propertyId: string, data: BroadbandServiceDetails, onSuccess: () => void) => {
      dispatch(
        BroadbandServiceActions.postBroadbandServiceStart({
          propertyId,
          serviceAccountId: null,
          data,
          onSuccess,
        }),
      );
    },
  };
}
function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    properties: state.broadbandService.addressLookup as BroadbandAddressLookup,
    property: state.broadbandService.property as PropertyDetails,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddressList);

import * as React from 'react';
import {
  withStyles,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { AddressListStyles, Styles } from '../../broadbandService/addressList/AddressListStyle';
import FooterComponent from '../../../footer/FooterComponent';
import {
  ElectricityAddressLookup,
  ElectricityService,
} from '../../../../services/electricityService/getElectricityAddressLookup/GetElectricityAddressLookup.data';
import locationIcon from '../../../../assets/location.png';
import selectedIcon from '../../../../assets/images/payment/filled.svg';
import { primaryAndSecondaryAddress } from '../../../../helper/PrimaryAndSecondaryAddress';
import { AddressListLabels } from './AddressFormConstants';
interface PropsFromParent {
  propertyList: ElectricityAddressLookup;
  handleNext: (electricityService: ElectricityService) => void;
  handleSkip: () => void;
}

interface OwnState {
  selectedIndex: string | null;
  addressList: {
    maxHeight: number;
    overflow: string;
  };
}
type OwnProps = AddressListStyles & PropsFromParent;

class AddressList extends React.Component<OwnProps, OwnState> {
  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      selectedIndex: null,
      addressList: {
        maxHeight: 0,
        overflow: 'scroll',
      },
    };
  }

  public componentDidMount() {
    this.setState({
      addressList: {
        maxHeight: window.innerHeight - 300,
        overflow: 'scroll',
      },
    });
  }

  public render() {
    const { classes } = this.props;
    const { addressList } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <Typography variant="h5" className={classes.mainHeading}>
            {AddressListLabels.title}
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            {AddressListLabels.subTitle}
          </Typography>
          <List style={addressList}>{this.renderExactMatchAddress()}</List>
          <Button
            variant="outlined"
            onClick={this.props.handleSkip}
            className={classes.addressNotShownButton}
          >
            {AddressListLabels.buttonLabel}
          </Button>
        </div>
        <FooterComponent
          buttonText={AddressListLabels.footerButtonLabel}
          disabled={this.state.selectedIndex === null}
          onClick={() => this.props.handleNext(this.props.propertyList.ElectricityService)}
        />
      </div>
    );
  }
  private renderExactMatchAddress = () => {
    const { classes, propertyList } = this.props;
    const address = primaryAndSecondaryAddress(
      propertyList.ElectricityService.NmiFullConfirmationAddress,
    );
    const primaryText = address[0];
    const secondaryText = address[1];
    const NmiNumber = `NMI: ${propertyList.ElectricityService.Nmi}`;
    return (
      <ListItem
        className={classes.list}
        button
        onClick={() =>
          this.setState({
            selectedIndex: propertyList.ElectricityService.Nmi,
          })
        }
      >
        <ListItemAvatar>
          <img src={locationIcon} className={classes.locationIcon} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography className={classes.primaryText} variant="caption">
              {primaryText}
            </Typography>
          }
          secondary={
            <Typography className={classes.secondaryText} variant="subtitle1">
              {secondaryText}
              <Typography className={classes.NmiNumber}>{NmiNumber}</Typography>
            </Typography>
          }
        />
        {this.state.selectedIndex === propertyList.ElectricityService.Nmi && (
          <ListItemSecondaryAction>
            <img src={selectedIcon} />
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  };
}

export default withStyles(Styles)(AddressList);

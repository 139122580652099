import { color } from '../../../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  loadingRoot: {
    backgroundColor: color.headerColor,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  sortedLoader: {
    height: 70,
  },
  primaryText: {
    color: color.black87,
    fontSize: '30px',
  },
  secondaryText: {
    color: color.black87,
    marginTop: 5,
  },
  alignCenter: {
    marginTop: '30vh',
  },
});

export interface SettingUpPropertyStyles extends WithStyles<typeof styles> {}

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { APP, AGENCIES } from '../helper/AppNameHelper';

export const layerIndex = {
  labelAboveInput: 0x100,
  iconAboveForm: 0x200,
  dialogAboveForm: 0x300,
  autoCompletePopUp: 0x400,
};

export const color =
  APP === AGENCIES.NAX
    ? {
        primary: '#000000',
        secondary: '#fafafa',
        secondary200: '#d3d3d3',
        secondary300: '#a9a9a9',
        secondary400: '#808080',
        black87: 'rgba(0, 0, 0, 0.87)',
        black38: 'rgba(0, 0, 0, 0.38)',
        black60: 'rgba(0, 0, 0, 0.6)',
        light: '#ffffff',
        danger: '#ff525b',
        darkerWhite: '#eeeeee',
        dark: '#000000',
        lightBlue: '#000000',
        completed: '#1fcee6',
        colorActive: '#009ED5',
        loaderBackground: '#f4f3f3',
        buttonBackground: '#000000',
        buttonBackgroundSecondary: '#000000',
        headerColor: '#f4f3f3',
        navColor: '#f4f3f3',
        navTitleColor: '#000000',
        pageLayoutColor: '#f4f3f3',
        titleColor: '#000000',
        borderColor: '#eeeeee',
        disabled: '#f6f6f6',
        link: '#1fcee6',
        fadedBlue: '#f4f3f3',
        actionButton: '#FF3324',
      }
    : APP === AGENCIES.SWITCH
    ? {
        primary: '#2A71E5',
        secondary: '#fafafa',
        secondary200: '#d3d3d3',
        secondary300: '#a9a9a9',
        secondary400: '#808080',
        black87: 'rgba(0, 0, 0, 0.87)',
        black38: 'rgba(0, 0, 0, 0.38)',
        black60: 'rgba(0, 0, 0, 0.6)',
        light: '#ffffff',
        danger: '#ff525b',
        darkerWhite: '#eeeeee',
        dark: '#000000',
        lightBlue: '#2A71E5',
        completed: '#2A71E5',
        colorActive: '#2A71E5',
        loaderBackground: '#4CDFAC',
        buttonBackground: '#4CDFAC',
        buttonBackgroundSecondary: '#4CDFAC',
        headerColor: '#ffffff',
        navColor: '#ffffff',
        navTitleColor: '#000000',
        pageLayoutColor: '#ffffff',
        titleColor: '#000000',
        borderColor: '#eeeeee',
        disabled: '#f6f6f6',
        link: '#2A71E5',
        fadedBlue: '#f2f8f9',
        actionButton: '#4CDFAC',
      }
    : {
        primary: '#1f0d33',
        secondary: '#fafafa',
        secondary200: '#d3d3d3',
        secondary300: '#a9a9a9',
        secondary400: '#808080',
        black87: 'rgba(0, 0, 0, 0.87)',
        black38: 'rgba(0, 0, 0, 0.38)',
        black60: 'rgba(0, 0, 0, 0.6)',
        light: '#ffffff',
        danger: '#ff525b',
        darkerWhite: '#eeeeee',
        dark: '#000000',
        lightBlue: '#0C7181',
        completed: '#0C7181',
        colorActive: '#0C7181',
        loaderBackground: '#ffffff',
        buttonBackground: '#0C7181',
        buttonBackgroundSecondary: '#0C7181',
        headerColor: '#ffffff',
        navColor: '#ffffff',
        navTitleColor: '#000000',
        pageLayoutColor: '#ffffff',
        titleColor: '#2d1b49',
        borderColor: '#eeeeee',
        disabled: '#f6f6f6',
        link: '#1fcee6',
        fadedBlue: '#f2f8f9',
        actionButton: '#0C7181',
      };

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: 'GT Walsheim',
    h4: {
      fontSize: 15,
      fontWeight: 300,
      lineHeight: 1.15,
    },
    h5: {
      fontSize: 27.2,
      fontWeight: 500,
      color: color.primary,
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: 1.5,
      opacity: 0.6,
      color: color.dark,
    },
    caption: {
      fontSize: 13.6,
      fontWeight: 500,
      lineHeight: 1.18,
      letterSpacing: 0.4,
      color: color.primary,
    },
    button: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.14,
      letterSpacing: 0.8,
      color: color.dark,
    },
    body2: {
      fontFamily: 'GT Walsheim light',
      fontSize: 14,
      fontWeight: 300,
      lineHeight: 1.43,
      letterSpacing: 0.2,
      color: color.secondary400,
    },
  },
  overrides: {
    MuiButton: {
      textPrimary: {
        '&$disabled': {
          backgroundColor: '#d3d3d3',
          color: '#000000',
          textAlign: 'center',
        },
        'backgroundColor': color.primary,
        'color': color.light,
        'textAlign': 'center',
      },
      textSecondary: {
        backgroundColor: color.primary,
        color: color.light,
        textAlign: 'center',
      },
    },
    MuiInputLabel: {
      formControl: {
        '&:after': {
          color: color.primary,
        },
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: `2px solid #1f0d33`,
        },
      },
    },
  },
};

const theme = createMuiTheme(themeOptions);

export default theme;

import * as React from 'react';
import {
  withStyles,
  Button,
  Typography,
  Drawer,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from '@material-ui/core';
import { WarningDrawerStyles, styles } from './WarningDrawerStyles';
import { serviceDeatils, ServiceDetails, walletLabels } from '../WalletConstants';

interface PropsFromParent {
  onClose: () => void;
  open: boolean;
  services: string[];
}

type OwnProps = PropsFromParent & WarningDrawerStyles;

function warningDrawer(props: OwnProps) {
  const { classes } = props;
  const renderServiceItems = (service: string) => {
    const serviceItem = serviceDeatils.find((data) => data.type === service) as ServiceDetails;
    return (
      <ListItem className={classes.listItemStyle}>
        <ListItemAvatar>
          <img src={serviceItem.logo} className={classes.logoStyle} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="subtitle1" className={classes.listItemText}>
              {serviceItem.label}
            </Typography>
          }
        />
      </ListItem>
    );
  };

  return (
    <Drawer
      anchor="bottom"
      open={props.open}
      classes={{
        paper: classes.drawerContiner,
      }}
    >
      <div className={classes.root}>
        <div>
          <Typography variant="h6" className={classes.titleStyle}>
            {walletLabels.WARNING_DRAWER_TITLE}
          </Typography>
          <Typography variant="body2" className={classes.subTitleStyle}>
            {walletLabels.WARNING_DRAWER_SUBTITLE1}
          </Typography>
          <Typography variant="subtitle1" className={classes.listHeadingStyle}>
            {walletLabels.WARNING_DRAWER_SUBTITLE2}
          </Typography>
          {props.services && props.services.map((service) => renderServiceItems(service))}
        </div>
        <div className={classes.buttonContainer}>
          <Button onClick={props.onClose} className={classes.buttonStyle}>
            <Typography variant="caption" className={classes.buttonText}>
              {walletLabels.CANCEL_LABEL}
            </Typography>
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

const warningDrawerComponent = withStyles(styles)(warningDrawer);
export default warningDrawerComponent;

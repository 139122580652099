export interface GetLeaseResponse {
  readonly id: number;
  readonly property: Property | undefined | null;
  readonly agency: Agency;
  readonly primaryTenant: User | undefined | null;
  readonly monthlyCost: number | undefined | null;
}

export interface Property {
  readonly id: number;
  readonly address: string;
  readonly type: PropertyType | string | undefined | null;
  readonly bedrooms: number | undefined | null;
  readonly bathrooms: number | undefined | null;
  readonly carspaces: number | undefined | null;
  readonly description: string | undefined | null;
  readonly managementFee: number | undefined | null;
  readonly commissionType: string | undefined | null;
  readonly secondaryAgents: User[] | undefined | null;
  readonly landlords: User[] | undefined | null;
  readonly managingAgent: User | undefined | null;
  readonly leasingAgent: User | undefined | null;
  readonly photos: PhotoLink[] | undefined | null;
  readonly unitNumber: string | undefined | null;
  readonly streetName: string | undefined | null;
  readonly suburb: string | undefined | null;
  readonly postcode: string | undefined | null;
  readonly streetNumber: string | undefined | null;
  readonly state: string | undefined | null;
  readonly country: string | undefined | null;
  readonly stateBondOffice: string | undefined | null;
  readonly leases: Lease[] | undefined | null;
  readonly leaseData: LeaseData;
  readonly tenants: Tenants;
}
export enum PropertyType {
  House = 'House',
  Townhouse = 'Townhouse',
  Apartment = 'Apartment',
  Villa = 'Villa',
}
export interface PhotoLink {
  readonly id: number;
  readonly link: string;
  readonly createdBy: string;
  readonly createdDate: string;
  readonly lastModifiedDate: string;
  readonly lastModifiedBy: string;
}
export interface User {
  readonly id: number;
  readonly userType: UserType;
  readonly name: string;
  readonly surname: string;
  readonly email: string;
  readonly phone: string;
  readonly salutation?: UserSalutation | null | undefined;
  readonly dob?: Date | string | null | undefined;
  readonly idType?: UserIdentifierType | null | undefined;
  readonly idNumber?: string | null | undefined;
  readonly idExpiry?: Date | string | null | undefined;
  readonly address?: string | null | undefined;
  readonly active?: boolean | null | undefined;
}
export enum UserType {
  Tenant = 'Tenant',
  Landlord = 'Landlord',
  Agent = 'Agent',
  Admin = 'Admin',
}
export enum UserSalutation {
  Mr = 'Mr.',
  Mrs = 'Mrs.',
  Ms = 'Ms.',
}
export enum UserIdentifierType {
  DriversLicense = 'Drivers license',
  Passport = 'Passport',
  Medicare = 'Medicare',
}

export interface Agency {
  readonly id: number;
  readonly name: string;
  readonly code: string;
  readonly tradingName: string;
  readonly agentLicenseNumber: string;
  readonly abn: string;
  readonly acn: string;
  readonly postalAddress: string;
  readonly email: string;
  readonly phone: string;
  readonly pmsName: string;
}

export interface LeaseData {
  readonly current: boolean;
  readonly daysLeft: number;
  readonly signed: boolean;
  readonly advertised: boolean;
  readonly pending: boolean;
}

export interface Tenants {
  readonly currentTenants: TenantsType[];
  readonly pendingTenants: TenantsType[];
}
export interface TenantsType {
  readonly id: number;
  readonly primaryTenant: User;
  readonly secondaryTenants: User[] | undefined | null;
  readonly startdate: string | undefined | null;
  readonly leased: boolean | undefined | null;
}
export interface Lease {
  readonly id: number;
  readonly property: Property | undefined | null;
  readonly monthlyCost: number | undefined | null;
  readonly bond: number | undefined | null;
  readonly urgentRepairsAmount: number | undefined | null;
  readonly rentalFrequency: RentalFrequency | undefined | null;
  readonly dateAvailable: string | undefined | null;
  readonly leaseDuration: number | undefined | null;
  readonly startDate: string | undefined | null;
  readonly endDate: string | undefined | null;
  readonly moveInDate: string | undefined | null;
  readonly premisesProvidedWith: string | undefined | null;
  readonly adultsPermitted: number | undefined | null;
  readonly childrenPermitted: number | undefined | null;
  readonly petPermitted: boolean | undefined | null;
  readonly allowedPetsInfo: string | undefined | null;
  readonly inspectionDate: string | undefined | null;
  readonly payViaSorted: boolean | undefined | null;
  readonly includeOnBond: UsersInBond[] | undefined | null;
  readonly tenants: User[] | undefined | null;
  readonly primaryTenant: User | undefined | null;
  readonly leased?: boolean;
  readonly depositAmount?: number | null;
  readonly depositPaymentDate?: string | undefined | null;
  readonly refId: string;
}

export enum RentalFrequency {
  Weekly = 'Weekly',
  Fortnightly = 'Fortnightly',
  Monthly = 'Monthly',
}
export interface UsersInBond {
  readonly userId: number;
  readonly onBond: boolean;
}

import * as React from 'react';
import VoiceScreenComponent from '../../../../component/services/plan/broadband/VoiceScreen';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/RootReducers';
import { Dispatch, AnyAction } from 'redux';
import { BroadbandPlanState } from '../../../../store/state/BroadbandPlanState';
import { BroadbandPlanActions } from '../../../../store/actions/BroadbandPlanAction';
import {
  BroadbandPlan,
  BroadbandPlanDetails,
  BroadbandProducts,
} from '../../../../models/plan/broadband/getPlanDetails/BroadbandPlan.data';
import { BackHelper } from '../../../../helper/BackHelper';

interface PropsFromState {
  plan: BroadbandPlanState;
}

interface PropsFromDispatch {
  saveSelectedVoicePlan: (data: string) => void;
}

type OwnProps = PropsFromDispatch & PropsFromState;

class VoiceScreen extends React.Component<OwnProps> {
  public render() {
    const { plan } = this.props;
    const choosenProduct =
      plan.broadbandChoice === BroadbandProducts.ADSL
        ? (plan.broadbandPlan as BroadbandPlan).ADSL
        : (plan.broadbandPlan as BroadbandPlan).NBN;
    return (
      <VoiceScreenComponent
        {...this.props}
        planDetails={choosenProduct as BroadbandPlanDetails}
        plan={this.props.plan}
        handleNext={this.handleNext}
      />
    );
  }

  private handleNext = (choice: string) => {
    BackHelper.saveCount(-1);
    if (this.props.plan.broadbandChoice === BroadbandProducts.NBN) {
      this.props.saveSelectedVoicePlan(choice);
    }
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    plan: state.broadbandPlan,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): PropsFromDispatch {
  return {
    saveSelectedVoicePlan: (data: string) => dispatch(BroadbandPlanActions.saveVoiceChoice(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VoiceScreen);

import { createStyles, WithStyles } from '@material-ui/core';
import { color } from '../../../../theme/Theme';

export const Styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    marginTop: 48,
  },
  serviceHeader: {
    display: 'flex',
    paddingTop: 15,
  },
  addressList: {
    maxHeight: window.innerHeight - 300,
    overflow: 'scroll',
  },
  description: {
    marginBottom: 45,
  },
  serviceHeaderText: {
    padding: 5,
  },
  serviceContent: {
    padding: '0px 15px 15px 15px',
    marginTop: 0,
  },
  serviceContentDesc: {
    padding: 15,
    marginTop: 0,
  },
  planCards: {
    height: 73,
    margin: '15px 0 15px 0',
  },
  serviceCard: {
    width: '100%',
    marginTop: -10,
    zIndex: 999,
    borderRadius: 8,
    position: 'absolute',
  },
  bottomDrawer: {
    marginTop: 24,
    padding: '0px 16px 18px 16px',
    whiteSpace: 'pre-line',
  },
  bottomDrawerTitle: {
    fontSize: 23,
    letterSpacing: 0.3,
  },
  drawerButtons: {
    marginTop: 16,
    background: color.primary,
    flex: 1,
    display: 'flex',
    height: 36,
  },
  downloadIcon: {
    height: 24,
    width: 24,
    marginLeft: 8,
  },
  downloadText: {
    font: 'GT Walsheim Regular',
    fontSize: 16,
    color: color.dark,
    marginLeft: 16,
  },
  drawerFooter: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginTop: 20,
  },
  acceptanceChecks: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    flexDirection: 'column',
    marginBottom: 10,
    marginLeft: -20,
  },
  flexRow: {
    flex: 1,

    width: '100%',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 10,
    paddingTop: 10,
    alignItems: 'center',
  },
  termsSection: {
    marginRight: 20,
    paddingTop: 6,
    paddingLeft: 10,
  },
  acceptTermsdefault: {
    height: 20,
    width: 20,
    border: '2px solid #D3D3D3',
  },
  acceptedTerms: {
    height: 24,
    width: 24,
    backgroundColor: '#2d1b49',
  },
  tickImage: {
    height: 18,
    width: 18,
    display: 'flex',
    paddingLeft: 3,
    paddingTop: 3,
  },
  terms: {
    fontFamily: 'GT Walsheim Light',
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: '20px',
    marginBottom: '24px',
    alignContent: 'center',
    alignItems: 'center',
  },
  downloadSection: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginTop: 8,
  },
  seperator: {
    height: 1,
    margin: 4,
    marginBottom: 20,
    background: '#eee',
  },
  section2: {
    marginBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  keyText: {
    fontSize: 16,
    marginRight: 10,
  },
  blueText: {
    color: color.lightBlue,
    textDecoration: 'underline',
  },
  detailCard: {
    minHeight: 50,
    padding: 15,
    margin: 10,
    marginBottom: 18,
    borderRadius: 10,
  },
  linkHeader: {
    paddingTop: 10,
    paddingLeft: 20,
  },
  switchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  disabledButtonText: {
    color: color.secondary300,
  },
  buttonText: {
    color: color.light,
  },
  button: {
    'height': 45,
    'width': '100%',
    'background': color.buttonBackground,
    '&:hover': {
      backgroundColor: color.buttonBackground,
    },
  },
  modalSubtitle: {
    border: 'none',
    borderBottom: '1px solid #000',
    fontFamily: 'GT Walsheim',
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: '20px',
    marginBottom: '24px',
    alignContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    paddingBottom: 12,
  },
  bottomDrawerContainer: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  serviceHeaderImg: {
    marginTop: 40,
    height: 230,
    width: '100%',
  },
  serviceLogo: {
    height: 60,
    width: 60,
    marginLeft: 10,
    marginRight: 5,
    borderRadius: 30,
  },
  serviceLogo1: {
    height: 40,
    width: 40,
    marginLeft: 10,
    marginTop: 10,
    marginRight: 5,
    borderRadius: 30,
  },
  avatarIcon: {
    fill: 'yellow',
    height: 30,
    width: 30,
  },
  colorBlack: {
    color: color.black87,
  },
  colorBlackInprogress: {
    fontSize: 16,
    marginTop: 20,
    color: color.primary,
  },
  key: {
    fontSize: 13,
    color: color.black38,
  },
  value: {
    fontFamily: 'GT Walsheim Regular',
    fontSize: 17,
  },
  list: {
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '10px',
    paddingRight: '2px',
    margineTop: '20px',
    marginBottom: '20px',
    background: '#F8F8F8',
    border: '1px solid #eee',
    borderRadius: 5,
  },
  locationIcon: {
    width: '18px',
    height: '20px',
  },
  nmiLabel: {
    paddingLeft: '73px',
  },
  primaryText: {
    fontSize: '16px',
    paddingLeft: '-90px',
    color: color.black87,
  },

  secondaryText: {
    fontSize: '16px',
    color: color.black60,
  },
  NmiNumber: {
    fontSize: '16px',
    color: color.completed,
  },
  addressNotShownButton: {
    height: 36,
    marginBottom: 20,
  },
  item: {
    margin: '10px 20px 20px 20px',
  },
  section: {
    borderRadius: 8,
    border: '1px solid rgba(0, 0, 0, 0.1)',
    marginBottom: 25,
  },
  sectionTitle: {
    fontFamily: 'GT Walsheim Regular',
    margin: '10px 20px',
    fontSize: 17,
  },
  infoContainer: {
    padding: 15,
    marginTop: -5,
    background: 'white',
    width: '92%',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    position: 'relative',
  },
  infoHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '91%',
    position: 'absolute',
    padding: '10px 20px',
    marginTop: -70,
  },
  serviceInfoLogo: {
    height: 40,
    width: 40,
  },
  serviceInfoName: {
    color: 'white',
    fontSize: 29,
  },
  vignette: {
    boxShadow: '0 0 100px rgba(0,0,0,0.9) inset',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  providerName: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
  },
  serviceProviderLogo: {
    height: 40,
    width: 40,
    marginRight: 15,
  },
  serviceDescriptionText: {
    padding: 10,
  },
});

export interface OverViewStyles extends WithStyles<typeof Styles> {}

import * as React from 'react';
import { InputAdornment, Input, withStyles } from '@material-ui/core';
import { Logging } from '../../helper/Logging';
import searchLogo from '../../assets/search.svg';
import arrowBack from '../../assets/back-arrow.png';
import cross from '../../assets/cross.png';
import { AddressValue } from './AddressValue.data';
import { AddressPickerState } from './AddressPickerState.data';
import { AddressPickerStyles, styles } from './AddressPickerStyles';
import { GooglePlacesHelper } from './GooglePlacesHelper';
export interface AddressPickerProps {
  readonly id: string;
  readonly value?: AddressValue | undefined | null;

  // optional
  readonly disabled?: boolean | undefined | null;
  readonly placeholder?: string | undefined | null;
  readonly fixSubPremise?: boolean | undefined | null; // default: true, if true, calls GooglePlacesHelper.fixAddressSubPremise()
  readonly handleAddressChanged?: ((value: AddressValue) => void) | undefined | null;
}

interface AddressPickerReduxProps extends AddressPickerStyles {
  readonly onKeyPress: (key: string) => void;
}

type OwnProps = AddressPickerProps & AddressPickerReduxProps;

/// <summary>
/// Google API address autocomplete picker
/// https://talum.github.io/blog/2017/05/20/autocomplete-an-address-with-a-react-form/
/// </summary>
export class AddressPickerComponent extends React.Component<OwnProps, AddressPickerState> {
  private addressInput: HTMLInputElement;

  public constructor(props: OwnProps) {
    super(props);

    this.state = this.initializeState(props);
  }

  public componentWillReceiveProps(nextProps: Readonly<OwnProps>) {
    if (
      !AddressValue.equals(nextProps.value, this.props.value) ||
      nextProps.disabled !== this.props.disabled
    ) {
      this.setState(this.initializeState(nextProps));
    }
  }

  public componentDidMount() {
    GooglePlacesHelper.createGooglePlacesAutoComplete(this.addressInput, (value) =>
      this.handleAddressChanged(value, true),
    );
  }

  /// <summary>
  /// Be prepared for args.place being null. Usually, when tesing and mock implementation is used.
  /// </summary>
  public handleAddressChanged(value: AddressValue, updateAddress = false) {
    Logging.trace(`AddressPicker.handleAddressChanged() #${this.props.id}`, value);

    /* istanbul ignore else*/
    if (this.props.fixSubPremise !== false) {
      value = GooglePlacesHelper.fixAddressSubPremise(value);
    }

    this.setState(
      {
        value,
      },
      () => this.notifyChange(updateAddress),
    );
  }

  public render() {
    const { classes, onKeyPress } = { ...this.props };

    return (
      <div
        className={`${!!this.state.value.inputValue ? classes.active : classes.searchContainer}`}
      >
        <Input
          id={this.props.id}
          disableUnderline={true}
          name={this.props.id}
          value={AddressValue.format(this.state.value)}
          onChange={(e: React.ChangeEvent) => {
            this.handleAddressChanged({
              inputValue: (e.target as HTMLInputElement).value,
              place: null,
            });
            onKeyPress((e.target as HTMLInputElement).value);
          }}
          onKeyPress={(e: React.KeyboardEvent) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          readOnly={!!this.props.disabled}
          inputProps={{
            maxLength: 200,
          }}
          placeholder={this.props.placeholder || undefined}
          startAdornment={
            <InputAdornment
              position="end"
              className={classes.searchIcon}
              onClick={() => {
                if (!!this.state.value.inputValue) {
                  this.handleAddressChanged({
                    inputValue: '',
                    place: null,
                  });
                }
              }}
            >
              <img
                src={!!this.state.value.inputValue ? arrowBack : searchLogo}
                className={classes.searchLogo}
                style={!!this.state.value.inputValue ? { height: 25, width: 25 } : {}}
                alt={'Search Logo'}
              />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment
              position="end"
              className={classes.crossIcon}
              onClick={() => {
                this.handleAddressChanged({
                  inputValue: '',
                  place: null,
                });
              }}
            >
              {!!this.state.value.inputValue && (
                <img
                  src={cross}
                  className={classes.searchLogo}
                  style={{ height: 25, width: 25 }}
                  alt={'Search Logo'}
                />
              )}
            </InputAdornment>
          }
          inputRef={(elem: HTMLInputElement) => (this.addressInput = elem)}
          className={classes.root}
          fullWidth
        />
      </div>
    );
  }

  private initializeState(props: OwnProps): AddressPickerState {
    // this.state can be null/undefined here
    return {
      value: props.value
        ? props.value
        : // this.state ? (this.state.value || new AddressValue()) : -- controlled, always takes props
          new AddressValue(),
    };
  }

  private notifyChange(updateAddress: boolean) {
    if (this.props.handleAddressChanged && updateAddress) {
      this.props.handleAddressChanged(this.state.value);
    }
  }
}

// component with styles
export const AddressPicker = withStyles(styles)(AddressPickerComponent);

import { color } from '../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';
import { SIZES } from '../../Constants';

export const styles = createStyles({
  paddingContainer: {
    paddingLeft: '80px',
    paddingRight: '80px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  mainContainer: {
    margin: 'auto',
    maxWidth: '800px',
    position: 'relative',
  },
  noPadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  width100: {
    width: '100%',
  },
  height100: {
    height: '100%',
  },
  noMaxWidth: {
    maxWidth: 'none',
  },
});

export interface MainContainerStyles extends WithStyles<typeof styles> {}

export const loadingAnimation = {
  background: 'linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%)',
  backgroundSize: '200% 100%',
  animation: '1.5s shine linear infinite',
  backgroundColor: '#242733',
};

export const classNameGenerator = (classes: (string | undefined | null | boolean)[]) => {
  return classes.join(' ');
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react';
import { FormHelperText, withStyles } from '@material-ui/core';
import { styles, PersonalDetailStyles } from './PersonalDetailsStyles';
import { PersonalDetails as PersonalDetailsInterface } from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { parseInitialDate } from '../../../../helper/DateFormatter';
import {
  MINIMUM_AGE,
  validationSchema,
  LABELS,
  TITLES,
  DATE_FORMAT_PICKER,
  ID_TYPES,
  STATE_OF_ISSUE,
  REFERENCE_NUMBER,
  MEDICARE_CARD_COLOR,
  COUNTRY_CODES,
} from '../../../../component/services/personalDetails/PersonalDetailConstants';
import { Formik } from 'formik';
import InputComponent from '../../../../component/services/electricityService/addressForm/InputComponent';
import SelectComponent from '../../../../component/services/electricityService/addressForm/SelectComponent';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import PhoneInput from 'react-phone-number-input';
import { PersonalDetailsFormInitialValues } from '../../../../models/checkout/Checkout';
import { getDefaultIdExpiry } from '../AccountSection/AccountSectionUtils';

interface PersonalDetailsProps extends PersonalDetailStyles {
  personalDetails: PersonalDetailsInterface;
  setPersonalDetails: (updatedDetails: PersonalDetailsFormInitialValues) => void;
}

const PersonalDetails: FC<PersonalDetailsProps> = ({
  classes,
  personalDetails,
  setPersonalDetails,
}) => {
  const initialValues: PersonalDetailsFormInitialValues = {
    email: personalDetails.email || '',
    title: personalDetails.salutation || '',
    firstName: personalDetails.name || '',
    lastName: personalDetails.surname || '',
    mobile: personalDetails.phone ? personalDetails.phone.replace(/ /g, '') : '',
    dob: personalDetails.dob ? parseInitialDate(personalDetails.dob as string) : null,
    expiry: personalDetails.idExpiry ? parseInitialDate(personalDetails.idExpiry as string) : null,
    idExpiry: personalDetails.idExpiry
      ? parseInitialDate(personalDetails.idExpiry as string)
      : null,
    idNumber: personalDetails.idNumber || '',
    idType: personalDetails.idType || '',
    stateIssue: personalDetails.idDetails ? personalDetails.idDetails.dlStateIssue : null,
    medicareCardColor: personalDetails.idDetails
      ? personalDetails.idDetails.medicareCardColor
      : null,
    countryOfIssue: personalDetails.idDetails ? personalDetails.idDetails.passportCountry : null,
    referenceNumber: personalDetails.idDetails ? personalDetails.idDetails.medicareRefNumber : null,
  };
  const expiryDate = new Date();
  expiryDate.setFullYear(expiryDate.getFullYear() + (2050 - expiryDate.getFullYear()));
  const expiryMinDate = new Date();
  expiryMinDate.setMonth(expiryMinDate.getMonth() - 3);
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - MINIMUM_AGE);
  const getErrorMessage = (error: string | undefined, touched: boolean | undefined) => {
    return error && touched ? error : undefined;
  };
  return (
    <div className={classes.root}>
      <Formik
        initialValues={initialValues}
        validateOnChange={true}
        validateOnBlur
        onSubmit={(values) => {}}
        validationSchema={validationSchema}
        // tslint:disable-next-line:cyclomatic-complexity
        render={({ values, setFieldValue, handleSubmit, errors, touched }) => {
          return (
            <div className={classes.formRoot}>
              <InputComponent
                label={LABELS.email}
                value={values.email}
                setFieldValue={(tag, value) => {
                  setPersonalDetails({
                    ...values,
                    [tag]: value,
                  });
                  setFieldValue(tag, value);
                }}
                disabled
                name="email"
                type="text"
                style={classes.emailStyle}
                errorMessage={getErrorMessage(errors.email, touched.email)}
              />
              <SelectComponent
                label={LABELS.title}
                value={values.title}
                setFieldValue={(tag, value) => {
                  setPersonalDetails({
                    ...values,
                    [tag]: value,
                  });
                  setFieldValue(tag, value);
                }}
                name="title"
                selectValues={TITLES}
                errorMessage={getErrorMessage(errors.title, touched.title)}
              />
              <InputComponent
                label={LABELS.firstName}
                value={values.firstName}
                setFieldValue={(tag, value) => {
                  setPersonalDetails({
                    ...values,
                    [tag]: value,
                  });
                  setFieldValue(tag, value);
                }}
                name="firstName"
                type="text"
                style={classes.inputStyle}
                errorMessage={getErrorMessage(errors.firstName, touched.firstName)}
              />
              <InputComponent
                label={LABELS.lastName}
                value={values.lastName}
                setFieldValue={(tag, value) => {
                  setPersonalDetails({
                    ...values,
                    [tag]: value,
                  });
                  setFieldValue(tag, value);
                }}
                name="lastName"
                type="text"
                style={classes.inputStyle}
                errorMessage={getErrorMessage(errors.lastName, touched.lastName)}
              />
              <div
                className={`${classes.mobilePicker} ${
                  getErrorMessage(errors.mobile, true) ? classes.errorBorder : classes.defaultBorder
                }`}
              >
                <div className={classes.mobileLabelStyle}>{LABELS.mobile}</div>
                <PhoneInput
                  flagUrl="https://public.sortedservices.com/flags/4x3/{xx}.svg"
                  defaultCountry="AU"
                  value={values.mobile ? values.mobile : ''}
                  onChange={(getPhoneDigit) => {
                    setFieldValue('mobile', getPhoneDigit);
                    setPersonalDetails({
                      ...values,
                      mobile: getPhoneDigit,
                    });
                  }}
                />
              </div>
              {getErrorMessage(errors.mobile, true) ? (
                <span className={classes.errorMessage}>{getErrorMessage(errors.mobile, true)}</span>
              ) : null}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label={LABELS.dob}
                  value={values.dob}
                  openTo={'year'}
                  initialFocusedDate={maxDate}
                  InputLabelProps={{
                    classes: {
                      root: values.dob ? classes.inputLabelStyle : classes.noInputLabelStyle,
                    },
                  }}
                  onChange={(value) => {
                    setFieldValue('dob', value);
                    setPersonalDetails({
                      ...values,
                      dob: value,
                    });
                  }}
                  maxDate={maxDate}
                  format={DATE_FORMAT_PICKER}
                  disableOpenOnEnter
                  InputProps={{ className: classes.datePicker }}
                  className={classes.datePickerComponent}
                  error={!!getErrorMessage(errors.dob, touched.dob)}
                />
                {getErrorMessage(errors.dob, touched.dob) && (
                  <FormHelperText className={classes.errorMessage}>{errors.dob}</FormHelperText>
                )}
              </MuiPickersUtilsProvider>
              <SelectComponent
                label={LABELS.idType}
                value={values.idType}
                setFieldValue={(tag, value) => {
                  setPersonalDetails({
                    ...values,
                    [tag]: value,
                  });
                  setFieldValue(tag, value);
                }}
                name="idType"
                onChange={() => {
                  setFieldValue('referenceNumber', null);
                  setFieldValue('medicareCardColor', null);
                  setFieldValue('stateIssue', null);
                  setFieldValue('countryOfIssue', null);
                }}
                selectValues={ID_TYPES}
                errorMessage={getErrorMessage(errors.idType, touched.idType)}
              />
              {values.idType === 'DriversLicence' ? (
                <SelectComponent
                  label={LABELS.stateIssue}
                  value={values.stateIssue || ''}
                  setFieldValue={(tag, value) => {
                    setPersonalDetails({
                      ...values,
                      [tag]: value,
                    });
                    setFieldValue(tag, value);
                  }}
                  name="stateIssue"
                  selectValues={STATE_OF_ISSUE}
                  errorMessage={getErrorMessage(errors.stateIssue, touched.stateIssue)}
                />
              ) : values.idType === 'Medicare' ? (
                <div>
                  <SelectComponent
                    label={LABELS.referenceNumber}
                    value={values.referenceNumber || ''}
                    setFieldValue={(tag, value) => {
                      setPersonalDetails({
                        ...values,
                        [tag]: value,
                      });
                      setFieldValue(tag, value);
                    }}
                    name="referenceNumber"
                    selectValues={REFERENCE_NUMBER}
                    errorMessage={getErrorMessage(errors.referenceNumber, touched.referenceNumber)}
                  />
                  <SelectComponent
                    label={LABELS.medicareCardColor}
                    value={values.medicareCardColor || ''}
                    setFieldValue={(tag, value) => {
                      setPersonalDetails({
                        ...values,
                        [tag]: value,
                      });
                      setFieldValue(tag, value);
                    }}
                    name="medicareCardColor"
                    selectValues={MEDICARE_CARD_COLOR}
                    errorMessage={getErrorMessage(
                      errors.medicareCardColor,
                      touched.medicareCardColor,
                    )}
                  />
                </div>
              ) : values.idType === 'Passport' ? (
                <SelectComponent
                  label={LABELS.countryOfIssue}
                  value={values.countryOfIssue || ''}
                  setFieldValue={(tag, value) => {
                    setPersonalDetails({
                      ...values,
                      [tag]: value,
                    });
                    setFieldValue(tag, value);
                  }}
                  name="countryOfIssue"
                  selectValues={COUNTRY_CODES}
                  errorMessage={getErrorMessage(errors.countryOfIssue, touched.countryOfIssue)}
                />
              ) : undefined}
              <InputComponent
                label={LABELS.idNumber}
                value={values.idNumber}
                setFieldValue={(tag, value) => {
                  setPersonalDetails({
                    ...values,
                    [tag]: value,
                  });
                  setFieldValue(tag, value);
                }}
                name="idNumber"
                type="text"
                style={classes.inputStyle}
                errorMessage={getErrorMessage(errors.idNumber, touched.idNumber)}
              />

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label={LABELS.idExpiry}
                  value={values.idExpiry}
                  openTo={'year'}
                  initialFocusedDate={new Date()}
                  style={{ marginBottom: 10 }}
                  InputLabelProps={{
                    classes: {
                      root: values.idExpiry ? classes.inputLabelStyle : classes.noInputLabelStyle,
                    },
                  }}
                  //TODO: Resolve any
                  onChange={(value: any) => {
                    setFieldValue(
                      'expiry',
                      value.getDate() +
                        '/' +
                        parseInt(value.getMonth() + 1, 10).toString() +
                        '/' +
                        value.getFullYear(),
                    );
                    setFieldValue('idExpiry', value);
                    setPersonalDetails({
                      ...values,
                      idExpiry: value,
                    });
                  }}
                  maxDate={expiryDate}
                  format={DATE_FORMAT_PICKER}
                  disableOpenOnEnter
                  InputProps={{ className: classes.datePicker }}
                  className={classes.datePickerComponent}
                  error={!!getErrorMessage(errors.idExpiry, touched.idExpiry)}
                  minDate={expiryMinDate}
                />
                {getErrorMessage(errors.idExpiry, touched.idExpiry) && (
                  <FormHelperText className={classes.errorMessage}>
                    {errors.idExpiry}
                  </FormHelperText>
                )}
              </MuiPickersUtilsProvider>
            </div>
          );
        }}
      />
    </div>
  );
};

export default withStyles(styles)(PersonalDetails);

import * as React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  ListItemSecondaryAction,
  Button,
} from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { CONCESSION_TYPES, TITLE, BOTTOM_TEXT } from './ConcessionCardConstants';
import Footer from '../footer/FooterComponent';
import { styles } from './ConcessionCardStyles';

export interface Props extends WithStyles<typeof styles> {}

interface AddConcessionState {
  readonly selected: number | boolean;
  readonly nextPage: string;
  readonly type: string;
}
interface PropsFromParent {
  handleNext: (redirectTo: string) => void;
  handleSkip: () => void;
  propertyId: string;
}
type OwnProps = Props & PropsFromParent;
class ConcessionCard extends React.Component<OwnProps, AddConcessionState> {
  constructor(props: OwnProps) {
    super(props);
    this.state = {
      selected: false,
      nextPage: '',
      type: '',
    };
  }
  public render() {
    const { classes, propertyId } = this.props;
    const { selected, nextPage } = this.state;
    return (
      <div className={classes.root}>
        <Typography className={classes.title} variant="h5">
          {TITLE}
        </Typography>
        <List className={classes.concessionTypes}>
          {CONCESSION_TYPES.map((concession, index) =>
            this.renderConcessionListItem(
              concession.type,
              concession.icon,
              selected === index,
              concession.selected,
              this.selectedConcession.bind(
                this,
                index,
                concession.nextRoute(propertyId),
                concession.type,
              ),
              classes,
            ),
          )}
        </List>
        <Footer
          buttonText={'Confirm'}
          disabled={selected === false}
          onClick={() => this.props.handleNext(nextPage)}
        >
          <Typography className={classes.bottomText}>{BOTTOM_TEXT}</Typography>
          <Button className={classes.button} onClick={this.props.handleSkip}>
            <Typography className={classes.buttonText}>SKIP</Typography>
          </Button>
        </Footer>
      </div>
    );
  }
  private selectedConcession = (index: number, link: string, type: string) => {
    this.setState({
      selected: index,
      nextPage: link,
      type,
    });
  };

  private renderConcessionListItem = (
    value: string,
    icon: string,
    isSelected: boolean,
    selectedIcon: string,
    selectedConcession: any,
    classes: any,
  ) => {
    return (
      <ListItem key={value} onClick={selectedConcession} className={classes.concessionType}>
        <ListItemAvatar>
          <img src={icon} />
        </ListItemAvatar>
        <ListItemText
          primary={<Typography className={classes.concessionTypeText}>{value}</Typography>}
        />
        {isSelected && (
          <ListItemSecondaryAction>
            <img src={selectedIcon} />
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  };
}
export default withStyles(styles)(ConcessionCard);

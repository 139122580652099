import React, { FC, useMemo, useState, useEffect } from 'react';
import { withStyles, Dialog } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import backArrow from '../../assets/arrow-back-24-px.svg';
import backArrowBlack from '../../assets/arrow-back-black.svg';
import chatMessage from '../../assets/textsms-24-px.svg';
import chatMessageBlack from '../../assets/chat-black.svg';
import sortedLogo from '../../assets/sorted-dark.png';
import sortedIcon from '../../assets/sorted-icon-dark.png';
import naxLogo from '../../assets/na-logo.svg';
import switchLogo from '../../assets/switch-logo-black.png';
import cart from '../../assets/cart-white.png';
import cartBlack from '../../assets/cart-black.png';
import { RouteComponentProps, withRouter } from 'react-router';
import { styles, HeaderPropStyles } from './HeaderStyles';
import { routes } from '../../Routes';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';
import { ServiceType } from '../../containers/services/ServiceConstants';
import { ApplicationState } from '../../store/RootReducers';
import { connect } from 'react-redux';
import { Params } from '../../store/state/HeaderState';
import { Dispatch } from 'redux';
import { HeaderActions } from '../../store/actions/HeaderActions';
import {
  urlCheck,
  dashboardUrls,
  cartUrls,
  noBackArrow,
  getBackUrls,
  blockedCountUrls,
  commonServiceUrls,
  stepRetention,
  noChat,
  showIcon,
} from './HeaderConstants';
import { BackHelper } from '../../helper/BackHelper';
import { APP, AGENCIES } from '../../helper/AppNameHelper';
import { getHeaderLabel, goBack } from './HeaderUtils';
import { CheckoutActions, CheckoutActionTypes } from '../../store/actions/CheckoutActions';
import { AvailableServicesResponse } from '../../models/checkout/Checkout';
import Cart from '../../containers/cart/Cart';
import { useCart } from '../../helper/CheckoutHelper';
import SlideLeft from '../transitionsHelper/SlideLeft';
import { SIZES } from '../../Constants';
import MainContainer from '../mainContainer/MainContainer';
import { loadingSelector } from '../../store/selectors/LoadingSelector';

interface HeaderProps extends HeaderPropStyles, RouteComponentProps<{}> {
  count: number;
  params: Params;
  isCartOpen: boolean;
  isExternal: boolean;
  loadingCheckout: boolean;
  isTradeRequest: boolean;
  saveCount: (count: number) => void;
  handleAnimation: () => void;
  toggleCart: () => void;
}

const Header: FC<HeaderProps> = ({
  classes,
  history,
  match,
  count,
  params,
  isCartOpen,
  isExternal,
  loadingCheckout,
  isTradeRequest,
  saveCount,
  handleAnimation,
  toggleCart,
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const propertyId = useMemo<string>(() => {
    let tempId = match.url.split('/')[3];

    if (isNaN(parseInt(tempId, 10))) {
      tempId = match.url.split('/')[4];
    }
    return tempId;
  }, []);

  const { cartCount } = useCart();

  const showCart = cartUrls.includes(match.url) && isTradeRequest === false;

  if (urlCheck(match)) {
    return <div />;
  }

  return (
    <>
      <AppBar position="fixed" className={classes.root}>
        <MainContainer width100>
          <div className={classes.row}>
            {match.url === routes.planSuccessSignUp.new(match.url.split('/')[3]) ||
            noBackArrow.includes(match.url) ? (
              <>
                {isExternal && screenWidth >= SIZES.sm && (
                  <img
                    src={
                      APP === AGENCIES.NAX
                        ? naxLogo
                        : APP === AGENCIES.SWITCH
                        ? switchLogo
                        : sortedLogo
                    }
                    className={
                      APP === AGENCIES.NAX
                        ? classes.naxLogo
                        : APP === AGENCIES.SWITCH
                        ? classes.switchLogo
                        : classes.sortedLogo
                    }
                    alt="sorted-logo"
                  />
                )}
                {(isExternal && screenWidth < SIZES.sm) ||
                (showIcon.includes(match.url) && !isExternal) ? (
                  <img
                    src={
                      APP === AGENCIES.NAX
                        ? naxLogo
                        : APP === AGENCIES.SWITCH
                        ? switchLogo
                        : sortedIcon
                    }
                    className={
                      APP === AGENCIES.NAX
                        ? classes.naxLogo
                        : APP === AGENCIES.SWITCH
                        ? classes.switchLogo
                        : classes.naxIcon
                    }
                    alt="sorted-logo"
                  />
                ) : (
                  <div />
                )}
              </>
            ) : (
              <img
                src={backArrowBlack}
                className={classes.imgStyle}
                onClick={() => goBack(match, history, params, propertyId, count, saveCount)}
                alt="Back Icon"
              />
            )}
            <div className={classes.label}>{getHeaderLabel(match, isTradeRequest)}</div>
            {showCart && !loadingCheckout ? (
              <div style={{ position: 'relative' }}>
                <img
                  src={cartBlack}
                  className={classes.imgStyle}
                  onClick={() => toggleCart()}
                  alt="Cart Icon"
                />
                {!!cartCount && <div className={classes.cartCount}>{cartCount}</div>}
              </div>
            ) : (
              <>
                {APP === AGENCIES.SORTED && !isExternal ? (
                  <img
                    src={chatMessageBlack}
                    className={classes.imgStyle}
                    onClick={() => sendMessageToApp(ValidMessages.Chat)}
                    alt="Chat Icon"
                  />
                ) : (
                  <div />
                )}
              </>
            )}
          </div>
        </MainContainer>
      </AppBar>
      <Dialog
        open={isCartOpen}
        fullScreen
        style={{
          zIndex: 999999,
          marginLeft: screenWidth <= 500 + 15 ? 15 : screenWidth - 500,
        }}
        TransitionComponent={SlideLeft}
        PaperProps={{
          className: classes.dialog,
        }}
      >
        <Cart history={history} configure={[routes.checkout.configure].includes(match.url)} />
      </Dialog>
    </>
  );
};

const loading = loadingSelector([CheckoutActionTypes.GET_AVAILABLE_SERVICES]);

const mapStateToProps = (state: ApplicationState) => ({
  loadingCheckout: loading(state),
  count: state.headerParams.count,
  params: state.headerParams.params,
  isCartOpen: state.checkout.isCartOpen,
  isExternal: state.token.isExternal,
  isTradeRequest: state.checkout.isTradeRequest,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveCount: (data: number) => dispatch(HeaderActions.saveCount(data)),
  toggleCart: () => dispatch(CheckoutActions.toggleCart()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header)));

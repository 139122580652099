import { APP, AGENCIES } from '../../helper/AppNameHelper';

export const LABELS = {
  TITLE: APP === AGENCIES.SWITCH ? 'Step into uncommon living.' : 'Stress free home services.',
  CONTENT: (external?: boolean) =>
    APP === AGENCIES.SWITCH
      ? 'Request maintenance, easily book a meeting room and talk to a team member through The Switch App'
      : `Get a quote and all of your home services sorted in just a few steps through ${
          external
            ? 'your app.'
            : APP === AGENCIES.NAX
            ? 'the Nelson Alexander App.'
            : 'the Sorted App.'
        }`,
  BUTTON: 'How it works',
};

import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceMock from '../__mocks__/ApiServiceMock';
import { AppSettings } from '../../AppSettings';
import ApiServiceBase from '../ApiServiceBase';
import { PostServicePayload } from '../../store/actions/ElectricityServiceActions';

const serviceType = ServiceType.Service;
export class ElectricityService {
  private readonly service: ApiServiceBase = AppSettings.currentTestSetting.testing
    ? new ApiServiceMock(serviceType)
    : new ApiService(serviceType);

  public getElectricityService(serviceAccountId: number): any {
    return this.service.get({
      route: ['service-account', serviceAccountId.toString()],
    });
  }
  public postElectricityService(service: PostServicePayload): any {
    const { data } = service;
    const { serviceAccountId } = service;
    return this.service.post(
      {
        route: ['service-account/electricity', service.propertyId.toString()],
        query: {
          serviceAccountId,
        },
      },
      data,
    );
  }
  public deleteElectricityService(serviceAccountId: string): any {
    return this.service.delete({
      route: ['service-account', serviceAccountId],
    });
  }
}

const electricityService = new ElectricityService();
export default electricityService;

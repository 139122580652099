import { PlanSummaryState } from '../state/PlanSummaryState';
import { PlanSummaryAction, PlanSummaryActionTypes } from '../actions/PlanSummaryActions';

const planSummaryInitialState: PlanSummaryState = {
  plan: undefined,
  error: undefined,
  loading: false,
  setServiceId: undefined,
  serviceSummary: undefined,
  serviceInfo: undefined,
  selections: undefined,
};

// tslint:disable-next-line:cyclomatic-complexity
export function planSummaryReducer(
  state = planSummaryInitialState,
  action: PlanSummaryAction,
): PlanSummaryState {
  switch (action.type) {
    case PlanSummaryActionTypes.GetPlanSummaryStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case PlanSummaryActionTypes.GetPlanSummarySuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        plan: action.data,
      };
    case PlanSummaryActionTypes.GetPlanSummaryError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    case PlanSummaryActionTypes.GetServiceSummaryStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case PlanSummaryActionTypes.SetServiceId:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case PlanSummaryActionTypes.SetServiceIdSuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        setServiceId: action.data,
      };
    case PlanSummaryActionTypes.GetServiceSummarySuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        serviceSummary: action.data,
      };
    case PlanSummaryActionTypes.GetServiceSummaryError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    case PlanSummaryActionTypes.PostServiceSummaryStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case PlanSummaryActionTypes.PostServiceSummarySuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        serviceSummary: action.data,
      };
    case PlanSummaryActionTypes.PostServiceSummaryError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    case PlanSummaryActionTypes.GetServiceInfoStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case PlanSummaryActionTypes.GetServiceInfoSuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        serviceInfo: action.data,
      };
    case PlanSummaryActionTypes.GetServiceInfoError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    case PlanSummaryActionTypes.SaveSelections:
      return {
        ...state,
        selections: action.data,
      };
    default:
      return state;
  }
}

import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceMock from '../__mocks__/ApiServiceMock';
import { AppSettings } from '../../AppSettings';
import ApiServiceBase from '../ApiServiceBase';
import {
  PostRentServiceRequest,
  PostRentServiceResponse,
} from './postRentService/PostRentService.data';
import { GetRentServiceResponse } from './getRentService/GetRentServiceResponse.data';
import { PostServiceabilityRequest } from './postServiceability/PostServiceability.data';

const serviceType = ServiceType.Service;

export class RentService {
  private readonly service: ApiServiceBase = AppSettings.currentTestSetting.testing
    ? new ApiServiceMock(serviceType)
    : new ApiService(serviceType);
  public getRentService(): Promise<GetRentServiceResponse[]> | GetRentServiceResponse[] {
    const service = new ApiService(ServiceType.Payment);
    return service.get({
      route: ['rental-service'],
      query: {
        app: 'web',
      },
    });
  }
  public postRentService(
    data: PostRentServiceRequest,
  ): Promise<PostRentServiceResponse> | PostRentServiceResponse {
    return this.service.post(
      {
        route: ['rental-service'],
      },
      data,
    );
  }
  public postServiceability(
    data: PostServiceabilityRequest[],
    leaseId: number,
  ): Promise<any> | any {
    return this.service.post(
      {
        route: ['optin-service', leaseId.toString()],
      },
      data,
    );
  }
}

const rentService = new RentService();
export default rentService;

import * as React from 'react';
import CommonPlanSummaryComponent from '../../../component/commonPlanSummary/CommonPlanSummary';
import { RouteComponentProps } from 'react-router';

interface PropsFromRoute {
  history: History;
}

type OwnProps = PropsFromRoute &
  RouteComponentProps<{
    serviceSummary: string;
    serviceName: string;
    propertyId: string;
    providerName: string;
  }>;
export class CommonPlanSummary extends React.Component<OwnProps, {}> {
  public serviceId = 0;
  public constructor(props: OwnProps) {
    super(props);
  }
  public render() {
    const { match } = this.props;
    return (
      <CommonPlanSummaryComponent
        propertyId={match.params.propertyId}
        providerName={match.params.providerName}
        serviceName={match.params.serviceName}
        history={this.props.history}
      />
    );
  }
}

export default CommonPlanSummary;

/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { takeEvery, put } from 'redux-saga/effects';
import { Logging } from '../../helper/Logging';
import { SessionTokenResponse } from '../../models/auth/SessionToken';
import TokenService from '../../services/token/TokenService';
import { SpinnerActions } from '../actions/SpinnerActions';
import { TokenAction, TokenActions, TokenActionTypes } from '../actions/TokenActions';

function* getSessionToken(action: TokenAction) {
  try {
    yield put(SpinnerActions.show());
    const response: SessionTokenResponse = yield TokenService.getSessionToken(
      action.data as string,
    );
    yield put(TokenActions.setAccessToken(response.access_token));
    yield put(TokenActions.setRefreshToken(response.refresh_token));
  } catch (error) {
    Logging.error('TokenWorker', {
      error,
      type: action.type,
      data: action.data,
    });
  } finally {
    // yield put(SpinnerActions.hide());
  }
}

function* tokenWatcher(): IterableIterator<any> {
  yield takeEvery(TokenActionTypes.GET_SESSION_TOKEN_REQUEST, (action: TokenAction) =>
    getSessionToken({
      type: action.type,
      data: action.data,
    }),
  );
}

export default tokenWatcher;

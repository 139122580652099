import { CommonSummaryState } from '../state/CommonSummaryState';
import {
  PlanCommonSummaryAction,
  PlanCommonSummaryActionTypes,
  StorePlanSummaryData,
} from '../actions/CommonSummaryActions';
import { PropertyDetails } from '../../services/electricityService/getElectricityService/GetElectricityService.data';

const commonSummaryState: CommonSummaryState = {
  planData: undefined,
  storePlanSummaryData: undefined,
  error: undefined,
  loading: false,
  planDetails: undefined,
  property: undefined,
  setStopCall: undefined,
  personalDetailStatus: undefined,
  savePropertyAddressStart: undefined,
  commonServicePlansSuccess: undefined,
  createProperty: undefined,
  commonServiceAddress: undefined,
  finalData: undefined,
  commonServicePlans: {},
  commonSummaryAddressLookup: undefined,
  commonServiceSelectedPlan: {},
  invoices: [],
  serviceSignupData: undefined,
  tncData: undefined,
  quotes: undefined,
  iframeData: undefined,
};

// tslint:disable-next-line:cyclomatic-complexity
export function commonSummaryReducer(
  state = commonSummaryState,
  action: PlanCommonSummaryAction,
): CommonSummaryState {
  switch (action.type) {
    // Summary pages
    case PlanCommonSummaryActionTypes.GetCommonSummary:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case PlanCommonSummaryActionTypes.GetCommonSummarySuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        planData: action.data,
      };
    case PlanCommonSummaryActionTypes.GetCommonSummaryError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };

    // Invoices data
    case PlanCommonSummaryActionTypes.GetCommonSummaryInvoicesStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case PlanCommonSummaryActionTypes.GetCommonSummaryInvoicesSuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        invoices: action.data,
      };
    case PlanCommonSummaryActionTypes.GetCommonSummaryInvoicesError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };

    // Save the property details in reducer
    case PlanCommonSummaryActionTypes.SaveProperty:
      return {
        ...state,
        property: action.data as PropertyDetails,
      };

    // Get address data for the address form page
    case PlanCommonSummaryActionTypes.GetAddressStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case PlanCommonSummaryActionTypes.GetAddressSuccess:
      return {
        ...state,
        commonServiceAddress: action.data as PropertyDetails,
        error: undefined,
        loading: false,
      };
    case PlanCommonSummaryActionTypes.GetAddressError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };

    // Get address lookup data for address list page
    case PlanCommonSummaryActionTypes.GetCommonAddressLookupStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case PlanCommonSummaryActionTypes.GetCommonAddressLookupSuccess:
      return {
        ...state,
        commonSummaryAddressLookup: action.data,
        error: undefined,
        loading: false,
      };
    case PlanCommonSummaryActionTypes.GetCommonAddressLookupError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };

    case PlanCommonSummaryActionTypes.SavePropertyAddressStart:
      return {
        ...state,
        savePropertyAddressStart: action.data,
        error: undefined,
        loading: true,
      };
    case PlanCommonSummaryActionTypes.SavePropertyAddressSuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        commonServicePlans: action.data,
      };
    case PlanCommonSummaryActionTypes.RemoveServiceId:
      return {
        ...state,
        error: undefined,
        loading: false,
        commonServicePlans: null,
      };
    case PlanCommonSummaryActionTypes.SetServiceId:
      return {
        ...state,
        error: undefined,
        loading: false,
        commonServicePlans: { id: action.data },
      };
    case PlanCommonSummaryActionTypes.CreatePropertySelected:
      return {
        ...state,
        error: undefined,
        loading: false,
        createProperty: action.data,
      };
    case PlanCommonSummaryActionTypes.SetProviderId:
      return {
        ...state,
        error: undefined,
        loading: false,
        commonServicePlansSuccess: { provider: action.data },
      };
    case PlanCommonSummaryActionTypes.SetStopCall:
      return {
        ...state,
        error: undefined,
        loading: false,
        setStopCall: action.data,
      };

    case PlanCommonSummaryActionTypes.setTncData:
      return {
        ...state,
        error: undefined,
        loading: false,
        tncData: action.data,
      };

    case PlanCommonSummaryActionTypes.SetPersonalDetailStatus:
      return {
        ...state,
        error: undefined,
        loading: false,
        personalDetailStatus: true,
      };
    case PlanCommonSummaryActionTypes.SetFinalScreenData:
      return {
        ...state,
        error: undefined,
        loading: false,
        finalData: action.data,
      };

    case PlanCommonSummaryActionTypes.SaveCommonPlanSuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        commonServicePlansSuccess: action.data,
      };

    case PlanCommonSummaryActionTypes.SaveCommonPlanError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };

    case PlanCommonSummaryActionTypes.SavePropertyAddressError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };

    //get quotes
    case PlanCommonSummaryActionTypes.GetCommonQuotesStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case PlanCommonSummaryActionTypes.GetIframeUrlStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case PlanCommonSummaryActionTypes.PostCommonSummaryData:
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case PlanCommonSummaryActionTypes.StorePlanSummaryData:
      return {
        ...state,
        storePlanSummaryData: action.data as StorePlanSummaryData,
      };

    case PlanCommonSummaryActionTypes.GetCommonQuotesSuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        quotes: action.data,
      };

    case PlanCommonSummaryActionTypes.SetIframeData:
      return {
        ...state,
        error: undefined,
        loading: false,
        iframeData: { getquote_url: action.data.body },
      };

    case PlanCommonSummaryActionTypes.GetIframeUrlSuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        iframeData: action.data,
      };
    case PlanCommonSummaryActionTypes.GetCommonQuotesError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };

    default:
      return state;
  }
}

import { color } from '../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  propertyHeader: {
    height: '30vh',
    backgroundColor: color.headerColor,
    color: color.light,
    display: 'flex',
    padding: '0px 20px 20px 20px',
    alignItems: 'flex-end',
  },
  headerPrimaryText: {
    color: color.light,
    fontSize: 24,
    marginBottom: 5,
  },
  headerSecondaryText: {
    color: color.light,
    fontSize: 14,
    marginBottom: 50,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 16px 0 16px',
    height: '100vh',
    position: 'absolute',
    top: '18vh',
    right: 0,
  },
  header: {
    marginTop: 35,
    display: 'flex',
    flexDirection: 'column',
    // marginBottom: "4vh",
    font: 'GT Walsheim',
  },
  stepper: {
    background: 'none',
  },
  dotActive: {
    background: color.light,
  },
  dot: {
    border: '1px solid white',
  },
  footerButtonsContainer: {
    bottom: 12,
    left: 12,
    right: 12,
    position: 'fixed',
  },
  footerButton: {
    padding: '16px 16px 16px 16px',
    backgroundColor: color.buttonBackgroundSecondary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.light,
    fontWeight: 600,
    borderRadius: 6,
  },
  footerButtonSecondary: {
    marginTop: 12,
    padding: '16px 16px 16px 16px',
    backgroundColor: color.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.black87,
    fontWeight: 600,
    borderRadius: 6,
    border: `${color.borderColor} 2px solid`,
  },
  disabled: {
    padding: '16px 16px 16px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    borderRadius: 6,
    backgroundColor: color.disabled,
    color: color.black38,
  },
  houseNavContainer: {
    width: '75px',
    height: '75px',
    position: 'absolute',
    right: 0,
    top: -35,
  },
  houseImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
});

export interface PropertyOnboardingStyles extends WithStyles<typeof styles> {}

import * as React from 'react';
import DateConfirmationComponent from '../../../../component/services/electricityService/connectionDate/DateConfirmation';
import {
  ElectricityServiceDetails,
  ServiceDetails,
  PropertyDetails,
  StepDetails,
  ServiceAccount,
} from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/RootReducers';
import { History } from 'history';
import { routes } from '../../../../Routes';
import { RouteComponentProps } from 'react-router-dom';
import { ConnectionDateActions } from '../../../../store/actions/ConnectionDateActions';
import { Leaves } from '../../../../models/Leaves.data';
import { LeaveListActions } from '../../../../store/actions/LeaveListAction';
import { ElectricityServiceActions } from '../../../../store/actions/ElectricityServiceActions';
import {
  DATE_FORMAT,
  createConnectionDateObject,
} from '../../../../component/services/electricityService/connectionDate/DateConfirmationConstants';
import { format, isSameDay } from 'date-fns';
import uuidv4 from 'uuid/v4';
import {
  hourValue,
  createPostPlanObject,
} from '../../../../component/services/electricityService/powerConnection/Constants';
import { PlanState } from '../../../../store/state/PlanState';
import { PlanDetails } from '../../../../models/plan/electricity/getPlanDetails/PlanDetails.data';
import { BackHelper } from '../../../../helper/BackHelper';

interface OwnState {
  connectionDate: string | Date;
  moving: boolean;
}
interface PropsFromDispatch {
  getLeaveList: (year: number) => void;
  saveConnectionDate: (date: string | Date, moving: boolean) => void;
  postConnectionDate: (
    propertyId: number,
    serviceAccountId: number,
    data: ElectricityServiceDetails,
    onSuccess: (service: ElectricityServiceDetails) => void,
  ) => void;
  postPlanDetails: (
    propertyId: number,
    serviceAccountId: number,
    data: ElectricityServiceDetails,
    onSuccess: () => void,
  ) => void;
}
interface PropsFromRoute {
  history: History;
}
interface PropsFromState {
  leaveList: Leaves[];
  service: ElectricityServiceDetails;
  serviceAccountId: number;
  plan: PlanState;
}

type OwnProps = PropsFromDispatch &
  PropsFromState &
  PropsFromRoute &
  RouteComponentProps<{ propertyId: string; serviceSummary: string }>;
class DateConfirmation extends React.Component<OwnProps, OwnState> {
  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      connectionDate: '',
      moving: false,
    };
  }
  public componentDidMount() {
    this.props.getLeaveList(new Date().getFullYear());
  }

  public render() {
    const { service, leaveList } = this.props;
    const serviceDetails = service && service.serviceDetails;
    if (leaveList.length !== 0) {
      return (
        <DateConfirmationComponent
          serviceDetails={serviceDetails}
          handleIAlreadyLiveHere={this.handleIAlreadyLiveHere}
          handleNext={this.handleNext}
          leaveList={leaveList}
          state={(service.serviceDetails.property as PropertyDetails).state}
        />
      );
    } else {
      return <div />;
    }
  }
  private handleIAlreadyLiveHere = (connectionDate: string | Date) => {
    this.setState({ connectionDate, moving: false });
    this.handlePlanSubmit(connectionDate, false);
  };

  private handleNext = (connectionDate: string | Date) => {
    this.setState({ connectionDate, moving: true });
    this.handlePlanSubmit(connectionDate, true);
  };

  private handlePlanSubmit = (connectionDate: string | Date, moving: boolean) => {
    const { plan, serviceAccountId } = this.props;
    const quoteValue = this.getQuoteValue(connectionDate)
      ? (plan.planDetails as PlanDetails).costBusinessHours
      : (plan.planDetails as PlanDetails).costAfterHours;
    const { propertyId } = this.props.match.params;
    const planDetails = plan.planDetails as PlanDetails;
    const connectionFee =
      planDetails.connectionFee + this.getConnectionFee(planDetails, connectionDate);
    const data: ElectricityServiceDetails = createPostPlanObject(
      plan,
      moving,
      quoteValue,
      uuidv4(),
      connectionFee,
    );
    this.props.postPlanDetails(
      parseInt(propertyId, 10),
      serviceAccountId,
      data,
      this.handleConnectionDateSubmit,
    );
  };

  private getConnectionFee = (planDetails: PlanDetails, connectionDate: string | Date): number => {
    const tomorrowDate = new Date();
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    return isSameDay(connectionDate as Date, tomorrowDate)
      ? parseFloat(planDetails.costAfterHours)
      : parseFloat(planDetails.costBusinessHours);
  };

  private handleConnectionDateSubmit = () => {
    const { connectionDate, moving } = this.state;
    const { serviceAccountId } = this.props;
    const { propertyId } = this.props.match.params;
    const formattedDate = format(connectionDate as Date, DATE_FORMAT);
    const data: ElectricityServiceDetails = createConnectionDateObject(formattedDate, moving);
    this.props.postConnectionDate(
      parseInt(propertyId, 10),
      serviceAccountId,
      data,
      this.handleConnectionDateSuccess,
    );
  };

  private handleConnectionDateSuccess = (service: ElectricityServiceDetails) => {
    const { propertyId, serviceSummary } = this.props.match.params;
    const { serviceDetails } = service;
    if ((serviceDetails.personalDetailsStep as StepDetails).completed) {
      BackHelper.saveCount(-1);
      this.props.history.push(
        routes.service.termsAndConditions.new(`${propertyId}`, serviceSummary),
      );
    } else {
      BackHelper.saveCount(-1);
      this.props.history.push(routes.service.personalDetails.showDetails(`${propertyId}`));
    }
  };
  private getQuoteValue = (connectionDate: string | Date): boolean => {
    const formattedSelectedDate = format(connectionDate as Date, DATE_FORMAT);
    const formattedCurrentDate = format(new Date(), DATE_FORMAT);
    if (formattedSelectedDate === formattedCurrentDate) {
      return new Date().getHours() < hourValue;
    } else {
      return false;
    }
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    leaveList: state.leaveList.leave,
    service: state.electricityService.service as ElectricityServiceDetails,
    serviceAccountId: (
      (state.electricityService.service as ElectricityServiceDetails)
        .serviceAccount as ServiceAccount
    ).id as number,
    plan: state.plan,
  };
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>): PropsFromDispatch {
  return {
    saveConnectionDate: (date: string | Date, moving: boolean) => {
      const data = { connectionDate: date, moving };
      dispatch(ConnectionDateActions.saveConnectionDate(data));
    },
    getLeaveList: (year: number) => {
      dispatch(LeaveListActions.getLeaveListStart(year));
    },
    postPlanDetails: (
      propertyId: number,
      serviceAccountId: number,
      data: ElectricityServiceDetails,
      onSuccess: () => void,
    ) =>
      dispatch(
        ElectricityServiceActions.postElectricityServiceStart({
          propertyId,
          serviceAccountId,
          data,
          onSuccess: () => onSuccess(),
        }),
      ),
    postConnectionDate: (
      propertyId: number,
      serviceAccountId: number,
      data: ElectricityServiceDetails,
      onSuccess: (service: ElectricityServiceDetails) => void,
    ) => {
      dispatch(
        ElectricityServiceActions.postElectricityServiceStart({
          propertyId,
          serviceAccountId,
          data,
          onSuccess: (service) => {
            onSuccess(service as ElectricityServiceDetails);
          },
        }),
      );
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DateConfirmation);

export const base = 'Sorted';
export const promotionSuffix = 25;
export const rentOptOut = `${base} Rent-opt-out`;
export const updateApp = `${base} Update-App`;
export const capitalizeFirstCharacter = (value: string) => {
  return `${value.charAt(0)}${value.slice(1).toLowerCase()}`;
};
export const serviceTag = (service: string, status?: string): string =>
  `${base} ${capitalizeFirstCharacter(service)} ${status ? status : ''}`.trim();

export const promotionCode = (agencyCode: string): string => `${agencyCode}${promotionSuffix}`;
export const SIZES = {
  sm: 560,
  md: 780,
  lg: 1024,
  lgMax: 1366,
};

export const INTERCOM_APP_ID = 'bp5lzkb6';

export const INTERCOM_SWITCH_APP_ID = 'jec424x9';

export const intercomChatScript = `
window.intercomSettings = {
  app_id: "bp5lzkb6"
};    
(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/bp5lzkb6';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
`;

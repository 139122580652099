/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useState } from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { styles, AddPropertyStyles } from './AddPropertyStyles';
import {
  OnboardingPropertyDetails,
  SetOnboardingPropertyDetails,
} from '../../../../models/property/Property.data';
import { History } from 'history';
import { AddressPicker } from '../../../../component/addressPicker/AddressPicker';
import { AddressValue } from '../../../../component/addressPicker/AddressValue.data';
import { GooglePlacesHelper } from '../../../../component/addressPicker/GooglePlacesHelper';

interface AddPropertyProps extends AddPropertyStyles {
  history: History;
  setPropertyDetails: (data: SetOnboardingPropertyDetails) => void;
  goToNextPage: () => void;
}

const AddProperty: FC<AddPropertyProps> = ({
  classes,
  history,
  setPropertyDetails,
  goToNextPage,
}) => {
  const [showText, setShowText] = useState<boolean>(false);
  const [addressValue, setAddressValue] = useState<any>(undefined);

  return (
    <div>
      <AddressPicker
        id="propertyAddressPicker"
        value={addressValue}
        disabled={false}
        placeholder={'Enter your address'}
        onKeyPress={(text: string) => {
          setShowText(!!text.length);
        }}
        handleAddressChanged={(value: AddressValue) => {
          setShowText(false);
          setPropertyDetails({
            field: 'address',
            value: GooglePlacesHelper.getAddressFeildsFromGoogleAPI(value),
          });
          setAddressValue(value);
          goToNextPage();
        }}
      />
      <Typography
        onClick={() => {
          setPropertyDetails({
            field: 'address',
            value: {
              state: '',
              country: 'Australia',
              postcode: '',
              streetName: '',
              streetNumber: '',
              streetType: '',
              unitNumber: '',
              suburb: '',
            },
          });
          history.push(`/onboarding/propertyOnboard/3`);
        }}
        className={`${showText ? classes.cantFindText : classes.hide}`}
      >
        Cant find your address?
      </Typography>
    </div>
  );
};

export default withStyles(styles)(AddProperty);

import * as React from 'react';
import EmptyAddressListComponent from '../../../../component/services/broadbandService/addressList/EmptyAddressList';
import { AnyAction } from 'redux';
import { ApplicationState } from '../../../../store/RootReducers';
import { connect } from 'react-redux';
import { ElectricityServiceActions } from '../../../../store/actions/ElectricityServiceActions';
import {
  ElectricityServiceDetails,
  PropertyDetails,
} from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { RouteComponentProps } from 'react-router-dom';
import { History } from 'history';
import { PropertyObject } from '../../../../objectFactory/PropertyObjectFactory';
import { routes } from '../../../../Routes';
import { AddressListLabels } from '../../../../component/services/electricityService/addressForm/AddressFormConstants';
import { BackHelper } from '../../../../helper/BackHelper';

interface PropsFromState {
  property: PropertyDetails;
}

interface PropsFromRoute {
  history: History;
}
interface PropsFromDispatch {
  postProperty: (
    propertyId: number,
    data: ElectricityServiceDetails,
    onSuccess: () => void,
  ) => void;
}
type OwnProps = PropsFromState &
  PropsFromDispatch &
  PropsFromRoute &
  RouteComponentProps<{
    propertyId: string;
    serviceName: string;
    providerName: string;
    lookupRequired: string;
  }>;

class EmptyAddressList extends React.Component<OwnProps, {}> {
  public render() {
    return <EmptyAddressListComponent handleSubmit={this.handleSubmit} />;
  }
  private handleSubmit = () => {
    const { propertyId } = this.props.match.params;
    const { property } = this.props;
    const propetyObject = new PropertyObject({});
    const addressData = propetyObject.createPropertyDetailsObject(
      property,
      AddressListLabels.stepName,
      null,
      null,
      null,
    );
    this.props.postProperty(parseInt(propertyId, 10), addressData, this.onSucess);
  };
  private onSucess = () => {
    const { propertyId } = this.props.match.params;
    BackHelper.saveCount(-1);
    this.props.history.push(routes.service.plan.selection(propertyId));
  };
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>): PropsFromDispatch {
  return {
    postProperty: (propertyId: number, data: ElectricityServiceDetails, onSuccess: () => void) => {
      dispatch(
        ElectricityServiceActions.postElectricityServiceStart({
          propertyId,
          serviceAccountId: null,
          data,
          onSuccess,
        }),
      );
    },
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    property: state.electricityService.property as PropertyDetails,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmptyAddressList);

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { withStyles, Typography } from '@material-ui/core';
import React, { FC, useState } from 'react';
import {
  PlanOption,
  Options,
  FeatureDetails,
} from '../../../../../../services/commonServices/CommonPlanSelectionInterface';
import { styles, SelectOptionsStyles } from '../../../SelectOptionsStyles';
import ReactHTMLParser from 'react-html-parser';
import { get } from 'lodash';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { getDefaultValue } from '../../../SelectOptionsUtils';

interface PlanOptionsProps extends SelectOptionsStyles {
  option: PlanOption;
  handlePlanOptionChange: (supplierOption: PlanOption, selection: Options, value?: string) => void;
  supplierSelectionAns: Map<string, FeatureDetails> | {};
}

const PlanOptions: FC<PlanOptionsProps> = ({
  classes,
  option,
  supplierSelectionAns,
  handlePlanOptionChange,
}) => {
  const toggleValue = getDefaultValue(supplierSelectionAns, option.Group);
  const selectedOption =
    option.Options &&
    option.Options.length > 0 &&
    option.Options.find((optionData) => optionData.Label === toggleValue);
  return (
    <div style={{ marginLeft: 16, marginRight: 16 }}>
      <Typography variant="h6">{get(option, 'Heading', '')}</Typography>
      <div className={classes.seperator} />
      <Typography variant="h1" className={`${classes.headerText2} ${classes.gtwLight}`}>
        {ReactHTMLParser(get(option, 'Description', ''))}
      </Typography>
      <Typography variant="h1" className={`${classes.headerText2} ${classes.gtwLight}`}>
        {ReactHTMLParser(get(option, 'OptionsDescription', ''))}
      </Typography>
      <ToggleButtonGroup className={classes.toggleContainer} value={toggleValue}>
        {option &&
          option.Options &&
          option.Options.map((selection: Options) => {
            return (
              <ToggleButton
                classes={{ selected: classes.toggleButtonActive }}
                value={
                  get(selection, 'ProductId', '')
                    ? get(selection, 'ProductId', '')
                    : get(selection, 'Label', '')
                }
                className={
                  toggleValue === get(selection, 'Label', '')
                    ? classes.toggleButtonActive
                    : classes.toggleButton
                }
                onClick={() => {
                  handlePlanOptionChange(option, selection, get(selection, 'Label', ''));
                }}
              >
                {get(selection, 'Label', '')}
              </ToggleButton>
            );
          })}
      </ToggleButtonGroup>
      {selectedOption && selectedOption.HelpText ? (
        <Typography
          variant="h1"
          className={`${classes.headerText2} ${classes.gtwLight} ${classes.footer}`}
        >
          {ReactHTMLParser(selectedOption.HelpText)}
        </Typography>
      ) : null}
      {selectedOption && selectedOption.PricingHelpText ? (
        <Typography
          variant="h1"
          className={`${classes.headerText2} ${classes.gtwLight} ${classes.footer}`}
        >
          {ReactHTMLParser(selectedOption.PricingHelpText)}
        </Typography>
      ) : null}
    </div>
  );
};

export default withStyles(styles)(PlanOptions);

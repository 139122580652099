import { WithStyles, createStyles } from '@material-ui/core';

export const styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 124,
    padding: 16,
    height: '100vh',
  },
  cardNumberInputContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 36,
  },
  expiryInput: {
    marginRight: 10,
  },
  cvvInput: {
    marginLeft: 10,
  },
});

export interface CardDetailsStyles extends WithStyles<typeof styles> {}

/* eslint-disable default-case */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  Snackbar,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  PaymentActions,
  SaveSelectedPaymentMethodPayload,
  PaymentErrorPayload,
} from '../../../../store/actions/PaymentActions';
import { Dispatch } from 'redux';
import value from 'card-validator';
import { PostPaymentUUIDRequest } from '../../../../services/payment/postPaymentUUID/PostPaymentUUIDRequest.data';
import { ApplicationState } from '../../../../store/RootReducers';
import { SpinnerActions } from '../../../../store/actions/SpinnerActions';
import { CreditCardRequest } from '../../../../models/payment/PaymentRequest.data';
import { PostPaymentToken } from '../../../../services/payment/postPaymentToken/PostPyamentToken.data';
import { routes } from '../../../../Routes';
import { GetPaymentMethodsResponse } from '../../../../services/payment/getPaymentMethods/GetPaymentMethods.data';
import {
  ElectricityServiceDetails,
  ServiceAccount,
} from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { BroadbandServiceDetails } from '../../../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import { ElectricityServiceActions } from '../../../../store/actions/ElectricityServiceActions';
import { BroadbandServiceActions } from '../../../../store/actions/BroadbandServiceActions';
import { ServiceType } from '../../../services/ServiceConstants';
import { createPaymentRequestObject } from '../../../../helper/PaymentHelper';
import { EditPaymentRequset } from '../../../../services/editPayment/postEditPayment/PostEditPayment.data';
import { EditPaymentActions } from '../../../../store/actions/EditPaymentActions';
import { createEditPaymentObject, maskCardDetails, MaskDetails } from '../AddPaymentUtils';
import { BackHelper } from '../../../../helper/BackHelper';
import { PlanCommonSummaryActions } from '../../../../store/actions/CommonSummaryActions';
import { styles, AddCardStyles } from './AddCardStyles';
import { withRouter, RouteComponentProps } from 'react-router';
import { CommonPaymentSummary, PaymentSummary } from '../addBank/AddBankConstants';
import {
  cardHolderNameMaxLength,
  cardHolderNameRegex,
  cardNumberLength,
  cardNumberPattern,
  CardType,
  cardTypeNotSupportedError,
  CARD_HOLDER_NAME,
  CARD_NUMBER,
  CVV,
  EXPIRY,
  expiryDateError,
  expiryDateLength,
  expiryDatePattern,
  InitialCardValues,
  invalidCardError,
  LABELS,
  MatchParams,
  nameFormatError,
  nameMaxLengthError,
  requiredFieldError,
  SECURE_CONNECTION,
  initialCardValues,
} from './AddCardConstants';
import { handlePaymentSuccess, renderCardIcon } from './AddCardUtils';
import CustomInput from '../../../../component/customInput/CustomInput';
import { dateValidator } from '../../../../helper/DateValidator';
import lockIcon from '../../../../assets/lock-24-px.svg';
import calendarIcon from '../../../../assets/outlined.svg';
import errorIcon from '../../../../assets/error-outline-24-px.svg';
import NavBar from '../../../../component/navBar/NavBar';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { CardHepler } from '../../../../helper/CardHelper';
import MainContainer from '../../../../component/mainContainer/MainContainer';

interface AddCardProps
  extends AddCardStyles,
    RouteComponentProps<MatchParams>,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const AddCard: FC<AddCardProps> = ({
  classes,
  history,
  match,
  paymentMethodId,
  paymentErrorState,
  electricityServiceId,
  broadbandServiceAccountId,
  serviceAccount,
  providerId,
  saveSelectedPaymentMethod,
  getPaymentAuthKey,
  postCommonData,
  postElectricityPaymentDetails,
  postBroadbandServiceDetails,
  postEditPayment,
}) => {
  const cardExpiryValidator = CustomInput(expiryDatePattern);
  const cardNumberValidator = CustomInput(cardNumberPattern);
  const [cardType, setCardType] = useState<string>('');
  const [cardError, setCardError] = useState<boolean>(false);
  const [cardErrorValue, setCardErrorValue] = useState<string>('');
  const [cvvLength, setCvvLength] = useState<number>(3);
  const [expiryError, setExpiryError] = useState<boolean>(false);
  const [cardTypeValidation, setCardTypeValidation] = useState<boolean>(true);
  const [maskedCardNumber, setMaskedCardNumber] = useState<MaskDetails>({
    paymentDetails: '',
    logo: '',
  });

  useEffect(() => {
    const { propertyId, paymentRefId, serviceAccountId } = match.params;
    BackHelper.saveParams({ propertyId, paymentRefId, serviceAccountId });
  }, []);

  useEffect(() => {
    saveSelectedPaymentMethod({
      paymentMethodId,
      maskedDetails: maskedCardNumber,
    });
  }, [paymentMethodId]);

  const handleSubmit = (paymentDetails: InitialCardValues) => {
    const expiryDate = paymentDetails.expiryDate.split('/');
    const paymentRequest: CreditCardRequest = {
      type: 'CREDITCARD',
      cardHolderName: paymentDetails.cardHolderName,
      cardNumber: paymentDetails.cardNumber,
      email: '',
      expiryMonth: expiryDate[0],
      expiryYear: expiryDate[1],
    };
    getPaymentAuthKey(paymentRequest, handlePayment);
  };

  const handleEditPayment = (payment: GetPaymentMethodsResponse) => {
    const { serviceAccountId } = match.params;
    const requestData = createEditPaymentObject(parseInt(serviceAccountId, 10), payment.refId);
    postEditPayment(requestData, () => handlePaymentSuccess(history, match.params));
  };

  const handleElectricityServicePayment = (payment: GetPaymentMethodsResponse) => {
    const { propertyId, serviceType } = match.params;
    const data: ElectricityServiceDetails = createPaymentRequestObject(payment.refId);
    const paymentComplete: PaymentSummary = {
      paymentRefId: payment.refId,
    };
    const commonPaymentSummary: CommonPaymentSummary = {
      serviceDetails: paymentComplete,
      step: 'PAYMENT',
      propertyId,
      serviceType,
      providerId,
      serviceAccountId: serviceAccount as string,
    };
    electricityServiceId
      ? postElectricityPaymentDetails(parseInt(propertyId, 10), electricityServiceId, data, () =>
          handlePaymentSuccess(history, match.params),
        )
      : postCommonData(commonPaymentSummary, () => handlePaymentSuccess(history, match.params));
  };

  const handleBroadbandServicePayment = (payment: GetPaymentMethodsResponse) => {
    const { propertyId } = match.params;
    const data: BroadbandServiceDetails = createPaymentRequestObject(payment.refId);
    postBroadbandServiceDetails(
      propertyId,
      (broadbandServiceAccountId as number).toString(),
      data,
      () => handlePaymentSuccess(history, match.params),
    );
  };

  const handleCardNumber = (cardValue: string) => {
    if (cardValue.length < 4) {
      setCardType('');
      setCardTypeValidation(true);
    } else if (cardType === '' && cardValue.length >= 4) {
      const { card } = value.number(cardValue);
      const type = card && (card as any).type;
      const cvvLength = card && card.code.size;
      const cardTypeValidation = type === CardType.Visa || type === CardType.MasterCard;
      setCardType(type);
      setCvvLength(cvvLength as number);
      setCardTypeValidation(cardTypeValidation);
    }
    const valid = value.number(cardValue.replace(/\s+/g, '')).isValid;
    switch (true) {
      case cardValue.length === cardNumberLength && !valid:
        setCardError(true);
        setCardErrorValue(invalidCardError);
        break;
      case !cardTypeValidation:
        setCardError(true);
        setCardErrorValue(`${cardType ? cardType : ''} ${cardTypeNotSupportedError}`);
        break;
      case cardError && cardTypeValidation:
        setCardError(false);
        setCardErrorValue('');
        break;
    }
  };

  const expiryDateValidation = (date: string) => {
    if (date.length === expiryDateLength) {
      setExpiryError(dateValidator(date, 'paymentCard'));
    } else if (expiryError) {
      setExpiryError(false);
    }
  };

  const saveNewCard = (data: InitialCardValues) => {
    handleSubmit(data);
    const maskedCardNumber = maskCardDetails(data.cardNumber.slice(-4), cardType);
    setMaskedCardNumber(maskedCardNumber);
    saveSelectedPaymentMethod({
      paymentMethodId: '1',
      maskedDetails: maskedCardNumber,
    });
  };

  const handlePayment = (payment: GetPaymentMethodsResponse) => {
    const { propertyId, serviceType } = match.params;
    switch (serviceType) {
      case ServiceType.Rent:
        history.push(routes.summary.new(propertyId, serviceType));
        break;
      case ServiceType.Electricity:
        handleElectricityServicePayment(payment);
        break;
      case ServiceType.ElectricityCaps:
        handleElectricityServicePayment(payment);
        break;
      case ServiceType.Broadband:
        handleBroadbandServicePayment(payment);
        break;
      case ServiceType.EditRent:
        handleEditPayment(payment);
        break;
      case ServiceType.EditElectricity:
        handleEditPayment(payment);
        break;
      case ServiceType.EditBroadband:
        handleEditPayment(payment);
        break;
      case ServiceType.Wallet:
        history.push(routes.wallet.paymentOptions(serviceType));
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.root}>
      <NavBar subTitle={LABELS.NAV_TITLE} subText={LABELS.NAV_SUBTITLE} />
      <div className={classes.body}>
        <MainContainer>
          <Formik
            initialValues={initialCardValues}
            onSubmit={(values) => saveNewCard(values)}
            validationSchema={Yup.object().shape({
              cardHolderName: Yup.string()
                .max(cardHolderNameMaxLength, nameMaxLengthError)
                .matches(cardHolderNameRegex, nameFormatError)
                .required(requiredFieldError),
              cardNumber: Yup.string().min(19).required(),
              expiryDate: Yup.string().min(5).required(),
              cvv: Yup.string().min(cvvLength).required(),
            })}
            render={({ values, handleSubmit, setFieldValue, isValid, errors }) => (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FormControl error={!!errors.cardHolderName}>
                  <InputLabel>{CARD_HOLDER_NAME}</InputLabel>
                  <Input
                    name="cardHolderName"
                    onChange={(event) => setFieldValue('cardHolderName', event.target.value)}
                    value={values.cardHolderName}
                  />
                  {!!errors.cardHolderName && (
                    <FormHelperText>{errors.cardHolderName}</FormHelperText>
                  )}
                </FormControl>
                <FormControl className={classes.cardNumberInputStyle} error={cardError}>
                  <InputLabel>{CARD_NUMBER}</InputLabel>
                  <Input
                    name="cardNumber"
                    type="text"
                    inputComponent={cardNumberValidator as any}
                    onChange={(event) => {
                      setFieldValue('cardNumber', event.target.value);
                      handleCardNumber(event.target.value);
                    }}
                    value={values.cardNumber}
                    endAdornment={
                      <InputAdornment position="end">
                        <img src={cardError ? errorIcon : renderCardIcon(cardType)} />
                      </InputAdornment>
                    }
                  />
                  {cardErrorValue && <FormHelperText>{cardErrorValue}</FormHelperText>}
                </FormControl>
                <div className={classes.inputBoxStyle}>
                  <FormControl className={classes.expiryInputStyle} error={expiryError}>
                    <InputLabel>{EXPIRY}</InputLabel>
                    <Input
                      name="expiryDate"
                      type="text"
                      placeholder="MM/YY"
                      inputComponent={cardExpiryValidator as any}
                      onChange={(event) => {
                        expiryDateValidation(event.target.value);
                        setFieldValue('expiryDate', event.target.value);
                      }}
                      value={values.expiryDate}
                      endAdornment={
                        <InputAdornment position="end">
                          <img src={calendarIcon} />
                        </InputAdornment>
                      }
                    />
                    {expiryError && <FormHelperText>{expiryDateError}</FormHelperText>}
                  </FormControl>
                  <FormControl className={classes.cvvInputStyle}>
                    <InputLabel>{CVV}</InputLabel>
                    <Input
                      name="cvv"
                      type="password"
                      onChange={(event) =>
                        setFieldValue('cvv', CardHepler.formatCvvNumber(event.target.value))
                      }
                      inputProps={{
                        maxLength: 3,
                      }}
                      value={values.cvv}
                      endAdornment={
                        <InputAdornment position="end">
                          <img src={lockIcon} />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
                {paymentErrorState && (
                  <Snackbar message={`${paymentErrorState.error}, try again`} />
                )}
                <div className={classes.secureBoxStyle}>
                  <img src={lockIcon} className={classes.secureLockStyle} />
                  <Typography variant="subtitle1">{SECURE_CONNECTION}</Typography>
                </div>

                <div className={classes.footerButtonsContainer}>
                  <div
                    className={`${
                      cardError || expiryError || !isValid ? classes.disabled : classes.footerButton
                    }`}
                    onClick={() => {
                      if (!(cardError || expiryError || !isValid)) {
                        handleSubmit();
                      }
                    }}
                  >
                    Save
                  </div>
                  <div className={classes.footerButtonSecondary} onClick={() => history.goBack()}>
                    Back
                  </div>
                </div>
              </div>
            )}
          />
        </MainContainer>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  paymentMethodId: state.payment.selectedPaymentMethod.paymentMethodId,
  paymentErrorState: state.payment.error,
  serviceAccount: state.commonSummary.commonServicePlans.id
    ? state.commonSummary.commonServicePlans.id
    : state.commonSummary.commonServicePlans.serviceAccountResponse
    ? state.commonSummary.commonServicePlans.serviceAccountResponse.id
    : undefined,
  providerId:
    state.commonSummary && state.commonSummary.commonServicePlansSuccess
      ? state.commonSummary.commonServicePlansSuccess.provider
        ? state.commonSummary.commonServicePlansSuccess.provider
        : state.commonSummary.commonServicePlansSuccess &&
          state.commonSummary.commonServicePlansSuccess.serviceAccountResponse
        ? state.commonSummary.commonServicePlansSuccess.serviceAccountResponse.provider
        : undefined
      : undefined,
  electricityServiceId:
    state.electricityService &&
    state.electricityService.service &&
    (state.electricityService.service.serviceAccount as ServiceAccount) &&
    ((state.electricityService.service.serviceAccount as ServiceAccount).id as number),
  broadbandServiceAccountId:
    state.broadbandService &&
    state.broadbandService.service &&
    ((state.broadbandService.service.serviceAccount as ServiceAccount).id as number),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveSelectedPaymentMethod: (data: SaveSelectedPaymentMethodPayload) => {
    dispatch(PaymentActions.saveSelectedPaymentMethod(data));
  },
  postCommonData: (serviceDetails: CommonPaymentSummary, onSuccess: () => void) =>
    dispatch(
      PlanCommonSummaryActions.postCommonSummaryData({
        data: serviceDetails,
        onSuccess,
      }),
    ),
  getPaymentMethods: (onSuccess: (response: GetPaymentMethodsResponse[]) => void) => {
    dispatch(
      PaymentActions.getPaymentMethodsStart({
        onSuccess,
      }),
    );
  },
  postPaymentUUID: (data: PostPaymentUUIDRequest) => {
    dispatch(PaymentActions.postPaymentMethodUUIDStart(data));
  },
  getPaymentAuthKey: (
    data: CreditCardRequest,
    handlePayment: (payment: GetPaymentMethodsResponse) => void,
  ) => {
    dispatch(
      PaymentActions.getPaymentAuthKeyStart({
        data,
        onSuccess: (token: string) => {
          dispatch(
            PaymentActions.postPaymentTokenStart({
              data: { resultKey: token },
              onSuccess: (payment: GetPaymentMethodsResponse) => {
                handlePayment(payment);
              },
            }),
          );
        },
        onError: (error: PaymentErrorPayload) => {
          dispatch(PaymentActions.getPaymentAuthKeyError(error));
          dispatch(SpinnerActions.hide());
        },
      }),
    );
  },
  postPaymentToken: (
    data: PostPaymentToken,
    handlePayment: (payment: GetPaymentMethodsResponse) => void,
  ) => {
    dispatch(
      PaymentActions.postPaymentTokenStart({
        data,
        onSuccess: (payment: GetPaymentMethodsResponse) => {
          handlePayment(payment);
        },
      }),
    );
  },
  postElectricityPaymentDetails: (
    propertyId: number,
    serviceAccountId: number,
    data: ElectricityServiceDetails,
    handlePaymentSuccess: () => void,
  ) => {
    dispatch(
      ElectricityServiceActions.postElectricityServiceStart({
        propertyId,
        serviceAccountId,
        data,
        onSuccess: () => {
          handlePaymentSuccess();
        },
      }),
    );
  },
  postBroadbandServiceDetails: (
    propertyId: string,
    serviceAccountId: string,
    data: BroadbandServiceDetails,
    handlePaymentSuccess: () => void,
  ) => {
    dispatch(
      BroadbandServiceActions.postBroadbandServiceStart({
        propertyId,
        serviceAccountId,
        data,
        onSuccess: () => {
          handlePaymentSuccess();
        },
      }),
    );
  },
  postEditPayment: (data: EditPaymentRequset, onSuccess: () => void) => {
    dispatch(EditPaymentActions.postEditPaymentStart({ data, onSuccess }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(AddCard)));

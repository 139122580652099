import {
  Options,
  ProductDetails,
} from '../containers/services/commonServices/CommonPlanSelectionInterface';

export default class PlanHelper {
  public static getProductName(
    productNames: string[],
    dataSize: string,
    planUsage: string,
    hasVoice: boolean,
  ) {
    let product = '';
    productNames.map((productName: string) => {
      if (
        !hasVoice &&
        productName.includes(dataSize) &&
        productName.includes(planUsage) &&
        !productName.includes('Voice')
      ) {
        product = productName;
      }
      if (
        hasVoice &&
        productName.includes(dataSize) &&
        productName.includes(planUsage) &&
        productName.includes('Voice')
      ) {
        product = productName;
      }
    });
    return product;
  }
  public static getProductDetails(productList: any, productName: string) {
    let product = {};
    productList.map((productDetail: any) => {
      if (
        productDetail.productDetails &&
        productDetail.productDetails.productName === productName
      ) {
        product = productDetail;
      }
    });
    return product;
  }
  public static getModemDetails(productList: any) {
    let product = null;
    productList.map((productDetail: any) => {
      if (productDetail.modem) {
        product = productDetail.modem;
      }
    });
    return product;
  }

  public static getFormattedProductDetails = (productDetail: ProductDetails | null) => {
    return {
      ...productDetail,
      cost: `${(productDetail!.monthlyCost as Options).BasePriceMonthly}`,
      monthlyCost: `${(productDetail!.monthlyCost as Options).BasePriceMonthly}`,
      dailyCost: `${(productDetail!.monthlyCost as Options).BasePriceDaily}`,
    };
  };
}

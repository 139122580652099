import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../Routes';
import SavedPaymentMethods from '../../containers/wallet/savedPaymentMethods/SavedPaymentMethods';
import BankDetails from '../../containers/wallet/bankDetails/BankDetails';
import CardDetails from '../../containers/wallet/cardDetails/CardDetails';

export default class PaymentOptionsLayout extends React.Component {
  public render() {
    return (
      <Switch>
        <Route
          path={routes.wallet.paymentOptions(':serviceType')}
          exact
          component={SavedPaymentMethods}
        />
        <Route path={routes.wallet.bankDetails(':serviceType')} exact component={BankDetails} />
        <Route path={routes.wallet.cardDetails(':serviceType')} exact component={CardDetails} />
      </Switch>
    );
  }
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { withStyles, Typography, Card } from '@material-ui/core';
import React, { FC } from 'react';
import ToggleButtons from '../../questionTypes/ToggleButtons/ToggleButtons';
import {
  FeatureDetails,
  QuotesV3,
  SupplierOption,
  SupplierOptions,
} from '../../../../../../services/commonServices/CommonPlanSelectionInterface';
import { styles, SelectOptionsStyles } from '../../../SelectOptionsStyles';
import { InitialConcessionCardValues } from '../../../../../../../models/checkout/Checkout';
import SingleSupplierOptions from '../../questionTypes/SingleSupplierOptions/SingleSupplierOptions';
import ConcessionCardForm from '../../questionTypes/ConcessionCardForm/ConcessionCardForm';
import { getDefaultValue } from '../../../SelectOptionsUtils';
import RadioButtonOptions from '../../questionTypes/RadioButtonOptions/RadioButtonOptions';
import FileUploads from '../../../../../../../component/fileUploads/FileUploads';
import { FileData } from '../../../../../../../models/files/files';

interface SupplierOptionsProps extends SelectOptionsStyles {
  setConcessionCard: (concessionCard: InitialConcessionCardValues | null) => void;
  option: SupplierOptions;
  handleMultipleOptionsChange: (
    supplierOption: SupplierOptions,
    selection: SupplierOption,
    value?: string | Date,
  ) => void;
  handleSingleOptionChange: (supplierOption: SupplierOptions, value?: string | Date) => void;
  supplierSelectionAns: Map<string, FeatureDetails> | {};
  publicHolidays?: string[];
}

const SupplierOptionsComponent: FC<SupplierOptionsProps> = ({
  classes,
  setConcessionCard,
  option,
  handleMultipleOptionsChange,
  handleSingleOptionChange,
  supplierSelectionAns,
  publicHolidays,
}) => {
  return (
    <div>
      {option.Group === 'ConcessionDetailsAgreement' ? (
        <ConcessionCardForm setConcessionCard={setConcessionCard} />
      ) : option.ComponentId === 'MediaFileInput' ? (
        <Card elevation={2} className={`${classes.detailCard} ${classes.planCardContainer}`}>
          <Typography variant="h6">{option.Heading}</Typography>
          <div className={classes.seperator} />
          <Typography variant="h1" className={`${classes.headerText2} ${classes.gtwLight}`}>
            {option.Description}
          </Typography>
          <FileUploads
            value={
              getDefaultValue(supplierSelectionAns, option.Group)
                ? (JSON.parse(getDefaultValue(supplierSelectionAns, option.Group)) as FileData[])
                : []
            }
            id="123" //This needs to be unique but I cannot find anything in redux
            onSuccess={(files: FileData[]) => {
              const oldData = getDefaultValue(supplierSelectionAns, option.Group)
                ? (JSON.parse(getDefaultValue(supplierSelectionAns, option.Group)) as FileData[])
                : [];
              const newData = oldData ? oldData.concat(files) : files;

              handleSingleOptionChange(option, JSON.stringify(newData)); // Are we joining all the file links into one string for one answer?
            }}
            multiple
            onDelete={(file: FileData) => {
              const oldData = getDefaultValue(supplierSelectionAns, option.Group)
                ? (JSON.parse(getDefaultValue(supplierSelectionAns, option.Group)) as FileData[])
                : [];
              const newData = oldData.filter((oldFile: FileData) => oldFile !== file);
              handleSingleOptionChange(option, JSON.stringify(newData)); // Are we joining all the file links into one string for one answer?
            }}
            acceptedTypes=".jpg, .jpeg, .pdf, .png"
          />
          <Typography className={classes.infoText}>{option.ComponentText}</Typography>
        </Card>
      ) : option.ComponentId === 'RadioCardSingleSelect' ? (
        <div style={{ marginLeft: 16, marginRight: 16 }}>
          <RadioButtonOptions
            option={option}
            handleMultipleOptionsChange={handleMultipleOptionsChange}
          />
        </div>
      ) : option.Options.length > 1 ? (
        <div style={{ marginLeft: 16, marginRight: 16 }}>
          <ToggleButtons
            activeIndex={0}
            publicHolidays={publicHolidays}
            classes={classes}
            option={option}
            getValue={() => ''}
            callBackHandler={(selection, date?: Date) =>
              handleMultipleOptionsChange(option, selection as SupplierOption, date)
            }
            defaultValue={getDefaultValue(supplierSelectionAns, option.Group)}
          />
        </div>
      ) : (
        <SingleSupplierOptions
          option={option.Options && option.Options.length > 0 ? option.Options[0].Option : option}
          handleSingleOptionChange={handleSingleOptionChange}
          supplierSelectionAns={supplierSelectionAns}
          publicHolidays={publicHolidays}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(SupplierOptionsComponent);

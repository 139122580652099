import { color } from '../../../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';
import { AGENCIES, APP } from '../../../../helper/AppNameHelper';

export const styles = createStyles({
  sectionContainer: {
    backgroundColor: color.light,
    padding: 15,
  },
  titleContainer: {
    display: 'flex',
  },
  title: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: 20,
  },
  price: {
    fontFamily: 'GT Walsheim',
    color: color.lightBlue,
    fontSize: 20,
    marginLeft: 6,
  },
  subtitle: {
    fontFamily: 'GT Walsheim',
    color: color.black38,
    fontSize: 14,
    marginTop: 6,
    marginBottom: 12,
  },
  line: {
    marginTop: 8,
    borderBottom: `${color.borderColor} 2px solid`,
    marginBottom: 8,
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: color.light,
    padding: '12px',
    paddingLeft: 16,
    alignItems: 'center',
    borderRadius: 8,
    minHeight: 40,
    border: `${color.borderColor} 2px solid`,
    '& > div': {
      flex: 1,
    },
  },
  addNewPaymentFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15,
  },
  secureBoxStyle: {
    height: 56,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  secureLockStyle: {
    marginRight: 16,
  },
  secureText: {
    fontFamily: 'GT Walsheim',
    fontWeight: 500,
    color: color.black38,
    fontSize: '14px',
  },
  addNewOptionSave: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: color.lightBlue,
    padding: '10px 14px',
    alignItems: 'center',
    borderRadius: 8,
    border: `${color.lightBlue} 2px solid`,
    fontFamily: 'GT Walsheim',
    fontWeight: 500,
    color: color.light,
    fontSize: '14px',
    minWidth: '60px',
  },
  disabledButton: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: color.disabled,
    padding: '10px 14px',
    alignItems: 'center',
    borderRadius: 8,
    border: `${color.disabled} 2px solid`,
    fontFamily: 'GT Walsheim',
    fontWeight: 500,
    color: color.secondary400,
    fontSize: '14px',
    minWidth: '60px',
  },
  successContainer: {
    display: 'flex',
    backgroundColor: color.fadedBlue,
    padding: '12px',
    paddingLeft: 16,
    alignItems: 'center',
    borderRadius: 8,
    minHeight: 40,
    border: `${color.fadedBlue} 2px solid`,
  },
  successIcon: {
    width: 22,
    height: 22,
    marginRight: 12,
  },
  successText: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: 16,
  },
  errorText: {
    fontFamily: 'GT Walsheim',
    color: color.danger,
    fontSize: 16,
  },
  costBreakdown: {
    marginBottom: 12,
    backgroundColor: color.secondary,
    padding: '15px 15px 0px 15px',
  },
  breakdownItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 15,
  },
  itemName: {
    fontFamily: 'GT Walsheim',
    fontSize: '13px',
    color: color.black38,
  },
  itemCost: {
    fontFamily: 'GT Walsheim',
    fontSize: '13px',
    color: color.black87,
  },
});

export interface StripeSectionStyles extends WithStyles<typeof styles> {}

import React, { FC, useState } from 'react';
import {
  Typography,
  Card,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

import { PlanSelectionStyles } from './PlanSelectionStyles';
import {
  SupplierOption,
  SupplierOptions,
} from '../../../containers/services/commonServices/CommonPlanSelectionInterface';

interface Props {
  classes: any;
  callBackHandler: (enteredValue: string) => void;
  optionValue: SupplierOptions;
  defaultValue?: string;
}

const RadioButtonComponent: FC<Props & PlanSelectionStyles> = ({
  classes,
  callBackHandler,
  optionValue,
  defaultValue,
}) => {
  const [radioButtonValue, setRadioButtonValue] = useState(defaultValue || '');
  return (
    <Card elevation={2} className={`${classes.detailCard} ${classes.planCardContainer}`}>
      <Typography variant="h6">{optionValue.Heading}</Typography>
      <div className={classes.seperator} />
      <Typography variant="h1" className={`${classes.headerText2} ${classes.gtwLight}`}>
        {optionValue.Description}
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={radioButtonValue}
          onChange={(event) => {
            callBackHandler(event.target.value);
            setRadioButtonValue(event.target.value);
          }}
        >
          {optionValue.ComponentOptions.map((value) => {
            return <FormControlLabel value={value.Value} control={<Radio />} label={value.Value} />;
          })}
        </RadioGroup>
      </FormControl>
    </Card>
  );
};

export default RadioButtonComponent;

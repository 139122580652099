import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/RootReducers';
import { withStyles } from '@material-ui/core';
import './Spinner.css';
import LOADER_IMAGE from '../../assets/loader-dark.png';
import NAX_LOADER from '../../assets/na-loader.png';
import SWITCH_LOADER from '../../assets/switch-loader.png';
import { color } from '../../theme/Theme';
import { AGENCIES, APP } from '../../helper/AppNameHelper';

interface LoadingIndicatorStyles {
  readonly overlay: any;
}
enum DEVICE_TYPE {
  Android,
  IOS,
}

const styles: LoadingIndicatorStyles = {
  overlay: {
    background: color.loaderBackground,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    zIndex: 99999,
    padding: '1em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

interface LoadingIndicatorProps {
  readonly isLoading: boolean;
  readonly classes: any;
}

interface ComponentProps {
  readonly overrideLoading?: boolean;
}

export const Spinner = (props: LoadingIndicatorProps & ComponentProps) => {
  const userAgent = navigator.userAgent || navigator.vendor;
  const IOSdevice = /iPhone|iPad|iPod/i.test(userAgent);
  return (
    <div>
      {props.isLoading || props.overrideLoading ? (
        <div className={props.classes.overlay}>
          <img
            className="image"
            style={{
              width: '60px',
            }}
            src={
              APP === AGENCIES.SORTED || APP === AGENCIES.TA
                ? LOADER_IMAGE
                : APP === AGENCIES.SWITCH
                ? SWITCH_LOADER
                : NAX_LOADER
            }
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.spinner.isLoading,
});

const SpinnerComponent = connect(mapStateToProps)(withStyles(styles)(Spinner as any));

export default SpinnerComponent;

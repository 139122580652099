import { GooglePlacesAutoComplete } from './GooglePlacesAutoComplete.data';
import { TypeHelper } from './TypeHelper';
import { Logging } from '../../helper/Logging';
import { StreetTypeList } from '../../containers/propertyOnboarding/PropertyOnboardingConstants';
import { AddressValue } from './AddressValue.data';

export type GooglePlaceResult = google.maps.places.PlaceResult;

export class GooglePlacesHelper {
  // google is not avaiable when running the tests in jest
  public static createGooglePlacesAutoComplete(
    input: HTMLInputElement,
    listener: (value: AddressValue) => void,
  ): GooglePlacesAutoComplete {
    let ctr: google.maps.places.Autocomplete | null = null;

    if (typeof google !== 'undefined') {
      ctr = new google.maps.places.Autocomplete(input, {});
      ctr.setComponentRestrictions({ country: ['au'] });
      ctr.addListener('place_changed', () =>
        listener({ inputValue: input.value, place: ctr!.getPlace() }),
      );
    } else {
      // Mock implementation for testing
      input.addEventListener('change', (e) => listener({ inputValue: input.value, place: null }));
    }

    return { input, listener, autoComplete: ctr };
  }

  /// <summary>
  /// Fix address according to this article.
  /// Google Places Autocomplete suggestions with Unit No/Subpremise is not coming in the response array
  /// https://stackoverflow.com/questions/17936689/google-places-autocomplete-suggestions-with-unit-no-subpremise-is-not-coming-in
  /// </summary>
  public static fixAddressSubPremise(address: AddressValue): AddressValue {
    if (address && address.inputValue && address.place && address.place.address_components) {
      const { inputValue } = address;
      const { place } = address;
      const route = place.address_components!.find(
        (t) => t.types && !!t.types.find((t2) => t2 === 'route'),
      );

      if (route && route.long_name) {
        const routePrefix = route.long_name.split(' ', 1)[0];
        const i = inputValue.indexOf(routePrefix);
        const j = place.formatted_address!.indexOf(routePrefix);

        if (i >= 0 && j >= 0) {
          const fullStreetNumber = inputValue.substr(0, i).trim();
          const routeIndex = place.address_components!.findIndex(
            (t) =>
              !!t.long_name &&
              t.long_name !== fullStreetNumber &&
              t.types &&
              !!t.types.find((t2) => t2 === 'street_number'),
          );

          if (routeIndex >= 0) {
            const newFormattedAddress = `${fullStreetNumber} ${place.formatted_address!.substr(j)}`;
            Logging.trace(
              `GooglePlacesHelper.fixAddressSubPremise: Changing Google address from '${place.formatted_address}' to '${newFormattedAddress}'`,
            );

            return {
              ...address,
              place: {
                ...place,
                formatted_address: newFormattedAddress,
                // arrayReplaceAt() handles invalid index
                address_components: TypeHelper.arrayReplaceAt(
                  place.address_components!,
                  routeIndex,
                  {
                    ...place.address_components![routeIndex],
                    long_name: fullStreetNumber,
                    short_name: fullStreetNumber,
                  },
                ),
              },
            };
          }
        }
      }
    }

    return address;
  }

  public static getAddressFeildsFromGoogleAPI(apiData: any) {
    let data: any = {
      unitNumber: '',
      streetType: '',
      streetNumber: '',
      streetName: '',
      suburb: '',
      state: '',
      postcode: '',
      country: '',
    };
    if (apiData.place.address_components) {
      apiData.place.address_components.forEach((element: any) => {
        if (element.types.includes('street_number')) {
          data.streetNumber = element.long_name;
        } else if (element.types.includes('route')) {
          data.streetName = element.short_name;
        } else if (element.types.includes('locality')) {
          data.suburb = element.long_name;
        } else if (element.types.includes('administrative_area_level_1')) {
          data.state = element.short_name;
        } else if (element.types.includes('postal_code')) {
          data.postcode = element.long_name;
        } else if (element.types.includes('country')) {
          data.country = element.long_name;
        }
      });
      data = { ...data, ...GooglePlacesHelper.extractStreetTypeAndName(data.streetName) };
    }
    return data;
  }

  public static extractStreetTypeAndName(street: string | undefined | null) {
    if (street) {
      const list = street.trim().split(' ');
      const lastValue = list.pop();
      const lastValueTemp = lastValue ? lastValue.toLowerCase() : '';
      const gotStreetType = StreetTypeList.some(
        (e) =>
          e.abbreviation.toLowerCase() === lastValueTemp ||
          e.street_type.toLowerCase() === lastValueTemp,
      );
      const streetType = gotStreetType ? lastValue : '';
      const streetName = gotStreetType ? list.join(' ') : street;
      return { streetType, streetName };
    }
    return { streetType: '', streetName: street };
  }
}

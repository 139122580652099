import * as React from 'react';
import { Typography, ListItem, ListItemText, ListItemAvatar, withStyles } from '@material-ui/core';
import { GetPaymentMethodsResponse } from '../../../services/payment/getPaymentMethods/GetPaymentMethods.data';
import {
  paymentMethod,
  PaymentMethod,
  deletePaymentSuccessMessage,
  paymentTypes,
} from './SavedPaymentMethodsConstants';
import { History } from 'history';
import ADD_NEW_ICON from '../../../assets/images/payment/filled.png';
import { PaymentMethodType, walletLabels } from '../WalletConstants';
import { SavedPaymentMethodsStyles, styles } from './SavedPaymentStyles';
import Snackbar from '../../snackbarComponent/SnackbarComponent';
import PayId from '../../../assets/payId.png';
import { sendMessageToApp, ValidMessages } from '../../../helper/MessageHelper';
import { GetFastPayResponse } from '../../../services/payment/getFastPayIDResponse/GetFastPayIdResponse';

interface PropsFromParent {
  payment: GetPaymentMethodsResponse[];
  history: History;
  handleOptions: (payment: GetPaymentMethodsResponse) => void;
  showSuccessMessage: boolean;
  payId: GetFastPayResponse | null;
  handleAddPaymentOption: () => void;
  appName: string | boolean | null;
}

type OwnProps = PropsFromParent & SavedPaymentMethodsStyles;
class SavedPaymentMethods extends React.Component<OwnProps, {}> {
  public render() {
    const { payment, classes, appName } = this.props;
    return (
      <div className={classes.root}>
        <div>
          {this.props.payId && this.props.payId.response.payments.length > 0 && (
            <div
              className={classes.payIdContainer}
              onClick={() => {
                sendMessageToApp(ValidMessages.PayId, {
                  payId: (this.props.payId as GetFastPayResponse).response.payments[0]
                    .fastpayReferenceNumber,
                  ref: (this.props.payId as GetFastPayResponse).response.sortedPayId,
                });
              }}
            >
              <img src={PayId} className={classes.payIdImage} />

              <div className={classes.marginColumn}>
                <Typography variant="body1" className={classes.payIdHeading}>
                  {'Sorted PayID'}
                </Typography>
                <Typography variant="subtitle1" className={classes.payIdSubheading}>
                  {`ID: ${this.props.payId.response.sortedPayId}  Ref: ${this.props.payId.response.payments[0].fastpayReferenceNumber}`}
                </Typography>
              </div>
            </div>
          )}
        </div>
        <div className={classes.paymentListContainer}>
          <div>
            {payment.map((paymentDetails: GetPaymentMethodsResponse) =>
              this.renderPaymentOptions(paymentDetails),
            )}
          </div>
          {appName && appName !== 'NAX' ? (
            <ListItem
              button
              className={classes.listitemStyle}
              onClick={this.props.handleAddPaymentOption}
            >
              <ListItemAvatar>
                <img src={ADD_NEW_ICON} className={classes.imageStyle} />
              </ListItemAvatar>
              <ListItemText
                className={classes.itemTextStyle}
                primary={<Typography variant="caption">{walletLabels.ADD_NEW_PAYMENT}</Typography>}
              />
            </ListItem>
          ) : null}
        </div>
        {this.props.showSuccessMessage && (
          <Snackbar message={deletePaymentSuccessMessage} type="success" />
        )}
      </div>
    );
  }
  public renderPaymentOptions = (payment: GetPaymentMethodsResponse) => {
    const { classes } = this.props;
    const paymentMethodsDetails: PaymentMethod =
      payment.paymentMethod === paymentTypes.Bank
        ? paymentMethod.Bank
        : (paymentMethod.Credit.find(
            (data: any) => data.cardType === payment.cardType,
          ) as PaymentMethod);
    return (
      <ListItem
        button
        onClick={() => this.props.handleOptions(payment)}
        className={classes.listitemStyle}
      >
        <ListItemAvatar>
          <img
            src={paymentMethodsDetails.logo}
            className={
              payment.paymentMethod === paymentTypes.Bank
                ? classes.imageStyle
                : classes.cardImageStyle
            }
          />
        </ListItemAvatar>
        <ListItemText
          className={classes.itemTextStyle}
          primary={<Typography variant="caption">{paymentMethodsDetails.name}</Typography>}
          secondary={
            <Typography variant="subtitle1" className={classes.textStyle}>
              {this.handlePaymentMethod(payment)}
            </Typography>
          }
        />
      </ListItem>
    );
  };
  public handlePaymentMethod = (payment: GetPaymentMethodsResponse) => {
    switch (payment.paymentMethod) {
      case PaymentMethodType.Bank:
        return `${payment.bsb} - ${payment.accountNumber}`;
      case PaymentMethodType.Credit:
        return payment.cardNumber.replace(/\./g, '*');
      default:
        return '';
    }
  };
}

export default withStyles(styles)(SavedPaymentMethods);

export const electricityServiceSteps = [
  {
    title: 'Confirm address',
    description: 'Confirm your address and make sure it matches the one in our system',
  },
  {
    title: 'Plan sign up',
    description:
      'Start by selecting the plan to best suit your lifestyle and budget and confirming your personal details',
  },
  {
    title: 'Accept terms',
    description: "Review the terms and conditions and make sure you're happy with everything",
  },
  {
    title: 'Select payment method',
    description: 'Set up direct payments with your preferred payment method',
  },
];
export const commonServiceSteps = [
  {
    title: 'Confirm address',
    description: 'Confirm your address and make sure it matches the one in our system',
  },
  {
    title: 'Plan sign up',
    description:
      'Start by selecting the plan to best suit your lifestyle and budget and confirming your personal details',
  },
  {
    title: 'Personal Details',
    description: 'Fill Personal Details',
  },
  {
    title: 'Accept Terms',
    description: 'Accept Terms and Condition to complete signup',
  },
  {
    title: 'Select payment',
    description: 'Set up direct payments with your preferred payment method',
  },
];
export const button = {
  continue: 'CONTINUE',
  getStarted: 'GET STARTED',
};
export const statusDone = 'Complete';
export const statusPending = 'Not started';
export const statusInProgress = 'In progress';
export const statusCurrent = 'Current';

export const broadbandTitle = 'Add broadband';

export enum ServiceType {
  Rent = 'rent',
  Electricity = 'electricity',
  ElectricityCaps = 'Electricity',
  Broadband = 'broadband',
  EditRent = 'editrent',
  EditElectricity = 'editelectricity',
  EditBroadband = 'editbroadband',
  ElectricitySummary = 'electricitysummary',
  BroadbandSummary = 'broadbandsummary',
  Wallet = 'wallet',
  Solar = 'Solar',
  Cleaning = 'Cleaning',
}

export enum DownLoadType {
  Basic = 'Basic',
  Standard = 'Standard',
  Premium = 'Premium',
}

export enum BroadbandTypes {
  adsl = 'adsl',
  nbn = 'nbn',
}

export const termsAndConditionValue =
  'All prices are inclusive of GST unless stated.\nSorted Services is a registered trading name of Energy Locals.';

export const getContractInfo = (minCost: number) =>
  `Minimum cost $${minCost} if you stay connected for 12 months`;
export const getNoContractInfo = (minCost: number) =>
  `Minimum cost $${minCost} including setup fee and first months charges`;

import * as React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { PlanSummaryStyle, Styles } from './PlanSummaryStyles';
import {
  ElectricityPlanSummary,
  ListValue,
  BroadbandPlanSummary,
  DocumentList,
} from '../../services/planSummary/getPlanSummary/GetPlanSummary.data';
import { ServiceType } from '../../containers/services/ServiceConstants';
import downloadIcon from '../../assets/download.svg';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';
import { LABELS, debitServiceAgrementDocument } from './PlanSummaryConstants';
import downloadPdfService from '../../services/downloadPdf/DownloadPdfService';
interface PropsFromParent {
  planSummary: ElectricityPlanSummary | BroadbandPlanSummary;
  serviceSummary: string;
}
type OwnProps = PlanSummaryStyle & PropsFromParent;
export class PlanSummary extends React.Component<OwnProps, {}> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant="h6" className={classes.titleStyle}>
          {LABELS.planDetails}
        </Typography>
        {this.renderPlanDetails()}
      </div>
    );
  }

  private renderPlanDetails = () => {
    const { serviceSummary } = this.props;
    if (serviceSummary === ServiceType.ElectricitySummary) {
      const planSummary = this.props.planSummary as ElectricityPlanSummary;
      return (
        <React.Fragment>
          {planSummary.orderDetails &&
            this.renderCard(planSummary.orderDetails.details, planSummary.orderDetails.name)}
          {planSummary.monthlyPlan &&
            this.renderCard(planSummary.monthlyPlan.plan, planSummary.monthlyPlan.name)}
          {planSummary.energyRates &&
            this.renderCard(planSummary.energyRates.rates, planSummary.energyRates.name)}
          {this.renderGSTinfoCard()}
          {this.renderDocumentsSection(planSummary.documents)}
        </React.Fragment>
      );
    } else {
      const planSummary = this.props.planSummary as BroadbandPlanSummary;
      return (
        <React.Fragment>
          {planSummary.orderDetails &&
            this.renderCard(planSummary.orderDetails.details, planSummary.orderDetails.name)}
          {this.renderCard(planSummary.planDetails.plan, planSummary.planDetails.name)}
          {this.renderDocumentsSection(planSummary.documents)}
        </React.Fragment>
      );
    }
  };

  private renderCard = (cardDetails: ListValue[], title?: string) => {
    const { classes } = this.props;
    if (cardDetails.length === 0) {
      return;
    }
    return (
      <div className={classes.cardStyle}>
        <div className={classes.cardContentStyle}>
          {title && (
            <div className={classes.cardHeaderStyle}>
              <Typography variant="h6" className={classes.headingText}>
                {title}
              </Typography>
            </div>
          )}
          <div className={classes.subTitleStyle}>
            {cardDetails.map((data: ListValue) => (
              <Typography className={classes.cardTextStyle}>{`${
                data.name ? `${data.name}:` : '' //display ':' only if key is present
              } ${data.value}`}</Typography>
            ))}
          </div>
        </div>
      </div>
    );
  };
  private renderGSTinfoCard = () => {
    const { classes } = this.props;
    return (
      <div className={classes.cardStyle}>
        <div className={`${classes.cardContentStyle} ${classes.subTitleStyle}`}>
          <Typography className={classes.GSTinfo}>{LABELS.inclusiveOfGST}</Typography>
          <Typography variant="h6" className={classes.cardTextStyle}>
            {LABELS.infoCardSubHeading}
          </Typography>
        </div>
      </div>
    );
  };
  private renderDocumentsSection = (documents: DocumentList[]) => {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography className={classes.documentText} variant="subtitle1">
          {LABELS.Documents}
        </Typography>
        <div className={`${classes.cardStyle} ${classes.downloadContentStyle}`}>
          {documents.map((document: DocumentList) => this.renderItem(document))}
          {this.renderItem(debitServiceAgrementDocument)}
        </div>
      </React.Fragment>
    );
  };

  private renderItem = (document: DocumentList) => {
    const { classes } = this.props;
    return (
      <div className={classes.downloadButton} onClick={() => this.handleDownload(document)}>
        <img src={downloadIcon} className={classes.downloadImage} />
        <Typography className={classes.downloadText}>{document.DocumentName}</Typography>
      </div>
    );
  };

  private handleDownload = async (document: DocumentList) => {
    if (document.docLibPath) {
      sendMessageToApp(ValidMessages.pdfFromURL, document.docLibPath);
    } else if (document.DownloadCode) {
      try {
        const response = await downloadPdfService.getPdfData(document.DownloadCode);
        sendMessageToApp(ValidMessages.pdfFromBase64, {
          base64: response.Document,
          fileName: response.filename,
        });
      } catch (error) {
        sendMessageToApp(ValidMessages.Log, error);
      }
    }
  };
}

export default withStyles(Styles)(PlanSummary);

import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../Routes';
import OtherServiceOverview from '../../containers/services/otherServices/OtherServiceOverview';
import OtherServiceSummary from '../../containers/services/otherServices/OtherServiceSummary';
import OtherServiceInfo from '../../containers/services/otherServices/OtherServiceInfo';

export default class OtherServicesLayout extends React.Component {
  public render() {
    return (
      <Switch>
        <Route
          path={routes.otherService.new(':serviceName', ':propertyId', ':providerName')}
          exact
          component={OtherServiceOverview}
        />
        <Route
          path={routes.otherService.summary(':serviceName', ':propertyId', ':serviceAccountId?')}
          exact
          component={OtherServiceSummary}
        />
        <Route
          path={routes.otherService.info(':serviceName', ':propertyId', ':serviceAccountId?')}
          exact
          component={OtherServiceInfo}
        />
      </Switch>
    );
  }
}

import * as React from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/RootReducers';
import { BroadbandServiceDetails } from '../../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import { History } from 'history';
import { RouteComponentProps } from 'react-router-dom';
import { routes } from '../../../Routes';
import { HeaderState, Params } from '../../../store/state/HeaderState';
import { BackHelper } from '../../../helper/BackHelper';
import PlanSelection from '../../../component/services/commonService/PlanSelection';
import { PlanSummaryActions } from '../../../store/actions/PlanSummaryActions';
import { hasUpdatedApp } from '../../../helper/AuthHelper';
import { ServiceSummaryResponse } from '../otherServices/OtherServicesSummary.data';
import {
  CommonSummary,
  ConcessionCard,
  FeatureDetails,
  FooterPricing,
  KeyValueData,
  Options,
  PlanDetail,
  PlanDetailObject,
  ProductDetails,
  QuotesV3,
  ServiceDetail,
  SummaryDetail,
} from './CommonPlanSelectionInterface';
import uuid from 'uuid';
import { get } from 'lodash';
import { PlanCommonSummaryActions } from '../../../store/actions/CommonSummaryActions';
import { CommonServiceAddressLookup } from '../../../services/commonService/getCommonServiceAddressLookup/GetCommonAddressLookup.data';
import { ServiceType } from '../ServiceConstants';

interface PropsFromDispatch {
  getServiceSummary: (
    serviceType: string,
    propertyId: string,
    providerName: string,
    history: History,
  ) => void;
  postCommonData: (serviceDetails: CommonSummary, onSuccess: () => void) => void;
  updateCommonData: (serviceDetails: CommonSummary) => void;
}

interface PropsFromState {
  service: BroadbandServiceDetails;
  accessToken: string | boolean | null;
  params: Params;
  serviceSummary: ServiceSummaryResponse;
  quotes: QuotesV3;
  commonSummaryAddressLookup: CommonServiceAddressLookup;
  serviceAccountId: string | null;
}

interface PropsFromRoute {
  history: History;
}

type OwnProps = PropsFromDispatch &
  PropsFromState &
  PropsFromRoute &
  RouteComponentProps<{
    propertyId: string;
    serviceSummary: string;
    paymentRefId: string;
    serviceAccountId: string;
    serviceName: string;
    providerName: string;
    index: string;
  }>;

class CommonPlanSelection extends React.Component<OwnProps, {}> {
  private waitForServiceId = 0;
  public constructor(props: OwnProps) {
    super(props);
  }
  public componentDidMount() {
    const { propertyId } = this.props.match.params;

    const paymentRefId = '';
    const serviceAccountId = '';

    if (this.waitForServiceId === 0) {
      this.waitForServiceId = 1;
      BackHelper.saveParams({
        propertyId,
        paymentRefId,
        serviceAccountId,
      });
    }
  }

  public componentDidUpdate() {
    const { propertyId } = this.props.match.params;
    const paymentRefId = '';
    const serviceAccountId = '';
    if (serviceAccountId && this.waitForServiceId === 0) {
      this.waitForServiceId = 1;
      BackHelper.saveParams({
        propertyId,
        paymentRefId,
        serviceAccountId,
      });
    }
  }

  public render() {
    const { serviceSummary } = this.props;
    return (
      <PlanSelection
        serviceName={this.props.match.params.serviceName}
        planIndex={this.props.match.params.index}
        onBtnClick={this.redirectTo}
        history={this.props.history}
        serviceSummary={serviceSummary}
      />
    );
  }

  public setSummary = (productDetails: ProductDetails, benefits: PlanDetailObject) => {
    const { quotes } = this.props;
    const planDetailName: KeyValueData = {
      key: quotes.Response.Plans[0].ProductType + ' Name',
      value: get(productDetails, 'productName', ''),
    };
    let planDetailCost: KeyValueData[] = [];
    this.props.quotes.Response.Plans[0].Plans[0].FooterPricing.map(
      (footerPricing: FooterPricing) => {
        if (footerPricing.PriceField !== null && footerPricing.PriceField.length > 0) {
          planDetailCost = [
            ...planDetailCost,
            {
              key: footerPricing.UnitOfMeasure,
              value: productDetails
                ? (productDetails.monthlyCost as Options)[footerPricing.PriceField]
                : '0',
            },
          ];
        }
      },
    );
    let planDetailDescription: KeyValueData[] = [];
    if (benefits.DescriptionFooter.length > 0) {
      planDetailDescription = [
        {
          key: 'Estimated Cost',
          value: get(benefits, 'DescriptionFooter', ''),
        },
      ];
    }

    const planDetailData = [planDetailName, ...planDetailCost, ...planDetailDescription];
    const sectionTitlePlanDetail = 'Plan summary';
    const summaryPlanDetail: SummaryDetail = {
      sectionTitle: sectionTitlePlanDetail,
      data: planDetailData,
    };
    const data = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < benefits.Benefits.length; i++) {
      const summaryData = {
        key: get(benefits.Benefits[i], 'Heading', ''),
        value: get(benefits.Benefits[i], 'Body', ''),
      };
      data.push(summaryData);
    }
    const sectionTitleBenefits = 'Benefits';
    const summaryBenefits: SummaryDetail = {
      sectionTitle: sectionTitleBenefits,
      data,
    };
    const summary: SummaryDetail[] = [summaryPlanDetail, summaryBenefits];

    return summary;
  };

  public signUpSuccess = () => {
    const { history } = this.props;
    history.push({ pathname: this.handleRedirecionUrl() });
  };
  public redirectTo = (
    planSummary: FeatureDetails[],
    productDetail: ProductDetails | null,
    concessionCard: ConcessionCard | null,
    paymentCardRequired: boolean,
    benefits: PlanDetailObject,
  ) => {
    const { quotes, commonSummaryAddressLookup } = this.props;
    const { propertyId, serviceAccountId, serviceName, providerName } = this.props.match.params;
    const summryDetail: SummaryDetail[] = this.setSummary(
      productDetail as ProductDetails,
      benefits,
    );
    //TODO Define variable for 1 or 2 and then compare to multiple places
    const productDetails: ProductDetails | null = productDetail;
    ((productDetails as ProductDetails).cost =
      this.props.quotes.Response.Plans[0].Plans[0].FooterPricing.length === 1 &&
      ((productDetails as ProductDetails).monthlyCost as Options)[
        this.props.quotes.Response.Plans[0].Plans[0].FooterPricing[0].PriceField
      ] !== undefined
        ? `${
            ((productDetails as ProductDetails).monthlyCost as Options)[
              this.props.quotes.Response.Plans[0].Plans[0].FooterPricing[0].PriceField
            ] as string
          }`
        : '0'),
      ((productDetails as ProductDetails).dailyCost =
        this.props.quotes.Response.Plans[0].Plans[0].FooterPricing.length === 2
          ? `${((productDetails as ProductDetails).monthlyCost as Options).BasePriceDaily}`
          : '0');
    (productDetails as ProductDetails).monthlyCost =
      this.props.quotes.Response.Plans[0].Plans[0].FooterPricing.length === 2
        ? `${((productDetails as ProductDetails).monthlyCost as Options).BasePriceMonthly}`
        : '0';
    (productDetails as ProductDetails).costUnit =
      this.props.quotes.Response.Plans[0].Plans[0].FooterPricing[0].UnitOfMeasure !== null
        ? this.props.quotes.Response.Plans[0].Plans[0].FooterPricing.length === 1
          ? `${this.props.quotes.Response.Plans[0].Plans[0].FooterPricing[0].UnitOfMeasure}`
          : `${this.props.quotes.Response.Plans[0].Plans[0].FooterPricing[1].UnitOfMeasure}`
        : '';
    const bodySummary: FeatureDetails = {
      type: 'postSummaryDetails',
      label: 'Body',
      labelId: 'Body',
      value: quotes.Response.Plans[0].PostAgreementSummary.Body.toString(),
      valueId: 'postSummary',
    };
    const titleSummary: FeatureDetails = {
      type: 'postSummaryDetails',
      label: 'title',
      labelId: 'title',
      value: quotes.Response.Plans[0].PostAgreementSummary.Title.toString(),
      valueId: 'postSummary',
    };
    planSummary.push(bodySummary);
    planSummary.push(titleSummary);
    const planDetail: PlanDetail = {
      featureDetails: planSummary,
      summaryDetails: summryDetail,
      attributes: commonSummaryAddressLookup ? commonSummaryAddressLookup.Attributes : {},
      productDetails,
      concessionCard,
      isAcceptTermAndCondition: true,
      transactionId: uuid(),
      skipPaymentStep: !paymentCardRequired,
      paymentType: null,
    };
    const serviceDetails: ServiceDetail = {
      planDetails: planDetail,
      partialCompletion: true,
    };
    const commonSummary: CommonSummary = {
      serviceDetails,
      step: 'PLAN_SELECTION',
      propertyId,
      serviceType: serviceName,
      providerId: providerName,
      serviceAccountId: this.props.serviceAccountId
        ? this.props.serviceAccountId
        : serviceAccountId,
    };
    // In case of solar and cleaning, skip usage submit and redirect to personal details screen
    if (serviceName === ServiceType.Solar || serviceName === ServiceType.Cleaning) {
      this.props.updateCommonData(commonSummary);
      this.signUpSuccess();
    } else {
      this.props.postCommonData(commonSummary, this.signUpSuccess);
    }
  };

  public handleRedirecionUrl = () => {
    const { propertyId, serviceName, providerName } = this.props.match.params;
    return routes.commonService.summary(serviceName, propertyId, providerName);
  };
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>): PropsFromDispatch {
  return {
    postCommonData: (serviceDetails: CommonSummary, onSuccess: () => void) =>
      dispatch(
        PlanCommonSummaryActions.postCommonSummaryData({
          data: serviceDetails,
          onSuccess,
        }),
      ),
    getServiceSummary: (
      serviceType: string,
      propertyId: string,
      providerName: string,
      history: History,
    ) => {
      if (hasUpdatedApp(history)) {
        dispatch(
          PlanSummaryActions.getServiceSummaryStart({
            serviceType,
            propertyId,
            providerName,
          }),
        );
      } else {
        history.push('/onboarding/serviceError');
      }
    },
    updateCommonData: (serviceDetails: CommonSummary) =>
      dispatch(PlanCommonSummaryActions.setCommonServiceDataSuccess(serviceDetails)),
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  const service: BroadbandServiceDetails = state.broadbandService
    .service as BroadbandServiceDetails;
  return {
    service,
    accessToken: state.token.accessToken,
    params: (state.headerParams as HeaderState).params,
    serviceSummary: state.planSummary.serviceSummary,
    quotes: state.commonSummary.quotes as QuotesV3,
    commonSummaryAddressLookup: state.commonSummary
      .commonSummaryAddressLookup as CommonServiceAddressLookup,
    serviceAccountId:
      state.commonSummary.commonServicePlans && state.commonSummary.commonServicePlans.id
        ? state.commonSummary.commonServicePlans.id
        : state.commonSummary.commonServicePlans.serviceAccountResponse
        ? state.commonSummary.commonServicePlans.serviceAccountResponse.id
        : null,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CommonPlanSelection);

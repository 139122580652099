import { takeEvery, put } from 'redux-saga/effects';
import { SpinnerActions } from '../actions/SpinnerActions';
import {
  EditPaymentAction,
  PostEditPaymentPayload,
  EditPaymentActions,
  EditPaymentActionTypes,
} from '../actions/EditPaymentActions';
import editPaymentService from '../../services/editPayment/EditPaymentService';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';

export function* editPaymentSaga(action: EditPaymentAction): IterableIterator<any> {
  try {
    yield put(SpinnerActions.show());
    const { data, onSuccess } = action.data as PostEditPaymentPayload;
    const response = yield editPaymentService.postEditPaymentService(data);
    if (onSuccess) {
      onSuccess();
    }
    yield put(EditPaymentActions.postEditPaymentSuccess(response));
  } catch (error) {
    yield put(SpinnerActions.hide());
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(EditPaymentActions.postEditPaymentError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

function* editPaymentWatcher(): IterableIterator<any> {
  yield takeEvery(EditPaymentActionTypes.PostEditPaymentStart, (action) =>
    editPaymentSaga({
      type: action.type,
      data: (action as EditPaymentAction).data,
    }),
  );
}
export default editPaymentWatcher;

import { put, takeEvery } from 'redux-saga/effects';
import {
  BroadbandPlanAction,
  BroadbandPlanActions,
  BroadbandPlanActionTypes,
} from '../actions/BroadbandPlanAction';
import { PlanAction, PlanActions, PlanActionTypes } from '../actions/PlanActions';
import planService from '../../services/plan/PlanService';
import { SpinnerActions } from '../actions/SpinnerActions';
import { PlanRequest } from '../../services/plan/getPlanDetails/GetPlanDetails.data';
import { TelcoService } from '../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';
import {
  EnergyPricing,
  Document,
} from '../../services/planSummary/getPlanSummary/GetPlanSummary.data';
// import { BroadbandPlan } from "../../models/plan/broadband/getPlanDetails/BroadbandPlan.data";

export function* getElectricityPlan(action: PlanAction): IterableIterator<any> {
  try {
    yield put(SpinnerActions.show());
    const requestData = action.data as PlanRequest;
    const response = yield planService.getPlanDetails(requestData);
    yield put(PlanActions.getPlanDetailsSuccess(filterPlanData(response)));
  } catch (error) {
    yield put(SpinnerActions.hide());
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(PlanActions.getPlanDetailsError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* getBroadbandPlan(action: BroadbandPlanAction): IterableIterator<any> {
  try {
    yield put(SpinnerActions.show());
    // TODO: Integrate service once APIs are deployed
    const response = yield planService.getPlanDetails(action.data);
    yield put(BroadbandPlanActions.getPlanDetailsSuccess(filterBroadbandPlanData(response)));
  } catch (error) {
    yield put(SpinnerActions.hide());
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(BroadbandPlanActions.getPlanDetailsError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

function* planWatcher(): IterableIterator<any> {
  yield takeEvery(PlanActionTypes.GetPlanDetailsStart, (action) =>
    getElectricityPlan({ type: action.type, data: (action as PlanAction).data }),
  );
  yield takeEvery(BroadbandPlanActionTypes.GetBroadbandPlanStart, (action) =>
    getBroadbandPlan({
      type: action.type,
      data: (action as BroadbandPlanAction).data,
    }),
  );
}

export default planWatcher;

const filterPlanData = (response: any) => {
  const monthlyCost: number[] = [];
  const dailyRate: number[] = [];
  const energyPricing: EnergyPricing[] = [];
  const documents: Document[] = [];
  let productCode = '';
  let productName = '';
  let productId = '';
  let productDescription = '';
  let annualDailyLoad = '';
  let distributor = '';
  let termsUrl = '';
  let costBusinessHours = '0';
  let costAfterHours = '0';
  let costAdditionalFees = '0';
  response.Products.map((product: any) => {
    if (product.ProductID === 'AverageDailyLoad') {
      productName = product.Name;
      productDescription = product.Description;
      productId = product.ProductID;
      product.ProductAttributes.map((productAttributes: any) => {
        if (productAttributes.Category === 'Settings' && productAttributes.Name === 'ProductCode') {
          productCode = productAttributes.Value;
        }
        if (
          productAttributes.Category === 'Information/Document' &&
          productAttributes.Name === 'Terms And Conditions'
        ) {
          termsUrl = productAttributes.Value;
        }
      });
    }
    if (product.ProductID === 'StandardElectricity') {
      product.ProductAttributes.map((productAttribute: any) => {
        if (
          productAttribute.Category === 'Settings' &&
          productAttribute.Name === 'AnnualDailyLoad'
        ) {
          annualDailyLoad = productAttribute.Value;
        }
      });
      distributor = product.Prices[0].Distributors[0].Name;
      product.Prices[0].Distributors[0].DistributorAttributes.map((distributorAttribute: any) => {
        if (distributorAttribute.Name === 'CostBusinessHours') {
          costBusinessHours = distributorAttribute.Value;
        }
        if (distributorAttribute.Name === 'CostAfterHours') {
          costAfterHours = distributorAttribute.Value;
        }
        if (distributorAttribute.Name === 'CostAdditionalFees') {
          costAdditionalFees = distributorAttribute.Value;
        }
      });
      product.Prices.map((price: any) => {
        price.Distributors[0].DistributorAttributes.map((attribute: any) => {
          if (attribute.Name.includes('PFS')) {
            documents.push({
              name: 'Energy Price Fact Sheet',
              link: getFileURL(attribute.Value),
            });
          }
        });
        price.PriceComponents.map((PriceComponent: any) => {
          energyPricing.push({
            name: PriceComponent.Name,
            amount: PriceComponent.Amount,
            unitOfMeasureId: PriceComponent.UnitOfMeasureID,
          });
        });
      });
    }
    product.Prices.map((price: any) => {
      price.PriceComponents.map((priceComponent: any) => {
        if (
          (priceComponent.Name === 'DefaultPrice' || priceComponent.Name === 'AverageDailyLoad') &&
          priceComponent.LineTypeID === 'MonthlyRate'
        ) {
          monthlyCost.push(priceComponent.Amount);
        }
        if (
          (priceComponent.Name === 'DefaultPrice' || priceComponent.Name === 'AverageDailyLoad') &&
          priceComponent.LineTypeID === 'DailyRate'
        ) {
          dailyRate.push(priceComponent.Amount);
        }
      });
    });
  });
  const connectionFee: number = parseFloat(costAdditionalFees);
  documents.push({
    name: 'Direct Debit Service Agreement',
    link: getFileURL('Direct-Debit-Request-Service-Agreement.pdf'),
  });
  return {
    annualUsage: annualDailyLoad,
    distributor,
    termsUrl,
    energyPricing,
    documents,
    plans: [
      {
        usage: 'Low',
        productName,
        monthlyCost: monthlyCost[0],
        dailyCost: dailyRate[0],
        productCode,
        productId,
        productDescription,
        productType: 'Plan',
      },
      {
        usage: 'Moderate',
        productName,
        monthlyCost: monthlyCost[1],
        dailyCost: dailyRate[1],
        productCode,
        productId,
        productDescription,
        productType: 'Plan',
      },
      {
        usage: 'High',
        productName,
        monthlyCost: monthlyCost[2],
        dailyCost: dailyRate[2],
        productCode,
        productId,
        productDescription,
        productType: 'Plan',
      },
    ],
    costAfterHours,
    costBusinessHours,
    connectionFee,
  };
};

const filterBroadbandPlanData = (response: any) => {
  const planData: any = [];
  const labels: string[] = [];
  const productNames: string[] = [];
  const telcoServiceResponse = response.TelcoService.TelcoServiceQualify;
  const telcoService: TelcoService = {};
  telcoService.zone = telcoServiceResponse.Zone;
  telcoService.rfsDate = telcoServiceResponse.RFSDate;
  telcoService.serviceClass = telcoServiceResponse.ServiceClass;
  telcoService.technologyType = telcoServiceResponse.Technology;
  if (telcoServiceResponse.nbnDevelopmentFee) {
    telcoService.nbnDevelopmentFee = telcoServiceResponse.nbnDevelopmentFee;
  }
  telcoServiceResponse.billingCTSTariffName = '';
  let productType = '';
  let modemLabel = '';
  const broadbandType = !telcoServiceResponse.Product ? 'nbn' : telcoServiceResponse.Product;
  response.Products.map((product: any) => {
    const data: any = {};
    data.voicePlanDetails = [];
    data.documents = [];

    if (product.ProductID.includes('Modem')) {
      data.modem = {
        productName: product.Name,
      };
      productNames.push(product.Name);
      product.ProductAttributes.map((productAttribute: any) => {
        if (productAttribute.Category === 'Settings' && productAttribute.Name === 'ProductCode') {
          data.modem = {
            ...data.modem,
            productCode: productAttribute.Value,
            productId: productAttribute.ProductID,
          };
        }
        if (productAttribute.Category === 'Settings' && productAttribute.Name === 'Plan Type') {
          data.modem = {
            ...data.modem,
            productType: productAttribute.Value,
          };
          productType = productAttribute.Value;
        }
        if (
          productAttribute.Category === 'Features' &&
          productAttribute.Name === 'ModemDescription'
        ) {
          data.modem = {
            ...data.modem,
            productDescription: productAttribute.Value,
          };
        }
        product.Prices.map((price: any) => {
          price.PriceComponents.map((priceComponent: any) => {
            if (priceComponent.Name === 'Free') {
              modemLabel = 'Free';
              data.modem = {
                ...data.modem,
                monthlyCost: 0,
                dailyCost: 0,
              };
            }
            if (priceComponent.Name === 'SingleCharge') {
              modemLabel = `$${priceComponent.Amount}`;
              data.modem = {
                ...data.modem,
                monthlyCost: priceComponent.Amount,
                dailyCost: 0,
              };
            }
          });
        });
      });
      planData.push(data);
    } else {
      productNames.push(product.Name);
      data.broadBandType = broadbandType;
      data.productDetails = {
        productName: product.Name,
        productId: product.ProductID,
        productDescription: product.Description,
      };
      data.speedDetails = {};
      product.Prices.map((price: any) => {
        price.PriceComponents.map((priceComponent: any) => {
          if (priceComponent.LineTypeID === 'MonthlyPlanRate') {
            data.productDetails = {
              ...data.productDetails,
              monthlyCost: priceComponent.Amount,
            };
          }
          if (priceComponent.LineTypeID === 'DailyRate') {
            data.productDetails = {
              ...data.productDetails,
              dailyCost: priceComponent.Amount,
            };
            data.minimumCost = (365 * parseFloat(priceComponent.Amount)).toFixed(2);
          }
        });
      });
      data.billingCTSTariffName = '';
      // tslint:disable-next-line:cyclomatic-complexity
      product.ProductAttributes.map((productAttribute: any) => {
        if (productAttribute.Category === 'Settings' && productAttribute.Name === 'ProductCode') {
          data.productDetails = {
            ...data.productDetails,
            productCode: productAttribute.Value,
          };
        }
        if (productAttribute.Category === 'Settings' && productAttribute.Name === 'Plan Type') {
          data.productDetails = {
            ...data.productDetails,
            productType: productAttribute.Value,
          };
        }
        if (
          productAttribute.Category === 'Settings' &&
          productAttribute.Name === 'ProvisioningProductCode'
        ) {
          data.provisioningProductCodeOriginal = productAttribute.Value;
          data.provisioningProductCodeCurrent = productAttribute.Value;
        }
        if (
          productAttribute.Category === 'Settings' &&
          productAttribute.Name === 'BillingCTSTariffName'
        ) {
          data.billingCTSTariffName =
            data.billingCTSTariffName === ''
              ? productAttribute.Value
              : `${data.billingCTSTariffName} | ${productAttribute.Value}`;
        }
        if (
          productAttribute.Category === 'Settings' &&
          productAttribute.Name === 'SetupFeeContractNo'
        ) {
          data.setupFeeContractNo = productAttribute.Value;
        }
        if (
          productAttribute.Category === 'Settings' &&
          productAttribute.Name === 'SetupFeeContractYes'
        ) {
          data.setupFeeContractYes = productAttribute.Value;
        }
        if (productAttribute.Category === 'Features' && productAttribute.Name === 'ConnectionFee') {
          data.connectionFeeAmount = productAttribute.Value && parseInt(productAttribute.Value, 10);
        }
        if (
          productAttribute.Category === 'Settings' &&
          productAttribute.Name === 'EarlyTerminationFeeContractNo'
        ) {
          data.earlyTerminationFeeContractNo = productAttribute.Value;
        }
        if (
          productAttribute.Category === 'Settings' &&
          productAttribute.Name === 'EarlyTerminationFeeContractYes'
        ) {
          data.earlyTerminationFeeContractYes = productAttribute.Value;
        }
        if (
          productAttribute.Category === 'Settings' &&
          productAttribute.Name === 'Data Allowance'
        ) {
          data.dataSize = productAttribute.Value;
        }
        if (
          productAttribute.Category === 'Settings' &&
          productAttribute.Name === 'Data Allowance Label'
        ) {
          if (!labels.includes(productAttribute.Value)) {
            labels.push(productAttribute.Value);
          }
        }
        if (productAttribute.Category === 'Features' && productAttribute.Name === 'ModemCost') {
          productAttribute.Value === 'Included'
            ? (data.modemIncluded = true)
            : (data.modemIncluded = false);
        } else {
          data.modemIncluded = false;
        }
        if (productAttribute.Category === 'Settings' && productAttribute.Name === 'Speed Tier') {
          if (broadbandType === 'nbn') {
            const speeddTier = productAttribute.Value.split('/');
            data.speedDetails = {
              ...data.speedDetails,
              downloadSpeed: `${speeddTier[0]} Mbps`,
              uploadSpeed: `${speeddTier[1]} Mbps`,
              peaktime: null,
              cost: null,
              speedTier: productAttribute.Value,
            };
          } else {
            data.speedDetails = {
              ...data.speedDetails,
              downloadSpeed: '0',
              uploadSpeed: '0',
              peaktime: null,
              cost: null,
              speedTier: productAttribute.Value,
            };
          }
        }
        if (productAttribute.Category === 'Features' && productAttribute.Name === 'DownloadSpeed') {
          data.speedDetails = {
            ...data.speedDetails,
            downloadSpeedText: productAttribute.Value,
          };
        }
        if (productAttribute.Category === 'Features' && productAttribute.Name === 'UploadSpeed') {
          data.speedDetails = {
            ...data.speedDetails,
            uploadSpeedText: productAttribute.Value,
          };
        }
        if (
          productAttribute.Category === 'Information/Document' &&
          productAttribute.Name === 'Terms And Conditions'
        ) {
          data.termsUrl = productAttribute.Value;
        }
        if (
          productAttribute.Category === 'Features' &&
          productAttribute.Name === 'LineFeatures' &&
          !productAttribute.Value.includes('http')
        ) {
          data.voicePlanDetails.push(productAttribute.Value);
        }
        if (productAttribute.Category === 'Settings' && productAttribute.Name.includes('InfoCIS')) {
          data.documents.push({
            name: 'Critical Information Summary',
            link: getFileURL(productAttribute.Value),
          });
        }
        if (
          productAttribute.Category === 'Settings' &&
          productAttribute.Name.includes('CallRates')
        ) {
          data.documents.push({
            name: 'International Call Rates',
            link: getFileURL(productAttribute.Value),
          });
        }
      });
      data.documents.push({
        name: 'Direct Debit Service Agreement',
        link: getFileURL('Direct-Debit-Request-Service-Agreement.pdf'),
      });
      planData.push(data);
    }
  });
  switch (broadbandType) {
    case 'nbn':
      return {
        NBN: {
          plan: planData,
          labels,
          productNames,
          telcoService,
          broadbandType,
          productType,
          modemLabel,
        },
        ADSL: null,
      };
    case 'adsl':
      return {
        NBN: null,
        ADSL: {
          plan: planData,
          labels,
          productNames,
          telcoService,
          broadbandType,
          productType,
          modemLabel,
        },
      };
    default:
      return {
        NBN: {
          plan: planData,
          labels,
          productNames,
          telcoService,
          broadbandType,
          productType,
          modemLabel,
        },
        ADSL: null,
      };
  }
};

const getFileURL = (link: string): string => {
  return `https://www.sortedservices.com/s/${link}`;
};

import { AvailableServicesResponse, Preopen, ServiceTypes } from '../../models/checkout/Checkout';

export const getActiveServices = (
  requiredServiceTypes: ServiceTypes[] | undefined,
  cartItems: AvailableServicesResponse[],
  availableServices: AvailableServicesResponse[] | undefined,
): AvailableServicesResponse[] => {
  if (availableServices && availableServices.length) {
    // If no required serviceTypes use response
    if (!requiredServiceTypes) {
      return availableServices;
    }
    // If nothing in cart, use only required serviceType/s
    if (!cartItems.length) {
      return availableServices.filter((service) =>
        requiredServiceTypes.includes(service.type as ServiceTypes),
      );
    }

    // Otherwise use what's in cart + required serviceType/s
    const cartServiceTypes = cartItems.map((c) => c.type);
    return availableServices.filter((service) =>
      [...cartServiceTypes, ...requiredServiceTypes].includes(service.type as ServiceTypes),
    );
  } else {
    return [];
  }
};

export const getPreopenedServices = (
  preopen: Preopen | undefined,
  cartItems: AvailableServicesResponse[],
): Preopen => {
  // TODO: Phase 2 preopened plans replace cart plans
  const result: Preopen = {};
  // Preopen cart items
  cartItems.map((item) => {
    const providerId = item.suppliers![0].providerId;
    const productId = item.suppliers![0].plans[0].productId;
    result[item.type] = {
      providerId,
      // productId,
    };
  });
  if (preopen) {
    Object.keys(preopen).map((key) => {
      if (preopen[key].providerId) {
        result[key] = {
          providerId: preopen[key].providerId,
        };
      } else {
        result[key] = {};
      }
    });
  }
  return result;
};

export const filterOutHTML = (value: string) => {
  return value.replace(/<[^>]*>?/gm, '');
};

import React, { FC, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../store/RootReducers';
import { GetPaymentTypeResponse } from '../../../services/payment/getPaymentTypes/GetPaymentTypesResponse.data';
import { withStyles, Typography, Drawer, Checkbox } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router';
import { BackHelper } from '../../../helper/BackHelper';
import { WalletActions } from '../../../store/actions/WalletActions';
import {
  GetRentServiceResponse,
  RentalFrequency,
} from '../../../services/rentService/getRentService/GetRentServiceResponse.data';
import {
  BroadbandServiceDetails,
  ServiceDetails,
  BroadbandPlanSubmit,
  ProductDetails,
} from '../../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import {
  ElectricityServiceDetails,
  ElectricityPlanDetails,
} from '../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { isUndefined } from 'lodash';
import {
  LABELS,
  MatchParams,
  PAYMENT_TYPES,
  DRAWERS,
  termsDrawerTitle,
  commonTermsAndConditionText,
  serviceTypeList,
  debitCardTermsAnadConditionsText,
  downloadText,
  paymentDisabledModal,
  AGREEMENT_URL,
  defaultPaymentTypes,
} from './AddPaymentConstants';
import { styles, AddPaymentStyles } from './AddPaymentStyles';
import { getAgencyName } from './AddPaymentUtils';
import { ServiceType } from '../../services/ServiceConstants';
import { calculateCost } from '../../../helper/CostHelper';
import {
  ExtraPayText,
  PAYMENT_LABELS,
  transactionFeeType,
} from '../selectPayment/PaymentDetailsConstants';
import { routes } from '../../../Routes';
import NavBar from '../../../component/navBar/NavBar';
import selectedIcon from '../../../assets/radio-filled.png';
import selectedIconBlack from '../../../assets/radio-filled-black.png';
import { color } from '../../../theme/Theme';
import MainContainer from '../../../component/mainContainer/MainContainer';
import { useGetLeaseData } from '../../../helper/RentServiceHelper';
import { AGENCIES, APP } from '../../../helper/AppNameHelper';
import { openPdf } from '../../../helper/PdfHelper';

interface AddPaymentProps extends AddPaymentStyles, RouteComponentProps<MatchParams> {
  broadbandMonthlyCost: number;
  electricityCost: number;
  paymentTypes: GetPaymentTypeResponse[];
  hideSuccessMessage: () => void;
}

const AddPayment: FC<AddPaymentProps> = ({
  classes,
  history,
  match,
  broadbandMonthlyCost,
  electricityCost,
  paymentTypes,
  hideSuccessMessage,
}) => {
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [selected, setSelected] = useState<string | null>(null);
  const [nextPage, setNextPage] = useState<string>('');
  const [termsDrawer, setTermsDrawer] = useState<boolean>(false);
  const [paymentTypeDisabledDrawer, setPaymentTypeDisabledDrawer] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<DRAWERS | undefined>(undefined);
  const [checked, setChecked] = useState<boolean>(false);
  const { propertyId, serviceType, paymentRefId, serviceAccountId } = match.params;
  const rentService = useGetLeaseData();

  useEffect(() => {
    const { propertyId, serviceType, serviceAccountId, paymentRefId } = match.params;
    BackHelper.saveParams({ propertyId, paymentRefId, serviceAccountId });
    if (serviceType === serviceTypeList.wallet) {
      hideSuccessMessage();
    }
  }, []);

  const paymentTypeOptions = useMemo<GetPaymentTypeResponse[]>(() => {
    return match.params.serviceType === serviceTypeList.wallet ? defaultPaymentTypes : paymentTypes;
  }, [paymentTypes, defaultPaymentTypes, match.params.serviceType]);

  const paymentMethodEnabled = useMemo<GetPaymentTypeResponse | undefined>(() => {
    if (selected === null) return undefined;
    return paymentTypeOptions.find((p) => p.paymentMethod === selected);
  }, [paymentTypeOptions, selected]);

  const paymentMethodDetails = useMemo<ExtraPayText | undefined>(() => {
    if (paymentMethodEnabled === undefined) return undefined;

    return PAYMENT_LABELS.extraPayText.find(
      (data) => data.type === paymentMethodEnabled.paymentMethod,
    );
  }, [paymentMethodEnabled]);

  const selectedPayment = (type: string, link: string, drawerName: DRAWERS) => {
    if (drawerName === DRAWERS.paymentTypeDisabledDrawer) {
      setPaymentTypeDisabledDrawer(true);
    }
    setSelected(type);
    setIsSelected(drawerName !== DRAWERS.paymentTypeDisabledDrawer);
    setNextPage(link);
    setOpenDrawer(drawerName);
  };

  return (
    <div className={classes.root}>
      <NavBar subTitle={LABELS.NAV_TITLE} subText={LABELS.NAV_SUBTITLE} />
      <div className={classes.body}>
        <MainContainer>
          {PAYMENT_TYPES.map((payment, index) => (
            <div
              key={payment.name}
              onClick={() =>
                selectedPayment(
                  payment.type,
                  payment.type === 'Bank'
                    ? routes.payment.new.bank(
                        propertyId,
                        serviceType,
                        paymentRefId,
                        serviceAccountId,
                      )
                    : routes.payment.new.card(
                        propertyId,
                        serviceType,
                        paymentRefId,
                        serviceAccountId,
                      ),
                  paymentTypeOptions.find(
                    (paymentType) => paymentType.paymentMethod === payment.type,
                  )
                    ? DRAWERS.termsDrawer
                    : DRAWERS.paymentTypeDisabledDrawer,
                )
              }
              style={
                !paymentTypeOptions.find(
                  (paymentType) => paymentType.paymentMethod === payment.type,
                )
                  ? { opacity: 0.5 }
                  : {}
              }
              className={classes.menuItem}
            >
              <div className={classes.iconContainer}>
                <img src={payment.icon} className={classes.iconImage} />
              </div>
              <div className={classes.optionContainer}>
                <div className={classes.optionTitle}>{payment.name}</div>
              </div>

              {isSelected && selected === payment.type && (
                <div className={classes.tickContainer}>
                  <img
                    src={APP === AGENCIES.NAX ? selectedIconBlack : selectedIcon}
                    className={classes.tick}
                  />
                </div>
              )}
            </div>
          ))}
          <div className={classes.messageBox}>
            {isSelected && paymentMethodEnabled ? (
              <>
                {!paymentMethodEnabled.transactionFeeValue ? (
                  <Typography variant="body2" className={classes.transactionFeeStyle}>
                    {paymentMethodDetails && paymentMethodDetails.zeroValueMessage}
                  </Typography>
                ) : (
                  <Typography variant="body2" className={classes.transactionFeeStyle}>
                    {paymentMethodDetails &&
                      paymentMethodDetails.value(
                        paymentMethodEnabled.transactionFeeType === transactionFeeType.Flat
                          ? `$${paymentMethodEnabled.transactionFeeValue}`
                          : `${paymentMethodEnabled.transactionFeeValue}%`,
                        paymentMethodEnabled.transactionFeeType === transactionFeeType.Flat
                          ? `$${paymentMethodEnabled.internationalFeeValue}`
                          : `${paymentMethodEnabled.internationalFeeValue}%`,
                      )}
                  </Typography>
                )}
              </>
            ) : (
              <div />
            )}
          </div>
        </MainContainer>

        <div className={classes.footerButtonsContainer}>
          <MainContainer>
            <div
              className={`${!isSelected ? classes.disabled : classes.footerButton}`}
              onClick={() => {
                if (isSelected) {
                  if (openDrawer === DRAWERS.paymentTypeDisabledDrawer) {
                    setPaymentTypeDisabledDrawer(true);
                  } else {
                    setTermsDrawer(true);
                  }
                }
              }}
            >
              {PAYMENT_LABELS.buttonLabel}
            </div>
            <div className={classes.footerButtonSecondary} onClick={() => history.goBack()}>
              Back
            </div>
          </MainContainer>
        </div>
      </div>
      <Drawer
        anchor="bottom"
        classes={{
          paper: classes.bottomDrawerContainer,
        }}
        open={termsDrawer}
        onClose={() => setTermsDrawer(false)}
      >
        <div className={classes.bottomDrawer}>
          <Typography className={classes.bottomDrawerTitle}>{termsDrawerTitle}</Typography>
          <Typography className={classes.terms}>
            {serviceType === ServiceType.Rent ? (
              <>
                {commonTermsAndConditionText(
                  calculateCost(
                    rentService!.paymentFrequency as RentalFrequency,
                    rentService!.monthlyCost as number,
                  ),
                  rentService!.agency.tradingName,
                  rentService!.nextPaymentDate,
                )}
              </>
            ) : serviceType === ServiceType.Electricity ? (
              <>{commonTermsAndConditionText(`$${electricityCost} per Month`, 'Sorted')}</>
            ) : serviceType === ServiceType.Broadband ? (
              <>{commonTermsAndConditionText(`$${broadbandMonthlyCost} per Month`, 'Sorted')}</>
            ) : (
              <div />
            )}
          </Typography>

          {serviceType === ServiceType.Wallet ||
          serviceType === ServiceType.EditRent ||
          serviceType === ServiceType.EditElectricity ||
          serviceType === ServiceType.EditBroadband ||
          selected === 'Bank' ||
          selected === 'Credit' ? (
            <div>
              <Typography className={classes.terms}>{debitCardTermsAnadConditionsText}</Typography>
              <Typography
                className={classes.downloadButtonText}
                onClick={() => openPdf(AGREEMENT_URL)}
              >
                {downloadText}
              </Typography>
            </div>
          ) : (
            <div />
          )}
          <div className={classes.checkContainer}>
            <Checkbox
              style={{ color: color.buttonBackgroundSecondary }}
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
            <Typography className={classes.checkboxText} onClick={() => setChecked(!checked)}>
              I accept terms and conditions
            </Typography>
          </div>
          <div className={classes.drawerFooter}>
            <div
              className={`${!checked ? classes.disabled : classes.footerButton}`}
              onClick={() => {
                if (checked) {
                  BackHelper.saveCount(0);
                  history.push(nextPage);
                }
              }}
            >
              Continue
            </div>
            <div className={classes.footerButtonSecondary} onClick={() => setTermsDrawer(false)}>
              Back
            </div>
          </div>
        </div>
      </Drawer>
      <Drawer
        anchor="bottom"
        classes={{
          paper: classes.bottomDrawerContainer,
        }}
        className={classes.bottomDrawerContainer}
        open={paymentTypeDisabledDrawer}
        onClose={() => setPaymentTypeDisabledDrawer(false)}
      >
        <div className={classes.bottomDrawer}>
          <Typography className={classes.bottomDrawerTitle}>
            {paymentDisabledModal.title}
          </Typography>
          <Typography variant="body2" className={classes.disabledMessage}>
            {paymentDisabledModal.info(
              getAgencyName(match.params, rentService!),
              selected!,
              serviceType.toLowerCase(),
            )}
          </Typography>
          <div
            className={classes.footerButtonSecondary}
            onClick={() => setPaymentTypeDisabledDrawer(false)}
          >
            Cancel
          </div>
        </div>
      </Drawer>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  hideSuccessMessage: () => dispatch(WalletActions.hideSuccessMessage()),
});

const mapStateToProps = (state: ApplicationState) => {
  const broadbandMonthlyCost =
    (state.broadbandService.service as ServiceDetails) &&
    ((
      (
        (state.broadbandService.service as BroadbandServiceDetails).serviceDetails
          .broadbandPlanDetails as BroadbandPlanSubmit
      ).productDetails as ProductDetails
    ).monthlyCost as number);
  const electricityCost =
    !isUndefined(state.electricityService.service as ElectricityServiceDetails) &&
    !isUndefined((state.electricityService.service as ElectricityServiceDetails).serviceDetails) &&
    !isUndefined(
      (state.electricityService.service as ElectricityServiceDetails).serviceDetails
        .electricityPlanDetails as ElectricityPlanDetails,
    ) &&
    !isUndefined(
      (
        (state.electricityService.service as ElectricityServiceDetails).serviceDetails
          .electricityPlanDetails as ElectricityPlanDetails
      ).productDetails as ProductDetails,
    )
      ? ((
          (
            (state.electricityService.service as ElectricityServiceDetails).serviceDetails
              .electricityPlanDetails as ElectricityPlanDetails
          ).productDetails as ProductDetails
        ).monthlyCost as number)
      : 0;

  return {
    electricityCost,
    broadbandMonthlyCost,
    paymentTypes: state.payment.paymentTypes,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(AddPayment)));

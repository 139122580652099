import { History } from 'history';
import { sendMessageToApp, ValidMessages } from '../../../../helper/MessageHelper';
import { routes } from '../../../../Routes';
import { ServiceType } from '../../../services/ServiceConstants';
import { MatchParams } from './AddBankConstants';

export const handlePaymentSuccess = (history: History, matchParams: MatchParams) => {
  const { serviceType } = matchParams;
  switch (serviceType) {
    case ServiceType.Electricity:
      history.push(routes.setup.electricity);
      break;
    case ServiceType.ElectricityCaps:
      history.push(routes.setup.electricity);
      break;
    case ServiceType.Broadband:
      history.push(routes.setup.broadband);
      break;
    case ServiceType.EditRent:
      sendMessageToApp(ValidMessages.Dashboard);
      break;
    case ServiceType.EditElectricity:
      sendMessageToApp(ValidMessages.Dashboard);
      break;
    case ServiceType.EditBroadband:
      sendMessageToApp(ValidMessages.Dashboard);
      break;
    default:
      break;
  }
};

import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';
import { ApplicationState } from '../../../store/RootReducers';
import OverViewScreen from '../../../component/services/electricityService/OverView';
import { ElectricityServiceActions } from '../../../store/actions/ElectricityServiceActions';
import {
  ElectricityServiceDetails,
  ServiceDetails,
  StepDetails,
} from '../../../services/electricityService/getElectricityService/GetElectricityService.data';
import {
  electricityServiceSteps,
  statusCurrent,
  statusDone,
  statusPending,
  ServiceType,
} from '../ServiceConstants';
import { routes } from '../../../Routes';
import { History } from 'history';
import { RouteComponentProps } from 'react-router-dom';
import { BackHelper } from '../../../helper/BackHelper';
import { HeaderState, Params } from '../../../store/state/HeaderState';

interface PropsFromDispatch {
  getElectricityService: (propertyId: number) => void;
  clearElectricityService: (serviceAccountId: string, onSuccess: () => void) => void;
  getAddress: (propertyId: string) => void;
}
interface PropsFromState {
  service: ElectricityServiceDetails;
  accessToken: string | boolean | null;
  params: Params;
}

interface PropsFromRoute {
  history: History;
}

type OwnProps = PropsFromDispatch &
  PropsFromState &
  PropsFromRoute &
  RouteComponentProps<{
    propertyId: string;
    serviceSummary: string;
    paymentRefId: string;
    serviceAccountId: string;
  }>;

interface OwnState {
  status: string[];
}
class OverView extends React.Component<OwnProps, OwnState> {
  private waitForAccessToken = 0;
  private waitForServiceId = 0;
  public constructor(props: any) {
    super(props);
    this.state = {
      status: [statusCurrent, statusPending, statusPending, statusPending],
    };
  }

  public componentDidMount() {
    const { accessToken, params } = this.props;
    const { propertyId, paymentRefId, serviceAccountId } = this.props.match.params;
    if (accessToken && (serviceAccountId || params.serviceAccountId)) {
      this.getElectricity();
    }
    if (serviceAccountId && this.waitForServiceId === 0) {
      this.waitForServiceId = 1;
      BackHelper.saveParams({
        propertyId,
        paymentRefId,
        serviceAccountId,
      });
    }
  }

  public getElectricity = () => {
    const { params } = this.props;
    const { serviceAccountId } = this.props.match.params;
    const electricityServiceId = serviceAccountId ? serviceAccountId : params.serviceAccountId;
    this.props.getElectricityService(parseInt(electricityServiceId, 10));
    this.waitForAccessToken = 1;
  };

  public componentDidUpdate() {
    const { serviceAccountId, propertyId, paymentRefId } = this.props.match.params;
    const { accessToken, params } = this.props;
    if (
      accessToken &&
      this.waitForAccessToken === 0 &&
      (serviceAccountId || params.serviceAccountId)
    ) {
      this.getElectricity();
    }
    if (serviceAccountId && this.waitForServiceId === 0) {
      this.waitForServiceId = 1;
      BackHelper.saveParams({
        propertyId,
        paymentRefId,
        serviceAccountId,
      });
    }
  }

  // TODO refactor in next pr
  public getStepsStatus = () => {
    const { service } = this.props;
    const serviceDetail = service && service.serviceDetails;
    const planDetails = this.getPlanStepDetails();
    if (serviceDetail && !(serviceDetail.confirmAddressStep as StepDetails).completed) {
      return [statusCurrent, statusPending, statusPending, statusPending];
    } else if (!planDetails) {
      return [statusDone, statusCurrent, statusPending, statusPending];
    } else if (serviceDetail && !(serviceDetail.acceptTermsStep as StepDetails).completed) {
      return [statusDone, statusDone, statusCurrent, statusPending];
    } else if (serviceDetail && !(serviceDetail.paymentDetailsStep as StepDetails).completed) {
      return [statusDone, statusDone, statusDone, statusCurrent];
    } else {
      return [statusDone, statusDone, statusDone, statusDone];
    }
  };
  public redirectTo = (stepCode: number) => {
    const stepUrls = this.handleRedirecionUrl();
    this.props.history.push(stepUrls[stepCode]);
  };
  public render() {
    const { params } = this.props;
    const { serviceAccountId } = this.props.match.params;
    if (serviceAccountId || params.serviceAccountId) {
      const stepStatus = this.getStepsStatus();
      return (
        <OverViewScreen
          overViewSteps={electricityServiceSteps}
          serviceTitle="Add Electricity"
          stepStatus={stepStatus}
          redirectTo={this.redirectTo}
          handleClear={this.handleClear}
        />
      );
    } else {
      const stepStatus = [statusCurrent, statusPending, statusPending, statusPending];
      return (
        <OverViewScreen
          overViewSteps={electricityServiceSteps}
          serviceTitle="Add Electricity"
          stepStatus={stepStatus}
          redirectTo={this.redirectTo}
          handleClear={this.handleClear}
        />
      );
    }
  }

  private handleClear = () => {
    const { params } = this.props;
    const { serviceAccountId } = this.props.match.params;
    const electricityServiceId = serviceAccountId ? serviceAccountId : params.serviceAccountId;
    this.props.clearElectricityService(electricityServiceId, this.handleClearSuccess);
  };

  private handleClearSuccess = () => {
    const { propertyId } = this.props.match.params;
    BackHelper.saveParams({
      propertyId,
      paymentRefId: '',
      serviceAccountId: '',
    });
    this.props.history.replace(routes.service.new(propertyId));
  };

  private getPlanStepDetails = () => {
    const { service } = this.props;
    const serviceDetail = service && service.serviceDetails;
    const personalDetailsStep =
      serviceDetail && (serviceDetail.personalDetailsStep as StepDetails).completed;
    const planDetailsStep =
      serviceDetail && (serviceDetail.planDetailsStep as StepDetails).completed;
    const connectionDateStep =
      serviceDetail && (serviceDetail.connectionDateStep as StepDetails).completed;
    return personalDetailsStep && planDetailsStep && connectionDateStep;
  };

  private handleRedirecionUrl = () => {
    const { propertyId, serviceSummary, serviceAccountId } = this.props.match.params;
    const { params } = this.props;
    if (serviceAccountId || params.serviceAccountId) {
      const { service } = this.props;
      const serviceDetail = service && service.serviceDetails;
      const planDetailsStep =
        serviceDetail && (serviceDetail.planDetailsStep as StepDetails).completed;
      const connectionDateStep =
        serviceDetail && (serviceDetail.connectionDateStep as StepDetails).completed;
      let planUrl = routes.service.personalDetails.showDetails(propertyId);
      if (!planDetailsStep) {
        planUrl = routes.service.plan.selection(propertyId);
      } else if (!connectionDateStep) {
        planUrl = routes.service.connectionDate.saved(propertyId);
      }
      return [
        routes.service.address.form(propertyId),
        planUrl,
        routes.service.termsAndConditions.new(propertyId, serviceSummary),
        routes.payment.list.saved(propertyId, ServiceType.Electricity),
      ];
    } else {
      return [
        routes.service.address.form(propertyId),
        routes.service.plan.selection(propertyId),
        routes.service.termsAndConditions.new(propertyId, serviceSummary),
        routes.payment.list.saved(propertyId, ServiceType.Electricity),
      ];
    }
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): PropsFromDispatch {
  return {
    getElectricityService: (propertyId: number) => {
      dispatch(ElectricityServiceActions.getElectricityServiceStart(propertyId));
    },
    clearElectricityService: (serviceAccountId: string, onSuccess: () => void) => {
      dispatch(
        ElectricityServiceActions.deleteElectricityServiceStart({
          data: serviceAccountId,
          onSuccess,
        }),
      );
    },
    getAddress: (propertyId: string) =>
      dispatch(ElectricityServiceActions.getAddressStart(propertyId)),
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  const service: ElectricityServiceDetails = state.electricityService
    .service as ElectricityServiceDetails;
  return {
    service,
    accessToken: state.token.accessToken,
    params: (state.headerParams as HeaderState).params,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OverView);

import { createStyles, WithStyles } from '@material-ui/core';
import { color } from '../../theme/Theme';

export const Styles = createStyles({
  root: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 84,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: color.secondary,
  },
  titleStyle: {
    marginTop: 48,
    paddingTop: 24,
    marginLeft: 8,
    fontSize: '23px',
    letterSpacing: '0.25px',
    color: color.dark,
  },
  cardStyle: {
    borderRadius: 8,
    marginTop: 16,
    border: `1px solid ${color.darkerWhite}`,
    backgroundColor: color.light,
  },
  cardContentStyle: {
    'display': 'flex',
    'flexDirection': 'column',
    '&:last-child': {
      paddingBottom: 12,
    },
  },
  cardHeaderStyle: {
    height: 48,
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    padding: 16,
    alignItems: 'center',
    borderBottom: `1px solid ${color.darkerWhite}`,
  },
  headingText: {
    fontSize: '17px',
    fontFamily: 'GT Walsheim',
    color: color.dark,
    letterSpacing: '0.1px',
  },
  cardTextStyle: {
    fontFamily: 'GT Walsheim Light',
    fontSize: '16px',
    marginTop: 16,
    letterSpacing: 0.15,
    lineHeight: 1.43,
    whiteSpace: 'pre-line',
    color: color.dark,
  },
  subTitleStyle: {
    fontFamily: 'GT Walsheim Regular',
    color: color.dark,
    padding: '0 16px 0 16px',
  },
  GSTinfo: {
    fontFamily: 'GT Walsheim',
    color: color.loaderBackground,
    fontSize: '14px',
    marginTop: '16px',
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: '0.15px',
  },
  textStyle: {
    textAlign: 'center',
    fontSize: 16.3,
    marginTop: 32,
    fontFamily: 'GT Walsheim light',
    color: color.dark,
  },
  downloadContentStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  documentText: {
    marginTop: '16px',
    fontSize: 16,
    fontFamily: 'GT Walsheim',
    marginLeft: '16px',
  },
  downloadButton: {
    display: 'flex',
    flexDirection: 'row',
    height: 45,
    alignItems: 'center',
    margin: '16px 6px 16px 18px',
  },
  downloadImage: {
    height: 24,
    width: 24,
  },
  downloadText: {
    fontFamily: 'GT Walsheim',
    fontSize: 16,
    letterSpacing: 0.1,
    marginLeft: 16,
    color: 'rgba(0,0,0, 0.87)',
    flex: 1,
  },
});

export interface PlanSummaryStyle extends WithStyles<typeof Styles> {}

import {
  paymentMethod,
  PaymentMethods,
} from '../../../component/wallet/savedPaymentMethods/SavedPaymentMethodsConstants';

export interface PaymentLabel {
  paymentLabel: string;
  savedPaymentLabel: string;
  creditCardLabel: string;
  debitCardLabel: string;
  bankAccountLabel: string;
  extraPayText: ExtraPayText[];
  buttonLabel: string;
  addNewLabel: string;
  paymentMethodDetails: PaymentMethods;
}

export const PAYMENT_LABELS: PaymentLabel = {
  paymentLabel: 'Payment',
  savedPaymentLabel: 'Your saved payment options',
  creditCardLabel: 'Credit card',
  debitCardLabel: 'Debit card',
  bankAccountLabel: 'Bank Account',
  extraPayText: [
    {
      type: 'Credit',
      value: (amount: string, international?: string) =>
        `*A ${amount} transaction fee applies to each Australian credit/debit card transaction${
          international
            ? `, and a ${international} transaction fee applies to each international credit/debit card transaction`
            : ''
        }`,
      zeroValueMessage: `*Credit/Debit card transactions are free`,
    },
    {
      type: 'Bank',
      value: (amount: string, international?: string) =>
        `*A ${amount} transaction fee applies to each Australian bank account transaction${
          international
            ? `, and a ${international} transaction fee applies to each international bank account transaction`
            : ''
        }`,
      zeroValueMessage: `*Bank account transactions are free`,
    },
  ],
  buttonLabel: 'Next',
  addNewLabel: 'Add new',
  paymentMethodDetails: paymentMethod,
};

export enum transactionFeeType {
  Flat = 'Flat',
  Percentage = 'Percentage',
}

export const modalLables = {
  title: 'Payment type disabled',
  subTitle:
    "Sorry, this property group doesn't accept credit card payments for rent. Please select another option.",
  buttonLabel: 'Cancel',
};

export interface ExtraPayText {
  type: string;
  value: (amount: string, international?: string) => string;
  zeroValueMessage: string;
}

export interface MatchParams {
  propertyId: string;
  serviceType: string;
  paymentRefId: string;
  serviceAccountId: string;
  agencyName: string;
}

export const LABELS = {
  NAV_TITLE: 'Select payment method',
  NAV_SUBTITLE:
    'Please select your preferred payment method. You can choose an existing payment method or add in a new one',
};

import React, { FC, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router';
import { styles, CheckoutSummaryStyles } from './CheckoutSummaryStyles';
import { LABELS } from './CheckoutSummaryConstants';
import Cart from '../cart/Cart';
import AddressHeader from '../../component/addressHeader/AddressHeader';
import CheckoutFooter from '../../component/checkoutFooter/CheckoutFooter';
import { routes } from '../../Routes';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/RootReducers';
import { Dispatch } from 'redux';
import { CheckoutRequest, Property, ServiceTypes } from '../../models/checkout/Checkout';
import { CheckoutActions } from '../../store/actions/CheckoutActions';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';
import MainContainer from '../../component/mainContainer/MainContainer';

interface CheckoutSummaryProps extends CheckoutSummaryStyles, RouteComponentProps<{}> {
  postCheckout: (data: CheckoutRequest) => void;
  property: Property;
  isTradeRequest: boolean;
  isExternal: boolean;
  requiredServiceTypes: ServiceTypes[];
}

const CheckoutSummary: FC<CheckoutSummaryProps> = ({
  classes,
  history,
  isTradeRequest,
  property,
  isExternal,
  postCheckout,
  requiredServiceTypes,
}) => {
  const [submitted, setSubmitted] = useState<boolean>(false);

  return (
    <div className={classes.root}>
      <AddressHeader address={property ? property.address : undefined} disabled={!!property} />
      <div style={{ marginTop: 50 }} />
      <MainContainer>
        <div className={classes.scrollContainer}>
          <Cart summary history={history} />
        </div>
      </MainContainer>
      <CheckoutFooter
        primaryText={submitted ? 'Processing...' : isTradeRequest ? LABELS.GET_QUOTES : LABELS.NEXT}
        disabled={submitted}
        onPrimary={() => {
          if (isTradeRequest) {
            setSubmitted(true);
            postCheckout({
              propertyId: property.id,
              checkoutServiceTypes: requiredServiceTypes,
              onSuccess: () => {
                if (isExternal) {
                  history.push(routes.checkout.completeCart);
                } else {
                  sendMessageToApp(ValidMessages.Dashboard, {
                    toHome: true,
                    showSetupCompleteToast: true,
                  });
                }
              },
              onError: () => setSubmitted(false),
            });
          } else {
            history.push(routes.checkout.finalize);
          }
        }}
        secondaryText={LABELS.BACK}
        onSecondary={() => history.push(`${routes.checkout.configure}?last=true`)}
      />
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  isTradeRequest: state.checkout.isTradeRequest,
  property: state.checkout.property,
  isExternal: state.token.isExternal,
  requiredServiceTypes: state.checkout.requiredServiceTypes,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  postCheckout: (data: CheckoutRequest) => dispatch(CheckoutActions.postCheckout(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(CheckoutSummary)));

import * as React from 'react';
import {
  Typography,
  withStyles,
  Drawer,
  Button,
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import PlanButton from '../PlanButtons';
import PlanFooter from '../PlanFooter';
import {
  CONCESSION_LABELS,
  CONCESSION_MODAL_DATA,
  TermsAndConditionsLabels,
  ModalLabels,
} from './Constants';
import { Styles, PlanScreenStyles } from './Styles';
import { PlanDetails } from '../../../../models/plan/electricity/getPlanDetails/PlanDetails.data';

export interface FooterData {
  amount: number;
  billingInfo?: string;
  extraInfo?: string;
}

interface PropsFromRoute {
  readonly history?: any;
}

interface PropsFromParent {
  readonly handleNext: (choice: string) => void;
  readonly footerData: FooterData;
  planDetails: PlanDetails;
  openDrawer: boolean;
  openModal: boolean;
  handleDrawer: (openDrawer: boolean) => void;
  handleModal: (openModal: boolean) => void;
}

interface PlanState {
  choice: number;
  showModal: boolean;
}

type OwnProps = PlanScreenStyles & PropsFromRoute & PropsFromParent;

class ConcessionScreen extends React.Component<OwnProps, PlanState> {
  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      choice: CONCESSION_LABELS.options.indexOf('no'),
      showModal: false,
    };
  }

  public render() {
    const { classes, footerData } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <div className={classes.top}>
            <Typography className={classes.title} variant="h5">
              {CONCESSION_LABELS.title}
            </Typography>
            <PlanButton
              options={CONCESSION_LABELS.options}
              label={CONCESSION_LABELS.buttonsHeading}
              info={CONCESSION_LABELS.planInfo}
              showHint
              selected={CONCESSION_LABELS.options.indexOf('no')}
              onChange={this.handleOptionChange}
              onHintClick={this.handleHelpClick}
            />
          </div>
          <PlanFooter amount={footerData.amount} onButtonClick={this.handleFooterClick} />
        </div>
        {this.renderModal()}
        {this.renderTermsAndConditionDrawer()}
        {this.props.openModal && this.renderTermsAndConditionsModal()}
      </div>
    );
  }

  private handleOptionChange = (choice: number) => {
    this.setState({ choice });
  };

  private handleFooterClick = () => {
    // TODO: Link concession flow
    this.props.handleNext(CONCESSION_LABELS.options[this.state.choice]);
  };

  private handleHelpClick = () => {
    this.toggleModal(true);
  };

  private toggleModal = (toggle: boolean) => {
    this.setState({ showModal: toggle });
  };

  private renderModal = () => {
    const { classes } = this.props;
    return (
      <Drawer
        anchor="bottom"
        open={this.state.showModal}
        classes={{
          paper: classes.bottomDrawerContainer,
        }}
        onClose={this.toggleModal.bind(this, false)}
      >
        <div className={classes.bottomDrawer}>
          <Typography className={classes.bottomDrawerTitle}>
            {CONCESSION_LABELS.drawerTitle}
          </Typography>
          <Divider className={classes.divider} />
          <Typography className={classes.terms}>{CONCESSION_MODAL_DATA.firstPara}</Typography>
          <Typography className={classes.terms}>{CONCESSION_MODAL_DATA.endPara}</Typography>
          <Button
            fullWidth
            onClick={this.toggleModal.bind(this, false)}
            className={classes.drawerButtons}
          >
            <Typography variant="body2">{CONCESSION_LABELS.drawerButton}</Typography>
          </Button>
        </div>
      </Drawer>
    );
  };
  private renderTermsAndConditionDrawer = () => {
    const { openDrawer, classes } = this.props;
    return (
      <Drawer
        anchor="bottom"
        open={openDrawer}
        classes={{
          paper: classes.bottomDrawerContainer,
        }}
        onClose={this.props.handleDrawer.bind(this, false)}
      >
        <div className={classes.bottomDrawer}>
          <Typography className={classes.bottomDrawerTitle}>
            {TermsAndConditionsLabels.title}
          </Typography>
          <Typography className={classes.drawerSubTitleStyle}>
            {TermsAndConditionsLabels.subTitle1}
          </Typography>
          <Typography className={classes.drawerSubTitleStyle}>
            {TermsAndConditionsLabels.subTitle2}
            {TermsAndConditionsLabels.subTitle3}
          </Typography>
          <Typography className={classes.drawerSubTitleStyle}>
            {TermsAndConditionsLabels.subTitle4}
          </Typography>
          <Typography className={classes.drawerSubTitleStyle}>
            {TermsAndConditionsLabels.subTitle5}
          </Typography>
          <Typography className={classes.drawerSubTitleStyle}>
            {TermsAndConditionsLabels.subTitle6}
          </Typography>
          <Typography className={classes.drawerSubTitleStyle}>
            {TermsAndConditionsLabels.subTitle7}
          </Typography>
          <div className={classes.buttonContainerStyle}>
            <Button
              fullWidth
              variant="outlined"
              onClick={this.props.handleDrawer.bind(this, false)}
              className={classes.disagreeButtonStyle}
            >
              <Typography variant="button">{TermsAndConditionsLabels.disagreeLabel}</Typography>
            </Button>
            <Button
              fullWidth
              onClick={this.props.handleDrawer.bind(this, true)}
              className={classes.agreeButtonStyle}
            >
              <Typography variant="button" className={classes.agreeButtonText}>
                {TermsAndConditionsLabels.agreeButtonLabel}
              </Typography>
            </Button>
          </div>
        </div>
      </Drawer>
    );
  };
  private renderTermsAndConditionsModal = () => {
    const { classes, openModal } = this.props;
    return (
      <Dialog
        open={openModal}
        classes={{
          paper: classes.modalStyle,
        }}
        onClose={this.props.handleModal.bind(this, false)}
      >
        <Typography className={classes.modalTitleStyle}>{ModalLabels.title}</Typography>
        <DialogContent
          classes={{
            root: classes.dialogContentStyle,
          }}
        >
          <DialogContentText className={classes.modalSubTitleStyle}>
            {ModalLabels.subTitle}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.handleModal.bind(this, false)}
            className={classes.cancelButtonStyle}
          >
            <Typography variant="button">{ModalLabels.cancelButtonLabel}</Typography>
          </Button>
          <Button
            onClick={this.props.handleModal.bind(this, true)}
            className={classes.modalButtonStyle}
          >
            <Typography variant="button" className={classes.agreeButtonText}>
              {ModalLabels.disAgreeButonLabel}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
}

export default withStyles(Styles)(ConcessionScreen);

import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../Routes';
import Setup from '../../containers/setup/Setup';

export default class CommonLayout extends React.Component {
  public render() {
    return (
      <Switch>
        <Route path={routes.setup.base(':setupType', ':propertyId?')} component={Setup} />
      </Switch>
    );
  }
}

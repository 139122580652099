import * as React from 'react';
import EmptyAddressListComponent from '../../../../component/services/broadbandService/addressList/EmptyAddressList';
import { AnyAction } from 'redux';
import { ApplicationState } from '../../../../store/RootReducers';
import { PropertyDetails } from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { connect } from 'react-redux';
import { BroadbandServiceActions } from '../../../../store/actions/BroadbandServiceActions';
import { BroadbandServiceDetails } from '../../../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import { RouteComponentProps } from 'react-router-dom';
import { History } from 'history';
import { routes } from '../../../../Routes';
import { PropertyObject } from '../../../../objectFactory/PropertyObjectFactory';
import { AddressListLabels } from '../../../../component/services/electricityService/addressForm/AddressFormConstants';
import { BackHelper } from '../../../../helper/BackHelper';

interface PropsFromState {
  property: PropertyDetails;
}

interface PropsFromRoute {
  history: History;
}
interface PropsFromDispatch {
  postProperty: (propertyId: string, data: BroadbandServiceDetails, onSuccess: () => void) => void;
}
type OwnProps = PropsFromState &
  PropsFromDispatch &
  PropsFromRoute &
  RouteComponentProps<{ propertyId: string }>;

class EmptyAddressList extends React.Component<OwnProps, {}> {
  public render() {
    return <EmptyAddressListComponent handleSubmit={this.handleSubmit} />;
  }
  private handleSubmit = () => {
    const { propertyId } = this.props.match.params;
    const { property } = this.props;
    const addressData = PropertyObject.createBroadbandPropertyDetailsObject(
      property,
      AddressListLabels.stepName,
    );
    this.props.postProperty(propertyId, addressData, this.onSucess);
  };
  private onSucess = () => {
    const { propertyId } = this.props.match.params;
    BackHelper.saveCount(-1);
    this.props.history.push(routes.broadbandService.type(propertyId));
  };
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>): PropsFromDispatch {
  return {
    postProperty: (propertyId: string, data: BroadbandServiceDetails, onSuccess: () => void) => {
      dispatch(
        BroadbandServiceActions.postBroadbandServiceStart({
          propertyId,
          serviceAccountId: null,
          data,
          onSuccess,
        }),
      );
    },
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    property: state.broadbandService.property as PropertyDetails,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmptyAddressList);

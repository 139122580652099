import { createStyles, WithStyles } from '@material-ui/core';
import { color } from '../../../theme/Theme';

export const Styles = createStyles({
  root: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  titleStyle: {
    marginTop: 10,
    paddingTop: 24,
  },
  termsSection: {
    marginRight: 20,
    paddingTop: 6,
    paddingLeft: 10,
  },
  acceptTermsdefault: {
    height: 20,
    width: 20,
    border: '2px solid #D3D3D3',
  },
  acceptedTerms: {
    height: 24,
    width: 24,
    backgroundColor: '#2d1b49',
  },
  tickImage: {
    height: 18,
    width: 18,
    display: 'flex',
    paddingLeft: 3,
    paddingTop: 3,
  },
  flexRow: {
    flex: 1,

    width: '100%',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 10,
    paddingTop: 10,
    alignItems: 'center',
  },
  button: {
    'height': 45,
    'width': '110%',
    'marginRight': -10,
    'marginLeft': -15,
    'background': color.buttonBackground,
    '&:hover': {
      backgroundColor: color.buttonBackground,
    },
  },
  disabledButtonText: {
    color: color.secondary300,
  },
  buttonText: {
    color: color.light,
  },
  acceptanceChecks: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    flexDirection: 'column',
    marginBottom: 10,
    marginLeft: -20,
  },
  cardStyle: {
    borderRadius: 8,
    padding: 0,
    marginTop: 16,
  },
  cardContentStyle: {
    'display': 'flex',
    'flexDirection': 'column',
    'padding': 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardHeaderStyle: {
    height: 48,
    backgroundColor: color.secondary,
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '16px 13px 16px 13px',
    color: color.dark,
  },
  headingSize: {
    fontSize: '16px',
    fontFamily: 'GT Walsheim Regular',
  },
  cardTextStyle: {
    fontFamily: 'GT Walsheim Regular',
    fontSize: 16.3,
    letterSpacing: 0.3,
    lineHeight: 1.23,
    color: color.dark,
  },
  textStyle: {
    textAlign: 'left',
    fontSize: 16.3,
    marginTop: 32,
    fontFamily: 'GT Walsheim light',
    color: color.dark,
  },
  listStyle: {
    marginLeft: 0,
    textAlign: 'left',
    fontSize: 16.3,
    fontFamily: 'GT Walsheim light',
    color: color.dark,
    paddingInlineStart: '25px',
  },
  subTitleStyle: {
    fontFamily: 'GT Walsheim Regular',
    color: color.dark,
    padding: 16,
  },
  buttonStyle: {
    height: 36,
    marginTop: 20,

    color: color.primary,
  },
  bottomDrawer: {
    marginTop: 24,
    padding: '0px 16px 18px 16px',
    whiteSpace: 'pre-line',
  },
  bottomDrawerContainer: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  bottomDrawerTitle: {
    fontSize: 23,
    letterSpacing: 0.3,
  },
  valueStyle: {
    fontSize: 14,
    fontFamily: 'GT Walsheim light',
    letterSpacing: 0.15,
    color: color.secondary400,
    marginTop: 16,
  },
  drawerSubtitleStyle: {
    fontSize: 16,
    letterSpacing: 0.1,
    color: 'rgba(0, 0, 0, 0.87)',
    paddingBottom: 10,
    marginTop: 29,
    borderBottom: '1px solid #D3D3D3',
  },
  text: {
    color: color.dark,
  },
  termsHeading: {
    fontFamily: 'GT Walsheim Regular',
    fontSize: '16px',
    letterSpacing: '0.15px',
    marginTop: '24px',
    color: color.secondary300,
  },
  terms: {
    fontFamily: 'GT Walsheim Light',
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: '20px',
    marginBottom: '24px',
    alignContent: 'center',
    alignItems: 'center',
  },
  drawerButtons: {
    marginTop: 16,
    background: color.primary,
    flex: 1,
    display: 'flex',
    height: 36,
  },
  drawerButtonText: {
    color: color.light,
    fontWeight: 'bold',
  },
  drawerFooter: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginTop: 20,
  },
  formRoot: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  inputStyle: {
    marginRight: 8,
  },
  chevronIcon: {
    height: 24,
    width: 24,
    marginLeft: 'auto',
  },
  section: {
    marginBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  linkHeader: {
    paddingTop: 10,
    paddingLeft: 20,
  },
  keyText: {
    fontSize: 16,
    marginRight: 10,
  },
  valueText: {
    fontSize: 16,
  },
  downloadSection: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginTop: 8,
  },
  downloadIcon: {
    height: 24,
    width: 24,
    marginLeft: 8,
  },
  downloadText: {
    font: 'GT Walsheim Regular',
    fontSize: 16,
    color: color.dark,
    marginLeft: 16,
  },
  modalSubtitle: {
    border: 'none',
    borderBottom: '1px solid #000',
    fontFamily: 'GT Walsheim',
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: '20px',
    marginBottom: '24px',
    alignContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    paddingBottom: 12,
  },
  planDetailsRoot: {
    backgroundColor: color.secondary,
  },
  planDetailsCard: {
    borderRadius: 8,
    marginTop: 16,
    border: `1px solid ${color.darkerWhite}`,
    backgroundColor: color.light,
    display: 'flex',
    flexDirection: 'column',
  },
  planDetailsCardHeader: {
    height: 48,
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    padding: 16,
    alignItems: 'center',
    borderBottom: `1px solid ${color.darkerWhite}`,
  },
  planDetailsHeadingText: {
    fontSize: '17px',
    fontFamily: 'GT Walsheim',
    color: color.dark,
    letterSpacing: '0.1px',
  },
  planDetailsCardTextStyle: {
    fontFamily: 'GT Walsheim Light',
    fontSize: '16px',
    marginTop: 16,
    letterSpacing: 0.15,
    lineHeight: 1.43,
    whiteSpace: 'pre-line',
    color: color.dark,
  },
  cardContent: {
    padding: '16px',
  },
  documentText: {
    marginTop: '16px',
    fontSize: 16,
    fontFamily: 'GT Walsheim',
    marginLeft: '16px',
  },
  downloadContentStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  downloadButton: {
    display: 'flex',
    flexDirection: 'row',
    height: 45,
    alignItems: 'center',
    margin: '16px 6px 16px 18px',
  },
  downloadImage: {
    height: 24,
    width: 24,
  },
  seperator: {
    height: 1,
    margin: 4,
    marginBottom: 20,
    background: '#eee',
  },
  switchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  blueText: {
    color: color.lightBlue,
    textDecoration: 'underline',
  },
  detailCard: {
    minHeight: 50,
    padding: 15,
    margin: 10,
    marginBottom: 18,
    borderRadius: 10,
  },
  planDetailsDownloadText: {
    fontFamily: 'GT Walsheim',
    fontSize: 16,
    letterSpacing: 0.1,
    marginLeft: 16,
    color: 'rgba(0,0,0, 0.87)',
    flex: 1,
  },
});

export interface TermsAndConditionsStyle extends WithStyles<typeof Styles> {}

export const LABELS = {};

export enum PopupType {
  PLAN,
  SUPPLIER,
  NONE,
}

export const ANIMATION_DELAY = 500;

export const SERVICE_TYPE_TITLE = {
  TechSupport: 'Tech Support',
  VerticalGardens: 'Vertical Gardens',
  PestControl: 'Pest Control',
  HomeSecurity: 'Home Security',
  BathroomKitchen: 'Bathroom/Kitchen',
  PremiumService: 'Premium Service',
};

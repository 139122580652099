/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Typography, withStyles } from '@material-ui/core';
import { get } from 'lodash';
import React, { FC, useState } from 'react';
import {
  FeatureDetails,
  SupplierOption,
  SupplierOptions,
} from '../../../../../../services/commonServices/CommonPlanSelectionInterface';
import { styles, RadioButtonOptionsStyles } from './RadioButtonOptionsStyles';
import ReactHTMLParser from 'react-html-parser';
import { color } from '../../../../../../../theme/Theme';
import linkIcon from '../../../../../../../assets/external-link.png';
import { sendMessageToApp, ValidMessages } from '../../../../../../../helper/MessageHelper';
import { getDefaultValue } from '../../../../SelectOptions/SelectOptionsUtils';

interface RadioButtonOptionsProps extends RadioButtonOptionsStyles {
  option: SupplierOptions;
  handleMultipleOptionsChange: (
    supplierOption: SupplierOptions,
    selection: SupplierOption,
    value?: string | Date,
  ) => void;
}

const RadioButtonOptions: FC<RadioButtonOptionsProps> = ({
  classes,
  option,
  handleMultipleOptionsChange,
}) => {
  return (
    <div>
      <Typography variant="h6">{get(option, 'Heading', '')}</Typography>
      <div className={classes.seperator} />
      <Typography variant="h1" className={`${classes.headerText2} ${classes.gtwLight}`}>
        {ReactHTMLParser(get(option, 'Description', ''))}
      </Typography>
      <div className={classes.optionsContainer}>
        {option.Options &&
          option.Options.length > 0 &&
          option.Options.map((selection) => {
            return (
              <div
                className={`${classes.cardContainerStyle} ${
                  selection.selected ? classes.selectedCardContainerStyle : undefined
                }`}
                onClick={() =>
                  handleMultipleOptionsChange(option, selection, get(selection, 'Label', ''))
                }
              >
                {selection.UiElements.ImageCentered ? (
                  <div className={classes.imageContainerCentered}>
                    <img
                      className={classes.imageCentered}
                      src={selection.UiElements.ImageCentered}
                      alt=""
                    />
                  </div>
                ) : (
                  <div className={classes.imageContainer}>
                    <img className={classes.image} src={selection.UiElements.Image} alt="" />
                  </div>
                )}
                <div className={classes.content}>
                  <div className={classes.title}>{selection.UiElements.Title}</div>
                  {selection.UiElements.Subtitle && (
                    <div className={classes.subtitle}>{selection.UiElements.Subtitle}</div>
                  )}
                  <div className={classes.line} />
                  {selection.UiElements.Price && (
                    <div className={classes.priceRow}>
                      <div className={classes.price}>{selection.UiElements.Price}</div>
                      <div className={classes.uom}>{selection.UiElements.Uom}</div>
                    </div>
                  )}
                  <div className={classes.body}>{ReactHTMLParser(selection.UiElements.Body)}</div>
                </div>
                {selection.UiElements.Link && (
                  <div
                    className={classes.viewMore}
                    onClick={() => {
                      window.open(selection.UiElements.Link);
                    }}
                  >
                    <div className={classes.viewMoreText}>Read more</div>
                    <img src={linkIcon} className={classes.link} alt="" />
                  </div>
                )}
                <div className={selection.selected ? classes.primaryButton : classes.outlineButton}>
                  {selection.selected ? 'Selected' : 'Select'}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default withStyles(styles)(RadioButtonOptions);

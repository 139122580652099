export const dateValidator = (expiryDate: string, type: string) => {
  const date = expiryDate.split('/');
  const expiryMonth = parseInt(date[0], 10);
  const expiryYear = parseInt(date[1], 10);

  const currentYear = new Date().getFullYear() % 100;
  let currentMonth = new Date().getMonth();
  switch (type) {
    case 'paymentCard': {
      currentMonth = new Date().getMonth() + 1;
      break;
    }
    case 'concessionCard': {
      currentMonth = new Date().getMonth() + 4;
      break;
    }
  }
  if (expiryYear < currentYear) {
    return true;
  } else if (expiryYear === currentYear && expiryMonth < currentMonth) {
    return true;
  } else if (expiryMonth > 12) {
    return true;
  }
  return false;
};

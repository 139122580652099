//TODO put all the mock related code into respected mock service
// import { leaseList } from '../__mocks__/LeaseMock';
import { GetLeaseResponse } from './getLease/GetLeaseResponse.data';

import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import { QueryPath } from '../QueryPath.data';

// const leasePromise = new Promise<GetLeaseResponse>((resolve, reject) => {
//   resolve(leaseList);
// });

export class LeaseService {
  //TODO uncomment when api is ready
  public getLease(): Promise<GetLeaseResponse> {
    const service = new ApiService(ServiceType.Payment);
    const path: QueryPath = {
      route: ['onboard/pendingTenancies'],
      query: null,
    };
    return service.get(path);
    // return leasePromise;
  }
}

const leaseService = new LeaseService();
export default leaseService;

import { color } from '../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';
import { SIZES } from '../../Constants';

export const styles = createStyles({
  root: {
    'paddingTop': '48px',
    'backgroundColor': color.secondary,
    'boxSizing': 'border-box',
    'scrollbarWidth': 'none',
    'msOverflowStyle': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  contentContainer: {
    [`@media (max-width: ${SIZES.md}px)`]: {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
  paperBack: {
    margin: 'auto',
    maxWidth: '1000px',
    borderRadius: '6px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
    overflow: 'hidden',
    position: 'relative',
  },
  backgroundContainer: {
    height: '280px',
    maxHeight: '280px',
    width: '100%',
    position: 'relative',
    display: 'flex',
    backgroundColor: color.headerColor,
    marginTop: 'auto',
  },
  backgroundIconWrapper: {
    margin: 'auto',
    marginTop: '40px',
    maxWidth: '1000px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '80px',
    paddingRight: '80px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
  backgroundIcon: {
    height: '80px',
  },
  cardContentContainer: {
    padding: '15px',
  },
  title: {
    color: color.primary,
    fontFamily: 'GT Walsheim',
    fontSize: 30,
    marginBottom: 15,
  },
  successTitle: {
    color: color.primary,
    fontFamily: 'GT Walsheim',
    fontSize: 28,
    marginBottom: 5,
  },
  text: {
    fontSize: '16px',
    color: color.black87,
    fontFamily: 'GT Walsheim Light',
    lineHeight: 1.5,
    marginBottom: 15,
  },
  subtitle: {
    fontSize: 28,
    color: color.primary,
    marginTop: '30px',
  },
  listItem: {
    fontSize: '18px',
    marginTop: '10px',
    opacity: 0.5,
    fontWeight: 100,
    fontFamily: 'GT Walsheim',
  },
  bottomContentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    minHeight: '400px',
    position: 'relative',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      minHeight: '300px',
    },
  },
  leftButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    height: '100%',
    zIndex: 4,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flexDirection: 'column',
    },
  },
  button: {
    marginBottom: '30px',
    marginLeft: '30px',
    height: '60px',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      height: '50px',
    },
  },
  rightContent: {
    position: 'absolute',
    right: 0,
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
  rightBgImgContainer: {
    width: '400px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  rightBgImg: {
    width: '100%',
  },
  rightImgContainer: {
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    right: 15,
    height: '100%',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      height: '90%',
    },
  },
  rightImg: {
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'bottom',
  },
  primaryButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 40,
    color: color.light,
    textAlign: 'center',
    fontFamily: 'GT Walsheim',
    fontSize: 15,
    border: `${color.buttonBackgroundSecondary} 2px solid`,
    backgroundColor: color.buttonBackgroundSecondary,
    borderRadius: 6,
  },
  back: {
    fontSize: '15px',
    marginTop: '15px',
    marginBottom: '15px',
    fontFamily: 'GT Walsheim',
    width: '100%',
    textAlign: 'center',
  },
});

export interface AbandonCartStyles extends WithStyles<typeof styles> {}

import React, { FC, useMemo, useEffect, useState, useRef } from 'react';
import { MenuItem, OutlinedInput, Select, withStyles } from '@material-ui/core';
import { History } from 'history';
import { styles, CartStyles } from './CartStyles';
import { LABELS } from './CartConstants';
import closeIcon from '../../assets/close.png';
import { ApplicationState } from '../../store/RootReducers';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { CheckoutActions } from '../../store/actions/CheckoutActions';
import { PromoCode } from '../../models/checkout/Checkout';
import CartItem from './components/CartItem/CartItem';
import tick from '../../assets/blueTick.png';
import downArrow from '../../assets/downArrow.png';
import emptyCart from '../../assets/empty-cart.png';
import chatMessageBlack from '../../assets/chat-black.svg';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';
import { useCart } from '../../helper/CheckoutHelper';
import { FooterPricing } from '../services/commonServices/CommonPlanSelectionInterface';
import { getFrequency } from '../checkoutSelect/components/Plan/PlanUtils';
import { getProductDetails } from '../checkoutConfigure/components/SelectOptions/SelectOptionsUtils';
import { dollarFormatter } from '../../helper/CostHelper';
import { routes } from '../../Routes';
import { getMonthlyDefault } from './CartUtils';

interface CartProps extends CartStyles {
  history: History;
  summary?: boolean;
  configure?: boolean;
  promoCode: PromoCode | undefined;
  isTradeRequest: boolean;
  toggleCart: () => void;
}

const Cart: FC<CartProps> = ({
  classes,
  history,
  summary,
  configure,
  promoCode,
  isTradeRequest,
  toggleCart,
}) => {
  const { cartItems, cartCount, cartPricing, feeItems, feeItemsHelperTextList } = useCart();
  const [frequency, setFrequency] = useState<string>(getMonthlyDefault(cartPricing));
  const estimateCostRef = useRef<HTMLDivElement>(null);
  const [estimateCostHeight, setEstimateCostHeight] = useState<number>(0);

  const totalCostRef = useRef<HTMLDivElement>(null);
  const otherChargesRef = useRef<HTMLDivElement>(null);
  const [totalCostHeight, setTotalCostHeight] = useState<number>(0);
  const [otherChargesHeight, setOtherChargesHeight] = useState<number>(0);

  const estimateTotal = useMemo<number>(() => {
    let result = 0;
    cartItems.map((item) =>
      item.suppliers!.map((supplier) =>
        supplier.plans.map((plan) => {
          result += Number(
            getProductDetails(supplier.extendedData!, plan.selectedProductId).productOptions[
              frequency
            ],
          );
        }),
      ),
    );
    return result;
  }, [cartItems]);

  useEffect(() => {
    document.addEventListener('backbutton', () => toggleCart());
    return () => document.removeEventListener('backbutton', () => null);
  }, []);

  return (
    <>
      {!summary && (
        <div className={classes.popupHeader}>
          <div className={classes.popupTitle}>{LABELS.TITLE}</div>
          <div className={classes.popupActionContainer} onClick={() => toggleCart()}>
            <img className={classes.popupAction} src={closeIcon} alt="closeIcon" />
          </div>
        </div>
      )}
      {isTradeRequest === false && cartPricing && cartPricing.length > 1 ? (
        <div
          className={classes.showPricingContainer}
          style={summary ? { paddingTop: 15, backgroundColor: 'white' } : {}}
        >
          <div className={classes.estimateLabel}>{LABELS.ESTIMATED}</div>
          <Select
            className={classes.selectFrequency}
            input={<OutlinedInput labelWidth={0} />}
            onChange={(e) => {
              setTimeout(() => {
                (document!.activeElement! as HTMLInputElement).blur();
              }, 0);
              {
                cartPricing.map((footerPricing: FooterPricing) => {
                  if (footerPricing.PriceField === e.target.value) {
                    setFrequency(e.target.value);
                  }
                });
              }
            }}
            value={frequency}
          >
            {cartPricing.map((footerPricing: FooterPricing) => {
              return (
                <MenuItem style={{ zIndex: 999999999 }} value={footerPricing.PriceField}>
                  {footerPricing.UnitOfMeasure}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      ) : undefined}

      {/* TODO: Empty cart */}
      <div
        className={classes.cartScroll}
        style={summary ? { backgroundColor: 'white', paddingBottom: 0 } : {}}
      >
        <div className={classes.cartContainer}>
          {!!cartItems.length ? (
            cartItems.map((cartItem) => (
              <CartItem
                hideAction={summary}
                cartItem={cartItem}
                frequency={frequency}
                key={`${cartItem.type}`}
              />
            ))
          ) : (
            <div className={classes.emptyCartContainer}>
              <div className={classes.emptyCartIconContainer}>
                <img className={classes.emptyCartIcon} src={emptyCart} alt="empty cart" />
              </div>
              <div className={classes.emptyCartTitle}>{LABELS.EMPTY_CART_TITLE}</div>
              <div className={classes.emptyCartText}>{LABELS.EMPTY_CART_TEXT}</div>
            </div>
          )}
          {!!cartItems.length && <div className={classes.break} />}
          {/* {promoCode && (
            <div className={classes.promoCodeContainer}>
              <div className={classes.promoTextContainer}>
                <div className={classes.promoCode}>{promoCode.title}</div>
                <div className={classes.promoSubtext}>
                  {`$${promoCode.value.toFixed(2)} applied!`}
                </div>
              </div>
              <div className={classes.promoIconContainer}>
                <img className={classes.promoIcon} src={tick} alt="tick" />
              </div>
            </div>
          )} */}
        </div>
        {!!cartItems.length && isTradeRequest && (
          <div className={classes.summaryContainer}>
            <div className={classes.summaryRow}>
              <div className={classes.summaryBlackKey}>Summary</div>
            </div>
            <div className={classes.content}>
              <>
                {cartItems.map((item) =>
                  item.suppliers!.map((supplier) =>
                    supplier.plans.map((plan) => (
                      <>
                        <div className={classes.summaryRow} style={{ marginTop: 30 }}>
                          <div className={classes.summaryBlackValue}>
                            {supplier.name} - {plan.title}
                          </div>
                        </div>
                        {plan
                          .cartData!.planDetails.featureDetails!.filter((f) => f.type === 'answers')
                          .filter((data) => data.labelId !== 'MediaUpload')
                          .map((f) => (
                            <div className={classes.summaryRow} style={{ marginTop: 15 }}>
                              <div className={classes.summaryGreyKey}>{f.label!}</div>
                              <div className={classes.summaryBlackValue}>{f.value}</div>
                            </div>
                          ))}
                      </>
                    )),
                  ),
                )}
              </>
            </div>
          </div>
        )}
        {!!cartItems.length && isTradeRequest === false && (
          <>
            <div className={classes.summaryContainer}>
              <div className={classes.summaryRow}>
                <div className={classes.summaryBlackKey}>
                  {!!cartItems.filter(
                    (item) => item.suppliers![0].plans[0].pricingType === 'Estimated',
                  ).length && 'Estimated '}
                  {getFrequency(frequency)} cost
                </div>
                <div className={classes.summaryBlueValue}>{`$${estimateTotal.toFixed(2)}`}</div>
              </div>
              <div
                className={classes.content}
                ref={estimateCostRef}
                style={{
                  maxHeight: `${estimateCostHeight}px`,
                  opacity: estimateCostHeight > 0 ? 1 : 0,
                }}
              >
                <>
                  {cartItems.map((item) =>
                    item.suppliers!.map((supplier) =>
                      supplier.plans.map((plan) => (
                        <div className={classes.summaryRow} style={{ marginTop: 15 }}>
                          <div className={classes.summaryGreyKey}>
                            {item.type} - {supplier.name}
                          </div>
                          <div className={classes.summaryBlackValue}>
                            {dollarFormatter(
                              getProductDetails(supplier.extendedData!, plan.selectedProductId)
                                .productOptions[frequency],
                            )}
                          </div>
                        </div>
                      )),
                    ),
                  )}
                </>
              </div>
            </div>
            <div
              className={
                estimateCostHeight > 0 ? classes.actionContainerHide : classes.actionContainer
              }
              onClick={() => {
                setEstimateCostHeight(
                  estimateCostHeight === 0 ? estimateCostRef.current!.scrollHeight : 0,
                );
              }}
            >
              <div className={classes.actionTitle}>
                {estimateCostHeight > 0 ? LABELS.HIDE : LABELS.SHOW}
              </div>
              <div
                className={`${classes.actionIconContainer} ${
                  estimateCostHeight > 0 && classes.rotate
                }`}
              >
                <img className={classes.actionIcon} src={downArrow} alt="status" />
              </div>
            </div>

            {/* --------------------------------------------------------------------------------------- */}
            {!!feeItems.filter(
              (feeItem) => feeItem.Type === 'MinimumFee' || feeItem.Type === 'Setup',
            ).length &&
              isTradeRequest === false && (
                <>
                  <div className={classes.summaryContainer} style={{ marginTop: 15 }}>
                    <div className={classes.summaryRow}>
                      <div className={classes.summaryBlackKey}>
                        Estimated connection or set up fees
                      </div>
                    </div>
                    <div className={classes.summaryGreyKey} style={{ marginTop: 5 }}>
                      These charges will be confirmed and usually appear on your first bill
                    </div>
                    <div
                      className={classes.content}
                      ref={totalCostRef}
                      style={{
                        maxHeight: `${totalCostHeight}px`,
                        opacity: totalCostHeight > 0 ? 1 : 0,
                      }}
                    >
                      <>
                        {feeItems.map((feeItem, index) => (
                          <>
                            {feeItem.Type === 'Setup' ? (
                              <div className={classes.summaryRow} style={{ marginTop: 15 }}>
                                <div
                                  className={classes.summaryGreyKey}
                                >{`${feeItem.ServiceType!} - ${feeItem.FeeItems[0].Label}`}</div>
                                <div className={classes.summaryBlackValue}>{`$${
                                  feeItem.FeeItems[0].Rate ? feeItem.FeeItems[0].Rate.toFixed(2) : 0
                                }`}</div>
                              </div>
                            ) : null}
                          </>
                        ))}
                        {!!feeItems.filter((feeItem) => feeItem.Type === 'MinimumFee').length && (
                          <div className={classes.summaryRow} style={{ marginTop: 15 }}>
                            <div className={classes.summaryBlackKey}>Early termination fees</div>
                          </div>
                        )}
                        {feeItems.map((feeItem, index) => (
                          <>
                            {feeItem.Type === 'MinimumFee' ? (
                              <div className={classes.summaryRow} style={{ marginTop: 15 }}>
                                <div
                                  className={classes.summaryGreyKey}
                                >{`${feeItem.ServiceType!} - ${feeItem.FeeItems[0].Label}`}</div>
                                <div className={classes.summaryBlackValue}>{`$${
                                  feeItem.FeeItems[0].Rate ? feeItem.FeeItems[0].Rate.toFixed(2) : 0
                                }`}</div>
                              </div>
                            ) : null}
                          </>
                        ))}
                      </>
                    </div>
                  </div>
                  <div
                    className={
                      totalCostHeight > 0 ? classes.actionContainerHide : classes.actionContainer
                    }
                    onClick={() => {
                      setTotalCostHeight(
                        totalCostHeight === 0 ? totalCostRef.current!.scrollHeight : 0,
                      );
                    }}
                  >
                    <div className={classes.actionTitle}>
                      {totalCostHeight > 0 ? LABELS.HIDE : LABELS.SHOW}
                    </div>
                    <div
                      className={`${classes.actionIconContainer} ${
                        totalCostHeight > 0 && classes.rotate
                      }`}
                    >
                      <img className={classes.actionIcon} src={downArrow} alt="status" />
                    </div>
                  </div>

                  {totalCostHeight > 0 &&
                    feeItemsHelperTextList.map((helperText, index) => (
                      <>
                        {helperText ? (
                          <div className={classes.disclamerText}>{helperText}</div>
                        ) : null}
                      </>
                    ))}
                </>
              )}

            {/* --------------------------------------------------------------------------------------- */}

            {/* --------------------------------------------------------------------------------------- */}
            {!!feeItems.filter((feeItem) => feeItem.Type === 'OtherCharges').length &&
              isTradeRequest === false && (
                <>
                  <div className={classes.summaryContainer} style={{ marginTop: 15 }}>
                    <div className={classes.summaryRow}>
                      <div className={classes.summaryBlackKey}>Other charges</div>
                    </div>
                    <div
                      className={classes.content}
                      ref={otherChargesRef}
                      style={{
                        maxHeight: `${otherChargesHeight}px`,
                        opacity: otherChargesHeight > 0 ? 1 : 0,
                      }}
                    >
                      <>
                        {feeItems.map((feeItem, index) => (
                          <>
                            {feeItem.Type === 'OtherCharges' ? (
                              <div style={{ marginTop: 15 }}>
                                <div className={classes.summaryBlackKey} style={{ marginTop: 5 }}>
                                  {`${feeItem.ServiceType!}`}
                                </div>
                                <div className={classes.summaryGreyKey} style={{ marginTop: 5 }}>
                                  {`${
                                    feeItem.ChargesFeesText ||
                                    'These charges will be confirmed and may appear on your first bill'
                                  }`}
                                </div>
                                <div className={classes.summaryRow} style={{ marginTop: 5 }}>
                                  <div className={classes.summaryGreyKey}>
                                    {`${feeItem.FeeItems[0].Label}`}
                                  </div>
                                  <div className={classes.summaryBlackValue}>
                                    {`$${
                                      feeItem.FeeItems[0].Rate
                                        ? feeItem.FeeItems[0].Rate.toFixed(2)
                                        : 0
                                    }`}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </>
                        ))}
                      </>
                    </div>
                  </div>
                  <div
                    className={
                      otherChargesHeight > 0 ? classes.actionContainerHide : classes.actionContainer
                    }
                    onClick={() => {
                      setOtherChargesHeight(
                        otherChargesHeight === 0 ? otherChargesRef.current!.scrollHeight : 0,
                      );
                    }}
                  >
                    <div className={classes.actionTitle}>
                      {otherChargesHeight > 0 ? LABELS.HIDE : LABELS.SHOW}
                    </div>
                    <div
                      className={`${classes.actionIconContainer} ${
                        otherChargesHeight > 0 && classes.rotate
                      }`}
                    >
                      <img className={classes.actionIcon} src={downArrow} alt="status" />
                    </div>
                  </div>
                </>
              )}

            {/* --------------------------------------------------------------------------------------- */}
          </>
        )}
        {/* TODO disabled and summary state */}
        {!summary && !configure && (
          <>
            <div
              className={classes.buttonContainer}
              onClick={() => {
                history.push(routes.checkout.configure);
                toggleCart();
              }}
            >
              <div className={classes.primaryButton}>{LABELS.CHECKOUT}</div>
            </div>
            <div className={classes.needSupport}>{LABELS.NEED_SUPPORT}</div>
            <div
              className={classes.supportRow}
              onClick={() => sendMessageToApp(ValidMessages.Chat)}
            >
              <img className={classes.supportIcon} src={chatMessageBlack} alt="chat" />
              <div className={classes.supportText}>{LABELS.CHAT}</div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  promoCode: state.checkout.promoCode,
  isTradeRequest: state.checkout.isTradeRequest,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleCart: () => dispatch(CheckoutActions.toggleCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Cart));

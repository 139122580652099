import * as React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import PlanButton from '../PlanButtons';
import PlanFooter from '../PlanFooter';
import { LABELS_VOICE, getDataExtraInfo, LABELS_MODEM } from './Constants';
import { Styles, PlanScreenStyles } from '../electricity/Styles';
import { routes } from '../../../../Routes';
import { BroadbandPlanDetails } from '../../../../models/plan/broadband/getPlanDetails/BroadbandPlan.data';
import PlanHelper from '../../../../helper/PlanHelper';
import {
  BroadbandPlanSubmit,
  Modem,
} from '../../../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import { BroadbandTypes } from '../../../../containers/services/ServiceConstants';
import { BroadbandPlanState } from '../../../../store/state/BroadbandPlanState';

interface PropsFromRoute {
  readonly history?: any;
}

interface PropsFromParent {
  readonly planDetails: BroadbandPlanDetails;
  readonly handleNext: (choice: string) => void;
  readonly plan: BroadbandPlanState;
}

interface PlanState {
  choice: number;
}

type OwnProps = PlanScreenStyles & PropsFromRoute & PropsFromParent;

class VoiceScreen extends React.Component<OwnProps, PlanState> {
  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      choice: LABELS_VOICE.options.indexOf('NO'),
    };
  }

  public render() {
    const { classes } = this.props;
    const footerValue = this.filterFooterValue();
    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <div className={classes.top}>
            <Typography className={classes.title} variant="h5">
              {LABELS_VOICE.title}
            </Typography>
            {this.props.planDetails.broadbandType === BroadbandTypes.nbn ? (
              <PlanButton
                options={LABELS_VOICE.options}
                label={LABELS_VOICE.buttonsHeading}
                selected={LABELS_VOICE.options.indexOf('NO')}
                onChange={this.handleOptionChange}
              />
            ) : (
              <PlanButton
                options={[]}
                label={LABELS_VOICE.adslButtonsHeading}
                info={LABELS_VOICE.adslInfo}
                onChange={this.handleOptionChange}
              />
            )}
          </div>
          <PlanFooter
            amount={`${footerValue}`}
            extraInfo={this.getExtraInfo()}
            onButtonClick={this.handleFooterClick}
          />
        </div>
      </div>
    );
  }

  private getExtraInfo = () => {
    const { plan, planDetails } = this.props;
    const voice =
      planDetails.broadbandType === BroadbandTypes.adsl ? false : this.state.choice === 0;
    const productName = PlanHelper.getProductName(
      planDetails.productNames,
      plan.dataPlan,
      plan.downloadPlan as string,
      voice,
    );
    const productInfo: BroadbandPlanSubmit = PlanHelper.getProductDetails(
      planDetails.plan,
      productName,
    );
    const modemInfo = PlanHelper.getModemDetails(planDetails.plan);
    const modemCost =
      plan.modem === LABELS_MODEM.modemLabel ? 0 : modemInfo && (modemInfo as Modem).monthlyCost;
    let connectionFeeAmount = 0;
    if (planDetails.broadbandType === BroadbandTypes.adsl) {
      connectionFeeAmount = productInfo && parseInt(productInfo.setupFeeContractYes as string, 10);
    } else if (planDetails.telcoService.nbnDevelopmentFee && productInfo.connectionFeeAmount) {
      connectionFeeAmount = productInfo.connectionFeeAmount;
    }
    const totalAmount = (modemCost as number) + parseInt(`${connectionFeeAmount}`, 10);
    if (connectionFeeAmount || modemCost) {
      return getDataExtraInfo(`${totalAmount}`, true);
    } else {
      return null;
    }
  };

  private filterFooterValue() {
    const { plan, planDetails } = this.props;
    const downloadPlan =
      planDetails.broadbandType === BroadbandTypes.adsl ? '' : (plan.downloadPlan as string);
    const voice =
      planDetails.broadbandType === BroadbandTypes.adsl ? false : this.state.choice === 0;
    const productName = PlanHelper.getProductName(
      planDetails.productNames,
      plan.dataPlan,
      downloadPlan,
      voice,
    );
    const productInfo: BroadbandPlanSubmit = PlanHelper.getProductDetails(
      planDetails.plan,
      productName,
    );
    return productInfo && productInfo.productDetails && productInfo.productDetails.monthlyCost;
  }

  private handleOptionChange = (choice: number) => {
    this.setState({ choice });
  };

  private handleFooterClick = () => {
    this.props.history.push(
      routes.broadbandService.plan.contractSelection((this.props as any).match.params.propertyId),
    );
    this.props.handleNext(LABELS_VOICE.options[this.state.choice]);
  };
}

export default withStyles(Styles)(VoiceScreen);

import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/RootReducers';
import { Dispatch, AnyAction } from 'redux';
import {
  IdDetails,
  PersonalDetails,
  ServiceAccount,
} from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { BroadbandServiceDetails } from '../../../../services/broadbandService/getBroadbandService/GetBroadbandService.data';

import { BroadbandServiceActions } from '../../../../store/actions/BroadbandServiceActions';
import PersonalDetailsUI, {
  InitialValues,
} from '../../../../component/services/personalDetails/PersonalDetails';
import { routes } from '../../../../Routes';
import { History } from 'history';
import { format } from 'date-fns';
import { RouteComponentProps } from 'react-router-dom';
import { BackHelper } from '../../../../helper/BackHelper';

interface PropsFromState {
  service: BroadbandServiceDetails;
  serviceAccountId: number;
}

interface PropsFromRoute {
  history: History;
}

interface PropsFromDispatch {
  postPersonalDetails: (
    propertyId: string,
    serviceAccountId: string,
    personalDetails: BroadbandServiceDetails,
    onSuccess: () => void,
  ) => void;
}

type OwnProps = PropsFromDispatch &
  PropsFromState &
  PropsFromRoute &
  RouteComponentProps<{
    propertyId: string;
    serviceSummary: string;
    paymentRefId: string;
  }>;

class PersonalDetail extends React.Component<OwnProps> {
  public componentDidMount() {
    const { propertyId, paymentRefId } = this.props.match.params;
    const { serviceAccountId } = this.props;
    BackHelper.saveParams({
      propertyId,
      paymentRefId,
      serviceAccountId: serviceAccountId.toString(),
    });
  }
  public render() {
    return (
      <PersonalDetailsUI
        personalDetails={this.props.service.serviceDetails.personalDetails as PersonalDetails}
        handleSave={this.handleSave}
      />
    );
  }

  private handleSave = (personalDetails: InitialValues) => {
    const { propertyId } = this.props.match.params;
    const { serviceAccountId } = this.props;
    const fetchedDetails = this.props.service.serviceDetails.personalDetails as PersonalDetails;
    const idDetails: IdDetails = {
      dlStateIssue: personalDetails.stateIssue,
      medicareRefNumber: personalDetails.referenceNumber,
      medicareCardColor: personalDetails.medicareCardColor,
      passportCountry: personalDetails.countryOfIssue,
    };
    const postData: BroadbandServiceDetails = {
      serviceDetails: {
        personalDetails: {
          id: fetchedDetails.id,
          name: personalDetails.firstName,
          surname: personalDetails.lastName,
          email: personalDetails.email,
          phone: personalDetails.mobile,
          salutation: personalDetails.title,
          dob: format(personalDetails.dob as Date, 'dd/MM/yyyy'),
          idType: personalDetails.idType,
          idNumber: personalDetails.idNumber,
          userType: fetchedDetails.userType,
          address: fetchedDetails.address,
          idExpiry: fetchedDetails.idExpiry,
          active: fetchedDetails.active,
          branches: fetchedDetails.branches,
          idDetails,
        },
      },
      step: 'PERSONAL_DETAILS',
    };
    this.props.postPersonalDetails(
      propertyId,
      serviceAccountId.toString(),
      postData,
      this.onSuccess,
    );
  };
  private onSuccess = () => {
    const { propertyId, serviceSummary } = this.props.match.params;
    BackHelper.saveCount(-1);
    this.props.history.push(routes.broadbandService.termsAndConditions(propertyId, serviceSummary));
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  const service = state.broadbandService.service as BroadbandServiceDetails;
  return {
    service,
    serviceAccountId: (
      (state.broadbandService.service as BroadbandServiceDetails).serviceAccount as ServiceAccount
    ).id as number,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): PropsFromDispatch {
  return {
    postPersonalDetails: (
      propertyId: string,
      serviceAccountId: string,
      personalDetails: BroadbandServiceDetails,
      onSuccess: () => void,
    ) => {
      dispatch(
        BroadbandServiceActions.postBroadbandServiceStart({
          propertyId,
          serviceAccountId,
          data: personalDetails,
          onSuccess,
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetail);

import { ConnectionDateAction, ConnectionDateActionTypes } from '../actions/ConnectionDateActions';
import { ConnectionDateState } from '../state/ConnectionDateState';

export const initialState: ConnectionDateState = {
  connectionDate: '',
  moving: false,
};

export const ConnectionDateReducer = (state = initialState, action: ConnectionDateAction) => {
  switch (action.type) {
    case ConnectionDateActionTypes.SaveConnectionDate:
      return {
        connectionDate: action.data && action.data.connectionDate,
        moving: action.data && action.data.moving,
      };
    default:
      return state;
  }
};

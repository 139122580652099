import * as React from 'react';
import SavedPaymentMethodsComponent from '../../../component/wallet/savedPaymentMethods/SavedPaymentMethods';
import { ApplicationState } from '../../../store/RootReducers';
import { GetPaymentMethodsResponse } from '../../../services/payment/getPaymentMethods/GetPaymentMethods.data';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { WalletActions } from '../../../store/actions/WalletActions';
import { History } from 'history';
import { routes } from '../../../Routes';
import { walletLabels, PaymentMethodType } from '../../../component/wallet/WalletConstants';
import { RouteComponentProps } from 'react-router-dom';
import { PaymentActions } from '../../../store/actions/PaymentActions';
import {
  GetFastPayResponse,
  PayIdPayLoad,
} from '../../../services/payment/getFastPayIDResponse/GetFastPayIdResponse';

interface PropsFromState {
  payment: GetPaymentMethodsResponse[];
  showSuccessMessage: boolean;
  accessToken: string | boolean | null | null;
  userId: string;
  payId: GetFastPayResponse | null;
  appName: string | boolean | null;
}

interface PropsFromDispatch {
  getPaymentMethods: () => void;
  saveSelectedPayment: (payment: GetPaymentMethodsResponse) => void;
  requestFastPayIdDetails: (payment: any) => void;
  saveHeaderLabel: (label: string) => void;
}

interface PropsFromRoute {
  history: History;
}

type OwnProps = PropsFromDispatch &
  PropsFromState &
  PropsFromRoute &
  RouteComponentProps<{ serviceType: string }>;
class SavedPaymentMethods extends React.Component<OwnProps, {}> {
  private waitForAccessToken = 0;

  public componentDidMount() {
    const { accessToken } = this.props;
    if (accessToken) {
      this.getPaymentDetails();
      const payIdpayLoad: PayIdPayLoad = { userId: this.props.userId };
      this.props.requestFastPayIdDetails(payIdpayLoad);
    }
  }

  public componentDidUpdate() {
    const { accessToken } = this.props;
    if (accessToken && this.waitForAccessToken === 0) {
      this.getPaymentDetails();
      const payIdpayLoad: PayIdPayLoad = { userId: this.props.userId };
      this.props.requestFastPayIdDetails(payIdpayLoad);
    }
  }

  public render() {
    return (
      <div style={{ backgroundColor: 'white', height: window.innerHeight }}>
        <SavedPaymentMethodsComponent
          payment={this.props.payment}
          payId={this.props.payId}
          history={this.props.history}
          handleOptions={this.handleOptions}
          showSuccessMessage={this.props.showSuccessMessage}
          handleAddPaymentOption={this.handleAddPaymentOption}
          appName={this.props.appName}
        />
      </div>
    );
  }
  public handleOptions = (payment: GetPaymentMethodsResponse) => {
    const { serviceType } = this.props.match.params;
    this.props.saveSelectedPayment(payment);
    switch (payment.paymentMethod) {
      case PaymentMethodType.Bank:
        this.props.history.push(routes.wallet.bankDetails(serviceType));
        break;
      case PaymentMethodType.Credit:
        this.props.history.push(routes.wallet.cardDetails(serviceType));
        break;
    }
  };

  private getPaymentDetails() {
    this.waitForAccessToken = 1;
    this.props.saveHeaderLabel(walletLabels.PAYMENT_TITLE);
    this.props.getPaymentMethods();
  }

  private handleAddPaymentOption = () => {
    const { serviceType } = this.props.match.params;
    this.props.history.push(routes.payment.list.empty('propertyId', serviceType));
  };
}
function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    payment: state.wallet.payment,
    showSuccessMessage: state.wallet.showSuccessMessage,
    accessToken: state.token.accessToken,
    userId: state.token.userId as string,
    payId: state.payment.paymentPayId,
    appName: state.token.appName,
  };
}

function mapDispatchFromProps(dispatch: React.Dispatch<AnyAction>): PropsFromDispatch {
  return {
    getPaymentMethods: () => {
      dispatch(WalletActions.getPaymentMethodsStart());
    },
    requestFastPayIdDetails: (payment: any) => {
      dispatch(PaymentActions.requestFastPayIdDetails(payment));
    },
    saveSelectedPayment: (payment: GetPaymentMethodsResponse) => {
      dispatch(WalletActions.saveSelectedPayment(payment));
    },
    saveHeaderLabel: (label: string) => {
      dispatch(WalletActions.saveHeaderLabel(label));
    },
  };
}

export default connect(mapStateToProps, mapDispatchFromProps)(SavedPaymentMethods);

import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import OverView from '../../containers/services/electricityService/OverView';
import { routes } from '../../Routes';
import AddressForm from '../../containers/services/electricityService/addressForm/AddressForm';
import ConcessionCardForm from '../../containers/services/plan/electricity/ConcessionCard';
import PlanScreen from '../../containers/services/plan/electricity/PlanScreen';
import SupportScreen from '../../containers/services/plan/electricity/SupportScreen';
import ConcessionScreen from '../../containers/services/plan/electricity/ConcessionScreen';
import TermsAndConditions from '../../containers/services/termsAndConditions/TermsAndConditions';
import DateConfirmation from '../../containers/services/electricityService/connectionDate/DateConfirmation';
import PersonalDetails from '../../containers/services/personalDetails/PersonalDetails';
import PowerConnection from '../../containers/services/electricityService/powerConnection/PowerConnection';
import ConcessionCardOptions from '../../containers/services/plan/electricity/ConcessionCardOptions';
import AddressList from '../../containers/services/electricityService/addressForm/AddressList';
import EmptyAddressList from '../../containers/services/electricityService/addressForm/EmptyAddressList';
import PlanSummary from '../../containers/services/electricityService/planSummary/PlanSummary';

export default class ElectricityServiceLayout extends React.Component {
  public render() {
    return (
      <Switch>
        <Route
          path={routes.service.new(':propertyId', ':serviceAccountId?')}
          exact
          component={OverView}
        />
        <Route path={routes.service.address.form(':propertyId')} exact component={AddressForm} />
        <Route path={routes.service.address.list(':propertyId')} exact component={AddressList} />
        <Route
          path={routes.service.address.emptyList(':propertyId')}
          exact
          component={EmptyAddressList}
        />
        <Route
          path={routes.service.concessionCard.base(':propertyId')}
          component={ConcessionCardOptions}
        />
        <Route
          path={routes.service.concessionCard.form(':propertyId', ':type')}
          component={ConcessionCardForm}
        />
        <Route path={routes.service.plan.selection(':propertyId')} exact component={PlanScreen} />
        <Route path={routes.service.plan.support(':propertyId')} exact component={SupportScreen} />
        <Route
          path={routes.service.plan.concession(':propertyId')}
          exact
          component={ConcessionScreen}
        />
        <Route
          path={routes.service.connectionDate.saved(':propertyId')}
          exact
          component={DateConfirmation}
        />
        <Route
          path={routes.service.termsAndConditions.new(':propertyId', ':serviceSummary?')}
          exact
          component={TermsAndConditions}
        />
        <Route path={routes.service.power.show(':propertyId')} exact component={PowerConnection} />
        <Route
          path={routes.service.personalDetails.showDetails(':propertyId')}
          exact
          component={PersonalDetails}
        />
        <Route
          path={routes.service.planSummary(':serviceAccountId', ':serviceSummary')}
          exact
          component={PlanSummary}
        />
      </Switch>
    );
  }
}

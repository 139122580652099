import { AGENCIES, APP } from '../../helper/AppNameHelper';

export const LABELS = {
  DOWNLOAD: `Download ${APP === AGENCIES.NAX ? 'the Nelson Alexander app' : 'Sorted'}`,
  BACK: 'Back',
  LIFE: APP === AGENCIES.NAX ? 'Renting made simple.' : 'Life made simple.',
  COMPLETE: 'Checkout complete!',
  SUBTEXT:
    'Your services may take up to a few days to become active. Checkout progress in the app and contact us through chat if you have any questions.',
  SUBTEXT2: `Download ${
    APP === AGENCIES.NAX ? 'the Nelson Alexander app' : 'the Sorted app'
  } to track your usage, manage your payments and more.`,
};

export const links = {
  try: 'https://www.sortedservices.com/try',
  appleStore:
    'https://apps.apple.com/app/apple-store/id1232960956?pt=118629506&ct=post-digital-signing&mt=8',
  appleStoreNAX:
    'https://apps.apple.com/app/apple-store/id1489135381?pt=118629506&ct=post-digital-signing&mt=8',
  appleStoreTA:
    'https://apps.apple.com/app/apple-store/id1520540329?pt=118629506&ct=post-digital-signing&mt=8',
  playstore:
    'https://play.google.com/store/apps/details?id=com.sortedservices.sorted&referrer=utm_source%3Dpost-digital-signing%26anid%3Dadmob',
  playstoreNAX:
    'https://play.google.com/store/apps/details?id=au.com.nelsonalexander.app&referrer=utm_source%3Dpost-digital-signing%26anid%3Dadmob',
  playstoreTA:
    'https://play.google.com/store/apps/details?id=au.com.theagency.app&referrer=utm_source%3Dpost-digital-signing%26anid%3Dadmob',
};

import { AvailableServicesResponse, Property } from '../../models/checkout/Checkout';
import electricity from '../../assets/serviceTypes/electricity.png';
import broadband from '../../assets/serviceTypes/broadband.png';
import gas from '../../assets/serviceTypes/gas.png';
import rent from '../../assets/serviceTypes/rent.png';
import sortedElectricity from '../../assets/suppliers/sortedElectricity.png';
import sortedBroadband from '../../assets/suppliers/sortedBroadband.png';
import energyAustraliaGas from '../../assets/suppliers/energyAustraliaGas.png';
import energyAustraliaElectricity from '../../assets/suppliers/energyAustraliaElectricity.png';
import aglElectricity from '../../assets/suppliers/aglElectricity.png';
import aglGas from '../../assets/suppliers/aglGas.png';
import sortedIcon from '../../assets/suppliers/sorted-square.png';
import aglIcon from '../../assets/suppliers/agl-square.png';
import energyAustraliaIcon from '../../assets/suppliers/energyAustralia-square.png';
import flower from '../../assets/flower.png';
import check from '../../assets/check.png';
import headphones from '../../assets/headphones.png';
import { QuotesV3 } from '../../containers/services/commonServices/CommonPlanSelectionInterface';
import { PersonalDetails } from '../electricityService/getElectricityService/GetElectricityService.data';

export const mockAddress: Property = {
  id: '5235614',
  streetNumber: '13',
  streetName: 'Cubitt Street',
  state: 'Vic',
  suburb: 'Cremorne',
  postcode: '3000',
  agency: {
    id: 1234,
    code: 'IPG',
  },
  branch: {
    id: 23,
    internalCode: 'IPG',
  },
  address: '11-13 Cubitt Street, Cremorne, Vic 3000',
  unitNumber: '',
  country: 'Australia',
};

export const mockPersonalDetails: PersonalDetails = {
  id: 30913,
  userType: 'string',
  name: 'Solar',
  surname: 'Test',
  email: 'solar1@mail.com',
  phone: '+61422200327',
  salutation: 'Mr.',
  dob: '24/08/1988',
  idType: 'DriversLicence',
  idNumber: '12568768',
  address: null,
  idExpiry: '24/08/2021',
  active: true,
  branches: [],
  idDetails: {
    dlStateIssue: 'VIC',
    passportCountry: null,
    medicareCardColor: null,
    medicareRefNumber: null,
  },
};

export const mockAvailableServiceResponse: AvailableServicesResponse[] = [
  {
    type: 'Electricity',
    logo: electricity,
    complete: {
      options: true,
    },
    suppliers: [
      {
        providerId: '1',
        name: 'Energy Australia',
        description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
        selected: false,
        descriptionLong:
          'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsa voluptate, nisi animi rerum harum corporis quis veniam dolores assumenda nulla nemo aperiam doloremque adipisci aliquam possimus eligendi hic? Fugiat eos aliquid modi quos, sint labore at odit non perspiciatis dolores',
        logo: energyAustraliaIcon,
        backgroundImg: energyAustraliaElectricity,
        testimonials: [
          {
            stars: 5,
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error quia harum voluptatum quibusdam porro neque ipsa ipsum nemo ea corporis.',
          },
          {
            stars: 5,
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error quia harum voluptatum quibusdam porro neque ipsa ipsum nemo ea corporis.',
          },
          {
            stars: 5,
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error quia harum voluptatum quibusdam porro neque ipsa ipsum nemo ea corporis.',
          },
        ],
        features: [
          {
            icon: flower,
            title: 'Green energy',
          },
          {
            icon: check,
            title: 'Australian owned',
          },
          {
            icon: headphones,
            title: 'Local support team',
          },
        ],
        plans: [
          {
            productId: '1',
            title: 'Everyday Saver',
            price: 80,
            uom: 'mo',
            subtext:
              '*For average usage, this plan is [X]% less than the reference price at an estimated yearly cost of $[X,XXX]. Annual usage for an average customer in your area is [XXXX]kWh',
            planInformation: [
              {
                'sectionTitle': 'Plan features',
                'content': [
                  {
                    'Key': 'Set your budget',
                    'Value':
                      'Your monthly payment amount is based off the average consumption at the premise, this is a guide to help households manage their energy consumption. You are only charged for what you use. Not meeting your set budget or going over? The difference is reflected in your next payment and updating your monthly payment amount can be adjusted anytime.',
                  },
                  {
                    'Key': 'Actual bill may vary based on usage',
                    'Value':
                      "Today's quote is on a peak only flat tariff and your actual tariff may be different depending on your meter type. Correct tariff information will be in your welcome pack. This is just an estimate, and your bills will be based on your actual usage over the year and your tariff type.",
                  },
                  {
                    'Key': 'No contract',
                    'Value':
                      'Enjoy freedom, you are not locked into a contract when you sign up with Sorted!',
                  },
                  {
                    'Key': 'Service team at your finger tips',
                    'Value':
                      "Have a question? We're just a tap away. Chat with one of our team members in the app.",
                  },
                  {
                    'Key': 'Solar friendly',
                    'Value':
                      'Solar customers will benefit from our great buy back rates for unused energy. 6.0c/kWh - 7.0c/kWh feed-in tariff.',
                  },
                  {
                    'Key': 'Wallet incentives',
                    'Value':
                      'Keep an eye on promotions in the wallet - just one of the many perks of being a Sorted customer.',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan benefits',
                'content': [
                  {
                    'Key': 'Contract term',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Credit card fees',
                    'Value': '1%',
                  },
                  {
                    'Key': 'Exit fees',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Late payment fee',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Rates',
                    'Value': 'Variable',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan rates',
                'content': [
                  {
                    'Key': 'Service charge',
                    'Value': '113.1863 c/day',
                  },
                  {
                    'Key': 'Peak rate',
                    'Value': '22 c/kWh',
                  },
                  {
                    'Key': 'Solar feed in',
                    'Value': '7 c/kWh',
                  },
                ],
              },
            ],
          },
          {
            productId: '2',
            title: 'Everyday Saver Plus',
            price: 90,
            selected: false,
            uom: 'mo',
            subtext:
              '*For average usage, this plan is [X]% less than the reference price at an estimated yearly cost of $[X,XXX]. Annual usage for an average customer in your area is [XXXX]kWh',
            planInformation: [
              {
                'sectionTitle': 'Plan features',
                'content': [
                  {
                    'Key': 'Set your budget',
                    'Value':
                      'Your monthly payment amount is based off the average consumption at the premise, this is a guide to help households manage their energy consumption. You are only charged for what you use. Not meeting your set budget or going over? The difference is reflected in your next payment and updating your monthly payment amount can be adjusted anytime.',
                  },
                  {
                    'Key': 'Actual bill may vary based on usage',
                    'Value':
                      "Today's quote is on a peak only flat tariff and your actual tariff may be different depending on your meter type. Correct tariff information will be in your welcome pack. This is just an estimate, and your bills will be based on your actual usage over the year and your tariff type.",
                  },
                  {
                    'Key': 'No contract',
                    'Value':
                      'Enjoy freedom, you are not locked into a contract when you sign up with Sorted!',
                  },
                  {
                    'Key': 'Service team at your finger tips',
                    'Value':
                      "Have a question? We're just a tap away. Chat with one of our team members in the app.",
                  },
                  {
                    'Key': 'Solar friendly',
                    'Value':
                      'Solar customers will benefit from our great buy back rates for unused energy. 6.0c/kWh - 7.0c/kWh feed-in tariff.',
                  },
                  {
                    'Key': 'Wallet incentives',
                    'Value':
                      'Keep an eye on promotions in the wallet - just one of the many perks of being a Sorted customer.',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan benefits',
                'content': [
                  {
                    'Key': 'Contract term',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Credit card fees',
                    'Value': '1%',
                  },
                  {
                    'Key': 'Exit fees',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Late payment fee',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Rates',
                    'Value': 'Variable',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan rates',
                'content': [
                  {
                    'Key': 'Service charge',
                    'Value': '113.1863 c/day',
                  },
                  {
                    'Key': 'Peak rate',
                    'Value': '22 c/kWh',
                  },
                  {
                    'Key': 'Solar feed in',
                    'Value': '7 c/kWh',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        providerId: '2',
        name: 'Sorted Services',
        description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
        descriptionLong:
          'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsa voluptate, nisi animi rerum harum corporis quis veniam dolores assumenda nulla nemo aperiam doloremque adipisci aliquam possimus eligendi hic? Fugiat eos aliquid modi quos, sint labore at odit non perspiciatis dolores',
        logo: sortedIcon,
        backgroundImg: sortedElectricity,
        testimonials: [
          {
            stars: 5,
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error quia harum voluptatum quibusdam porro neque ipsa ipsum nemo ea corporis.',
          },
          {
            stars: 5,
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error quia harum voluptatum quibusdam porro neque ipsa ipsum nemo ea corporis.',
          },
          {
            stars: 5,
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error quia harum voluptatum quibusdam porro neque ipsa ipsum nemo ea corporis.',
          },
        ],
        features: [
          {
            icon: flower,
            title: 'Green energy',
          },
          {
            icon: check,
            title: 'Australian owned',
          },
          {
            icon: headphones,
            title: 'Local support team',
          },
        ],
        plans: [
          {
            productId: '3',
            title: 'Sorted Saver',
            price: 80,
            uom: 'mo',
            subtext:
              '*For average usage, this plan is [X]% less than the reference price at an estimated yearly cost of $[X,XXX]. Annual usage for an average customer in your area is [XXXX]kWh',
            planInformation: [
              {
                'sectionTitle': 'Plan features',
                'content': [
                  {
                    'Key': 'Set your budget',
                    'Value':
                      'Your monthly payment amount is based off the average consumption at the premise, this is a guide to help households manage their energy consumption. You are only charged for what you use. Not meeting your set budget or going over? The difference is reflected in your next payment and updating your monthly payment amount can be adjusted anytime.',
                  },
                  {
                    'Key': 'Actual bill may vary based on usage',
                    'Value':
                      "Today's quote is on a peak only flat tariff and your actual tariff may be different depending on your meter type. Correct tariff information will be in your welcome pack. This is just an estimate, and your bills will be based on your actual usage over the year and your tariff type.",
                  },
                  {
                    'Key': 'No contract',
                    'Value':
                      'Enjoy freedom, you are not locked into a contract when you sign up with Sorted!',
                  },
                  {
                    'Key': 'Service team at your finger tips',
                    'Value':
                      "Have a question? We're just a tap away. Chat with one of our team members in the app.",
                  },
                  {
                    'Key': 'Solar friendly',
                    'Value':
                      'Solar customers will benefit from our great buy back rates for unused energy. 6.0c/kWh - 7.0c/kWh feed-in tariff.',
                  },
                  {
                    'Key': 'Wallet incentives',
                    'Value':
                      'Keep an eye on promotions in the wallet - just one of the many perks of being a Sorted customer.',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan benefits',
                'content': [
                  {
                    'Key': 'Contract term',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Credit card fees',
                    'Value': '1%',
                  },
                  {
                    'Key': 'Exit fees',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Late payment fee',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Rates',
                    'Value': 'Variable',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan rates',
                'content': [
                  {
                    'Key': 'Service charge',
                    'Value': '113.1863 c/day',
                  },
                  {
                    'Key': 'Peak rate',
                    'Value': '22 c/kWh',
                  },
                  {
                    'Key': 'Solar feed in',
                    'Value': '7 c/kWh',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        providerId: '3',
        name: 'ACTEW AGL',
        description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
        descriptionLong:
          'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsa voluptate, nisi animi rerum harum corporis quis veniam dolores assumenda nulla nemo aperiam doloremque adipisci aliquam possimus eligendi hic? Fugiat eos aliquid modi quos, sint labore at odit non perspiciatis dolores',
        logo: aglIcon,
        backgroundImg: aglElectricity,
        testimonials: [
          {
            stars: 5,
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error quia harum voluptatum quibusdam porro neque ipsa ipsum nemo ea corporis.',
          },
          {
            stars: 5,
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error quia harum voluptatum quibusdam porro neque ipsa ipsum nemo ea corporis.',
          },
          {
            stars: 5,
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error quia harum voluptatum quibusdam porro neque ipsa ipsum nemo ea corporis.',
          },
        ],
        features: [
          {
            icon: flower,
            title: 'Green energy',
          },
          {
            icon: check,
            title: 'Australian owned',
          },
          {
            icon: headphones,
            title: 'Local support team',
          },
        ],
        plans: [
          {
            productId: '4',
            title: 'Starter plan',
            price: 80,
            uom: 'mo',
            subtext:
              '*For average usage, this plan is [X]% less than the reference price at an estimated yearly cost of $[X,XXX]. Annual usage for an average customer in your area is [XXXX]kWh',
            planInformation: [
              {
                'sectionTitle': 'Plan features',
                'content': [
                  {
                    'Key': 'Set your budget',
                    'Value':
                      'Your monthly payment amount is based off the average consumption at the premise, this is a guide to help households manage their energy consumption. You are only charged for what you use. Not meeting your set budget or going over? The difference is reflected in your next payment and updating your monthly payment amount can be adjusted anytime.',
                  },
                  {
                    'Key': 'Actual bill may vary based on usage',
                    'Value':
                      "Today's quote is on a peak only flat tariff and your actual tariff may be different depending on your meter type. Correct tariff information will be in your welcome pack. This is just an estimate, and your bills will be based on your actual usage over the year and your tariff type.",
                  },
                  {
                    'Key': 'No contract',
                    'Value':
                      'Enjoy freedom, you are not locked into a contract when you sign up with Sorted!',
                  },
                  {
                    'Key': 'Service team at your finger tips',
                    'Value':
                      "Have a question? We're just a tap away. Chat with one of our team members in the app.",
                  },
                  {
                    'Key': 'Solar friendly',
                    'Value':
                      'Solar customers will benefit from our great buy back rates for unused energy. 6.0c/kWh - 7.0c/kWh feed-in tariff.',
                  },
                  {
                    'Key': 'Wallet incentives',
                    'Value':
                      'Keep an eye on promotions in the wallet - just one of the many perks of being a Sorted customer.',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan benefits',
                'content': [
                  {
                    'Key': 'Contract term',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Credit card fees',
                    'Value': '1%',
                  },
                  {
                    'Key': 'Exit fees',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Late payment fee',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Rates',
                    'Value': 'Variable',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan rates',
                'content': [
                  {
                    'Key': 'Service charge',
                    'Value': '113.1863 c/day',
                  },
                  {
                    'Key': 'Peak rate',
                    'Value': '22 c/kWh',
                  },
                  {
                    'Key': 'Solar feed in',
                    'Value': '7 c/kWh',
                  },
                ],
              },
            ],
          },
          {
            productId: '5',
            title: 'Power Plus',
            price: 90,
            uom: 'mo',
            subtext:
              '*For average usage, this plan is [X]% less than the reference price at an estimated yearly cost of $[X,XXX]. Annual usage for an average customer in your area is [XXXX]kWh',
            planInformation: [
              {
                'sectionTitle': 'Plan features',
                'content': [
                  {
                    'Key': 'Set your budget',
                    'Value':
                      'Your monthly payment amount is based off the average consumption at the premise, this is a guide to help households manage their energy consumption. You are only charged for what you use. Not meeting your set budget or going over? The difference is reflected in your next payment and updating your monthly payment amount can be adjusted anytime.',
                  },
                  {
                    'Key': 'Actual bill may vary based on usage',
                    'Value':
                      "Today's quote is on a peak only flat tariff and your actual tariff may be different depending on your meter type. Correct tariff information will be in your welcome pack. This is just an estimate, and your bills will be based on your actual usage over the year and your tariff type.",
                  },
                  {
                    'Key': 'No contract',
                    'Value':
                      'Enjoy freedom, you are not locked into a contract when you sign up with Sorted!',
                  },
                  {
                    'Key': 'Service team at your finger tips',
                    'Value':
                      "Have a question? We're just a tap away. Chat with one of our team members in the app.",
                  },
                  {
                    'Key': 'Solar friendly',
                    'Value':
                      'Solar customers will benefit from our great buy back rates for unused energy. 6.0c/kWh - 7.0c/kWh feed-in tariff.',
                  },
                  {
                    'Key': 'Wallet incentives',
                    'Value':
                      'Keep an eye on promotions in the wallet - just one of the many perks of being a Sorted customer.',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan benefits',
                'content': [
                  {
                    'Key': 'Contract term',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Credit card fees',
                    'Value': '1%',
                  },
                  {
                    'Key': 'Exit fees',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Late payment fee',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Rates',
                    'Value': 'Variable',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan rates',
                'content': [
                  {
                    'Key': 'Service charge',
                    'Value': '113.1863 c/day',
                  },
                  {
                    'Key': 'Peak rate',
                    'Value': '22 c/kWh',
                  },
                  {
                    'Key': 'Solar feed in',
                    'Value': '7 c/kWh',
                  },
                ],
              },
            ],
          },
          {
            productId: '6',
            title: 'Maximum Plus',
            price: 95,
            uom: 'mo',
            subtext:
              '*For average usage, this plan is [X]% less than the reference price at an estimated yearly cost of $[X,XXX]. Annual usage for an average customer in your area is [XXXX]kWh',
            planInformation: [
              {
                'sectionTitle': 'Plan features',
                'content': [
                  {
                    'Key': 'Set your budget',
                    'Value':
                      'Your monthly payment amount is based off the average consumption at the premise, this is a guide to help households manage their energy consumption. You are only charged for what you use. Not meeting your set budget or going over? The difference is reflected in your next payment and updating your monthly payment amount can be adjusted anytime.',
                  },
                  {
                    'Key': 'Actual bill may vary based on usage',
                    'Value':
                      "Today's quote is on a peak only flat tariff and your actual tariff may be different depending on your meter type. Correct tariff information will be in your welcome pack. This is just an estimate, and your bills will be based on your actual usage over the year and your tariff type.",
                  },
                  {
                    'Key': 'No contract',
                    'Value':
                      'Enjoy freedom, you are not locked into a contract when you sign up with Sorted!',
                  },
                  {
                    'Key': 'Service team at your finger tips',
                    'Value':
                      "Have a question? We're just a tap away. Chat with one of our team members in the app.",
                  },
                  {
                    'Key': 'Solar friendly',
                    'Value':
                      'Solar customers will benefit from our great buy back rates for unused energy. 6.0c/kWh - 7.0c/kWh feed-in tariff.',
                  },
                  {
                    'Key': 'Wallet incentives',
                    'Value':
                      'Keep an eye on promotions in the wallet - just one of the many perks of being a Sorted customer.',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan benefits',
                'content': [
                  {
                    'Key': 'Contract term',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Credit card fees',
                    'Value': '1%',
                  },
                  {
                    'Key': 'Exit fees',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Late payment fee',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Rates',
                    'Value': 'Variable',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan rates',
                'content': [
                  {
                    'Key': 'Service charge',
                    'Value': '113.1863 c/day',
                  },
                  {
                    'Key': 'Peak rate',
                    'Value': '22 c/kWh',
                  },
                  {
                    'Key': 'Solar feed in',
                    'Value': '7 c/kWh',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'Gas',
    logo: gas,
    complete: {
      all: true,
      options: true,
      terms: true,
    },
    suppliers: [
      {
        providerId: '4',
        name: 'ACTEW AGL',
        description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
        descriptionLong:
          'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsa voluptate, nisi animi rerum harum corporis quis veniam dolores assumenda nulla nemo aperiam doloremque adipisci aliquam possimus eligendi hic? Fugiat eos aliquid modi quos, sint labore at odit non perspiciatis dolores',
        logo: aglIcon,
        backgroundImg: aglGas,
        selected: false,
        testimonials: [
          {
            stars: 5,
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error quia harum voluptatum quibusdam porro neque ipsa ipsum nemo ea corporis.',
          },
          {
            stars: 5,
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error quia harum voluptatum quibusdam porro neque ipsa ipsum nemo ea corporis.',
          },
          {
            stars: 5,
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error quia harum voluptatum quibusdam porro neque ipsa ipsum nemo ea corporis.',
          },
        ],
        features: [
          {
            icon: flower,
            title: 'Green energy',
          },
          {
            icon: check,
            title: 'Australian owned',
          },
          {
            icon: headphones,
            title: 'Local support team',
          },
        ],
        plans: [
          {
            productId: '7',
            title: 'Starter plan',
            price: 80,
            uom: 'mo',
            selected: false,
            planInformation: [
              {
                'sectionTitle': 'Plan features',
                'content': [
                  {
                    'Key': 'Set your budget',
                    'Value':
                      'Your monthly payment amount is based off the average consumption at the premise, this is a guide to help households manage their energy consumption. You are only charged for what you use. Not meeting your set budget or going over? The difference is reflected in your next payment and updating your monthly payment amount can be adjusted anytime.',
                  },
                  {
                    'Key': 'Actual bill may vary based on usage',
                    'Value':
                      "Today's quote is on a peak only flat tariff and your actual tariff may be different depending on your meter type. Correct tariff information will be in your welcome pack. This is just an estimate, and your bills will be based on your actual usage over the year and your tariff type.",
                  },
                  {
                    'Key': 'No contract',
                    'Value':
                      'Enjoy freedom, you are not locked into a contract when you sign up with Sorted!',
                  },
                  {
                    'Key': 'Service team at your finger tips',
                    'Value':
                      "Have a question? We're just a tap away. Chat with one of our team members in the app.",
                  },
                  {
                    'Key': 'Solar friendly',
                    'Value':
                      'Solar customers will benefit from our great buy back rates for unused energy. 6.0c/kWh - 7.0c/kWh feed-in tariff.',
                  },
                  {
                    'Key': 'Wallet incentives',
                    'Value':
                      'Keep an eye on promotions in the wallet - just one of the many perks of being a Sorted customer.',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan benefits',
                'content': [
                  {
                    'Key': 'Contract term',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Credit card fees',
                    'Value': '1%',
                  },
                  {
                    'Key': 'Exit fees',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Late payment fee',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Rates',
                    'Value': 'Variable',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan rates',
                'content': [
                  {
                    'Key': 'Service charge',
                    'Value': '113.1863 c/day',
                  },
                  {
                    'Key': 'Peak rate',
                    'Value': '22 c/kWh',
                  },
                  {
                    'Key': 'Solar feed in',
                    'Value': '7 c/kWh',
                  },
                ],
              },
            ],
          },
          {
            productId: '8',
            title: 'Power Plus',
            price: 90,
            uom: 'mo',
            planInformation: [
              {
                'sectionTitle': 'Plan features',
                'content': [
                  {
                    'Key': 'Set your budget',
                    'Value':
                      'Your monthly payment amount is based off the average consumption at the premise, this is a guide to help households manage their energy consumption. You are only charged for what you use. Not meeting your set budget or going over? The difference is reflected in your next payment and updating your monthly payment amount can be adjusted anytime.',
                  },
                  {
                    'Key': 'Actual bill may vary based on usage',
                    'Value':
                      "Today's quote is on a peak only flat tariff and your actual tariff may be different depending on your meter type. Correct tariff information will be in your welcome pack. This is just an estimate, and your bills will be based on your actual usage over the year and your tariff type.",
                  },
                  {
                    'Key': 'No contract',
                    'Value':
                      'Enjoy freedom, you are not locked into a contract when you sign up with Sorted!',
                  },
                  {
                    'Key': 'Service team at your finger tips',
                    'Value':
                      "Have a question? We're just a tap away. Chat with one of our team members in the app.",
                  },
                  {
                    'Key': 'Solar friendly',
                    'Value':
                      'Solar customers will benefit from our great buy back rates for unused energy. 6.0c/kWh - 7.0c/kWh feed-in tariff.',
                  },
                  {
                    'Key': 'Wallet incentives',
                    'Value':
                      'Keep an eye on promotions in the wallet - just one of the many perks of being a Sorted customer.',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan benefits',
                'content': [
                  {
                    'Key': 'Contract term',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Credit card fees',
                    'Value': '1%',
                  },
                  {
                    'Key': 'Exit fees',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Late payment fee',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Rates',
                    'Value': 'Variable',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan rates',
                'content': [
                  {
                    'Key': 'Service charge',
                    'Value': '113.1863 c/day',
                  },
                  {
                    'Key': 'Peak rate',
                    'Value': '22 c/kWh',
                  },
                  {
                    'Key': 'Solar feed in',
                    'Value': '7 c/kWh',
                  },
                ],
              },
            ],
          },
          {
            productId: '9',
            title: 'Maximum Plus',
            price: 95,
            uom: 'mo',
            planInformation: [
              {
                'sectionTitle': 'Plan features',
                'content': [
                  {
                    'Key': 'Set your budget',
                    'Value':
                      'Your monthly payment amount is based off the average consumption at the premise, this is a guide to help households manage their energy consumption. You are only charged for what you use. Not meeting your set budget or going over? The difference is reflected in your next payment and updating your monthly payment amount can be adjusted anytime.',
                  },
                  {
                    'Key': 'Actual bill may vary based on usage',
                    'Value':
                      "Today's quote is on a peak only flat tariff and your actual tariff may be different depending on your meter type. Correct tariff information will be in your welcome pack. This is just an estimate, and your bills will be based on your actual usage over the year and your tariff type.",
                  },
                  {
                    'Key': 'No contract',
                    'Value':
                      'Enjoy freedom, you are not locked into a contract when you sign up with Sorted!',
                  },
                  {
                    'Key': 'Service team at your finger tips',
                    'Value':
                      "Have a question? We're just a tap away. Chat with one of our team members in the app.",
                  },
                  {
                    'Key': 'Solar friendly',
                    'Value':
                      'Solar customers will benefit from our great buy back rates for unused energy. 6.0c/kWh - 7.0c/kWh feed-in tariff.',
                  },
                  {
                    'Key': 'Wallet incentives',
                    'Value':
                      'Keep an eye on promotions in the wallet - just one of the many perks of being a Sorted customer.',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan benefits',
                'content': [
                  {
                    'Key': 'Contract term',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Credit card fees',
                    'Value': '1%',
                  },
                  {
                    'Key': 'Exit fees',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Late payment fee',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Rates',
                    'Value': 'Variable',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan rates',
                'content': [
                  {
                    'Key': 'Service charge',
                    'Value': '113.1863 c/day',
                  },
                  {
                    'Key': 'Peak rate',
                    'Value': '22 c/kWh',
                  },
                  {
                    'Key': 'Solar feed in',
                    'Value': '7 c/kWh',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        providerId: '5',
        name: 'Energy Australia',
        description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
        descriptionLong:
          'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsa voluptate, nisi animi rerum harum corporis quis veniam dolores assumenda nulla nemo aperiam doloremque adipisci aliquam possimus eligendi hic? Fugiat eos aliquid modi quos, sint labore at odit non perspiciatis dolores',
        logo: energyAustraliaIcon,
        backgroundImg: energyAustraliaGas,
        testimonials: [
          {
            stars: 5,
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error quia harum voluptatum quibusdam porro neque ipsa ipsum nemo ea corporis.',
          },
          {
            stars: 5,
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error quia harum voluptatum quibusdam porro neque ipsa ipsum nemo ea corporis.',
          },
          {
            stars: 5,
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error quia harum voluptatum quibusdam porro neque ipsa ipsum nemo ea corporis.',
          },
        ],
        features: [
          {
            icon: flower,
            title: 'Green energy',
          },
          {
            icon: check,
            title: 'Australian owned',
          },
          {
            icon: headphones,
            title: 'Local support team',
          },
        ],
        plans: [
          {
            productId: '10',
            title: 'Everyday Saver',
            price: 80,
            uom: 'mo',
            planInformation: [
              {
                'sectionTitle': 'Plan features',
                'content': [
                  {
                    'Key': 'Set your budget',
                    'Value':
                      'Your monthly payment amount is based off the average consumption at the premise, this is a guide to help households manage their energy consumption. You are only charged for what you use. Not meeting your set budget or going over? The difference is reflected in your next payment and updating your monthly payment amount can be adjusted anytime.',
                  },
                  {
                    'Key': 'Actual bill may vary based on usage',
                    'Value':
                      "Today's quote is on a peak only flat tariff and your actual tariff may be different depending on your meter type. Correct tariff information will be in your welcome pack. This is just an estimate, and your bills will be based on your actual usage over the year and your tariff type.",
                  },
                  {
                    'Key': 'No contract',
                    'Value':
                      'Enjoy freedom, you are not locked into a contract when you sign up with Sorted!',
                  },
                  {
                    'Key': 'Service team at your finger tips',
                    'Value':
                      "Have a question? We're just a tap away. Chat with one of our team members in the app.",
                  },
                  {
                    'Key': 'Solar friendly',
                    'Value':
                      'Solar customers will benefit from our great buy back rates for unused energy. 6.0c/kWh - 7.0c/kWh feed-in tariff.',
                  },
                  {
                    'Key': 'Wallet incentives',
                    'Value':
                      'Keep an eye on promotions in the wallet - just one of the many perks of being a Sorted customer.',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan benefits',
                'content': [
                  {
                    'Key': 'Contract term',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Credit card fees',
                    'Value': '1%',
                  },
                  {
                    'Key': 'Exit fees',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Late payment fee',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Rates',
                    'Value': 'Variable',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan rates',
                'content': [
                  {
                    'Key': 'Service charge',
                    'Value': '113.1863 c/day',
                  },
                  {
                    'Key': 'Peak rate',
                    'Value': '22 c/kWh',
                  },
                  {
                    'Key': 'Solar feed in',
                    'Value': '7 c/kWh',
                  },
                ],
              },
            ],
          },
          {
            productId: '11',
            title: 'Everyday Saver',
            price: 90,
            uom: 'mo',
            planInformation: [
              {
                'sectionTitle': 'Plan features',
                'content': [
                  {
                    'Key': 'Set your budget',
                    'Value':
                      'Your monthly payment amount is based off the average consumption at the premise, this is a guide to help households manage their energy consumption. You are only charged for what you use. Not meeting your set budget or going over? The difference is reflected in your next payment and updating your monthly payment amount can be adjusted anytime.',
                  },
                  {
                    'Key': 'Actual bill may vary based on usage',
                    'Value':
                      "Today's quote is on a peak only flat tariff and your actual tariff may be different depending on your meter type. Correct tariff information will be in your welcome pack. This is just an estimate, and your bills will be based on your actual usage over the year and your tariff type.",
                  },
                  {
                    'Key': 'No contract',
                    'Value':
                      'Enjoy freedom, you are not locked into a contract when you sign up with Sorted!',
                  },
                  {
                    'Key': 'Service team at your finger tips',
                    'Value':
                      "Have a question? We're just a tap away. Chat with one of our team members in the app.",
                  },
                  {
                    'Key': 'Solar friendly',
                    'Value':
                      'Solar customers will benefit from our great buy back rates for unused energy. 6.0c/kWh - 7.0c/kWh feed-in tariff.',
                  },
                  {
                    'Key': 'Wallet incentives',
                    'Value':
                      'Keep an eye on promotions in the wallet - just one of the many perks of being a Sorted customer.',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan benefits',
                'content': [
                  {
                    'Key': 'Contract term',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Credit card fees',
                    'Value': '1%',
                  },
                  {
                    'Key': 'Exit fees',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Late payment fee',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Rates',
                    'Value': 'Variable',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan rates',
                'content': [
                  {
                    'Key': 'Service charge',
                    'Value': '113.1863 c/day',
                  },
                  {
                    'Key': 'Peak rate',
                    'Value': '22 c/kWh',
                  },
                  {
                    'Key': 'Solar feed in',
                    'Value': '7 c/kWh',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'Broadband',
    logo: broadband,
    suppliers: [
      {
        providerId: '6',
        name: 'Sorted Services',
        description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
        descriptionLong:
          'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsa voluptate, nisi animi rerum harum corporis quis veniam dolores assumenda nulla nemo aperiam doloremque adipisci aliquam possimus eligendi hic? Fugiat eos aliquid modi quos, sint labore at odit non perspiciatis dolores',
        logo: sortedIcon,
        backgroundImg: sortedBroadband,
        selected: false,
        testimonials: [
          {
            stars: 5,
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error quia harum voluptatum quibusdam porro neque ipsa ipsum nemo ea corporis.',
          },
          {
            stars: 5,
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error quia harum voluptatum quibusdam porro neque ipsa ipsum nemo ea corporis.',
          },
          {
            stars: 5,
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error quia harum voluptatum quibusdam porro neque ipsa ipsum nemo ea corporis.',
          },
        ],
        features: [
          {
            icon: flower,
            title: 'Green energy',
          },
          {
            icon: check,
            title: 'Australian owned',
          },
          {
            icon: headphones,
            title: 'Local support team',
          },
        ],
        plans: [
          {
            productId: '12',
            title: 'Sorted Saver',
            price: 80,
            uom: 'mo',
            selected: false,
            planInformation: [
              {
                'sectionTitle': 'Plan features',
                'content': [
                  {
                    'Key': 'Set your budget',
                    'Value':
                      'Your monthly payment amount is based off the average consumption at the premise, this is a guide to help households manage their energy consumption. You are only charged for what you use. Not meeting your set budget or going over? The difference is reflected in your next payment and updating your monthly payment amount can be adjusted anytime.',
                  },
                  {
                    'Key': 'Actual bill may vary based on usage',
                    'Value':
                      "Today's quote is on a peak only flat tariff and your actual tariff may be different depending on your meter type. Correct tariff information will be in your welcome pack. This is just an estimate, and your bills will be based on your actual usage over the year and your tariff type.",
                  },
                  {
                    'Key': 'No contract',
                    'Value':
                      'Enjoy freedom, you are not locked into a contract when you sign up with Sorted!',
                  },
                  {
                    'Key': 'Service team at your finger tips',
                    'Value':
                      "Have a question? We're just a tap away. Chat with one of our team members in the app.",
                  },
                  {
                    'Key': 'Solar friendly',
                    'Value':
                      'Solar customers will benefit from our great buy back rates for unused energy. 6.0c/kWh - 7.0c/kWh feed-in tariff.',
                  },
                  {
                    'Key': 'Wallet incentives',
                    'Value':
                      'Keep an eye on promotions in the wallet - just one of the many perks of being a Sorted customer.',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan benefits',
                'content': [
                  {
                    'Key': 'Contract term',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Credit card fees',
                    'Value': '1%',
                  },
                  {
                    'Key': 'Exit fees',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Late payment fee',
                    'Value': 'No',
                  },
                  {
                    'Key': 'Rates',
                    'Value': 'Variable',
                  },
                ],
              },
              {
                'sectionTitle': 'Plan rates',
                'content': [
                  {
                    'Key': 'Service charge',
                    'Value': '113.1863 c/day',
                  },
                  {
                    'Key': 'Peak rate',
                    'Value': '22 c/kWh',
                  },
                  {
                    'Key': 'Solar feed in',
                    'Value': '7 c/kWh',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export const RentMock = {
  type: 'Rent',
  logo: rent,
};

export const quotesResponse: any = {
  Success: true,
  Errors: [],
  Response: {
    SupplierId: 'Integral',
    ServiceType: 'Broadband',
    Postcode: '3175',
    KeyIdentifier: '160789862',
    ServiceabilityDetails: {
      ServiceClassesAvailable: [],
      Product: '',
      RFSDate: null,
      ServiceClass: null,
      Technology: null,
      Zone: '1',
      nbnDevelopmentFee: false,
      Notes: null,
    },
    Plans: [
      {
        SignUpFlow: 'V2',
        SignUpType: 'ui-full',
        Iframe: '',
        ServiceType: 'Broadband',
        ServiceTypeLogo:
          'https://prodau-sorted-tenancy-service.s3.ap-southeast-2.amazonaws.com/static%20assets/services/servicetypes/internet_24px@2x.png',
        ServiceClass: 'Consumption',
        SupplierId: 'Integral',
        ProductId: '',
        SupplierTitle: 'Request broadband connection',
        SupplierName: 'Sorted Services',
        SupplierLogo:
          'https://prodau-sorted-tenancy-service.s3.ap-southeast-2.amazonaws.com/static%20assets/services/suppliers/sorted/sortedservices-circular-logo.png',
        SupplierLogoHero:
          'https://prodau-sorted-tenancy-service.s3.ap-southeast-2.amazonaws.com/static%20assets/services/suppliers/sorted/sortedservices-hero.png',
        SupplierBackgroundLogo:
          'https://prodau-sorted-tenancy-service.s3.ap-southeast-2.amazonaws.com/static%20assets/services/suppliers/sorted/brandpage-info-sorted-5.png',
        SupplierTagLine: 'Life made simple',
        ServiceName: 'Sorted Broadband',
        IdentificationTypes: [
          {
            Name: 'Drivers License',
            Value: 'DriversLicence',
          },
          {
            Name: 'Medicare',
            Value: 'Medicare',
          },
          {
            Name: 'Passport',
            Value: 'Passport',
          },
        ],
        CustomerType: 'Residential',
        ServiceCategoryId: 'Consumption',
        ServiceCategoryName: 'Consumption',
        Description: 'Sorted is a 100% Australian owned energy company and broadband retailer.',
        DescriptionBanner:
          "You can manage your services in one app, even across multiple properties. You can see everything at a glance and use live chat for help when it suits you.<br/><br/>With Sorted, you don't have to remember account numbers or passwords for multiple accounts. All your details are safe, with a record of everything so you never have to repeat yourself or look up information.",
        DescriptionFooter:
          'Plan prices are estimates only, actual price may vary depending on the optional extras chosen',
        ProductType: 'Plan',
        SupplierIntroductionButtonLabel: 'Tap to continue',
        Plans: [
          {
            OrderBy: 0,
            Group: 'STANDARD',
            ProductId: 'NBN.9999.025.ST.NOV',
            Type: 'Plan',
            PaymentCardRequired: true,
            Name: 'Home Internet - Standard',
            Description: 'Home Internet - Standard',
            DescriptionFooter: null,
            BasePrice: 0,
            BasePriceDaily: 2.14,
            BasePriceMonthly: 65,
            MinimumContract: 780,
            StartDate: null,
            EndDate: null,
            Options: [
              {
                OrderBy: 1,
                Group: 'STANDARD',
                Heading: 'Home internet standard',
                Description:
                  'Unlimited Data<br/>25Mbps max download speed.<br/>5Mbps max upload speed.<br/>',
                OptionsDescription: '',
                Footer: 'All prices are GST inclusive.',
                Type: 'Product',
                Options: [
                  {
                    OrderBy: 0,
                    IsProduct: true,
                    ProductId: 'NBN.9999.025.ST.NOV',
                    Label: 'Home internet standard',
                    HelpText:
                      'Enjoy unlimited data with a plan that suits basic browsing, email and light video streaming.<br/>15Mbps typical peak speeds between 7PM to 11PM.',
                    PricingHelpText: ' ',
                    BasePrice: null,
                    BasePriceDaily: 2,
                    BasePriceMonthly: 65,
                    SinglePrice: 0,
                    StepPriceDaily: null,
                    StepPriceMonthly: null,
                    MinimumContract: 780,
                    Default: true,
                    FeeId: ['SetupFee'],
                  },
                ],
              },
              {
                OrderBy: 2,
                Group: 'Contract',
                Heading: 'Contract',
                Description: 'Would you like to be on a contract',
                OptionsDescription: null,
                Footer: null,
                Type: 'Question',
                Options: [
                  {
                    OrderBy: 1,
                    IsProduct: false,
                    ProductId: 'Yes',
                    Label: '12 MONTH',
                    HelpText:
                      '<b>Minimum</b> cost <b>$780</b> if you stay connected for 12 months.<br/><br/>If you cancel your service during the Minimum Term, an <b>Early Termination Charge</b> of <b>$197</b> applies.',
                    PricingHelpText: null,
                    BasePrice: null,
                    BasePriceDaily: 2,
                    BasePriceMonthly: 65,
                    SinglePrice: null,
                    StepPriceDaily: null,
                    StepPriceMonthly: null,
                    MinimumContract: 780,
                    Default: true,
                    FeeId: [],
                  },
                  {
                    OrderBy: 2,
                    IsProduct: false,
                    ProductId: 'No',
                    Label: 'NONE',
                    HelpText:
                      '<b>Minimum</b> cost <b>$262</b> includes <b>setup</b> fee <b>$197</b> and first months charges.',
                    PricingHelpText: null,
                    BasePrice: null,
                    BasePriceDaily: 2,
                    BasePriceMonthly: 65,
                    SinglePrice: null,
                    StepPriceDaily: null,
                    StepPriceMonthly: null,
                    MinimumContract: 780,
                    Default: null,
                    FeeId: [],
                  },
                ],
              },
            ],
            Headlines: [],
            Benefits: [],
            Features: [
              {
                Heading: 'Service team at your finger tips',
                Body: "Have a question? We're just a tap away. Chat with one of our team members in the app.",
                StatesIncluded: [],
              },
              {
                Heading: 'Wallet incentives',
                Body: 'Keep an eye on promotions in the wallet - just one of the many perks of being a Sorted customer.',
                StatesIncluded: [],
              },
            ],
            Inclusions: [],
            HeadlinePromo: {
              Title: 'nbn New development fee',
              Description:
                'In some circumstances, a new development fee or susequent installation fee between $270 - $300 may be applied. By agreeing you accept the pass through of these charges.',
              Banner: '#f0efeb',
              Group: '*',
              StatesIncluded: [],
            },
            Rates: {
              HeaderText: null,
              FooterText: null,
              OrderBy: 0,
              Rates: [],
              Fees: [],
              Links: [
                {
                  Type: 'pdf',
                  Name: 'CIS',
                  Label: 'Critical Information Summary',
                  Link: 'http://sortedservices.com/s/Sorted_CIS_NBN_HomeBundle.pdf',
                  StatesIncluded: [],
                },
              ],
            },
            Links: [
              {
                Type: 'pdf',
                Name: 'CIS',
                Label: 'Critical Information Summary',
                Link: 'http://sortedservices.com/s/Sorted_CIS_NBN_HomeBundle.pdf',
                StatesIncluded: [],
              },
              {
                Type: 'pdf',
                Name: 'DDA',
                Label: 'Direct Debit Service Agreement',
                Link: 'http://sortedservices.com/s/Direct-Debit-Request-Service-Agreement.pdf',
                StatesIncluded: [],
              },
              {
                Type: 'pdf',
                Name: 'TermsConditions',
                Label: 'Terms And Conditions',
                Link: 'http:/sortedservices.com/s/Sorted-Services-Customer-Terms-Conditions.pdf',
                StatesIncluded: [],
              },
              {
                Type: 'pdf',
                Name: 'ModemSetupGuide',
                Label: 'Modem Setup Guide',
                Link: 'https://www.sortedservices.com/s/NBN_Modem_Setup_Guide.pdf',
                StatesIncluded: [],
              },
              {
                Type: 'pdf',
                Name: 'ReasonableUsePolicy',
                Label: 'Reasonable Use Policy',
                Link: 'https://www.sortedservices.com/s/Sorted_Reasonable_Use_Policy.pdf',
                StatesIncluded: [],
              },
              {
                Type: 'pdf',
                Name: 'InternationalCallRates',
                Label: 'International Call Rates',
                Link: 'https://www.sortedservices.com/s/Sorted_Services_Voice_International_Call_Rates.pdf',
                StatesIncluded: [],
              },
            ],
            Fees: [
              {
                Id: 'ConnnectionNBN',
                Name: 'NBN Connnection',
                Type: 'Connection',
                Heading: 'NBN Connection Fee',
                HelpText:
                  'A connection is paid to connect your property to the NBN network (applicable if your property is not connected to the NBN network)',
                LinkItems: [
                  {
                    Type: '',
                    Name: 'NBNLink',
                    Label: 'See more for details',
                    Link: 'https://www.nbnco.com.au/develop-or-plan-with-the-nbn/new-developments/government-policy-for-new-developments',
                    StatesIncluded: [],
                  },
                ],
                FeeItems: [
                  {
                    OrderBy: 0,
                    Type: 'Fee',
                    Name: 'Connection Fee',
                    Label: 'Connection Fee',
                    Rate: 300,
                    Rule: 'Mandatory',
                    Unit: 'SingleCharge',
                    Links: [],
                  },
                ],
              },
              {
                Id: 'EarlyTerminationFee',
                Name: 'Early Termination Fee',
                Type: 'Fee',
                Heading: 'Early Termination Fee',
                HelpText:
                  'Early Termination Fee applies if you cancel your service during the Minimum Term',
                LinkItems: null,
                FeeItems: [
                  {
                    OrderBy: 0,
                    Type: 'Fee',
                    Name: 'Early Termination Fee',
                    Label: 'Early Termination Fee',
                    Rate: 197,
                    Rule: 'Optional',
                    Unit: '',
                    Links: [],
                  },
                ],
              },
            ],
            SignupConfig: {
              Type: null,
              Container: null,
              Signup: null,
            },
            FooterPricing: [
              {
                PricingType: 'Monthly',
                UnitOfMeasure: 'Monthly',
                UnitOfMeasureLabel: '/Month',
                PriceField: 'BasePriceMonthly',
              },
            ],
          },
          {
            OrderBy: 0,
            Group: 'STANDARDPLUS',
            ProductId: 'NBN.9999.050.STP.NOV',
            Type: 'Plan',
            PaymentCardRequired: true,
            Name: 'Home Internet - Plus',
            Description: 'Home Internet - Plus',
            DescriptionFooter: null,
            BasePrice: 0,
            BasePriceDaily: 2.56,
            BasePriceMonthly: 78,
            MinimumContract: 936,
            StartDate: null,
            EndDate: null,
            Options: [
              {
                OrderBy: 1,
                Group: 'STANDARDPLUS',
                Heading: 'Home internet plus',
                Description:
                  'Unlimited Data<br/>50Mbps max download speed.<br/>20Mbps max upload speed.<br/>',
                OptionsDescription: '',
                Footer: 'All prices are GST inclusive.',
                Type: 'Product',
                Options: [
                  {
                    OrderBy: 0,
                    IsProduct: true,
                    ProductId: 'NBN.9999.050.STP.NOV',
                    Label: 'Home internet plus',
                    HelpText:
                      'Enjoy unlimited data with a plan that is suitable for multiple streaming devices.<br/><br/>30Mbps typical peak speeds between 7PM to 11PM.',
                    PricingHelpText: ' ',
                    BasePrice: null,
                    BasePriceDaily: 3,
                    BasePriceMonthly: 78,
                    SinglePrice: 0,
                    StepPriceDaily: null,
                    StepPriceMonthly: null,
                    MinimumContract: 936,
                    Default: true,
                    FeeId: ['SetupFee'],
                  },
                ],
              },
              {
                OrderBy: 2,
                Group: 'Contract',
                Heading: 'Contract',
                Description: 'Would you like to be on a contract',
                OptionsDescription: null,
                Footer: null,
                Type: 'Question',
                Options: [
                  {
                    OrderBy: 1,
                    IsProduct: false,
                    ProductId: 'Yes',
                    Label: '12 MONTH',
                    HelpText:
                      '<b>Minimum</b> cost <b>$936</b> if you stay connected for 12 months.<br/><br/>If you cancel your service during the Minimum Term, an <b>Early Termination Charge</b> of <b>$197</b> applies.',
                    PricingHelpText: null,
                    BasePrice: null,
                    BasePriceDaily: 3,
                    BasePriceMonthly: 78,
                    SinglePrice: null,
                    StepPriceDaily: null,
                    StepPriceMonthly: null,
                    MinimumContract: 936,
                    Default: true,
                    FeeId: [],
                  },
                  {
                    OrderBy: 2,
                    IsProduct: false,
                    ProductId: 'No',
                    Label: 'NONE',
                    HelpText:
                      '<b>Minimum</b> cost <b>$275</b> includes <b>setup</b> fee <b>$197</b> and first months charges.',
                    PricingHelpText: null,
                    BasePrice: null,
                    BasePriceDaily: 3,
                    BasePriceMonthly: 78,
                    SinglePrice: null,
                    StepPriceDaily: null,
                    StepPriceMonthly: null,
                    MinimumContract: 936,
                    Default: null,
                    FeeId: [],
                  },
                ],
              },
            ],
            Headlines: [],
            Benefits: [],
            Features: [
              {
                Heading: 'Service team at your finger tips',
                Body: "Have a question? We're just a tap away. Chat with one of our team members in the app.",
                StatesIncluded: [],
              },
              {
                Heading: 'Wallet incentives',
                Body: 'Keep an eye on promotions in the wallet - just one of the many perks of being a Sorted customer.',
                StatesIncluded: [],
              },
            ],
            Inclusions: [],
            HeadlinePromo: {
              Title: 'nbn New development fee',
              Description:
                'In some circumstances, a new development fee or susequent installation fee between $270 - $300 may be applied. By agreeing you accept the pass through of these charges.',
              Banner: '#f0efeb',
              Group: '*',
              StatesIncluded: [],
            },
            Rates: {
              HeaderText: null,
              FooterText: null,
              OrderBy: 0,
              Rates: [],
              Fees: [],
              Links: [],
            },
            Links: [
              {
                Type: 'pdf',
                Name: 'CIS',
                Label: 'Critical Information Summary',
                Link: 'http://sortedservices.com/s/Sorted_CIS_NBN_HomeBundle.pdf',
                StatesIncluded: [],
              },
              {
                Type: 'pdf',
                Name: 'DDA',
                Label: 'Direct Debit Service Agreement',
                Link: 'http://sortedservices.com/s/Direct-Debit-Request-Service-Agreement.pdf',
                StatesIncluded: [],
              },
              {
                Type: 'pdf',
                Name: 'TermsConditions',
                Label: 'Terms And Conditions',
                Link: 'http:/sortedservices.com/s/Sorted-Services-Customer-Terms-Conditions.pdf',
                StatesIncluded: [],
              },
              {
                Type: 'pdf',
                Name: 'ModemSetupGuide',
                Label: 'Modem Setup Guide',
                Link: 'https://www.sortedservices.com/s/NBN_Modem_Setup_Guide.pdf',
                StatesIncluded: [],
              },
              {
                Type: 'pdf',
                Name: 'ReasonableUsePolicy',
                Label: 'Reasonable Use Policy',
                Link: 'https://www.sortedservices.com/s/Sorted_Reasonable_Use_Policy.pdf',
                StatesIncluded: [],
              },
              {
                Type: 'pdf',
                Name: 'InternationalCallRates',
                Label: 'International Call Rates',
                Link: 'https://www.sortedservices.com/s/Sorted_Services_Voice_International_Call_Rates.pdf',
                StatesIncluded: [],
              },
            ],
            Fees: [
              {
                Id: 'ConnnectionNBN',
                Name: 'NBN Connnection',
                Type: 'Connection',
                Heading: 'NBN Connection Fee',
                HelpText:
                  'A connection is paid to connect your property to the NBN network (applicable if your property is not connected to the NBN network)',
                LinkItems: [
                  {
                    Type: '',
                    Name: 'NBNLink',
                    Label: 'See more for details',
                    Link: 'https://www.nbnco.com.au/develop-or-plan-with-the-nbn/new-developments/government-policy-for-new-developments',
                    StatesIncluded: [],
                  },
                ],
                FeeItems: [
                  {
                    OrderBy: 0,
                    Type: 'Fee',
                    Name: 'Connection Fee',
                    Label: 'Connection Fee',
                    Rate: 300,
                    Rule: 'Mandatory',
                    Unit: 'SingleCharge',
                    Links: [],
                  },
                ],
              },
              {
                Id: 'EarlyTerminationFee',
                Name: 'Early Termination Fee',
                Type: 'Fee',
                Heading: 'Early Termination Fee',
                HelpText:
                  'Early Termination Fee applies if you cancel your service during the Minimum Term',
                LinkItems: null,
                FeeItems: [
                  {
                    OrderBy: 0,
                    Type: 'Fee',
                    Name: 'Early Termination Fee',
                    Label: 'Early Termination Fee',
                    Rate: 197,
                    Rule: 'Optional',
                    Unit: '',
                    Links: [],
                  },
                ],
              },
            ],
            SignupConfig: {
              Type: null,
              Container: null,
              Signup: null,
            },
            FooterPricing: [
              {
                PricingType: 'Monthly',
                UnitOfMeasure: 'Monthly',
                UnitOfMeasureLabel: '/Month',
                PriceField: 'BasePriceMonthly',
              },
            ],
          },
          {
            OrderBy: 0,
            Group: 'PREMIUM',
            ProductId: 'NBN.9999.100.PR.NOV',
            Type: 'Plan',
            PaymentCardRequired: true,
            Name: 'Home Internet - Premium',
            Description: 'Home Internet - Premium',
            DescriptionFooter: null,
            BasePrice: 0,
            BasePriceDaily: 3.12,
            BasePriceMonthly: 95,
            MinimumContract: 1140,
            StartDate: null,
            EndDate: null,
            Options: [
              {
                OrderBy: 1,
                Group: 'PREMIUM',
                Heading: 'Home internet premium',
                Description:
                  'Unlimited Data<br/>100Mbps max download speed.<br/>40Mbps max upload speed.<br/>',
                OptionsDescription: '',
                Footer: 'All prices are GST inclusive.',
                Type: 'Product',
                Options: [
                  {
                    OrderBy: 0,
                    IsProduct: true,
                    ProductId: 'NBN.9999.100.PR.NOV',
                    Label: 'Home internet premium',
                    HelpText:
                      'Enjoy unlimited data with a plan that suits large famnilies with multiple streaming devices<br/>70Mbps typical peak speeds between 7PM to 11PM.<br/>',
                    PricingHelpText: ' ',
                    BasePrice: null,
                    BasePriceDaily: 3,
                    BasePriceMonthly: 95,
                    SinglePrice: 0,
                    StepPriceDaily: null,
                    StepPriceMonthly: null,
                    MinimumContract: 1140,
                    Default: true,
                    FeeId: ['SetupFee'],
                  },
                ],
              },
              {
                OrderBy: 2,
                Group: 'Contract',
                Heading: 'Contract',
                Description: 'Would you like to be on a contract',
                OptionsDescription: null,
                Footer: null,
                Type: 'Question',
                Options: [
                  {
                    OrderBy: 1,
                    IsProduct: false,
                    ProductId: 'Yes',
                    Label: '12 MONTH',
                    HelpText:
                      '<b>Minimum</b> cost <b>$1,140</b> if you stay connected for 12 months.<br/><br/>If you cancel your service during the Minimum Term, an <b>Early Termination Charge</b> of <b>$197</b> applies.',
                    PricingHelpText: null,
                    BasePrice: null,
                    BasePriceDaily: 3,
                    BasePriceMonthly: 95,
                    SinglePrice: null,
                    StepPriceDaily: null,
                    StepPriceMonthly: null,
                    MinimumContract: 1140,
                    Default: true,
                    FeeId: [],
                  },
                  {
                    OrderBy: 2,
                    IsProduct: false,
                    ProductId: 'No',
                    Label: 'NONE',
                    HelpText:
                      '<b>Minimum</b> cost <b>$292</b> includes <b>setup</b> fee <b>$197</b> and first months charges.',
                    PricingHelpText: null,
                    BasePrice: null,
                    BasePriceDaily: 3,
                    BasePriceMonthly: 95,
                    SinglePrice: null,
                    StepPriceDaily: null,
                    StepPriceMonthly: null,
                    MinimumContract: 1140,
                    Default: null,
                    FeeId: [],
                  },
                ],
              },
            ],
            Headlines: [],
            Benefits: [],
            Features: [
              {
                Heading: 'Service team at your finger tips',
                Body: "Have a question? We're just a tap away. Chat with one of our team members in the app.",
                StatesIncluded: [],
              },
              {
                Heading: 'Wallet incentives',
                Body: 'Keep an eye on promotions in the wallet - just one of the many perks of being a Sorted customer.',
                StatesIncluded: [],
              },
            ],
            Inclusions: [],
            HeadlinePromo: {
              Title: 'nbn New development fee',
              Description:
                'In some circumstances, a new development fee or susequent installation fee between $270 - $300 may be applied. By agreeing you accept the pass through of these charges.',
              Banner: '#f0efeb',
              Group: '*',
              StatesIncluded: [],
            },
            Rates: {
              HeaderText: null,
              FooterText: null,
              OrderBy: 0,
              Rates: [],
              Fees: [],
              Links: [],
            },
            Links: [
              {
                Type: 'pdf',
                Name: 'CIS',
                Label: 'Critical Information Summary',
                Link: 'http://sortedservices.com/s/Sorted_CIS_NBN_HomeBundle.pdf',
                StatesIncluded: [],
              },
              {
                Type: 'pdf',
                Name: 'DDA',
                Label: 'Direct Debit Service Agreement',
                Link: 'http://sortedservices.com/s/Direct-Debit-Request-Service-Agreement.pdf',
                StatesIncluded: [],
              },
              {
                Type: 'pdf',
                Name: 'TermsConditions',
                Label: 'Terms And Conditions',
                Link: 'http:/sortedservices.com/s/Sorted-Services-Customer-Terms-Conditions.pdf',
                StatesIncluded: [],
              },
              {
                Type: 'pdf',
                Name: 'ModemSetupGuide',
                Label: 'Modem Setup Guide',
                Link: 'https://www.sortedservices.com/s/NBN_Modem_Setup_Guide.pdf',
                StatesIncluded: [],
              },
              {
                Type: 'pdf',
                Name: 'ReasonableUsePolicy',
                Label: 'Reasonable Use Policy',
                Link: 'https://www.sortedservices.com/s/Sorted_Reasonable_Use_Policy.pdf',
                StatesIncluded: [],
              },
              {
                Type: 'pdf',
                Name: 'InternationalCallRates',
                Label: 'International Call Rates',
                Link: 'https://www.sortedservices.com/s/Sorted_Services_Voice_International_Call_Rates.pdf',
                StatesIncluded: [],
              },
            ],
            Fees: [
              {
                Id: 'ConnnectionNBN',
                Name: 'NBN Connnection',
                Type: 'Connection',
                Heading: 'NBN Connection Fee',
                HelpText:
                  'A connection is paid to connect your property to the NBN network (applicable if your property is not connected to the NBN network)',
                LinkItems: [
                  {
                    Type: '',
                    Name: 'NBNLink',
                    Label: 'See more for details',
                    Link: 'https://www.nbnco.com.au/develop-or-plan-with-the-nbn/new-developments/government-policy-for-new-developments',
                    StatesIncluded: [],
                  },
                ],
                FeeItems: [
                  {
                    OrderBy: 0,
                    Type: 'Fee',
                    Name: 'Connection Fee',
                    Label: 'Connection Fee',
                    Rate: 300,
                    Rule: 'Mandatory',
                    Unit: 'SingleCharge',
                    Links: [],
                  },
                ],
              },
              {
                Id: 'EarlyTerminationFee',
                Name: 'Early Termination Fee',
                Type: 'Fee',
                Heading: 'Early Termination Fee',
                HelpText:
                  'Early Termination Fee applies if you cancel your service during the Minimum Term',
                LinkItems: null,
                FeeItems: [
                  {
                    OrderBy: 0,
                    Type: 'Fee',
                    Name: 'Early Termination Fee',
                    Label: 'Early Termination Fee',
                    Rate: 197,
                    Rule: 'Optional',
                    Unit: '',
                    Links: [],
                  },
                ],
              },
            ],
            SignupConfig: {
              Type: null,
              Container: null,
              Signup: null,
            },
            FooterPricing: [
              {
                PricingType: 'Monthly',
                UnitOfMeasure: 'Monthly',
                UnitOfMeasureLabel: '/Month',
                PriceField: 'BasePriceMonthly',
              },
            ],
          },
        ],
        LinkItems: [],
        SupplierOptions: [
          {
            OrderBy: 1,
            Group: 'ConnectionDate',
            ProductId: null,
            Heading: 'Connection Date',
            Description: 'When would you like your service to be connected?',
            Type: 'Question',
            Page: 0,
            Default: null,
            IncludedInContractPricing: null,
            FooterText: null,
            ChatEnabled: null,
            ComponentType: null,
            ComponentId: null,
            ComponentText: null,
            ComponentIsMandatory: false,
            ComponentDateMaxFutureDays: 0,
            ComponentDateExcludeWeekend: false,
            ComponentDateExcludeCurrentDay: false,
            PublicHolidayExclusion: false,
            ComponentOptions: [],
            Links: [],
            Options: [
              {
                OrderBy: 1,
                ProductId: 'Date',
                Label: 'Date',
                HelpText: '',
                PricingHelpText: null,
                SinglePrice: null,
                BasePriceDaily: null,
                BasePriceMonthly: null,
                HasPrice: false,
                Default: null,
                StopSaleProcess: null,
                Option: {
                  OrderBy: 0,
                  Group: 'ConnectionDate',
                  ProductId: null,
                  Heading: 'Select date',
                  Description: '',
                  Type: 'Question',
                  Page: 0,
                  Default: false,
                  IncludedInContractPricing: null,
                  FooterText: null,
                  ChatEnabled: null,
                  ComponentType: 'Date',
                  ComponentId: 'ConnectionDateInput',
                  ComponentText:
                    'We will reserve your appointment on the closest available date to the one you have selected.',
                  ComponentIsMandatory: true,
                  ComponentDateMaxFutureDays: 60,
                  ComponentDateExcludeWeekend: true,
                  ComponentDateExcludeCurrentDay: true,
                  PublicHolidayExclusion: true,
                  ComponentOptions: [],
                  Links: [],
                  Options: [],
                  StatesIncluded: [],
                  DistributorIncluded: [],
                },
              },
              {
                OrderBy: 2,
                ProductId: 'ASAP',
                Label: 'ASAP',
                HelpText: 'We will schedule the first available NBN appointment for you.',
                PricingHelpText: null,
                SinglePrice: null,
                BasePriceDaily: null,
                BasePriceMonthly: null,
                HasPrice: false,
                Default: true,
                StopSaleProcess: null,
                Option: {
                  OrderBy: 0,
                  Group: null,
                  ProductId: null,
                  Heading: null,
                  Description: null,
                  Type: null,
                  Page: 0,
                  Default: null,
                  IncludedInContractPricing: null,
                  FooterText: null,
                  ChatEnabled: null,
                  ComponentType: null,
                  ComponentId: null,
                  ComponentText: null,
                  ComponentIsMandatory: false,
                  ComponentDateMaxFutureDays: 0,
                  ComponentDateExcludeWeekend: false,
                  ComponentDateExcludeCurrentDay: false,
                  PublicHolidayExclusion: false,
                  ComponentOptions: [],
                  Links: [],
                  Options: [],
                  StatesIncluded: [],
                  DistributorIncluded: [],
                },
              },
            ],
            StatesIncluded: [],
            DistributorIncluded: [],
          },
          {
            OrderBy: 2,
            Group: 'Modem',
            ProductId: null,
            Heading: 'Modem',
            Description: 'Do you need a modem?',
            Type: 'Question',
            Page: 0,
            Default: null,
            IncludedInContractPricing: null,
            FooterText: null,
            ChatEnabled: null,
            ComponentType: null,
            ComponentId: null,
            ComponentText: null,
            ComponentIsMandatory: false,
            ComponentDateMaxFutureDays: 0,
            ComponentDateExcludeWeekend: false,
            ComponentDateExcludeCurrentDay: false,
            PublicHolidayExclusion: false,
            ComponentOptions: [],
            Links: [],
            Options: [
              {
                OrderBy: 1,
                ProductId: 'Yes',
                Label: 'Yes',
                HelpText: '',
                PricingHelpText: null,
                SinglePrice: null,
                BasePriceDaily: null,
                BasePriceMonthly: null,
                HasPrice: false,
                Default: null,
                StopSaleProcess: null,
                Option: {
                  OrderBy: 0,
                  Group: 'ModemDeliveryAddress',
                  ProductId: null,
                  Heading: 'Modem Delivery Address',
                  Description:
                    'If you have a preferred connection date that is more than a week from today, do you want the modem delivered to another address?',
                  Type: 'Question',
                  Page: 0,
                  Default: false,
                  IncludedInContractPricing: null,
                  FooterText: null,
                  ChatEnabled: null,
                  ComponentType: 'Text',
                  ComponentId: 'TextInput',
                  ComponentText:
                    'Your modem will be delivered in 3-5 days, please provide your FULL address for delivery (example. 11 Cubitt Street, Cremorne, 3121 VIC)',
                  ComponentIsMandatory: false,
                  ComponentDateMaxFutureDays: 60,
                  ComponentDateExcludeWeekend: false,
                  ComponentDateExcludeCurrentDay: false,
                  PublicHolidayExclusion: false,
                  ComponentOptions: [],
                  Links: [],
                  Options: [],
                  StatesIncluded: [],
                  DistributorIncluded: [],
                },
              },
              {
                OrderBy: 2,
                ProductId: 'No',
                Label: 'No',
                HelpText: '',
                PricingHelpText: null,
                SinglePrice: null,
                BasePriceDaily: null,
                BasePriceMonthly: null,
                HasPrice: false,
                Default: null,
                StopSaleProcess: null,
                Option: {
                  OrderBy: 0,
                  Group: null,
                  ProductId: null,
                  Heading: null,
                  Description: null,
                  Type: null,
                  Page: 0,
                  Default: null,
                  IncludedInContractPricing: null,
                  FooterText: null,
                  ChatEnabled: null,
                  ComponentType: null,
                  ComponentId: null,
                  ComponentText: null,
                  ComponentIsMandatory: false,
                  ComponentDateMaxFutureDays: 0,
                  ComponentDateExcludeWeekend: false,
                  ComponentDateExcludeCurrentDay: false,
                  PublicHolidayExclusion: false,
                  ComponentOptions: [],
                  Links: [],
                  Options: [],
                  StatesIncluded: [],
                  DistributorIncluded: [],
                },
              },
            ],
            StatesIncluded: [],
            DistributorIncluded: [],
          },
          {
            OrderBy: 1,
            Group: 'MoveOutDate',
            ProductId: null,
            Heading: 'What date are you moving out',
            Description: 'What is the date you are moving out?',
            Type: 'Question',
            Page: 0,
            Default: null,
            IncludedInContractPricing: null,
            FooterText: null,
            ChatEnabled: null,
            ComponentType: null,
            ComponentId: null,
            ComponentText: null,
            ComponentIsMandatory: false,
            ComponentDateMaxFutureDays: 0,
            ComponentDateExcludeWeekend: false,
            ComponentDateExcludeCurrentDay: false,
            PublicHolidayExclusion: false,
            ComponentOptions: [],
            Links: [],
            Options: [
              {
                OrderBy: 1,
                ProductId: '',
                Label: 'Vacate date',
                HelpText: 'The date you will be vacating the property',
                PricingHelpText: null,
                SinglePrice: null,
                BasePriceDaily: null,
                BasePriceMonthly: null,
                HasPrice: false,
                Default: null,
                StopSaleProcess: null,
                Option: {
                  OrderBy: 0,
                  Group: 'MoveOutDate',
                  ProductId: null,
                  Heading: 'Move-out date',
                  Description: '',
                  Type: 'Question',
                  Page: 0,
                  Default: false,
                  IncludedInContractPricing: null,
                  FooterText: null,
                  ChatEnabled: null,
                  ComponentType: 'Date',
                  ComponentId: 'ConnectionDateInput',
                  ComponentText: '',
                  ComponentIsMandatory: false,
                  ComponentDateMaxFutureDays: 60,
                  ComponentDateExcludeWeekend: true,
                  ComponentDateExcludeCurrentDay: true,
                  PublicHolidayExclusion: true,
                  ComponentOptions: [],
                  Links: [],
                  Options: [],
                  StatesIncluded: [],
                  DistributorIncluded: [],
                },
              },
            ],
            StatesIncluded: [],
            DistributorIncluded: [],
          },
          {
            OrderBy: 1,
            Group: 'CleaningDate',
            ProductId: null,
            Heading: 'Preferred cleaning date',
            Description: 'What is your preferred cleaning date?',
            Type: 'Question',
            Page: 0,
            Default: null,
            IncludedInContractPricing: null,
            FooterText: null,
            ChatEnabled: null,
            ComponentType: null,
            ComponentId: null,
            ComponentText: null,
            ComponentIsMandatory: false,
            ComponentDateMaxFutureDays: 0,
            ComponentDateExcludeWeekend: false,
            ComponentDateExcludeCurrentDay: false,
            PublicHolidayExclusion: false,
            ComponentOptions: [],
            Links: [],
            Options: [
              {
                OrderBy: 1,
                ProductId: '',
                Label: '',
                HelpText: 'The date you would like your property cleaned on',
                PricingHelpText: null,
                SinglePrice: null,
                BasePriceDaily: null,
                BasePriceMonthly: null,
                HasPrice: false,
                Default: null,
                StopSaleProcess: null,
                Option: {
                  OrderBy: 0,
                  Group: 'CleaningDate',
                  ProductId: null,
                  Heading: 'Preferred cleaning date',
                  Description: '',
                  Type: 'Question',
                  Page: 0,
                  Default: false,
                  IncludedInContractPricing: null,
                  FooterText: null,
                  ChatEnabled: null,
                  ComponentType: 'Date',
                  ComponentId: 'ConnectionDateInput',
                  ComponentText: '',
                  ComponentIsMandatory: false,
                  ComponentDateMaxFutureDays: 60,
                  ComponentDateExcludeWeekend: true,
                  ComponentDateExcludeCurrentDay: true,
                  PublicHolidayExclusion: true,
                  ComponentOptions: [],
                  Links: [],
                  Options: [],
                  StatesIncluded: [],
                  DistributorIncluded: [],
                },
              },
            ],
            StatesIncluded: [],
            DistributorIncluded: [],
          },
          {
            OrderBy: 1,
            Group: 'NoOfRooms',
            ProductId: null,
            Heading: 'Number of rooms requiring cleaning',
            Description: 'choose the number of rooms to be cleaned',
            Type: 'Question',
            Page: 0,
            Default: null,
            IncludedInContractPricing: null,
            FooterText: null,
            ChatEnabled: null,
            ComponentType: null,
            ComponentId: null,
            ComponentText: null,
            ComponentIsMandatory: false,
            ComponentDateMaxFutureDays: 0,
            ComponentDateExcludeWeekend: false,
            ComponentDateExcludeCurrentDay: false,
            PublicHolidayExclusion: false,
            ComponentOptions: [],
            Links: [],
            Options: [
              {
                OrderBy: 1,
                ProductId: 'NoOfRooms',
                Label: 'Please select the number of rooms to be cleaned',
                HelpText: 'Please select one of the options below',
                PricingHelpText: null,
                SinglePrice: null,
                BasePriceDaily: null,
                BasePriceMonthly: null,
                HasPrice: false,
                Default: null,
                StopSaleProcess: null,
                Option: {
                  OrderBy: 0,
                  Group: 'NoOfRooms',
                  ProductId: null,
                  Heading: 'Number of rooms',
                  Description: 'How many rooms do you want cleaned?',
                  Type: 'Question',
                  Page: 0,
                  Default: true,
                  IncludedInContractPricing: null,
                  FooterText: null,
                  ChatEnabled: null,
                  ComponentType: 'Dropdown',
                  ComponentId: 'DropdownInput',
                  ComponentText: 'Please select from the list',
                  ComponentIsMandatory: false,
                  ComponentDateMaxFutureDays: 0,
                  ComponentDateExcludeWeekend: false,
                  ComponentDateExcludeCurrentDay: false,
                  PublicHolidayExclusion: false,
                  ComponentOptions: [
                    {
                      Name: '1',
                      Value: '1',
                    },
                    {
                      Name: '2',
                      Value: '2',
                    },
                    {
                      Name: '3',
                      Value: '3',
                    },
                    {
                      Name: '4',
                      Value: '4',
                    },
                    {
                      Name: '5',
                      Value: '5',
                    },
                    {
                      Name: '6',
                      Value: '6',
                    },
                    {
                      Name: '7',
                      Value: '7',
                    },
                    {
                      Name: '8',
                      Value: '8',
                    },
                    {
                      Name: '9',
                      Value: '9',
                    },
                    {
                      Name: '10+',
                      Value: '10+',
                    },
                  ],
                  Links: [],
                  Options: [],
                  StatesIncluded: [],
                  DistributorIncluded: [],
                },
              },
            ],
            StatesIncluded: [],
            DistributorIncluded: [],
          },
          {
            OrderBy: 1,
            Group: 'FloorTypes',
            ProductId: null,
            Heading: 'What types of floors require cleaning',
            Description: 'choose the floor types to be cleaned',
            Type: 'Question',
            Page: 0,
            Default: null,
            IncludedInContractPricing: null,
            FooterText: null,
            ChatEnabled: null,
            ComponentType: null,
            ComponentId: null,
            ComponentText: null,
            ComponentIsMandatory: false,
            ComponentDateMaxFutureDays: 0,
            ComponentDateExcludeWeekend: false,
            ComponentDateExcludeCurrentDay: false,
            PublicHolidayExclusion: false,
            ComponentOptions: [],
            Links: [],
            Options: [
              {
                OrderBy: 1,
                ProductId: 'FloorTypes',
                Label: 'Please select one or more options below',
                HelpText: 'Please select one of the options below',
                PricingHelpText: null,
                SinglePrice: null,
                BasePriceDaily: null,
                BasePriceMonthly: null,
                HasPrice: false,
                Default: null,
                StopSaleProcess: null,
                Option: {
                  OrderBy: 0,
                  Group: 'FloorTypes',
                  ProductId: null,
                  Heading: 'Floor types',
                  Description: 'What types of floors do you have?',
                  Type: 'Question',
                  Page: 0,
                  Default: true,
                  IncludedInContractPricing: null,
                  FooterText: null,
                  ChatEnabled: null,
                  ComponentType: 'MultiCheckbox',
                  ComponentId: 'MultiCheckboxInput',
                  ComponentText: 'Please select from the list',
                  ComponentIsMandatory: false,
                  ComponentDateMaxFutureDays: 0,
                  ComponentDateExcludeWeekend: false,
                  ComponentDateExcludeCurrentDay: false,
                  PublicHolidayExclusion: false,
                  ComponentOptions: [
                    {
                      Name: 'Wood',
                      Value: 'Wood',
                    },
                    {
                      Name: 'Tiles',
                      Value: 'Tiles',
                    },
                    {
                      Name: 'Carpet',
                      Value: 'Carpet',
                    },
                    {
                      Name: 'Mix of floors',
                      Value: 'Mixed',
                    },
                  ],
                  Links: [],
                  Options: [],
                  StatesIncluded: [],
                  DistributorIncluded: [],
                },
              },
            ],
            StatesIncluded: [],
            DistributorIncluded: [],
          },
          {
            OrderBy: 1,
            Group: 'ParkingAvailable',
            ProductId: null,
            Heading: 'Parking availability',
            Description: 'choose what type of parking is available for the cleaner(s)',
            Type: 'Question',
            Page: 0,
            Default: null,
            IncludedInContractPricing: null,
            FooterText: null,
            ChatEnabled: null,
            ComponentType: null,
            ComponentId: null,
            ComponentText: null,
            ComponentIsMandatory: false,
            ComponentDateMaxFutureDays: 0,
            ComponentDateExcludeWeekend: false,
            ComponentDateExcludeCurrentDay: false,
            PublicHolidayExclusion: false,
            ComponentOptions: [],
            Links: [],
            Options: [
              {
                OrderBy: 1,
                ProductId: 'ParkingAvailable',
                Label: 'Please select the parking availability',
                HelpText: 'Please select one of the options below',
                PricingHelpText: null,
                SinglePrice: null,
                BasePriceDaily: null,
                BasePriceMonthly: null,
                HasPrice: false,
                Default: null,
                StopSaleProcess: null,
                Option: {
                  OrderBy: 0,
                  Group: 'ParkingAvailable',
                  ProductId: null,
                  Heading: 'Parking options',
                  Description: 'How can the cleaners park their vehicle?',
                  Type: 'Question',
                  Page: 0,
                  Default: true,
                  IncludedInContractPricing: null,
                  FooterText: null,
                  ChatEnabled: null,
                  ComponentType: 'Dropdown',
                  ComponentId: 'DropdownInput',
                  ComponentText: 'Please select from the list',
                  ComponentIsMandatory: false,
                  ComponentDateMaxFutureDays: 0,
                  ComponentDateExcludeWeekend: false,
                  ComponentDateExcludeCurrentDay: false,
                  PublicHolidayExclusion: false,
                  ComponentOptions: [
                    {
                      Name: 'On site',
                      Value: 'Onsite',
                    },
                    {
                      Name: 'Street',
                      Value: 'Street',
                    },
                  ],
                  Links: [],
                  Options: [],
                  StatesIncluded: [],
                  DistributorIncluded: [],
                },
              },
            ],
            StatesIncluded: [],
            DistributorIncluded: [],
          },
          {
            OrderBy: 1,
            Group: 'Materials',
            ProductId: null,
            Heading: 'Cleaning supplies',
            Description: 'Do you have cleaning supplies or bring our own',
            Type: 'Question',
            Page: 0,
            Default: null,
            IncludedInContractPricing: null,
            FooterText: null,
            ChatEnabled: null,
            ComponentType: null,
            ComponentId: null,
            ComponentText: null,
            ComponentIsMandatory: false,
            ComponentDateMaxFutureDays: 0,
            ComponentDateExcludeWeekend: false,
            ComponentDateExcludeCurrentDay: false,
            PublicHolidayExclusion: false,
            ComponentOptions: [],
            Links: [],
            Options: [
              {
                OrderBy: 1,
                ProductId: 'Materials',
                Label: 'Please select the materials option',
                HelpText: 'Please select one of the options below',
                PricingHelpText: null,
                SinglePrice: null,
                BasePriceDaily: null,
                BasePriceMonthly: null,
                HasPrice: false,
                Default: null,
                StopSaleProcess: null,
                Option: {
                  OrderBy: 0,
                  Group: 'Materials',
                  ProductId: null,
                  Heading: 'Materials',
                  Description: 'Do you have cleaning supplies or bring our own?',
                  Type: 'Question',
                  Page: 0,
                  Default: true,
                  IncludedInContractPricing: null,
                  FooterText: null,
                  ChatEnabled: null,
                  ComponentType: 'Dropdown',
                  ComponentId: 'DropdownInput',
                  ComponentText: 'Please select from the list',
                  ComponentIsMandatory: false,
                  ComponentDateMaxFutureDays: 0,
                  ComponentDateExcludeWeekend: false,
                  ComponentDateExcludeCurrentDay: false,
                  PublicHolidayExclusion: false,
                  ComponentOptions: [
                    {
                      Name: 'Use mine',
                      Value: 'UseMine',
                    },
                    {
                      Name: 'Please supply',
                      Value: 'SupplyOwn',
                    },
                  ],
                  Links: [],
                  Options: [],
                  StatesIncluded: [],
                  DistributorIncluded: [],
                },
              },
            ],
            StatesIncluded: [],
            DistributorIncluded: [],
          },
        ],
        HeadlinePromo: {
          Title: null,
          Description: null,
          Banner: null,
          Group: null,
          StatesIncluded: [],
        },
        Settings: [],
        PreAgreementSummary: {
          Heading: 'All set?',
          Title: 'Final recap',
          Body: "To confirm your broadband connection to Sorted Services, tap <b>I agree</b> below, which confirms that you:<br/><ul><li>Understand and agree to proceed with the broadband service</li><li>Consent to us verifying your identity information with the document issuer or official record holder.</li><li>Have read and accepted the Sorted Service's <b>Terms and Conditions</b></li></ul>",
          Footer: null,
          ButtonLabel: null,
          AcceptanceChecks: [
            {
              ComponentType: 'Checkbox',
              ComponentText: 'I agree to the above terms',
              IsMandatory: true,
            },
          ],
          LinkItems: [
            {
              Type: 'pdf',
              Name: 'CIS',
              Label: 'Critical Information Summary',
              Link: 'http://sortedservices.com/s/Sorted_CIS_NBN_HomeBundle.pdf',
              StatesIncluded: [],
            },
            {
              Type: 'pdf',
              Name: 'DDA',
              Label: 'Direct Debit Service Agreement',
              Link: 'http://sortedservices.com/s/Direct-Debit-Request-Service-Agreement.pdf',
              StatesIncluded: [],
            },
            {
              Type: 'pdf',
              Name: 'TermsConditions',
              Label: 'Terms And Conditions',
              Link: 'http:/sortedservices.com/s/Sorted-Services-Customer-Terms-Conditions.pdf',
              StatesIncluded: [],
            },
            {
              Type: 'pdf',
              Name: 'ModemSetupGuide',
              Label: 'Modem Setup Guide',
              Link: 'https://www.sortedservices.com/s/NBN_Modem_Setup_Guide.pdf',
              StatesIncluded: [],
            },
            {
              Type: 'pdf',
              Name: 'ReasonableUsePolicy',
              Label: 'Reasonable Use Policy',
              Link: 'https://www.sortedservices.com/s/Sorted_Reasonable_Use_Policy.pdf',
              StatesIncluded: [],
            },
            {
              Type: 'pdf',
              Name: 'InternationalCallRates',
              Label: 'International Call Rates',
              Link: 'https://www.sortedservices.com/s/Sorted_Services_Voice_International_Call_Rates.pdf',
              StatesIncluded: [],
            },
          ],
        },
        PostAgreementSummary: {
          Heading: 'What next',
          Title: 'Broadband setup complete!',
          Body: '<p>Check your connection status from the home screen of your app.</p>',
          Footer: null,
          ButtonLabel: null,
        },
        UiModelAttributes: {
          PlansSelector: {
            Control: 'Label',
            Type: 'Label',
            Section: 'Title',
            Name: 'PlansSelector',
            Value: 'Select a plan',
            LogoIconDisplay: true,
          },
          Benefit: {
            Control: 'Label',
            Type: 'Label',
            Section: 'Title',
            Name: 'Benefit',
            Value: 'Benefits',
            LogoIconDisplay: true,
          },
          Links: {
            Control: 'Label',
            Type: 'Label',
            Section: 'Title',
            Name: 'Links',
            Value: 'Important Information',
            LogoIconDisplay: true,
          },
          TermsAccept: {
            Control: 'Button',
            Type: 'Label',
            Section: 'Caption',
            Name: 'TermsAccept',
            Value: 'Sign Up',
            LogoIconDisplay: true,
          },
          ViewPlanOptions: {
            Control: 'Button',
            Type: 'Label',
            Section: 'Caption',
            Name: 'ViewPlanOptions',
            Value: 'View Plans',
            LogoIconDisplay: true,
          },
          Summary: {
            Control: 'boolean',
            Type: 'Flag',
            Section: 'Summary',
            Name: 'Display',
            Value: true,
            LogoIconDisplay: true,
          },
        },
        PublicHolidays: [
          '2022-01-26',
          '2022-03-14',
          '2022-04-15',
          '2022-04-16',
          '2022-04-17',
          '2022-04-18',
          '2022-04-25',
          '2022-06-13',
          '2022-09-24',
          '2022-11-01',
          '2022-12-26',
          '2022-12-27',
        ],
      },
    ],
  },
  PayloadVersion: null,
};

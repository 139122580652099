import { History } from 'history';
import { sendMessageToApp, ValidMessages } from '../../../../helper/MessageHelper';
import { routes } from '../../../../Routes';
import { ServiceType } from '../../../services/ServiceConstants';
import { MatchParams } from './AddCardConstants';
import cardLogo from '../../../../assets/credit-card-24-px.svg';
import visaIcon from '../../../../assets/group-82.svg';
import masterCardIcon from '../../../../assets/mc-symbol.svg';

export const handlePaymentSuccess = (history: History, matchParams: MatchParams) => {
  const { serviceType } = matchParams;
  switch (serviceType) {
    case ServiceType.Electricity:
      history.push(routes.setup.electricity);
      break;
    case ServiceType.ElectricityCaps:
      history.push(routes.setup.electricity);
      break;
    case ServiceType.Broadband:
      history.push(routes.setup.broadband);
      break;
    case ServiceType.EditRent:
      sendMessageToApp(ValidMessages.Dashboard);
      break;
    case ServiceType.EditElectricity:
      sendMessageToApp(ValidMessages.Dashboard);
      break;
    case ServiceType.EditBroadband:
      sendMessageToApp(ValidMessages.Dashboard);
      break;
    default:
      break;
  }
};

export const renderCardIcon = (cardType: string) => {
  switch (cardType) {
    case 'visa':
      return visaIcon;
    case 'mastercard':
      return masterCardIcon;
    default:
      return cardLogo;
  }
};

import * as React from 'react';
import { Typography, withStyles, Button } from '@material-ui/core';
import PlanButton from '../../plan/PlanButtons';
import { POWER_CONNECTION_LABELS } from './Constants';
import { Styles, PowerScreenStyles } from './Styles';

interface PropsFromParent {
  handleNext: (choice: string) => void;
}

interface PlanState {
  choice: number;
}

type OwnProps = PowerScreenStyles & PropsFromParent;

class PowerScreen extends React.Component<OwnProps, PlanState> {
  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      choice: POWER_CONNECTION_LABELS.options.indexOf('no'),
    };
  }

  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <div className={classes.top}>
            <Typography className={classes.title} variant="h5">
              {POWER_CONNECTION_LABELS.title}
            </Typography>
            <PlanButton
              options={POWER_CONNECTION_LABELS.options}
              label={POWER_CONNECTION_LABELS.buttonGroupHeading}
              selected={POWER_CONNECTION_LABELS.options.indexOf('no')}
              onChange={this.handleOptionChange}
            />
          </div>
          <Button fullWidth onClick={this.handleNextButtonClick} className={classes.button}>
            <Typography className={classes.buttonText}>
              {POWER_CONNECTION_LABELS.buttonText}
            </Typography>
          </Button>
        </div>
      </div>
    );
  }

  private handleNextButtonClick = () => {
    this.props.handleNext(POWER_CONNECTION_LABELS.options[this.state.choice]);
  };

  private handleOptionChange = (choice: number) => {
    this.setState({ choice });
  };
}

export default withStyles(Styles)(PowerScreen);

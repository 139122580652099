import { FooterPricing } from '../services/commonServices/CommonPlanSelectionInterface';

export const getMonthlyDefault = (footerPricing: FooterPricing[]) => {
  const found = footerPricing.find((f) => f.PriceField === 'BasePriceMonthly');

  if (found) {
    return found.PriceField;
  } else {
    return footerPricing && footerPricing.length > 0 ? footerPricing[0].PriceField : '';
  }
};

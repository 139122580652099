import * as React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  withStyles,
  FormHelperText,
} from '@material-ui/core';
import { Styles, AddressFormStyles } from './AddressFormStyles';

interface Props {
  label: string;
  name?: string;
  selectValues: any;
  value: string;
  style?: any;
  disabled?: boolean;
  errorMessage?: string;
  setFieldValue: (fieldName: string, fieldValue: string) => void;
  onChange?: () => void;
}
type OwnProps = AddressFormStyles & Props;

function selectComponent(props: OwnProps) {
  const { label, name, value, selectValues, classes, style, disabled, errorMessage } = props;
  const inputClass = value ? classes.inputLabelStyle : classes.noInputLabelStyle;
  return (
    <FormControl className={`${classes.selectFormControlStyle} ${style}`}>
      <InputLabel className={inputClass} error={!!errorMessage}>
        {label}
      </InputLabel>
      <Select
        className={classes.selectStyle}
        value={value}
        name={name}
        disabled={disabled}
        error={!!errorMessage}
        onChange={(event) => {
          props.setFieldValue(name as string, event.target.value as string);
          if (props.onChange) {
            props.onChange();
          }
        }}
      >
        {selectValues.map((data: any, k: number) => (
          <MenuItem value={data.value}>{data.name}</MenuItem>
        ))}
      </Select>
      {errorMessage && (
        <FormHelperText className={classes.errorMessage}>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
}

export default withStyles(Styles)(selectComponent);

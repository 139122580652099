import { takeEvery, put } from 'redux-saga/effects';
import { SpinnerActions } from '../actions/SpinnerActions';
import {
  RentServiceAction,
  RentServiceActions,
  PostServicePayload,
  RentServiceActionTypes,
  GetRentServicePayload,
  PostServiceabilityPayload,
} from '../actions/RentServiceActions';
import rentService from '../../services/rentService/RentService';
import { GetRentServiceResponse } from '../../services/rentService/getRentService/GetRentServiceResponse.data';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';

export function* getRentServiceSaga(action: RentServiceAction): IterableIterator<any> {
  try {
    yield put(SpinnerActions.show());
    const { onSuccess } = action.data as GetRentServicePayload;
    const response: GetRentServiceResponse[] = yield rentService.getRentService();
    if (onSuccess) {
      onSuccess(response);
    }
    yield put(RentServiceActions.getRentServiceSuccess(response));
  } catch (error) {
    yield put(SpinnerActions.hide());
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(RentServiceActions.getRentServiceError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* postRentServiceSaga(action: RentServiceAction): IterableIterator<any> {
  try {
    const { onSuccess } = action.data as PostServicePayload;
    yield put(SpinnerActions.show());
    const response = yield rentService.postRentService((action.data as PostServicePayload).data);
    yield put(RentServiceActions.postRentServiceSuccess(response));
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    yield put(SpinnerActions.hide());
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(RentServiceActions.postRentServiceError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* postServiceabilitySaga(action: RentServiceAction): IterableIterator<any> {
  try {
    const { onSuccess, data, leaseId } = action.data as PostServiceabilityPayload;
    yield put(SpinnerActions.show());
    const response = yield rentService.postServiceability(data, leaseId);
    yield put(RentServiceActions.postServiceabilitySuccess(response));
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    yield put(SpinnerActions.hide());
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(RentServiceActions.postServiceabilityError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

function* rentServiceWatcher(): IterableIterator<any> {
  yield takeEvery(RentServiceActionTypes.GetRentServiceStart, (action) =>
    getRentServiceSaga({
      type: action.type,
      data: (action as RentServiceAction).data,
    }),
  );
  yield takeEvery(RentServiceActionTypes.PostRentServiceStart, (action) =>
    postRentServiceSaga({
      type: action.type,
      data: (action as RentServiceAction).data,
    }),
  );
  yield takeEvery(RentServiceActionTypes.PostServiceabilityStart, (action) =>
    postServiceabilitySaga({
      type: action.type,
      data: (action as RentServiceAction).data,
    }),
  );
}
export default rentServiceWatcher;

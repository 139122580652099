import React, { FC, useEffect, useState, useMemo } from 'react';
import { withStyles } from '@material-ui/core';
import { styles, LoadingServicesStyles } from './LoadingServicesStyles';
import arrow from '../../../../assets/chevron-right.png';
import { SIZES } from '../../../../Constants';

interface LoadingServicesProps extends LoadingServicesStyles {}

const LoadingServices: FC<LoadingServicesProps> = ({ classes }) => {
  return (
    <>
      <div className={classes.card}>
        <div className={classes.loadingServiceRow}>
          <div className={classes.logo} />
          <div className={classes.title} />
          <div className={classes.action} />
        </div>
      </div>
      <div className={classes.card}>
        <div className={classes.loadingServiceRow}>
          <div className={classes.logo} />
          <div className={classes.title} />
          <div className={classes.action} />
        </div>
        <div className={classes.loadingServiceRow}>
          <div className={classes.providerTitle} />
          <div className={classes.providerIcons} />
        </div>
        <div className={classes.providerCards}>
          <div className={classes.providerCard1} />
          <div className={classes.providerCard2} />
        </div>
        <div className={classes.loadingServiceRow}>
          <div className={classes.providerTitle} />
        </div>
        <div className={classes.plans}>
          <div className={classes.plan} />
          <div className={classes.plan} />
        </div>
      </div>
      <div className={classes.card}>
        <div className={classes.loadingServiceRow}>
          <div className={classes.logo} />
          <div className={classes.title} />
          <div className={classes.action} />
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(LoadingServices);

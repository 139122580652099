import { createStyles } from '@material-ui/core/styles';
import { color } from '../../theme/Theme';

export const styles = createStyles({
  root: {
    padding: '0 16px',
    height: '100vh',
  },
  body: {
    height: '100%',
  },
  title: {
    paddingTop: 70,
  },
  concessionTypes: {
    marginTop: '25px',
  },
  concessionType: {
    padding: '16px 8px',
    cursor: 'pointer',
  },
  concessionTypeText: {
    color: '#212121',
    fontSize: '16px',
    lineHeight: 1.5,
    letterSpacing: '0.1px',
  },
  bottomText: {
    display: 'flex',
    fontSize: '14px',
    color: 'black-87',
    letterSpacing: 0.15,
    marginRight: '17px',
    marginLeft: '17px',
    marginBottom: '32px',
    textAlign: 'center',
    justifyContent: 'center',
  },
  button: {
    height: 36,
    width: '100%',
    marginBottom: '16px',
    border: '1px solid grey',
  },
  buttonText: {
    color: color.primary,
  },
});

import { takeEvery, put } from 'redux-saga/effects';
import { SummaryActions, SummaryActionTypes, SummaryAction } from '../actions/SummaryActions';
import summaryService from '../../services/summary/SummaryService';
import { PostOnboardingStatusRequest } from '../../services/summary/postOnboardingStatus/PostOnboardingStatusRequest.data';
import { SpinnerActions } from '../actions/SpinnerActions';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';

export function* postOnboardingStatusWorker(action: SummaryAction): IterableIterator<any> {
  try {
    yield put(SpinnerActions.show());
    yield summaryService.postOnboardingStatus(action.data as PostOnboardingStatusRequest);
    yield put(SummaryActions.postOnboardingStatusSuccess());
  } catch (error) {
    yield put(SpinnerActions.hide());
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(SummaryActions.postOnboardingStatusError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

function* summaryWatcher(): IterableIterator<any> {
  yield takeEvery(SummaryActionTypes.PostOnboardingStatusStart, (action) =>
    postOnboardingStatusWorker({
      type: action.type,
      data: (action as SummaryAction).data,
    }),
  );
}
export default summaryWatcher;

import { StoreAction } from '../StoreHelper';
import { Params } from '../state/HeaderState';

export enum HeaderActionTypes {
  SaveParams = 'SAVE_PARAMS',
  SaveCount = 'SAVE_COUNT',
}

export type HeaderPayload = number | Params;

export type HeaderAction = StoreAction<HeaderActionTypes, HeaderPayload>;

export class HeaderActions {
  public static saveCount(payload: number): HeaderAction {
    return {
      type: HeaderActionTypes.SaveCount,
      data: payload,
    };
  }
  public static saveParams(payload: Params): HeaderAction {
    return {
      type: HeaderActionTypes.SaveParams,
      data: payload,
    };
  }
}

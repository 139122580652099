import React, { Component } from 'react';
import { Card, Typography, withStyles } from '@material-ui/core';
import { Styles, OverViewStyles } from './OverviewStyles';
import FooterComponent from '../../footer/FooterComponent';
import { get } from 'lodash';
import { ServiceSummaryResponse } from '../../../containers/services/otherServices/OtherServicesSummary.data';
import ReactHTMLParser from 'react-html-parser';

interface PropsFromPage {
  readonly onBtnClick: () => void;
  readonly serviceSummary: ServiceSummaryResponse;
  readonly serviceName: string;
}

type OwnProps = OverViewStyles & PropsFromPage;

class Overview extends Component<OwnProps> {
  public render() {
    const { classes, onBtnClick, serviceSummary } = this.props;
    const backgroundUrl = get(serviceSummary, 'serviceProvider[0].SupplierLogoHero', undefined);
    const iconUrl = get(serviceSummary, 'serviceProvider[0].SupplierLogo', 'undefined');
    const name = get(serviceSummary, 'serviceProvider[0].SupplierName', '');
    const tagline = get(serviceSummary, 'serviceProvider[0].SupplierTagLine', '');
    const description = get(serviceSummary, 'serviceProvider[0].Description', '');
    const descriptionBanner = get(serviceSummary, 'serviceProvider[0].DescriptionBanner', '');
    return (
      <React.Fragment>
        <img src={backgroundUrl} className={classes.serviceHeaderImg} alt="Service Header Image" />
        <Card className={classes.serviceCard}>
          <div className={classes.serviceHeader}>
            <img src={iconUrl} alt="Service Logo" className={classes.serviceLogo} />
            <div className={classes.serviceHeaderText}>
              <Typography variant="h5">{name}</Typography>
              <Typography variant="subtitle1">{tagline}</Typography>
            </div>
          </div>
          <div className={classes.serviceContent}>
            <Typography variant="body2">
              <span className={classes.colorBlack}>{ReactHTMLParser(description)}</span>
              <br />
              <br />
              <span className={classes.colorBlack}>{ReactHTMLParser(descriptionBanner)}</span>
            </Typography>
          </div>
          <FooterComponent buttonText="Get Started" onClick={onBtnClick} />
        </Card>
      </React.Fragment>
    );
  }
}

export default withStyles(Styles)(Overview);

import * as React from 'react';
import ModemScreenComponent from '../../../../component/services/plan/broadband/ModemScreen';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/RootReducers';
import { Dispatch, AnyAction } from 'redux';
import { BroadbandPlanState } from '../../../../store/state/BroadbandPlanState';
import { BroadbandPlanActions } from '../../../../store/actions/BroadbandPlanAction';
import {
  BroadbandPlan,
  BroadbandPlanDetails,
  BroadbandProducts,
} from '../../../../models/plan/broadband/getPlanDetails/BroadbandPlan.data';
import PlanHelper from '../../../../helper/PlanHelper';
import { BroadbandPlanSubmit } from '../../../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import { BackHelper } from '../../../../helper/BackHelper';

interface PropsFromState {
  plan: BroadbandPlanState;
}

interface PropsFromDispatch {
  saveSelectedModemPlan: (data: string) => void;
}

type OwnProps = PropsFromDispatch & PropsFromState;

class ModemScreen extends React.Component<OwnProps> {
  public render() {
    const { plan } = this.props;
    const choosenProduct =
      plan.broadbandChoice === BroadbandProducts.ADSL
        ? (plan.broadbandPlan as BroadbandPlan).ADSL
        : (plan.broadbandPlan as BroadbandPlan).NBN;
    return (
      <ModemScreenComponent
        {...this.props}
        planDetails={choosenProduct as BroadbandPlanDetails}
        handleNext={this.handleNext}
        plan={this.props.plan}
        footerValue={this.filterFooterValue(choosenProduct as BroadbandPlanDetails) as number}
      />
    );
  }

  private filterFooterValue(planDetails: BroadbandPlanDetails) {
    const { plan } = this.props;
    const productName = PlanHelper.getProductName(
      planDetails.productNames,
      plan.dataPlan,
      plan.downloadPlan as string,
      false,
    );
    const productInfo: BroadbandPlanSubmit = PlanHelper.getProductDetails(
      planDetails.plan,
      productName,
    );
    return productInfo && productInfo.productDetails && productInfo.productDetails.monthlyCost;
  }

  private handleNext = (choice: string) => {
    BackHelper.saveCount(-1);
    this.props.saveSelectedModemPlan(choice);
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    plan: state.broadbandPlan,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): PropsFromDispatch {
  return {
    saveSelectedModemPlan: (data: string) => dispatch(BroadbandPlanActions.saveModemChoice(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModemScreen);

import {
  BroadbandPlanSubmit,
  Modem,
} from '../../../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import { BroadbandPlanDetails } from '../../../../models/plan/broadband/getPlanDetails/BroadbandPlan.data';

export const LABELS_DATA = {
  title: 'Data',
  buttonsHeading: 'Choose a data package',
  planInfo: [
    'Perfect for low-end users (email, Facebook and basic web surfing)',
    'Our most popular (streaming music, TV, online gaming and YouTube)',
    'For high-end users (streaming video, downloading movies and video games)',
  ],
};

export const LABELS_DOWNLOAD = {
  title: 'Download speed',
  buttonsHeading: 'Choose download speed',
  getInfo: (type: 'Peaktime' | 'Average', value: string) => `${type} = ${value}`,
  buttonLabels: ['Basic', 'Standard', 'Premium'],
};

export const LABELS_MODEM = {
  title: 'Modem',
  buttonsHeading: 'Choose modem option',
  getInfo: (planType: string) => `${planType} compatible modem (delivery within 3-5 business days)`,
  modemLabel: 'BYO',
};

export const LABELS_VOICE = {
  title: 'Voice',
  buttonsHeading: 'Do you want a phone line rental?',
  options: ['YES', 'NO'],
  adslButtonsHeading: 'Phone line rental',
  adslInfo: "Sorted ADSL plans include line rental at super low 'pay as you go call rates'",
  getInfo: (minCost: string, timePeriod: string) =>
    `Minimum cost $${minCost} over ${timePeriod} months`,
};

export const LABELS_CONTRACT = {
  title: 'Contract',
  buttonsHeading: 'Choose contract length',
  getInfo: (setupFee: string, minCost: number, contractLength: string) =>
    `$${setupFee} once-off setup fee (Minimum cost $${minCost} if you stay connected for ${contractLength} months)`,
  contractLabels: ['12 MONTH', 'NONE'],
  costDefaultValue: '12 months',
  getContractInfo: (minCost: number) =>
    `Minimum cost $${minCost} if you stay connected for 12 months`,
  getNoContractInfo: (minCost: number) =>
    `Minimum cost $${minCost} including setup fee and first months charges`,
};

export const DEFAULT_SELECTED_BUTTON_INDEX = 1;
export const DEFAULT_SELECTED_BUTTON_FIRST_INDEX = 0;
export const EMPTY_ARRAY_LENGTH = 0;
export const FOOTER_VALUE_NA = 'N/A';

export const getDataExtraInfo = (value: string, showModem?: boolean) =>
  `+$${value} once off set-up fee${showModem ? ' & modem' : ''}`;

export const createBroadbandPostPlanObject = (
  productInfo: BroadbandPlanSubmit,
  choosenProduct: BroadbandPlanDetails,
  contract: boolean,
  downloadPlan: string,
  voicePlan: string,
  modem: string,
  modemInfo: Modem | null,
  minimumCost: number,
  connectionFeeAmount: number,
): BroadbandPlanSubmit => {
  return {
    productDetails: {
      ...productInfo.productDetails,
    },
    modem: modem === 'BYO' ? null : modemInfo,
    contract,
    broadbandType: choosenProduct.broadbandType,
    dataSize: productInfo.dataSize,
    speedDetails: {
      ...productInfo.speedDetails,
      dataSpeedTag: downloadPlan === '' ? null : downloadPlan,
    },
    setupFee: !contract,
    setupFeeContractNo: productInfo.setupFeeContractNo,
    setupFeeContractYes: productInfo.setupFeeContractYes,
    connectionFeeAmount,
    minimumCost,
    hasVoice: voicePlan === '' ? true : voicePlan === LABELS_VOICE.options[0],
    earlyTerminationFeeContractNo: productInfo.earlyTerminationFeeContractNo,
    earlyTerminationFeeContractYes: productInfo.earlyTerminationFeeContractYes,
    connection: false,
    provisioningProductCodeCurrent: productInfo.provisioningProductCodeCurrent || '',
    voicePlanDetails: productInfo.voicePlanDetails,
    documents: productInfo.documents,
    provisioningProductCodeOriginal: productInfo.provisioningProductCodeOriginal || '',
    zone: choosenProduct.telcoService.zone,
    serviceClass: choosenProduct.telcoService.serviceClass,
    rfsDate: choosenProduct.telcoService.rfsDate,
    technologyType: choosenProduct.telcoService.technologyType,
    nbnDevelopmentFee: choosenProduct.telcoService.nbnDevelopmentFee || false,
    billingCTSTariffName: productInfo.billingCTSTariffName,
    modemIncluded: productInfo.modemIncluded,
    termsUrl: productInfo.termsUrl,
  };
};

import ApiServiceBase from '../ApiServiceBase';
import { ServiceType } from '../ServiceType.data';
import { AppSettings } from '../../AppSettings';
import ApiServiceMock from '../__mocks__/ApiServiceMock';
import ApiService from '../ApiService';
import { Leaves } from '../../models/Leaves.data';

const serviceType = ServiceType.Service;

export class LeaveListService {
  private readonly service: ApiServiceBase = AppSettings.currentTestSetting.testing
    ? new ApiServiceMock(serviceType)
    : new ApiService(serviceType);
  public getLeaveListService(year: number): Leaves[] | Promise<Leaves[]> {
    return this.service.get({
      route: [`holidays`],
      query: {
        year,
      },
    });
  }
}

const leaveListService = new LeaveListService();
export default leaveListService;

import React, { FC, useState } from 'react';
import { Typography, Card } from '@material-ui/core';

import { PlanSelectionStyles } from './PlanSelectionStyles';
import InputComponent from '../electricityService/addressForm/InputComponent';
import { SupplierOption } from './kannopiService/KanopiInterface';
import { SupplierOptions } from '../../../containers/services/commonServices/CommonPlanSelectionInterface';

interface Props {
  classes: any;
  callBackHandler: (enteredValue: string) => void;
  optionValue: SupplierOptions;
  defaultValue?: string;
}
const TextBoxComponent: FC<Props & PlanSelectionStyles> = ({
  classes,
  callBackHandler,
  optionValue,
  defaultValue,
}) => {
  const [textBoxValue, setTextBoxValue] = useState(defaultValue || '');
  return (
    <Card elevation={2} className={`${classes.detailCard} ${classes.planCardContainer}`}>
      <Typography variant="h6">{optionValue.Heading}</Typography>
      <div className={classes.seperator} />
      <Typography variant="h1" className={`${classes.headerText2} ${classes.gtwLight}`}>
        {optionValue.Description}
      </Typography>
      <InputComponent
        label={''}
        value={textBoxValue}
        setFieldValue={(fieldName, value) => {
          callBackHandler(value);
          setTextBoxValue(value);
        }}
        name="textBoxValue"
        type="text"
        style={classes.inputStyle}
      />
    </Card>
  );
};

export default TextBoxComponent;

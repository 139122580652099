import { takeEvery, put } from 'redux-saga/effects';
import { SpinnerActions } from '../actions/SpinnerActions';
import {
  PlanSummaryAction,
  GetPlanSummaryRequest,
  PlanSummaryActions,
  PlanSummaryActionTypes,
  GetServiceSummaryRequest,
  PostServiceSummaryRequest,
  GetServiceInfoRequest,
  SetServiceIdRequest,
} from '../actions/PlanSummaryActions';
import planSummaryService from '../../services/planSummary/PlanSummaryService';
import {
  PlanSummary,
  Item,
  ChildSection,
  List,
  ElectricityPlanSummary,
  ListValue,
  BroadbandPlanSummary,
  PlanSummaryV1,
} from '../../services/planSummary/getPlanSummary/GetPlanSummary.data';
import { ServiceType } from '../../containers/services/ServiceConstants';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';

export function* getPlanSummarySaga(action: PlanSummaryAction): IterableIterator<//TODO: Resolve any
any> {
  try {
    const { serviceAccountId, onSuccess, serviceSummary } = action.data as GetPlanSummaryRequest;
    yield put(SpinnerActions.show());
    const response: PlanSummary = yield planSummaryService.getPlanSummaryDetails(serviceAccountId);
    let responseData = {};
    if (response.v1Response) {
      if (serviceSummary === ServiceType.ElectricitySummary) {
        responseData = filterElectricityPlanSummary(response.v1Response);
      }
      if (serviceSummary === ServiceType.BroadbandSummary) {
        responseData = filterBroadbandPlanSummary(response.v1Response);
      }
    }
    if (onSuccess) {
      onSuccess(!!response.v1Response);
    }
    yield put(
      PlanSummaryActions.getPlanSummarySuccess(
        responseData as ElectricityPlanSummary | BroadbandPlanSummary,
      ),
    );
  } catch (error) {
    yield put(SpinnerActions.hide());
    yield put(PlanSummaryActions.getPlanSummaryError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* setServiceId(
  action: PlanSummaryAction,
  //TODO: Resolve any
): IterableIterator<any> {
  try {
    const { body, onSuccess } = action.data as SetServiceIdRequest;
    yield put(SpinnerActions.show());
    //TODO: Resolve any
    const response: any = yield planSummaryService.setServiceId(body);
    yield put(PlanSummaryActions.setServiceIdSuccess(response));
    if (onSuccess) {
      onSuccess(response);
    }
  } catch (error) {
    const { onError } = action.data;
    yield put(SpinnerActions.hide());
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(PlanSummaryActions.setServiceIdError(error));
    if (onError) {
      onError(error);
    }
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* getServiceSummaryWorker(
  action: PlanSummaryAction,
  //TODO: Resolve any
): IterableIterator<any> {
  try {
    const { serviceType, propertyId, providerName, onSuccess } =
      action.data as GetServiceSummaryRequest;
    yield put(SpinnerActions.show());
    //TODO: Resolve any
    const response: any = yield planSummaryService.getServiceSummaryDetails(
      serviceType,
      propertyId,
      providerName,
    );
    if (onSuccess) {
      onSuccess(response);
    }
    yield put(PlanSummaryActions.getServiceSummarySuccess(response));
  } catch (error) {
    const { onError } = action.data;
    yield put(SpinnerActions.hide());
    // sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(PlanSummaryActions.getServiceSummaryError(error));
    if (onError) {
      onError(error);
    }
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* postServiceSummaryWorker(
  action: PlanSummaryAction,
  //TODO: Resolve any
): IterableIterator<any> {
  try {
    const { serviceType, propertyId, body, onSuccess } = action.data as PostServiceSummaryRequest;
    yield put(SpinnerActions.show());
    //TODO: Resolve any
    const response: any = yield planSummaryService.postServiceSummaryDetails(
      serviceType,
      propertyId,
      body,
    );
    if (onSuccess) {
      onSuccess(response);
    }
    yield put(PlanSummaryActions.postServiceSummarySuccess(response));
  } catch (error) {
    const { onError } = action.data;
    yield put(SpinnerActions.hide());
    // sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(PlanSummaryActions.postServiceSummaryError(error));
    if (onError) {
      onError(error);
    }
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* getServiceInfoWorker(
  action: PlanSummaryAction,
  //TODO: Resolve any
): IterableIterator<any> {
  try {
    const { serviceAccountId } = action.data as GetServiceInfoRequest;
    yield put(SpinnerActions.show());
    //TODO: Resolve any
    const response: any = yield planSummaryService.getServiceInfo(serviceAccountId);
    yield put(PlanSummaryActions.getServiceInfoSuccess(response));
  } catch (error) {
    yield put(SpinnerActions.hide());
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(PlanSummaryActions.getServiceInfoError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

//TODO: Resolve any
function* planSummaryWatcher(): IterableIterator<any> {
  yield takeEvery(PlanSummaryActionTypes.GetPlanSummaryStart, (action) =>
    getPlanSummarySaga({
      type: action.type,
      data: (action as PlanSummaryAction).data,
    }),
  );
  yield takeEvery(PlanSummaryActionTypes.GetServiceSummaryStart, (action) =>
    getServiceSummaryWorker({
      type: action.type,
      data: (action as PlanSummaryAction).data,
    }),
  );
  yield takeEvery(PlanSummaryActionTypes.SetServiceId, (action) =>
    setServiceId({
      type: action.type,
      data: (action as PlanSummaryAction).data,
    }),
  );
  yield takeEvery(PlanSummaryActionTypes.PostServiceSummaryStart, (action) =>
    postServiceSummaryWorker({
      type: action.type,
      data: (action as PlanSummaryAction).data,
    }),
  );
  yield takeEvery(PlanSummaryActionTypes.GetServiceInfoStart, (action) =>
    getServiceInfoWorker({
      type: action.type,
      data: (action as PlanSummaryAction).data,
    }),
  );
}
export default planSummaryWatcher;

const filterElectricityPlanSummary = (response: PlanSummaryV1): ElectricityPlanSummary => {
  const monthlyPlan: ListValue[] = [];
  const energyRates: ListValue[] = [];
  const orderDetails: ListValue[] = [];
  let monthlyPlanName = '';
  let energyRatesName = '';
  let orderDetailsName = '';
  response.PlanDetails.Items.map((item: Item) => {
    if (item.Name.includes('Monthly plan')) {
      monthlyPlanName = item.Name;
      item.ChildSections.map((childSection: ChildSection) => {
        monthlyPlan.push({
          name: childSection.Name,
          value: childSection.Value,
        });
      });
    }
    if (item.Name.includes('Energy rates')) {
      energyRatesName = item.Name;
      item.ChildSections.map((childSection: ChildSection) => {
        energyRates.push({
          name: childSection.Name,
          value: childSection.Value,
        });
      });
    }
    if (item.Name.includes('Order Details')) {
      orderDetailsName = item.Name;
      item.ChildSections.map((childSection: ChildSection) => {
        orderDetails.push({
          name: childSection.Name,
          value: childSection.Value,
        });
      });
    }
  });
  return {
    orderDetails: {
      name: orderDetailsName,
      details: orderDetails,
    },
    monthlyPlan: {
      name: monthlyPlanName,
      plan: monthlyPlan,
    },
    energyRates: {
      name: energyRatesName,
      rates: energyRates,
    },
    documents: response.DocumentList,
  };
};

const filterBroadbandPlanSummary = (response: PlanSummaryV1): BroadbandPlanSummary => {
  const planDetails: ListValue[] = [];
  const orderDetails: ListValue[] = [];
  let PlanDetailsName = '';
  let orderDetailsName = '';
  response.PlanDetails.Items.map((item: Item) => {
    if (item.Name.includes('Product Details')) {
      PlanDetailsName = item.Name;
      item.ChildSections.map((childSection: ChildSection) => {
        if (childSection.List.length > 0 && childSection.Value == null) {
          let childList = ' \n';
          childSection.List.map((listItem: List) => {
            childList += `${listItem.Value}\n`;
          });
          planDetails.push({
            name: childSection.Name,
            value: childList,
          });
        } else {
          planDetails.push({
            name: childSection.Name,
            value: childSection.Value,
          });
        }
      });
    }
    if (item.Name.includes('Order Details')) {
      orderDetailsName = item.Name;
      item.ChildSections.map((childSection: ChildSection) => {
        orderDetails.push({
          name: childSection.Name,
          value: childSection.Value,
        });
      });
    }
  });
  return {
    planDetails: {
      name: PlanDetailsName,
      plan: planDetails,
    },
    orderDetails: {
      name: orderDetailsName,
      details: orderDetails,
    },
    documents: response.DocumentList,
  };
};

import * as React from 'react';
import BroadBandTypeComponent from '../../component/broadBandType/BroadBandType';
import { routes } from '../../Routes';
import { ApplicationState } from '../../store/RootReducers';
import { BroadbandPlanState } from '../../store/state/BroadbandPlanState';
import { BroadbandPlan } from '../../models/plan/broadband/getPlanDetails/BroadbandPlan.data';
import { Dispatch, AnyAction } from 'redux';
import { BroadbandPlanActions } from '../../store/actions/BroadbandPlanAction';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { History } from 'history';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';
import { PlanRequest } from '../../services/plan/getPlanDetails/GetPlanDetails.data';
import { BroadbandServiceDetails } from '../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import {
  PropertyDetails,
  ServiceAccount,
} from '../../services/electricityService/getElectricityService/GetElectricityService.data';
import { createBroadbandPlanRequest } from '../../component/broadBandType/BroadBandTypeConstants';
import { BackHelper } from '../../helper/BackHelper';

interface PropsFromState {
  plan: BroadbandPlanState;
  property: PropertyDetails;
  serviceAccountId: number;
}

interface PropsFromRoute {
  history: History;
}

interface PropsFromDispatch {
  getPlanDetails: (data: PlanRequest) => void;
  saveBroadbandChoice: (data: string) => void;
}

type OwnProps = PropsFromState &
  PropsFromDispatch &
  PropsFromRoute &
  RouteComponentProps<{ propertyId: string; paymentRefId: string }>;

class BroadBandType extends React.Component<OwnProps, {}> {
  public componentDidMount() {
    const { propertyId, paymentRefId } = this.props.match.params;
    const { property, serviceAccountId } = this.props;
    BackHelper.saveParams({
      propertyId,
      paymentRefId,
      serviceAccountId: serviceAccountId.toString(),
    });
    const plan: PlanRequest = createBroadbandPlanRequest(property.postcode, property.keyIdentifier);
    this.props.getPlanDetails(plan);
  }

  public render() {
    const { plan } = this.props;
    return (
      <BroadBandTypeComponent
        type={plan.broadbandPlan as BroadbandPlan}
        handleNext={this.handleNext}
        handleBackHome={this.handleBackHome}
        handleEdit={this.handleEdit}
      />
    );
  }
  private handleEdit = () => {
    const { propertyId } = this.props.match.params;
    this.props.history.push(routes.broadbandService.address.form(propertyId));
  };

  private handleNext = (choice: string) => {
    const { propertyId } = this.props.match.params;
    this.props.saveBroadbandChoice(choice);
    BackHelper.saveCount(-1);
    this.props.history.push(routes.broadbandService.plan.dataSelection(propertyId));
  };

  private handleBackHome() {
    sendMessageToApp(ValidMessages.Dashboard);
  }
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    plan: state.broadbandPlan,
    property: (state.broadbandService.service as BroadbandServiceDetails).serviceDetails
      .property as PropertyDetails,
    serviceAccountId: (
      (state.broadbandService.service as BroadbandServiceDetails).serviceAccount as ServiceAccount
    ).id as number,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): PropsFromDispatch {
  return {
    getPlanDetails: (data: PlanRequest) => dispatch(BroadbandPlanActions.getPlanDetailsStart(data)),
    saveBroadbandChoice: (data: string) => dispatch(BroadbandPlanActions.saveBroadbandChoice(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BroadBandType);

import rentLogo from '../../assets/home-filled.svg';
import electricityLogo from '../../assets/electricity-fill.svg';
import internetLogo from '../../assets/internet-fill.svg';
import { routes } from '../../Routes';
import { ServiceType } from '../../containers/services/ServiceConstants';

export interface ServiceDetails {
  type: string;
  label: string;
  logo: any;
}

export const serviceDeatils: ServiceDetails[] = [
  {
    type: 'Electricity',
    label: 'Electricity',
    logo: electricityLogo,
  },
  {
    type: 'Broadband',
    label: 'Broadband',
    logo: internetLogo,
  },
  {
    type: 'Rent',
    label: 'Rent',
    logo: rentLogo,
  },
];

export enum PaymentMethodType {
  Bank = 'Bank',
  Credit = 'Credit',
}

export const walletLabels = {
  PAYMENT_TITLE: 'Payment methods',
  BANK_TITLE: 'Bank account',
  ADD_NEW_PAYMENT: 'Add new payment method',
  ACCOUNT_NAME: 'Account name',
  BSB_NUMBER: 'BSB number',
  ACCOUNT_NUMBER: 'Account number',
  DELETE_BUTTON: 'Delete',
  ACCOUNT_DELETE_BUTTON: 'Delete Account',
  CARD_DELETE_BUTTON: 'Delete Card',
  CANCEL_LABEL: 'Cancel',
  CARD_NUMBER: 'Card number',
  EXPIRY_LABEL: 'Expiry',
  CVV_LABEL: 'CVV',
  DELETE_DRAWER_TITLE: 'Delete payment method',
  DELETE_DRAWER_SUBTITLE: 'Are you sure you want to delete this payment method from your wallet?',
  WARNING_DRAWER_TITLE: 'Payment method is in use',
  WARNING_DRAWER_SUBTITLE1:
    "You can't delete a payment method linked to an active service.Select a service from the dasboard, then edit, or add a payment type under 'payment details'",
  WARNING_DRAWER_SUBTITLE2: 'Active services for this payment type',
};

export const headerUrl = [
  routes.wallet.paymentOptions(ServiceType.Wallet),
  routes.wallet.bankDetails(ServiceType.Wallet),
  routes.wallet.cardDetails(ServiceType.Wallet),
];

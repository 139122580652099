import { Typography, withStyles } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { FC, useState } from 'react';
import { Styles, ConcessionCardDetailStyles } from './ConcessionCardDetailStyles';

interface ConcessionCardDetailProps {
  incrementCount: (increment: boolean, questionAnswered: boolean) => void;
  setValue: (value: string) => void;
}

const ConcessionCardDetail: FC<ConcessionCardDetailStyles & ConcessionCardDetailProps> = ({
  incrementCount,
  classes,
  setValue,
}) => {
  const [cardType, setCardType] = useState('');

  const concessionToggle = (cardTypeValue: string, buttonText: string) => {
    return (
      <ToggleButton
        classes={{ selected: classes.toggleButtonActive }}
        className={cardType === cardTypeValue ? classes.toggleButtonActive : classes.toggleButton}
        value={cardTypeValue}
        onClick={() => {
          setCardType(cardTypeValue);
          setValue(cardTypeValue);
          if (cardType.length === 0) incrementCount(true, true);
        }}
      >
        {buttonText}
      </ToggleButton>
    );
  };

  return (
    <div className={classes.cardType}>
      <Typography variant="subtitle1">Card type</Typography>
      <ToggleButtonGroup className={classes.cardToggleContainer} value={cardType}>
        {concessionToggle('PensionerConcessionCard', 'Pensioner')}
        {concessionToggle('HealthCareCard', 'Health Care')}
        {concessionToggle('GoldCard', 'Gold')}
      </ToggleButtonGroup>
    </div>
  );
};

export default withStyles(Styles)(ConcessionCardDetail);

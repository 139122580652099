import { LeaseState } from '../state/LeaseState';
import { LeaseAction, LeaseActionTypes } from '../actions/LeaseAction';
import { GetLeaseResponse } from '../../services/lease/getLease/GetLeaseResponse.data';

export const INITIAL_STATE: LeaseState = {
  lease: [],
  error: '',
  loading: false,
};

export default function LeaseReducer(state = INITIAL_STATE, action: LeaseAction): LeaseState {
  switch (action.type) {
    case LeaseActionTypes.GET_LEASE_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case LeaseActionTypes.GET_LEASE_SUCCESS:
      return {
        ...state,
        loading: false,
        lease: action.payload as GetLeaseResponse,
      };
    case LeaseActionTypes.GET_LEASE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceMock from '../__mocks__/ApiServiceMock';
import { AppSettings } from '../../AppSettings';
import ApiServiceBase from '../ApiServiceBase';
import { DownloadPdfResponse } from './DownloadPdf.data';

const serviceType = ServiceType.downloadPdf;

export class DownloadPdfService {
  private readonly service: ApiServiceBase = AppSettings.currentTestSetting.testing
    ? new ApiServiceMock(serviceType)
    : new ApiService(serviceType);
  public getPdfData(downloadCode: string): Promise<DownloadPdfResponse> | DownloadPdfResponse {
    return this.service.get({
      route: ['document'],
      query: {
        downloadCode,
      },
    });
  }
}

const downloadPdfService = new DownloadPdfService();
export default downloadPdfService;

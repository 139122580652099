import { color } from '../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  informationContainer: {
    // paddingBottom: 60,
  },
  flex: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  supplierBackgroundImgContainer: {
    width: '100%',
    height: '180px',
    position: 'relative',
  },
  supplierBackgroundImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  informationHeader: {
    position: 'relative',
    padding: 15,
    paddingBottom: 0,
  },
  supplierLogoContainer: {
    position: 'absolute',
    right: 15,
    top: -35,
    width: '70px',
    height: '70px',
    borderRadius: 5000,
    overflow: 'hidden',
    border: `${color.light} 1px solid`,
    boxSizing: 'border-box',
    backgroundColor: 'white',
  },
  supplierLogo: {
    width: 'calc(100% + 4px)',
    height: 'calc(100% + 4px)',
    objectFit: 'cover',
    objectPosition: 'center',
    position: 'relative',
    top: '-2px',
    left: '-2px',
  },
  supplierName: {
    fontFamily: 'GT Walsheim',
    fontSize: '24px',
    color: color.black87,
  },
  tabsContainer: {
    marginTop: 15,
    display: 'flex',
  },
  activeTab: {
    padding: '14px 24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
  },
  marker: {
    position: 'absolute',
    bottom: 4,
    left: 0,
    right: 0,
    height: 4,
    backgroundColor: color.lightBlue,
    margin: 'auto',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  inactiveTab: {
    padding: '14px 24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  activeTabText: {
    fontFamily: 'GT Walsheim',
    fontSize: '15px',
    color: color.black87,
  },
  inactiveTabText: {
    fontFamily: 'GT Walsheim',
    fontSize: '15px',
    color: color.black38,
  },
  section: {
    padding: 15,
    backgroundColor: color.light,
    marginTop: 15,
    borderRadius: 8,
  },
  description: {
    fontFamily: 'GT Walsheim Light',
    fontSize: '13px',
    color: color.black87,
    lineHeight: 1.5,
  },
  sectionTitle: {
    fontFamily: 'GT Walsheim',
    fontSize: '18px',
    color: color.black87,
  },
  featureContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
  },
  featureIconContainer: {
    width: 24,
    height: 24,
    marginRight: 10,
  },
  featureIcon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  featureText: {
    fontFamily: 'GT Walsheim',
    fontSize: '14px',
    color: color.black87,
  },
  testimonialTitle: {
    fontFamily: 'GT Walsheim',
    fontSize: '18px',
    color: color.black87,
    padding: 15,
  },
  testimonialContainer: {
    'display': 'flex',
    'alignItems': 'center',
    'overflow': 'scroll',
    'paddingLeft': 15,
    'height': '100%',
    'scrollbarWidth': 'none',
    'msOverflowStyle': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  testimonialCard: {
    padding: '18px 15px',
    border: `${color.borderColor} 2px solid`,
    borderRadius: 6,
    backgroundColor: color.light,
    marginRight: 15,
    minWidth: 180,
  },
  starsContainer: {
    height: 20,
    marginBottom: 25,
  },
  stars: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  testimonalText: {
    fontFamily: 'GT Walsheim',
    fontSize: '13px',
    color: color.black87,
    marginBottom: 25,
    textAlign: 'center',
  },
  testimonalSource: {
    fontFamily: 'GT Walsheim',
    fontSize: '16px',
    color: color.black38,
    textAlign: 'center',
  },
  plansContainer: {
    padding: 15,
    paddingTop: 0,
  },
  popupContainer: {
    backgroundColor: color.secondary,
    height: '100%',
  },
  popupHeader: {
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'start',
    alignItems: 'center',
    paddingLeft: 12,
    paddingRight: 12,
    height: '48px',
    backgroundColor: color.headerColor,
    position: 'relative',
  },
  popupLabel: {
    position: 'absolute',
    left: 0,
    right: 0,
    textAlign: 'center',
    margin: 'auto',
    color: color.black87,
    fontFamily: 'GT Walsheim',
    fontSize: 15,
    pointerEvents: 'none',
  },
  popupActionContainer: {
    width: 25,
    height: 25,
  },
  popupAction: {
    width: '100%',
    height: '100%',
    objectFix: 'contain',
    objectPosition: 'center',
  },
  closeContainer: {
    position: 'absolute',
    top: 15,
    right: 15,
    textAlign: 'center',
    margin: 'auto',
    color: color.black87,
    fontFamily: 'GT Walsheim',
    fontSize: 15,
    width: 30,
    height: 30,
    filter: 'invert(1)',
    cursor: 'pointer',
  },
  closeIcon: {
    width: '100%',
    height: '100%',
    objectFix: 'contain',
    objectPosition: 'center',
  },
});

export interface SupplierInformationStyles extends WithStyles<typeof styles> {}

import React, { FC, useEffect, useState, useRef } from 'react';
import { withStyles } from '@material-ui/core';
import { styles, AccountSectionStyles } from './AccountSectionStyles';
import { LABELS } from './AccountSectionConstants';
import { stringToHslColor } from '../../../../helper/GravatarHelper';
import { PersonalDetailsFormInitialValues } from '../../../../models/checkout/Checkout';
import clock from '../../../../assets/error-bold.png';
import tick from '../../../../assets/radio-filled.png';
import tickBlack from '../../../../assets/radio-filled-black.png';
import downArrow from '../../../../assets/downArrow.png';
import { ApplicationState } from '../../../../store/RootReducers';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  IdDetails,
  PersonalDetails,
} from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import PersonalDetailsComponent from '../personalDetails/PersonalDetails';
import { format } from 'date-fns';
import { isPersonalDetailsCompleted } from './AccountSectionUtils';
import MainContainer from '../../../../component/mainContainer/MainContainer';
import { AGENCIES, APP } from '../../../../helper/AppNameHelper';

interface AccountSectionProps extends AccountSectionStyles {
  personalDetails: PersonalDetails;
  setPersonalDetails: (personalDetails: PersonalDetails) => void;
  validate: boolean;
}

const AccountSection: FC<AccountSectionProps> = ({
  classes,
  personalDetails,
  setPersonalDetails,
  validate,
}) => {
  const personalInfoRef = useRef<HTMLDivElement>(null);
  const [personalInfoHeight, setPersonalInfoHeight] = useState<number>(0);
  const [personalDetailsCompleted, setPersonalDetailsCompleted] = useState<boolean>(
    isPersonalDetailsCompleted(personalDetails),
  );

  useEffect(() => {
    const personalDetailsCompleted = isPersonalDetailsCompleted(personalDetails);
    if (!personalDetailsCompleted && personalInfoHeight > 0) {
      setPersonalInfoHeight(personalInfoRef.current!.scrollHeight);
    }
  }, [personalDetails]);

  const updatePersonalDetails = (updatedDetails: PersonalDetailsFormInitialValues) => {
    const idDetails: IdDetails = {
      dlStateIssue: updatedDetails.stateIssue,
      medicareRefNumber: updatedDetails.referenceNumber,
      medicareCardColor: updatedDetails.medicareCardColor,
      passportCountry: updatedDetails.countryOfIssue,
    };
    const updatedPersonalDetails = {
      ...personalDetails,
      name: updatedDetails.firstName,
      surname: updatedDetails.lastName,
      email: updatedDetails.email,
      phone: updatedDetails.mobile,
      salutation: updatedDetails.title,
      dob: updatedDetails.dob ? format(updatedDetails.dob as Date, 'dd/MM/yyyy') : null,
      idType: updatedDetails.idType,
      idNumber: updatedDetails.idNumber,
      idExpiry: updatedDetails.idExpiry
        ? format(updatedDetails.idExpiry as Date, 'dd/MM/yyyy')
        : null,
      idDetails,
    };
    setPersonalDetailsCompleted(isPersonalDetailsCompleted(updatedPersonalDetails));
    setPersonalDetails(updatedPersonalDetails);
  };

  return (
    <MainContainer>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionTitleContainer}>
          <div className={classes.sectionTitle}>{LABELS.ACCOUNT}</div>
        </div>
        {/* Profile */}
        <div
          className={`${classes.applicationProfileCard} ${
            validate && !personalDetailsCompleted ? 'errorCard' : ''
          }`}
        >
          <div className={classes.cardHeaderContainer}>
            <div className={classes.cardTitle}>{LABELS.PERSONAL_DETAILS}</div>
            <div className={classes.cardIconContainer}>
              <img
                className={classes.cardIcon}
                src={personalDetailsCompleted ? (APP === AGENCIES.NAX ? tickBlack : tick) : clock}
                alt="status"
              />
            </div>
          </div>
          <div className={classes.planHeader}>
            <div
              className={classes.gravatar}
              style={{
                backgroundColor: stringToHslColor(
                  `${personalDetails.name}${personalDetails.surname}`,
                  70,
                  70,
                ),
              }}
            >
              {personalDetails.name.charAt(0)}
              {personalDetails.surname.charAt(0)}
            </div>
            <div className={classes.applicationProfileDetails}>
              <div
                className={classes.applicationProfileName}
              >{`${personalDetails.name} ${personalDetails.surname}`}</div>
              <div className={classes.applicationProfileContext}>{personalDetails.email}</div>
            </div>
          </div>
          <div
            className={classes.content}
            ref={personalInfoRef}
            style={{
              maxHeight: `${personalInfoHeight}px`,
              opacity: personalInfoHeight > 0 ? 1 : 0,
            }}
          >
            <PersonalDetailsComponent
              setPersonalDetails={updatePersonalDetails}
              personalDetails={personalDetails}
            />
          </div>
          <div
            className={classes.actionContainer}
            onClick={() => {
              setPersonalInfoHeight(
                personalInfoHeight === 0 ? personalInfoRef.current!.scrollHeight : 0,
              );
            }}
          >
            <div className={classes.actionTitle}>
              {personalInfoHeight > 0 ? LABELS.HIDE_PERSONAL : LABELS.SHOW_PERSONAL}
            </div>
            <div
              className={`${classes.actionIconContainer} ${
                personalInfoHeight > 0 && classes.rotate
              }`}
            >
              <img className={classes.actionIcon} src={downArrow} alt="status" />
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  personalDetails: state.checkout.personalDetails,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountSection));

import { takeEvery, put } from 'redux-saga/effects';
import { SpinnerActions } from '../actions/SpinnerActions';
import {
  ElectricityServiceAction,
  ElectricityServiceActions,
  ElectricityServiceActionTypes,
  PostServicePayload,
  GetElectricityAddressLookup,
  DeleteElectricityService,
} from '../actions/ElectricityServiceActions';
import addressLookupService from '../../services/addressLookup/AddressLookupService';
import electricityService from '../../services/electricityService/ElectricityService';
import { ElectricityAddressLookup } from '../../services/electricityService/getElectricityAddressLookup/GetElectricityAddressLookup.data';
import { ElectricityServiceDetails } from '../../services/electricityService/getElectricityService/GetElectricityService.data';
import addressService from '../../services/address/GetAddressService';
// import { sendMessageToApp, ValidMessages } from "../../helper/MessageHelper";

export function* getElectricityServiceSaga(
  action: ElectricityServiceAction,
): IterableIterator<any> {
  try {
    yield put(SpinnerActions.show());
    const response = yield electricityService.getElectricityService(action.data as number);
    const responseData: ElectricityServiceDetails = {
      serviceAccount: response.serviceAccount,
      serviceDetails: {
        ...response.serviceDetails,
        property: response.serviceDetails.serviceabilityAddress,
      },
    };
    yield put(ElectricityServiceActions.getElectricityServiceSuccess(responseData));
  } catch (error) {
    yield put(SpinnerActions.hide());
    // sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(ElectricityServiceActions.getElectricityServiceError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}
export function* getElectricityAddressLookupSaga(
  action: ElectricityServiceAction,
): IterableIterator<any> {
  try {
    yield put(SpinnerActions.show());
    const { data, onSuccess } = action.data as GetElectricityAddressLookup;
    const response: ElectricityAddressLookup =
      yield addressLookupService.postAddressLookupElectricityService(data);
    yield put(ElectricityServiceActions.getElectricityServiceAddressLookupSuccess(response));
    if (onSuccess) {
      onSuccess(response);
    }
  } catch (error) {
    const { onError } = action.data as GetElectricityAddressLookup;
    if (onError) {
      onError();
    }
    yield put(ElectricityServiceActions.getElectricityServiceAddressLookupError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* postElectricityServiceSaga(
  action: ElectricityServiceAction,
): IterableIterator<any> {
  try {
    const { onSuccess } = action.data as PostServicePayload;
    yield put(SpinnerActions.show());
    const response = yield electricityService.postElectricityService(
      action.data as PostServicePayload,
    );
    const responseData: ElectricityServiceDetails = {
      serviceAccount: response.serviceAccount,
      serviceDetails: {
        ...response.serviceDetails,
        property: response.serviceDetails.serviceabilityAddress,
      },
    };
    yield put(ElectricityServiceActions.postElectricityServiceSuccess(responseData));
    if (onSuccess) {
      onSuccess(response);
    }
  } catch (error) {
    yield put(ElectricityServiceActions.postElectricityServiceError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}
export function* deleteElectricityServiceSaga(
  action: ElectricityServiceAction,
): IterableIterator<any> {
  try {
    yield put(SpinnerActions.show());
    const { data, onSuccess } = action.data as DeleteElectricityService;
    const response = yield electricityService.deleteElectricityService(data);
    if (onSuccess) {
      onSuccess();
    }
    yield put(ElectricityServiceActions.deleteElectricityServiceSuccess(response));
  } catch (error) {
    yield put(ElectricityServiceActions.deleteElectricityServiceError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* getAddressServiceSaga(action: ElectricityServiceAction): IterableIterator<any> {
  try {
    yield put(SpinnerActions.show());
    const response = yield addressService.getAddressService(action.data as string);
    yield put(ElectricityServiceActions.getAddressSuccess(response));
  } catch (error) {
    yield put(ElectricityServiceActions.getAddressError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

function* electricityServiceWatcher(): IterableIterator<any> {
  yield takeEvery(ElectricityServiceActionTypes.GetElectricityServiceStart, (action) =>
    getElectricityServiceSaga({
      type: action.type,
      data: (action as ElectricityServiceAction).data,
    }),
  );
  yield takeEvery(ElectricityServiceActionTypes.GetElectricityServiceAddressLookupStart, (action) =>
    getElectricityAddressLookupSaga({
      type: action.type,
      data: (action as ElectricityServiceAction).data,
    }),
  );
  yield takeEvery(ElectricityServiceActionTypes.PostElectricityServiceStart, (action) =>
    postElectricityServiceSaga({
      type: action.type,
      data: (action as ElectricityServiceAction).data,
    }),
  );
  yield takeEvery(ElectricityServiceActionTypes.DeleteElectricityServiceStart, (action) =>
    deleteElectricityServiceSaga({
      type: action.type,
      data: (action as ElectricityServiceAction).data,
    }),
  );
  yield takeEvery(ElectricityServiceActionTypes.GetAddressStart, (action) =>
    getAddressServiceSaga({
      type: action.type,
      data: (action as ElectricityServiceAction).data,
    }),
  );
}
export default electricityServiceWatcher;

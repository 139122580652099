import { paymentMethod } from '../wallet/savedPaymentMethods/SavedPaymentMethodsConstants';
export interface PaymentLabel {
  paymentLabel: string;
  savedPaymentLabel: string;
  creditCardLabel: string;
  debitCardLabel: string;
  bankAccountLabel: string;
  extraPayText: any;
  buttonLabel: string;
  addNewLabel: string;
  paymentMethodDetails: any;
}

export const PAYMENT_LABELS: PaymentLabel = {
  paymentLabel: 'Payment',
  savedPaymentLabel: 'Your saved payment options',
  creditCardLabel: 'Credit card',
  debitCardLabel: 'Debit card',
  bankAccountLabel: 'Bank Account',
  extraPayText: [
    {
      type: 'Credit',
      value: (amount: string) =>
        `*A ${amount} transaction fee applies to each Credit Card transaction`,
      zeroValueMessage: `*Credit Card transactions are free`,
    },
    {
      type: 'Bank',
      value: (amount: string) =>
        `*A ${amount} transaction fee applies to each Bank Account transaction`,
      zeroValueMessage: `*Bank Account transactions are free`,
    },
  ],
  buttonLabel: 'NEXT',
  addNewLabel: 'Add new',
  paymentMethodDetails: paymentMethod,
};

export enum transactionFeeType {
  Flat = 'Flat',
  Percentage = 'Percentage',
}

export const modalLables = {
  title: 'Payment type disabled',
  subTitle:
    "Sorry, this property group doesn't accept credit card payments for rent. Please select another option.",
  buttonLabel: 'Cancel',
};

import { color } from '../../../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  contentContainer: {
    padding: '12px',
  },
  heading: {
    margin: 5,
  },
  text: {
    color: color.black87,
    fontSize: '20px',
    fontWeight: 500,
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: color.light,
    padding: '12px',
    alignItems: 'center',
    fontWeight: 600,
    color: color.black87,
    borderRadius: 8,
    border: `${color.borderColor} 2px solid`,
    marginBottom: '12px',
  },
  menuRow: {
    display: 'flex',
    alignItems: 'center',
  },
  houseContainer: {
    width: '60px',
    height: '60px',
    marginRight: '24px',
  },
  houseImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  tickContainer: {
    width: '22px',
    height: '22px',
    marginLeft: 'auto',
    marginRight: '12px',
  },
  tick: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  hideItem: {
    display: 'none',
  },
});

export interface SelectPropertyTypeStyles extends WithStyles<typeof styles> {}

import { color } from '../../../../../../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  optionsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '12px',
    marginTop: '12px',
    marginBottom: '12px',
  },
  cardContainerStyle: {
    borderRadius: 6,
    border: `${color.borderColor} 2px solid`,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    cursor: 'pointer',
    backgroundColor: color.light,
  },
  selectedCardContainerStyle: {
    border: `${color.lightBlue} 2px solid !important`,
    backgroundColor: `${color.fadedBlue} !important`,
  },
  seperator: {
    height: 1,
    margin: 4,
    marginBottom: 12,
    background: '#eee',
  },
  gtwLight: {
    fontFamily: 'GT Walsheim Light',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  headerText2: {
    fontSize: 15,
    marginTop: 5,
  },
  imageContainer: {
    width: '100%',
    height: 140,
    flexShrink: 0,
    borderRadius: 6,
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: '100%',
    objectPosition: 'center',
    objectFit: 'cover',
  },
  imageContainerCentered: {
    width: '100%',
    height: 140,
    flexShrink: 0,
    borderRadius: 6,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  imageCentered: {
    width: '100%',
    height: '70%',
    objectPosition: 'center',
    objectFit: 'contain',
  },
  content: {
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: 20,
  },
  subtitle: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: 14,
    marginTop: 4,
  },
  line: {
    marginTop: 8,
    borderBottom: `${color.borderColor} 2px solid`,
    marginBottom: 8,
  },
  priceRow: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: 12,
  },
  price: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: 26,
    marginRight: 6,
  },
  uom: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: 14,
    position: 'relative',
    top: '-4px',
  },
  body: {
    fontFamily: 'GT Walsheim',
    color: color.black38,
    fontSize: 14,
    marginBottom: 12,
  },
  viewMore: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 12,
    marginBottom: 12,
  },
  viewMoreText: {
    fontFamily: 'GT Walsheim',
    color: color.lightBlue,
    textDecoration: 'underline',
    fontSize: 14,
  },
  link: {
    width: 18,
    height: 18,
    marginLeft: 12,
  },
  primaryButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    margin: 12,
    color: color.light,
    textAlign: 'center',
    fontFamily: 'GT Walsheim',
    fontSize: 15,
    border: `${color.lightBlue} 2px solid`,
    backgroundColor: color.lightBlue,
    borderRadius: 6,
    cursor: 'pointer',
  },
  outlineButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    margin: 12,
    color: color.lightBlue,
    textAlign: 'center',
    fontFamily: 'GT Walsheim',
    fontSize: 15,
    border: `${color.lightBlue} 2px solid`,
    backgroundColor: color.light,
    borderRadius: 6,
    cursor: 'pointer',
  },
});

export interface RadioButtonOptionsStyles extends WithStyles<typeof styles> {}

import React, { FC, useEffect, useState, useRef } from 'react';
import { withStyles } from '@material-ui/core';
import { styles, SupplierStyles } from './SupplierStyles';
import { LABELS } from './SupplierConstants';
import { SupplierResponse } from '../../../../models/checkout/Checkout';
import arrow from '../../../../assets/chevron-right.png';
import { SIZES } from '../../../../Constants';

interface SupplierProps extends SupplierStyles {
  supplier: SupplierResponse;
  addMarginRight: boolean;
  addMarginLeft: boolean;
  setShowPopup: () => void;
}

const Supplier: FC<SupplierProps> = ({
  classes,
  supplier,
  addMarginRight,
  addMarginLeft,
  setShowPopup,
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const descriptionLength = screenWidth <= SIZES.sm ? 105 : 180;

  const handleTouchStart = () => {
    setIsDragging(false);
  };

  const handleTouchMove = () => {
    setIsDragging(true);
  };

  const handleTouchEnd = () => {
    if (!isDragging) {
      setShowPopup();
    }
  };

  return (
    <div
      className={classes.supplierContainer}
      style={{ marginRight: addMarginRight ? 15 : 0, marginLeft: addMarginLeft ? 15 : 0 }}
      onMouseDown={handleTouchStart}
      onMouseMove={handleTouchMove}
      onMouseUp={handleTouchEnd}
    >
      <div className={classes.backgroundImgContainer}>
        <img
          className={classes.backgroundImg}
          src={supplier.backgroundImg}
          alt="background-img"
          draggable={false}
        />
      </div>
      <div className={classes.detailsContainer}>
        <div className={classes.logoContainer}>
          <img className={classes.logo} src={supplier.logo} alt="logo" />
        </div>
        <div className={classes.contentContainer}>
          <div className={classes.supplierDetails}>
            <div className={classes.supplierTitle}>{supplier.name}</div>
            <div
              className={classes.supplierDescription}
              dangerouslySetInnerHTML={{
                __html:
                  supplier.description.length > descriptionLength
                    ? `${supplier.description.substring(0, descriptionLength)}...`
                    : supplier.description,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Supplier);

import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../Routes';
import CommonServiceOverview from '../../containers/services/commonServices/CommonServiceOverview';
import KannopiScreen from '../../containers/services/commonServices/kannopiService/KannopiService';
import CommonPlanSelection from '../../containers/services/commonServices/CommonPlanSelection';
import CommonAddressList from '../../containers/services/commonServices/addressForm/CommonAddressList';
import CommonAddressForm from '../../containers/services/commonServices/addressForm/CommonAddressForm';
import CommonAddressEmpty from '../../containers/services/commonServices/addressForm/CommonAddressEmpty';
import { CommonPlanSummary } from '../../containers/services/commonServices/CommonPlanSummary';
import PlanSuccessfulSignUp from '../../component/planSuccessfulSignUp/PlanSuccessfulSignUp';
import PersonalDetail from '../../containers/services/commonServices/CommonPersonalDetails';
import OverView from '../../containers/services/commonServices/Overview';
import TermsAndConditionsMock from '../../containers/services/termsAndConditions/PlanSelectionTnc';
import OpenNewWebPage from '../../component/services/webPage/OpenNewWebPage';

export default class CommonServicesLayout extends React.Component {
  public render() {
    return (
      <Switch>
        <Route
          path={routes.commonService.new(
            ':serviceName',
            ':propertyId',
            ':providerName',
            ':addressConfirmed',
            ':lookupRequired',
            ':serviceAccountId',
          )}
          exact
          component={CommonServiceOverview}
        />
        <Route
          path={routes.commonService.newOther(
            ':serviceName',
            ':propertyId',
            ':providerName',
            ':addressConfirmed',
            ':lookupRequired',
            ':serviceAccountId',
          )}
          exact
          component={KannopiScreen}
        />
        <Route
          path={routes.commonService.openNewWebPage(':providerName', ':propertyId', ':serviceName')}
          exact
          component={OpenNewWebPage}
        />
        <Route
          path={routes.planSuccessSignUp.new(':propertyId')}
          exact
          component={PlanSuccessfulSignUp}
        />
        <Route
          path={routes.commonService.plans(
            ':serviceName',
            ':propertyId',
            ':providerName',
            ':index',
          )}
          exact
          component={CommonPlanSelection}
        />
        <Route
          path={routes.commonService.termsAndConditions(
            ':serviceName',
            ':propertyId',
            ':providerName',
          )}
          exact
          component={TermsAndConditionsMock}
        />
        <Route
          path={routes.commonService.commonPersonalDetails(
            ':propertyId',
            ':serviceName',
            ':providerId',
            ':serviceAccountId',
          )}
          exact
          component={PersonalDetail}
        />
        <Route
          path={routes.commonService.summary(':serviceName', ':propertyId', ':providerName')}
          exact
          component={CommonPlanSummary}
        />
        <Route
          path={routes.commonService.address.overview(
            ':propertyId',
            ':serviceName',
            ':addressConfirmed',
            ':lookupRequired',
            ':providerName',
            ':serviceAccountId',
          )}
          exact
          component={OverView}
        />
        <Route
          path={routes.commonService.address.overview(
            ':propertyId',
            ':serviceName',
            ':addressConfirmed',
            ':lookupRequired',
            ':providerName',
          )}
          exact
          component={OverView}
        />
        <Route
          path={routes.commonService.address.form(
            ':serviceName',
            ':propertyId',
            ':providerName',
            ':addressConfirmed',
            ':lookupRequired',
            ':serviceAccountId',
          )}
          exact
          component={CommonAddressForm}
        />
        <Route
          path={routes.commonService.address.form(
            ':serviceName',
            ':propertyId',
            ':providerName',
            ':addressConfirmed',
            ':lookupRequired',
            ':serviceAccountId',
            ':first',
          )}
          exact
          component={CommonAddressForm}
        />
        <Route
          path={routes.commonService.address.list(
            ':propertyId',
            ':serviceName',
            ':providerName',
            ':lookupRequired',
            ':serviceAccountId',
          )}
          exact
          component={CommonAddressList}
        />
        <Route
          path={routes.commonService.address.empty(
            ':propertyId',
            ':serviceName',
            ':providerName',
            ':lookupRequired',
            ':serviceAccountId',
          )}
          exact
          component={CommonAddressEmpty}
        />
      </Switch>
    );
  }
}

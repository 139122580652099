/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../store/RootReducers';
import { Typography, withStyles, Drawer } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router';
import { PaymentActions } from '../../../store/actions/PaymentActions';
import { GetPaymentMethodsResponse } from '../../../services/payment/getPaymentMethods/GetPaymentMethods.data';
import { routes } from '../../../Routes';
import { ElectricityServiceActions } from '../../../store/actions/ElectricityServiceActions';
import {
  ElectricityServiceDetails,
  ServiceAccount,
} from '../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { BroadbandServiceActions } from '../../../store/actions/BroadbandServiceActions';
import { BroadbandServiceDetails } from '../../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import { ServiceType } from '../../services/ServiceConstants';
import { createPaymentRequestObject } from '../../../helper/PaymentHelper';
import { EditPaymentRequset } from '../../../services/editPayment/postEditPayment/PostEditPayment.data';
import { EditPaymentActions } from '../../../store/actions/EditPaymentActions';
import { createEditPaymentObject } from '../addPayment/AddPaymentUtils';
import { GetPaymentType } from '../../../services/payment/getPaymentTypes/GetPaymentTypes.data';
import { GetPaymentTypeResponse } from '../../../services/payment/getPaymentTypes/GetPaymentTypesResponse.data';
import { styles, PaymentDetailStyles } from './PaymentDetailsStyles';
import {
  MatchParams,
  PAYMENT_LABELS,
  transactionFeeType,
  modalLables,
  LABELS,
} from './PaymentDetailsConstants';
import {
  handlePaymentSuccess,
  handlePaymentTypeSuccess,
  openAddPayments,
  saveUrlParams,
} from './PaymentDetailsUtils';
import selectedIcon from '../../../assets/radio-filled.png';
import selectedIconBlack from '../../../assets/radio-filled-black.png';
import ADD_NEW_ICON from '../../../assets/images/payment/filled.png';
import { paymentTypes as PaymentType } from '../../../component/wallet/savedPaymentMethods/SavedPaymentMethodsConstants';
import NavBar from '../../../component/navBar/NavBar';
import { serviceTypeList } from '../addPayment/AddPaymentConstants';
import MainContainer from '../../../component/mainContainer/MainContainer';
import { AGENCIES, APP } from '../../../helper/AppNameHelper';
import { ValidMessages, sendMessageToApp } from '../../../helper/MessageHelper';
import { goBack } from '../../../component/header/HeaderUtils';
import { HeaderActions } from '../../../store/actions/HeaderActions';

interface PaymentDetailsProps
  extends PaymentDetailStyles,
    RouteComponentProps<MatchParams>,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const PaymentDetails: FC<PaymentDetailsProps> = ({
  classes,
  history,
  match,
  paymentMethods,
  accessToken,
  paymentTypes,
  electricityServiceId,
  broadbandServiceAccountId,
  isExternal,
  count,
  saveCount,
  getPaymentMethods,
  saveSelectedMethod,
  postElectricityPaymentDetails,
  postBroadbandServiceDetails,
  postEditPayment,
  getPaymentTypes,
}) => {
  const [index, setIndex] = useState<number | boolean>(false);
  const [payment, setPayment] = useState<boolean | GetPaymentMethodsResponse>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    if (accessToken && !paymentMethods.length && !paymentTypes.length) {
      getPaymentMethods(handlePaymentMethodsSuccess);
    }
  }, []);

  useEffect(() => {
    const { paymentRefId } = match.params;
    saveUrlParams(electricityServiceId, broadbandServiceAccountId, match.params);
    if (paymentMethods && paymentRefId) {
      const paymentIndex = paymentMethods.findIndex((payment) => payment.refId === paymentRefId);
      setIndex(paymentIndex);
    }
  }, [match.params.paymentRefId, paymentMethods]);

  const selectedPaymentIndex = useMemo<number>(() => {
    return paymentMethods.findIndex((payment) => payment.refId === match.params.paymentRefId);
  }, [match.params.paymentRefId, paymentMethods]);

  const paymentMethodEnabled = useMemo<GetPaymentTypeResponse | undefined>(() => {
    if (payment) {
      return paymentTypes.find(
        (paymentType: GetPaymentTypeResponse) =>
          paymentType.paymentMethod === (payment as GetPaymentMethodsResponse).paymentMethod,
      );
    }
    return undefined;
  }, [payment, paymentTypes]);

  const paymentMethodDetails = useMemo(() => {
    if (paymentMethodEnabled) {
      return PAYMENT_LABELS.extraPayText.find(
        (data) => data.type === paymentMethodEnabled.paymentMethod,
      );
    }
    return undefined;
  }, [paymentMethodEnabled]);

  const handleNext = (payment: GetPaymentMethodsResponse) => {
    const { serviceType, propertyId } = match.params;
    switch (serviceType) {
      case ServiceType.Electricity:
        handleElectricityServicePayment(payment);
        break;
      case ServiceType.Rent:
        saveSelectedMethod(payment);
        history.push(routes.summary.new(propertyId, serviceType));
        break;
      case ServiceType.Broadband:
        handleBroadbandServicePayment(payment);
        break;
      case ServiceType.EditRent:
        handleEditPayment(payment);
        break;
      case ServiceType.EditElectricity:
        handleEditPayment(payment);
        break;
      case ServiceType.EditBroadband:
        handleEditPayment(payment);
        break;
      default:
        break;
    }
  };

  const handlePaymentChange = (paymentToChange: GetPaymentMethodsResponse, idx: number) => {
    const isPaymentMethodEnabled = paymentTypes.find(
      (paymentType: GetPaymentTypeResponse) =>
        paymentType.paymentMethod === paymentToChange.paymentMethod,
    );
    setPayment(paymentToChange);
    if (isPaymentMethodEnabled) {
      setIndex(idx);
    } else {
      setOpenModal(true);
    }
  };

  const handleEditPayment = (payment: GetPaymentMethodsResponse) => {
    const { serviceAccountId } = match.params;
    const requestData = createEditPaymentObject(parseInt(serviceAccountId, 10), payment.refId);
    postEditPayment(requestData, () => handlePaymentSuccess(history, match.params));
  };

  const handleElectricityServicePayment = (payment: GetPaymentMethodsResponse) => {
    const { propertyId } = match.params;
    const data: ElectricityServiceDetails = createPaymentRequestObject(payment.refId);
    postElectricityPaymentDetails(
      parseInt(propertyId, 10),
      electricityServiceId as number,
      data,
      () => handlePaymentSuccess(history, match.params),
    );
  };

  const handleBroadbandServicePayment = (payment: GetPaymentMethodsResponse) => {
    const { propertyId } = match.params;
    const data: BroadbandServiceDetails = createPaymentRequestObject(payment.refId);
    postBroadbandServiceDetails(
      propertyId,
      (broadbandServiceAccountId as number).toString(),
      data,
      () => handlePaymentSuccess(history, match.params),
    );
  };

  const handlePaymentMethodsSuccess = (response: GetPaymentMethodsResponse[]) => {
    getPayments(response.length !== 0);
  };

  const getPayments = (isPaymentMethods: boolean) => {
    const { propertyId, serviceType } = match.params;
    getPaymentTypes(
      { propertyId, serviceType: serviceTypeList[serviceType] },
      isPaymentMethods,
      () => handlePaymentTypeSuccess(isPaymentMethods, history, match.params),
    );
  };

  return (
    <div className={classes.root}>
      <NavBar subTitle={LABELS.NAV_TITLE} subText={LABELS.NAV_SUBTITLE} />

      <div className={classes.body}>
        <MainContainer>
          {paymentMethods &&
            paymentMethods.map((paymentMethod: GetPaymentMethodsResponse, idx: number) => {
              const paymentMethodDetails =
                paymentMethod.paymentMethod === PaymentType.Bank
                  ? PAYMENT_LABELS.paymentMethodDetails.Bank
                  : PAYMENT_LABELS.paymentMethodDetails.Credit.find(
                      (data) => data.cardType === paymentMethod.cardType,
                    ) || PAYMENT_LABELS.paymentMethodDetails.Credit[0];
              return (
                <div
                  onClick={() => handlePaymentChange(paymentMethod, idx)}
                  className={classes.menuItem}
                >
                  <div className={classes.iconContainer}>
                    <img src={paymentMethodDetails.logo} className={classes.iconImage} />
                  </div>
                  <div className={classes.optionContainer}>
                    <div className={classes.optionTitle}>{paymentMethodDetails.name}</div>
                    <div className={classes.optionSubtitle}>
                      {paymentMethod.paymentMethod === 'Bank'
                        ? `${paymentMethod.bsb} - ${paymentMethod.accountNumber}`
                        : paymentMethod.paymentMethod === 'Credit'
                        ? `${paymentMethod.cardNumber.replace(/\./g, '*')}`
                        : '**** **** **** 1234'}
                    </div>
                  </div>
                  <div className={classes.tickContainer}>
                    {idx === index && (
                      <img
                        src={APP === AGENCIES.NAX ? selectedIconBlack : selectedIcon}
                        className={classes.tick}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          <div className={classes.menuItem} onClick={() => openAddPayments(history, match.params)}>
            <div className={classes.iconContainer}>
              <img src={ADD_NEW_ICON} className={classes.iconImage} />
            </div>
            <div className={classes.optionContainer}>
              <div className={classes.optionTitle}>{PAYMENT_LABELS.addNewLabel}</div>
            </div>
          </div>

          <div className={classes.messageBox}>
            {paymentMethodEnabled && (
              <>
                {!paymentMethodEnabled.transactionFeeValue ? (
                  <Typography variant="body2" className={classes.transactionFeeStyle}>
                    {paymentMethodDetails && paymentMethodDetails.zeroValueMessage}
                  </Typography>
                ) : (
                  <Typography variant="body2" className={classes.transactionFeeStyle}>
                    {paymentMethodDetails &&
                      paymentMethodDetails.value(
                        paymentMethodEnabled.transactionFeeType === transactionFeeType.Flat
                          ? `$${paymentMethodEnabled.transactionFeeValue}`
                          : `${paymentMethodEnabled.transactionFeeValue}%`,
                        paymentMethodEnabled.transactionFeeType === transactionFeeType.Flat
                          ? `$${paymentMethodEnabled.internationalFeeValue}`
                          : `${paymentMethodEnabled.internationalFeeValue}%`,
                      )}
                  </Typography>
                )}
              </>
            )}
          </div>
        </MainContainer>

        <div className={classes.footerButtonsContainer}>
          <MainContainer>
            <div
              className={`${
                selectedPaymentIndex === index || index === false
                  ? classes.disabled
                  : classes.footerButton
              }`}
              onClick={() => {
                if (!(selectedPaymentIndex === index || index === false)) {
                  handleNext(payment as GetPaymentMethodsResponse);
                }
              }}
            >
              {PAYMENT_LABELS.buttonLabel}
            </div>
            <div
              className={classes.footerButtonSecondary}
              onClick={() =>
                goBack(match, history, match.params, match.params.propertyId, count, saveCount)
              }
            >
              Back
            </div>
          </MainContainer>
        </div>
      </div>
      <Drawer
        anchor="bottom"
        classes={{
          paper: classes.bottomDrawerContainer,
        }}
        className={classes.bottomDrawerContainer}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div className={classes.bottomDrawer}>
          <Typography className={classes.bottomDrawerTitle}>{modalLables.title}</Typography>
          <Typography variant="body2" className={classes.disabledMessage}>
            {modalLables.subTitle}
          </Typography>
          <div className={classes.footerButtonSecondary} onClick={() => setOpenModal(false)}>
            Cancel
          </div>
        </div>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  paymentMethods: state.payment.paymentMethods,
  paymentTypes: state.payment.paymentTypes,
  accessToken: state.token.accessToken,
  count: state.headerParams.count,
  isExternal: state.token.isExternal,
  electricityServiceId:
    state.electricityService &&
    state.electricityService.service &&
    ((state.electricityService.service.serviceAccount as ServiceAccount).id as number),
  broadbandServiceAccountId:
    state.broadbandService &&
    state.broadbandService.service &&
    ((state.broadbandService.service.serviceAccount as ServiceAccount).id as number),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveCount: (data: number) => dispatch(HeaderActions.saveCount(data)),
  getPaymentMethods: (onSuccess: (response: GetPaymentMethodsResponse[]) => void) => {
    dispatch(
      PaymentActions.getPaymentMethodsStart({
        onSuccess,
      }),
    );
  },
  saveSelectedMethod: (payment: GetPaymentMethodsResponse) => {
    dispatch(PaymentActions.postPaymentTokenSuccess(payment));
  },
  postElectricityPaymentDetails: (
    propertyId: number,
    serviceAccountId: number,
    data: ElectricityServiceDetails,
    handlePaymentSuccess: () => void,
  ) => {
    dispatch(
      ElectricityServiceActions.postElectricityServiceStart({
        propertyId,
        serviceAccountId,
        data,
        onSuccess: () => {
          handlePaymentSuccess();
        },
      }),
    );
  },
  postBroadbandServiceDetails: (
    propertyId: string,
    serviceAccountId: string,
    data: BroadbandServiceDetails,
    handlePaymentSuccess: () => void,
  ) => {
    dispatch(
      BroadbandServiceActions.postBroadbandServiceStart({
        propertyId,
        serviceAccountId,
        data,
        onSuccess: () => {
          handlePaymentSuccess();
        },
      }),
    );
  },
  postEditPayment: (data: EditPaymentRequset, onSuccess: () => void) => {
    dispatch(EditPaymentActions.postEditPaymentStart({ data, onSuccess }));
  },
  getPaymentTypes: (
    data: GetPaymentType,
    isPaymentMethods: boolean,
    onSuccess: (isPaymentMethods: boolean) => void,
  ) => {
    dispatch(
      PaymentActions.getPaymentTypesStart({
        data,
        onSuccess: () => onSuccess(isPaymentMethods),
      }),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(PaymentDetails)));

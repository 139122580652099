/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useEffect, useState, useMemo, useRef } from 'react';
import { withStyles, Dialog } from '@material-ui/core';
import { styles, ServiceContainerStyles } from './ServiceContainerStyles';
import { History } from 'history';
import { ANIMATION_DELAY, PopupType, SERVICE_TYPE_TITLE } from './ServiceContainerConstants';
import { AvailableServicesResponse, PreopenDetails } from '../../../../models/checkout/Checkout';
import SwipeableViews from 'react-swipeable-views';
import addCircle from '../../../../assets/add-circle.png';
import minusCircle from '../../../../assets/minus-circle.png';
import doneCircle from '../../../../assets/radio-filled.png';
import doneCircleBlack from '../../../../assets/radio-filled-black.png';
import Supplier from '../Supplier/Supplier';
import Plan from '../Plan/Plan';
import PlanInformation from '../../../../component/planInformation/PlanInformation';
import SupplierInformation from '../../../../component/supplierInformation/SupplierInformation';
import SlideUp from '../../../../component/transitionsHelper/SlideUp';
import SlideLeft from '../../../../component/transitionsHelper/SlideLeft';
import MainContainer from '../../../../component/mainContainer/MainContainer';
import { isMiddle } from './ServiceContainerUtils';
import { ApplicationState } from '../../../../store/RootReducers';
import { Dispatch } from 'redux';
import { CheckoutActions } from '../../../../store/actions/CheckoutActions';
import { connect } from 'react-redux';
import { AGENCIES, APP } from '../../../../helper/AppNameHelper';
import { routes } from '../../../../Routes';
import { classNameGenerator } from '../../../../theme/GlobalStyles';

interface ServiceContainerProps extends ServiceContainerStyles {
  isPreopened?: boolean;
  preopenedDetails?: PreopenDetails;
  service: AvailableServicesResponse;
  rent?: boolean;
  isModalOpen: PopupType;
  isTradeRequest: boolean;
  history: History;
  toggleModal: (data: PopupType) => void;
}

const ServiceContainer: FC<ServiceContainerProps> = ({
  classes,
  service,
  isPreopened,
  preopenedDetails,
  rent,
  isModalOpen,
  isTradeRequest,
  history,
  toggleModal,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [selectedSupplier, setSelectedSupplier] = useState<number>(0);
  const [showPopup, setShowPopup] = useState<PopupType>(PopupType.NONE);
  const [openPlan, setOpenPlan] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [hasPreopened, setHasPreopened] = useState<boolean>(false);
  const [touchedSupplier, setTouchedSupplier] = useState<number>(0);

  const addMarginRight = useMemo<boolean>(() => {
    return service.suppliers
      ? service.suppliers.length > 1 && selectedSupplier !== service.suppliers.length - 1
      : false;
  }, [selectedSupplier, service]);

  const addMarginLeft = useMemo<boolean>(() => {
    return service.suppliers ? selectedSupplier >= 1 : false;
  }, [selectedSupplier, service]);

  const completed = useMemo<boolean>(() => {
    return (
      !!service.suppliers && !!service.suppliers.filter((supplier) => supplier.selected).length
    );
  }, [service]);

  const numOfPlans = useMemo<number>(() => {
    if (service.suppliers) {
      return service.suppliers.reduce((a, b) => a + b.plans.length, 0);
    }
    return 0;
  }, [service]);

  const toggleAccordion = () => {
    setHeight(height === 0 ? contentRef.current!.scrollHeight : 0);
  };

  useEffect(() => {
    if (height > 0) {
      setHeight(contentRef.current!.scrollHeight);
    }
  }, [selectedSupplier]);

  useEffect(() => {
    if (isPreopened && !hasPreopened) {
      setTimeout(() => {
        if (contentRef && contentRef.current) {
          setHasPreopened(true);
          setHeight(contentRef.current!.scrollHeight);
        }
      }, ANIMATION_DELAY);
    }
  }, [isPreopened]);

  useEffect(() => {
    if (preopenedDetails && preopenedDetails.providerId && service.suppliers) {
      const index = service.suppliers.findIndex(
        (supplier) => supplier.providerId === preopenedDetails.providerId,
      );

      if (index !== -1 && !hasPreopened) {
        setTimeout(() => {
          setSelectedSupplier(index);
        }, ANIMATION_DELAY * 2);
      }
    }
  }, [preopenedDetails]);

  return (
    <MainContainer>
      <div className={classes.serviceTypeContainer}>
        <div className={classes.serviceHeader} onClick={() => !rent && toggleAccordion()}>
          <div className={classes.serviceTypeIconContainer}>
            <img className={classes.serviceTypeIcon} src={service.logo} alt="service type" />
          </div>
          <div className={classes.serviceTypeTitle}>
            {SERVICE_TYPE_TITLE[service.type] || service.type}
          </div>
          {rent || (height === 0 && completed) ? (
            <img
              className={classes.doneContainer}
              src={APP === AGENCIES.NAX ? doneCircleBlack : doneCircle}
              alt="done"
            />
          ) : (
            <>
              {height > 0 && (
                <div className={classes.planCounter}>
                  {`(${numOfPlans} option${numOfPlans === 1 ? '' : 's'})`}
                </div>
              )}
              {!isTradeRequest && (
                <div className={classes.toggleContainer}>
                  <img
                    className={classes.toggle}
                    src={height > 0 ? minusCircle : addCircle}
                    alt="add"
                  />
                </div>
              )}
            </>
          )}
        </div>
        {service.suppliers && (
          <>
            <div
              className={classes.serviceTypeContent}
              ref={contentRef}
              style={{
                maxHeight: `${height}px`,
                opacity: height > 0 ? 1 : 0,
              }}
            >
              <div className={classes.supplierHeader}>
                <div className={classes.supplierCount}>Retailers ({service.suppliers.length})</div>
                {service.suppliers.length !== 1 && !isTradeRequest && (
                  <div className={classes.supplierLogosContainer}>
                    {service.suppliers.map((supplier, idx) => (
                      <div
                        className={classes.logoContainer}
                        key={idx}
                        onClick={() => setSelectedSupplier(idx)}
                      >
                        <img className={classes.logo} src={supplier.logo} alt="logo" />
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <SwipeableViews
                index={selectedSupplier}
                onChangeIndex={(idx: number) => setSelectedSupplier(idx)}
                resistance
                enableMouseEvents
                disabled={service.suppliers!.length === 1}
                className={classNameGenerator([
                  classes.paddingNone,
                  addMarginRight && classes.isFarLeft,
                  addMarginLeft && classes.isFarRight,
                  isMiddle(selectedSupplier, service.suppliers!.length) && classes.isMiddlePadding,
                ])}
              >
                {service.suppliers.map((supplier, index) => (
                  <div
                    className={classNameGenerator([
                      classes.gapNone,
                      (selectedSupplier === index
                        ? addMarginRight
                          ? !isMiddle(selectedSupplier, service.suppliers!.length)
                          : false
                        : addMarginRight) && classes.gapToTheRight,
                      (selectedSupplier === index
                        ? addMarginLeft
                          ? !isMiddle(selectedSupplier, service.suppliers!.length)
                          : false
                        : addMarginLeft) && classes.gapToTheLeft,
                    ])}
                  >
                    <Supplier
                      supplier={supplier}
                      key={index}
                      addMarginRight={false}
                      addMarginLeft={false}
                      setShowPopup={() => {
                        setShowPopup(PopupType.SUPPLIER);
                        toggleModal(PopupType.SUPPLIER);
                        setTouchedSupplier(index);
                      }}
                    />
                    <div className={classes.plansHeader}>{supplier.name} services</div>
                    {supplier.plans.map((plan, idx) => (
                      <Plan
                        plan={plan}
                        supplier={supplier}
                        serviceType={service.type}
                        logo={supplier.logo}
                        handleAction={() => {
                          if (!isTradeRequest) {
                            setOpenPlan(idx);
                            toggleModal(PopupType.PLAN);
                            setShowPopup(PopupType.PLAN);
                            setTouchedSupplier(index);
                          }
                        }}
                        showCompare={supplier.extendedData!.ServiceCategoryId !== 'TradeRequest'}
                        onSelection={() => {
                          if (isTradeRequest) {
                            history.push(routes.checkout.configure);
                          } else {
                            setHasPreopened(true);
                            toggleAccordion();
                          }
                        }}
                        key={index}
                      />
                    ))}
                  </div>
                ))}
              </SwipeableViews>
            </div>
            <Dialog
              open={isModalOpen !== PopupType.NONE && showPopup === PopupType.SUPPLIER}
              fullWidth
              maxWidth="sm"
              style={{ zIndex: 999999 }}
              TransitionComponent={SlideUp}
              onClose={() => {
                setShowPopup(PopupType.NONE);
                toggleModal(PopupType.NONE);
              }}
            >
              <div className={classes.popupContainer}>
                <SupplierInformation
                  supplier={service.suppliers[touchedSupplier]}
                  closePopup={() => {
                    setShowPopup(PopupType.NONE);
                    toggleModal(PopupType.NONE);
                  }}
                  serviceType={service.type}
                  isTradeRequest={isTradeRequest}
                />
              </div>
            </Dialog>
            <Dialog
              open={isModalOpen !== PopupType.NONE && showPopup === PopupType.PLAN}
              fullWidth
              maxWidth="sm"
              style={{ zIndex: 999999 }}
              TransitionComponent={SlideUp}
              onClose={() => {
                setShowPopup(PopupType.NONE);
                toggleModal(PopupType.NONE);
              }}
            >
              <div className={classes.popupContainer}>
                <PlanInformation
                  plan={service.suppliers![touchedSupplier].plans[openPlan]}
                  serviceType={service.type}
                  supplier={service.suppliers![touchedSupplier]}
                  logo={service.suppliers![touchedSupplier].logo}
                  closePopup={() => {
                    setShowPopup(PopupType.NONE);
                    toggleModal(PopupType.NONE);
                  }}
                />
              </div>
            </Dialog>
          </>
        )}
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  isModalOpen: state.checkout.isModalOpen,
  isTradeRequest: state.checkout.isTradeRequest,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleModal: (data: PopupType) => dispatch(CheckoutActions.toggleModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ServiceContainer));

export const LABELS = {
  email: 'Email address',
  title: 'Title',
  firstName: 'First name',
  lastName: 'Last name',
  mobile: 'Mobile number',
  dob: 'D.O.B',
  idType: 'ID Type',
  idNumber: 'ID Number',
  buttonLabel: 'Save',
  pageTitle: 'Personal Details',
};
export const TITLES = [
  { name: 'Mr.', value: 'Mr.' },
  { name: 'Ms.', value: 'Ms.' },
  { name: 'Mrs.', value: 'Mrs.' },
  { name: 'Dr.', value: 'Dr.' },
];

export const ID_TYPES = [
  { name: "Driver's License", value: "Driver's License" },
  { name: 'Medicare', value: 'Medicare' },
  { name: 'Passport', value: 'Passport' },
];
export const BUTTON_TEXT = 'Close';
export const labels = {
  title: 'Agreement',
  summaryTitle: 'Your plan',
  planCardTitle: 'Plan details',
  personalCardTitle: 'Personal details',
  termsAndConditionsCardTitle: 'Terms and conditions',
  termsAndConditionsCardSubTitleSignup: 'By signing up to a service you agree to our ',
  termsAndConditionsCardSubTitleAfterSignup: "View Sorted's ",
  termsAndConditionsCardSubTitle2: 'Terms of Use & Service Agreement.',
  downloadButtonText: 'DOWNLOAD ALL AS PDF',
  agreeToTermsSubText:
    'To accept this quote to switch your electricity to Sorted Electricity, tap “Agree to Terms” below, which confirms that you: ',
  agreement1: 'Understand and agree to proceed with the energy service',
  agreement2: 'Understand that you will enter a 10 business day cooling off period',
  agreement3: 'Have read and accepted Sorted Service’s Terms and Conditions',
  buttonText: 'AGREE TO TERMS',
  termsAndConditionsUrl: '/s/Sorted-Services-Customer-Terms-Conditions.pdf',
};

import { color } from '../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  root: {
    backgroundColor: color.secondary,
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 48px)',
  },
  body: {
    padding: '20px 16px 70px 16px',
  },
  agencyContainer: {
    display: 'flex',
    backgroundColor: color.light,
    padding: '16px',
    alignItems: 'center',
    fontWeight: 600,
    color: color.black87,
    borderRadius: 8,
    border: `${color.borderColor} 2px solid`,
    marginBottom: '12px',
  },
  agencyLogo: {
    width: '55px',
    height: '55px',
    borderRadius: 5000,
    backgroundColor: color.light,
  },
  messageContainer: {
    marginLeft: '16px',
  },
  messageTitle: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: '20px',
    fontWeight: 500,
  },
  messageSubtitle: {
    fontFamily: 'GT Walsheim',
    marginTop: 4,
    color: color.black38,
    fontSize: '16px',
    fontWeight: 500,
  },
  stepContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: color.light,
    padding: '24px',
    fontWeight: 600,
    color: color.black87,
    borderRadius: 8,
    border: `${color.borderColor} 2px solid`,
    marginBottom: '12px',
  },
  listBox: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 300,
  },
  listItem: {
    'fontFamily': 'GT Walsheim',
    'marginTop': 36,
    'display': 'flex',
    'flexDirection': 'row',
    'alignItems': 'center',
    '&:first-child': {
      marginTop: 0,
    },
  },
  listIcon: {
    marginRight: 24,
    height: 30,
    width: 30,
    marginLeft: 6,
  },
  listInfoBox: {},
  listTitle: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: '20px',
    fontWeight: 500,
  },
  listMessage: {
    fontFamily: 'GT Walsheim',
    color: color.black38,
    fontSize: '16px',
    fontWeight: 500,
  },
  poweredContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 24,
  },
  poweredLogo: { height: 18 },
  footerButtonsContainer: {
    bottom: 12,
    left: 12,
    right: 12,
    position: 'fixed',
  },
  footerButton: {
    padding: '16px 16px 16px 16px',
    backgroundColor: color.buttonBackgroundSecondary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.light,
    fontWeight: 600,
    borderRadius: 6,
  },
  footerButtonSecondary: {
    marginTop: 12,
    padding: '16px 16px 16px 16px',
    backgroundColor: color.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.black87,
    fontWeight: 600,
    borderRadius: 6,
    border: `${color.borderColor} 2px solid`,
  },
});

export interface WelcomeScreenStyles extends WithStyles<typeof styles> {}

import { takeEvery, put } from 'redux-saga/effects';
import {
  PropertyActionTypes,
  PropertyActions,
  PropertyAction,
} from '../actions/PropertyOnboardingAction';
import propertyService from '../../services/propertyOnboarding/PropertyOnboardingService';
import { SpinnerActions } from '../actions/SpinnerActions';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';
import { PostRequest } from '../../models/property/Property.data';

export function* postPropertySaga(action: PropertyAction): IterableIterator<any> {
  try {
    const { data } = action;
    yield propertyService.postProperty(data as PostRequest);
    yield put(PropertyActions.postPropertySuccess());
    sendMessageToApp(ValidMessages.Dashboard, { showPropertyAddedToast: true });
  } catch (error) {
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(PropertyActions.postPropertyError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

function* propertyWatcher(): IterableIterator<any> {
  yield takeEvery(PropertyActionTypes.POST_PROPERTY_REQUEST, (action) =>
    postPropertySaga({
      type: action.type,
      data: (action as PropertyAction).data,
    }),
  );
}
export default propertyWatcher;

import {
  User,
  UserType,
} from '../../services/rentService/getRentService/GetRentServiceResponse.data';

export const LABELS = {
  BACK: 'Back',
  NEXT: 'Finish',
  ACCOUNT: 'Account',
  PAYMENT: 'Payment',
  CONCESSION: 'Concession card',
  ID: 'Identification',
};

export const mockUser: User = {
  id: 0,
  name: 'Ben',
  surname: 'Kessler',
  email: 'bennyboy@gmail.com',
  phone: '0412 345 678',
  userType: UserType.Tenant,
};

import {
  AddressComponents,
  OnboardingPropertyDetails,
  PropertyClass,
  PropertyType,
} from '../../models/property/Property.data';
import { History } from 'history';
import { PropertyOnboardingStep } from './PropertyOnboardingConstants';
import villa from '../../assets/villa.svg';
import owner from '../../assets/owner.svg';
import landlord from '../../assets/landlord.svg';
import key from '../../assets/key.svg';
import apartment from '../../assets/apartment.svg';
import homeOutline from '../../assets/home-outline.svg';
import homeSolid from '../../assets/home-solid.svg';

export const createAddressString = (data: AddressComponents) => {
  if (data) {
    const unitNumber = data.unitNumber ? `${data.unitNumber}/` : '';
    return `${unitNumber}${data.streetNumber} ${data.streetName} ${data.streetType}, ${data.suburb} ${data.state} ${data.postcode}, ${data.country}`;
  }
  return '';
};

export const getPropertyLogo = (propertyType: PropertyType | undefined) => {
  switch (propertyType) {
    case PropertyType.Apartment:
      return apartment;
    case PropertyType.Home:
      return homeSolid;
    case PropertyType.Townhouse:
      return homeOutline;
    case PropertyType.Villa:
      return villa;
    default:
      return apartment;
  }
};

export const getClassLogo = (propertyClass: PropertyClass | undefined) => {
  switch (propertyClass) {
    case PropertyClass.Accomodation:
      return villa;
    case PropertyClass.Landlord:
      return landlord;
    case PropertyClass.Owner:
      return owner;
    case PropertyClass.Tenant:
      return key;
    default:
      return villa;
  }
};

export const getBtnStatus = (
  activeStep: PropertyOnboardingStep,
  propertyOnboardingState: OnboardingPropertyDetails,
) => {
  const { selectedClass, selectedType, address } = propertyOnboardingState;
  switch (activeStep) {
    case PropertyOnboardingStep.SelectPropertyType:
      return !selectedType;
    case PropertyOnboardingStep.SelectRole:
      return !selectedClass;
    case PropertyOnboardingStep.AddProperty:
    case PropertyOnboardingStep.ManualEntry:
      return !address;
    default:
      return false;
  }
};

export const checkAddress = (history: History, address: AddressComponents) => {
  const { state, streetName, streetType, suburb, streetNumber, postcode } = address;
  if (!state || !streetName || !streetType || !suburb || !streetNumber || !postcode) {
    history.push(`/onboarding/propertyOnboard/3`);
  } else {
    history.push(`/onboarding/propertyOnboard/1`);
  }
};

export const getOnboardingStep = (history: History, pageId: string) => {
  const activeStep = parseInt(pageId, 10);
  if (pageId !== 'loading') {
    if ((!activeStep && activeStep !== 0) || activeStep > 6) {
      history.push(`/onboarding/propertyOnboard/0`);
    }
    if (pageId === '' || pageId === undefined) {
      return PropertyOnboardingStep.AddProperty;
    }
  }
  switch (pageId) {
    case '0':
      return PropertyOnboardingStep.AddProperty;
    case '1':
      return PropertyOnboardingStep.SelectPropertyType;
    case '2':
      return PropertyOnboardingStep.SelectRole;
    case '3':
      return PropertyOnboardingStep.ManualEntry;
    case '4':
      return PropertyOnboardingStep.Summary;
    case 'loading':
      return PropertyOnboardingStep.Loading;
    default:
      return PropertyOnboardingStep.Loading;
  }
};

import * as React from 'react';
import { Button, Typography, withStyles, Card } from '@material-ui/core';
import { Styles, PlanFooterStyles } from './PlanFooterStyles';
import LOGO from '../../../assets/sorted-logotype-rgb.png';
import { PLAN_FOOTER_LABELS } from './PlanConstants';

interface PropsFromParent {
  amount: string | number;
  billingInfo?: string;
  extraInfo?: string | null;
  buttonText?: string;
  onButtonClick: () => void;
}

type OwnProps = PropsFromParent & PlanFooterStyles;

class PlanFooter extends React.Component<OwnProps> {
  public render() {
    const { classes, onButtonClick, buttonText, amount, billingInfo, extraInfo } = this.props;
    const extraLabel = extraInfo || '';
    return (
      <Card className={classes.card}>
        <div className={classes.cardTop}>
          <Typography variant="h5">{`$${amount}`}</Typography>
          <img className={classes.image} src={LOGO} />
        </div>
        <Typography className={classes.billingInfo} variant="subtitle1">
          {billingInfo || PLAN_FOOTER_LABELS.BILLING_INFO}
        </Typography>
        <Typography className={classes.extraInfo} variant="body2">
          {extraLabel}
        </Typography>
        <Button className={classes.button} fullWidth onClick={onButtonClick}>
          <Typography className={classes.buttonText}>
            {buttonText || PLAN_FOOTER_LABELS.BUTTON_TEXT}
          </Typography>
        </Button>
      </Card>
    );
  }
}

export default withStyles(Styles)(PlanFooter);

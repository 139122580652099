import * as React from 'react';
import { GetPaymentMethodsResponse } from '../../../services/payment/getPaymentMethods/GetPaymentMethods.data';
import Input from '../inputComponent/Input';
import lockIcon from '../../../assets/lock.svg';
import DeleteButton from '../buttonCompoent/Button';
import ConfirmationDrawer from '../confirmationDrawer/ConfirmationDrawer';
import WarningDrawer from '../warningDrawer/WarningDrawer';
import { BankDetailsStyles, styles } from './BankDetailsStyle';
import { withStyles } from '@material-ui/core';
import { walletLabels } from '../WalletConstants';

interface PropsFromParent {
  payment: GetPaymentMethodsResponse;
  handlePaymentDelete: () => void;
  showConfirmationModal: boolean;
  showWarningModal: boolean;
  handleConfirmationModal: (showConfirmationModal: boolean) => void;
  hanldeWarningModal: (showWarningModal: boolean) => void;
  services: string[];
}

type OwnProps = PropsFromParent & BankDetailsStyles;

class BankDetails extends React.Component<OwnProps, {}> {
  public render() {
    const { payment, classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.accountNameContainer}>
          <Input label={walletLabels.ACCOUNT_NAME} value={payment.accountName} />
        </div>
        <div className={classes.inputContainer}>
          <Input
            label={walletLabels.BSB_NUMBER}
            value={payment.bsb}
            haveIcon={true}
            icon={lockIcon}
          />
        </div>
        <div className={classes.inputContainer}>
          <Input
            label={walletLabels.ACCOUNT_NUMBER}
            value={payment.accountNumber}
            haveIcon={true}
            icon={lockIcon}
          />
        </div>
        <DeleteButton
          label={walletLabels.ACCOUNT_DELETE_BUTTON}
          onClick={() => this.props.handleConfirmationModal(true)}
        />
        <ConfirmationDrawer
          onCancel={() => this.props.handleConfirmationModal(false)}
          onDelete={this.props.handlePaymentDelete}
          open={this.props.showConfirmationModal}
        />
        <WarningDrawer
          onClose={() => this.props.hanldeWarningModal(false)}
          open={this.props.showWarningModal}
          services={this.props.services}
        />
      </div>
    );
  }
}

export default withStyles(styles)(BankDetails);

import React, { Component } from 'react';
import { Card, Typography, withStyles } from '@material-ui/core';
import { Styles, OverViewStyles } from './OverviewStyles';
import ReactHTMLParser from 'react-html-parser';
import sortedBackground from '../../../assets/images/sorted-background.jpg';
import { routes } from '../../../Routes';
import { get } from 'lodash';
import { Plan } from '../../../containers/services/commonServices/CommonPlanSelectionInterface';
import FooterComponent from '../../footer/FooterComponent';
import { sendMessageToApp, ValidMessages } from '../../../helper/MessageHelper';
import Spinner from '../../../containers/spinnerComponent/Spinner';

interface PropsFromPage {
  readonly onBtnClick: (route: string) => void;
  readonly serviceName: string;
}

interface Props {
  propertyId: string;
  providerName: string;
  serviceName: string;
  quotes: Plan;
}

interface PropsFromParent {
  //TODO: Resolve any
  history: any;
}

type OwnProps = PropsFromParent & OverViewStyles & PropsFromPage & Props;

class Overview extends Component<OwnProps> {
  public componentDidMount() {
    window.scrollTo(0, 0);
  }

  public render() {
    const { classes, propertyId, serviceName, providerName, quotes } = this.props;

    const nameMock =
      get(quotes, 'Plans', []).length > 0 ? 'Sorted services' : 'There are no Plans Available';
    const taglineMock = get(quotes, 'Plans', []).length > 0 ? 'Lighting the way' : '';
    const descriptionMock =
      get(quotes, 'Plans', []).length > 0
        ? '<p>Sorted is a 100% Australian owned energy company and broadband retailer.<br/><br/>\
    You can manage your services in one app, even across multiple properties. You can see everything at a glance and use live chat for help when it suits you.<br/><br/>\
    With Sorted, you don’t have to remember account numbers or passwords for multiple accounts. All your details are safe, with a record of everything so you never have to repeat yourself or look up information.</p>'
        : '';

    const icon = get(quotes, 'Plans.0.SupplierLogo', '');
    const name = get(quotes, 'Plans.0.SupplierName', nameMock);
    const tagline = get(quotes, 'Plans.0.SupplierTagLine', taglineMock);
    const description = get(quotes, 'Plans.0.Description', descriptionMock);
    const descriptionBanner = get(quotes, 'Plans.0.DescriptionBanner', '');
    const supplierBackgroundLogo = get(quotes, 'Plans.0.SupplierBackgroundLogo', sortedBackground);

    return (
      <div>
        {quotes ? (
          <div>
            <img
              src={supplierBackgroundLogo}
              className={classes.serviceHeaderImg}
              alt="Service Header Image"
            />
            <Card className={classes.serviceCard}>
              <div className={classes.serviceHeader}>
                {icon.length > 0 ? <img src={icon} className={classes.serviceLogo1} /> : undefined}
                <div className={classes.serviceHeaderText}>
                  <Typography variant="h5" style={{ fontSize: 21 }}>
                    {name}
                  </Typography>
                  <Typography variant="subtitle1">{tagline}</Typography>
                </div>
              </div>
              <div className={classes.serviceContentDesc}>
                <Typography variant="body2" className={classes.description}>
                  <span className={classes.colorBlack}>{ReactHTMLParser(description)}</span>
                  <br />
                  <span className={classes.colorBlack}>{ReactHTMLParser(descriptionBanner)}</span>
                  <br />
                </Typography>
                <br />
                <FooterComponent
                  buttonText={
                    get(quotes, 'Plans', []).length > 0
                      ? quotes.Plans[0].UiModelAttributes.ViewPlanOptions.Value
                      : 'CLOSE'
                  }
                  onClick={() => {
                    if (get(quotes, 'Plans', []).length > 0) {
                      this.props.onBtnClick(
                        routes.commonService.plans(serviceName, propertyId, providerName, '0'),
                      );
                    } else {
                      sendMessageToApp(ValidMessages.Dashboard);
                    }
                  }}
                />
              </div>
            </Card>
          </div>
        ) : (
          <Spinner overrideLoading />
        )}
      </div>
    );
  }
}

export default withStyles(Styles)(Overview);

import { StoreAction } from '../StoreHelper';
import {
  BroadbandAddressLookup,
  AddressLookup,
} from '../../services/broadbandService/getBroadbandAddressLookup/GetBroadbandAddressLookup.data';
import { BroadbandServiceDetails } from '../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import { PropertyDetails } from '../../services/electricityService/getElectricityService/GetElectricityService.data';

export enum BroadbandServiceActionTypes {
  GetBroadbandServiceStart = 'GET_BROADBAND_SERVICE_START',
  GetBroadbandServiceSuccess = 'GET_BROADBAND_SERVICE_SUCCESS',
  GetBroadbandServiceError = 'GET_BROADBAND_SERVICE_ERROR',
  GetBroadbandServiceAddressLookupStart = 'GET_BROADBAND_SERVICE_ADDRESS_LOOKUP_START',
  GetBroadbandServiceAddressLookupSuccess = 'GET_BROADBAND_SERVICE_ADDRESS_LOOKUP_SUCCESS',
  GetBroadbandServiceAddressLookupError = 'GET_BROADBAND_SERVICE_ADDRESS_LOOKUP_ERROR',
  SaveProperty = 'SAVE_PROPERTY',
  PostBroadbandServiceStart = 'POST_BROADBAND_SERVICE_START',
  PostBroadbandServiceSuccess = 'POST_BROADBAND_SERVICE_SUCCESS',
  PostBroadbandServiceError = 'POST_BROADBAND_SERVICE_ERROR',
  DeleteBroadbandServiceStart = 'DELETE_BROADBAND_SERVICE_START',
  DeleteBroadbandServiceSuccess = 'DELETE_BROADBAND_SERVICE_SUCCESS',
  DeleteBroadbandServiceError = 'DELETE_BROADBAND_SERVICE_ERROR',
  GetBroadbandAddressStart = 'GET_BROADBAND_ADDRESS_START',
  GetBroadbandAddressSuccess = 'GET_BROADBAND_ADDRESS_SUCCESS',
  GetBroadbandAddressError = 'GET_BROADBAND_ADDRESS_ERROR',
}

export interface BroadbandServiceErrorPayload {
  readonly error: Error | any;
}

export interface PostServicePayload {
  propertyId: string;
  serviceAccountId: string | null;
  data: BroadbandServiceDetails;
  onSuccess?: (response?: BroadbandServiceDetails) => void;
}

export interface GetBroadbandAddressLookup {
  data: AddressLookup;
  onSuccess: (response: BroadbandAddressLookup) => void;
  onError: () => void;
}

export interface DeleteBroadbandService {
  data: string;
  onSuccess: () => void;
}
export type BroadbandServicePayload =
  | BroadbandAddressLookup
  | GetBroadbandAddressLookup
  | AddressLookup
  | BroadbandServiceDetails
  | PostServicePayload
  | string
  | BroadbandServiceErrorPayload
  | PropertyDetails
  | DeleteBroadbandService;

export type BroadbandServiceAction = StoreAction<
  BroadbandServiceActionTypes,
  BroadbandServicePayload
>;

export class BroadbandServiceActions {
  public static getBroadbandServiceStart(data: string): BroadbandServiceAction {
    return {
      type: BroadbandServiceActionTypes.GetBroadbandServiceStart,
      data,
    };
  }
  public static getBroadbandServiceSuccess(data: BroadbandServiceDetails): BroadbandServiceAction {
    return {
      type: BroadbandServiceActionTypes.GetBroadbandServiceSuccess,
      data,
    };
  }
  public static getBroadbandServiceError(
    data: BroadbandServiceErrorPayload,
  ): BroadbandServiceAction {
    return {
      type: BroadbandServiceActionTypes.GetBroadbandServiceError,
      data,
    };
  }
  public static getBroadbandServiceAddressLookupStart(
    data: GetBroadbandAddressLookup,
  ): BroadbandServiceAction {
    return {
      type: BroadbandServiceActionTypes.GetBroadbandServiceAddressLookupStart,
      data,
    };
  }
  public static getBroadbandServiceAddressLookupSuccess(
    data: BroadbandAddressLookup,
  ): BroadbandServiceAction {
    return {
      type: BroadbandServiceActionTypes.GetBroadbandServiceAddressLookupSuccess,
      data,
    };
  }
  public static getBroadbandServiceAddressLookupError(
    data: BroadbandServiceErrorPayload,
  ): BroadbandServiceAction {
    return {
      type: BroadbandServiceActionTypes.GetBroadbandServiceAddressLookupError,
      data,
    };
  }
  public static saveProperty(data: PropertyDetails): BroadbandServiceAction {
    return {
      type: BroadbandServiceActionTypes.SaveProperty,
      data,
    };
  }
  public static postBroadbandServiceStart(data: PostServicePayload): BroadbandServiceAction {
    return {
      type: BroadbandServiceActionTypes.PostBroadbandServiceStart,
      data,
    };
  }
  public static postBroadbandServiceSuccess(data: BroadbandServiceDetails): BroadbandServiceAction {
    return {
      type: BroadbandServiceActionTypes.PostBroadbandServiceSuccess,
      data,
    };
  }
  public static postBroadbandServiceError(
    data: BroadbandServiceErrorPayload,
  ): BroadbandServiceAction {
    return {
      type: BroadbandServiceActionTypes.PostBroadbandServiceError,
      data,
    };
  }
  public static deleteBroadbandServiceStart(data: DeleteBroadbandService): BroadbandServiceAction {
    return {
      type: BroadbandServiceActionTypes.DeleteBroadbandServiceStart,
      data,
    };
  }
  public static deleteBroadbandServiceSuccess(
    data: BroadbandServiceDetails,
  ): BroadbandServiceAction {
    return {
      type: BroadbandServiceActionTypes.DeleteBroadbandServiceSuccess,
      data,
    };
  }
  public static deleteBroadbandServiceError(
    data: BroadbandServiceErrorPayload,
  ): BroadbandServiceAction {
    return {
      type: BroadbandServiceActionTypes.DeleteBroadbandServiceError,
      data,
    };
  }
  public static getBroadbandAddressStart(propertyId: string): BroadbandServiceAction {
    return {
      type: BroadbandServiceActionTypes.GetBroadbandAddressStart,
      data: propertyId,
    };
  }
  public static getBroadbandAddressSuccess(data: PropertyDetails): BroadbandServiceAction {
    return {
      type: BroadbandServiceActionTypes.GetBroadbandAddressSuccess,
      data,
    };
  }
  public static getBroadbandAddressError(
    data: BroadbandServiceErrorPayload,
  ): BroadbandServiceAction {
    return {
      type: BroadbandServiceActionTypes.GetBroadbandAddressError,
      data,
    };
  }
}

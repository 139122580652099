import { color } from '../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  planInformationContainer: {
    padding: 15,
    paddingTop: 0,
    // paddingBottom: 60,
  },
  noPadding: {
    padding: 0,
    marginTop: -15,
  },
  compareScroll: {
    position: 'relative',
    height: 'calc(100vh - 280px)',
  },
  section: {
    marginTop: 15,
    padding: 15,
    backgroundColor: color.light,
    border: `${color.borderColor} 2px solid`,
    borderRadius: 6,
    // '&:first-child': {
    //   marginTop: 5,
    // },
  },
  noBorder: {
    border: 'none !important',
    marginTop: 0,
  },
  planHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logoContainer: {
    height: '40px',
    width: '40px',
  },
  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  titleContainer: {
    marginLeft: 15,
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    paddingBottom: 3,
  },
  title: {
    fontFamily: 'GT Walsheim',
    fontSize: '13px',
    color: color.black38,
    textTransform: 'uppercase',
  },
  description: {
    fontFamily: 'GT Walsheim',
    fontSize: '17px',
    color: color.black87,
  },
  priceContainer: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  from: {
    fontFamily: 'GT Walsheim',
    fontSize: '14px',
    color: color.black38,
    opacity: 0.6,
  },
  priceWrapper: {
    display: 'flex',
    alignItems: 'baseline',
  },
  price: {
    fontFamily: 'GT Walsheim',
    fontWeight: 500,
    fontSize: '20px',
    color: color.lightBlue,
  },
  uom: {
    fontFamily: 'GT Walsheim',
    fontSize: '12px',
    color: color.lightBlue,
  },
  sectionTitle: {
    fontFamily: 'GT Walsheim',
    fontSize: '18px',
    color: color.black87,
    marginBottom: 10,
  },
  textContainer: {
    marginTop: 10,
  },
  regularText: {
    fontFamily: 'GT Walsheim Light',
    fontSize: '14px',
    color: color.black87,
  },
  boldText: {
    marginRight: 5,
    fontFamily: 'GT Walsheim',
    fontSize: '14px',
    color: color.black87,
  },
  link: {
    fontFamily: 'GT Walsheim',
    fontSize: '14px',
    color: color.lightBlue,
    textDecoration: 'underline',
  },
  highlight: {
    fontFamily: 'GT Walsheim Light',
    fontSize: '14px',
    color: color.lightBlue,
  },
  popupHeader: {
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'end',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    height: '48px',
    marginTop: 10,
  },
  popupActionContainer: {
    width: 25,
    height: 25,
    cursor: 'pointer',
  },
  popupAction: {
    width: '100%',
    height: '100%',
    objectFix: 'contain',
    objectPosition: 'center',
  },
  primaryButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 40,
    color: color.light,
    textAlign: 'center',
    fontFamily: 'GT Walsheim',
    fontSize: 15,
    border: `${color.buttonBackgroundSecondary} 2px solid`,
    backgroundColor: color.buttonBackgroundSecondary,
    borderRadius: 6,
    marginTop: 15,
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  card: {
    borderRadius: 6,
    border: `${color.borderColor} 2px solid`,
    marginTop: 15,
    backgroundColor: color.light,
    overflow: 'hidden',
  },
  cardHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: 15,
    marginRight: 15,
    borderBottom: `${color.borderColor} 2px solid`,
  },
  content: {
    overflow: 'hidden',
    transition: 'all 0.3s ease-out',
    height: '100%',
    paddingLeft: 15,
    paddingRight: 15,
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 15px',
    backgroundColor: color.secondary,
    borderTop: `${color.borderColor} 2px solid`,
    cursor: 'pointer',
  },
  actionTitle: {
    marginRight: 'auto',
    fontFamily: 'GT Walsheim',
    fontSize: '14px',
    color: color.black87,
  },
  actionIconContainer: {
    marginLeft: 'auto',
    width: 16,
    height: 16,
    transition: 'all 0.3s ease-out',
    cursor: 'pointer',
  },
  actionIcon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
  cardTitle: {
    marginRight: 'auto',
    fontFamily: 'GT Walsheim',
    fontSize: '16px',
    color: color.black87,
  },
  planScroll: {
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    height: 'calc(100% - 190px)',
    marginTop: 15,
    pointerEvents: 'all',
  },
  infoText: {
    fontFamily: 'GT Walsheim',
    fontSize: '11px',
    color: color.black38,
    marginTop: 15,
    opacity: 0.6,
    lineHeight: 1.5,
  },
});

export interface PlanInformationStyles extends WithStyles<typeof styles> {}

import React, { FC, useEffect, useState, useMemo, createRef, useRef } from 'react';
import { withStyles } from '@material-ui/core';
import backArrow from '../../assets/arrow-back-24-px.svg';
import backArrowBlack from '../../assets/arrow-back-black.svg';
import { styles, CheckoutCompareStyles } from './CheckoutCompareStyles';
import { AGENCIES, APP } from '../../helper/AppNameHelper';
import { LABELS } from './CheckoutCompareConstants';
import ServiceTabs from '../../containers/checkoutConfigure/components/ServiceTabs/ServiceTabs';
import { useCart } from '../../helper/CheckoutHelper';
import { ModifyCart, PageName } from '../../models/checkout/Checkout';
import PlanInformation from '../planInformation/PlanInformation';
import MainContainer from '../mainContainer/MainContainer';
import { ApplicationState } from '../../store/RootReducers';
import { Dispatch } from 'redux';
import { CheckoutActions } from '../../store/actions/CheckoutActions';
import { connect } from 'react-redux';
import closeIcon from '../../assets/close.png';

interface CheckoutCompareProps extends CheckoutCompareStyles {
  closePopup: () => void;
  removeFromCompare: (data: ModifyCart) => void;
}

const CheckoutCompare: FC<CheckoutCompareProps> = ({ classes, closePopup, removeFromCompare }) => {
  const { compareItems } = useCart();
  const [activeTab, setActiveTab] = useState<number>(compareItems.length - 1);
  const clientX = useRef(0);
  const scrollX = useRef(0);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [planRefs, setPlanRefs] = useState<any>(undefined);
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (compareItems && compareItems.length > 0) {
      let updatedplanRefs = {};
      compareItems[activeTab].suppliers!.map((supplier) => {
        supplier.plans!.map((plan) => {
          plan.planInformation &&
            plan.planInformation.length > 0 &&
            plan.planInformation.map((planInfo) => {
              updatedplanRefs = {
                ...updatedplanRefs,
                [`${plan.title}_${planInfo.sectionTitle}`]: {
                  ref: createRef(),
                  height: 0,
                },
              };
            });
        });
      });
      setPlanRefs(updatedplanRefs);
    }
  }, [activeTab, compareItems]);

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    clientX.current = e.clientX;
    setIsScrolling(true);
  };

  const onMouseUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsScrolling(false);
  };

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (scrollRef && scrollRef.current && isScrolling) {
      const dx = e.clientX - clientX.current;

      scrollRef.current.scrollLeft = scrollX.current - dx;
      scrollX.current -= dx;
      clientX.current = e.clientX;
    }
  };

  useEffect(() => {
    document.addEventListener('backbutton', () => closePopup());
    return () => document.removeEventListener('backbutton', () => null);
  }, []);

  const updateRefsHeight = (key: string) => {
    let updatedPlanRefs = { ...planRefs };
    compareItems[activeTab].suppliers!.map((supplier) => {
      supplier.plans!.map((plan) => {
        updatedPlanRefs = {
          ...updatedPlanRefs,
          [`${plan.title}_${key}`]: {
            ...updatedPlanRefs[`${plan.title}_${key}`],
            height: updatedPlanRefs[`${plan.title}_${key}`]
              ? updatedPlanRefs[`${plan.title}_${key}`].height === 0
                ? updatedPlanRefs[`${plan.title}_${key}`].ref
                  ? updatedPlanRefs[`${plan.title}_${key}`].ref.current!.scrollHeight
                  : 0
                : 0
              : 0,
          },
        };
      });
    });
    setPlanRefs(updatedPlanRefs);
  };

  useEffect(() => {
    if (compareItems.length) {
      if (
        compareItems[activeTab] === undefined ||
        compareItems[activeTab].suppliers === undefined
      ) {
        setActiveTab(compareItems.length - 1);
      }
    } else {
      closePopup();
    }
  }, [activeTab, compareItems]);

  return (
    <>
      <div className={classes.popupHeader}>
        <div className={classes.flex}>
          <div className={classes.popupActionContainer} onClick={() => closePopup()}>
            <img className={classes.popupAction} src={backArrowBlack} alt="backIcon" />
          </div>
          <div className={classes.popupLabel}>{LABELS.COMPARE}</div>
          <div className={classes.closeContainer} onClick={() => closePopup()}>
            <img className={classes.closeIcon} src={closeIcon} alt="backIcon" />
          </div>
        </div>
      </div>

      <ServiceTabs
        activeTab={activeTab}
        setActiveTab={(value: number) => {
          if (value !== activeTab) {
            setPlanRefs(undefined);
            setActiveTab(value);
          }
        }}
        pageName={PageName.CHECKOUTCOMPARE}
        key={`service_tab_${activeTab}`}
        compare
      />
      <div
        className={classes.informationContainer}
        key={`plan_information_${activeTab}`}
        ref={scrollRef}
        onMouseMove={(e) => onMouseMove(e)}
        onMouseDown={(e) => onMouseDown(e)}
        onMouseUp={(e) => onMouseUp(e)}
      >
        {!!planRefs
          ? compareItems.length && compareItems[activeTab] && compareItems[activeTab].suppliers
            ? compareItems[activeTab].suppliers!.map((supplier, idx) =>
                supplier.plans.map((plan, index) => (
                  <div
                    key={`${plan.productId}`}
                    className={`${classes.compareItem} ${
                      index !== 0 || idx !== 0 ? classes.marginLeft : undefined
                    }`}
                  >
                    <PlanInformation
                      plan={plan}
                      serviceType={compareItems[activeTab].type}
                      supplier={supplier}
                      logo={supplier.logo}
                      closePopup={() =>
                        removeFromCompare({
                          productId: plan.productId,
                          providerId: supplier.providerId,
                          serviceType: compareItems[activeTab].type,
                        })
                      }
                      compare
                      planRefs={planRefs}
                      updateRefsHeight={updateRefsHeight}
                      compareMode
                    />
                  </div>
                )),
              )
            : null
          : null}
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  removeFromCompare: (data: ModifyCart) => dispatch(CheckoutActions.removeFromCompare(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CheckoutCompare));

export const LABELS = {
  REMIND: 'Remind me later',
  GET_STARTED: 'Get started',
  FINISH: 'Close',
  NOTHIN:
    'This may be because your place is not in our range or you are already connected to everything we can currently offer',
  NOTHINGTITLE: 'No services available at your address',
  CONTACT_SUPPORT: 'Contact support',
  TITLE: 'Add services to get started',
  TITLE_TRADE_REQUEST: 'Select a service',
  LOADING_SERVICES: 'Loading services',
};

import { color } from '../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  root: {
    height: '100vh',
  },
  body: {
    padding: '0 16px 20px 16px',
    display: 'flex',
    flexDirection: 'column',
  },
  messageBox: {
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    bottom: 0,
    right: 0,
    left: 0,
    padding: 16,
    position: 'fixed',
    textAlign: 'center',
  },
  paymentLabelStyle: {
    marginTop: 65,
    marginBottom: '4vh',
  },
  savedPaymentLabelStyle: {
    color: color.dark,
  },
  iconStyle: {
    width: 24,
    height: 24,
    margin: 6,
  },
  cardIconStyle: {
    width: 36,
    height: 24,
  },
  listStyle: {
    marginBottom: '2vh',
  },
  listItemStyle: {
    paddingLeft: 0,
    marginTop: 10,
    marginBottom: '2vh',
  },
  itemTextStyle: {
    marginLeft: 23,
  },
  listItemTitle: {
    fontSize: 14,
  },
  listItemSubtitle: {
    fontSize: 16,
  },
  payTextStyle: {
    fontFamily: 'GT Walsheim',
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 1.43,
    color: color.dark,
    marginBottom: 32,
  },
  buttonStyle: {
    height: 36,
  },
  disableButtonTextStyle: {
    color: color.secondary300,
  },
  activeButtonTextStyle: {
    color: color.light,
  },
  bottomDrawer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 29,
    padding: '0px 16px 18px 16px',
  },
  bottomDrawerContainer: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  bottomDrawerTitle: {
    fontSize: 23,
    letterSpacing: 0.3,
    marginBottom: 24,
  },
  disabledMessage: {
    fontSize: 16,
    fontWeight: 300,
    lineHeight: 1.23,
    letterSpacing: 0.15,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  buttonPaymentDisabled: {
    flex: 1,
    borderRadius: 0,
    marginTop: 100,
  },
  transactionFeeContainerStyle: {
    paddingBottom: 32,
  },
  transactionFeeStyle: {
    color: color.dark,
  },
});

export interface PaymentStyle extends WithStyles<typeof styles> {}

import * as React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import PlanButton from '../PlanButtons';
import PlanFooter from '../PlanFooter';
import {
  LABELS_CONTRACT,
  getDataExtraInfo,
  LABELS_MODEM,
  DEFAULT_SELECTED_BUTTON_FIRST_INDEX,
  LABELS_VOICE,
} from './Constants';
import { Styles, PlanScreenStyles } from '../electricity/Styles';
import { BroadbandPlanDetails } from '../../../../models/plan/broadband/getPlanDetails/BroadbandPlan.data';
import PlanHelper from '../../../../helper/PlanHelper';
import {
  BroadbandPlanSubmit,
  Modem,
} from '../../../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import { BroadbandPlanState } from '../../../../store/state/BroadbandPlanState';
import { ProductDetails } from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { BroadbandTypes } from '../../../../containers/services/ServiceConstants';

interface PropsFromRoute {
  readonly history?: any;
}

interface PropsFromParent {
  readonly planDetails: BroadbandPlanDetails;
  readonly handleNext: (choice: string, minimumCost: number, connectionFee: number) => void;
  plan: BroadbandPlanState;
}

interface FooterValue {
  monthlyCost: number;
  minimumCost: number;
  setupFee: string;
}

interface PlanState {
  choice: number;
}

type OwnProps = PlanScreenStyles & PropsFromRoute & PropsFromParent;

class ContractScreen extends React.Component<OwnProps, PlanState> {
  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      choice: DEFAULT_SELECTED_BUTTON_FIRST_INDEX,
    };
  }

  public render() {
    const { classes } = this.props;
    const footerValue = this.filterFooterValue();
    const minCost = this.getMinCost();
    const minCostLabel =
      this.state.choice === DEFAULT_SELECTED_BUTTON_FIRST_INDEX
        ? LABELS_CONTRACT.getContractInfo(minCost)
        : LABELS_CONTRACT.getNoContractInfo(minCost);
    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <div className={classes.top}>
            <Typography className={classes.title} variant="h5">
              {LABELS_CONTRACT.title}
            </Typography>
            <PlanButton
              options={this.filterOptions()}
              label={LABELS_CONTRACT.buttonsHeading}
              info={minCostLabel}
              onChange={this.handleOptionChange}
              selected={this.state.choice}
            />
          </div>
          <PlanFooter
            amount={`${footerValue.monthlyCost}`}
            extraInfo={this.getExtraInfo()}
            onButtonClick={this.handleFooterClick}
          />
        </div>
      </div>
    );
  }

  private filterOptions = (): string[] => {
    return LABELS_CONTRACT.contractLabels;
  };

  private getMinCost = () => {
    const { plan, planDetails } = this.props;
    const productName = PlanHelper.getProductName(
      planDetails.productNames,
      plan.dataPlan,
      plan.downloadPlan as string,
      plan.voicePlan === LABELS_VOICE.options[0],
    );
    const productInfo: BroadbandPlanSubmit = PlanHelper.getProductDetails(
      planDetails.plan,
      productName,
    );
    const { monthlyCost } = productInfo.productDetails as ProductDetails;
    const minCost =
      this.state.choice === DEFAULT_SELECTED_BUTTON_FIRST_INDEX
        ? 12 * monthlyCost
        : monthlyCost + parseInt(productInfo.setupFeeContractNo as string, 10);
    return minCost;
  };

  // tslint:disable-next-line:cyclomatic-complexity
  private getExtraInfo = () => {
    const { plan, planDetails } = this.props;
    const productName = PlanHelper.getProductName(
      planDetails.productNames,
      plan.dataPlan,
      plan.downloadPlan as string,
      plan.voicePlan === LABELS_VOICE.options[0],
    );
    const productInfo: BroadbandPlanSubmit = PlanHelper.getProductDetails(
      planDetails.plan,
      productName,
    );
    const modemInfo = PlanHelper.getModemDetails(planDetails.plan);
    const modemCost =
      plan.modem === LABELS_MODEM.modemLabel ? 0 : modemInfo && (modemInfo as Modem).monthlyCost;
    let contractFee = 0;
    if (planDetails.broadbandType === BroadbandTypes.adsl) {
      contractFee = !this.state.choice
        ? productInfo && parseInt(productInfo.setupFeeContractYes as string, 10)
        : productInfo && parseInt(productInfo.setupFeeContractNo as string, 10);
    } else if (planDetails.telcoService.nbnDevelopmentFee && productInfo.connectionFeeAmount) {
      contractFee = !this.state.choice
        ? productInfo.connectionFeeAmount + parseInt(productInfo.setupFeeContractYes as string, 10)
        : productInfo.connectionFeeAmount + parseInt(productInfo.setupFeeContractNo as string, 10);
    } else {
      contractFee = !this.state.choice
        ? parseInt(productInfo.setupFeeContractYes as string, 10)
        : parseInt(productInfo.setupFeeContractNo as string, 10);
    }
    const totalAmount = (modemCost as number) + contractFee;
    if (modemCost || contractFee) {
      return getDataExtraInfo(`${totalAmount}`, true);
    } else {
      return null;
    }
  };

  private filterFooterValue(): FooterValue {
    const { plan, planDetails } = this.props;
    const productName = PlanHelper.getProductName(
      planDetails.productNames,
      plan.dataPlan,
      plan.downloadPlan as string,
      plan.voicePlan === LABELS_VOICE.options[0],
    );
    const productInfo: BroadbandPlanSubmit = PlanHelper.getProductDetails(
      planDetails.plan,
      productName,
    );
    const { monthlyCost } = productInfo.productDetails as ProductDetails;
    const minimumCost = productInfo.minimumCost as number;
    const setupFee = productInfo.setupFeeContractNo as string;
    return {
      monthlyCost,
      minimumCost,
      setupFee,
    };
  }

  private handleOptionChange = (choice: number) => {
    this.setState({ choice });
  };

  private getConnectionFee = () => {
    const { plan, planDetails } = this.props;
    const productName = PlanHelper.getProductName(
      planDetails.productNames,
      plan.dataPlan,
      plan.downloadPlan as string,
      plan.voicePlan === LABELS_VOICE.options[0],
    );
    const productInfo: BroadbandPlanSubmit = PlanHelper.getProductDetails(
      planDetails.plan,
      productName,
    );
    let connectionFee = 0;
    if (
      planDetails.broadbandType === BroadbandTypes.nbn &&
      planDetails.telcoService.nbnDevelopmentFee &&
      productInfo.connectionFeeAmount
    ) {
      connectionFee = productInfo.connectionFeeAmount;
    }
    return connectionFee;
  };

  private handleFooterClick = () => {
    const minCost = this.getMinCost();
    const connectionFee = this.getConnectionFee();
    this.props.handleNext(this.filterOptions()[this.state.choice], minCost, connectionFee);
  };
}

export default withStyles(Styles)(ContractScreen);

import * as React from 'react';
import { Typography, withStyles, Card } from '@material-ui/core';
import { PlanSummaryStyle, Styles } from './PlanSummaryStyles';
import { History } from 'history';
import ReactHTMLParser from 'react-html-parser';
import FooterComponent from '../footer/FooterComponent';
import errorIcon from '../../assets/error-outline-24-px.svg';
import {
  ConcessionCard,
  ProductDetails,
  CommonSummary,
  FeatureDetails,
  BenefitsObject,
  PlanDetailObject,
  KeyValueData,
  SummaryDetail,
  QuotesV3,
  FooterPricing,
  Link,
} from '../../containers/services/commonServices/CommonPlanSelectionInterface';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/RootReducers';
import { AnyAction } from 'redux';
import { PlanCommonSummaryActions } from '../../store/actions/CommonSummaryActions';
import { routes } from '../../Routes';
import { get } from 'lodash';
import {
  ElectricityServiceDetails,
  PersonalDetails,
  PropertyDetails,
  StepDetails,
} from '../../services/electricityService/getElectricityService/GetElectricityService.data';
import Snackbar from '@material-ui/core/Snackbar';
import { LABELS } from './PlanSummaryConstants';

interface PropsFromParent {
  propertyId: string;
  serviceName: string;
  providerName: string;
}

interface PropsFromState {
  serviceAccountId: string;
  quotes: QuotesV3;
  address: string;
  storePlanSummary: FeatureDetails[];
  productDetails: ProductDetails | null;
  concessionCard: ConcessionCard | null;
  paymentCardRequired: boolean;
  personalDetailsCompleted: boolean;
  personalDetailsStatus: boolean;
  personalDetails: PersonalDetails;
  benefits: PlanDetailObject | SummaryDetail;
  serviceabilityDetails: any | null;
}

interface PropsFromDispatch {
  postCommonData: (serviceDetails: CommonSummary, onSuccess: () => void) => void;
  //TODO: Resolve any
  setTncData: (serviceDetails: any) => void;
}

interface PropsFromRoute {
  history: History;
}

interface State {
  isAcceptTermAndCondition: boolean;
  isPersonalDetailCompleted: boolean;
  openSnackBar: boolean;
}

type OwnProps = PlanSummaryStyle &
  PropsFromParent &
  PropsFromState &
  PropsFromDispatch &
  PropsFromRoute;
export class CommonPlanSummary extends React.Component<OwnProps, State> {
  public constructor(props: OwnProps) {
    super(props);
    window.scrollTo(0, 0);
    const { personalDetailsCompleted, personalDetailsStatus } = this.props;
    this.state = {
      isAcceptTermAndCondition: false,
      isPersonalDetailCompleted: personalDetailsCompleted || personalDetailsStatus,
      openSnackBar: false,
    };
  }
  public componentDidMount() {
    window.scrollTo(0, 0);
  }
  public render() {
    const {
      classes,
      history,
      propertyId,
      serviceName,
      providerName,
      productDetails,
      concessionCard,
      storePlanSummary,
      paymentCardRequired,
      benefits,
      setTncData,
      quotes,
      serviceabilityDetails,
    } = this.props;
    return (
      <div className={classes.root} style={{ paddingBottom: 90 }}>
        <Typography variant="h6" className={classes.titleStyle}>
          {LABELS.personalDetails}
        </Typography>
        {this.renderPersonalDetail()}
        <Typography variant="h6" className={classes.titleStyle}>
          {quotes.Response.Plans[0].ProductType} details
        </Typography>
        {quotes.Response.Plans[0].UiModelAttributes.Summary.Value
          ? this.renderPlanSummary()
          : undefined}
        {get(benefits, `Benefits`, []).length > 0 ? this.renderBenefits() : undefined}
        {get(benefits, `Benefits`, []).length > 0 ? this.renderBenefits() : undefined}
        {get(benefits as PlanDetailObject, `data`, []).length > 0
          ? this.renderBenefitsSummary()
          : undefined}
        {this.renderPlanDetails()}
        <div className={classes.footerSection}>
          <FooterComponent
            buttonText="NEXT"
            onClick={() => {
              if (!this.state.isPersonalDetailCompleted) {
                this.setState({ openSnackBar: true });
              } else {
                const planDetailName: KeyValueData = {
                  key: quotes.Response.Plans[0].ProductType + ' Name',
                  value: get(productDetails as ProductDetails, 'productName', ''),
                };

                let planDetailCost: KeyValueData[] = [];
                this.props.quotes.Response.Plans[0].Plans[0].FooterPricing.map(
                  (footerPricing: FooterPricing) => {
                    if (footerPricing.PriceField !== null && footerPricing.PriceField.length > 0) {
                      planDetailCost = [
                        ...planDetailCost,
                        {
                          key: footerPricing.UnitOfMeasure,
                          value:
                            footerPricing.UnitOfMeasure === 'Monthly'
                              ? (productDetails as ProductDetails).monthlyCost
                              : footerPricing.UnitOfMeasure === 'Daily'
                              ? (productDetails as ProductDetails).dailyCost
                              : (productDetails as ProductDetails).cost,
                        },
                      ];
                    }
                  },
                );
                let planDetailDescription: KeyValueData[] = [];
                if (
                  get(benefits, 'DescriptionFooter', '') &&
                  get(benefits, 'DescriptionFooter', '').length > 0
                ) {
                  planDetailDescription = [
                    {
                      key: 'Estimated Cost',
                      value: get(benefits, 'DescriptionFooter', ''),
                    },
                  ];
                }

                const planDetailData = [
                  planDetailName,
                  ...planDetailCost,
                  ...planDetailDescription,
                ];
                const sectionTitlePlanDetail = 'Plan summary';
                const summaryPlanDetail: SummaryDetail = {
                  sectionTitle: sectionTitlePlanDetail,
                  data: planDetailData,
                };
                const data = [];
                // tslint:disable-next-line:prefer-for-of
                let summaryBenefits: SummaryDetail = {
                  sectionTitle: '',
                  data: {},
                };
                if ((benefits as PlanDetailObject).Benefits) {
                  for (let i = 0; i < (benefits as PlanDetailObject).Benefits.length; i++) {
                    const summaryData = {
                      key: get((benefits as PlanDetailObject).Benefits[i], 'Heading', ''),
                      value: get((benefits as PlanDetailObject).Benefits[i], 'Body', ''),
                    };
                    data.push(summaryData);
                  }
                  const sectionTitleBenefits = 'Benefits';
                  summaryBenefits = {
                    sectionTitle: sectionTitleBenefits,
                    data,
                  };
                } else {
                  summaryBenefits = benefits as SummaryDetail;
                }
                const summaryDocumentsData: KeyValueData[] = [];
                if (
                  (benefits as PlanDetailObject) &&
                  (benefits as PlanDetailObject).Links &&
                  (benefits as PlanDetailObject).Links.length > 0
                ) {
                  (benefits as PlanDetailObject).Links.forEach((link: Link) => {
                    summaryDocumentsData.push({
                      key: get(link, 'Label', '') as string,
                      value: get(link, 'Link', ''),
                    });
                  });
                }
                const summaryDocuments = {
                  sectionTitle: 'Documents',
                  data: summaryDocumentsData,
                };
                const summary: SummaryDetail[] = [
                  summaryPlanDetail,
                  summaryBenefits,
                  summaryDocuments,
                ];
                setTncData({
                  summary,
                  storePlanSummary,
                  concessionCard,
                  productDetails,
                  paymentCardRequired,
                  serviceabilityDetails,
                });

                history.push({
                  pathname: routes.commonService.termsAndConditions(
                    serviceName,
                    propertyId,
                    providerName,
                  ),
                });
              }
            }}
          />
        </div>
        <Snackbar
          open={this.state.openSnackBar}
          className={classes.root}
          onClose={this.handleClose}
          message={
            <div className={classes.snackMessageBoxStyle}>
              <img src={errorIcon} className={classes.iconStyle} />
              <Typography variant="body2" className={classes.snackTextStyle}>
                {'Please provide personal details'}
              </Typography>
            </div>
          }
          autoHideDuration={4000}
        />
      </div>
    );
  }

  public renderKeyValue = (key: string, value: string | number) => {
    const { classes } = this.props;
    return (
      <div className={classes.keyValueContainer}>
        <Typography variant="h1" className={`${classes.keyText} ${classes.gtwLight}`}>
          {ReactHTMLParser(key)}:
        </Typography>
        <Typography variant="h1" className={`${classes.keyText} ${classes.gtwLight}`}>
          {ReactHTMLParser(value as string)}
        </Typography>
      </div>
    );
  };
  public renderKeyValueColumn = (key?: string, value?: string) => {
    const { classes } = this.props;
    return (
      <div className={classes.keyValueContainerColumn}>
        <Typography variant="caption" className={`${classes.keyText}`}>
          {ReactHTMLParser(key as string)}:
        </Typography>
        <Typography variant="h1" className={`${classes.value} ${classes.gtwLight}`}>
          {ReactHTMLParser(value as string)}
        </Typography>
      </div>
    );
  };

  private renderBenefits = () => {
    const { classes, benefits, quotes } = this.props;

    return (
      <Card className={classes.detailCard}>
        {quotes.Response.Plans[0].UiModelAttributes.Benefit.Value ? (
          <Typography variant="h6">
            {quotes.Response.Plans[0].UiModelAttributes.Benefit.Value}
          </Typography>
        ) : undefined}
        <div className={classes.seperator} />
        {!!get(benefits, `Benefits`, []).length && (
          <div className={classes.section}>
            {(benefits as PlanDetailObject).Benefits.map((benefit: BenefitsObject) =>
              this.renderKeyValue(get(benefit, 'Heading', ''), get(benefit, 'Body', '')),
            )}
          </div>
        )}
      </Card>
    );
  };

  private renderBenefitsSummary = () => {
    const { classes, benefits } = this.props;

    return (
      <Card className={classes.detailCard}>
        <Typography variant="h6">Benefits</Typography>
        <div className={classes.seperator} />
        {!!get(benefits as PlanDetailObject, `data`, []).length && (
          <div className={classes.section}>
            {(benefits as PlanDetailObject).data.map((benefit: KeyValueData) =>
              this.renderKeyValue(benefit.key, benefit.value),
            )}
          </div>
        )}
      </Card>
    );
  };

  private renderPersonalDetail = () => {
    const {
      classes,
      history,
      propertyId,
      serviceName,
      providerName,
      serviceAccountId,
      personalDetails,
      address,
    } = this.props;
    return (
      <Card
        className={classes.detailCard}
        onClick={() => {
          history.push(
            routes.commonService.commonPersonalDetails(
              propertyId,
              serviceName,
              providerName,
              serviceAccountId,
            ),
          );
        }}
      >
        <div className={classes.personalDetailCard}>
          {!this.state.isPersonalDetailCompleted && (
            <img src={errorIcon} className={classes.iconStyle} />
          )}
        </div>
        <div className={classes.seperator} />

        <div className={classes.section}>
          {this.renderKeyValue('Name', personalDetails.name + ' ' + personalDetails.surname)}
        </div>
        <div className={classes.section}>{this.renderKeyValue('Email', personalDetails.email)}</div>
        <div className={classes.section}>
          {personalDetails.dob && this.renderKeyValue('Date Of Birth', `${personalDetails.dob}`)}
        </div>
        <div className={classes.section}>
          {personalDetails.idNumber &&
            this.renderKeyValue('Id Number', `${personalDetails.idNumber}`)}
        </div>
        <div className={classes.section}>
          {personalDetails.idType && this.renderKeyValue('Id Type', `${personalDetails.idType}`)}
        </div>
        <div className={classes.section}>
          {personalDetails.idExpiry &&
            this.renderKeyValue('Id Expiry', `${personalDetails.idExpiry}`)}
        </div>
        <div className={classes.section}>
          {address && (
            <div>
              <Typography variant="h1" className={`${classes.keyText} ${classes.gtwLight}`}>
                Address
              </Typography>
              <Typography
                variant="h1"
                className={`${classes.keyText} ${classes.gtwLight}`}
                style={{ paddingTop: 10 }}
              >
                {address}
              </Typography>
            </div>
          )}
        </div>
      </Card>
    );
  };

  private handleClose = () => {
    this.setState({ openSnackBar: false });
  };

  private renderPlanSummary = () => {
    const { classes, benefits, productDetails, quotes } = this.props;

    return (
      <Card className={classes.detailCard}>
        <Typography variant="h6">{quotes.Response.Plans[0].ProductType} summary</Typography>
        <div className={classes.seperator} />
        <div className={classes.section}>
          {this.renderKeyValue(
            quotes.Response.Plans[0].ProductType + ' Name',
            get(productDetails as ProductDetails, 'productName', ''),
          )}
          {quotes.Response.Plans[0].Plans[0].FooterPricing.map((footerPricing: FooterPricing) => {
            return this.renderKeyValue(
              footerPricing.UnitOfMeasure,
              footerPricing.UnitOfMeasure === 'Monthly'
                ? ((productDetails as ProductDetails).monthlyCost as string)
                : footerPricing.UnitOfMeasure === 'Daily'
                ? ((productDetails as ProductDetails).dailyCost as string)
                : ((productDetails as ProductDetails).cost as string),
            );
          })}
          <div className={classes.bottomText}>
            <div
              className={`${classes.keyText} ${classes.gtwLight}`}
              dangerouslySetInnerHTML={{
                __html: get(benefits, 'DescriptionFooter', ''),
              }}
            />
          </div>
        </div>
      </Card>
    );
  };

  private renderPlanDetails = () => {
    const { classes, storePlanSummary, quotes } = this.props;
    const supplierOptions = storePlanSummary.filter(
      (p: FeatureDetails) => p.type === 'Supplier Option',
    );
    const providerOptions = storePlanSummary.filter((p: FeatureDetails) => p.type === 'answers');
    const providerOptionsFirst = storePlanSummary.filter((p: FeatureDetails) => p.type === 'first');

    return (
      <div className={classes.cardStylePlan}>
        <div className={`${classes.cardContentStyle} ${classes.subTitleStyle}`}>
          <Typography className={classes.summaryInfo}>
            {!!providerOptionsFirst.length
              ? providerOptionsFirst[0].label
              : `${quotes.Response.Plans[0].ProductType} detail`}
          </Typography>
          {!!providerOptions.length &&
            providerOptions.map((option: FeatureDetails) =>
              this.renderKeyValueColumn(option.label, option.value),
            )}
          {!!supplierOptions.length &&
            supplierOptions.map((option: FeatureDetails) =>
              this.renderKeyValueColumn(option.label, option.value),
            )}
        </div>
      </div>
    );
  };
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>): PropsFromDispatch {
  return {
    postCommonData: (serviceDetails: CommonSummary, onSuccess: () => void) =>
      dispatch(
        PlanCommonSummaryActions.postCommonSummaryData({
          data: serviceDetails,
          onSuccess,
        }),
      ),
    //TODO: Resolve any
    setTncData: (serviceDetails: any) =>
      dispatch(
        PlanCommonSummaryActions.setTncData({
          data: serviceDetails,
        }),
      ),
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    serviceAccountId: state.commonSummary.commonServicePlans.id
      ? state.commonSummary.commonServicePlans.id
      : state.commonSummary.commonServicePlans.serviceAccountResponse.id,
    storePlanSummary: state.commonSummary.storePlanSummaryData.data.serviceDetails,
    productDetails: state.commonSummary.storePlanSummaryData.data.productDetails,
    personalDetailsCompleted:
      (state.electricityService.service as ElectricityServiceDetails) &&
      (state.electricityService.service as ElectricityServiceDetails).serviceDetails
        ? (
            (state.electricityService.service as ElectricityServiceDetails).serviceDetails
              .personalDetailsStep as StepDetails
          ).completed
        : state.commonSummary.commonServicePlansSuccess
        ? state.commonSummary.commonServicePlansSuccess.steps.personalDetailsStep.completed
        : null,
    personalDetails:
      (state.electricityService.service as ElectricityServiceDetails) &&
      (state.electricityService.service as ElectricityServiceDetails).serviceDetails
        ? (state.electricityService.service as ElectricityServiceDetails).serviceDetails
            .personalDetails
        : state.commonSummary.commonServicePlansSuccess
        ? state.commonSummary.commonServicePlansSuccess.steps.personalDetails
        : null,
    concessionCard: state.commonSummary.storePlanSummaryData.data.concessionCard,
    paymentCardRequired: state.commonSummary.storePlanSummaryData.data.paymentCardRequired,
    benefits: state.commonSummary.storePlanSummaryData.data.benefits,
    personalDetailsStatus: state.commonSummary.personalDetailStatus
      ? state.commonSummary.personalDetailStatus
      : false,
    address: (state.commonSummary.commonServiceAddress as PropertyDetails).address,
    quotes: state.commonSummary.quotes as QuotesV3,
    serviceabilityDetails: state.commonSummary.storePlanSummaryData.data
      ? state.commonSummary.storePlanSummaryData.data.serviceabilityDetails
      : null,
  };
}

export default withStyles(Styles)(connect(mapStateToProps, mapDispatchToProps)(CommonPlanSummary));

import { WalletState } from '../state/WalletState';
import { WalletAction, WalletActionTypes } from '../actions/WalletActions';
import { GetPaymentMethodsResponse } from '../../services/payment/getPaymentMethods/GetPaymentMethods.data';

const walletInitialState: WalletState = {
  payment: [],
  selectedPayment: undefined,
  deletePaymentResponse: undefined,
  loading: false,
  headerLabel: '',
  showSuccessMessage: false,
};

export function walletReducer(state = walletInitialState, action: WalletAction): WalletState {
  switch (action.type) {
    case WalletActionTypes.GetPaymentMethodsStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case WalletActionTypes.GetPaymentMethodsSuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        payment: action.data as GetPaymentMethodsResponse[],
      };
    case WalletActionTypes.GetPaymentMethodsError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    case WalletActionTypes.SaveSelectedPayment:
      return {
        ...state,
        selectedPayment: action.data as GetPaymentMethodsResponse,
      };
    case WalletActionTypes.DeletePaymentStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case WalletActionTypes.DeletePaymentSuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        deletePaymentResponse: action.data as string[],
      };
    case WalletActionTypes.DeletePaymenError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    case WalletActionTypes.SaveHeaderLabel:
      return {
        ...state,
        headerLabel: action.data as string,
      };
    case WalletActionTypes.ShowSuccessMessage:
      return {
        ...state,
        showSuccessMessage: true,
      };
    case WalletActionTypes.HideSuccessMessage:
      return {
        ...state,
        showSuccessMessage: false,
      };
    default:
      return state;
  }
}

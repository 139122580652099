import { color } from '../../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const Styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    marginTop: 48,
  },
  clear: {
    color: '#1f0d33',
    fontSize: '13.6px',
    letterSpacing: '0.4px',
    marginLeft: '4px',
    fontWeight: 500,
  },
  titleStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 16,
    marginTop: 13,
  },
  clearButtonStyle: {
    textTransform: 'none',
  },
  cardStyle: {
    'height': 164,
    'borderRadius': 8,
    'marginTop': 8,
    '&:last-child': {
      marginBottom: 20,
    },
  },
  cardContentStyle: {
    paddingTop: 0,
    paddingBottom: 0,
    height: 57,
  },
  cardActionsStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  statusBoxStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 36,
  },
  cardTitle: {
    fontFamily: 'GT Walsheim',
    fontSize: 23.3,
    letterSpacing: 0.3,
    color: color.dark,
    paddingBottom: 0,
  },
  cardSubtitle: {
    fontFamily: 'GT Walsheim light',
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 1.43,
  },
  disableCardTextStyle: {
    color: color.secondary300,
  },
  button: {
    'width': 144,
    'height': 36,
    'backgroundColor': color.primary,
    '&:hover': {
      backgroundColor: color.primary,
    },
  },
  buttonText: {
    color: color.light,
  },
  completedStatusStyle: {
    color: color.completed,
    marginLeft: 8,
  },
  pendingStatusStyle: {
    color: color.secondary300,
    marginLeft: 8,
  },
});

export interface OverViewStyles extends WithStyles<typeof Styles> {}

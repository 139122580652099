import React, { FC } from 'react';
import { withStyles, Typography, Card } from '@material-ui/core';
import { LABELS } from './SummaryConstants';
import { styles, SummaryStyles } from './SummaryStyles';
import { OnboardingPropertyDetails } from '../../../../models/property/Property.data';
import { getPropertyLogo, createAddressString, getClassLogo } from '../../PropertyOnboardingUtils';

interface SummaryProps extends SummaryStyles {
  propertyOnboardingState: OnboardingPropertyDetails;
}

const Summary: FC<SummaryProps> = ({ classes, propertyOnboardingState: { address } }) => {
  const addressString = createAddressString(address);
  return (
    <div className={classes.contentContainer}>
      <div className={classes.summaryCard}>
        <div className={classes.address}>{addressString.split(',')[0]}</div>
        <div className={classes.address2}>
          {`${addressString.split(',')[1]}, ${addressString.split(',')[2]}`}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Summary);

import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store/RootReducers';
import { Dispatch } from 'redux';
import { styles, CheckoutConfigureStyles } from './CheckoutConfigureStyles';
import { createRequiredServiceTypes, useCart } from '../../helper/CheckoutHelper';
import SelectedPlan from './components/SelectedPlan/SelectedPlan';
import { routes } from '../../Routes';
import { getQueryParams } from '../../helper/QueryHelper';
import { ServiceTypes } from '../../models/checkout/Checkout';
import { CheckoutActions } from '../../store/actions/CheckoutActions';

interface CheckoutConfigureProps extends CheckoutConfigureStyles, RouteComponentProps<{}> {
  isCartOpen: boolean;
  initialServiceTypes: ServiceTypes[];
  toggleCart: () => void;
}

const CheckoutConfigure: FC<CheckoutConfigureProps> = ({
  classes,
  history,
  location,
  isCartOpen,
  initialServiceTypes,
  toggleCart,
}) => {
  const last = getQueryParams(location, 'last');
  const { cartCount, cartItems } = useCart();
  const [activeTab, setActiveTab] = useState<number>(last ? cartCount - 1 : 0);

  useEffect(() => {
    if (cartCount === 0) {
      history.goBack();
      if (isCartOpen) {
        toggleCart();
      }
    }
  }, [cartCount]);

  const goBack = () => {
    if (activeTab === 0) {
      history.push(
        `${routes.checkout.select}?requiredServiceTypes=${createRequiredServiceTypes(
          initialServiceTypes,
        )}`,
      );
    } else {
      setActiveTab(activeTab - 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const onNext = () => {
    if (activeTab + 1 === cartCount) {
      history.push(routes.checkout.summary);
    } else {
      setActiveTab(activeTab + 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <div className={classes.root}>
      {cartCount !== 0 ? (
        <div className={classes.scrollContainer}>
          {/* added key for resolving the state reference in selectedPlan component */}
          <div key={`selected_plan_${activeTab}`}>
            <SelectedPlan
              selectedPlan={cartItems[activeTab]}
              history={history}
              index={activeTab}
              onNext={onNext}
              goBack={goBack}
              setActiveTab={setActiveTab}
              cartItems={cartItems}
              key={`${cartItems[activeTab].type}`}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  isCartOpen: state.checkout.isCartOpen,
  initialServiceTypes: state.checkout.initialServiceTypes,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleCart: () => dispatch(CheckoutActions.toggleCart()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(CheckoutConfigure)));

import React, { FC, useState } from 'react';
import { MenuItem, OutlinedInput, Select, Typography, withStyles } from '@material-ui/core';
import { styles, CheckoutFooterStyles } from './CheckoutFooterStyles';
import compareIcon from '../../assets/compare-white.png';
import arrowForward from '../../assets/chevron-right-white.png';
import { FooterPricing } from '../../containers/services/commonServices/CommonPlanSelectionInterface';
import MainContainer from '../mainContainer/MainContainer';
import { useCart } from '../../helper/CheckoutHelper';
import { LABELS } from './CheckoutFooterConstants';

interface CheckoutFooterProps extends CheckoutFooterStyles {
  showHelp?: boolean;
  primaryText: string;
  secondaryText: string;
  disabled?: boolean;
  showPricing?: boolean;
  showCompare?: boolean;
  footerPriceLabel?: string;
  footerPricing?: FooterPricing[];
  frequency?: string;
  hidePrimary?: boolean;
  hideSecondary?: boolean;
  onHelp?: () => void;
  onCompare?: () => void;
  onPrimary: () => void;
  onSecondary: () => void;
  setFrequency?: (value: string) => void;
  handleValidation?: () => void;
}

const CheckoutFooter: FC<CheckoutFooterProps> = ({
  classes,
  showHelp,
  primaryText,
  secondaryText,
  disabled,
  showPricing,
  showCompare,
  footerPriceLabel,
  footerPricing,
  frequency,
  hidePrimary,
  hideSecondary,
  onCompare,
  onHelp,
  onPrimary,
  onSecondary,
  setFrequency,
  handleValidation,
}) => {
  const { compareCount } = useCart();
  return (
    <div className={classes.footerContainer}>
      <div className={`${showCompare ? classes.compareContainer : classes.hideCompare}`}>
        <MainContainer noPadding>
          <div className={classes.compareWrapper} onClick={onCompare}>
            <div className={classes.compareIconContainer}>
              <img className={classes.compareIcon} src={compareIcon} alt="compare" />
            </div>
            <div className={classes.compareText}>{`Compare plans (${compareCount})`}</div>
            <div className={classes.compareIconContainer}>
              <img className={classes.compareIcon} src={arrowForward} alt="compare-arrow" />
            </div>
          </div>
        </MainContainer>
      </div>
      <div className={classes.backgroundColor}>
        <MainContainer>
          {showPricing && (
            <>
              {footerPricing && footerPricing.length >= 1 ? (
                <div className={classes.footerContent} style={{ paddingBottom: 0 }}>
                  <div className={classes.pricingContainer}>
                    <div className={classes.priceContainer}>
                      <div className={classes.estimatedText}>{LABELS.ESTIMATED}</div>
                      <div className={classes.totalContainer}>
                        <div className={classes.total1}>{footerPriceLabel!.split('.')[0]}</div>
                        <div className={classes.decimal}>.{footerPriceLabel!.split('.')[1]}</div>
                      </div>
                    </div>
                    <div className={classes.toggleContainer}>
                      {footerPricing.map((footerPrice: FooterPricing) => (
                        <div
                          onClick={() => {
                            setFrequency!(footerPrice.PriceField);
                          }}
                          className={`${
                            frequency === footerPrice.PriceField
                              ? classes.toggleActive
                              : classes.toggle
                          }`}
                        >
                          {footerPrice.UnitOfMeasure}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          )}

          <div className={classes.footerContent}>
            {!hideSecondary && (
              <div className={classes.secondaryButton} onClick={() => onSecondary()}>
                {secondaryText}
              </div>
            )}
            {!hidePrimary && (
              <div
                className={disabled ? classes.disabledButton : classes.primaryButton}
                onClick={() => {
                  if (disabled) {
                    if (handleValidation) {
                      handleValidation();
                    }
                  } else {
                    onPrimary();
                  }
                }}
              >
                {primaryText}
              </div>
            )}
          </div>
        </MainContainer>
      </div>
    </div>
  );
};
export default withStyles(styles)(CheckoutFooter);

import * as React from 'react';
import { PersonalDetails } from '../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { Styles, PersonalDetailStyles } from './PersonalDetailStyles';
import { withStyles, Typography, FormHelperText } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import {
  LABELS,
  validationSchema,
  TITLES,
  ID_TYPES,
  DATE_FORMAT_PICKER,
  MINIMUM_AGE,
  STATE_OF_ISSUE,
  MEDICARE_CARD_COLOR,
  REFERENCE_NUMBER,
  COUNTRY_CODES,
} from './PersonalDetailConstants';
import { Formik } from 'formik';
import InputComponent from '../electricityService/addressForm/InputComponent';
import SelectComponent from '../electricityService/addressForm/SelectComponent';
import FooterComponent from '../../footer/FooterComponent';
import { parseInitialDate } from '../../../helper/DateFormatter';

interface PropsFromParent {
  personalDetails: PersonalDetails;
  handleSave: (personalDetails: InitialValues) => void;
  showExpiry?: boolean;
}

export interface InitialValues {
  email: string;
  title: string;
  firstName: string;
  lastName: string;
  mobile: string;
  dob: Date | string | null;
  expiry: Date | string | null;
  idExpiry: Date | string | null;
  idType: string;
  stateIssue: string | null;
  medicareCardColor: string | null;
  referenceNumber: string | null;
  countryOfIssue: string | null;
  idNumber: string;
}

type OwnProps = PropsFromParent & PersonalDetailStyles;

class PersonalDetail extends React.Component<OwnProps> {
  public render() {
    const { classes, personalDetails, handleSave } = this.props;
    const initialValues: InitialValues = {
      email: personalDetails.email || '',
      title: personalDetails.salutation || '',
      firstName: personalDetails.name || '',
      lastName: personalDetails.surname || '',
      mobile: personalDetails.phone.replace(/ /g, '') || '',
      dob: parseInitialDate(personalDetails.dob as string),
      expiry: parseInitialDate(personalDetails.idExpiry as string),
      idExpiry: parseInitialDate(personalDetails.idExpiry as string),
      idNumber: personalDetails.idNumber || '',
      idType: personalDetails.idType || '',
      stateIssue: personalDetails.idDetails ? personalDetails.idDetails.dlStateIssue : null,
      medicareCardColor: personalDetails.idDetails
        ? personalDetails.idDetails.medicareCardColor
        : null,
      countryOfIssue: personalDetails.idDetails ? personalDetails.idDetails.passportCountry : null,
      referenceNumber: personalDetails.idDetails
        ? personalDetails.idDetails.medicareRefNumber
        : null,
    };
    const expiryDate = new Date();
    expiryDate.setFullYear(expiryDate.getFullYear() + (2050 - expiryDate.getFullYear()));
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - MINIMUM_AGE);
    const getErrorMessage = (error: string | undefined, touched: boolean | undefined) => {
      return error && touched ? error : undefined;
    };
    return (
      <div className={classes.root}>
        <Typography variant="h5">{LABELS.pageTitle}</Typography>
        <Formik
          initialValues={initialValues}
          validateOnChange={true}
          validateOnBlur
          onSubmit={(values) => {
            return handleSave(values);
          }}
          validationSchema={validationSchema}
          // tslint:disable-next-line:cyclomatic-complexity
          render={({ values, setFieldValue, handleSubmit, errors, touched }) => {
            return (
              <div className={classes.formRoot}>
                <InputComponent
                  label={LABELS.email}
                  value={values.email}
                  setFieldValue={setFieldValue}
                  disabled
                  name="email"
                  type="text"
                  style={classes.emailStyle}
                  errorMessage={getErrorMessage(errors.email, touched.email)}
                />
                <SelectComponent
                  label={LABELS.title}
                  value={values.title}
                  setFieldValue={setFieldValue}
                  name="title"
                  selectValues={TITLES}
                  errorMessage={getErrorMessage(errors.title, touched.title)}
                />
                <InputComponent
                  label={LABELS.firstName}
                  value={values.firstName}
                  setFieldValue={setFieldValue}
                  name="firstName"
                  type="text"
                  style={classes.inputStyle}
                  errorMessage={getErrorMessage(errors.firstName, touched.firstName)}
                />
                <InputComponent
                  label={LABELS.lastName}
                  value={values.lastName}
                  setFieldValue={setFieldValue}
                  name="lastName"
                  type="text"
                  style={classes.inputStyle}
                  errorMessage={getErrorMessage(errors.lastName, touched.lastName)}
                />
                <div
                  className={`${classes.mobilePicker} ${
                    getErrorMessage(errors.mobile, true)
                      ? classes.errorBorder
                      : classes.defaultBorder
                  }`}
                >
                  <div className={classes.mobileLabelStyle}>{LABELS.mobile}</div>
                  <PhoneInput
                    flagUrl="https://public.sortedservices.com/flags/4x3/{xx}.svg"
                    defaultCountry="AU"
                    value={values.mobile ? values.mobile : ''}
                    onChange={(getPhoneDigit) => {
                      setFieldValue('mobile', getPhoneDigit);
                    }}
                  />
                </div>
                {getErrorMessage(errors.mobile, true) ? (
                  <span className={classes.errorMessage}>
                    {getErrorMessage(errors.mobile, true)}
                  </span>
                ) : null}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    label={LABELS.dob}
                    value={values.dob}
                    openTo={'year'}
                    initialFocusedDate={maxDate}
                    InputLabelProps={{
                      classes: {
                        root: values.dob ? classes.inputLabelStyle : classes.noInputLabelStyle,
                      },
                    }}
                    onChange={(value) => {
                      setFieldValue('dob', value);
                    }}
                    maxDate={maxDate}
                    format={DATE_FORMAT_PICKER}
                    disableOpenOnEnter
                    InputProps={{ className: classes.datePicker }}
                    className={classes.datePickerComponent}
                    error={!!getErrorMessage(errors.dob, touched.dob)}
                  />
                  {getErrorMessage(errors.dob, touched.dob) && (
                    <FormHelperText className={classes.errorMessage}>{errors.dob}</FormHelperText>
                  )}
                </MuiPickersUtilsProvider>
                <SelectComponent
                  label={LABELS.idType}
                  value={values.idType}
                  setFieldValue={setFieldValue}
                  name="idType"
                  onChange={() => {
                    setFieldValue('referenceNumber', null);
                    setFieldValue('medicareCardColor', null);
                    setFieldValue('stateIssue', null);
                    setFieldValue('countryOfIssue', null);
                  }}
                  selectValues={ID_TYPES}
                  errorMessage={getErrorMessage(errors.idType, touched.idType)}
                />
                {values.idType === 'DriversLicence' ? (
                  <SelectComponent
                    label={LABELS.stateIssue}
                    value={values.stateIssue || ''}
                    setFieldValue={setFieldValue}
                    name="stateIssue"
                    selectValues={STATE_OF_ISSUE}
                    errorMessage={getErrorMessage(errors.stateIssue, touched.stateIssue)}
                  />
                ) : values.idType === 'Medicare' ? (
                  <div>
                    <SelectComponent
                      label={LABELS.referenceNumber}
                      value={values.referenceNumber || ''}
                      setFieldValue={setFieldValue}
                      name="referenceNumber"
                      selectValues={REFERENCE_NUMBER}
                      errorMessage={getErrorMessage(
                        errors.referenceNumber,
                        touched.referenceNumber,
                      )}
                    />
                    <SelectComponent
                      label={LABELS.medicareCardColor}
                      value={values.medicareCardColor || ''}
                      setFieldValue={setFieldValue}
                      name="medicareCardColor"
                      selectValues={MEDICARE_CARD_COLOR}
                      errorMessage={getErrorMessage(
                        errors.medicareCardColor,
                        touched.medicareCardColor,
                      )}
                    />
                  </div>
                ) : values.idType === 'Passport' ? (
                  <SelectComponent
                    label={LABELS.countryOfIssue}
                    value={values.countryOfIssue || ''}
                    setFieldValue={setFieldValue}
                    name="countryOfIssue"
                    selectValues={COUNTRY_CODES}
                    errorMessage={getErrorMessage(errors.countryOfIssue, touched.countryOfIssue)}
                  />
                ) : undefined}
                <InputComponent
                  label={LABELS.idNumber}
                  value={values.idNumber}
                  setFieldValue={setFieldValue}
                  name="idNumber"
                  type="text"
                  style={classes.inputStyle}
                  errorMessage={getErrorMessage(errors.idNumber, touched.idNumber)}
                />

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    label={LABELS.idExpiry}
                    value={values.idExpiry}
                    openTo={'year'}
                    initialFocusedDate={expiryDate}
                    style={{ marginBottom: 60 }}
                    InputLabelProps={{
                      classes: {
                        root: values.idExpiry ? classes.inputLabelStyle : classes.noInputLabelStyle,
                      },
                    }}
                    //TODO: Resolve any
                    onChange={(value: any) => {
                      setFieldValue(
                        'expiry',
                        value.getDate() +
                          '/' +
                          parseInt(value.getMonth() + 1, 10).toString() +
                          '/' +
                          value.getFullYear(),
                      );
                      setFieldValue('idExpiry', value);
                    }}
                    maxDate={expiryDate}
                    format={DATE_FORMAT_PICKER}
                    disableOpenOnEnter
                    InputProps={{ className: classes.datePicker }}
                    className={classes.datePickerComponent}
                    error={!!getErrorMessage(errors.idExpiry, touched.idExpiry)}
                  />
                  {getErrorMessage(errors.idExpiry, touched.idExpiry) && (
                    <FormHelperText className={classes.errorMessage}>
                      {errors.idExpiry}
                    </FormHelperText>
                  )}
                </MuiPickersUtilsProvider>

                <FooterComponent buttonText={LABELS.buttonLabel} onClick={handleSubmit} />
              </div>
            );
          }}
        />
      </div>
    );
  }
}

export default withStyles(Styles)(PersonalDetail);

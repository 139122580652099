/* eslint-disable no-lonely-if */
import React, { FC } from 'react';
import filledCheckIcon from '../../assets/tick-icon.png';
import celebration from '../../assets/celebration.jpg';
import { withStyles } from '@material-ui/core';
import { LABELS, rentDefaultServiceTypes } from './SetupConstants';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';
import { serviceTag } from '../../Constants';
import { withRouter, RouteComponentProps } from 'react-router';
import { styles, SetupStyles } from './SetupStyles';
import { AGENCIES, APP } from '../../helper/AppNameHelper';
import { routes } from '../../Routes';
import { useGetLeaseData } from '../../helper/RentServiceHelper';
import { AppSettings } from '../../AppSettings';
import { sortedWebUrls } from '../../SortedWebUrls';
import { store } from '../../store/Store';

interface SetupProps
  extends SetupStyles,
    RouteComponentProps<{ setupType: string; propertyId: string }> {}

const Setup: FC<SetupProps> = ({ classes, match, history }) => {
  const { setupType, propertyId } = match.params;
  const serviceType = setupType.charAt(0).toUpperCase() + setupType.slice(1);
  const screenStatus = setupType === 'rent';
  const isOtherService = setupType === 'otherServices';
  const lease = useGetLeaseData();

  const handleFinish = (toMarketplace?: boolean) => {
    if (APP === AGENCIES.NAX) {
      sendMessageToApp(ValidMessages.Dashboard);
    } else {
      if (setupType === 'otherServices') {
        sendMessageToApp(ValidMessages.Dashboard, { toMarketplace, toHome: !toMarketplace });
      } else if (setupType === LABELS.ROUTECHECK) {
        // sendMessageToApp(ValidMessages.ShowToast);
        const { cimetEnabled } = lease!;
        if (APP !== AGENCIES.SWITCH && !!cimetEnabled) {
          window.location.href = `${AppSettings.server.sortedWebUrl}${sortedWebUrls.cimet(
            propertyId,
            store.getState().token.accessToken as string,
            store.getState().token.refreshToken as string,
          )}`;
        } else {
          // If rent go to new checkout
          history.push(
            `${
              routes.checkout.select
            }?requiredServiceTypes=${rentDefaultServiceTypes.toString()}&propertyId=${propertyId}`,
          );
        }
      } else {
        sendMessageToApp(ValidMessages.intercomTagging, serviceTag(setupType));
        // sendMessageToApp(ValidMessages.Dashboard, { toMarketplace, toHome: !toMarketplace });
      }
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.background}>
        <img src={celebration} className={classes.backgroundImg} />
      </div>
      <div className={classes.bottomSheet}>
        <div className={classes.titleContainer}>
          <img className={classes.titleIcon} src={filledCheckIcon} />
          <div className={classes.title}>
            {isOtherService ? LABELS.OTHERSERVICEHEADING : serviceType + LABELS.HEADINGTEXT}
          </div>
        </div>
        <div className={classes.description}>
          {isOtherService
            ? LABELS.OTHERSERVICETEXT
            : screenStatus
            ? APP === AGENCIES.NAX
              ? LABELS.RENT_NAX
              : LABELS.RENT_SORTED
            : LABELS.SERVICESUBHEADINGTEXT}
        </div>
      </div>

      <div className={classes.footerButtonsContainer}>
        {APP !== AGENCIES.NAX && (
          <div className={classes.footerButton} onClick={() => handleFinish(true)}>
            {LABELS.SERVICE_SETUP}
          </div>
        )}
        <div className={classes.footerButtonSecondary} onClick={() => handleFinish(false)}>
          {LABELS.FINISH}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(withRouter(Setup));

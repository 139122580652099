/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  PaymentActions,
  PaymentAction,
  PaymentActionTypes,
  GetPaymentAuthKeyRequest,
  PaymentErrorPayload,
  PostPaymentRequest,
  GetPaymentMethodsPayLoad,
  GetPaymentTypes,
  GetPaymentRefId,
} from '../actions/PaymentActions';
import { GetPaymentTypeResponse } from '../../services/payment/getPaymentTypes/GetPaymentTypesResponse.data';
import PaymentService from '../../services/payment/PaymentService';
import { put, takeEvery, call } from 'redux-saga/effects';
import { PostPaymentUUIDResponse } from '../../services/payment/postPaymentUUID/PostPaymentUUIDResponse.data';
import { PostPaymentUUIDRequest } from '../../services/payment/postPaymentUUID/PostPaymentUUIDRequest.data';
import { GetPaymentMethodsResponse } from '../../services/payment/getPaymentMethods/GetPaymentMethods.data';
import { SpinnerActions } from '../actions/SpinnerActions';
import { GetPaymentAuthKey } from '../../services/payment/getPaymentAuthKey/GetPaymentAuthKey.data';
import {
  CreditCardRequest,
  BankAccountRequest,
  QuickStreamCreditCardRequest,
  QuickStreamBankAccountRequest,
  GetRefIdResponse,
} from '../../models/payment/PaymentRequest.data';
import { PostPaymentToken } from '../../services/payment/postPaymentToken/PostPyamentToken.data';
import { GetPaymentType } from '../../services/payment/getPaymentTypes/GetPaymentTypes.data';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';
import {
  GetFastPayResponse,
  PayIdPayLoad,
} from '../../services/payment/getFastPayIDResponse/GetFastPayIdResponse';
import camelizeCase from '../../helper/ConvertCase';
import pascalCase from '../../helper/ConvertPascal';
import {
  CheckoutStripeToken,
  GetStripePaymentIntentRequest,
  GetStripePaymentIntentResponse,
} from '../../services/payment/stripeCheckout/StripeCheckout';
import { PaymentGateway } from '../../containers/services/commonServices/CommonPlanSelectionInterface';

export function* getPaymentTypesWorker(action: PaymentAction): IterableIterator<any> {
  try {
    yield put(SpinnerActions.show());
    const { data, onSuccess } = action.data as GetPaymentTypes;
    const response: GetPaymentTypeResponse[] = yield PaymentService.getPaymentTypes(data);
    yield put(PaymentActions.getPaymentTypesSuccess(response));
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    yield put(SpinnerActions.hide());
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(PaymentActions.getPaymentTypesError({ error }));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* getPaymentPayIdWorker(action: PaymentAction): IterableIterator<any> {
  try {
    const { onSuccess } = action.data as any;
    yield put(SpinnerActions.show());
    const response = yield PaymentService.getPaymentPayId(pascalCase(action.data as PayIdPayLoad));
    const camelCasekeys: GetFastPayResponse = camelizeCase(response);
    yield put(PaymentActions.getPaymentPayIdSuccess(camelCasekeys));
    if (onSuccess) {
      onSuccess(camelCasekeys);
    }
  } catch (error) {
    yield put(SpinnerActions.hide());
    // sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(PaymentActions.getPaymentMethodsError({ error }));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* getPaymentMethodsWorker(action: PaymentAction): IterableIterator<any> {
  try {
    const { onSuccess } = action.data as GetPaymentMethodsPayLoad;
    yield put(SpinnerActions.show());
    const response: GetPaymentMethodsResponse[] = yield PaymentService.getPaymentMethods();
    yield put(PaymentActions.getPaymentMethodsSuccess(response));
    if (onSuccess) {
      onSuccess(response);
    }
  } catch (error) {
    yield put(SpinnerActions.hide());
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(PaymentActions.getPaymentMethodsError({ error }));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* postPaymentUUIDWorker(action: PaymentAction): IterableIterator<any> {
  try {
    yield put(SpinnerActions.show());
    const response: PostPaymentUUIDResponse = yield PaymentService.postPaymentUUID(
      action.data as PostPaymentUUIDRequest,
    );
    yield put(PaymentActions.postPaymentMethodUUIDSuccess(response));
  } catch (error) {
    yield put(SpinnerActions.hide());
    sendMessageToApp(ValidMessages.ErrorScreen);
    // yield put(PaymentActions.getPaymentTypesError({ error }));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* getPaymentRefId(action: PaymentAction): IterableIterator<any> {
  try {
    const actionData = action.data as GetPaymentRefId;
    const response: GetRefIdResponse = yield PaymentService.getPaymentRefId(
      actionData.token,
      actionData.providerId,
      actionData.transactionId,
    );

    actionData.onSuccess(response.accountToken);
    yield put(PaymentActions.getRefIdSuccess(response));
  } catch (error) {
    yield put(SpinnerActions.hide());
    yield put(PaymentActions.getRefIdError({ error }));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* getPaymentAuthKeySaga(action: PaymentAction): IterableIterator<any> {
  try {
    yield put(SpinnerActions.show());
    const actionData = action.data as GetPaymentAuthKeyRequest;
    const response: GetPaymentAuthKey = yield PaymentService.getPaymentAuthKey(
      actionData.paymentGateway,
      actionData.data.type === 'CREDITCARD' ? 'card' : 'bank',
      actionData.providerId,
    );
    if (response.status === 0) {
      // IF WESTPAC QUICK STREAM ==============================================================
      if (
        !!actionData.paymentGateway &&
        actionData.paymentGateway === PaymentGateway.WESTPAC_QUICK
      ) {
        QuickstreamAPI.init({
          publishableApiKey: response.authKey,
        });
        if (actionData.data.type === 'CREDITCARD') {
          const paymentDetails = actionData.data as CreditCardRequest;
          const quickStreamRequest: QuickStreamCreditCardRequest = {
            accountType: 'CREDIT_CARD',
            cardHolderName: paymentDetails.cardHolderName,
            cardNumber: paymentDetails.cardNumber.replace(/\s+/g, ''),
            expiryDateMonth: paymentDetails.expiryMonth,
            expiryDateYear: '20' + paymentDetails.expiryYear,
            supplierBusinessCode: response.businessCode!,
          };
          const form = document.createElement('form');
          for (const [key, value] of Object.entries(quickStreamRequest)) {
            const input = document.createElement('input');
            input.setAttribute('data-quickstream-api', key);
            input.value = value;
            form.appendChild(input);
          }
          QuickstreamAPI.creditCards.getToken(
            form as any,
            response.businessCode!,
            (errors, data) => {
              if (errors) {
                actionData.onError({ error: errors[0].messages[0] });
                console.log('Errors: ' + JSON.stringify(errors));
              } else {
                actionData.onSuccess(data.singleUseToken.singleUseTokenId);
              }
            },
          );
        } else {
          const paymentDetails = actionData.data as BankAccountRequest;
          const quickStreamRequest: QuickStreamBankAccountRequest = {
            accountType: 'DIRECT_DEBIT',
            accountName: paymentDetails.accountName,
            bsb: paymentDetails.bsbNumber.replace('-', ''),
            accountNumber: paymentDetails.accountNumber,
            supplierBusinessCode: response.businessCode!,
          };
          const form = document.createElement('form');
          for (const [key, value] of Object.entries(quickStreamRequest)) {
            const input = document.createElement('input');
            input.setAttribute('data-quickstream-api', key);
            input.value = value;
            form.appendChild(input);
          }
          QuickstreamAPI.bankAccounts.getToken(
            form as any,
            response.businessCode!,
            (errors, data) => {
              if (errors) {
                actionData.onError({ error: errors[0].messages[0] });
                console.log('Errors: ' + JSON.stringify(errors));
              } else {
                actionData.onSuccess(data.singleUseToken.singleUseTokenId);
              }
            },
          );
        }
        // IF BPOINT =============================================================================
      } else {
        let requestObject = {};
        if (actionData.data.type === 'CREDITCARD') {
          const paymentDetails = actionData.data as CreditCardRequest;
          requestObject = {
            Type: paymentDetails.type,
            CardHolderName: paymentDetails.cardHolderName,
            CardNumber: paymentDetails.cardNumber.replace(/\s+/g, ''),
            ExpiryMonth: paymentDetails.expiryMonth,
            ExpiryYear: paymentDetails.expiryYear,
          };
        } else {
          const paymentDetails = actionData.data as BankAccountRequest;
          requestObject = {
            Type: paymentDetails.type,
            BankAccountName: paymentDetails.accountName,
            BankAccountNumber: paymentDetails.accountNumber,
            BSBNumber: paymentDetails.bsbNumber.replace('-', ''),
            AcceptBankAccountTerms: true,
          };
        }
        const paymentDetails = actionData.data;
        // @ts-ignore
        CBA.ProcessAddToken({
          AuthKey: response.authKey,
          Crn1: paymentDetails.email,
          EmailAddress: paymentDetails.email,
          ...requestObject,
          CallbackFunction: (result: any) =>
            creditCardCallBackFunction(result, actionData.onSuccess, actionData.onError),
        });
      }
    } else {
      yield put(SpinnerActions.hide());
      yield put(
        PaymentActions.getPaymentAuthKeyError({
          error: 'error to fetch auth key',
        }),
      );
    }
    yield put(PaymentActions.getPaymentAuthKeySuccess(response));
  } catch (error) {
    yield put(SpinnerActions.hide());
    yield call(console.log, error);
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(PaymentActions.getPaymentAuthKeyError({ error }));
    yield put(SpinnerActions.hide());
  }
}

const creditCardCallBackFunction = (
  response: any,
  onSuccess: (token: string) => void,
  onError: (error: PaymentErrorPayload) => void,
) => {
  if (response.AjaxResponseType === 0 && response.ApiResponseCode === 0) {
    const dvtoken = response.ResultKey;
    onSuccess(dvtoken);
  } else {
    console.log('error: ', response);
    onError({ error: response.Errors[0].Message });
  }
};

export function* postPaymentTokenSaga(action: PaymentAction): IterableIterator<any> {
  try {
    yield put(SpinnerActions.show());
    const actionData = action.data as PostPaymentRequest;
    const response = yield PaymentService.postPaymentToken(actionData.data);
    yield put(PaymentActions.postPaymentTokenSuccess(response));
    if (actionData.onSuccess) {
      actionData.onSuccess(response);
    }
  } catch (error) {
    yield put(SpinnerActions.hide());
    yield call(console.log, error);
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(PaymentActions.getPaymentAuthKeyError({ error }));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

export function* getStripePaymentIntent(action: PaymentAction): IterableIterator<any> {
  try {
    const response: GetPaymentAuthKey = yield PaymentService.getPaymentAuthKey(
      PaymentGateway.STRIPE,
      'card',
      (action.data as GetStripePaymentIntentRequest).providerId,
    );

    const shapedResponse = {
      stripePromise: response.authKey,
      serviceType: (action.data as GetStripePaymentIntentRequest).serviceType,
      providerId: (action.data as GetStripePaymentIntentRequest).providerId,
      productId: (action.data as GetStripePaymentIntentRequest).productId,
    } as CheckoutStripeToken;
    yield put(PaymentActions.getStripeCheckoutTokenSuccess(shapedResponse));
  } catch (error) {
    yield call(console.log, error);
    yield put(PaymentActions.getStripeCheckoutTokenError(error as any));
  }
}

function* paymentWatcher(): IterableIterator<any> {
  yield takeEvery(PaymentActionTypes.GetPaymentTypesStart, (action) =>
    getPaymentTypesWorker({
      type: action.type,
      data: (action as PaymentAction).data,
    }),
  );

  yield takeEvery(PaymentActionTypes.PostPaymentMethodUUIDStart, (action) =>
    postPaymentUUIDWorker({
      type: action.type,
      data: (action as PaymentAction).data,
    }),
  );

  yield takeEvery(PaymentActionTypes.GetPaymentMethodsStart, (action) =>
    getPaymentMethodsWorker({
      type: action.type,
      data: (action as PaymentAction).data,
    }),
  );

  yield takeEvery(PaymentActionTypes.RequestFastPayIdDetails, (action) =>
    getPaymentPayIdWorker({
      type: action.type,
      data: (action as PaymentAction).data,
    }),
  );

  yield takeEvery(PaymentActionTypes.GetPaymentAuthKeyStart, (action) =>
    getPaymentAuthKeySaga({
      type: action.type,
      data: (action as PaymentAction).data,
    }),
  );
  yield takeEvery(PaymentActionTypes.PostPaymentTokenStart, (action) =>
    postPaymentTokenSaga({
      type: action.type,
      data: (action as PaymentAction).data,
    }),
  );
  yield takeEvery(PaymentActionTypes.GET_STRIPE_CHECKOUT_TOKEN_REQUEST, (action) =>
    getStripePaymentIntent({
      type: action.type,
      data: (action as PaymentAction).data,
    }),
  );
  yield takeEvery(PaymentActionTypes.GET_REFID_REQUEST, (action) =>
    getPaymentRefId({
      type: action.type,
      data: (action as PaymentAction).data,
    }),
  );
}

export default paymentWatcher;

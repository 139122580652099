import { color } from '../../theme/Theme';
export interface WelcomeScreenStyles {
  agencyLogo: any;
  readonly root: any;
  readonly body: any;
  readonly providerBox: any;
  readonly providerMessage: any;
  readonly sortedBox: any;
  readonly sortedImage: any;
  readonly sortedMessage: any;
  readonly listBox: any;
  readonly listItem: any;
  readonly listIcon: any;
  readonly listInfoBox: any;
  readonly listTitle: any;
  readonly listMessage: any;
  readonly stepContainerStyle: any;
  readonly rootContainer: any;
  readonly imageContainer: any;
  readonly imageStyle: any;
  readonly titleStyle: any;
  readonly subTitleStyle: any;
  readonly buttonContainer: any;
  readonly chatButtonStyle: any;
  readonly closeButtonStyle: any;
  readonly naxImage: any;
  readonly naxProviderMessage: any;
  readonly taImage: any;
}

export const Styles: WelcomeScreenStyles = {
  root: {
    backgroundColor: color.light,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  body: {
    padding: '20px 16px 70px 16px',
  },
  providerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginHorizontal: 45,
  },
  providerMessage: {
    fontFamily: 'GT Walsheim',
    marginTop: '1vh',
    color: color.dark,
    opacity: 0.87,
    height: 20,
    letterSpacing: 0.25,
    fontSize: 16,
  },
  naxProviderMessage: {
    fontFamily: 'GT Walsheim',
    marginTop: '6vh',
    color: color.dark,
    opacity: 0.87,
    height: 20,
    letterSpacing: 0.25,
    fontSize: 16,
  },
  sortedBox: {
    marginTop: '5vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sortedImage: {
    width: 126,
    height: 31,
  },
  naxImage: {
    width: 173,
    height: 62,
  },
  taImage: {
    width: 220,
    height: 25,
    marginBottom: 15,
    marginTop: 20,
  },
  sortedMessage: {
    fontFamily: 'GT Walsheim',
    marginTop: '1.5vh',
    color: color.primary,
    height: 18,
    letterSpacing: 0.25,
    fontSize: 16,
  },
  stepContainerStyle: {
    paddingTop: 48,
  },
  listBox: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 300,
  },
  listItem: {
    'fontFamily': 'GT Walsheim',
    'marginLeft': 30,
    '&:first-child': {
      marginTop: 0,
    },
    'marginTop': 55,
    'display': 'flex',
    'flexDirection': 'row',
    'alignItems': 'center',
  },
  listIcon: {
    marginRight: 23,
    height: 32,
    width: 32,
  },
  listInfoBox: {},
  listTitle: {
    fontSize: 23,
    height: 23.5,
    letterSpacing: 0.25,
  },
  listMessage: {
    fontFamily: 'GT Walsheim light',
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: 2,
    color: color.dark,
    opacity: 0.8,
  },
  agencyLogo: {
    height: 140,
    marginTop: 0,
    padding: 0,
  },
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 32px)',
    justifyContent: 'space-between',
    padding: 16,
  },
  imageContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 16,
    paddingRight: 16,
  },
  imageStyle: {
    width: 64,
    height: 64,
    marginBottom: 16,
  },
  titleStyle: {
    textAlign: 'center',
    padding: 24,
    color: color.titleColor,
  },
  subTitleStyle: {
    textAlign: 'center',
    color: color.titleColor,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-end',
  },
  chatButtonStyle: {
    height: 36,
    marginBottom: 16,
  },
  closeButtonStyle: {
    backgroundColor: color.buttonBackground,
    height: 36,
  },
};

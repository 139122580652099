import { WithStyles, createStyles, createMuiTheme } from '@material-ui/core';
import { color } from '../../../../theme/Theme';

export const Styles = createStyles({
  root: {
    paddingLeft: 16,
    paddingRight: 16,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  addressBoxStyle: {
    marginTop: 16,
  },
  imageBoxStyle: {
    display: 'flex',
    justifyContent: 'center',
    width: 64,
    height: 64,
    borderRadius: '50%',
    backgroundColor: color.light,
    position: 'relative',
    marginBottom: -32,
    marginLeft: 19,
  },
  imageStyle: {
    width: 48,
    height: 48,
    borderRadius: 24,
  },
  cardStyle: {
    borderRadius: 8,
    padding: 0,
  },
  cardContentStyle: {
    'display': 'flex',
    'flex': 1,
    'flexDirection': 'column',
    'padding': 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardHeaderStyle: {
    height: 42,
    backgroundColor: color.secondary,
  },
  listStyle: {
    padding: 16,
  },
  listItemStyle: {
    padding: 0,
  },
  primaryTextStyle: {
    color: color.dark,
    opacity: 0.38,
  },
  secondaryTextStyle: {
    color: color.dark,
    opacity: 0.87,
  },
  footer: {
    display: 'flex',
    marginBottom: 16,
  },
  button: {
    backgroundColor: '#fff',
    height: 36,
    display: 'flex',
    flex: 1,
    color: color.primary,
  },
  dateContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginTop: 24,
  },
  body: {
    marginTop: 48,
    paddingTop: 24,
    marginBottom: 200,
  },
  datePicker: {
    flex: 1,
    marginTop: 24,
  },
  input: {
    border: 'none',
    borderBottom: `2px solid ${color.secondary300}`,
  },
  datePickerStyle: {
    flex: 1,
  },
  text: {
    marginTop: 20,
  },
});
export const customThemeDatePicker = createMuiTheme({
  palette: {
    primary: {
      main: '#1BADC1',
    },
  },
  overrides: {
    MuiButton: {
      textPrimary: {
        color: color.primary,
      },
    },
    MuiInputLabel: {
      root: {
        color: color.primary,
      },
    },
  },
});

export interface DateConfirmationStyles extends WithStyles<typeof Styles> {}

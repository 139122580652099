import { color } from '../../../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';
import { SIZES } from '../../../../Constants';

export const styles = createStyles({
  supplierContainer: {
    overflow: 'hidden',
    borderRadius: 6,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  backgroundImgContainer: {
    width: '100%',
    height: '120px',
  },
  backgroundImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  detailsContainer: {
    position: 'relative',
    padding: 15,
    border: `${color.borderColor} 2px solid`,
    borderTop: 'none',
    borderRadius: 6,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    // minHeight: '85px',
    height: '90px',
  },
  logoContainer: {
    position: 'absolute',
    right: 10,
    top: -25,
    width: '50px',
    height: '50px',
    borderRadius: 5000,
    overflow: 'hidden',
    border: `${color.borderColor} 1px solid`,
    boxSizing: 'border-box',
    backgroundColor: 'white',
  },
  logo: {
    width: 'calc(100% + 4px)',
    height: 'calc(100% + 4px)',
    objectFit: 'cover',
    objectPosition: 'center',
    position: 'relative',
    top: '-2px',
    left: '-2px',
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  supplierDetails: {
    marginRight: 10,
  },
  supplierTitle: {
    fontFamily: 'GT Walsheim',
    fontSize: '15px',
    color: color.black87,
  },
  supplierDescription: {
    fontFamily: 'GT Walsheim',
    fontSize: '13px',
    color: color.black38,
    marginTop: 8,

    [`@media (max-width: ${SIZES.sm}px)`]: {
      maxWidth: '300px',
    },
  },
  actionContainer: {
    padding: '8px',
    borderRadius: 5000,
    width: 16,
    height: 16,
    alignSelf: 'flex-end',
    backgroundColor: color.borderColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  arrow: {
    width: 24,
    height: 24,
  },
});

export interface SupplierStyles extends WithStyles<typeof styles> {}

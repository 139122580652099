import { StoreAction } from '../StoreHelper';
import {
  ElectricityPlanSummary,
  BroadbandPlanSummary,
} from '../../services/planSummary/getPlanSummary/GetPlanSummary.data';
import { PostSummaryRequest } from '../../containers/services/otherServices/OtherServicesSummary.data';
import {
  ServiceIframe,
  ServiceIframeInProgress,
} from '../../component/services/commonService/kannopiService/KanopiInterface';

export enum PlanSummaryActionTypes {
  GetPlanSummaryStart = 'GET_PLAN_SUMMARY_START',
  GetPlanSummarySuccess = 'GET_PLAN_SUMMARY_SUCCESS',
  GetPlanSummaryError = 'GET_PLAN_SUMMARY_ERROR',

  GetServiceSummaryStart = 'GET_SERVICE_SUMMARY_START',
  SetServiceId = 'SET_SERVICE_ID_KANOPI',
  SetIframeData = 'SET_IFRAME_DATA',
  GetServiceSummarySuccess = 'GET_SERVICE_SUMMARY_SUCCESS',
  SetServiceIdSuccess = 'SET_SERVICE_ID_SUCCESS',
  GetServiceSummaryError = 'GET_SERVICE_SUMMARY_ERROR',
  SetServiceIdError = 'SET_SERVICE_ID_ERROR',

  PostServiceSummaryStart = 'POST_SERVICE_SUMMARY_START',
  PostServiceSummarySuccess = 'POST_SERVICE_SUMMARY_SUCCESS',
  PostServiceSummaryError = 'POST_SERVICE_SUMMARY_ERROR',

  GetServiceInfoStart = 'GET_SERVICE_INFO_START',
  GetServiceInfoSuccess = 'GET_SERVICE_INFO_SUCCESS',
  GetServiceInfoError = 'GET_SERVICE_INFO_ERROR',

  SaveSelections = 'SAVE_SELECTIONS',
}

export interface PlanSummaryErrorPayload {
  //TODO: Resolve any
  readonly error: Error | any;
}

export interface GetPlanSummaryRequest {
  serviceAccountId: string;
  serviceSummary: string;
  onSuccess: (v1: boolean) => void;
  onError: () => void;
}

export interface GetServiceSummaryRequest {
  propertyId: string;
  serviceType: string;
  providerName: string;
  onSuccess?: (v1: boolean) => void;
  onError?: () => void;
}

export interface SetServiceIdRequest {
  body: ServiceIframeInProgress | ServiceIframe;
  onSuccess?: (v1: boolean) => void;
  onError?: () => void;
}

export interface GetServiceInfoRequest {
  serviceAccountId: string;
}

export interface PostServiceSummaryRequest {
  propertyId: string;
  serviceType: string;
  //TODO: Resolve any
  body: any;
  onSuccess?: (v1: boolean) => void;
  onError?: () => void;
}

//TODO: Resolve any
export type PlanSummaryPayload =
  | GetPlanSummaryRequest
  | any
  | PlanSummaryErrorPayload
  | any
  | GetServiceSummaryRequest;

export type PlanSummaryAction = StoreAction<PlanSummaryActionTypes, PlanSummaryPayload>;

export class PlanSummaryActions {
  public static getPlanSummaryStart(data: GetPlanSummaryRequest): PlanSummaryAction {
    return {
      type: PlanSummaryActionTypes.GetPlanSummaryStart,
      data,
    };
  }
  public static getPlanSummarySuccess(
    data: ElectricityPlanSummary | BroadbandPlanSummary,
  ): PlanSummaryAction {
    return {
      type: PlanSummaryActionTypes.GetPlanSummarySuccess,
      data,
    };
  }
  public static getPlanSummaryError(data: PlanSummaryErrorPayload): PlanSummaryAction {
    return {
      type: PlanSummaryActionTypes.GetPlanSummaryError,
      data,
    };
  }
  public static getServiceSummaryStart(data: GetServiceSummaryRequest): PlanSummaryAction {
    return {
      type: PlanSummaryActionTypes.GetServiceSummaryStart,
      data,
    };
  }
  public static startServiceId(data: SetServiceIdRequest): PlanSummaryAction {
    return {
      type: PlanSummaryActionTypes.SetServiceId,
      data,
    };
  }

  public static setServiceIdSuccess(data: any): PlanSummaryAction {
    return {
      type: PlanSummaryActionTypes.SetServiceIdSuccess,
      data,
    };
  }
  //TODO: Resolve any
  public static getServiceSummarySuccess(data: any): PlanSummaryAction {
    return {
      type: PlanSummaryActionTypes.GetServiceSummarySuccess,
      data,
    };
  }
  public static getServiceSummaryError(data: PlanSummaryErrorPayload): PlanSummaryAction {
    return {
      type: PlanSummaryActionTypes.GetServiceSummaryError,
      data,
    };
  }
  public static setServiceIdError(data: PlanSummaryErrorPayload): PlanSummaryAction {
    return {
      type: PlanSummaryActionTypes.SetServiceIdError,
      data,
    };
  }
  public static postServiceSummaryStart(data: {
    serviceType: string;
    propertyId: string;
    body: PostSummaryRequest;
    onSuccess: () => void;
  }): PlanSummaryAction {
    return {
      type: PlanSummaryActionTypes.PostServiceSummaryStart,
      data,
    };
  }
  //TODO: Resolve any
  public static postServiceSummarySuccess(data: any): PlanSummaryAction {
    return {
      type: PlanSummaryActionTypes.PostServiceSummarySuccess,
      data,
    };
  }
  public static postServiceSummaryError(data: PlanSummaryErrorPayload): PlanSummaryAction {
    return {
      type: PlanSummaryActionTypes.PostServiceSummaryError,
      data,
    };
  }
  public static getServiceInfoStart(data: GetServiceInfoRequest): PlanSummaryAction {
    return {
      type: PlanSummaryActionTypes.GetServiceInfoStart,
      data,
    };
  }
  //TODO: Resolve any
  public static getServiceInfoSuccess(data: any): PlanSummaryAction {
    return {
      type: PlanSummaryActionTypes.GetServiceInfoSuccess,
      data,
    };
  }
  public static getServiceInfoError(data: PlanSummaryErrorPayload): PlanSummaryAction {
    return {
      type: PlanSummaryActionTypes.GetServiceInfoError,
      data,
    };
  }
  //TODO: Resolve any
  public static saveSelections(data: any): PlanSummaryAction {
    return {
      type: PlanSummaryActionTypes.SaveSelections,
      data,
    };
  }
}

import { PlanRequest } from '../../services/plan/getPlanDetails/GetPlanDetails.data';

export const noService = '1/580  Church Street';
export const adslService = 'ADSL Broadband';
export const nbnService = 'NBN Broadband';
export const available = 'Available';
export const unavailable = 'Unavailable';
export const edit = 'Edit';
export const chatButton = 'Chat to Support';
export const backButton = 'Back to Home';
export const next = 'Next';

export const createBroadbandPlanRequest = (
  postcode: string,
  keyIdentifier: string,
): PlanRequest => {
  return {
    ServiceType: 'Broadband',
    Postcode: postcode,
    KeyIdentifier: keyIdentifier || '',
  };
};

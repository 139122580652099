import { color } from '../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  root: {
    backgroundColor: '#fff',
    padding: 12,
    borderRadius: 500,
    border: `${color.borderColor} 1px solid`,
  },
  searchContainer: {
    padding: 12,
    top: '0',
    position: 'relative',
    background: '#fafafa',
    // transition: 'all 0.5s ease 0s',
  },
  active: {
    padding: 12,
    top: '49px',
    position: 'fixed',
    background: '#fafafa',
    paddingBottom: 28,
    left: 0,
    right: 0,
    // transition: 'all 0.5s ease 0s',
  },
  searchIcon: {
    marginLeft: 6,
    marginRight: 12,
    width: 24,
    height: 24,
    cursor: 'pointer',
  },
  crossIcon: {
    marginLeft: 12,
    marginRight: 6,
    width: 24,
    height: 24,
    cursor: 'pointer',
  },
  searchLogo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
});

export interface AddressPickerStyles extends WithStyles<typeof styles> {}

import React, { FC, useState } from 'react';
import { Typography, Card } from '@material-ui/core';

import { PlanSelectionStyles } from './PlanSelectionStyles';
import SelectComponent from '../electricityService/addressForm/SelectComponent';
import {
  ComponentOptions,
  SupplierOption,
  SupplierOptions,
} from '../../../containers/services/commonServices/CommonPlanSelectionInterface';
import camelizeCase from '../../../helper/ConvertCase';

interface Props {
  classes: any;
  callBackHandler: (enteredValue: string) => void;
  optionValue: SupplierOptions;
  defaultValue?: string;
}
const DropDownComponent: FC<Props & PlanSelectionStyles> = ({
  classes,
  callBackHandler,
  optionValue,
  defaultValue,
}) => {
  const [dropDownValue, setDropDownValue] = useState(defaultValue || '');
  return (
    <Card elevation={2} className={`${classes.detailCard} ${classes.planCardContainer}`}>
      <Typography variant="h6">{optionValue.Heading}</Typography>
      <div className={classes.seperator} />
      <Typography variant="h1" className={`${classes.headerText2} ${classes.gtwLight}`}>
        {optionValue.Description}
      </Typography>
      <SelectComponent
        label={`${optionValue.ComponentText}`}
        value={dropDownValue}
        setFieldValue={(name, value) => {
          optionValue.ComponentOptions.map((item: ComponentOptions) => {
            if (item.Value === value) {
              setDropDownValue(value);
              callBackHandler(value);
            }
          });
        }}
        name="dropDownValue"
        selectValues={camelizeCase(optionValue.ComponentOptions)}
      />
    </Card>
  );
};

export default DropDownComponent;

import { createStyles, WithStyles } from '@material-ui/core';
import { color } from '../../../theme/Theme';

export const Styles = createStyles({
  root: {
    padding: 16,
    marginBottom: 40,
    marginTop: 48,
  },
  formRoot: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  inputStyle: {
    marginRight: 8,
  },
  emailStyle: {
    marginRight: 8,
    marginBottom: -30,
  },
  datePickerComponent: {
    marginTop: 18,
  },
  datePickerLabel: {
    paddingLeft: 20,
  },
  datePicker: {
    '& input': {
      paddingLeft: 16,
    },
  },
  mobilePicker: {
    'marginTop': 10,
    'marginLeft': 5,
    'marginRight': 5,
    'paddingLeft': 15,
    'paddingBottom': 10,
    'borderBottom': '1px solid #a9a9a9',
    '& input': {
      border: 'none',
      fontSize: '1rem',
      fontWeight: 500,
    },
  },
  mobileLabelStyle: {
    color: color.primary,
    fontWeight: 600,
    fontSize: '0.7rem',
    marginBottom: 10,
  },
  errorBorder: {
    borderColor: color.danger,
  },
  defaultBorder: {
    borderColor: color.secondary300,
  },
  errorMessage: {
    color: color.danger,
    fontSize: 13,
    paddingLeft: 16,
    marginTop: 3,
    fontfamily: 'GTWalsheim',
    fontWeight: 500,
    lineHeight: '1.18',
    letterSpacing: '0.4px',
  },
  inputLabelStyle: {
    marginLeft: 16,
    color: color.primary,
    fontWeight: 'lighter',
    fontSize: '16px',
  },
  noInputLabelStyle: {
    marginLeft: 16,
    color: color.secondary300,
    fontWeight: 'lighter',
    fontSize: '16px',
  },
});

export interface PersonalDetailStyles extends WithStyles<typeof Styles> {}

import { StoreAction } from '../StoreHelper';
import { PropertyDetails } from '../../services/electricityService/getElectricityService/GetElectricityService.data';
import { PostCommonServiceAddressSaveRequest } from '../../services/commonService/getCommonService/GetCommonService.data';
import { AddressLookup } from '../../services/electricityService/getElectricityAddressLookup/GetElectricityAddressLookup.data';
import { CommonServiceAddressLookup } from '../../services/commonService/getCommonServiceAddressLookup/GetCommonAddressLookup.data';
import { Referal } from '../../component/services/commonService/kannopiService/KanopiInterface';

export enum PlanCommonSummaryActionTypes {
  GetCommonSummary = 'GET_COMMON_SUMMARY_START',
  CreatePropertySelected = 'CREATE_PROPERTY_SELECTED',

  GetCommonSummarySuccess = 'GET_COMMON_SUMMARY_SUCCESS',
  GetCommonSummaryError = 'GET_COMMON_SUMMARY_ERROR',
  SetFinalScreenData = 'SET_FINAL_SCREEN_DATA',

  GetCommonSummaryInvoicesStart = 'GET_COMMON_SUMMARY_INVOICES_START',
  GetCommonSummaryInvoicesSuccess = 'GET_COMMON_SUMMARY_INVOICES_SUCCESS',
  GetCommonSummaryInvoicesError = 'GET_COMMON_SUMMARY_INVOICES_ERROR',

  GetAddressStart = 'GET_ADDRRESS_START',
  GetAddressSuccess = 'GET_ADDRESS_SUCCESS',
  GetAddressError = 'GET_ADDRESS_ERROR',

  GetProperty = 'GET_PROPERTY',
  SaveProperty = 'SAVE_PROPERTY',

  GetCommonAddressLookupStart = 'GET_COMMON_ADDRESS_LOOKUP_START',
  GetCommonAddressLookupSuccess = 'GET_COMMON_ADDRESS_LOOKUP_SUCCESS',
  GetCommonAddressLookupError = 'GET_COMMON_ADDRESS_LOOKUP_ERROR',

  SavePropertyAddressStart = 'SAVE_PROPERTY_ADDRESS_START',
  SavePropertyAddressSuccess = 'SAVE_PROPERTY_ADDRESS_SUCCESS',
  RemoveServiceId = 'REMOVE_SERVICE_ID',
  SetServiceId = 'SET_SERVICE_ID',
  SetStopCall = 'SET_STOP_CALL',
  SetProviderId = 'SET_PROVIER_ID',
  SaveCommonPlanSuccess = 'SAVE_COMMON_PLAN_SUCCESS',
  SaveCommonPlanError = 'SAVE_COMMON_PLAN_ERROR',
  SavePropertyAddressError = 'SAVE_PROPERTY_ADDRESS_ERROR',
  GetCommonQuotesStart = 'GET_COMMON_QUOTES_START',
  GetIframeUrlStart = 'GET_IFRAME_URL',
  PostPersonalDetails = 'POST_PERSONAL_DETAIL',
  SetPersonalDetailStatus = 'SET_PERSONAL_DETAI:L_STATUS',
  PostCommonSummaryData = 'POST_COMMON_SUMMARY_DATA',
  setTncData = 'SET_TNC_DATA',
  StorePlanSummaryData = 'STORE_PLAN_SUMMARY_DATA',
  GetCommonQuotesSuccess = 'GET_COMMON_QUOTES_SUCCESS',
  GetIframeUrlSuccess = 'GET_IFRAME_URL_SUCCESS',
  SetIframeData = 'SET_IFRAME_DATA',
  PostPersonalDetailsSuccess = 'POST_PERSONAL_DETAILS_SUCCESS',
  PostPersonalDetailsError = 'POST_PERSONAL_DETAILS_ERROR',
  GetCommonQuotesError = 'GET_COMMON_QUOTES_ERROR',
}

export interface PlanCommonSummaryErrorPayload {
  //TODO: Resolve any
  readonly error: Error | any;
}
export interface SetIframeData {
  body: string;
}

export interface GetCommonServiceAddressLookup {
  readonly data: AddressLookup;
  //TODO: Resolve any
  readonly onSuccess: (response: any) => void;
  readonly onError: () => void;
}

export interface GetCommonQuote {
  //TODO: Resolve any
  readonly data: any;
  //TODO: Resolve any
  readonly onSuccess?: (response: any) => void;
}

export interface GetIframe {
  readonly data: Referal;
  readonly url: string;
  readonly token: string;
}

export interface GetCommonSummaryRequest {
  readonly propertyId: number;
  readonly serviceAccountId: number;
}

export interface GetCommonAddressLookupRequest {
  readonly propertyId: string;
  readonly providerName: string;
  readonly serviceName: string;
  readonly onSuccess?: () => void;
  readonly onError?: (error: string) => void;
}

export interface PostCommonSummaryDataRequest {
  //TODO: Resolve any
  readonly data: any;
  readonly onSuccess?: () => void;
  readonly onError?: (error: string) => void;
}

export interface StorePlanSummaryData {
  readonly data: {};
}

export type PlanCommonSummaryPayload =
  | GetCommonSummaryRequest
  | GetCommonAddressLookupRequest
  | GetCommonServiceAddressLookup
  | PostCommonServiceAddressSaveRequest
  | GetCommonQuote
  | PlanCommonSummaryErrorPayload
  //TODO: Resolve any
  | any;

export type PlanCommonSummaryAction = StoreAction<
  PlanCommonSummaryActionTypes,
  PlanCommonSummaryPayload
>;

export class PlanCommonSummaryActions {
  // The plan details page
  public static getPlanCommonSummaryStart(data: GetCommonSummaryRequest): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.GetCommonSummary,
      data,
    };
  }
  //TODO: Resolve any
  public static createPropertySelected(data: any): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.CreatePropertySelected,
      data,
    };
  }
  public static getPlanCommonSummarySuccess(
    //TODO: Resolve any
    data: any,
  ): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.GetCommonSummarySuccess,
      data,
    };
  }
  public static getPlanCommonSummaryError(
    data: PlanCommonSummaryErrorPayload,
  ): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.GetCommonSummaryError,
      data,
    };
  }

  // Invoices
  public static getInvoicesCommonSummaryStart(
    data: GetCommonSummaryRequest,
  ): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.GetCommonSummaryInvoicesStart,
      data,
    };
  }
  public static getInvoicesCommonSummarySuccess(
    //TODO: Resolve any
    data: any,
  ): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.GetCommonSummaryInvoicesSuccess,
      data,
    };
  }
  public static getInvoicesCommonSummaryError(
    //TODO: Resolve any
    data: any,
  ): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.GetCommonSummaryInvoicesError,
      data,
    };
  }

  public static saveProperty(data: PropertyDetails): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.SaveProperty,
      data,
    };
  }

  // Data for the address form page
  public static getAddressStart(propertyId: string): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.GetAddressStart,
      data: propertyId,
    };
  }
  public static getAddressSuccess(data: PropertyDetails): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.GetAddressSuccess,
      data,
    };
  }
  //TODO: Resolve any
  public static getAddressError(data: any): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.GetAddressError,
      data,
    };
  }

  // Data for the address list page / lookup api
  public static getAddressLookupStart(
    data: GetCommonAddressLookupRequest,
  ): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.GetCommonAddressLookupStart,
      data,
    };
  }
  public static getAddressLookupSuccess(data: CommonServiceAddressLookup): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.GetCommonAddressLookupSuccess,
      data,
    };
  }
  //TODO: Resolve any
  public static getAddressLookupError(data: any): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.GetCommonAddressLookupError,
      data,
    };
  }

  // For the post calls of /service-account/usage
  public static setPropertyAddressData(data: {
    data: PostCommonServiceAddressSaveRequest;
    //TODO: Resolve any
    onSuccess: (response: any) => void;
    //TODO: Resolve any
    onError?: (error: any) => void;
  }): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.SavePropertyAddressStart,
      data,
    };
  }

  public static setStopCall(data: { data: number }): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.SetStopCall,
      data,
    };
  }

  public static setPropertyAddressDataSuccess(
    //TODO: Resolve any
    response: any,
  ): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.SavePropertyAddressSuccess,
      data: response,
    };
  }

  public static removeServiceId(data: number): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.RemoveServiceId,
      data,
    };
  }

  public static setServiceId(data: number): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.SetServiceId,
      data,
    };
  }

  public static setProviderId(data: string): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.SetProviderId,
      data,
    };
  }

  public static setPropertyAddressDataError(error: string): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.SavePropertyAddressError,
      data: error,
    };
  }

  public static setCommonServiceDataError(error: string): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.SaveCommonPlanError,
      data: error,
    };
  }

  public static setCommonServiceDataSuccess(
    //TODO: Resolve any
    response: any,
  ): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.SaveCommonPlanSuccess,
      data: response,
    };
  }

  public static getCommonQuoteStart(data: GetCommonQuote): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.GetCommonQuotesStart,
      data,
    };
  }

  public static getIframeUrlStart(data: GetIframe): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.GetIframeUrlStart,
      data,
    };
  }

  //TODO: Resolve any
  public static postPersonalDetails(data: any): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.PostPersonalDetails,
      data,
    };
  }

  public static setPersonalDetailStatus(): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.SetPersonalDetailStatus,
      data: null,
    };
  }

  //TODO: Resolve any
  public static setFinalScreenData(data: any): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.SetFinalScreenData,
      data,
    };
  }

  public static postCommonSummaryData(data: PostCommonSummaryDataRequest): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.PostCommonSummaryData,
      data,
    };
  }

  public static setTncData(data: PostCommonSummaryDataRequest): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.setTncData,
      data,
    };
  }

  public static storePlanSummaryData(data: StorePlanSummaryData): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.StorePlanSummaryData,
      data,
    };
  }

  public static getCommonQuoteSuccess(
    //TODO: Resolve any
    data: any, // Replace with GetCommonSummaryData
  ): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.GetCommonQuotesSuccess,
      data,
    };
  }

  public static setIframeData(data: SetIframeData): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.SetIframeData,
      data,
    };
  }

  public static getIframeUrlSuccess(
    data: any, // Replace with GetCommonSummaryData
  ): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.GetIframeUrlSuccess,
      data,
    };
  }

  public static postPersonalDetailsSuccess(
    //TODO: Resolve any
    data: any, // Replace with GetCommonSummaryData
  ): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.PostPersonalDetailsSuccess,
      data,
    };
  }
  public static postPersonalDetailsError(
    data: PlanCommonSummaryErrorPayload,
  ): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.PostPersonalDetailsError,
      data,
    };
  }

  public static getCommonQuoteError(data: PlanCommonSummaryErrorPayload): PlanCommonSummaryAction {
    return {
      type: PlanCommonSummaryActionTypes.GetCommonQuotesError,
      data,
    };
  }
}

import * as React from 'react';
import ContractScreenComponent from '../../../../component/services/plan/broadband/ContractScreen';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/RootReducers';
import { Dispatch, AnyAction } from 'redux';
import { BroadbandPlanState } from '../../../../store/state/BroadbandPlanState';
import { BroadbandPlanActions } from '../../../../store/actions/BroadbandPlanAction';
import {
  BroadbandPlan,
  BroadbandPlanDetails,
  BroadbandProducts,
} from '../../../../models/plan/broadband/getPlanDetails/BroadbandPlan.data';
import { History } from 'history';
import {
  BroadbandPlanSubmit,
  BroadbandServiceDetails,
} from '../../../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import { BroadbandServiceActions } from '../../../../store/actions/BroadbandServiceActions';
import { RouteComponentProps } from 'react-router-dom';
import { routes } from '../../../../Routes';
import {
  StepDetails,
  ServiceAccount,
} from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import PlanHelper from '../../../../helper/PlanHelper';
import uuidv4 from 'uuid/v4';
import {
  LABELS_VOICE,
  createBroadbandPostPlanObject,
} from '../../../../component/services/plan/broadband/Constants';
import { BackHelper } from '../../../../helper/BackHelper';

interface PropsFromState {
  plan: BroadbandPlanState;
  serviceAccountId: number;
}

interface PropsFromDispatch {
  saveSelectedContractPlan: (data: string) => void;
  postProperty: (
    propertyId: string,
    serviceAccountId: string,
    serviceSummary: string,
    data: BroadbandServiceDetails,
    props: OwnProps,
  ) => void;
}

interface PropsFromRoute {
  history: History;
}

type OwnProps = PropsFromDispatch &
  PropsFromState &
  PropsFromRoute &
  RouteComponentProps<{ propertyId: string; serviceSummary: string }>;
class ContractScreen extends React.Component<OwnProps> {
  public render() {
    const { plan } = this.props;
    const choosenProduct =
      plan.broadbandChoice === BroadbandProducts.ADSL
        ? (plan.broadbandPlan as BroadbandPlan).ADSL
        : (plan.broadbandPlan as BroadbandPlan).NBN;
    return (
      <ContractScreenComponent
        {...this.props}
        planDetails={choosenProduct as BroadbandPlanDetails}
        plan={plan}
        handleNext={this.handleNext}
      />
    );
  }

  private handleNext = (choice: string, minimumCost: number, connectionFee: number) => {
    const { propertyId, serviceSummary } = this.props.match.params;
    this.props.saveSelectedContractPlan(choice);
    const { plan, serviceAccountId } = this.props;
    const { voicePlan, dataPlan, downloadPlan, modem } = this.props.plan;
    const choosenProduct =
      plan.broadbandChoice === BroadbandProducts.ADSL
        ? ((plan.broadbandPlan as BroadbandPlan).ADSL as BroadbandPlanDetails)
        : ((plan.broadbandPlan as BroadbandPlan).NBN as BroadbandPlanDetails);
    const productName = PlanHelper.getProductName(
      choosenProduct.productNames,
      dataPlan,
      downloadPlan as string,
      voicePlan === LABELS_VOICE.options[0],
    );
    const modemInfo = PlanHelper.getModemDetails(choosenProduct.plan);
    const productInfo: BroadbandPlanSubmit = PlanHelper.getProductDetails(
      choosenProduct.plan,
      productName,
    );
    const contract = choice !== 'NONE';
    const data: BroadbandServiceDetails = {
      serviceDetails: {
        broadbandPlanDetails: createBroadbandPostPlanObject(
          productInfo,
          choosenProduct,
          contract,
          downloadPlan as string,
          voicePlan as string,
          modem,
          modemInfo,
          minimumCost,
          connectionFee,
        ),
        transactionId: uuidv4(),
      },
      step: 'PLAN_SELECTION',
    };
    this.props.postProperty(
      propertyId,
      serviceAccountId.toString(),
      serviceSummary,
      data,
      this.props,
    );
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    plan: state.broadbandPlan,
    serviceAccountId: (
      (state.broadbandService.service as BroadbandServiceDetails).serviceAccount as ServiceAccount
    ).id as number,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): PropsFromDispatch {
  return {
    saveSelectedContractPlan: (data: string) =>
      dispatch(BroadbandPlanActions.saveContractChoice(data)),
    postProperty: (
      propertyId: string,
      serviceAccountId: string,
      serviceSummary: string,
      data: BroadbandServiceDetails,
      props: OwnProps,
    ) => {
      dispatch(
        BroadbandServiceActions.postBroadbandServiceStart({
          propertyId,
          serviceAccountId,
          data,
          onSuccess: (service) => {
            const { serviceDetails } = service as BroadbandServiceDetails;
            if ((serviceDetails.personalDetailsStep as StepDetails).completed) {
              BackHelper.saveCount(-1);
              props.history.push(
                routes.broadbandService.termsAndConditions(propertyId, serviceSummary),
              );
            } else {
              BackHelper.saveCount(-1);
              props.history.push(routes.broadbandService.personalDetails.showDetails(propertyId));
            }
          },
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractScreen);

export const LABELS = {
  planDetails: 'Plan details',
  personalDetails: 'Personal Details',
  documents: 'Documents',

  infoCardSubHeading: 'Sorted Services is a registered trading name of Energy Locals',
  inclusiveOfGST: 'All prices are inclusive of GST unless stated.',
  directDebitServiceAgreement: 'Direct debit service agreement',
  planSummary: 'Plan Summary',
};
export const debitServiceAgrementDocument = {
  DocumentName: LABELS.directDebitServiceAgreement,
  DocumentId: 0,
  UserId: 0,
  docLibPath: 'https://sortedservices.com/s/Direct-Debit-Request-Service-Agreement.pdf',
};

export enum PlanSummaryStep {
  ADDRESS_COMPLETION,
  PLAN_SELECTION,
  PERSONAL_DETAIL_CHECK,
  ACCEPT_TERMS_AND_CONDITION,
  PAYMENT_CHECK,
}

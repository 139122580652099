import * as React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { AddressListStyles, Styles } from './AppUpdateStyle';
import searchLogo from '../../../assets/search.svg';
import { isNil } from 'lodash';
import { AppUpdateLabels } from './AppUpdateConstants';
import { color } from '../../../theme/Theme';
import FooterComponent from '../../footer/FooterComponent';
import { FC, useEffect } from 'react';
import { sendMessageToApp, ValidMessages } from '../../../helper/MessageHelper';
import { updateApp } from '../../../Constants';

interface PropsFormParent {
  handleSubmit: () => void;
  buttonText?: string;
}
type OwnProps = AddressListStyles & PropsFormParent;

const AppUpdate: FC<OwnProps> = ({ handleSubmit, buttonText, classes }) => {
  useEffect(() => {
    sendMessageToApp(ValidMessages.intercomTagging, updateApp);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.body} style={{ alignItems: 'center', alignContent: 'center' }}>
        <img className={classes.headIcon} src={searchLogo} />
        <Typography className={classes.heading} variant="h5">
          {AppUpdateLabels.title}
        </Typography>
        <Typography
          className={classes.paragraph}
          variant="body1"
          style={{ color: color.primary, fontFamily: 'GT Walsheim' }}
        >
          {AppUpdateLabels.subTitle1}
        </Typography>
        <Typography
          className={classes.paragraph}
          variant="body2"
          style={{
            color: color.primary,
            fontFamily: 'GT Walsheim',
            textAlign: 'center',
          }}
        >
          {AppUpdateLabels.subTitle2}
        </Typography>
        <FooterComponent
          buttonText={isNil(buttonText) ? 'Back' : buttonText}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default withStyles(Styles)(AppUpdate);

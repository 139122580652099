import { routes } from '../../Routes';
import { ServiceType } from '../../containers/services/ServiceConstants';
import { Params } from '../../store/state/HeaderState';
import { match } from 'react-router';

export const headerBlockUrls = [
  // '/onboarding/rent',
  '/setup/rent',
  '/setup/electricity',
  '/setup/broadband',
  routes.wallet.paymentOptions(ServiceType.Wallet),
  routes.wallet.bankDetails(ServiceType.Wallet),
  routes.wallet.cardDetails(ServiceType.Wallet),
  '/onboarding/propertyOnboard/loading',
];

export const noBackArrow = [
  '/onboarding/rent',
  routes.checkout.select,
  routes.checkout.configure,
  routes.checkout.summary,
  routes.checkout.finalize,
  routes.checkout.abandonCart,
  routes.checkout.completeCart,
];

export const showIcon = [routes.checkout.select];

export const noChat = [routes.checkout.abandonCart, routes.checkout.completeCart];

export const cartUrls = [
  routes.checkout.select,
  routes.checkout.configure,
  // routes.checkout.finalize,
];

export const urlCheck = (match: match) => headerBlockUrls.includes(match.url);

export const dashboardUrls = (propertyId: string, params: Params) => {
  return [
    routes.service.new(propertyId, params.serviceAccountId),
    routes.service.new(propertyId),
    routes.broadbandService.new(propertyId, params.serviceAccountId),
    routes.broadbandService.new(propertyId),
    routes.service.termsAndConditions.new(propertyId, ServiceType.ElectricitySummary),
    routes.broadbandService.termsAndConditions(propertyId, ServiceType.BroadbandSummary),
    routes.payment.list.saved(
      params.propertyId,
      ServiceType.EditRent,
      params.paymentRefId,
      params.serviceAccountId,
      params.agencyName,
    ),
    routes.payment.list.saved(
      params.propertyId,
      ServiceType.EditElectricity,
      params.paymentRefId,
      params.serviceAccountId,
      params.agencyName,
    ),
    routes.payment.list.saved(
      params.propertyId,
      ServiceType.EditBroadband,
      params.paymentRefId,
      params.serviceAccountId,
      params.agencyName,
    ),
    `${routes.propertyOnboarding.new()}/0`, // zero denotes the first step pf property onboarding process
  ];
};

export const getBackUrls = (propertyId: string, params: Params) => {
  return [
    routes.service.plan.selection(propertyId),
    routes.service.personalDetails.showDetails(propertyId),
    routes.service.termsAndConditions.new(propertyId),
    routes.payment.list.saved(params.propertyId, ServiceType.Electricity),
    routes.broadbandService.type(propertyId),
    routes.broadbandService.personalDetails.showDetails(propertyId),
    routes.broadbandService.termsAndConditions(propertyId),
    routes.payment.list.saved(params.propertyId, ServiceType.Broadband),
  ];
};

export const blockedCountUrls = (params: Params) => {
  return [
    routes.payment.list.empty(params.propertyId, ServiceType.Electricity),
    routes.payment.list.empty(params.propertyId, ServiceType.Broadband),
    routes.payment.new.bank(params.propertyId, ServiceType.Electricity),
    routes.payment.new.bank(params.propertyId, ServiceType.Broadband),
    routes.payment.new.card(params.propertyId, ServiceType.Electricity),
    routes.payment.new.card(params.propertyId, ServiceType.Broadband),
  ];
};

export const commonServiceUrls = (match: match) => {
  const propertyIdCommon = match.url.split('/')[5];
  const serviceNameCommon = match.url.split('/')[4];
  const providerNameCommon = match.url.split('/')[7];
  const lookupRequiredCommon = match.url.split('/')[9];
  const serviceAccountIdCommon = match.url.split('/')[10];
  const adressConfirmedCommon = match.url.split('/')[8];

  return [
    routes.commonService.address.overview(
      serviceNameCommon,
      propertyIdCommon,
      providerNameCommon,
      adressConfirmedCommon,
      lookupRequiredCommon,
      serviceAccountIdCommon,
    ),
    routes.commonService.address.form(
      serviceNameCommon,
      propertyIdCommon,
      providerNameCommon,
      adressConfirmedCommon,
      lookupRequiredCommon,
      serviceAccountIdCommon,
    ),
    routes.commonService.address.empty(
      match.url.split('/')[5],
      match.url.split('/')[4],
      match.url.split('/')[7],
      match.url.split('/')[8],
      match.url.split('/')[9],
    ),
  ];
};

export const stepRetention = (match: match) => {
  const propertyIdCommon = match.url.split('/')[5];
  const serviceTypeCommon = match.url.split('/')[3];
  const providerNameCommon = match.url.split('/')[6];
  const lookupRequiredCommon = match.url.split('/')[9];
  const serviceAccountIdCommon = match.url.split('/')[10];
  const adressConfirmedCommon = match.url.split('/')[8];

  return [
    routes.commonService.new(
      serviceTypeCommon,
      propertyIdCommon,
      providerNameCommon,
      adressConfirmedCommon,
      lookupRequiredCommon,
      serviceAccountIdCommon,
    ),
  ];
};

export const LABELS = {
  CHECKOUT: {
    SELECT: 'Select plans',
    CUSTOMISE: 'Setup plans',
    CUSTOMISE_ONE: 'Setup plan',
    CUSTOMISE_TRADE_REQUEST: 'Service questions',
    SUMMARY: 'Summary',
    FINALIZE: 'Complete checkout',
  },
};

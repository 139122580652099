import { color } from '../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  dialog: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    overflow: 'hidden',
  },
  popupHeader: {
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    height: '48px',
    marginTop: 10,
  },
  popupTitle: {
    fontFamily: 'GT Walsheim',
    fontSize: '26px',
    color: color.black87,
  },
  popupActionContainer: {
    width: 25,
    height: 25,
  },
  popupAction: {
    width: '100%',
    height: '100%',
    objectFix: 'contain',
    objectPosition: 'center',
  },
  cartScroll: {
    overflowY: 'scroll',
    'scrollbarWidth': 'none',
    'msOverflowStyle': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    paddingBottom: 30,
  },
  cartContainer: {
    padding: 15,
    paddingTop: 0,
    paddingBottom: 0,
  },
  break: {
    margin: '20px 15px',
    height: 2,
    backgroundColor: color.borderColor,
  },
  promoCodeContainer: {
    backgroundColor: color.fadedBlue,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 15px',
  },
  promoTextContainer: {
    marginRight: 'auto',
  },
  promoCode: {
    fontFamily: 'GT Walsheim',
    fontSize: '15px',
    color: color.lightBlue,
    textTransform: 'uppercase',
    marginBottom: 4,
  },
  promoSubtext: {
    fontFamily: 'GT Walsheim',
    fontSize: '13px',
    color: color.lightBlue,
  },
  promoIconContainer: {
    width: 24,
    height: 24,
  },
  promoIcon: {
    width: '100%',
    height: '100%',
    objectFix: 'contain',
    objectPosition: 'center',
  },
  summaryContainer: {
    backgroundColor: color.secondary,
    padding: '15px 15px 15px 15px',
  },
  summaryRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  summaryGreyKey: {
    fontFamily: 'GT Walsheim',
    fontSize: '13px',
    color: color.black38,
  },
  summaryBlackValue: {
    fontFamily: 'GT Walsheim',
    fontSize: '13px',
    color: color.black87,
  },
  summaryBlackKey: {
    fontFamily: 'GT Walsheim',
    fontSize: '15px',
    color: color.black87,
  },
  summaryBlueValue: {
    fontFamily: 'GT Walsheim',
    fontSize: '18px',
    color: color.lightBlue,
  },
  buttonContainer: {
    marginTop: 5,
    padding: 15,
    marginBottom: 15,
  },
  primaryButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 40,
    color: color.light,
    textAlign: 'center',
    fontFamily: 'GT Walsheim',
    fontSize: 15,
    border: `${color.buttonBackgroundSecondary} 2px solid`,
    backgroundColor: color.buttonBackgroundSecondary,
    borderRadius: 6,
  },
  needSupport: {
    margin: 15,
    paddingBottom: 10,
    borderBottom: `${color.borderColor} 2px solid`,
    fontFamily: 'GT Walsheim',
    fontSize: '20px',
    color: color.black87,
  },
  supportRow: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 20,
  },
  supportIcon: {
    width: 22,
    height: 22,
    marginRight: 10,
  },
  supportText: {
    fontFamily: 'GT Walsheim',
    fontSize: '14px',
    color: color.black87,
  },
  emptyCartContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  emptyCartIconContainer: {
    width: 60,
    height: 60,
    marginBottom: 15,
    marginTop: 30,
    flexShrink: 0,
  },
  emptyCartIcon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  emptyCartTitle: {
    marginBottom: 15,
    fontFamily: 'GT Walsheim',
    fontSize: '16px',
    color: color.black87,
  },
  emptyCartText: {
    fontFamily: 'GT Walsheim Light',
    fontSize: '15px',
    color: color.black87,
    marginBottom: 15,
  },
  selectFrequency: {
    height: 30,
    width: 130,
  },
  showPricingContainer: {
    display: 'flex',
    width: 'calc(100% - 30px)',
    paddingLeft: 15,
    paddingRight: 15,
    justifyContent: 'space-between',
    alignContent: 'center',
    marginTop: 15,
    borderRadius: 8,
  },
  estimateLabel: {
    fontFamily: 'GT Walsheim',
    fontSize: '16px',
    color: color.black38,
  },
  content: {
    overflow: 'hidden',
    transition: 'all 0.3s ease-out',
    height: '100%',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: color.secondary,
    padding: 15,
    paddingTop: 0,
  },
  actionContainerHide: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#f5f5f5',
    padding: 15,
  },
  actionIconContainer: {
    marginLeft: 'auto',
    width: 16,
    height: 16,
    transition: 'all 0.3s ease-out',
  },
  actionIcon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    opacity: 0.8,
  },
  actionTitle: {
    marginRight: 'auto',
    fontFamily: 'GT Walsheim',
    fontSize: '15px',
    color: color.black38,
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
  disclamerText: {
    marginLeft: 15,
    marginTop: 15,
    fontFamily: 'GT Walsheim',
    fontSize: '13px',
    color: color.black38,
  },
});

export interface CartStyles extends WithStyles<typeof styles> {}

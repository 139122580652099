import * as React from 'react';
import { withStyles, Button, Typography } from '@material-ui/core';
import { ButtonStyles, styles } from './ButtonStyles';

interface PropsFromParent {
  label: string;
  onClick: () => void;
}

type OwnProps = PropsFromParent & ButtonStyles;

function renderButton(props: OwnProps) {
  const { classes, label } = props;
  return (
    <div className={classes.root}>
      <Button variant="outlined" onClick={props.onClick} className={classes.buttonStyle}>
        <Typography variant="caption" className={classes.buttonTextStyle}>
          {label}
        </Typography>
      </Button>
    </div>
  );
}

const buttonComponent = withStyles(styles)(renderButton);
export default buttonComponent;

import { color } from '../../../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  sectionContainer: {
    marginTop: 15,
    backgroundColor: color.light,
    padding: 15,
    borderRadius: 8,
  },
  sectionTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
  },
  sectionTitle: {
    marginRight: 'auto',
    fontFamily: 'GT Walsheim',
    fontSize: '20px',
    color: color.black87,
  },
  sectionIconContainer: {
    width: 24,
    height: 24,
  },
  sectionIcon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    opacity: 0.3,
  },
  applicationProfileCard: {
    borderRadius: 6,
    border: `${color.borderColor} 2px solid`,
    marginTop: 15,
    overflow: 'hidden',
    marginBottom: 15,
  },
  profileImage: {
    height: '45px',
    width: '45px',
    borderRadius: 500,
    flexShrink: 0,
  },
  gravatar: {
    height: '45px',
    width: '45px',
    marginLeft: -8,
    zIndex: 2,
    borderRadius: 5000,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.light,
    textTransform: 'uppercase',
    fontSize: '16px',
    border: `${color.light} 1px solid`,
    flexShrink: 0,
  },
  applicationProfileDetails: {
    marginLeft: '12px',
    marginRight: 'auto',
  },
  applicationProfileName: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: '16px',
    fontWeight: 500,
  },
  applicationProfileContext: {
    fontFamily: 'GT Walsheim',
    marginTop: 4,
    color: color.black38,
    fontSize: '13px',
    fontWeight: 500,
  },
  propertyContainer: {
    display: 'flex',
    backgroundColor: color.light,
    justifyContent: 'space-between',
    padding: '12px',
    alignItems: 'center',
    fontWeight: 600,
    color: color.black87,
    borderRadius: 8,
    border: `${color.borderColor} 2px solid`,
    marginBottom: '12px',
  },
  messageContainer: {
    marginRight: 'auto',
  },
  messageTitle: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: '16px',
    fontWeight: 500,
  },
  messageSubtitle: {
    fontFamily: 'GT Walsheim',
    marginTop: 4,
    color: color.black38,
    fontSize: '13px',
    fontWeight: 500,
  },
  agencyLogo: {
    width: '45px',
    height: '45px',
    borderRadius: 5000,
    backgroundColor: color.light,
    marginLeft: 'auto',
    marginRight: 12,
  },
  houseContainer: {
    width: '45px',
    height: '45px',
  },
  house: {
    width: '100%',
    height: '100%',
    objectPosition: 'center',
    objectFit: 'contain',
  },
  cardHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: 15,
    marginRight: 15,
    borderBottom: `${color.borderColor} 2px solid`,
  },
  cardTitle: {
    marginRight: 'auto',
    fontFamily: 'GT Walsheim',
    fontSize: '16px',
    color: color.black87,
  },
  cardIconContainer: {
    marginLeft: 'auto',
    width: 20,
    height: 20,
  },
  cardIcon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  content: {
    overflow: 'hidden',
    transition: 'all 0.3s ease-out',
    height: '100%',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 15px',
    backgroundColor: color.secondary,
    borderTop: `${color.borderColor} 2px solid`,
  },
  actionIconContainer: {
    marginLeft: 'auto',
    width: 16,
    height: 16,
    transition: 'all 0.3s ease-out',
  },
  actionIcon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  actionTitle: {
    marginRight: 'auto',
    fontFamily: 'GT Walsheim',
    fontSize: '14px',
    color: color.black87,
  },
  borderBottom: {
    borderBottom: `${color.borderColor} 2px solid`,
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
  planHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 15px',
  },
});

export interface AccountSectionStyles extends WithStyles<typeof styles> {}

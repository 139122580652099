/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { get, isNil } from 'lodash';
import React, { FC, useState } from 'react';
import { withStyles } from '@material-ui/core';
import {
  FeatureDetails,
  Options,
  PlanDetailObject,
  PlanOption,
  SupplierOption,
  SupplierOptions,
} from '../../../services/commonServices/CommonPlanSelectionInterface';
import ReactHTMLParser from 'react-html-parser';
import {
  AvailableServicesResponse,
  InitialConcessionCardValues,
  ServiceDetailsData,
} from '../../../../models/checkout/Checkout';
import { getSelectedOptions, setSupplierAnswers } from './SelectOptionsUtils';
import SupplierOptionsComponent from './components/optionsType/SupplierOptions/SupplierOptions';
import { styles, SelectOptionsStyles } from './SelectOptionsStyles';
import PlanOptionsComponent from './components/optionsType/PlanOptions/PlanOptions';
import { sortSupplierOptions } from '../../../../helper/CheckoutHelper';

interface SelectOptionsProps extends SelectOptionsStyles {
  selectedSupplierOptions: SupplierOptions[];
  planOptions: PlanOption[] | undefined;
  stopSaleList: string[];
  cardData?: ServiceDetailsData | null;
  setPlanSummary: (planSummary: Map<string, FeatureDetails> | {}) => void;
  setConcessionCard: (concessionCard: InitialConcessionCardValues | null) => void;
  setStopSaleList: (value: string[]) => void;
  cartItems: AvailableServicesResponse[];
  extendedDataPlan: PlanDetailObject;
  setProductDetails: (data: Options) => void;
  publicHolidays?: string[];
}

const SelectOptions: FC<SelectOptionsProps> = ({
  classes,
  selectedSupplierOptions,
  planOptions,
  stopSaleList,
  cardData,
  setPlanSummary,
  setConcessionCard,
  setStopSaleList,
  cartItems,
  extendedDataPlan,
  setProductDetails,
  publicHolidays,
}) => {
  const { updatedSupplierOptions, supplierAnswer } = setSupplierAnswers(
    selectedSupplierOptions,
    planOptions,
    cardData,
    cartItems,
  );
  const [supplierOptions, setSupplierOptions] = useState(updatedSupplierOptions);
  const [supplierSelectionAns, setSupplierSelectionAns] = useState(supplierAnswer);

  const handlePlanOptionChange = (
    supplierOption: PlanOption,
    selection: Options,
    value?: string,
  ) => {
    if (supplierOption.Type === 'Product') {
      setProductDetails(selection);
    }
    const updatedSupplierSelectionAns = {
      ...supplierSelectionAns,
    };
    updatedSupplierSelectionAns[supplierOption.Group] = {
      ...updatedSupplierSelectionAns[supplierOption.Group],
      value,
      valueId: selection.ProductId || value,
      cost: get(selection, 'SinglePrice', null),
    };
    setSupplierSelectionAns(updatedSupplierSelectionAns);
    setPlanSummary(updatedSupplierSelectionAns);
  };

  const handleSingleOptionChange = (supplierOption: SupplierOptions, value?: string | Date) => {
    const updatedSupplierSelectionAns = {
      ...supplierSelectionAns,
    };
    updatedSupplierSelectionAns[supplierOption.Group] = {
      ...updatedSupplierSelectionAns[supplierOption.Group],
      value,
      valueId: supplierOption.ProductId || value,
      cost: get(supplierOption, 'SinglePrice', null),
    };

    setSupplierSelectionAns(updatedSupplierSelectionAns);
    setPlanSummary(updatedSupplierSelectionAns);
  };

  const handleMultipleOptionsChange = (
    supplierOption: SupplierOptions,
    selection: SupplierOption,
    value?: string | Date,
  ) => {
    const updatedSupplierOptions = supplierOptions.map((option: SupplierOptions) => {
      if (option.Group === supplierOption.Group) {
        return {
          ...option,
          Options: option.Options.map((innerOptions: SupplierOption) => {
            const checkConcession = () => {
              if (option.Group === 'Concession') {
                if (selection.Option && selection.Option.Group === 'ConcessionDetailsAgreement') {
                  setConcessionCard({ name: '', cardNumber: '', cardType: '', expiry: '' });
                } else {
                  setConcessionCard(null);
                }
              }
            };

            return getSelectedOptions(innerOptions, value as string, undefined, checkConcession);
          }),
        };
      } else {
        return option;
      }
    });
    setSupplierOptions(updatedSupplierOptions);
    const updatedSupplierSelectionAns = {
      ...supplierSelectionAns,
    };
    updatedSupplierSelectionAns[supplierOption.Group] = {
      ...updatedSupplierSelectionAns[supplierOption.Group],
      label: selection.PlanName || updatedSupplierSelectionAns[supplierOption.Group].label,
      value: get(selection, 'Label', ''),
      valueId: selection.ProductId || get(selection, 'Label', ''),
      cost: get(selection, 'SinglePrice', null),
    };
    if (get(selection, 'StopSaleProcess', false)) {
      const updatedStopSaleList = [...stopSaleList];
      updatedStopSaleList.push(get(supplierOption, 'Group', ''));
      setStopSaleList(updatedStopSaleList);
    } else {
      const newList = stopSaleList.filter((value) => value !== get(supplierOption, 'Group', ''));
      setStopSaleList(newList);
    }
    if (!isNil(selection.Option) && !isNil(selection.Option.Group)) {
      const newSupplierAnswer: FeatureDetails =
        supplierOption.Group === selection.Option.Group
          ? {
              ...updatedSupplierSelectionAns[supplierOption.Group],
              label:
                selection.Option.PlanName ||
                updatedSupplierSelectionAns[supplierOption.Group].label,
              value: get(selection, 'Label', ''),
              valueId: selection.ProductId || get(selection, 'Label', ''),
              isMandatory: get(selection.Option, 'ComponentIsMandatory', false),
              parentGroup: supplierOption.Group,
              componentType: get(selection.Option, 'ComponentType', ''),
              cost: get(selection.Option, 'SinglePrice', null),
            }
          : {
              type: 'answers',
              label:
                selection.PlanName || `${ReactHTMLParser(get(selection.Option, 'Heading', ''))}`,
              labelId: get(selection.Option, 'Group', ''),
              value: '',
              valueId: selection.ProductId || get(selection, 'Label', ''),
              isMandatory: get(selection.Option, 'ComponentIsMandatory', false),
              parentGroup: supplierOption.Group,
              componentType: get(selection.Option, 'ComponentType', ''),
              cost: get(selection.Option, 'SinglePrice', null),
            };
      setSupplierSelectionAns({
        ...updatedSupplierSelectionAns,
        [selection.Option.Group]: newSupplierAnswer,
      });

      setPlanSummary({
        ...updatedSupplierSelectionAns,
        [selection.Option.Group]: newSupplierAnswer,
      });
    } else {
      Object.keys({
        ...supplierSelectionAns,
      }).forEach((key: string) => {
        if (
          selection.Option &&
          supplierSelectionAns[key].parentGroup === supplierOption.Group &&
          selection.Option.Group !== supplierOption.Group
        ) {
          delete updatedSupplierSelectionAns[key];
        }
      });
      setPlanSummary({ ...updatedSupplierSelectionAns });
      setSupplierSelectionAns({ ...updatedSupplierSelectionAns });
    }
  };

  return (
    <div className={classes.selectOptionsContainer}>
      {extendedDataPlan &&
        extendedDataPlan.Options &&
        extendedDataPlan.Options.length > 0 &&
        extendedDataPlan.Options.map((option) =>
          option && option.Options && option.Options.length > 1 ? (
            <PlanOptionsComponent
              option={option}
              handlePlanOptionChange={handlePlanOptionChange}
              supplierSelectionAns={supplierSelectionAns}
            />
          ) : null,
        )}

      {sortSupplierOptions(supplierOptions).map((option: SupplierOptions) => (
        <>
          <SupplierOptionsComponent
            option={option}
            setConcessionCard={setConcessionCard}
            handleMultipleOptionsChange={handleMultipleOptionsChange}
            handleSingleOptionChange={handleSingleOptionChange}
            supplierSelectionAns={supplierSelectionAns}
            publicHolidays={publicHolidays}
          />
          {option.Options.map(
            (innerOption: SupplierOption) =>
              innerOption.selected && (
                <SupplierOptionsComponent
                  option={innerOption.Option}
                  setConcessionCard={setConcessionCard}
                  handleMultipleOptionsChange={handleMultipleOptionsChange}
                  handleSingleOptionChange={handleSingleOptionChange}
                  supplierSelectionAns={supplierSelectionAns}
                  publicHolidays={publicHolidays}
                />
              ),
          )}
        </>
      ))}
    </div>
  );
};

export default withStyles(styles)(SelectOptions);

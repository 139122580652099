import * as React from 'react';
import ConcessionScreenUI, {
  FooterData,
} from '../../../../component/services/plan/electricity/ConcessionScreen'; // Screens,
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/RootReducers';
import { Dispatch, AnyAction } from 'redux';
import { PlanState } from '../../../../store/state/PlanState';
import { PlanActions } from '../../../../store/actions/PlanActions';
import { getExtraInfoLabel } from './PlanConstants';
import { routes } from '../../../../Routes';
import { RouteComponentProps } from 'react-router-dom';
import { PlanDetailsSubmit } from '../../../../models/plan/electricity/postPlanDetails/PlanDetailsSubmit.data';
import { createPlanObject } from '../../../../component/services/plan/electricity/Constants';
import { BackHelper } from '../../../../helper/BackHelper';
import { PlanDetails } from '../../../../models/plan/electricity/getPlanDetails/PlanDetails.data';

interface PropsFromState {
  plan: PlanState;
}

interface OwnState {
  openDrawer: boolean;
  openModal: boolean;
}
interface PropsFromDispatch {
  toggleConcession: (choice: boolean) => void;
  savePlan: (data: PlanDetailsSubmit) => void;
}

interface PropsFromRoute {
  history?: any;
}
type OwnProps = PropsFromDispatch &
  PropsFromState &
  PropsFromRoute &
  RouteComponentProps<{ propertyId: string }>;

class ConcessionScreen extends React.Component<OwnProps, OwnState> {
  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      openDrawer: false,
      openModal: false,
    };
  }
  public render() {
    return (
      <ConcessionScreenUI
        {...this.props}
        handleNext={this.handleNext}
        footerData={this.filterFooterData()}
        planDetails={this.props.plan.planDetails as PlanDetails}
        openDrawer={this.state.openDrawer}
        openModal={this.state.openModal}
        handleDrawer={this.handleDrawer}
        handleModal={this.handleModal}
      />
    );
  }

  private filterFooterData = (): FooterData => {
    const { plan } = this.props.plan;
    return {
      amount: plan.productDetails.monthlyCost,
      extraInfo: getExtraInfoLabel(''),
    };
  };

  private handleNext = (choice: string) => {
    const { plan, lifeSupport } = this.props.plan;
    const { propertyId } = this.props.match.params;
    this.props.toggleConcession(choice === 'yes');
    if (choice === 'yes') {
      this.setState({ openDrawer: true });
    } else {
      const data: PlanDetailsSubmit = createPlanObject(plan, lifeSupport);
      BackHelper.saveCount(-1);
      this.props.savePlan(data);
      this.props.history.push(routes.service.connectionDate.saved(propertyId));
    }
  };

  private handleDrawer = (openDrawer: boolean) => {
    const { propertyId } = this.props.match.params;
    if (openDrawer) {
      this.setState({ openDrawer: false });
      BackHelper.saveCount(-1);
      this.props.history.push(routes.service.concessionCard.base(propertyId));
    } else {
      this.setState({ openModal: true });
    }
  };

  private handleModal = (openModal: boolean) => {
    const { plan, lifeSupport } = this.props.plan;
    const { propertyId } = this.props.match.params;
    if (openModal) {
      this.setState({ openModal: false, openDrawer: false });
      const data: PlanDetailsSubmit = createPlanObject(plan, lifeSupport);
      BackHelper.saveCount(-1);
      this.props.savePlan(data);
      this.props.history.push(routes.service.connectionDate.saved(propertyId));
    } else {
      this.setState({ openModal });
    }
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    plan: state.plan,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): PropsFromDispatch {
  return {
    toggleConcession: (choice: boolean) => {
      dispatch(PlanActions.toggleConcession(choice));
    },
    savePlan: (data: PlanDetailsSubmit) => dispatch(PlanActions.saveChoosenPlan(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConcessionScreen);

import React, { Component } from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { Styles, OverViewStyles } from './OverviewStyles';

interface PropsFromPage {
  readonly serviceSummary: any;
  readonly serviceName: string;
}

type OwnProps = OverViewStyles & PropsFromPage;

class InfoPage extends Component<OwnProps> {
  public render() {
    const { classes, serviceSummary } = this.props;
    return (
      <div>
        {serviceSummary && (
          <React.Fragment>
            <div>
              {/* <div className={classes.vignette}></div> */}
              <img
                src={serviceSummary.backgroundImageUrl}
                className={classes.serviceHeaderImg}
                alt="Service Header Image"
              />
            </div>
            <div className={classes.infoHeader}>
              <Typography className={classes.serviceInfoName}>
                {serviceSummary.serviceType}
              </Typography>
              <img
                src={serviceSummary.logoUrl}
                className={classes.serviceInfoLogo}
                alt="Service Logo"
              />
            </div>
            <div className={classes.infoContainer}>
              {serviceSummary.sections &&
                serviceSummary.sections.length &&
                serviceSummary.sections.map((section: any) => (
                  <>
                    {section.data && section.data.length && (
                      <div>
                        <Typography className={classes.sectionTitle}>
                          {section.sectionTitle || ''}
                        </Typography>
                        <div className={classes.section}>
                          {section.data.map((item: any, index: any) =>
                            this.renderItems(item.key, item.value, section.sectionTitle, index),
                          )}
                        </div>
                      </div>
                    )}
                  </>
                ))}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

  public renderItems = (key: string, value: string, sectionTitle: string, index: number) => {
    const { classes, serviceSummary } = this.props;
    return (
      <div>
        {sectionTitle === 'Supplier details' && index === 0 && (
          <div className={classes.providerName}>
            <img
              src={serviceSummary.logoUrl}
              className={classes.serviceProviderLogo}
              alt="Service Logo"
            />
            <Typography className={classes.value}>{serviceSummary.serviceProvider}</Typography>
          </div>
        )}
        {key && value && (
          <div className={classes.item}>
            <Typography className={classes.key}>{key}</Typography>
            <Typography className={classes.value}>{value}</Typography>
          </div>
        )}
      </div>
    );
  };
}

export default withStyles(Styles)(InfoPage);

import { createStyles, WithStyles } from '@material-ui/core';

export const Styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 16px',
    height: '100vh',
  },
  cardNameInputStyle: {
    flexGrow: 1,
    marginRight: 10,
    marginTop: 10,
  },
  titleStyle: {
    marginTop: 65,
    marginBottom: '4vh',
  },
  cardNumberInputStyle: {
    flexGrow: 1,
    marginRight: 10,
    marginTop: 10,
  },
  expiryInputStyle: {
    flexGrow: 1,
    marginRight: 10,
    marginTop: 10,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  inputs: {
    width: '100%',
    marginTop: 36,
  },
  secureIcon: {
    padding: '0 12px 16px 0',
  },
});

export interface CardFormStyle extends WithStyles<typeof Styles> {}

import ApiServiceBase from '../ApiServiceBase';
import { ServiceType } from '../ServiceType.data';
import { QueryPath } from '../QueryPath.data';
import { ApiServiceMockBase } from './ApiServiceBaseMock';
import { MultipartFileData } from '../MultipartFormData.data';
// import { GetPaymentTypesMock } from "../__mocks__/GetPaymentTypesMock";

const mocks: { [serviceType: string]: ApiServiceMockBase } = {};

// Create mocks of base services
// mocks[ServiceType.PaymentTypes] = new GetPaymentTypesMock();

// This is just a multiplexer, calls the appropriate base service mock based on ServiceType.
// ApiServiceMock cannot inherit ApiService, because that's mocked and that would create an infinite loop, that's why we need ApiServiceBase.
// We return T instead of Promise<T> otherwise we can't wait for success in out tests.
export default class ApiServiceMock extends ApiServiceBase {
  public constructor(serviceType: ServiceType) {
    super(serviceType);
  }

  public get<T = void>(path: QueryPath): T {
    return this.getService().get<T>(path);
  }

  public post<T = void>(path: QueryPath, body: any): T {
    return this.getService().post<T>(path, body);
  }
  public postOtherService<T = void>(path: string, body: any, token: string): T {
    return this.getService().postOtherService<T>(path, body, token);
  }

  public put<T = void>(path: QueryPath, body: any): T {
    return this.getService().put<T>(path, body);
  }

  public delete<T = void>(path: QueryPath): T {
    return this.getService().delete<T>(path);
  }

  public postMultipartFileData<T = void>(path: QueryPath, data: MultipartFileData[]): T {
    return this.getService().postMultipartFileData<T>(path, data);
  }

  public getPdf<T = void>(path: QueryPath): T {
    return this.getService().get<T>(path);
  }

  private getService(): ApiServiceMockBase {
    const service = mocks[this.serviceType];

    if (!service) {
      throw Error(
        `ApiServiceMock.getService: Service mock is not found @serviceType: ${this.serviceType}, register in services/__mocks__/ApiService.ts`,
      );
    }

    return service;
  }
}

import * as React from 'react';
import { History } from 'history';
import { AnyAction } from 'redux';
import { PlanCommonSummaryActions } from '../../../store/actions/CommonSummaryActions';
import { ApplicationState } from '../../../store/RootReducers';
import { connect } from 'react-redux';
import { sendMessageToApp, ValidMessages } from '../../../helper/MessageHelper';
import { FC, useEffect, useRef } from 'react';
import {
  OpenWebPage,
  ReferalIframePlan,
  ThirdPartyApiData,
  Referal,
} from '../commonService/kannopiService/KanopiInterface';

const windowHeight = screen.availHeight;
interface Props {
  iframeData?: ThirdPartyApiData;
  setService?: OpenWebPage | {};
  email: string;
  quotes: ReferalIframePlan;
  history: History;
  getIframeUrl: (data: Referal, url: string, token: string) => void;
  setIframeData: (body: string) => void;
}

const OpenNewWebPage: FC<Props> = ({
  quotes,
  setIframeData,
  getIframeUrl,
  setService,
  email,
  iframeData,
}) => {
  const webViewUrl = useRef<string>('');
  useEffect(() => {
    switch (quotes.Plans[0].SignupConfig.Signup.Type) {
      case 'Url':
        webViewUrl.current = '';
        setIframeData(quotes.Plans[0].SignupConfig.Signup.Url);
        break;
      case 'CallbackUrl':
        webViewUrl.current = quotes.Plans[0].SignupConfig.Signup.BaseUrl as string;
        getIframeUrl(
          {
            customer_number: (setService as OpenWebPage).customerNumber,
            service_number: (setService as OpenWebPage).serviceNumber,
            email: email,
            address: (setService as OpenWebPage).serviceAddress,
          },
          webViewUrl.current + quotes.Plans[0].SignupConfig.Signup.Url,
          quotes.Plans[0].SignupConfig.Signup.Token as string,
        );
        break;
      default:
        break;
    }
  }, []);
  if (iframeData) {
    sendMessageToApp(ValidMessages.OpenWebView, webViewUrl.current + iframeData.getquote_url);
  }
  return <div style={{ flex: 1, height: windowHeight + 55 }} />;
};

const mapDispatchToProps = (dispatch: React.Dispatch<AnyAction>) => {
  return {
    setIframeData: (body: string) => dispatch(PlanCommonSummaryActions.setIframeData({ body })),
    getIframeUrl: (data: Referal, url: string, token: string) =>
      dispatch(
        PlanCommonSummaryActions.getIframeUrlStart({
          data,
          url,
          token,
        }),
      ),
  };
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    setService: state.planSummary.setServiceId,
    quotes: state.planSummary.serviceSummary.serviceProvider[0],
    iframeData: state.commonSummary.iframeData,
    email: state.planSummary.serviceSummary.userResponse.email,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenNewWebPage);

import { Card, Typography, withStyles, Button } from '@material-ui/core';
import * as React from 'react';
import { Styles, TermsAndConditionsStyle } from './TermsAndConditionsStyle';
import { History } from 'history';
import { get, isUndefined, isNil, isNull } from 'lodash';
import {
  CommonSummary,
  ProductDetails,
  PlanDetail,
  ServiceDetail,
  ConcessionCard,
  PreAgreementSummary,
  FeatureDetails,
  QuotesV3,
  Link,
  AcceptanceChecks,
  SummaryDetail,
} from '../../../containers/services/commonServices/CommonPlanSelectionInterface';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import tickWhite from '../../../assets/tick-white.svg';
import { PlanCommonSummaryActions } from '../../../store/actions/CommonSummaryActions';
import { ApplicationState } from '../../../store/RootReducers';
import uuid from 'uuid';
import { routes } from '../../../Routes';
import { sendMessageToApp, ValidMessages } from '../../../helper/MessageHelper';
import {
  ElectricityServiceDetails,
  StepDetails,
} from '../../../services/electricityService/getElectricityService/GetElectricityService.data';
interface PropsFromParent {
  preAgreementSummary: PreAgreementSummary;
  linkTitle: string | null | undefined;
  planSummary: FeatureDetails[];
  concessionCard: ConcessionCard | null;
  attributes: any;
  propertyId: string;
  serviceName: string;
  paymentCardRequiresd: boolean;
  summary: SummaryDetail[];
  providerName: string;
  productDetail: ProductDetails | null;
  serviceabilityDetails: any;
}

interface PropsFromDispatch {
  postCommonData: (serviceDetails: CommonSummary, onSuccess: () => void) => void;
  //TODO: Resolve any
  setFinalScreenData: (finalData: any) => void;
}

interface PropsFromState {
  serviceAccountId: string;
  //TODO: Resolve any
  state: any;
  quotes?: QuotesV3;
  //TODO: Resolve any
  planDetails: any;
  personalCompleted: boolean;
}

export interface InitialValues {
  email: string;
  title: string;
  firstName: string;
  lastName: string;
  mobile: string;
  dob: string;
  idType: string;
  idNumber: string;
}
interface PropsFromRoute {
  history: History;
}

export interface TermsState {
  accepted: boolean;
  acceptedChecks: number[];
}
type OwnProps = TermsAndConditionsStyle &
  PropsFromParent &
  PropsFromRoute &
  PropsFromDispatch &
  PropsFromState;

class TermsAndConditionMock extends React.Component<OwnProps, TermsState> {
  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      accepted: false,
      acceptedChecks: [],
    };
  }

  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.titleStyle}>{this.renderTerms()}</div>
      </div>
    );
  }

  public renderTerms = () => {
    const { preAgreementSummary, classes } = this.props;
    return (
      <div className={classes.bottomDrawer}>
        {!isNil(preAgreementSummary) ? (
          <div>
            <Typography className={classes.bottomDrawerTitle}>
              {get(preAgreementSummary, 'Heading', '')}
            </Typography>
            <Typography className={classes.modalSubtitle}>
              {get(preAgreementSummary, 'Title', '')}
            </Typography>
            <div
              className={classes.terms}
              dangerouslySetInnerHTML={{
                __html: get(preAgreementSummary, 'Body', ''),
              }}
            />
            {!!preAgreementSummary.LinkItems.length && (
              <Card elevation={2} className={classes.detailCard}>
                {(this.props.quotes as QuotesV3).Response.Plans[0].UiModelAttributes.Links.Value ? (
                  <div className={classes.switchContainer}>
                    <Typography className={classes.linkHeader} variant="h6">
                      {
                        (this.props.quotes as QuotesV3).Response.Plans[0].UiModelAttributes.Links
                          .Value
                      }
                    </Typography>
                  </div>
                ) : undefined}
                <div className={classes.seperator} />
                {!isUndefined(preAgreementSummary) && !!preAgreementSummary.LinkItems.length && (
                  <div className={classes.section}>
                    {preAgreementSummary.LinkItems.map((link: Link) => (
                      <>
                        <div className={classes.section}>
                          <div
                            key={`rate-${link.Label}`}
                            onClick={() => {
                              sendMessageToApp(ValidMessages.OpenUrl, get(link, 'Link', ''));
                            }}
                          >
                            <Typography
                              variant="h1"
                              className={`${classes.keyText} ${classes.blueText}`}
                            >
                              {get(link, 'Label', '')}
                            </Typography>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                )}
              </Card>
            )}
          </div>
        ) : undefined}
        <div className={classes.drawerFooter}>
          <div className={classes.acceptanceChecks}>
            {(
              this.props.quotes as QuotesV3
            ).Response.Plans[0].PreAgreementSummary.AcceptanceChecks.map(
              (item: AcceptanceChecks, index: number) => (
                <div className={classes.flexRow}>
                  <Typography
                    className={classes.termsSection}
                    onClick={async () => {
                      if (this.state.acceptedChecks.includes(index)) {
                        const array = [...this.state.acceptedChecks];
                        const arrayIndex = array.indexOf(index);
                        if (arrayIndex !== -1) {
                          array.splice(arrayIndex, 1);
                          await this.setState({
                            acceptedChecks: array,
                            accepted: false,
                          });
                        }
                      } else {
                        await this.setState({
                          acceptedChecks: [...this.state.acceptedChecks, index],
                          accepted: false,
                        });
                      }
                      if (
                        (this.props.quotes as QuotesV3).Response.Plans[0].PreAgreementSummary
                          .AcceptanceChecks.length === this.state.acceptedChecks.length
                      ) {
                        await this.setState({ accepted: true });
                      } else {
                        await this.setState({ accepted: false });
                      }
                    }}
                  >
                    {!this.state.acceptedChecks.includes(index) ? (
                      <div className={classes.acceptTermsdefault} />
                    ) : (
                      <div className={classes.acceptedTerms}>
                        <img src={tickWhite} className={classes.tickImage} />
                      </div>
                    )}
                  </Typography>
                  <Typography variant="body1">
                    {get(item, 'ComponentText', 'I accept the above terms')}
                  </Typography>
                </div>
              ),
            )}
          </div>

          <Button
            className={classes.button}
            fullWidth
            onClick={() => {
              const {
                propertyId,
                serviceName,
                providerName,
                quotes,
                serviceAccountId,
                productDetail,
                planSummary,
                concessionCard,
                paymentCardRequiresd,
                attributes,
                summary,
                serviceabilityDetails,
              } = this.props;
              const productDetails: ProductDetails | null = productDetail;
              const bodySummary: FeatureDetails = {
                type: 'postSummaryDetails',
                label: 'Body',
                labelId: 'Body',
                value: (quotes as QuotesV3).Response.Plans[0].PostAgreementSummary.Body.toString(),
                valueId: 'postSummary',
              };
              const titleSummary: FeatureDetails = {
                type: 'postSummaryDetails',
                label: 'title',
                labelId: 'title',
                value: (quotes as QuotesV3).Response.Plans[0].PostAgreementSummary.Title.toString(),
                valueId: 'postSummary',
              };
              if (
                planSummary.filter((item: FeatureDetails) => item.value === bodySummary.value)
                  .length === 0
              ) {
                planSummary.push(bodySummary);
              }
              if (
                planSummary.filter((item: FeatureDetails) => item.value === titleSummary.value)
                  .length === 0
              ) {
                planSummary.push(titleSummary);
              }
              const planDetail: PlanDetail = {
                featureDetails: planSummary,
                summaryDetails: summary,
                attributes,
                productDetails,
                concessionCard,
                isAcceptTermAndCondition: true,
                transactionId: uuid(),
                skipPaymentStep: !paymentCardRequiresd,
                serviceabilityDetails,
                paymentType: null,
              };
              const serviceDetails: ServiceDetail = {
                planDetails: planDetail,
                partialCompletion: false,
              };
              const commonSummary: CommonSummary = {
                serviceDetails,
                step: 'PLAN_SELECTION',
                propertyId,
                serviceType: serviceName,
                providerId: providerName,
                serviceAccountId,
              };
              this.props.postCommonData(commonSummary, this.signUpSuccess);
            }}
            disabled={!this.state.accepted}
            color={!this.state.accepted ? 'secondary' : 'inherit'}
            variant="contained"
          >
            <Typography
              className={!this.state.accepted ? classes.disabledButtonText : classes.buttonText}
            >
              {
                (this.props.quotes as QuotesV3).Response.Plans[0].UiModelAttributes.TermsAccept
                  .Value
              }
            </Typography>
          </Button>
        </div>
      </div>
    );
  };

  public signUpSuccess = () => {
    const { paymentCardRequiresd, history, propertyId, serviceName, quotes } = this.props;
    if (paymentCardRequiresd) {
      history.push(routes.payment.list.commonServiceSaved(propertyId, serviceName));
    } else if (isNull((quotes as QuotesV3).Response.Plans[0].PostAgreementSummary)) {
      sendMessageToApp(ValidMessages.Dashboard);
    } else {
      if (this.props.planDetails) {
        const planSummaryList: FeatureDetails[] = [];
        this.props.planDetails.featureDetails
          //TODO: Resolve any
          .filter((feature: any) => feature.type === 'postSummaryDetails')
          //TODO: Resolve any
          .map((filteredFeature: any) => planSummaryList.push(filteredFeature));
        this.props.setFinalScreenData(planSummaryList);
      }
      history.push(routes.planSuccessSignUp.new(propertyId));
    }
  };
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>): PropsFromDispatch {
  return {
    postCommonData: (serviceDetails: CommonSummary, onSuccess: () => void) =>
      dispatch(
        PlanCommonSummaryActions.postCommonSummaryData({
          data: serviceDetails,
          onSuccess,
        }),
      ),
    //TODO: Resolve any
    setFinalScreenData: (finalData: any) => {
      dispatch(
        PlanCommonSummaryActions.setFinalScreenData({
          data: finalData,
        }),
      );
    },
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    quotes: state.commonSummary.quotes,
    state,
    planDetails:
      state.commonSummary.commonServicePlans && state.commonSummary.commonServicePlans.steps
        ? state.commonSummary.commonServicePlans.steps.planDetails
        : (state.electricityService.service as ElectricityServiceDetails).serviceDetails
            .planDetails,
    serviceAccountId: state.commonSummary.commonServicePlans.id
      ? state.commonSummary.commonServicePlans.id
      : state.commonSummary.commonServicePlans.serviceAccountResponse.id,
    personalCompleted: state.commonSummary.savePropertyAddressStart
      ? state.commonSummary.commonServicePlans.steps
        ? state.commonSummary.commonServicePlans.steps.personalDetailsStep.completed
        : (
            (state.electricityService.service as ElectricityServiceDetails).serviceDetails
              .personalDetailsStep as StepDetails
          ).completed
      : (
          (state.electricityService.service as ElectricityServiceDetails).serviceDetails
            .personalDetailsStep as StepDetails
        ).completed,
  };
}

export default withStyles(Styles)(
  connect(mapStateToProps, mapDispatchToProps)(TermsAndConditionMock),
);

import { parseISO } from 'date-fns/esm';

export const parseInitialDate = (date: string) => {
  if (date === null) {
    return null;
  } else {
    const splits = date.split('/');
    // Based on current date format in API response. (dd/MM/yyyy)
    return parseISO(`${splits[2]}-${splits[1]}-${splits[0]}`);
  }
};

export const parseDate = (date: string) => {
  if (date === null) {
    return null;
  } else {
    const dateSplit = date.split('T');
    return dateSplit[0];
  }
};

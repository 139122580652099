import * as React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import PlanButton from '../PlanButtons';
import PlanFooter from '../PlanFooter';
import {
  LABELS_DATA,
  DEFAULT_SELECTED_BUTTON_INDEX,
  FOOTER_VALUE_NA,
  getDataExtraInfo,
} from './Constants';
import { Styles, PlanScreenStyles } from '../electricity/Styles';
import { routes } from '../../../../Routes';
import { BroadbandPlanDetails } from '../../../../models/plan/broadband/getPlanDetails/BroadbandPlan.data';
import PlanHelper from '../../../../helper/PlanHelper';
import { BroadbandPlanSubmit } from '../../../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import { BroadbandTypes, DownLoadType } from '../../../../containers/services/ServiceConstants';
import { BackHelper } from '../../../../helper/BackHelper';

interface PropsFromRoute {
  readonly history?: any;
}

interface PropsFromParent {
  readonly planDetails: BroadbandPlanDetails;
  readonly handleNext: (choice: string) => void;
}

interface PlanState {
  choice: number;
}

type OwnProps = PlanScreenStyles & PropsFromRoute & PropsFromParent;

class DataScreen extends React.Component<OwnProps, PlanState> {
  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      choice: DEFAULT_SELECTED_BUTTON_INDEX,
    };
  }

  public render() {
    const { classes } = this.props;
    const { choice } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <div className={classes.top}>
            <Typography className={classes.title} variant="h5">
              {LABELS_DATA.title}
            </Typography>
            <PlanButton
              options={this.props.planDetails.labels}
              label={LABELS_DATA.buttonsHeading}
              info={LABELS_DATA.planInfo[choice]}
              onChange={this.handleOptionChange}
              selected={choice}
            />
          </div>
          <PlanFooter
            amount={this.filterFooterValue()}
            extraInfo={this.getExtraInfo()}
            onButtonClick={this.handleFooterClick}
          />
        </div>
      </div>
    );
  }

  private getExtraInfo = () => {
    const { planDetails } = this.props;
    const downloadType =
      planDetails.broadbandType === BroadbandTypes.adsl ? '' : DownLoadType.Basic;
    const productName = PlanHelper.getProductName(
      planDetails.productNames,
      planDetails.labels[this.state.choice],
      downloadType,
      false,
    );
    const productInfo: BroadbandPlanSubmit = PlanHelper.getProductDetails(
      planDetails.plan,
      productName,
    );
    let setupFee = null;
    if (planDetails.broadbandType === BroadbandTypes.adsl) {
      setupFee = getDataExtraInfo(`${productInfo && productInfo.setupFeeContractYes}`);
    } else if (planDetails.telcoService.nbnDevelopmentFee && productInfo.connectionFeeAmount) {
      setupFee = getDataExtraInfo(productInfo.connectionFeeAmount.toString());
    }
    return setupFee;
  };

  private filterFooterValue = (): string => {
    const downloadType =
      this.props.planDetails.broadbandType === BroadbandTypes.adsl ? '' : DownLoadType.Basic;
    const productName = PlanHelper.getProductName(
      this.props.planDetails.productNames,
      this.props.planDetails.labels[this.state.choice],
      downloadType,
      false,
    );
    const productInfo: BroadbandPlanSubmit = PlanHelper.getProductDetails(
      this.props.planDetails.plan,
      productName,
    );
    return productInfo && productInfo.productDetails
      ? `${productInfo.productDetails.monthlyCost}`
      : FOOTER_VALUE_NA;
  };

  private handleOptionChange = (choice: number) => {
    this.setState({ choice });
  };

  private handleFooterClick = () => {
    BackHelper.saveCount(-1);
    this.props.handleNext(this.props.planDetails.labels[this.state.choice]);
    this.props.planDetails.broadbandType === 'adsl'
      ? this.props.history.push(
          routes.broadbandService.plan.modemSelection((this.props as any).match.params.propertyId),
        )
      : this.props.history.push(
          routes.broadbandService.plan.downloadSpeedSelection(
            (this.props as any).match.params.propertyId,
          ),
        );
    this.props.handleNext(this.props.planDetails.labels[this.state.choice]);
  };
}

export default withStyles(Styles)(DataScreen);

import * as React from 'react';
import CardDetailsComponent from '../../../component/wallet/cardDetails/CardDetails';
import { GetPaymentMethodsResponse } from '../../../services/payment/getPaymentMethods/GetPaymentMethods.data';
import { ApplicationState } from '../../../store/RootReducers';
import { AnyAction } from 'redux';
import { WalletActions } from '../../../store/actions/WalletActions';
import { connect } from 'react-redux';
import { History } from 'history';

interface PropsFromState {
  selectedPayment: GetPaymentMethodsResponse;
  services: string[];
}

interface PropsFromDispatch {
  deletePaymentMethod: (paymentRefId: string, onSuccess: (response: string[]) => void) => void;
  saveHeaderLabel: (label: string) => void;
  showSuccessMessage: () => void;
  hideSuccessMessage: () => void;
}

interface PropsFromRoute {
  history: History;
}

type OwnProps = PropsFromState & PropsFromDispatch & PropsFromRoute;

interface OwnState {
  showConfirmationModal: boolean;
  showWarningModal: boolean;
}

class CardDetails extends React.Component<OwnProps, OwnState> {
  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      showConfirmationModal: false,
      showWarningModal: false,
    };
  }
  public componentDidMount() {
    const { selectedPayment } = this.props;
    this.props.saveHeaderLabel(selectedPayment.cardType);
    this.props.hideSuccessMessage();
  }
  public render() {
    return (
      <CardDetailsComponent
        payment={this.props.selectedPayment}
        handlePaymentDelete={this.handlePaymentDelete}
        showConfirmationModal={this.state.showConfirmationModal}
        showWarningModal={this.state.showWarningModal}
        handleConfirmationModal={this.handleConfirmationModal}
        hanldeWarningModal={this.hanldeWarningModal}
        services={this.props.services}
      />
    );
  }
  public handlePaymentDelete = () => {
    const { selectedPayment } = this.props;
    this.props.deletePaymentMethod(selectedPayment.refId, this.handlDeletePaymentResponse);
    this.setState({ showConfirmationModal: false });
  };

  public handleConfirmationModal = (showConfirmationModal: boolean) => {
    this.setState({ showConfirmationModal });
  };

  public hanldeWarningModal = (showWarningModal: boolean) => {
    this.setState({ showWarningModal });
  };

  public handlDeletePaymentResponse = (response: string[]) => {
    if (response.length === 0) {
      this.props.history.goBack();
      this.props.showSuccessMessage();
    } else {
      this.hanldeWarningModal(true);
    }
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    selectedPayment: state.wallet.selectedPayment as GetPaymentMethodsResponse,
    services: state.wallet.deletePaymentResponse as string[],
  };
}

function mapDispatchFromProps(dispatch: React.Dispatch<AnyAction>): PropsFromDispatch {
  return {
    deletePaymentMethod: (
      paymentRefId: string,
      handleDeletePayment: (response: string[]) => void,
    ) => {
      dispatch(
        WalletActions.deletePaymentStart({
          paymentRefId,
          onSuccess: (response: string[]) => handleDeletePayment(response),
        }),
      );
    },
    showSuccessMessage: () => dispatch(WalletActions.showSuccessMessage()),
    hideSuccessMessage: () => dispatch(WalletActions.hideSuccessMessage()),
    saveHeaderLabel: (label: string) => {
      dispatch(WalletActions.saveHeaderLabel(label));
    },
  };
}

export default connect(mapStateToProps, mapDispatchFromProps)(CardDetails);

import * as React from 'react';
import AddressFormComponent, {
  initialValuesState,
} from '../../../../component/services/electricityService/addressForm/AddressForm';
import { ApplicationState } from '../../../../store/RootReducers';
import { connect } from 'react-redux';
import {
  ElectricityServiceDetails,
  PropertyDetails,
} from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { ElectricityServiceActions } from '../../../../store/actions/ElectricityServiceActions';
import { Dispatch, AnyAction } from 'redux';
import { routes } from '../../../../Routes';
import { RouteComponentProps } from 'react-router-dom';
import {
  AddressLookup,
  ElectricityAddressLookup,
} from '../../../../services/electricityService/getElectricityAddressLookup/GetElectricityAddressLookup.data';
import { PropertyObject } from '../../../../objectFactory/PropertyObjectFactory';
import { BackHelper } from '../../../../helper/BackHelper';

interface PropsFromDispatch {
  saveProperty: (property: PropertyDetails) => void;
  getAddressList: (
    property: AddressLookup,
    onSuccess: (response: ElectricityAddressLookup) => void,
    onError: () => void,
  ) => void;
  getAddress: (propertyId: string) => void;
}
interface PropsFromState {
  service: ElectricityServiceDetails;
  property: PropertyDetails;
}
interface PropsFromRoute {
  history?: any;
}
type OwnProps = PropsFromState &
  PropsFromDispatch &
  PropsFromRoute &
  RouteComponentProps<{ propertyId: string }>;
class AddressForm extends React.Component<OwnProps> {
  public componentDidMount() {
    const { propertyId } = this.props.match.params;
    this.props.getAddress(propertyId);
  }
  public render() {
    const { property } = this.props;
    if (property) {
      return <AddressFormComponent propertyDetails={property} handleSubmit={this.handleSubmit} />;
    } else {
      return <div />;
    }
  }
  public handleSubmit = (property: initialValuesState) => {
    const propertyDetails = this.props.property;

    const address: PropertyDetails = {
      ...property,
      address: propertyDetails.address,
      id: propertyDetails.id,
      photos: propertyDetails.photos,
      type: propertyDetails.type,
      nmi: '',
      nmiMeterNumbers: null,
      keyIdentifier: '',
      meterSerialNumber: null,
      addressLine1: null,
      addressLine2: null,
      addressLine3: null,
    };
    const propertyObject = new PropertyObject({});
    const addressRequest = propertyObject.createAddresLookupPropertyObject(property, 'Electricity');
    this.props.getAddressList(addressRequest, this.onSuccess, this.onError);
    this.props.saveProperty(address);
  };
  private onSuccess = (response: ElectricityAddressLookup) => {
    const { propertyId } = this.props.match.params;
    if (!response.ElectricityService.NmiFullConfirmationAddress) {
      BackHelper.saveCount(-1);
      this.props.history.push(routes.service.address.emptyList(propertyId));
    } else {
      BackHelper.saveCount(-1);
      this.props.history.push(routes.service.address.list(propertyId));
    }
  };
  private onError = () => {
    BackHelper.saveCount(-1);
    this.props.history.push(routes.service.address.emptyList(this.props.match.params.propertyId));
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): PropsFromDispatch {
  return {
    saveProperty: (property: PropertyDetails) => {
      dispatch(ElectricityServiceActions.saveProperty(property));
    },
    getAddressList: (
      data: AddressLookup,
      onSuccess: (response: ElectricityAddressLookup) => void,
      onError: () => void,
    ) => {
      dispatch(
        ElectricityServiceActions.getElectricityServiceAddressLookupStart({
          data,
          onSuccess: (response: ElectricityAddressLookup) => onSuccess(response),
          onError,
        }),
      );
    },
    getAddress: (propertyId: string) =>
      dispatch(ElectricityServiceActions.getAddressStart(propertyId)),
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    service: state.electricityService.service as ElectricityServiceDetails,
    property: state.electricityService.serviceAddress as PropertyDetails,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddressForm);

import React, { Component } from 'react';
import { Typography, withStyles, Grid, List, ListItem, ListItemText } from '@material-ui/core';
import Footer from '../../footer/FooterComponent';
import { SummaryStyles, Styles } from './SummaryStyles';
import * as Labels from './SummaryConstants';
import { get } from 'lodash';
import { ServiceSummaryResponse } from '../../../containers/services/otherServices/OtherServicesSummary.data';
import LOGO_HOME from '../../../assets/images/homescreen/home.png';
import ReactHTMLParser from 'react-html-parser';

interface PropsFromPage {
  readonly onBtnClick: () => void;
  readonly serviceName: string;
  readonly serviceSummary: ServiceSummaryResponse;
}

type OwnProps = SummaryStyles & PropsFromPage;

class Summary extends Component<OwnProps> {
  public render() {
    const { classes, onBtnClick, serviceSummary } = this.props;
    const address = get(serviceSummary, 'property.address', undefined);
    const photo = get(serviceSummary, 'property.photos[0].link', undefined);
    const name = get(serviceSummary, 'userResponse.name', undefined);
    const surname = get(serviceSummary, 'userResponse.surname', undefined);
    const email = get(serviceSummary, 'userResponse.email', undefined);
    const phone = get(serviceSummary, 'userResponse.phone', undefined);
    const footer = get(serviceSummary, 'serviceProvider[0].DescriptionFooter', undefined);
    const title = get(serviceSummary, 'serviceProvider[0].SupplierTitle', undefined);
    return (
      <Grid className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h5">{title}</Typography>
        </div>
        <div className={classes.imageBox}>
          <img src={photo || LOGO_HOME} className={classes.imageStyle} alt="Property Image" />
        </div>
        <div className={classes.card}>
          <div className={classes.cardBody}>
            <List>
              <ListItem>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="overline" className={classes.primaryTextStyle}>
                      {Labels.ADDRESS}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="subtitle2" className={classes.secondaryTextStyle}>
                      {address || ''}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="overline" className={classes.primaryTextStyle}>
                      {Labels.ACCOUNT_HOLDER}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="subtitle2" className={classes.secondaryTextStyle}>
                      {name && surname ? `${name} ${surname}` : ''}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="overline" className={classes.primaryTextStyle}>
                      {Labels.PHONE}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="subtitle2" className={classes.secondaryTextStyle}>
                      {phone || ''}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="overline" className={classes.primaryTextStyle}>
                      {Labels.EMAIL}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="subtitle2" className={classes.secondaryTextStyle}>
                      {email || ''}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </div>
        </div>
        {footer && <div className={classes.infoText}>{ReactHTMLParser(footer)}</div>}
        <Footer buttonText={Labels.BUTTON_TEXT} onClick={onBtnClick} />
      </Grid>
    );
  }
}

export default withStyles(Styles)(Summary);

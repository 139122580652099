import React, { FC, useState, useEffect } from 'react';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducers';
import { connect } from 'react-redux';
import {
  Services,
  Serviceability,
  GetRentServiceResponse,
  PaymentProvider,
} from '../../services/rentService/getRentService/GetRentServiceResponse.data';
import { RentServiceActions } from '../../store/actions/RentServiceActions';
import { PostRentServiceRequest } from '../../services/rentService/postRentService/PostRentService.data';
import { PostPaymentTokenResponse } from '../../services/payment/postPaymentToken/PostPyamentToken.data';
import {
  createRentServiceRequestWithPaymentObject,
  createRentServiceRequestObject,
} from '../property/ConfirmationConstants';
import { PostServiceabilityRequest } from '../../services/rentService/postServiceability/PostServiceability.data';
import { routes } from '../../Routes';
import { withRouter, RouteComponentProps } from 'react-router';
import {
  Typography,
  Card,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  withStyles,
  MuiThemeProvider,
} from '@material-ui/core';
import NavBar from '../../component/navBar/NavBar';
import {
  createPostServiceabilityRequestObject,
  LABELS,
  message,
} from './LeaseServiceabilityConstants';
import waterIcon from '../../assets/water.svg';
import rentIcon from '../../assets/home.svg';
import callbackIcon from '../../assets/callback.svg';
import electricityIcon from '../../assets/electricity.svg';
import gasIcon from '../../assets/gas.svg';
import insuranceIcon from '../../assets/insurance.svg';
import internetIcon from '../../assets/internet.svg';
import doneIcon from '../../assets/tick.svg';
import { styles, LeaseServiceabilityStyles, theme } from './LeaseServiceabilityStyles';
import { findIndex } from 'lodash';
import { useGetLeaseData } from '../../helper/RentServiceHelper';

interface LeaseServiceabilityProps extends LeaseServiceabilityStyles, RouteComponentProps<{}> {
  paymentResponse: PostPaymentTokenResponse;
  postRentServices: (data: PostRentServiceRequest, onSuccess: () => void) => void;
  postServiceability: (
    data: PostServiceabilityRequest[],
    leaseId: number,
    onSuccess: () => void,
  ) => void;
}

const LeaseServiceability: FC<LeaseServiceabilityProps> = ({
  classes,
  history,
  paymentResponse,
  postRentServices,
  postServiceability,
}) => {
  const [selectedOptInService, setSelectedOptInService] = useState<boolean[]>([]);
  const [leaseServices, setLeaseServices] = useState<Services[]>([]);
  const lease = useGetLeaseData();
  const { serviceability, leaseId, property, paymentDetails, leaseRefId } = lease!;

  useEffect(() => {
    const selectedOptInService: boolean[] = [];
    serviceability.map((service: Services) => {
      if (service.serviceability === Serviceability.OPT_YES) {
        selectedOptInService.push(true);
      } else if (service.serviceability === Serviceability.OPT_NO) {
        selectedOptInService.push(false);
      }
    });
    setSelectedOptInService(selectedOptInService);
    setLeaseServices(serviceability);
  }, []);

  const handleOnSelect = (service: any, index: number) => {
    const result = selectedOptInService.map((service, idx) => {
      if (index === idx) {
        return !service;
      }
      return service;
    });
    setSelectedOptInService(result);
  };

  const handleServiceabilitySuccess = () => {
    history.push(routes.setup.base('rent', `${property!.id}`));
  };

  const handlePostRentServiceSuccess = () => {
    const requestData = createPostServiceabilityRequestObject(leaseServices, selectedOptInService);
    postServiceability(requestData, leaseId, handleServiceabilitySuccess);
  };

  const handleServiceabilitySubmit = () => {
    const { paymentProvider } = paymentDetails!;
    if (paymentProvider && paymentProvider === PaymentProvider.SORTED) {
      const requestData = createRentServiceRequestWithPaymentObject(
        leaseRefId,
        paymentResponse.refId,
      );
      postRentServices(requestData, handlePostRentServiceSuccess);
    } else {
      const requestData = createRentServiceRequestObject(leaseRefId);
      postRentServices(requestData, handlePostRentServiceSuccess);
    }
  };

  const checkMandatoryService = () => {
    return (
      findIndex(serviceability, function (service: Services) {
        return service.serviceability === Serviceability.OPT_MANDATORY;
      }) >= 0
    );
  };

  const checkOptInService = () => {
    return (
      findIndex(serviceability, function (service: Services) {
        return (
          service.serviceability === Serviceability.OPT_NO ||
          service.serviceability === Serviceability.OPT_YES
        );
      }) >= 0
    );
  };

  const getServiceIcon = (serviceType: string) => {
    switch (serviceType.replace(' ', '')) {
      case 'UtilityCallback':
        return <img src={callbackIcon} className={classes.imageStyle} />;
      case 'Water':
        return <img src={waterIcon} className={classes.imageStyle} />;
      case 'Broadband':
        return <img src={internetIcon} className={classes.imageStyle} />;
      case 'Voice':
        return <div className={classes.defaultIconStyle} />;
      case 'Electricity':
        return <img src={electricityIcon} className={classes.imageStyle} />;
      case 'Gas':
        return <img src={gasIcon} className={classes.imageStyle} />;
      case 'Solar':
        return <div className={classes.defaultIconStyle} />;
      case 'Insurance':
        return <img src={insuranceIcon} className={classes.imageStyle} />;
      case 'Health Check':
        return <div className={classes.defaultIconStyle} />;
      case 'Rent':
        return <img src={rentIcon} className={classes.imageStyle} />;
      case 'Mobile':
        return <div className={classes.defaultIconStyle} />;
      default:
        return <div className={classes.defaultIconStyle} />;
    }
  };

  return (
    <div className={classes.root}>
      <NavBar subTitle={LABELS.NAV_TITLE} subText={LABELS.NAV_SUBTITLE} />
      <div className={classes.body}>
        {checkMandatoryService() && (
          <div>
            <Typography variant="subtitle1" className={classes.subTitleStyle}>
              {message.tenancyServiceTitle}
            </Typography>
            <div>
              <div className={classes.card}>
                {serviceability
                  .filter(
                    (service: Services) => service.serviceability === Serviceability.OPT_MANDATORY,
                  )
                  .map((service: Services) => {
                    return (
                      <List>
                        <ListItem
                          classes={{
                            root: classes.listItemStyle,
                            dense: classes.listItemStyle,
                            default: classes.listItemStyle,
                          }}
                        >
                          <ListItemAvatar>
                            {service.serviceProvider.icon ? (
                              <img
                                src={service.serviceProvider.icon}
                                className={classes.imageStyle}
                              />
                            ) : (
                              getServiceIcon(service.serviceType)
                            )}
                          </ListItemAvatar>
                          <ListItemText
                            primary={service.serviceType}
                            secondary={service.serviceProvider.name}
                          />
                          <ListItemSecondaryAction>
                            <img src={doneIcon} className={classes.doneIconStyle} />
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    );
                  })}
              </div>
              <Typography variant="body2" className={classes.subTextStyle}>
                {message.tenancyServiceSubTitle}
              </Typography>
            </div>
          </div>
        )}
        {checkOptInService() && (
          <div>
            <Typography variant="subtitle1" className={classes.subTitleStyle}>
              {message.optInServiceTitle}
            </Typography>
            <div className={classes.optInSectionStyle}>
              <div className={classes.card}>
                {serviceability
                  .filter(
                    (service: Services) =>
                      service.serviceability === Serviceability.OPT_YES ||
                      service.serviceability === Serviceability.OPT_NO,
                  )
                  .map((service: Services, index: number) => {
                    return (
                      <List>
                        <ListItem
                          classes={{
                            root: classes.listItemStyle,
                            dense: classes.listItemStyle,
                            default: classes.listItemStyle,
                          }}
                        >
                          <ListItemAvatar>
                            {service.serviceProvider.icon ? (
                              <img
                                src={service.serviceProvider.icon}
                                className={classes.imageStyle}
                              />
                            ) : (
                              getServiceIcon(service.serviceType)
                            )}
                          </ListItemAvatar>
                          <ListItemText
                            primary={service.serviceType}
                            secondary={service.serviceProvider.name}
                          />
                          <ListItemSecondaryAction>
                            {service.serviceability === Serviceability.OPT_YES ? (
                              <img src={doneIcon} className={classes.doneIconStyle} />
                            ) : (
                              <MuiThemeProvider theme={theme}>
                                <Switch
                                  checked={selectedOptInService[index]}
                                  onChange={() => handleOnSelect(service, index)}
                                  color="primary"
                                />
                              </MuiThemeProvider>
                            )}
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    );
                  })}
              </div>
              <Typography variant="body2" className={classes.subTextStyle}>
                {message.optInServiceSubTitle}
              </Typography>
            </div>
          </div>
        )}
        <div className={classes.footerButtonsContainer}>
          <div className={classes.footerButton} onClick={() => handleServiceabilitySubmit()}>
            Confirm
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => {
  return {
    paymentResponse: state.payment.postPaymentTokenReponse as PostPaymentTokenResponse,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  postRentServices: (data: PostRentServiceRequest, onSuccess: () => void) =>
    dispatch(RentServiceActions.postRentServiceStart({ data, onSuccess })),
  postServiceability: (data: PostServiceabilityRequest[], leaseId: number, onSuccess: () => void) =>
    dispatch(RentServiceActions.postServiceabilityStart({ data, leaseId, onSuccess })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(LeaseServiceability)));

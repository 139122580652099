import { WithStyles, createStyles } from '@material-ui/core';
import { color } from '../../../theme/Theme';

export const styles = createStyles({
  root: {
    display: 'flex',
    flex: 1,
  },
  labelStyle: {
    fontFamily: 'GT Walsheim',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.18,
    letterSpacing: 0.4,
    color: color.primary,
    marginLeft: 16,
  },
  inputStyle: {
    fontFamily: 'GT Walsheim light',
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: color.dark,
    opacity: 1,
    paddingLeft: 16,
    borderBottomWidth: 1,
    borderBottomColor: color.dark,
    borderBottomStyle: 'solid',
  },
  logoStyle: {
    width: 24,
    height: 24,
  },
  textStyle: {
    color: color.dark,
  },
  textFormStyle: {
    marginTop: -8,
  },
});

export interface InputStyles extends WithStyles<typeof styles> {}

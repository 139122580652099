import { SetOnboardingPropertyDetails } from '../../models/property/Property.data';
import { PropertyAction, PropertyActionTypes } from '../actions/PropertyOnboardingAction';
import {
  PropertyOnboardingState,
  ONBOARDING_INITIAL_STATE,
} from '../state/PropertyOnboardingState';

export const initialState: PropertyOnboardingState = {
  propertyOnboardingState: ONBOARDING_INITIAL_STATE,
  error: null,
  loading: false,
};

export const propertyOnboardingReducer = (state = initialState, action: PropertyAction) => {
  switch (action.type) {
    case PropertyActionTypes.POST_PROPERTY_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case PropertyActionTypes.POST_PROPERTY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PropertyActionTypes.POST_PROPERTY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case PropertyActionTypes.SET_PROPERTY_DETAILS:
      const { field, value } = action.data as SetOnboardingPropertyDetails;
      return {
        propertyOnboardingState: {
          ...state.propertyOnboardingState,
          [field]: value,
        },
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};

import {
  Services,
  Serviceability,
} from '../../services/rentService/getRentService/GetRentServiceResponse.data';
import { PostServiceabilityRequest } from '../../services/rentService/postServiceability/PostServiceability.data';

export const LABELS = {
  NAV_TITLE: 'Your services',
  NAV_SUBTITLE: 'Opt in to services for your home setup.',
};

export const createPostServiceabilityRequestObject = (
  serviceability: Services[],
  selectedOptInService: boolean[],
): PostServiceabilityRequest[] => {
  const services: PostServiceabilityRequest[] = [];
  serviceability
    .filter((service: Services) => service.serviceability === Serviceability.OPT_MANDATORY)
    .map((service: Services) => {
      services.push({
        serviceType: service.serviceType,
        serviceability: service.serviceability,
        providerId: service.serviceProvider.id,
        providerName: service.serviceProvider.name,
      });
    });
  serviceability
    .filter(
      (service: Services) =>
        service.serviceability === Serviceability.OPT_YES ||
        service.serviceability === Serviceability.OPT_NO,
    )
    .map((service: Services, index: number) => {
      if (selectedOptInService[index]) {
        services.push({
          serviceType: service.serviceType,
          serviceability: Serviceability.OPT_YES,
          providerId: service.serviceProvider.id,
          providerName: service.serviceProvider.name,
        });
      } else {
        services.push({
          serviceType: service.serviceType,
          serviceability: Serviceability.OPT_NO,
          providerId: service.serviceProvider.id,
          providerName: service.serviceProvider.name,
        });
      }
    });
  return services;
};

export const message = {
  title: 'Your Services',
  tenancyServiceTitle: 'Tenancy services',
  optInServiceTitle: 'Opt-in services',
  tenancyServiceSubTitle:
    'The above services have been setup at the property on your behalf, with the Primary Tenant named as the account holder.',
  optInServiceSubTitle:
    'Opting in to the above services provides consent for your details to be shared with the provider so they can contact you regarding the service.',
  buttonText: 'Confirm',
  noServiceText: 'No services found',
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
  withStyles,
} from '@material-ui/core';
import React, { FC } from 'react';
import { styles, ConcessionCardFormStyles } from './ConcessionCardFormStyles';
import { Formik } from 'formik';
import * as Labels from '../../../../../../../component/services/commonService/PlanSelectionUtil';
import calendarIcon from '../../../../../../../assets/outlined.svg';
import cardIcon from '../../../../../../../assets/credit-card-24-px.svg';
import ConcessionCardDetail from '../../../../../../../component/services/commonService/concessionCard/ConcessionCard';
import { InitialConcessionCardValues } from '../../../../../../../models/checkout/Checkout';

interface ConcessionCardFormProps extends ConcessionCardFormStyles {
  setConcessionCard: (concessionCard: InitialConcessionCardValues | null) => void;
}

const ConcessionCardForm: FC<ConcessionCardFormProps> = ({ classes, setConcessionCard }) => {
  const initialValues = {
    name: '',
    expiry: '',
    cardNumber: '',
    cardType: '',
  };
  return (
    <div className={classes.cardStyle} style={{ marginLeft: 16, marginRight: 16 }}>
      <Typography variant="h6">Add card details</Typography>
      <div className={classes.seperator} />
      <Formik
        initialValues={initialValues}
        // tslint:disable-next-line:no-empty
        onSubmit={(values) => () => {}}
        validationSchema={Labels.Validation}
        render={({ values, setFieldValue, handleSubmit, isValid }) => (
          <div className={classes.form}>
            <ConcessionCardDetail
              incrementCount={() => {}}
              setValue={(value: string) => {
                setFieldValue('cardType', value);
                setConcessionCard({
                  ...values,
                  cardType: value,
                });
              }}
            />
            <FormControl className={classes.inputs} error={false}>
              <InputLabel>Card Holder Name</InputLabel>
              <Input
                name="name"
                onChange={(event) => {
                  setConcessionCard({
                    ...values,
                    name: event.target.value,
                  });
                  setFieldValue('name', event.target.value);
                }}
                value={values.name}
              />
            </FormControl>
            <FormControl className={classes.inputs} error={false}>
              <InputLabel>Card Number</InputLabel>
              <Input
                endAdornment={
                  <InputAdornment position="end">
                    <img src={cardIcon} />
                  </InputAdornment>
                }
                name="cardNumber"
                onChange={(event) => {
                  setConcessionCard({
                    ...values,
                    cardNumber: event.target.value,
                  });
                  setFieldValue('cardNumber', event.target.value);
                }}
                value={values.cardNumber}
              />
            </FormControl>
            <FormControl className={classes.inputs} error={false}>
              <InputLabel>Expiry Date MM/YY</InputLabel>
              <Input
                name="expiry"
                type="text"
                value={values.expiry}
                placeholder="Expiry (MM/YY)"
                // inputComponent={CustomInput(Labels.expiryDatePattern) as any}
                autoFocus={false}
                onChange={(event) => {
                  if (event.target.value.length === 3 && values.expiry.length === 4) {
                    setFieldValue('expiry', event.target.value.slice(0, -1));
                    setConcessionCard({
                      ...values,
                      expiry: event.target.value.slice(0, -1),
                    });
                  } else if (event.target.value.length === 2 && values.expiry.length === 1) {
                    setFieldValue('expiry', `${event.target.value}/`);
                    setConcessionCard({
                      ...values,
                      expiry: `${event.target.value}/`,
                    });
                  } else {
                    setFieldValue('expiry', event.target.value);
                    setConcessionCard({
                      ...values,
                      expiry: event.target.value,
                    });
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <img src={calendarIcon} />
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
        )}
      />
    </div>
  );
};

export default withStyles(styles)(ConcessionCardForm);

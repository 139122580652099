import * as React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import PlanButton from '../PlanButtons';
import PlanFooter from '../PlanFooter';
import { LABELS_MODEM, getDataExtraInfo, DEFAULT_SELECTED_BUTTON_FIRST_INDEX } from './Constants';
import { Styles, PlanScreenStyles } from '../electricity/Styles';
import { routes } from '../../../../Routes';
import { BroadbandPlanDetails } from '../../../../models/plan/broadband/getPlanDetails/BroadbandPlan.data';
import {
  BroadbandPlanSubmit,
  Modem,
} from '../../../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import PlanHelper from '../../../../helper/PlanHelper';
import { BroadbandPlanState } from '../../../../store/state/BroadbandPlanState';
import { BroadbandTypes } from '../../../../containers/services/ServiceConstants';

interface PropsFromRoute {
  readonly history?: any;
}

interface PropsFromParent {
  readonly planDetails: BroadbandPlanDetails;
  readonly footerValue: number;
  readonly handleNext: (choice: string) => void;
  readonly plan: BroadbandPlanState;
}

interface PlanState {
  choice: number;
}

type OwnProps = PlanScreenStyles & PropsFromRoute & PropsFromParent;

class ModemScreen extends React.Component<OwnProps, PlanState> {
  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      choice: DEFAULT_SELECTED_BUTTON_FIRST_INDEX,
    };
  }

  public render() {
    const { classes, footerValue } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <div className={classes.top}>
            <Typography className={classes.title} variant="h5">
              {LABELS_MODEM.title}
            </Typography>
            <PlanButton
              options={this.filterOptions()}
              label={LABELS_MODEM.buttonsHeading}
              onChange={this.handleOptionChange}
              selected={this.state.choice}
            />
          </div>
          <PlanFooter
            amount={`${footerValue}`}
            extraInfo={this.getExtraInfo()}
            onButtonClick={this.handleFooterClick}
          />
        </div>
      </div>
    );
  }

  private filterOptions = (): string[] => {
    return [LABELS_MODEM.modemLabel, this.props.planDetails.modemLabel];
  };

  private getExtraInfo = () => {
    const { plan, planDetails } = this.props;
    const productName = PlanHelper.getProductName(
      planDetails.productNames,
      plan.dataPlan,
      plan.downloadPlan as string,
      false,
    );
    const productInfo: BroadbandPlanSubmit = PlanHelper.getProductDetails(
      planDetails.plan,
      productName,
    );
    const modemInfo = PlanHelper.getModemDetails(planDetails.plan);
    const modemCost = this.state.choice === 0 ? 0 : modemInfo && (modemInfo as Modem).monthlyCost;
    let connectionFeeAmount = 0;
    if (planDetails.broadbandType === BroadbandTypes.adsl) {
      connectionFeeAmount = productInfo && parseInt(productInfo.setupFeeContractYes as string, 10);
    } else if (planDetails.telcoService.nbnDevelopmentFee && productInfo.connectionFeeAmount) {
      connectionFeeAmount = productInfo.connectionFeeAmount;
    }
    const totalAmount = (modemCost as number) + parseInt(`${connectionFeeAmount}`, 10);
    if (connectionFeeAmount || modemCost) {
      return getDataExtraInfo(`${totalAmount}`, true);
    } else {
      return null;
    }
  };

  private handleOptionChange = (choice: number) => {
    this.setState({ choice });
  };

  private handleFooterClick = () => {
    this.props.history.push(
      routes.broadbandService.plan.voiceSelection((this.props as any).match.params.propertyId),
    );
    this.props.handleNext(this.filterOptions()[this.state.choice]);
  };
}

export default withStyles(Styles)(ModemScreen);

import { color } from '../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';
import naxBG from '../../assets/agencyBgs/nax.jpg';
import sortedBG from '../../assets/agencyBgs/sorted.jpg';
import switchPromo from '../../assets/switchPromo.png';
import { AGENCIES, APP } from '../../helper/AppNameHelper';
import { SIZES } from '../../Constants';

export const styles = createStyles({
  backgroundContainer: {
    height: '360px',
    maxHeight: '360px',
    width: '100%',
    position: 'relative',
    zIndex: 1,
    backgroundImage: `url(${
      APP === AGENCIES.NAX ? naxBG : APP === AGENCIES.SWITCH ? switchPromo : sortedBG
    })`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      height: '100%',
      maxHeight: '100%',
    },
  },
  backgroundOverlay: {
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.6)',
    width: '100%',
    height: '100%',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      position: 'relative',
    },
  },
  contentContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  textContent: {
    alignSelf: 'flex-start',
    marginTop: 90,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      marginTop: 60,
      marginBottom: 20,
    },
  },
  title: {
    color: color.light,
    fontFamily: 'GT Walsheim',
    fontSize: 28,
    marginBottom: 20,
    width: 'max-content',
  },
  text: {
    color: color.light,
    fontFamily: 'GT Walsheim',
    fontSize: 16,
    marginBottom: 40,
    maxWidth: 330,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      marginBottom: 20,
    },
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    paddingLeft: 30,
    paddingRight: 30,
    color: color.light,
    width: 'max-content',
    textAlign: 'center',
    fontFamily: 'GT Walsheim',
    fontSize: 15,
    border: `${color.actionButton} 2px solid`,
    backgroundColor: color.actionButton,
    borderRadius: 6,
    cursor: 'pointer',
  },
  imageContainer: {
    position: 'relative',
    height: '80%',
    flexShrink: 0,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      display: 'none',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
});

export interface AgencyBackgroundStyles extends WithStyles<typeof styles> {}

// checking app name from url, if it is not there,using sorted as default.
export const isSorted =
  window.location.href.split('?').length >= 2
    ? window.location.href.split('?')[1].split('=').length >= 2
      ? window.location.href.split('?')[1].split('=')[1] !== 'NAX'
      : true
    : true;

export enum AGENCIES {
  SORTED = 'SORTED',
  NAX = 'NAX',
  TA = 'TA',
  SWITCH = 'SWITCH',
}

export const APP = window.location.href.includes('SORTED')
  ? AGENCIES.SORTED
  : window.location.href.includes('NAX')
  ? AGENCIES.NAX
  : window.location.href.includes('TA-TODO')
  ? AGENCIES.TA
  : window.location.href.includes('SWITCH') || window.location.href.includes('AU_SWIT')
  ? AGENCIES.SWITCH
  : AGENCIES.SORTED;

import { color } from '../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  navContainer: {
    backgroundColor: color.headerColor,
    paddingLeft: 12,
    paddingRight: 12,
    marginTop: 47,
    paddingTop: 12,
    pointerEvents: 'none',
    minHeight: '110px',
  },
  navTitle: {
    color: color.black87,
    marginRight: 'auto',
    marginLeft: 16,
  },
  bottomNavContainer: {
    marginTop: 6,
    paddingBottom: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: color.headerColor,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  bottomTitle: {
    color: color.navTitleColor,
    fontSize: 27,
    fontWeight: 500,
    fontFamily: 'GT Walsheim',
  },
  bottomSubtitle: {
    marginTop: 8,
    color: color.navTitleColor,
    letterSpacing: 0.2,
    fontSize: 14,
    opacity: 0.5,
    fontWeight: 500,
    fontFamily: 'GT Walsheim',
  },
  bottomRightContent: {
    position: 'relative',
  },
});

export interface NavBarStyles extends WithStyles<typeof styles> {}

import { PlanState } from '../../../../store/state/PlanState';
import { PlanDetails } from '../../../../models/plan/electricity/getPlanDetails/PlanDetails.data';

export const POWER_CONNECTION_LABELS = {
  title: 'Power on?',
  buttonGroupHeading: 'Is the power currently on at your property?',
  options: ['yes', 'no'],
  buttonText: 'NEXT',
};

export const createPostPlanObject = (
  plan: PlanState,
  moving: boolean,
  moveInQuote: string,
  transactionId: string,
  connectionFee: number,
) => {
  return {
    serviceDetails: {
      electricityPlanDetails: {
        ...plan.plan,
        connectionFee: moving ? connectionFee : null,
        documents: (plan.planDetails as PlanDetails).documents,
        energyPricing: (plan.planDetails as PlanDetails).energyPricing,
        moveInQuote,
      },
      transactionId,
    },
    step: 'PLAN_SELECTION',
  };
};

export const choiceValue = 'yes';

export const hourValue = 12;

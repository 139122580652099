export const LABELS = {
  PAYMENT: 'Payment',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  SECURE_CONNECTION: 'Secure',
  PLEASE_INPUT_DETAILS: 'Please fill out the following details below',
  PAY: 'Pay',
  PROCESSING: 'Processing',
  TITLE: 'Total to pay today',
  PAID: 'Total paid',
  SUCCESS: 'Payment success',
  CAPTURED: 'Payment method saved',
  SUBTITLE:
    "This service requires an upfront payment that includes your first month's bill plus any extra addons",
};

import { WithStyles, createStyles } from '@material-ui/core';
import { color } from '../../../theme/Theme';

export const styles = createStyles({
  drawerContiner: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    justifyContent: 'space-between',
  },
  titleStyle: {
    fontSize: 23,
    fontFamily: 'GT Walsheim',
  },
  subTitleStyle: {
    fontFamily: 'GT Walsheim Regular',
    marginTop: 24,
    marginRight: 15,
    color: color.dark,
    opacity: 1,
  },
  listHeadingStyle: {
    marginTop: 24,
    marginBottom: 12,
    fontFamily: 'GT Walsheim Regular',
    color: color.dark,
    opacity: 1,
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    marginTop: 52,
  },
  buttonStyle: {
    'display': 'flex',
    'flex': 1,
    'height': 36,
    'background': color.actionButton,
    '&:hover': {
      backgroundColor: color.actionButton,
    },
  },
  buttonText: {
    fontFamily: 'GT Walsheim',
    color: color.light,
  },
  listItemStyle: {
    paddingLeft: 0,
  },
  logoStyle: {
    marginRight: 8,
  },
  listItemText: {
    color: color.dark,
    opacity: 1,
    fontFamily: 'GT Walsheim Regular',
  },
});

export interface WarningDrawerStyles extends WithStyles<typeof styles> {}

import React, { FC, useRef } from 'react';
import {
  Typography,
  Card,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  withStyles,
} from '@material-ui/core';
import { PlanSelectionStyles } from './PlanSelectionStyles';
import {
  SupplierOption,
  SupplierOptions,
} from '../../../containers/services/commonServices/CommonPlanSelectionInterface';
import { color } from '../../../theme/Theme';

interface Props {
  classes: any;
  callBackHandler: (enteredValue: string) => void;
  optionValue: SupplierOptions;
  defaultValue?: string;
}

const checkBoxStyles = () => ({
  root: {
    '&$checked': {
      color: color.lightBlue,
    },
  },
  checked: {},
});

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const CheckBoxComponent: FC<Props & PlanSelectionStyles> = ({
  classes,
  callBackHandler,
  optionValue,
  defaultValue,
}) => {
  const checkBoxValue = useRef<string[]>(defaultValue ? defaultValue.split(', ') : []);
  return (
    <Card elevation={2} className={`${classes.detailCard} ${classes.planCardContainer}`}>
      <Typography variant="h6">{optionValue.Heading}</Typography>
      <div className={classes.seperator} />
      <Typography variant="h1" className={`${classes.headerText2} ${classes.gtwLight}`}>
        {optionValue.Description}
      </Typography>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup>
          {optionValue.ComponentOptions.map((value) => {
            return (
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={
                      checkBoxValue.current.filter((checkedValue) => checkedValue === value.Name)
                        .length > 0
                    }
                    onChange={(event) => {
                      if (
                        checkBoxValue.current.filter((checkedValue) => checkedValue === value.Name)
                          .length === 0
                      ) {
                        const checkedValue = [...checkBoxValue.current, event.target.value];
                        checkBoxValue.current = checkedValue;
                      } else {
                        const checkedValue = checkBoxValue.current.filter(
                          (checkedValue) => checkedValue !== event.target.value,
                        );
                        checkBoxValue.current = checkedValue;
                      }
                      callBackHandler(checkBoxValue.current.join(', '));
                    }}
                    name={value.Name}
                  />
                }
                label={value.Name}
                value={value.Name}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </Card>
  );
};

export default CheckBoxComponent;

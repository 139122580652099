import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';
import { ApplicationState } from '../../../store/RootReducers';
import OverViewScreen from './OverViewStepCard';
import { ElectricityServiceActions } from '../../../store/actions/ElectricityServiceActions';
import { PlanCommonSummaryActions } from '../../../store/actions/CommonSummaryActions';
import {
  ElectricityServiceDetails,
  PropertyDetails,
  ServiceDetails,
  StepDetails,
} from '../../../services/electricityService/getElectricityService/GetElectricityService.data';
import {
  statusCurrent,
  statusDone,
  statusPending,
  commonServiceSteps,
  ServiceType,
} from '../ServiceConstants';
import { routes } from '../../../Routes';
import { History } from 'history';
import { RouteComponentProps } from 'react-router-dom';
import { BackHelper } from '../../../helper/BackHelper';
import { HeaderState, Params } from '../../../store/state/HeaderState';
import { isNil } from 'lodash';
import {
  ConcessionCard,
  FeatureDetails,
  PlanDetailObject,
  ProductDetails,
} from './CommonPlanSelectionInterface';
import { PlanSummaryStep } from '../../../component/commonPlanSummary/PlanSummaryConstants';

interface PropsFromDispatch {
  getElectricityService: (propertyId: number) => void;
  removeServiceId: (propertyId: number) => void;
  setServiceId: (id: number) => void;
  setProviderId: (provider: string) => void;
  getCommonQuotes: (
    serviceType: string,
    postcode: string,
    keyIdentifier: string,
    SupplierId: string,
    onSuccess: () => void,
  ) => void;
  storePlanSummaryData: (
    serviceDetails: FeatureDetails[],
    productDetails: ProductDetails | null,
    concessionCard: ConcessionCard | null,
    paymentCardRequired: boolean,
    benefits: PlanDetailObject,
  ) => void;
  getCommonAddressLookup: (
    propertyId: string,
    serviceName: string,
    providerName: string,
    onSuccess: () => void,
    onError: () => void,
  ) => void;
  clearElectricityService: (serviceAccountId: string, onSuccess: () => void) => void;
  getAddress: (propertyId: string) => void;
}
interface PropsFromState {
  service: ElectricityServiceDetails;
  accessToken: string | boolean | null;
  params: Params;
  savePropertyAddressStart: string;
  //TODO: Resolve any
  state: any;
}

interface PropsFromRoute {
  history: History;
}

type OwnProps = PropsFromDispatch &
  PropsFromState &
  PropsFromRoute &
  RouteComponentProps<{
    serviceSummary: string;
    serviceName: string;
    propertyId: string;
    providerName: string;
    addressConfirmed: string;
    lookupRequired: string;
    serviceAccountId: string;
  }>;

interface OwnState {
  stepCode: number | null;
  status: string[];
}
class OverView extends React.Component<OwnProps, OwnState> {
  private waitForAccessToken = 0;
  private stepIndex = 0;

  public constructor(
    props: //TODO: Resolve any
    any,
  ) {
    super(props);
    this.state = {
      stepCode: null,
      status: [statusCurrent, statusPending, statusPending, statusPending],
    };
  }

  public componentDidMount() {
    const { params, getAddress } = this.props;
    const { serviceAccountId, propertyId, serviceName } = this.props.match.params;
    if (!isNil(this.props.savePropertyAddressStart)) {
      getAddress(propertyId);
      this.getPLanStatus(this.props.savePropertyAddressStart);
    } else if (this.waitForAccessToken === 0 && (serviceAccountId || params.serviceAccountId)) {
      getAddress(propertyId);
      this.getElectricity();
    }
    // In case of solar and cleaning, skip steps screen and redirect to summary screen
    if ((serviceName === ServiceType.Solar || serviceName === ServiceType.Cleaning) && propertyId) {
      getAddress(propertyId);
      this.redirectTo(PlanSummaryStep.PLAN_SELECTION);
    }
  }

  public getPLanStatus = (serviceAccountId: string) => {
    this.props.getElectricityService(parseInt(serviceAccountId, 10));
    this.waitForAccessToken = 1;
  };

  public getElectricity = () => {
    const { params } = this.props;
    const { serviceAccountId } = this.props.match.params;
    const electricityServiceId = serviceAccountId ? serviceAccountId : params.serviceAccountId;
    this.props.getElectricityService(parseInt(electricityServiceId, 10));
    this.waitForAccessToken = 1;
  };

  public componentDidUpdate() {
    const { serviceAccountId, propertyId } = this.props.match.params;
    const { accessToken, params, getAddress } = this.props;

    if (
      accessToken &&
      this.waitForAccessToken === 0 &&
      (serviceAccountId || params.serviceAccountId)
    ) {
      getAddress(propertyId);
      this.getElectricity();
    }

    if (
      accessToken &&
      !isNil(this.props.savePropertyAddressStart) &&
      this.waitForAccessToken === 0
    ) {
      getAddress(propertyId);
      this.getPLanStatus(this.props.savePropertyAddressStart);
    }
  }

  public getStepsStatus = () => {
    const { service } = this.props;
    const serviceDetail = service && service.serviceDetails;
    if (serviceDetail && !(serviceDetail.lookupDetailsStep as StepDetails).completed) {
      return [statusDone, statusCurrent, statusPending, statusPending, statusPending];
    } else if (serviceDetail && !(serviceDetail.planDetailsStep as StepDetails).completed) {
      return [statusDone, statusCurrent, statusPending, statusPending, statusPending];
    } else if (serviceDetail && !(serviceDetail.personalDetailsStep as StepDetails).completed) {
      //pD
      return [statusDone, statusDone, statusCurrent, statusPending, statusPending];
    } else if (
      serviceDetail &&
      serviceDetail.partialCompletion !== null &&
      serviceDetail.partialCompletion
    ) {
      //Accept
      return [statusDone, statusDone, statusDone, statusCurrent, statusPending];
    } else if (
      serviceDetail &&
      serviceDetail.partialCompletion !== null &&
      serviceDetail.partialCompletion
    ) {
      //Accept
      return [statusDone, statusDone, statusDone, statusCurrent, statusPending];
    } else if (serviceDetail && !(serviceDetail.paymentDetailsStep as StepDetails).completed) {
      return [statusDone, statusDone, statusDone, statusDone, statusCurrent];
    } else {
      return [statusDone, statusCurrent, statusPending, statusPending, statusPending];
    }
  };
  public getPlanSummary = () => {
    const { service } = this.props;
    if (service && service.serviceDetails) {
      return service.serviceDetails.planDetails;
    } else {
      return null;
    }
  };
  // tslint:disable-next-line:cyclomatic-complexity
  public redirectTo = async (stepCode: PlanSummaryStep) => {
    const stepUrls = this.handleRedirecionUrl();
    const { serviceName, serviceAccountId, providerName, propertyId } = this.props.match.params;
    // TODO these multiple enum compares into an array and do a includes
    if (
      stepCode === PlanSummaryStep.PLAN_SELECTION ||
      stepCode === PlanSummaryStep.PERSONAL_DETAIL_CHECK ||
      stepCode === PlanSummaryStep.ACCEPT_TERMS_AND_CONDITION
    ) {
      let accountId = '';
      if (!isNil(serviceAccountId) && !isNaN(parseInt(serviceAccountId, 10))) {
        accountId = this.props.match.params.serviceAccountId;
      } else if (
        !isNil(this.props.savePropertyAddressStart) &&
        !isNaN(parseInt(this.props.savePropertyAddressStart, 10))
      ) {
        accountId = this.props.savePropertyAddressStart;
      }
      this.props.setServiceId(parseInt(accountId, 10));
      this.stepIndex = stepCode;
      if (
        (stepCode === PlanSummaryStep.PLAN_SELECTION ||
          stepCode === PlanSummaryStep.PERSONAL_DETAIL_CHECK ||
          stepCode === PlanSummaryStep.ACCEPT_TERMS_AND_CONDITION) &&
        this.props.match.params.lookupRequired === 'true'
      ) {
        await this.props.getCommonAddressLookup(
          propertyId,
          serviceName,
          providerName,
          this.onSuccess,
          this.onError,
        );
      } else {
        this.props.history.push(stepUrls[this.stepIndex]);
        this.onSuccess();
      }
    }
    if (stepCode === PlanSummaryStep.PAYMENT_CHECK) {
      this.props.setProviderId(this.props.match.params.providerName);
    }
    if (
      stepCode !== PlanSummaryStep.PLAN_SELECTION &&
      stepCode !== PlanSummaryStep.PERSONAL_DETAIL_CHECK &&
      stepCode !== PlanSummaryStep.ACCEPT_TERMS_AND_CONDITION
    ) {
      let accountId = '';
      if (!isNil(this.props.match.params.serviceAccountId)) {
        accountId = this.props.match.params.serviceAccountId;
      } else if (!isNil(this.props.savePropertyAddressStart)) {
        accountId = this.props.savePropertyAddressStart;
      }

      this.props.setServiceId(parseInt(accountId, 10));
      this.props.history.push(stepUrls[stepCode]);
    }
  };
  public render() {
    const { params } = this.props;
    const { serviceAccountId } = this.props.match.params;
    const planSummary = this.getPlanSummary();
    if (this.props.service) {
      if (serviceAccountId || params.serviceAccountId || this.props.savePropertyAddressStart) {
        const stepStatus = this.getStepsStatus();
        return (
          <OverViewScreen
            overViewSteps={commonServiceSteps}
            serviceTitle="Add Service"
            planSummary={planSummary}
            showPersonalDetail={
              this.props.service.serviceDetails
                ? (this.props.service.serviceDetails.personalDetailsStep as StepDetails).completed
                : false
            }
            stepStatus={stepStatus}
            redirectTo={this.redirectTo}
            handleClear={this.handleClear}
          />
        );
      } else {
        const stepStatus = [statusCurrent, statusPending, statusPending, statusPending];
        return (
          <OverViewScreen
            overViewSteps={commonServiceSteps}
            planSummary={planSummary}
            serviceTitle="Add Service"
            showPersonalDetail={
              (this.props.service.serviceDetails.personalDetailsStep as StepDetails).completed
            }
            stepStatus={stepStatus}
            redirectTo={this.redirectTo}
            handleClear={this.handleClear}
          />
        );
      }
    } else {
      return <div />;
    }
  }

  private handleClear = () => {
    const { params } = this.props;
    const { serviceAccountId } = this.props.match.params;

    const electricityServiceId =
      serviceAccountId.toString() !== 'null'
        ? serviceAccountId
        : this.props.savePropertyAddressStart.toString() !== 'null'
        ? this.props.savePropertyAddressStart
        : params.serviceAccountId;

    this.props.clearElectricityService(electricityServiceId, this.handleClearSuccess);
  };

  private handleClearSuccess = () => {
    const { serviceName, propertyId, providerName, addressConfirmed, lookupRequired } =
      this.props.match.params;
    BackHelper.saveParams({
      propertyId,
      paymentRefId: '',
      serviceAccountId: '',
    });
    this.props.removeServiceId(parseInt(propertyId, 10));
    this.props.history.replace(
      routes.commonService.address.form(
        serviceName,
        propertyId,
        providerName,
        addressConfirmed,
        lookupRequired,
        'null',
      ),
    );
  };

  private onSuccess = () => {
    if (this.stepIndex === 1) {
      const stepUrls = this.handleRedirecionUrl();
      this.props.history.push(stepUrls[this.stepIndex]);
    } else {
      this.props.getCommonQuotes(
        this.props.match.params.serviceName,
        (this.props.service.serviceDetails.property as PropertyDetails).postcode,
        '',
        this.props.match.params.providerName,
        this.onSuccessCommonQuotes,
      );
    }
  };

  private onSuccessCommonQuotes = () => {
    if (this.stepIndex === 2 || this.stepIndex === 3) {
      this.props.storePlanSummaryData(
        this.props.service.serviceDetails.planDetails.featureDetails,
        this.props.service.serviceDetails.planDetails.productDetails,
        this.props.service.serviceDetails.planDetails.concessionCard,
        !this.props.service.serviceDetails.planDetails.skipPaymentStep,
        this.props.service.serviceDetails.planDetails.summaryDetails[1],
      );
    }
    const stepUrls = this.handleRedirecionUrl();
    this.props.history.push(stepUrls[this.stepIndex]);
  };

  private onError = () => {
    if (this.stepIndex === 1) {
      const stepUrls = this.handleRedirecionUrl();
      this.props.history.push(stepUrls[this.stepIndex]);
    } else {
      this.props.getCommonQuotes(
        this.props.match.params.serviceName,
        (this.props.service.serviceDetails.property as PropertyDetails).postcode,
        '',
        this.props.match.params.providerName,
        this.onSuccessCommonQuotes,
      );
    }
  };

  private handleRedirecionUrl = () => {
    const {
      serviceName,
      propertyId,
      providerName,
      addressConfirmed,
      lookupRequired,
      serviceAccountId,
    } = this.props.match.params;
    const { params } = this.props;

    if (serviceAccountId || params.serviceAccountId) {
      return [
        routes.commonService.address.form(
          serviceName,
          propertyId,
          providerName,
          addressConfirmed,
          lookupRequired,
          serviceAccountId,
        ),
        routes.commonService.new(
          serviceName,
          propertyId,
          providerName,
          addressConfirmed,
          lookupRequired,
          serviceAccountId,
        ),
        routes.commonService.summary(serviceName, propertyId, providerName),
        routes.commonService.termsAndConditions(serviceName, propertyId, providerName),
        routes.payment.list.commonServiceSaved(propertyId, serviceName),
      ];
    } else {
      return [
        routes.commonService.address.form(
          serviceName,
          propertyId,
          providerName,
          addressConfirmed,
          lookupRequired,
          'null',
        ),
        routes.commonService.new(
          serviceName,
          propertyId,
          providerName,
          addressConfirmed,
          lookupRequired,
          serviceAccountId,
        ),
        routes.commonService.summary(serviceName, propertyId, providerName),
        routes.commonService.termsAndConditions(serviceName, propertyId, providerName),
        routes.payment.list.commonServiceSaved(propertyId, serviceName),
      ];
    }
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): PropsFromDispatch {
  return {
    getElectricityService: (propertyId: number) => {
      dispatch(ElectricityServiceActions.getElectricityServiceStart(propertyId));
    },
    removeServiceId: (propertyId: number) => {
      dispatch(PlanCommonSummaryActions.removeServiceId(propertyId));
    },
    setServiceId: (id: number) => {
      dispatch(PlanCommonSummaryActions.setServiceId(id));
    },
    setProviderId: (provider: string) => {
      dispatch(PlanCommonSummaryActions.setProviderId(provider));
    },
    storePlanSummaryData: (
      serviceDetails: FeatureDetails[],
      productDetails: ProductDetails | null,
      concessionCard: ConcessionCard | null,
      paymentCardRequired: boolean,
      benefits: PlanDetailObject,
    ) =>
      dispatch(
        PlanCommonSummaryActions.storePlanSummaryData({
          data: {
            serviceDetails,
            productDetails,
            concessionCard,
            paymentCardRequired,
            benefits,
          },
        }),
      ),
    getCommonQuotes: (
      ServiceType: string,
      Postcode: string,
      KeyIdentifier: string,
      SupplierId: string,
      onSuccess: () => void,
    ) =>
      dispatch(
        PlanCommonSummaryActions.getCommonQuoteStart({
          data: { ServiceType, Postcode, KeyIdentifier, SupplierId },
          onSuccess,
        }),
      ),
    getCommonAddressLookup: (
      propertyId: string,
      serviceName: string,
      providerName: string,
      onSuccess: () => void,
      onError: () => void,
    ) => {
      dispatch(
        PlanCommonSummaryActions.getAddressLookupStart({
          propertyId,
          serviceName,
          providerName,
          onSuccess,
          onError,
        }),
      );
    },
    clearElectricityService: (serviceAccountId: string, onSuccess: () => void) => {
      dispatch(
        ElectricityServiceActions.deleteElectricityServiceStart({
          data: serviceAccountId,
          onSuccess,
        }),
      );
    },

    getAddress: (propertyId: string) =>
      dispatch(ElectricityServiceActions.getAddressStart(propertyId)),
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  const service: ElectricityServiceDetails = state.electricityService
    .service as ElectricityServiceDetails;
  return {
    service,
    savePropertyAddressStart:
      state.commonSummary && isNil(state.commonSummary.commonServicePlans)
        ? null
        : !isNil(state.commonSummary.commonServicePlans.id)
        ? state.commonSummary.commonServicePlans.id
        : state.commonSummary.commonServicePlans.serviceAccountResponse
        ? state.commonSummary.commonServicePlans.serviceAccountResponse.id
        : null,
    state,
    accessToken: state.token.accessToken,
    params: (state.headerParams as HeaderState).params,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OverView);

import {
  Button,
  Card,
  CardContent,
  Typography,
  withStyles,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import * as React from 'react';
import { ServiceDetails } from '../../../services/electricityService/getElectricityService/GetElectricityService.data';
import FooterComponent from '../../footer/FooterComponent';
import { Styles, TermsAndConditionsStyle } from './TermsAndConditionsStyle';
import PersonalDetailsModal from './PersonalDetailsModal';
import { BUTTON_TEXT, labels } from './TermsAndConditionsConstants';
import {
  PropsObjectType,
  ModalPlanDetails,
} from '../../../containers/services/termsAndConditions/TermsAndConditions';
import { sendMessageToApp, ValidMessages } from '../../../helper/MessageHelper';
import chevronIcon from '../../../assets/chevron-right.png';
import download from '../../../assets/download.svg';
import { Document } from '../../../services/planSummary/getPlanSummary/GetPlanSummary.data';
interface PropsFromParent {
  handleSubmit: () => void;
  serviceDetails: ServiceDetails;
  cardsPersonalDetails: PropsObjectType[];
  modalPersonalDetails: PropsObjectType[];
  cardsPlanDetails: PropsObjectType[];
  modalPlanDetails: ModalPlanDetails[];
  planModalSubtext: string;
  summary: string | undefined;
  termsAndConditionsUrl?: string;
  termsDrawer: boolean;
  documents?: Document[];
  planDetailsDrawer: boolean;
  personalDetailsDrawer: boolean;
  toggleDrawer: (drawerType: string, toggle: boolean) => void;
}
interface CardDetails {
  key: string;
  value: string | null;
}

export interface InitialValues {
  email: string;
  title: string;
  firstName: string;
  lastName: string;
  mobile: string;
  dob: string;
  idType: string;
  idNumber: string;
}
type OwnProps = TermsAndConditionsStyle & PropsFromParent;

class TermsAndConditions extends React.Component<OwnProps> {
  public render() {
    const {
      classes,
      cardsPersonalDetails,
      cardsPlanDetails,
      summary,
      termsDrawer,
      planDetailsDrawer,
      personalDetailsDrawer,
    } = this.props;
    const title = !summary ? labels.title : labels.summaryTitle;
    return (
      <div className={classes.root}>
        <div className={classes.titleStyle}>
          <Typography variant="h5">{title}</Typography>
          {this.renderCard(labels.personalCardTitle, cardsPersonalDetails)}
          {this.renderCard(labels.planCardTitle, cardsPlanDetails)}
          {this.renderTermsAndConditionsCard()}
        </div>
        {!summary && (
          <>
            <Typography variant="body2" className={classes.textStyle}>
              {labels.agreeToTermsSubText}
            </Typography>
            <ol className={classes.listStyle}>
              <li>{labels.agreement1}</li>
              <li>{labels.agreement2}</li>
              <li>{labels.agreement3}</li>
            </ol>
          </>
        )}
        {!summary && (
          <FooterComponent buttonText={labels.buttonText} onClick={this.props.handleSubmit} />
        )}

        <Drawer
          anchor="bottom"
          classes={{
            paper: `${classes.bottomDrawerContainer} ${classes.planDetailsRoot}`,
          }}
          open={planDetailsDrawer}
          onClose={this.props.toggleDrawer.bind(this, 'planDetailsDrawer', false)}
        >
          {this.renderPlanDetailsDrawer(
            this.props.toggleDrawer.bind(this, 'planDetailsDrawer', false),
            classes,
          )}
        </Drawer>
        <Drawer
          anchor="bottom"
          classes={{
            paper: classes.bottomDrawerContainer,
          }}
          open={termsDrawer}
          onClose={this.props.toggleDrawer.bind(this, 'termsDrawer', false)}
        >
          {this.renderTermsDrawer(
            this.props.toggleDrawer.bind(this, 'termsDrawer', false),
            classes,
          )}
        </Drawer>
        <Drawer
          anchor="bottom"
          classes={{
            paper: classes.bottomDrawerContainer,
          }}
          open={personalDetailsDrawer}
          onClose={this.props.toggleDrawer.bind(this, 'personalDetailsDrawer', false)}
        >
          {this.renderPersonalDetailsDrawer(
            this.props.toggleDrawer.bind(this, 'personalDetailsDrawer', false),
          )}
        </Drawer>
      </div>
    );
  }

  private renderCard = (title: string, cardDetails: CardDetails[]) => {
    const { classes } = this.props;
    return (
      <Card className={classes.cardStyle}>
        <CardContent className={classes.cardContentStyle}>
          <div
            className={classes.cardHeaderStyle}
            onClick={this.props.toggleDrawer.bind(
              this,
              title === 'Plan details' ? 'planDetailsDrawer' : 'personalDetailsDrawer',
              true,
            )}
          >
            <Typography variant="h6" className={classes.headingSize}>
              {title}
            </Typography>
            <img src={chevronIcon} alt="details" className={classes.chevronIcon} />
          </div>
          <div className={classes.subTitleStyle}>
            {cardDetails.map((data: CardDetails) => (
              <Typography
                className={classes.cardTextStyle}
              >{`${data.key}: ${data.value}`}</Typography>
            ))}
          </div>
        </CardContent>
      </Card>
    );
  };
  private renderTermsAndConditionsCard = () => {
    const { classes, summary } = this.props;
    return (
      <Card className={classes.cardStyle}>
        <CardContent className={classes.cardContentStyle}>
          <div
            className={classes.cardHeaderStyle}
            onClick={this.props.toggleDrawer.bind(this, 'termsDrawer', true)}
          >
            <Typography variant="h6" className={classes.headingSize}>
              {labels.termsAndConditionsCardTitle}
            </Typography>
            <img src={chevronIcon} alt="details" className={classes.chevronIcon} />
          </div>
          <div className={classes.subTitleStyle}>
            <Typography className={classes.cardTextStyle}>
              {summary
                ? labels.termsAndConditionsCardSubTitleAfterSignup
                : labels.termsAndConditionsCardSubTitleSignup}
            </Typography>
            <Typography
              className={classes.cardTextStyle}
              style={{ textDecoration: 'underline' }}
              onClick={this.downloadPDF}
            >
              {labels.termsAndConditionsCardSubTitle2}
            </Typography>
          </div>
        </CardContent>
      </Card>
    );
  };

  private renderPlanDetailsDrawer = (togglePlanDetailsDrawer: any, classes: any) => {
    const { modalPlanDetails, planModalSubtext, documents } = this.props;
    const priceComponent = modalPlanDetails[0];
    return (
      <div className={classes.bottomDrawer}>
        <Typography className={classes.bottomDrawerTitle}>{labels.planCardTitle}</Typography>
        <div className={classes.planDetailsCard}>
          <div className={classes.planDetailsCardHeader}>
            <Typography variant="h6" className={classes.planDetailsHeadingText}>
              {priceComponent.title}
            </Typography>
          </div>
          <List>
            {priceComponent.value &&
              (priceComponent.value as PropsObjectType[]).map((data) => (
                <ListItem>
                  <ListItemText
                    primary={<Typography variant="subtitle1">{data.key}</Typography>}
                    secondary={<Typography variant="caption">{data.value}</Typography>}
                  />
                </ListItem>
              ))}
          </List>
        </div>
        {planModalSubtext && (
          <div className={`${classes.planDetailsCard} ${classes.cardContent}`}>
            <Typography className={classes.planDetailsCardTextStyle}>{planModalSubtext}</Typography>
          </div>
        )}
        {modalPlanDetails.map((component: ModalPlanDetails, index: number) => {
          if (index) {
            return (
              <div className={classes.planDetailsCard}>
                <div className={classes.planDetailsCardHeader}>
                  <Typography className={classes.planDetailsHeadingText}>
                    {component.title}
                  </Typography>
                </div>
                {typeof component.value === 'string' ? (
                  <div className={classes.cardContent}>
                    <Typography variant="body2" className={classes.planDetailsCardTextStyle}>
                      {component.value}
                    </Typography>
                  </div>
                ) : (
                  <div>
                    <List>
                      {component.value &&
                        component.value.map((data) => (
                          <ListItem>
                            <ListItemText
                              primary={<Typography variant="subtitle1">{data.key}</Typography>}
                              secondary={<Typography variant="caption">{data.value}</Typography>}
                            />
                          </ListItem>
                        ))}
                    </List>
                  </div>
                )}
              </div>
            );
          } else {
            return <div />;
          }
        })}
        {documents && documents.length !== 0 && this.renderDocumentsSection(documents)}

        <div className={classes.drawerFooter}>
          <Button
            onClick={togglePlanDetailsDrawer}
            variant="contained"
            className={classes.drawerButtons}
          >
            <Typography variant="body2" className={classes.drawerButtonText}>
              {BUTTON_TEXT}
            </Typography>
          </Button>
        </div>
      </div>
    );
  };

  private renderDocumentsSection = (documents: Document[]) => {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography className={classes.documentText} variant="subtitle1">
          Documents
        </Typography>
        <div className={`${classes.planDetailsCard} ${classes.downloadContentStyle}`}>
          {documents.map((document: Document) => this.renderItem(document))}
        </div>
      </React.Fragment>
    );
  };

  private renderItem = (document: Document) => {
    const { classes } = this.props;
    return (
      <div className={classes.downloadButton} onClick={() => this.handleDownload(document.link)}>
        <img src={download} className={classes.downloadImage} />
        <Typography className={classes.planDetailsDownloadText}>{document.name}</Typography>
      </div>
    );
  };

  private handleDownload = (link: string) => {
    sendMessageToApp(ValidMessages.pdfFromURL, link);
  };
  private renderTermsDrawer = (toggleTermsDrawer: any, classes: any) => {
    return (
      <div className={classes.bottomDrawer}>
        <Typography className={classes.bottomDrawerTitle}>
          {labels.termsAndConditionsCardTitle}
        </Typography>
        <Typography className={classes.modalSubtitle}>{'The quick version '}</Typography>
        <Typography className={classes.terms}>
          {
            '1. You are signing up to a utility (such as Broadband or Electricity) provided by Sorted Service.'
          }
        </Typography>
        <Typography className={classes.terms}>
          {'2. We will do our best to keep your service(s) connected.'}
        </Typography>
        <Typography className={classes.terms}>{'3. You will pay your bills on time'}</Typography>
        <Typography className={classes.terms}>
          {"4. If there is a problem, we'll do our best to sort it out with you."}
        </Typography>
        <Typography className={classes.terms}>
          {'5. You agree to the full Terms & Conditions (download below)'}
        </Typography>
        <div className={classes.downloadSection} onClick={() => this.downloadPDF()}>
          <img src={download} alt="download" className={classes.downloadIcon} />
          <Typography className={classes.downloadText}>
            {' Sorted Services Terms and Conditions'}
          </Typography>
        </div>
        <div className={classes.drawerFooter}>
          <Button onClick={toggleTermsDrawer} variant="contained" className={classes.drawerButtons}>
            <Typography variant="body2" className={classes.drawerButtonText}>
              {BUTTON_TEXT}
            </Typography>
          </Button>
        </div>
      </div>
    );
  };

  private renderPersonalDetailsDrawer = (togglePlanDetailsDrawer: any) => {
    const { modalPersonalDetails } = this.props;
    return (
      <PersonalDetailsModal
        personalDetails={modalPersonalDetails}
        togglePlanDetailsDrawer={togglePlanDetailsDrawer}
      />
    );
  };
  private downloadPDF = () => {
    const { termsAndConditionsUrl, summary } = this.props;
    if (!summary) {
      sendMessageToApp(ValidMessages.ElectricityPdf, termsAndConditionsUrl);
    } else {
      sendMessageToApp(ValidMessages.ElectricityPdf, labels.termsAndConditionsUrl);
    }
  };
}

export default withStyles(Styles)(TermsAndConditions);

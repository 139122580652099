import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../Routes';
import OverviewScreen from '../../containers/services/broadbandService/overviewScreen/OverviewScreen';
import BroadBandType from '../../containers/broadBandType/BroadBandType';
import BroadbandAddressForm from '../../containers/services/broadbandService/address/BroadbandAddressForm';
import AddressList from '../../containers/services/broadbandService/address/AddressList';
import EmptyAddressList from '../../containers/services/broadbandService/address/EmptyAddressList';
import DataScreen from '../../containers/services/plan/broadband/DataScreen';
import DownloadScreen from '../../containers/services/plan/broadband/DownloadScreen';
import ModemScreen from '../../containers/services/plan/broadband/ModemScreen';
import VoiceScreen from '../../containers/services/plan/broadband/VoiceScreen';
import ContractScreen from '../../containers/services/plan/broadband/ContractScreen';
import PersonalDetails from '../../containers/services/broadbandService/personalDetails//PersonalDetails';
import TermsAndConditions from '../../containers/services/broadbandService/termsAndConditions/TermsAndConditions';
import { PlanSummary } from '../../containers/services/electricityService/planSummary/PlanSummary';

export default class BroadbandLayout extends React.Component {
  public render() {
    return (
      <Switch>
        <Route
          path={routes.broadbandService.new(':propertyId', ':serviceAccountId?')}
          exact
          component={OverviewScreen}
        />
        <Route path={routes.broadbandService.type(':propertyId')} exact component={BroadBandType} />
        <Route
          path={routes.broadbandService.address.form(':propertyId')}
          exact
          component={BroadbandAddressForm}
        />
        <Route
          path={routes.broadbandService.address.list(':propertyId')}
          exact
          component={AddressList}
        />
        <Route
          path={routes.broadbandService.address.emptyList(':propertyId')}
          exact
          component={EmptyAddressList}
        />
        <Route
          path={routes.broadbandService.plan.dataSelection(':propertyId')}
          exact
          component={DataScreen}
        />
        <Route
          path={routes.broadbandService.plan.downloadSpeedSelection(':propertyId')}
          exact
          component={DownloadScreen}
        />
        <Route
          path={routes.broadbandService.plan.modemSelection(':propertyId')}
          exact
          component={ModemScreen}
        />
        <Route
          path={routes.broadbandService.plan.voiceSelection(':propertyId')}
          exact
          component={VoiceScreen}
        />
        <Route
          path={routes.broadbandService.plan.contractSelection(':propertyId')}
          exact
          component={ContractScreen}
        />
        <Route
          path={routes.broadbandService.personalDetails.showDetails(':propertyId')}
          exact
          component={PersonalDetails}
        />
        <Route
          path={routes.broadbandService.termsAndConditions(':propertyId', ':serviceSummary?')}
          exact
          component={TermsAndConditions}
        />
        <Route
          path={routes.broadbandService.planSummary(':serviceAccountId', ':serviceSummary')}
          exact
          component={PlanSummary}
        />
      </Switch>
    );
  }
}

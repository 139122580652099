import * as React from 'react';
import Input from '../inputComponent/Input';
import calendarIcon from '../../../assets/outlined.svg';
import lockIcon from '../../../assets/lock-24-px.svg';
import DeleteButton from '../buttonCompoent/Button';
import ConfirmationDrawer from '../confirmationDrawer/ConfirmationDrawer';
import WarningDrawer from '../warningDrawer/WarningDrawer';
import { GetPaymentMethodsResponse } from '../../../services/payment/getPaymentMethods/GetPaymentMethods.data';
import { withStyles } from '@material-ui/core';
import { CardDetailsStyles, styles } from './CardDetailsStyle';
import { walletLabels } from '../WalletConstants';

interface PropsFromParent {
  payment: GetPaymentMethodsResponse;
  handlePaymentDelete: () => void;
  showConfirmationModal: boolean;
  showWarningModal: boolean;
  handleConfirmationModal: (showConfirmationModal: boolean) => void;
  hanldeWarningModal: (showWarningModal: boolean) => void;
  services: string[];
}

type OwnProps = PropsFromParent & CardDetailsStyles;

class CardDetails extends React.Component<OwnProps, {}> {
  public render() {
    const { payment, classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.cardNumberInputContainer}>
          <Input label={walletLabels.CARD_NUMBER} value={payment.cardNumber.replace(/\./g, '*')} />
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.expiryInput}>
            <Input
              label={walletLabels.EXPIRY_LABEL}
              value={`${payment.cardExpiryMonth}/${payment.cardExpiryYear}`}
              haveIcon={true}
              icon={calendarIcon}
            />
          </div>
          <div className={classes.cvvInput}>
            <Input label={walletLabels.CVV_LABEL} value="***" haveIcon={true} icon={lockIcon} />
          </div>
        </div>
        <DeleteButton
          label={walletLabels.CARD_DELETE_BUTTON}
          onClick={() => this.props.handleConfirmationModal(true)}
        />
        <ConfirmationDrawer
          onCancel={() => this.props.handleConfirmationModal(false)}
          onDelete={this.props.handlePaymentDelete}
          open={this.props.showConfirmationModal}
        />
        <WarningDrawer
          onClose={() => this.props.hanldeWarningModal(false)}
          open={this.props.showWarningModal}
          services={this.props.services}
        />
      </div>
    );
  }
}

export default withStyles(styles)(CardDetails);

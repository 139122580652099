/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CheckoutState } from '../state/CheckoutState';
import { CheckoutAction, CheckoutActionTypes } from '../actions/CheckoutActions';
import {
  AddAndUpdateCartActionRequest,
  AvailableServicesResponse,
  DeleteCartItemActionRequest,
  GetCartResponse,
  ModifyCart,
  Preopen,
  ServiceTypes,
} from '../../models/checkout/Checkout';
import {
  mockAvailableServiceResponse,
  mockAddress,
  mockPersonalDetails,
} from '../../services/checkoutService/CheckoutMockResponse';
import { PersonalDetails } from '../../services/electricityService/getElectricityService/GetElectricityService.data';
import { PopupType } from '../../containers/checkoutSelect/components/ServiceContainer/ServiceContainerConstants';
import { addToCompare, removeFromCompare } from '../../helper/CheckoutHelper';
import LogRocket from 'logrocket';

export const initialState: CheckoutState = {
  isCartOpen: false,
  promoCode: { title: 'PROMOCODE25', value: 25 },
  availableServices: undefined,
  property: undefined,
  personalDetails: mockPersonalDetails,
  requiredServiceTypes: undefined,
  initialServiceTypes: undefined,
  preopen: undefined,
  propertyId: undefined,
  infoPopupDismissed: false,
  isModalOpen: PopupType.NONE,
  isLandlord: false,
  isTradeRequest: false,
};

export const checkoutReducer: (state: CheckoutState, action: CheckoutAction) => CheckoutState = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case CheckoutActionTypes.GET_AVAILABLE_SERVICES_SUCCESS:
      if (!!(action.data as GetCartResponse).user) {
        LogRocket.identify((action.data as GetCartResponse).user.id.toString(), {
          email: (action.data as GetCartResponse).user.email,
        });
      }
      return {
        ...state,
        availableServices: (action.data as GetCartResponse).availableServices,
        property: (action.data as GetCartResponse).property,
        personalDetails: (action.data as GetCartResponse).user,
      };

    case CheckoutActionTypes.TOGGLE_CART:
      return {
        ...state,
        isCartOpen: !state.isCartOpen,
      };

    case CheckoutActionTypes.TOGGLE_LL_MODAL:
      return {
        ...state,
        isModalOpen: action.data as PopupType,
      };

    case CheckoutActionTypes.ADD_AND_UPDATE_CART_REQUEST:
      return {
        ...state,
        availableServices: (action.data as AddAndUpdateCartActionRequest).availableServices,
      };

    case CheckoutActionTypes.ADD_AND_UPDATE_CART_SUCCESS:
      return {
        ...state,
        availableServices: action.data as AvailableServicesResponse[],
      };

    case CheckoutActionTypes.DELETE_CART_ITEM_REQUEST:
      return {
        ...state,
        availableServices: (action.data as DeleteCartItemActionRequest).availableServices,
      };

    case CheckoutActionTypes.ADD_TO_CART:
      return {
        ...state,
        availableServices: state.availableServices!.map((service) =>
          service.type !== (action.data as ModifyCart).serviceType
            ? service
            : {
                ...service,
                suppliers: service.suppliers!.map((supplier) => ({
                  ...supplier,
                  selected: supplier.providerId === (action.data as ModifyCart).providerId,
                  plans: supplier.plans.map((plan) => ({
                    ...plan,
                    selected: plan.productId === (action.data as ModifyCart).productId,
                  })),
                })),
              },
        ),
      };
    case CheckoutActionTypes.REMOVE_FROM_CART:
      return {
        ...state,
        availableServices: state.availableServices!.map((service) =>
          service.type !== (action.data as ModifyCart).serviceType
            ? service
            : {
                ...service,
                suppliers: service.suppliers!.map((supplier) => ({
                  ...supplier,
                  selected: false,
                  plans: supplier.plans.map((plan) => ({
                    ...plan,
                    selected: false,
                  })),
                })),
              },
        ),
      };
    case CheckoutActionTypes.ADD_TO_COMPARE:
      return {
        ...state,
        availableServices: addToCompare(
          (action.data as ModifyCart).serviceType,
          (action.data as ModifyCart).providerId,
          (action.data as ModifyCart).productId,
          state.availableServices!,
        ),
      };
    case CheckoutActionTypes.REMOVE_FROM_COMPARE:
      return {
        ...state,
        availableServices: removeFromCompare(
          (action.data as ModifyCart).serviceType,
          (action.data as ModifyCart).providerId,
          (action.data as ModifyCart).productId,
          state.availableServices!,
        ),
      };
    case CheckoutActionTypes.SET_REQUIRED_SERVICE_TYPES:
      const hasRent =
        state.requiredServiceTypes && state.requiredServiceTypes.includes(ServiceTypes.Rent);
      const result = hasRent
        ? [...(action.data as ServiceTypes[]), ...[ServiceTypes.Rent]]
        : action.data;

      return {
        ...state,
        requiredServiceTypes: result as ServiceTypes[],
        initialServiceTypes:
          state.initialServiceTypes === undefined
            ? (result as ServiceTypes[])
            : state.initialServiceTypes,
      };
    case CheckoutActionTypes.SET_PREOPEN:
      return {
        ...state,
        preopen: action.data as Preopen,
      };
    case CheckoutActionTypes.SET_CHECKOUT_PROPERTY_ID:
      return {
        ...state,
        propertyId: action.data as string,
      };
    case CheckoutActionTypes.UPDATE_PERSONAL_DETAILS:
      return {
        ...state,
        personalDetails: action.data as PersonalDetails,
      };

    case CheckoutActionTypes.DISMISS_INFO_POPUP:
      return {
        ...state,
        infoPopupDismissed: true,
      };

    case CheckoutActionTypes.SET_IS_LANDLORD:
      return {
        ...state,
        isLandlord: action.data as boolean,
      };

    case CheckoutActionTypes.SET_IS_TRADE_REQUEST:
      return {
        ...state,
        isTradeRequest: action.data as boolean,
      };
    default:
      return state;
  }
};

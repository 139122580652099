/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AvailableServicesResponse, PaymentRules } from '../../../../models/checkout/Checkout';
import { PaymentInformation } from '../../../services/commonServices/CommonPlanSelectionInterface';

export const getPreSelectedPaymentMethods = (cartItem: AvailableServicesResponse[]) => {
  let paymentMethods = {};
  cartItem &&
    cartItem.length > 0 &&
    cartItem.forEach((item: AvailableServicesResponse, index: number) => {
      const supplier = item.suppliers![0];
      const { cartData } = supplier.plans[0];
      if (cartData && cartData.paymentRefId) {
        paymentMethods = { ...paymentMethods, [index]: cartData.paymentRefId };
      }
    });
  return paymentMethods;
};

export const getServicePaymentRules = (cartItem: AvailableServicesResponse): PaymentRules => {
  const plan = cartItem.suppliers![0].plans[0];
  const item = cartItem.suppliers![0].extendedData!.Plans.find(
    (p) => p.ProductId === plan.productId,
  );
  const paymentInformation =
    item !== undefined
      ? item.PaymentInformation
      : cartItem.suppliers![0].extendedData!.Plans[0].PaymentInformation;
  return {
    paymentMethods: paymentInformation.PaymentMethods,
    saveToDB: paymentInformation.SignupPayments.SaveToDb,
    instantPay: paymentInformation.SignupPayments.InstantPay,
    paymentType: paymentInformation.PaymentGateway,
  };
};

import { color } from '../../../../theme/Theme';
import { WithStyles, createStyles } from '@material-ui/core';

export const Styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  body: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: 70,
  },
  top: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 16px 0 16px',
  },
  title: {
    marginBottom: '3vh',
  },
  imageBox: {
    display: 'flex',
    justifyContent: 'center',
    width: 64,
    height: 64,
    borderRadius: '50%',
    backgroundColor: color.light,
    position: 'relative',
    marginBottom: -32,
    marginLeft: 19,
  },
  imageStyle: {
    width: 64,
    height: 64,
    borderRadius: '50%',
  },
  card: {
    borderRadius: 8,
    padding: 0,
    height: 370,
    marginBottom: 80,
    width: '100%',
  },
  cardHeader: {
    height: 14,
    backgroundColor: color.secondary,
    padding: 14,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  propertyImage: {
    height: 16,
    width: 98,
  },
  cardBody: {
    padding: 0,
    paddingRight: 14,
  },
  primaryTextStyle: {
    color: color.dark,
    opacity: 0.38,
  },
  secondaryTextStyle: {
    color: color.dark,
    opacity: 0.87,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  backButtonStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.light,
    height: 40,
  },
  divider: {
    margin: '14px 0 14px 0',
  },
  contract: {
    color: color.dark,
    opacity: 1,
  },
  contractInfo: {
    marginTop: 11,
    fontFamily: 'GT Walsheim light',
    color: color.dark,
  },
  bottomDrawerContainer: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  bottomDrawer: {
    marginTop: '29px',
    padding: '0px 16px 18px 16px',
  },
  bottomDrawerTitle: {
    fontSize: '23.3px',
    letterSpacing: '0.3px',
  },
  drawerButtons: {
    'marginTop': 50,
    'background': color.primary,
    '&:hover': {
      backgroundColor: color.primary,
    },
    '& > span > p': {
      color: 'white',
    },
  },
  terms: {
    fontFamily: 'GT Walsheim light',
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: '20px',
    marginTop: 24,
  },
  termsList: {
    fontFamily: 'GT Walsheim light',
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: '20px',
    marginTop: 10,
  },
  drawerSubTitleStyle: {
    fontFamily: 'GT Walsheim',
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 1.43,
    marginTop: 24,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  disagreeButtonStyle: {
    'marginTop': 50,
    'marginRight': 6,
    'borderRadius': 0,
    'background': color.light,
    '&:hover': {
      backgroundColor: color.light,
    },
    '& > span > p': {
      color: color.primary,
    },
  },
  agreeButtonStyle: {
    'marginTop': 50,
    'marginLeft': 6,
    'background': color.primary,
    'borderRadius': 0,
    '&:hover': {
      backgroundColor: color.primary,
    },
    '& > span > p': {
      color: 'white',
    },
  },
  agreeButtonText: {
    color: 'white',
  },
  modalStyle: {
    margin: 0,
    marginLeft: 16,
    marginRight: 16,
  },
  dialogtitleStyle: {},
  dialogContentStyle: {
    paddingLeft: '0px 16px 0px 16px',
  },
  modalTitleStyle: {
    padding: '24px 24px 0px 24px',
    fontFamily: 'GT Walsheim',
    fontSize: 16,
    letterSpacing: 0.25,
    lineHeight: 1.23,
  },
  modalSubTitleStyle: {
    fontFamily: 'GT Walsheim Light',
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 1.43,
    marginTop: 16,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  cancelButtonStyle: {
    'marginTop': 32,
    'marginRight': 6,
    'width': 113,
    'height': 36,
    'background': color.light,
    '&:hover': {
      backgroundColor: color.light,
    },
    '& > span > p': {
      color: color.primary,
    },
  },
  modalButtonStyle: {
    'marginTop': 32,
    'width': 113,
    'height': 36,
    'marginLeft': 6,
    'background': color.primary,
    'borderRadius': 2,
    '&:hover': {
      backgroundColor: color.primary,
    },
    '& > span > p': {
      color: 'white',
    },
  },
  buttonContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
});

export interface PlanScreenStyles extends WithStyles<typeof Styles> {}

import * as React from 'react';
import AddressFormComponent, {
  initialValuesState,
} from '../../../../component/services/electricityService/addressForm/AddressForm';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { PropertyDetails } from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { BroadbandServiceActions } from '../../../../store/actions/BroadbandServiceActions';
import { ApplicationState } from '../../../../store/RootReducers';
import { BroadbandServiceDetails } from '../../../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import {
  AddressLookup,
  BroadbandAddressLookup,
} from '../../../../services/broadbandService/getBroadbandAddressLookup/GetBroadbandAddressLookup.data';
import { History } from 'history';
import { RouteComponentProps } from 'react-router-dom';
import { routes } from '../../../../Routes';
import { PropertyObject } from '../../../../objectFactory/PropertyObjectFactory';
import { BackHelper } from '../../../../helper/BackHelper';

interface PropsFromDispatch {
  saveProperty: (property: PropertyDetails) => void;
  getAddressList: (property: AddressLookup, history: History, propertyId: string) => void;
  getAddress: (propertyId: string) => void;
}

interface PropsFromState {
  service: BroadbandServiceDetails;
  property: PropertyDetails;
}

interface PropsFromRoute {
  history: History;
}
type OwnProps = PropsFromDispatch &
  PropsFromState &
  PropsFromRoute &
  RouteComponentProps<{ propertyId: string }>;
class AddressForm extends React.Component<OwnProps, {}> {
  public componentDidMount() {
    const { propertyId } = this.props.match.params;
    this.props.getAddress(propertyId);
  }
  public render() {
    const { property } = this.props;
    if (property) {
      return <AddressFormComponent propertyDetails={property} handleSubmit={this.handleSubmit} />;
    } else {
      return <div />;
    }
  }

  public handleSubmit = (property: initialValuesState) => {
    const { propertyId } = this.props.match.params;
    const propertyDetails = this.props.property;
    const address: PropertyDetails = {
      ...property,
      address: propertyDetails.address,
      id: propertyDetails.id,
      photos: propertyDetails.photos,
      type: propertyDetails.type,
      nmi: '',
      nmiMeterNumbers: null,
      keyIdentifier: '',
      meterSerialNumber: null,
      addressLine1: null,
      addressLine2: null,
      addressLine3: null,
    };
    const propertyObject = new PropertyObject({});
    const addressRequest = propertyObject.createAddresLookupPropertyObject(property, 'Broadband');
    BackHelper.saveCount(-1);
    this.props.getAddressList(addressRequest, this.props.history, propertyId);
    this.props.saveProperty(address);
  };
}
function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>): PropsFromDispatch {
  return {
    saveProperty: (property: PropertyDetails) => {
      dispatch(BroadbandServiceActions.saveProperty(property));
    },
    getAddressList: (data: AddressLookup, history: History, propertyId: string) => {
      dispatch(
        BroadbandServiceActions.getBroadbandServiceAddressLookupStart({
          data,
          onSuccess: (response: BroadbandAddressLookup) => {
            if (response.AddressSearch.length === 0) {
              history.push(routes.broadbandService.address.emptyList(propertyId));
            } else {
              history.push(routes.broadbandService.address.list(propertyId));
            }
          },
          onError: () => {
            history.push(routes.broadbandService.address.emptyList(propertyId));
          },
        }),
      );
    },
    getAddress: (propertyId: string) =>
      dispatch(BroadbandServiceActions.getBroadbandAddressStart(propertyId)),
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    service: state.broadbandService.service as BroadbandServiceDetails,
    property: state.broadbandService.serviceAddress as PropertyDetails,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddressForm);

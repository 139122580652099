import { createStyles, WithStyles } from '@material-ui/core';
import { color } from '../../../../theme/Theme';

export const styles = createStyles({
  root: {
    backgroundColor: color.secondary,
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 48px)',
  },
  body: {
    padding: '12px',
  },
  titleStyle: {
    marginTop: 65,
    marginBottom: '4vh',
  },
  cardNumberInputStyle: {
    flexGrow: 1,
    marginTop: 20,
  },
  inputBoxStyle: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
  },
  expiryInputStyle: {
    flex: 1,
    marginRight: 10,
  },
  cvvInputStyle: {
    flex: 1,
    marginLeft: 10,
  },
  errorButton: {
    borderWidth: 0,
    marginTop: 40,
  },
  errorMessage: {
    color: 'red',
  },
  secureBoxStyle: {
    height: 56,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    marginVertical: 20,
  },
  secureLockStyle: {
    marginRight: 16,
  },
  footerButtonsContainer: {
    bottom: 12,
    left: 12,
    right: 12,
    position: 'fixed',
    margin: 'auto',
    maxWidth: 800,
  },
  footerButton: {
    padding: '16px 16px 16px 16px',
    backgroundColor: color.buttonBackgroundSecondary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.light,
    fontWeight: 600,
    borderRadius: 6,
  },
  footerButtonSecondary: {
    marginTop: 12,
    padding: '16px 16px 16px 16px',
    backgroundColor: color.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.black87,
    fontWeight: 600,
    borderRadius: 6,
    border: `${color.borderColor} 2px solid`,
  },
  disabled: {
    padding: '16px 16px 16px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    borderRadius: 6,
    backgroundColor: color.disabled,
    color: color.black38,
  },
});

export interface AddCardStyles extends WithStyles<typeof styles> {}

import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { MultipartFileData } from '../MultipartFormData.data';
import { DeleteFileRequest, FileUploadResponse } from '../../models/files/files';

const serviceType = ServiceType.FileUploadOrDelete;

export class FileUploadService {
  private readonly service: ApiServiceBase = new ApiService(serviceType);

  public postMultipleFile(payload: File[]): Promise<FileUploadResponse[]> | FileUploadResponse[] {
    const data: MultipartFileData[] = [];
    payload.forEach((file) =>
      data.push({
        name: 'files',
        file,
      }),
    );
    return this.service.postMultipartFileData<FileUploadResponse[]>({}, data);
  }
  public deleteFile(payload: DeleteFileRequest): Promise<void> | void {
    return this.service.delete({
      route: ['delete'],
      query: { key: payload.key },
    });
  }
}

const postFileService = new FileUploadService();
export default postFileService;

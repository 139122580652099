import localStorage from '../services/LocalStorage';

// Make sure this enum is similar to the one in Onboarding.tsx in Sorted.
export enum ValidMessages {
  Chat = 'chat',
  Dashboard = 'dashboard',
  ErrorScreen = 'errorScreen',
  Pdf = 'pdf',
  PayId = 'payId',
  ShowToast = 'showToast',
  OpenWebView = 'openWebView',
  Log = 'log',
  UpdateToken = 'updateToken',
  SessionExpired = 'sessionExpired',
  ElectricityPdf = 'electricityPdf',
  intercomTagging = 'intercomTagging',
  pdfFromURL = 'pdfFromURL',
  OpenUrl = 'openurl',
  pdfFromBase64 = 'pdfFromBase64',
  close = 'close',
  token = 'token',
}

//For clearing authtoken when user goes to dashboard
const tokenClearList = [
  ValidMessages.Dashboard,
  ValidMessages.ErrorScreen,
  ValidMessages.ShowToast,
  ValidMessages.SessionExpired,
];
// SENDS MESSAGE TO SORTED APP
export const sendMessageToApp = (message: ValidMessages, payload?: string | object) => {
  const data = { message, payload };
  if (tokenClearList.includes(message)) {
    localStorage.clearAll();
  }
  try {
    window.postMessage(JSON.stringify(data), '*');
    (window as any).ReactNativeWebView.postMessage(JSON.stringify(data));
  } catch (e) {
    console.log(e);
  }
};

import * as React from 'react';
import AddressListComponent from '../../../../component/services/commonService/addressForm/CommonAddressList';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/RootReducers';
import { ElectricityAddressLookup } from '../../../../services/electricityService/getElectricityAddressLookup/GetElectricityAddressLookup.data';
import { History } from 'history';
import { RouteComponentProps } from 'react-router-dom';
import { routes } from '../../../../Routes';
import { PropertyDetails } from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { AnyAction } from 'redux';
import { BackHelper } from '../../../../helper/BackHelper';
import { CommonServiceAddressLookup } from '../../../../services/commonService/getCommonServiceAddressLookup/GetCommonAddressLookup.data';
import { PropertyObject } from '../../../../objectFactory/PropertyObjectFactory';
import { PlanCommonSummaryActions } from '../../../../store/actions/CommonSummaryActions';

interface PropsFromState {
  //TODO: Resolve any
  propertyList: ElectricityAddressLookup | any;
  //TODO: Resolve any
  propertyDetail: any;
  property: PropertyDetails;
  //TODO: Resolve any
  state: any;
}

interface PropsFromDispatch {
  //TODO: Resolve any
  postProperty: (data: any, onSuccess: () => void) => void;
  //TODO: Resolve any
  createPropertyDetail: (data: any) => void;
}

interface PropsFromRoute {
  history: History;
  serviceName: string;
}
type OwnProps = PropsFromState &
  PropsFromDispatch &
  PropsFromRoute &
  RouteComponentProps<{
    propertyId: string;
    serviceName: string;
    providerName: string;
    lookupRequired: string;
    serviceAccountId: string;
  }>;

class AddressList extends React.Component<OwnProps, {}> {
  private stopCall = 0;
  public componentDidMount() {
    if (
      (this.props.propertyList &&
        (this.props.propertyList.AddressesSearchable === null ||
          this.props.propertyList.AddressesSearchable.length === 0)) ||
      !JSON.parse(this.props.match.params.lookupRequired)
    ) {
      this.handleNext(null);
    } else if (
      (this.props.propertyList &&
        this.props.propertyList.AddressesSearchable !== null &&
        this.props.propertyList.AddressesSearchable.length === 1) ||
      !JSON.parse(this.props.match.params.lookupRequired)
    ) {
      this.handleNext(this.props.propertyList.AddressesSearchable[0]);
    } else if (!this.props.propertyList) {
      this.handleNext(null);
    }
  }

  public render() {
    const { serviceName } = this.props.match.params;
    const { propertyList, history } = this.props;
    return this.props.propertyList &&
      this.props.propertyList.AddressesSearchable !== null &&
      this.props.propertyList.AddressesSearchable.length > 0 ? (
      <AddressListComponent
        propertyList={propertyList}
        handleSkip={this.handleSkip}
        handleNext={this.handleNext}
        history={history}
        serviceName={serviceName}
      />
    ) : (
      <div />
    );
  }
  private handleSkip = () => {
    BackHelper.saveCount(-1);
  };
  //TODO: Resolve any
  private handleNext = (lookupObject: any) => {
    const { propertyId, serviceName, providerName, lookupRequired, serviceAccountId } =
      this.props.match.params;
    this.props.createPropertyDetail(lookupObject);
    const { propertyDetail } = this.props;
    const propetyObject = new PropertyObject({});
    const serviceDetail =
      serviceAccountId === 'null' ||
      serviceAccountId.length === 0 ||
      serviceAccountId === 'undefined'
        ? null
        : serviceAccountId;
    lookupObject =
      lookupObject == null ? (propertyDetail ? propertyDetail.Attributes : {}) : lookupObject;
    const lookupData = propetyObject.createCommonLookupAddressObject(
      lookupObject,
      'LOOKUP_DETAILS',
      propertyId,
      serviceName,
      providerName,
      propertyDetail ? propertyDetail.Attributes : {},
      serviceDetail,
    );
    if (this.stopCall === 0) {
      this.stopCall = 1;
      this.props.postProperty(lookupData, () => {
        this.props.history.push(
          routes.commonService.new(
            serviceName,
            propertyId,
            providerName,
            'true',
            lookupRequired,
            serviceDetail,
          ),
        );
      });
    }
  };
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>): PropsFromDispatch {
  return {
    //TODO: Resolve any
    postProperty: (data: any, onSuccess: () => void) => {
      dispatch(PlanCommonSummaryActions.setPropertyAddressData({ data, onSuccess }));
    },
    //TODO: Resolve any
    createPropertyDetail: (data: any) => {
      dispatch(PlanCommonSummaryActions.createPropertySelected(data));
    },
  };
}
function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    propertyList: state.commonSummary.commonSummaryAddressLookup as CommonServiceAddressLookup,
    propertyDetail: state.commonSummary.commonSummaryAddressLookup,
    property: state.commonSummary.property as PropertyDetails,
    state,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddressList);

import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../Routes';
import ServicePlan from '../../containers/servicePlan/ServicePlan';
import OverView from '../../containers/services/commonServices/Overview';
import TermsAndConditionsMock from '../../containers/services/termsAndConditions/PlanSelectionTnc';

export default class CommonSummaryLayout extends React.Component {
  public render() {
    return (
      <Switch>
        <Route
          path={routes.service.commonServiceSummary(':propertyId', ':serviceAccountId')}
          exact
          component={ServicePlan}
        />
        <Route
          path={routes.service.commonTermsAndConditions.new(':propertyId', ':serviceAccountId')}
          exact
          component={TermsAndConditionsMock}
        />
        <Route
          path={routes.commonService.address.overview(
            ':propertyId',
            ':serviceName',
            ':addressConfirmed',
            ':lookupRequired',
            ':providerName',
            ':serviceAccountId',
          )}
          exact
          component={OverView}
        />
        <Route
          path={routes.commonService.address.overview(
            ':propertyId',
            ':serviceName',
            ':addressConfirmed',
            ':lookupRequired',
            ':providerName',
          )}
          exact
          component={OverView}
        />
      </Switch>
    );
  }
}

import * as React from 'react';
import SupportScreenUI, {
  FooterData,
} from '../../../../component/services/plan/electricity/SupportScreen'; // Screens,
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/RootReducers';
import { Dispatch, AnyAction } from 'redux';
import { PlanState } from '../../../../store/state/PlanState';
import { PlanActions } from '../../../../store/actions/PlanActions';
import { getExtraInfoLabel } from './PlanConstants';
import { BackHelper } from '../../../../helper/BackHelper';
import { PlanDetails } from '../../../../models/plan/electricity/getPlanDetails/PlanDetails.data';

interface PropsFromState {
  plan: PlanState;
}

interface PropsFromDispatch {
  toggleSupport: (choice: boolean) => void;
}

type OwnProps = PropsFromDispatch & PropsFromState;

class SupportScreen extends React.Component<OwnProps> {
  public render() {
    return (
      <SupportScreenUI
        {...this.props}
        handleNext={this.handleNext}
        footerData={this.filterFooterData()}
        planDetails={this.props.plan.planDetails as PlanDetails}
      />
    );
  }

  private filterFooterData = (): FooterData => {
    const { plan } = this.props.plan;
    return {
      amount: plan.productDetails.monthlyCost,
      extraInfo: getExtraInfoLabel(''),
    };
  };

  private handleNext = (choice: string) => {
    BackHelper.saveCount(-1);
    this.props.toggleSupport(choice === 'yes');
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    plan: state.plan,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): PropsFromDispatch {
  return {
    toggleSupport: (choice: boolean) => {
      dispatch(PlanActions.toggleSupport(choice));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SupportScreen);

/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, useState, useEffect } from 'react';
import { Dispatch } from 'redux';
import {
  PaymentActions,
  SaveSelectedPaymentMethodPayload,
  PaymentErrorPayload,
} from '../../../../store/actions/PaymentActions';
import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  Snackbar,
  withStyles,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/RootReducers';
import { BankAccountRequest } from '../../../../models/payment/PaymentRequest.data';
import { SpinnerActions } from '../../../../store/actions/SpinnerActions';
import { routes } from '../../../../Routes';
import { withRouter, RouteComponentProps } from 'react-router';
import { GetPaymentMethodsResponse } from '../../../../services/payment/getPaymentMethods/GetPaymentMethods.data';
import {
  ElectricityServiceDetails,
  ServiceAccount,
} from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { BroadbandServiceDetails } from '../../../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import { ElectricityServiceActions } from '../../../../store/actions/ElectricityServiceActions';
import { BroadbandServiceActions } from '../../../../store/actions/BroadbandServiceActions';
import { ServiceType } from '../../../services/ServiceConstants';
import { createPaymentRequestObject } from '../../../../helper/PaymentHelper';
import { EditPaymentRequset } from '../../../../services/editPayment/postEditPayment/PostEditPayment.data';
import { EditPaymentActions } from '../../../../store/actions/EditPaymentActions';
import { createEditPaymentObject, maskBankAccountDetails, MaskDetails } from '../AddPaymentUtils';
import { BackHelper } from '../../../../helper/BackHelper';
import { PlanCommonSummaryActions } from '../../../../store/actions/CommonSummaryActions';
import { styles, AddBankStyles } from './AddBankStyles';
import {
  CommonPaymentSummary,
  MatchParams,
  PaymentSummary,
  InitialBankValues,
  accountNumberRegex,
  errorMessages,
  bsbValidatorPattern,
  LABELS,
  lengths,
  accountNameRegex,
  bsbNumberRegex,
  initialBankValues,
} from './AddBankConstants';
import { handlePaymentSuccess } from './AddBankUtils';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import secureIcon from '../../../../assets/images/payment/lock-24-px.svg';
import CustomInput from '../../../../component/customInput/CustomInput';
import NavBar from '../../../../component/navBar/NavBar';
import MainContainer from '../../../../component/mainContainer/MainContainer';

interface AddBankProps
  extends AddBankStyles,
    RouteComponentProps<MatchParams>,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const AddBank: FC<AddBankProps> = ({
  classes,
  history,
  match,
  paymentMethodId,
  paymentErrorState,
  electricityServiceId,
  broadbandServiceAccountId,
  serviceAccount,
  providerId,
  saveSelectedPaymentMethod,
  getPaymentAuthKey,
  postCommonData,
  postElectricityPaymentDetails,
  postBroadbandServiceDetails,
  postEditPayment,
}) => {
  const bsbNumberValidator = CustomInput(bsbValidatorPattern);
  const [accountNumberError, setAccountNumberError] = useState<boolean>(false);
  const [maskedBankAccountDetails, setMaskedBankAccountDetails] = useState<MaskDetails>({
    paymentDetails: '',
    logo: '',
  });

  useEffect(() => {
    const { propertyId, paymentRefId, serviceAccountId } = match.params;
    BackHelper.saveParams({ propertyId, paymentRefId, serviceAccountId });
  }, []);

  useEffect(() => {
    saveSelectedPaymentMethod({
      paymentMethodId,
      maskedDetails: maskedBankAccountDetails,
    });
  }, [paymentMethodId]);

  const handleAccountNumber = (accountNumber: string, setFieldValue: any) => {
    setFieldValue('accountNumber', accountNumber.replace(accountNumberRegex, ''));
    if (accountNumber.length > 10) {
      setAccountNumberError(true);
    } else if (accountNumberError) {
      setAccountNumberError(false);
    }
  };

  const handleSubmit = (values: InitialBankValues) => {
    const maskedBankAccountDetails = maskBankAccountDetails(values.accountNumber, values.bsbNumber);
    setMaskedBankAccountDetails(maskedBankAccountDetails);
    saveSelectedPaymentMethod({
      paymentMethodId: '2',
      maskedDetails: maskedBankAccountDetails,
    });
    const paymentRequest: BankAccountRequest = {
      type: 'BANKACCOUNT',
      accountName: values.accountName.trim(),
      accountNumber: values.accountNumber,
      bsbNumber: values.bsbNumber,
      email: '',
    };
    getPaymentAuthKey(paymentRequest, handlePayment);
  };

  const handlePayment = (payment: GetPaymentMethodsResponse) => {
    const { propertyId, serviceType } = match.params;
    switch (serviceType) {
      case ServiceType.Rent:
        history.push(routes.summary.new(propertyId, serviceType));
        break;
      case ServiceType.Electricity:
        handleElectricityServicePayment(payment);
        break;
      case ServiceType.ElectricityCaps:
        handleElectricityServicePayment(payment);
        break;
      case ServiceType.Broadband:
        handleBroadbandServicePayment(payment);
        break;
      case ServiceType.EditRent:
        handleEditPayment(payment);
        break;
      case ServiceType.EditElectricity:
        handleEditPayment(payment);
        break;
      case ServiceType.EditBroadband:
        handleEditPayment(payment);
        break;
      case ServiceType.Wallet:
        history.push(routes.wallet.paymentOptions(serviceType));
        break;
      default:
        break;
    }
  };

  const handleEditPayment = (payment: GetPaymentMethodsResponse) => {
    const { serviceAccountId } = match.params;
    const requestData = createEditPaymentObject(parseInt(serviceAccountId, 10), payment.refId);
    postEditPayment(requestData, () => handlePaymentSuccess(history, match.params));
  };

  const handleElectricityServicePayment = (payment: GetPaymentMethodsResponse) => {
    const { propertyId, serviceAccountId, serviceType } = match.params;
    const data: ElectricityServiceDetails = createPaymentRequestObject(payment.refId);
    const paymentComplete: PaymentSummary = {
      paymentRefId: payment.refId,
    };
    const commonPaymentSummary: CommonPaymentSummary = {
      serviceDetails: paymentComplete,
      step: 'PAYMENT',
      propertyId,
      serviceType,
      providerId,
      serviceAccountId: serviceAccount as string,
    };
    electricityServiceId
      ? postElectricityPaymentDetails(
          parseInt(propertyId, 10),
          electricityServiceId ? electricityServiceId : Number(serviceAccountId),
          data,
          () => handlePaymentSuccess(history, match.params),
        )
      : postCommonData(commonPaymentSummary, () => handlePaymentSuccess(history, match.params));
  };

  const handleBroadbandServicePayment = (payment: GetPaymentMethodsResponse) => {
    const { propertyId } = match.params;
    const data: BroadbandServiceDetails = createPaymentRequestObject(payment.refId);
    postBroadbandServiceDetails(
      propertyId,
      (broadbandServiceAccountId as number).toString(),
      data,
      () => handlePaymentSuccess(history, match.params),
    );
  };

  return (
    <div className={classes.root}>
      <NavBar subTitle={LABELS.NAV_TITLE} subText={LABELS.NAV_SUBTITLE} />

      <div className={classes.body}>
        <MainContainer>
          <Formik
            initialValues={initialBankValues}
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={Yup.object().shape({
              accountName: Yup.string()
                .required(errorMessages.required)
                .max(lengths.accNameMax, errorMessages.accNameLength)
                .matches(accountNameRegex, errorMessages.accName),
              bsbNumber: Yup.string()
                .required(errorMessages.required)
                .matches(bsbNumberRegex, errorMessages.invalidBsb),
              accountNumber: Yup.string()
                .required(errorMessages.required)
                .min(lengths.accNumMin, errorMessages.accNumMin)
                .max(lengths.accNumMax),
            })}
            render={({
              values,
              handleChange,
              setFieldValue,
              handleSubmit,
              errors,
              touched,
              isValid,
            }) => (
              <div className={classes.form}>
                <FormControl className={classes.inputs} error={!!errors.accountName}>
                  <InputLabel>Account name</InputLabel>
                  <Input
                    name="accountName"
                    type="text"
                    onChange={(event) => setFieldValue('accountName', event.target.value)}
                    value={values.accountName}
                  />
                  {!!errors.accountName && <FormHelperText>{errors.accountName}</FormHelperText>}
                </FormControl>
                <FormControl
                  className={classes.inputs}
                  error={!!errors.bsbNumber && touched.bsbNumber}
                >
                  <InputLabel>BSB Number</InputLabel>
                  <Input
                    name="bsbNumber"
                    onChange={handleChange}
                    value={values.bsbNumber}
                    inputComponent={bsbNumberValidator}
                    endAdornment={
                      <InputAdornment position="end">
                        <img src={secureIcon} className={classes.secureIcon} />
                      </InputAdornment>
                    }
                  />
                  <FormHelperText>
                    <ErrorMessage name="bsbNumber" />
                  </FormHelperText>
                </FormControl>
                <FormControl className={classes.inputs} error={accountNumberError}>
                  <InputLabel>Account Number</InputLabel>
                  <Input
                    name="accountNumber"
                    value={values.accountNumber}
                    onChange={(event) => handleAccountNumber(event.target.value, setFieldValue)}
                    endAdornment={
                      <InputAdornment position="end">
                        <img src={secureIcon} className={classes.secureIcon} />
                      </InputAdornment>
                    }
                  />
                  {accountNumberError && <FormHelperText>{errorMessages.accNumMax}</FormHelperText>}
                </FormControl>
                {paymentErrorState && (
                  <Snackbar message={`${paymentErrorState.error}, try again`} />
                )}
                <div className={classes.footerButtonsContainer}>
                  <div
                    className={`${!isValid ? classes.disabled : classes.footerButton}`}
                    onClick={() => {
                      if (isValid) {
                        handleSubmit();
                      }
                    }}
                  >
                    Save
                  </div>
                  <div className={classes.footerButtonSecondary} onClick={() => history.goBack()}>
                    Back
                  </div>
                </div>
              </div>
            )}
          />
        </MainContainer>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  paymentMethodId: state.payment.selectedPaymentMethod.paymentMethodId,
  paymentErrorState: state.payment.error,
  serviceAccount: state.commonSummary.commonServicePlans.id
    ? state.commonSummary.commonServicePlans.id
    : state.commonSummary.commonServicePlans.serviceAccountResponse
    ? state.commonSummary.commonServicePlans.serviceAccountResponse.id
    : undefined,
  providerId:
    state.commonSummary && state.commonSummary.commonServicePlansSuccess
      ? state.commonSummary.commonServicePlansSuccess.provider
        ? state.commonSummary.commonServicePlansSuccess.provider
        : state.commonSummary.commonServicePlansSuccess &&
          state.commonSummary.commonServicePlansSuccess.serviceAccountResponse
        ? state.commonSummary.commonServicePlansSuccess.serviceAccountResponse.provider
        : undefined
      : undefined,
  electricityServiceId:
    state.electricityService &&
    state.electricityService.service &&
    (state.electricityService.service.serviceAccount as ServiceAccount) &&
    ((state.electricityService.service.serviceAccount as ServiceAccount).id as number),
  broadbandServiceAccountId:
    state.broadbandService &&
    state.broadbandService.service &&
    ((state.broadbandService.service.serviceAccount as ServiceAccount).id as number),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getPaymentMethods: (onSuccess: (response: GetPaymentMethodsResponse[]) => void) => {
    dispatch(
      PaymentActions.getPaymentMethodsStart({
        onSuccess,
      }),
    );
  },
  postCommonData: (serviceDetails: CommonPaymentSummary, onSuccess: () => void) =>
    dispatch(
      PlanCommonSummaryActions.postCommonSummaryData({
        data: serviceDetails,
        onSuccess,
      }),
    ),
  saveSelectedPaymentMethod: (data: SaveSelectedPaymentMethodPayload) => {
    dispatch(PaymentActions.saveSelectedPaymentMethod(data));
  },
  getPaymentAuthKey: (
    data: BankAccountRequest,
    handlePayment: (payment: GetPaymentMethodsResponse) => void,
  ) => {
    dispatch(
      PaymentActions.getPaymentAuthKeyStart({
        data,
        onSuccess: (token: string) => {
          dispatch(
            PaymentActions.postPaymentTokenStart({
              data: { resultKey: token },
              onSuccess: (payment: GetPaymentMethodsResponse) => {
                handlePayment(payment);
              },
            }),
          );
        },
        onError: (error: PaymentErrorPayload) => {
          dispatch(PaymentActions.getPaymentAuthKeyError(error));
          dispatch(SpinnerActions.hide());
        },
      }),
    );
  },
  postElectricityPaymentDetails: (
    propertyId: number,
    serviceAccountId: number,
    data: ElectricityServiceDetails,
    handlePaymentSuccess: () => void,
  ) => {
    dispatch(
      ElectricityServiceActions.postElectricityServiceStart({
        propertyId,
        serviceAccountId,
        data,
        onSuccess: () => {
          handlePaymentSuccess();
        },
      }),
    );
  },
  postBroadbandServiceDetails: (
    propertyId: string,
    serviceAccountId: string,
    data: BroadbandServiceDetails,
    handlePaymentSuccess: () => void,
  ) => {
    dispatch(
      BroadbandServiceActions.postBroadbandServiceStart({
        propertyId,
        serviceAccountId,
        data,
        onSuccess: () => {
          handlePaymentSuccess();
        },
      }),
    );
  },
  postEditPayment: (data: EditPaymentRequset, onSuccess: () => void) => {
    dispatch(EditPaymentActions.postEditPaymentStart({ data, onSuccess }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(AddBank)));

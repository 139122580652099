import { PlanDetailsSubmit } from '../../../../models/plan/electricity/postPlanDetails/PlanDetailsSubmit.data';

export const PLAN_LABELS = {
  title: 'Select your plan',
  drawerTitle: 'Usage',
  drawerButton: 'CLOSE',
  buttonsHeading: 'Usage for your property',
  planInfo:
    "Unsure how much you will use? Don't worry, you can adjust your payment plan at any time.",
  contract: 'No contract',
  contractInfo:
    'Enjoy freedom! All of our plans are no contract! Your payments are processed monthly',
  lowUsageInfo: (usage: string) =>
    `${usage} KWh (Ideal if you use less electricity than average, or have just installed solar).`,
  moderateUsageInfo: (usage: string) =>
    `RECOMMENDED: ${usage} KWh (Based on the average usage of your property or similar households).`,
  highUsageInfo: (usage: string) =>
    `${usage} KWh (Ideal if you use more electricity than the average household).`,
};

export const SUPPORT_LABELS = {
  title: 'Life support',
  drawerTitle: 'Life support',
  drawerButton: 'CLOSE',
  buttonsHeading: 'Do you need life support?',
  planInfo: "If 'Yes' Additional information may be required",
  options: ['yes', 'no'],
};

export const CONCESSION_LABELS = {
  title: 'Concessions',
  drawerTitle: 'Concession Card',
  drawerButton: 'CLOSE',
  buttonsHeading: 'Do you have a concession card?',
  planInfo: 'You will be asked to provide card details to complete',
  options: ['yes', 'no'],
};

export const TermsAndConditionsLabels = {
  title: 'Concession card terms and conditions',
  subTitle1:
    'Sorted Services is required to perform an enquiry on the validity of your Concession/DVA card using the Centrelink eService.',
  subTitle2:
    'By agreeing you grant Sorted Services permission to perform this check and the Department of Human Services to provide the results of that enquiry back to Sorted Services.',
  subTitle3: 'To continue you need to agree to the following:',
  subTitle4:
    'The Department of Human Services will use information I have provided to Sorted Services Electricity to confirm my eligibility for relevant concessions or rebates and will disclose to Sorted Services Electricity personal information including my name, address, payment type, payment status, concession card type and status.',
  subTitle5:
    'This consent, remains valid while I am a customer of Sorted Services Electricity unless I withdraw it by contacting Sorted Services Electricity or the department.',
  subTitle6:
    'If I withdraw my consent or do not alternatively provide proof of my circumstances, I may not be eligible for the relevant concessions or rebates provided by Sorted Services Electricity.',
  subTitle7:
    'Once we have your concession details, we will keep you informed of any planned outages.',
  agreeButtonLabel: 'Agree',
  disagreeLabel: 'Disagree',
};

export const serviceTypes = {
  Electricity: 'ElectricityService',
  Gas: 'GasService',
  Telecom: 'TelcoService',
  Broadband: 'BroadbandService',
};

export const ModalLabels = {
  title: 'Disagree and skip concession card?',
  subTitle:
    'If you disagree to the concession card terms and conditions you will not be able to sign up with a concession card added to your account.',
  cancelButtonLabel: 'Cancel',
  disAgreeButonLabel: 'Disagree',
};

export const PLAN_MODAL_DATA = {
  firstPara: 'Usage for your property can vary.',
  list: [
    '∙ The recommended usage is based off the average consumption for the household and is an estimate only.',
    '∙ It is a guide to help households manage their energy consumption.',
    "∙ You are charged for what you actually use. If you go over we'll add the difference in the following payment period. If you are under it will stay as a credit on your account.",
    '∙ We will regularly review your account to ensure you are on the right budget.',
  ],
  endPara: '',
};

export const CONCESSION_MODAL_DATA = {
  firstPara:
    'If you hold an eligible concession card you may be entitled to receive a concession on your energy rates. It is the responsibility of the user to determine whether they are entitled to receive a concession.',
  endPara:
    'If you are unsure whether you are entitled to a concession, please contact us to discuss your eligibility.',
};

export const SUPPORT_MODAL_DATA = {
  firstPara:
    'You are classified as being life support dependent if you depend on any of the following equipment',
  list: [
    '∙ Oxygen concentrator',
    '∙ Intermittent peritoneal dialysis machine',
    '∙ Kidney dialysis machine',
    '∙ Chronic positive airways pressure respirator',
    '∙ Crigler najjar syndrome phototherapy equipment',
    '∙ Ventilator for life support',
  ],
  endPara:
    'Any other equipment (whether fuelled by electricity or gas) that a registered medical practitioner certifies is required for life support, or a medical condition which requires continued supply of gas.',
};

export const DEFAULT_SELECTED_BUTTON_INDEX = 1;
export const EMPTY_ARRAY_LENGTH = 0;
export const FOOTER_VALUE_NA = 'N/A';

export const createPlanObject = (plan: PlanDetailsSubmit, lifeSupport: boolean) => {
  return {
    ...plan,
    lifeSupport,
    concessionCard: null,
  };
};

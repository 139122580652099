import React, { FC, useEffect, useState, useMemo } from 'react';
import { withStyles, Dialog } from '@material-ui/core';
import { styles, AgencyBackgroundStyles } from './AgencyBackgroundStyles';
import { LABELS } from './AgencyBackgroundConstants';
import MainContainer from '../mainContainer/MainContainer';
import naxPromo from '../../assets/naxPromo.png';
import sortedPromo from '../../assets/sortedPromo.png';
import switchPromo from '../../assets/switchPromo.png';
import { AGENCIES, APP } from '../../helper/AppNameHelper';
import HowItWorks from '../howItWorks/HowItWorks';

interface AgencyBackgroundProps extends AgencyBackgroundStyles {}

const AgencyBackground: FC<AgencyBackgroundProps> = ({ classes }) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);

  return (
    <div className={classes.backgroundContainer}>
      <div className={classes.backgroundOverlay}>
        <MainContainer height100>
          <div className={classes.contentContainer}>
            <div className={classes.textContent}>
              <div className={classes.title}>{LABELS.TITLE}</div>
              <div className={classes.text}>{LABELS.CONTENT()}</div>
              {APP !== AGENCIES.SWITCH && (
                <div className={classes.button} onClick={() => setShowPopup(true)}>
                  {LABELS.BUTTON}
                </div>
              )}
            </div>
            {APP !== AGENCIES.SWITCH && (
              <div className={classes.imageContainer}>
                <img
                  className={classes.image}
                  src={APP === AGENCIES.NAX ? naxPromo : sortedPromo}
                  alt=""
                />
              </div>
            )}
          </div>
        </MainContainer>
      </div>
      <Dialog
        open={showPopup}
        maxWidth="sm"
        fullWidth
        style={{
          zIndex: 999999,
        }}
        onClose={() => setShowPopup(false)}
      >
        <HowItWorks closePopup={() => setShowPopup(false)} />
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(AgencyBackground);

import * as React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { AddressListStyles, Styles } from './AddressListStyle';
import searchLogo from '../../../../assets/search.svg';
import { color } from '../../../../theme/Theme';
import FooterComponent from '../../../footer/FooterComponent';
import { Labels } from './AddressListConstants';
import { isNil } from 'lodash';

interface PropsFormParent {
  handleSubmit: () => void;
  buttonText?: string;
}
type OwnProps = AddressListStyles & PropsFormParent;

class EmptyAddressList extends React.Component<OwnProps, {}> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.body} style={{ alignItems: 'center', alignContent: 'center' }}>
          <img className={classes.headIcon} src={searchLogo} />
          <Typography className={classes.heading} variant="h5">
            {Labels.title}
          </Typography>
          <Typography
            className={classes.paragraph}
            variant="body2"
            style={{ color: color.primary, fontFamily: 'GT Walsheim' }}
          >
            {Labels.subTitle1}
          </Typography>
          <Typography
            className={classes.paragraph}
            variant="body2"
            style={{ color: color.primary, fontFamily: 'GT Walsheim' }}
          >
            {Labels.subTitle2}
          </Typography>
          <FooterComponent
            buttonText={isNil(this.props.buttonText) ? 'NEXT' : this.props.buttonText}
            onClick={this.props.handleSubmit}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(Styles)(EmptyAddressList);

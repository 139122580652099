import { WithStyles, createStyles } from '@material-ui/core';
import { color } from '../../../theme/Theme';

export const styles = createStyles({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    bottom: 16,
    left: 0,
    right: 0,
    padding: '0 16px 0 16px',
    position: 'fixed',
  },
  buttonStyle: {
    'display': 'flex',
    'flex': 1,
    'height': 36,
    'background': color.actionButton,
    '&:hover': {
      backgroundColor: color.actionButton,
    },
  },
  buttonTextStyle: {
    color: color.light,
  },
});

export interface ButtonStyles extends WithStyles<typeof styles> {}

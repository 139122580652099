import React, { FC } from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { LABELS } from './SettingUpPropertyConstants';
import { styles, SettingUpPropertyStyles } from './SettingUpPropertyStyles';
import LOADER_IMAGE from '../../../../assets/sorted-icon-dark.png';

interface SettingUpPropertyProps extends SettingUpPropertyStyles {}

const SettingUpProperty: FC<SettingUpPropertyProps> = ({ classes }) => {
  return (
    <div className={classes.loadingRoot}>
      <div className={classes.alignCenter}>
        <img className={classes.sortedLoader} src={LOADER_IMAGE} />
        <Typography className={classes.primaryText} variant="body1">
          {LABELS.SETTING_UP}
        </Typography>
        <Typography className={classes.secondaryText} variant="subtitle2">
          {LABELS.WONT}
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(styles)(SettingUpProperty);

import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';

import { History } from 'history';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../../store/RootReducers';
import { format } from 'date-fns';
import { PlanCommonSummaryActions } from '../../../store/actions/CommonSummaryActions';
import PersonalDetailsUI, {
  InitialValues,
} from '../../../component/services/personalDetails/PersonalDetails';

import { QuotesV3 } from './CommonPlanSelectionInterface';
import {
  ElectricityServiceDetails,
  IdDetails,
} from '../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { get } from 'lodash';

interface PropsFromState {
  //TODO: Resolve any
  service: any;
  //TODO: Resolve any
  personalDetail: any;
  quotes: QuotesV3;
  //TODO: Resolve any
  state: any;
  serviceAccountId: number;
  partialCompletion: boolean;
}

interface PropsFromRoute {
  history: History;
}

interface PropsFromDispatch {
  //TODO: Resolve any
  postPersonalDetails: (personalDetails: any, onSuccess: () => void) => void;
  setPersonalDetailStatus: () => void;
  //TODO: Resolve any
  setFinalScreenData: (finalData: any) => void;
}

type OwnProps = PropsFromDispatch &
  PropsFromState &
  PropsFromRoute &
  RouteComponentProps<{
    propertyId: string;
    serviceName: string;
    providerId: string;
    serviceAccountId: string;
  }>;

class PersonalDetail extends React.Component<OwnProps> {
  public render() {
    return (
      <PersonalDetailsUI
        //TODO: Resolve any
        personalDetails={this.props.personalDetail}
        handleSave={this.handleSave}
        showExpiry={true}
      />
    );
  }

  private handleSave = (personalDetails: InitialValues) => {
    const idDetails: IdDetails = {
      dlStateIssue: personalDetails.stateIssue,
      medicareRefNumber: personalDetails.referenceNumber,
      medicareCardColor: personalDetails.medicareCardColor,
      passportCountry: personalDetails.countryOfIssue,
    };
    //TODO: Resolve any
    const postData: any = {
      serviceDetails: {
        personalDetails: {
          id: this.props.personalDetail.id,
          name: personalDetails.firstName,
          surname: personalDetails.lastName,
          email: personalDetails.email,
          phone: personalDetails.mobile,
          salutation: personalDetails.title,
          dob: format(personalDetails.dob as Date, 'dd/MM/yyyy'),
          idType: personalDetails.idType,
          idNumber: personalDetails.idNumber,
          idExpiry: format(personalDetails.idExpiry as Date, 'dd/MM/yyyy'),
          idDetails,
        },
        partialCompletion: get(this.props, 'partialCompletion', true),
      },
      propertyId: this.props.match.params.propertyId,
      serviceType: this.props.match.params.serviceName,
      providerId: this.props.match.params.providerId,
      serviceAccountId: this.props.match.params.serviceAccountId,
      step: 'PERSONAL_DETAILS',
    };
    this.props.postPersonalDetails(postData, this.onSuccess);
  };

  private onSuccess = () => {
    const { history, setPersonalDetailStatus } = this.props;
    setPersonalDetailStatus();
    history.goBack();
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  //TODO: Resolve any
  const service = state.broadbandService.service as any;
  return {
    service,
    partialCompletion:
      state.commonSummary.commonServicePlansSuccess &&
      state.commonSummary.commonServicePlansSuccess.steps
        ? state.commonSummary.commonServicePlansSuccess.steps.partialCompletion
        : (state.electricityService.service as ElectricityServiceDetails).serviceDetails
            .partialCompletion,
    personalDetail: state.commonSummary.savePropertyAddressStart
      ? state.commonSummary.commonServicePlans.steps
        ? state.commonSummary.commonServicePlans.steps.personalDetails
        : (state.electricityService.service as ElectricityServiceDetails).serviceDetails
            .personalDetails
      : (state.electricityService.service as ElectricityServiceDetails).serviceDetails
          .personalDetails,
    quotes: state.commonSummary.quotes as QuotesV3,
    state,
    serviceAccountId: state.commonSummary.commonServicePlans.id
      ? state.commonSummary.commonServicePlans.id
      : state.commonSummary.commonServicePlans.serviceAccountResponse.id,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): PropsFromDispatch {
  return {
    //TODO: Resolve any
    postPersonalDetails: (personalDetails: any, onSuccess: () => void) => {
      dispatch(
        PlanCommonSummaryActions.postPersonalDetails({
          data: personalDetails,
          onSuccess,
        }),
      );
    },
    setPersonalDetailStatus: () => {
      dispatch(PlanCommonSummaryActions.setPersonalDetailStatus());
    },
    //TODO: Resolve any
    setFinalScreenData: (finalData: any) => {
      dispatch(
        PlanCommonSummaryActions.setFinalScreenData({
          data: finalData,
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetail);

import * as React from 'react';
import ConcessionCard from '../../../../component/concessionCard/ConcessionCard';
import { AnyAction } from 'redux';
import {
  ElectricityServiceDetails,
  ServiceAccount,
} from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { PlanState } from '../../../../store/state/PlanState';
import { ApplicationState } from '../../../../store/RootReducers';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { routes } from '../../../../Routes';
import { BackHelper } from '../../../../helper/BackHelper';
import { createPlanObject } from '../../../../component/services/plan/electricity/Constants';
import { PlanDetailsSubmit } from '../../../../models/plan/electricity/postPlanDetails/PlanDetailsSubmit.data';
import { PlanActions } from '../../../../store/actions/PlanActions';

interface PropsFromDispatch {
  savePlan: (data: PlanDetailsSubmit) => void;
}
interface PropsFromRoute {
  history: History;
}
interface PropsFromState {
  plan: PlanState;
  serviceAccountId: number;
}
type OwnProps = PropsFromDispatch &
  PropsFromState &
  PropsFromRoute &
  RouteComponentProps<{ propertyId: string }>;
class ConcessionCardOptions extends React.Component<OwnProps> {
  public render() {
    const { propertyId } = this.props.match.params;
    return (
      <ConcessionCard
        handleNext={this.handleNext}
        handleSkip={this.handleSkip}
        propertyId={propertyId}
      />
    );
  }
  private handleNext = (nextPage: string) => {
    this.props.history.push(nextPage);
  };
  private handleSkip = () => {
    const { plan, lifeSupport } = this.props.plan;
    const { propertyId } = (this.props as any).match.params;
    const data: PlanDetailsSubmit = createPlanObject(plan, lifeSupport);
    this.props.savePlan(data);
    BackHelper.saveCount(-1);
    this.props.history.push(routes.service.connectionDate.saved(propertyId));
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    plan: state.plan,
    serviceAccountId: (
      (state.electricityService.service as ElectricityServiceDetails)
        .serviceAccount as ServiceAccount
    ).id as number,
  };
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>): PropsFromDispatch {
  return {
    savePlan: (data: PlanDetailsSubmit) => dispatch(PlanActions.saveChoosenPlan(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConcessionCardOptions);

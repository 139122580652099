/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store/RootReducers';
import { Dispatch } from 'redux';
import { styles, CheckoutFinalizeStyles } from './CheckoutFinalizeStyles';
import { LABELS } from './CheckoutFinalizeConstants';
import CheckoutFooter from '../../component/checkoutFooter/CheckoutFooter';
import AccountSection from './components/AccountSection/AccountSection';
import {
  updateAccountAndPaymentInAvailableServices,
  updateAccountInAvailableServices,
  useCart,
} from '../../helper/CheckoutHelper';
import {
  AddAndUpdateCartActionRequest,
  AvailableServicesResponse,
  CheckoutRequest,
  Property,
  ServiceTypes,
} from '../../models/checkout/Checkout';
import { hasPayments } from './CheckoutFinalizeUtils';
import { CheckoutActions } from '../../store/actions/CheckoutActions';
import { PersonalDetails } from '../../services/electricityService/getElectricityService/GetElectricityService.data';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';
import { isPersonalDetailsCompleted } from './components/AccountSection/AccountSectionUtils';
import { getPreSelectedPaymentMethods } from './components/PaymentSection/PaymentSectionUtils';
import MainContainer from '../../component/mainContainer/MainContainer';
import AddressHeader from '../../component/addressHeader/AddressHeader';
import PaymentItem from './components/PaymentItem/PaymentItem';
import { routes } from '../../Routes';
import { PaymentActions } from '../../store/actions/PaymentActions';

interface CheckoutFinalizeProps extends CheckoutFinalizeStyles, RouteComponentProps<{}> {
  property: Property;
  isExternal: boolean;
  personalDetails: PersonalDetails;
  availableServices: AvailableServicesResponse[];
  updateCart: (data: AddAndUpdateCartActionRequest) => void;
  postCheckout: (data: CheckoutRequest) => void;
  updatePersonalDetails: (data: PersonalDetails) => void;
  accessToken: string | boolean | null;
  getPaymentMethods: (onSuccess: () => void) => void;
  requiredServiceTypes: ServiceTypes[]
}

const CheckoutFinalize: FC<CheckoutFinalizeProps> = ({
  classes,
  history,
  isExternal,
  property,
  personalDetails,
  availableServices,
  updateCart,
  postCheckout,
  updatePersonalDetails,
  accessToken,
  getPaymentMethods,
  requiredServiceTypes
}) => {
  const { cartItems } = useCart();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [updatedPersonalDetails, setPersonalDetails] = useState<PersonalDetails>({
    ...personalDetails,
  });
  const [selectedPaymentMethods, setSelectedPaymentMethod] = useState<Map<string, string> | {}>(
    getPreSelectedPaymentMethods(cartItems),
  );
  const [validate, setValidationFlag] = useState<boolean>(false);

  const hasAnyPayment = useMemo<boolean>(() => {
    let result = false;

    cartItems.filter((cartItem) => {
      if (hasPayments(cartItem.suppliers![0].plans[0].cartData)) {
        result = true;
      }
    });
    return result;
  }, [cartItems]);

  useEffect(() => {
    if (accessToken) {
      getPaymentMethods(() => true);
    }
  }, []);

  const isButtonEnabled = () => {
    if (submitted) return false;
    let isPaymentMethodsSelected: boolean[] = [];

    cartItems.map((cartItem, index) => {
      if (cartItem.complete.payment) {
        isPaymentMethodsSelected.push(true);
      } else {
        if (
          cartItem.suppliers![0].plans[0].cartData &&
          !cartItem.suppliers![0].plans[0].cartData.planDetails.skipPaymentStep
        ) {
          isPaymentMethodsSelected.push(!!selectedPaymentMethods[index]);
        } else {
          isPaymentMethodsSelected.push(true);
        }
      }
    });
    const isPersonalInfoCompleted = isPersonalDetailsCompleted(updatedPersonalDetails);
    const isAllServicesPaymentCompleted =
      isPaymentMethodsSelected.findIndex((paymentMethod) => !paymentMethod) > -1;
    return !isAllServicesPaymentCompleted && isPersonalInfoCompleted;
  };

  const goBack = () => {
    history.goBack();
  };

  const onNext = () => {
    setSubmitted(true);
    let updatedAvailableServices = [...availableServices];
    cartItems &&
      cartItems.length > 0 &&
      cartItems.forEach((cartItem) => {
        updatedAvailableServices = updateAccountInAvailableServices(
          updatedAvailableServices,
          cartItem.type,
          cartItem.suppliers![0].providerId,
          cartItem.suppliers![0].plans[0].productId,
          updatedPersonalDetails,
        );
      });
    updateCart({
      availableServices: updatedAvailableServices,
      propertyId: property.id,
      onSuccess: () => {
        postCheckout({
          propertyId: property.id,
          checkoutServiceTypes: requiredServiceTypes,
          onSuccess: () => {
            if (isExternal) {
              history.push(routes.checkout.completeCart);
            } else {
              sendMessageToApp(ValidMessages.Dashboard, {
                toHome: true,
                showSetupCompleteToast: true,
              });
            }
          },
          onError: () => setSubmitted(false),
        });
      },
    });
  };

  const savePersonalDetails = (personalDetails: PersonalDetails) => {
    setPersonalDetails(personalDetails);
    updatePersonalDetails(personalDetails);
  };

  const savePaymentMethod = (index: number, refId: string | null) => {
    setSelectedPaymentMethod({ ...selectedPaymentMethods, [index]: refId });
    const updatedAvailableServices = updateAccountAndPaymentInAvailableServices(
      availableServices,
      cartItems[index].type,
      cartItems[index]!.suppliers![0].providerId,
      cartItems[index]!.suppliers![0].plans[0].productId,
      updatedPersonalDetails,
      refId,
    );
    updateCart({
      availableServices: updatedAvailableServices,
      propertyId: property.id,
    });
  };

  return (
    <div className={classes.root}>
      <AddressHeader address={property ? property.address : undefined} disabled={!!property} />
      <div style={{ marginTop: 50 }} />
      <div className={classes.scrollContainer}>
        <AccountSection
          setPersonalDetails={(personalDetails: PersonalDetails) =>
            savePersonalDetails(personalDetails)
          }
          validate={validate}
        />
        {hasAnyPayment && (
          <MainContainer>
            <div className={classes.sectionContainer}>
              <div className={classes.sectionTitleContainer}>
                <div className={classes.sectionTitle}>{LABELS.PAYMENT}</div>
              </div>
              {cartItems.map((paymentItem, index) => (
                <>
                  {hasPayments(paymentItem.suppliers![0].plans[0].cartData) && (
                    <PaymentItem
                      cartItem={paymentItem}
                      activeTab={index}
                      paymentType={
                        paymentItem.suppliers![0].plans[0].cartData!.planDetails.paymentType
                      }
                      setPaymentMethod={(refId: string | null) => savePaymentMethod(index, refId)}
                      selectedPaymentRefId={selectedPaymentMethods[index] || ''}
                      key={`payment_item_${index}`}
                      validate={validate}
                    />
                  )}
                </>
              ))}
            </div>
          </MainContainer>
        )}
      </div>
      <CheckoutFooter
        primaryText={submitted ? 'Processing...' : LABELS.NEXT}
        onPrimary={onNext}
        disabled={!isButtonEnabled()}
        secondaryText={LABELS.BACK}
        onSecondary={goBack}
        handleValidation={() => {
          setValidationFlag(true);
          setTimeout(() => {
            setValidationFlag(false);
          }, 5000);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  property: state.checkout.property,
  personalDetails: state.checkout.personalDetails,
  availableServices: state.checkout.availableServices,
  isExternal: state.token.isExternal,
  accessToken: state.token.accessToken,
  requiredServiceTypes: state.checkout.requiredServiceTypes
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCart: (data: AddAndUpdateCartActionRequest) => dispatch(CheckoutActions.updateCart(data)),
  postCheckout: (data: CheckoutRequest) => dispatch(CheckoutActions.postCheckout(data)),
  updatePersonalDetails: (data: PersonalDetails) =>
    dispatch(CheckoutActions.updatePersonalDetails(data)),
  getPaymentMethods: (onSuccess: () => void) => {
    dispatch(
      PaymentActions.getPaymentMethodsStart({
        onSuccess,
      }),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(CheckoutFinalize)));

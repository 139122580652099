import { WithStyles, createStyles } from '@material-ui/core';
import { color } from '../../../theme/Theme';

export const styles = createStyles({
  drawerContiner: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    justifyContent: 'space-between',
  },
  titleStyle: {
    fontSize: 23,
  },
  subTitleStyle: {
    marginTop: 29,
    marginRight: 29,
    fontFamily: 'GT Walsheim Regular',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 52,
  },
  cancelButtonStyle: {
    'display': 'flex',
    'flex': 1,
    'height': 36,
    'background': color.actionButton,
    '&:hover': {
      backgroundColor: color.actionButton,
    },
  },
  cancelButtonText: {
    color: color.light,
  },
  deleteButtonText: {
    color: color.light,
  },
  deleteButtonStyle: {
    'display': 'flex',
    'flex': 1,
    'height': 36,
    'background': color.actionButton,
    '&:hover': {
      backgroundColor: color.actionButton,
    },
  },
});

export interface ConfirmationDrawerStyles extends WithStyles<typeof styles> {}

import { Leaves } from '../models/Leaves.data';
import { parseDate } from './DateFormatter';
import { isAfter, isBefore } from 'date-fns';

export const connectionDateValidationHelper = (dateObj: Date, leavelist: string[]) => {
  let leaveFlag = false;
  const date = dateObj || new Date();
  const pickedDate = date.getDate();
  const pickedDateMonth = date.getMonth() + 1;
  const pickedDateYear = date.getFullYear();

  leaveFlag = disableWeekends(date);

  if (leaveFlag !== true) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < leavelist.length; i++) {
      const leave = leavelist[i].split('-');
      const leaveDate = +leave[2];
      const leaveYear = +leave[0];
      const leaveMonth = +leave[1];

      if (
        leaveYear === pickedDateYear &&
        leaveMonth === pickedDateMonth &&
        leaveDate === pickedDate
      ) {
        leaveFlag = true;
        break;
      }
    }
  }
  return leaveFlag;
};

export const leaveFilter = (state: string, leavelist: Leaves[]) => {
  const leaveListArray = [];
  //To disable today's Date
  leaveListArray.push(parseDate(new Date().toISOString()));
  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < leavelist.length; i++) {
    if (leavelist[i].State === state || leavelist[i].State === null || leavelist[i].State === '*') {
      leaveListArray.push(parseDate(leavelist[i].Date));
    }
  }
  return leaveListArray;
};

export const MovingDateValidationHelper = (date: Date) => {
  return !isAfter(date, new Date());
};
export const DateValidationHelper = (dateObj: Date, leavelist: string[]) => {
  let date = dateObj || new Date();
  const tomorrow: Date = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  if (isBefore(date, tomorrow)) {
    date = tomorrow;
  }
  while (connectionDateValidationHelper(date, leavelist)) {
    date.setDate(date.getDate() + 1);
  }
  return date;
};

export const disableWeekends = (date: Date) => {
  return date.getDay() === 0 || date.getDay() === 6;
};

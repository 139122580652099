import { createStyles, WithStyles } from '@material-ui/core/styles';
import { AGENCIES, APP } from '../../helper/AppNameHelper';
import { color } from '../../theme/Theme';
export const styles = createStyles({
  root: {
    'height': 48,
    'display': 'flex',
    'flexDirection': 'row',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    'paddingLeft': 12,
    'paddingRight': 12,
    'backgroundColor': color.headerColor,
    '& img': {
      cursor: 'pointer',
    },
    'boxShadow': 'none',
    'pointerEvents': 'all',
    'zIndex': 5000,
  },
  row: {
    'display': 'flex',
    'flexDirection': 'row',
    'justifyContent': 'space-between',
    'alignItems': 'center',
  },
  label: {
    color: color.black87,
    fontFamily: 'GT Walsheim',
    fontSize: 15,
    display: APP === AGENCIES.NAX ? 'none' : 'block',
  },
  cartCount: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: 12,
    height: 12,
    backgroundColor: APP === AGENCIES.NAX ? color.actionButton : color.lightBlue,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    color: color.light,
    fontFamily: 'GT Walsheim',
    fontSize: 9,
    fontWeight: 'bold',
  },
  sortedLogo: {
    height: 20,
    position: 'absolute',
  },
  naxLogo: {
    height: 30,
  },
  naxIcon: {
    height: 20,
  },
  switchLogo: {
    height: 50,
    top: 6,
    position: 'relative',
  },
  imgStyle: { height: 25, width: 25, alignItems: 'center', position: 'relative' },
  dialog: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    overflow: 'hidden',
  },
});

export interface HeaderPropStyles extends WithStyles<typeof styles> {}

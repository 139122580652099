import * as React from 'react';
import EmptyAddressListComponent from '../../../../component/services/broadbandService/addressList/EmptyAddressList';
import { AnyAction } from 'redux';
import { ApplicationState } from '../../../../store/RootReducers';
import { connect } from 'react-redux';
import { PlanCommonSummaryActions } from '../../../../store/actions/CommonSummaryActions';
import { PropertyDetails } from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { RouteComponentProps } from 'react-router-dom';
import { History } from 'history';
import { PostCommonServiceAddressSaveRequest } from '../../../../services/commonService/getCommonService/GetCommonService.data';
import { routes } from '../../../../Routes';
import { PropertyObject } from '../../../../objectFactory/PropertyObjectFactory';

interface PropsFromState {
  property: PropertyDetails;
  //TODO: Resolve any
  propertyList: any;
}

interface PropsFromRoute {
  history: History;
}
interface PropsFromDispatch {
  postProperty: (data: PostCommonServiceAddressSaveRequest, onSuccess: () => void) => void;
}
type OwnProps = PropsFromState &
  PropsFromDispatch &
  PropsFromRoute &
  RouteComponentProps<{
    propertyId: string;
    serviceName: string;
    providerName: string;
    lookupRequired: string;
    serviceAccountId: string;
  }>;

class CommonAddressList extends React.Component<OwnProps, {}> {
  public render() {
    return <EmptyAddressListComponent handleSubmit={this.handleSubmit} />;
  }
  private handleSubmit = () => {
    const { propertyId, serviceName, providerName, lookupRequired, serviceAccountId } =
      this.props.match.params;
    const propetyObject = new PropertyObject({});
    const lookupData = propetyObject.createCommonLookupAddressObject(
      {},
      'LOOKUP_DETAILS',
      propertyId,
      serviceName,
      providerName,
      {},
      serviceAccountId,
    );
    this.props.postProperty(lookupData, () => {
      this.props.history.push(
        routes.commonService.new(
          serviceName,
          propertyId,
          providerName,
          'true',
          lookupRequired,
          serviceAccountId,
        ),
      );
    });
  };
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>): PropsFromDispatch {
  return {
    postProperty: (data: PostCommonServiceAddressSaveRequest, onSuccess: () => void) => {
      dispatch(PlanCommonSummaryActions.setPropertyAddressData({ data, onSuccess }));
    },
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    property: state.electricityService.property as PropertyDetails,
    propertyList: state.commonSummary.commonSummaryAddressLookup,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommonAddressList);

import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import WelcomeScreen from '../../containers/welcomeScreen/WelcomeScreen';
import { routes } from '../../Routes';
import Summary from '../../containers/summary/Summary';
import PropertyConfirmation from '../../containers/property/Confirmation';
import LeaseServiceability from '../../containers/leaseServiceability/LeaseServiceability';

export default class RentLayout extends React.Component {
  public render() {
    return (
      <Switch>
        <Route path={routes.welcome} exact component={WelcomeScreen} />
        <Route path={routes.property.new} component={PropertyConfirmation} />
        <Route path={routes.summary.new(':propertyId', ':serviceType')} component={Summary} />
        <Route path={routes.serviceability} component={LeaseServiceability} />
      </Switch>
    );
  }
}

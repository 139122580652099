import { createStyles, WithStyles } from '@material-ui/core/styles';
import { color } from '../../../theme/Theme';

export const styles = createStyles({
  root: {
    'height': 124,
    'display': 'flex',
    'flexDirection': 'column',
    'paddingLeft': 16,
    'paddingRight': 16,
    'paddingTop': 25,
    'paddingBottom': 21,
    'backgroundColor': color.headerColor,
    'boxShadow': 'none',
    '& img': {
      cursor: 'pointer',
    },
  },
  imageContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  logoStyle: {
    width: 24,
    height: 24,
  },
  labelStyle: {
    color: color.black87,
  },
});

export interface WalletHeaderStyles extends WithStyles<typeof styles> {}

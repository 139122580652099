import { makeStyles } from '@material-ui/core';
import { color } from '../../theme/Theme';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    cursor: 'pointer',
  },
  inputFileStyle: {
    padding: '5px 2px 10px 0px',
    cursor: 'pointer',
  },
  input: {
    display: 'none',
  },
  fileImage: {
    color: 'grey',
    cursor: 'pointer',
  },
  cancelIcon: {
    cursor: 'pointer',
  },
  displayFile: {
    paddingBottom: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `dotted ${color.borderColor}`,
    marginBottom: '10px',
  },
  uploadFile: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `2px solid ${color.borderColor}`,
    cursor: 'pointer',
  },
  errorLine: {
    borderBottom: `2px solid ${color.danger}`,
  },
});

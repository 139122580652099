import { color } from '../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';
import opacity from 'material-ui/svg-icons/action/opacity';

export const styles = createStyles({
  footerContainer: {
    position: 'fixed',
    bottom: 0,
    width: '100vw',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    boxShadow: '0px 15px 20px #888',
    // overflow: 'hidden',
  },
  backgroundColor: {
    backgroundColor: color.light,
    position: 'relative',
    zIndex: 2,
  },
  footerContent: {
    padding: 15,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  compareIconContainer: {
    width: 24,
    height: 24,
    flexShrink: 0,
  },
  compareIcon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  secondaryButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 40,
    color: color.black87,
    textAlign: 'center',
    fontFamily: 'GT Walsheim',
    fontSize: 15,
    border: `${color.borderColor} 2px solid`,
    borderRadius: 6,
    marginRight: 15,
    cursor: 'pointer',
  },
  primaryButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 40,
    color: color.light,
    textAlign: 'center',
    fontFamily: 'GT Walsheim',
    fontSize: 15,
    border: `${color.buttonBackgroundSecondary} 2px solid`,
    backgroundColor: color.buttonBackgroundSecondary,
    borderRadius: 6,
    cursor: 'pointer',
  },
  disabledButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 40,
    color: color.secondary400,
    textAlign: 'center',
    fontFamily: 'GT Walsheim',
    fontSize: 15,
    border: `${color.disabled} 2px solid`,
    backgroundColor: color.disabled,
    borderRadius: 6,
  },
  arrowImage: { height: 20, width: 20 },
  arrow: { display: 'flex', justifyContent: 'center' },
  costAmount: {
    fontSize: 16,
    color: color.lightBlue,
  },
  selectFrequency: {
    height: 30,
    width: 130,
  },
  compareContainer: {
    padding: 15,
    backgroundColor: color.actionButton,
    position: 'absolute',
    top: '-54px',
    opacity: 1,
    left: 0,
    right: 0,
    zIndex: 1,
    transition: 'all 0.5s ease 0s',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    margin: 'auto',
    maxWidth: '1000px',
    cursor: 'pointer',
  },
  hideCompare: {
    padding: 15,
    backgroundColor: color.primary,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    opacity: 0,
    zIndex: 1,
    transition: 'all 0.5s ease 0s',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    margin: 'auto',
    maxWidth: '1000px',
  },
  compareWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  compareText: {
    fontFamily: 'GT Walsheim',
    fontSize: 16,
    marginLeft: 15,
    color: color.light,
    marginRight: 'auto',
  },
  pricingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  priceContainer: {},
  estimatedText: {
    fontFamily: 'GT Walsheim',
    fontSize: 14,
    color: color.black38,
  },
  totalContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  total1: {
    fontFamily: 'GT Walsheim',
    fontSize: 30,
    color: color.lightBlue,
  },
  decimal: {
    fontFamily: 'GT Walsheim',
    fontSize: 14,
    color: color.lightBlue,
    position: 'relative',
    top: 3,
    left: 3,
  },
  toggleContainer: {
    display: 'flex',
  },
  toggle: {
    marginLeft: 8,
    width: 80,
    height: 35,
    borderRadius: 5000,
    backgroundColor: color.secondary,
    fontFamily: 'GT Walsheim',
    fontSize: 14,
    color: color.black38,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toggleActive: {
    marginLeft: 8,
    width: 80,
    height: 35,
    borderRadius: 5000,
    backgroundColor: color.fadedBlue,
    fontFamily: 'GT Walsheim',
    fontSize: 14,
    color: color.black87,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export interface CheckoutFooterStyles extends WithStyles<typeof styles> {}

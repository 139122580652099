import * as React from 'react';
import { FormControl, InputLabel, Input, withStyles, FormHelperText } from '@material-ui/core';
import { Styles, AddressFormStyles } from './AddressFormStyles';
interface Props {
  label: string;
  name?: string;
  type?: string;
  errorMessage?: string;
  value: string;
  style?: any;
  disabled?: boolean;
  setFieldValue?: (fieldName: string, fieldValue: string) => void;
  inputProps?: any;
}
type OwnProps = AddressFormStyles & Props;

function inputComponent(props: OwnProps) {
  const { label, name, type, value, classes, style, disabled, errorMessage, inputProps } = props;
  const inputClass = value ? classes.inputLabelStyle : classes.noInputLabelStyle;
  const errorClass = errorMessage ? classes.error : '';
  return (
    <FormControl className={`${classes.inputFormControlStyle} ${style}`}>
      <InputLabel className={`${inputClass} ${errorClass}`}>{label}</InputLabel>
      <Input
        disableUnderline={disabled}
        name={name}
        type={type}
        error={!!errorMessage}
        value={value}
        disabled={disabled}
        inputProps={inputProps}
        onChange={(event) =>
          props.setFieldValue && props.setFieldValue(name as string, event.target.value)
        }
        className={classes.inputStyle}
      />
      {errorMessage && (
        <FormHelperText className={classes.errorMessage}>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
}

export default withStyles(Styles)(inputComponent);

import { color } from '../../../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';
import { AGENCIES, APP } from '../../../../helper/AppNameHelper';

export const styles = createStyles({
  serviceTypeContainer: {
    marginTop: 15,
    backgroundColor: color.light,
    padding: 15,
    borderRadius: 8,
  },
  serviceHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  serviceTypeIconContainer: {
    width: 28,
    height: 28,
  },
  serviceTypeIcon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  serviceTypeTitle: {
    marginRight: 'auto',
    marginLeft: '15px',
    fontFamily: 'GT Walsheim',
    fontSize: '20px',
    color: color.black87,
  },
  planCounter: {
    marginLeft: 'auto',
    fontFamily: 'GT Walsheim',
    fontSize: '14px',
    marginRight: 10,
    color: color.black38,
    opacity: 0.7,
    top: '-1px',
    position: 'relative',
  },
  toggleContainer: {
    width: 26,
    height: 26,
    cursor: 'pointer',
  },
  doneContainer: {
    width: 21,
    height: 21,
    marginRight: 2,
  },
  toggle: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  serviceTypeContent: {
    overflow: 'hidden',
    transition: 'all 0.5s ease-out',
    height: '100%',
  },
  supplierHeader: {
    paddingTop: 25,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  supplierCount: {
    fontFamily: 'GT Walsheim',
    fontSize: '17px',
    color: color.black87,
  },
  supplierLogosContainer: {
    display: 'flex',
  },
  logoContainer: {
    width: '35px',
    height: '35px',
    borderRadius: 5000,
    overflow: 'hidden',
    boxSizing: 'border-box',
    backgroundColor: 'white',
    marginLeft: '-8px',
    position: 'relative',
    cursor: 'pointer',
  },
  logo: {
    width: 'calc(100% + 4px)',
    height: 'calc(100% + 4px)',
    objectFit: 'cover',
    objectPosition: 'center',
    position: 'relative',
    top: '-2px',
    left: '-2px',
  },
  infoText: {
    fontFamily: 'GT Walsheim',
    fontSize: '15px',
    textAlign: 'right',
    color: color.black38,
  },
  plansHeader: {
    paddingTop: 15,
    fontFamily: 'GT Walsheim',
    fontSize: '17px',
    color: color.black87,
    marginBottom: 10,
  },
  popupContainer: {
    backgroundColor: color.secondary,
    height: '100%',
  },
  popupHeader: {
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'start',
    alignItems: 'center',
    paddingLeft: 12,
    paddingRight: 12,
    height: '48px',
    backgroundColor: color.headerColor,
  },
  popupActionContainer: {
    width: 25,
    height: 25,
  },
  popupAction: {
    width: '100%',
    height: '100%',
    objectFix: 'contain',
    objectPosition: 'center',
  },
  paddingNone: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  isMiddlePadding: {
    paddingRight: `150px !important`,
    paddingLeft: `150px !important`,
    transition: 'all 0.5s ease 0s',
    [`@media (max-width: 1000px)`]: {
      paddingLeft: `100px !important`,
      paddingRight: `100px !important`,
    },
    [`@media (max-width: 900px)`]: {
      paddingLeft: `80px !important`,
      paddingRight: `80px !important`,
    },
    [`@media (max-width: 800px)`]: {
      paddingLeft: `60px !important`,
      paddingRight: `60px !important`,
    },
    [`@media (max-width: 600px)`]: {
      paddingLeft: `30px !important`,
      paddingRight: `30px !important`,
    },
    [`@media (max-width: 500px)`]: {
      paddingLeft: `20px !important`,
      paddingRight: `20px !important`,
    },
  },
  isFarLeft: {
    paddingRight: 300,
    transition: 'all 0.5s ease 0s',
    [`@media (max-width: 1000px)`]: {
      paddingRight: 220,
    },
    [`@media (max-width: 900px)`]: {
      paddingRight: 140,
    },
    [`@media (max-width: 800px)`]: {
      paddingRight: 80,
    },
    [`@media (max-width: 600px)`]: {
      paddingRight: 40,
    },
    [`@media (max-width: 500px)`]: {
      paddingRight: 30,
    },
  },
  isFarRight: {
    paddingLeft: 300,
    transition: 'all 0.5s ease 0s',
    [`@media (max-width: 1000px)`]: {
      paddingLeft: 220,
    },
    [`@media (max-width: 900px)`]: {
      paddingLeft: 140,
    },
    [`@media (max-width: 800px)`]: {
      paddingLeft: 80,
    },
    [`@media (max-width: 600px)`]: {
      paddingLeft: 40,
    },
    [`@media (max-width: 500px)`]: {
      paddingLeft: 30,
    },
  },
  gapNone: {
    marginLeft: 0,
    marginRight: 0,
  },
  gapToTheLeft: {
    marginLeft: 15,
  },
  gapToTheRight: {
    marginRight: 15,
  },
});

// At at 1000px screen width, the supplier width is 715px,
// taking into account the 800px - 30px padding, 15px gap and 340px overflow
//

export interface ServiceContainerStyles extends WithStyles<typeof styles> {}

import * as React from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/RootReducers';
import { History } from 'history';
import { RouteComponentProps } from 'react-router-dom';
import { HeaderState, Params } from '../../../store/state/HeaderState';
import Overview from '../../../component/services/commonService/Overview';
import { PlanCommonSummaryActions } from '../../../store/actions/CommonSummaryActions';
import {
  PropertyDetails,
  ElectricityServiceDetails,
  ServiceDetails,
  LookUpDetails,
} from '../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { get } from 'lodash';

interface PropsFromDispatch {
  getCommonQuotes: (
    serviceType: string,
    postcode: string,
    keyIdentifier: string,
    SupplierId: string,
  ) => void;
}

interface PropsFromState {
  property: PropertyDetails;
  propertyList: any; //TODO: Resolve any
  //TODO: Resolve any
  propertyDetail: any;
  accessToken: string | boolean | null;
  keyIdentifier: string;
  params: Params;
  //TODO: Resolve any
  serviceData: any;
  //TODO: Resolve any
  quotes: any;
  //TODO: Resolve any
  state: any;
}

interface PropsFromRoute {
  history: History;
}

type OwnProps = PropsFromState &
  PropsFromDispatch &
  PropsFromRoute &
  RouteComponentProps<{
    propertyId: string;
    addressConfirmed: string;
    serviceName: string;
    providerName: string;
    lookupRequired: string;
  }>;

class OverviewScreen extends React.Component<OwnProps, {}> {
  private waitForAccessToken = 0;
  public constructor(props: OwnProps) {
    super(props);
  }
  public componentDidMount() {
    const { accessToken, getCommonQuotes, property } = this.props;
    const { serviceName } = this.props.match.params;
    if (accessToken && serviceName !== undefined && property) {
      getCommonQuotes(
        get(this.props.propertyList, 'ServiceType', serviceName),
        get(this.props.propertyList, 'Postcode', property.postcode),
        get(this.props.propertyList, 'KeyIdentifier', ''),
        this.props.match.params.providerName,
      );
    }
  }

  public componentDidUpdate() {
    const { serviceName } = this.props.match.params;
    const { accessToken, getCommonQuotes, property } = this.props;
    if (accessToken && this.waitForAccessToken === 0 && serviceName && property) {
      this.waitForAccessToken = 1;
      const { keyIdentifier } = this.props;

      getCommonQuotes(
        get(this.props.propertyList, 'ServiceType', serviceName),
        get(this.props.propertyList, 'Postcode', property.postcode),
        keyIdentifier,
        this.props.match.params.providerName,
      );
    }
  }

  public render() {
    const { quotes } = this.props;
    const { propertyId, serviceName, providerName } = this.props.match.params;
    return (
      <div>
        <Overview
          quotes={get(quotes, 'Response', null)}
          history={history}
          propertyId={propertyId}
          serviceName={serviceName}
          providerName={providerName}
          onBtnClick={this.redirectTo}
        />
      </div>
    );
  }

  public redirectTo = (route: string) => {
    this.props.history.push(route);
  };
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>): PropsFromDispatch {
  return {
    getCommonQuotes: (
      ServiceType: string,
      Postcode: string,
      KeyIdentifier: string,
      SupplierId: string,
    ) =>
      dispatch(
        PlanCommonSummaryActions.getCommonQuoteStart({
          data: { ServiceType, Postcode, KeyIdentifier, SupplierId },
        }),
      ),
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  // const serviceData = state.commonSummary.serviceSignupData[serviceTypes[props.match.params.serviceName]];
  const serviceData = {
    name: 'Clark kent',
    phoneNumber: '69',
  };
  const keyIdentifier: string =
    state.electricityService.service && state.electricityService.service.serviceDetails
      ? (state.electricityService.service.serviceDetails.lookupDetails as LookUpDetails) //TODO: Resolve any
          .KeyIdentifier //TODO: Resolve any
      : state.commonSummary.commonServicePlans.steps
      ? state.commonSummary.commonServicePlans.steps.lookupDetails.KeyIdentifier
      : '';
  return {
    state,
    property: state.commonSummary.commonServiceAddress as PropertyDetails,
    keyIdentifier,
    propertyList: state.commonSummary.commonSummaryAddressLookup,
    propertyDetail: state.commonSummary.createProperty,
    accessToken: state.token.accessToken,
    params: (state.headerParams as HeaderState).params,
    quotes: state.commonSummary.quotes,
    serviceData,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(OverviewScreen);

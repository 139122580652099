import cardIcon from '../../assets/images/payment/credit-card-24-px.svg';
import selectedIcon from '../../assets/images/payment/filled.svg';
import { routes } from '../../Routes';
import { CardType } from './concessionCardForm/ConcessionCardFormConstants';

export const CONCESSION_TYPES = [
  {
    type: "Pensioner's Concession Card",
    icon: cardIcon,
    nextRoute: (propertyId: string) =>
      routes.service.concessionCard.form(propertyId, CardType.pensionerCard),
    selected: selectedIcon,
  },
  {
    type: 'Health Care Card',
    icon: cardIcon,
    nextRoute: (propertyId: string) =>
      routes.service.concessionCard.form(propertyId, CardType.healthCare),
    selected: selectedIcon,
  },
  {
    type: 'Gold Card',
    icon: cardIcon,
    nextRoute: (propertyId: string) =>
      routes.service.concessionCard.form(propertyId, CardType.glodCard),
    selected: selectedIcon,
  },
];
export const TITLE = 'Concession Card';
export const BOTTOM_TEXT =
  'Select one of the concession card options above,terms and conditions may apply';

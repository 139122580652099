import { color } from '../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  root: {
    backgroundColor: color.secondary,
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 48px)',
  },
  body: {
    padding: '12px',
  },
  text: {
    color: color.black87,
    fontSize: '20px',
    fontWeight: 500,
  },
  contentContainer: {
    marginTop: 12,
    backgroundColor: color.light,
    padding: 12,
    borderRadius: 8,
  },
  contentTitle: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: '20px',
    fontWeight: 500,
    marginBottom: 12,
  },
  propertyContainer: {
    display: 'flex',
    backgroundColor: color.light,
    justifyContent: 'space-between',
    padding: '12px',
    alignItems: 'center',
    fontWeight: 600,
    color: color.black87,
    borderRadius: 8,
    border: `${color.borderColor} 2px solid`,
    marginBottom: '12px',
  },
  messageContainer: {
    marginRight: 'auto',
  },
  messageTitle: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: '20px',
    fontWeight: 500,
  },
  messageSubtitle: {
    fontFamily: 'GT Walsheim',
    marginTop: 4,
    color: color.black38,
    fontSize: '14px',
    fontWeight: 500,
  },
  agencyLogo: {
    width: '55px',
    height: '55px',
    borderRadius: 5000,
    backgroundColor: color.light,
    marginLeft: 'auto',
    marginRight: 12,
  },
  houseContainer: {
    width: '55px',
    height: '55px',
  },
  house: {
    width: '100%',
    height: '100%',
    objectPosition: 'center',
    objectFit: 'contain',
  },
  rentLogo: {
    width: '55px',
    height: '55px',
    borderRadius: 5000,
    backgroundColor: color.light,
    marginRight: 12,
  },
  rentTextContainer: {
    marginLeft: 'auto',
  },
  bankLogo: {
    marginLeft: 16,
    marginRight: 28,
  },
  masterLogo: {
    marginLeft: 10,
    marginRight: 24,
  },
  visaLogo: {
    marginLeft: 12,
    marginRight: 24,
  },
  paymentTitle: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: '18px',
    fontWeight: 500,
  },
  rentTitle: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: '20px',
    fontWeight: 500,
    textAlign: 'right',
  },
  rentSubtitle: {
    fontFamily: 'GT Walsheim',
    marginTop: 4,
    color: color.black38,
    fontSize: '14px',
    fontWeight: 500,
    textAlign: 'right',
  },
  rentContainer: {
    display: 'flex',
    backgroundColor: color.light,
    justifyContent: 'space-between',
    padding: '12px',
    alignItems: 'center',
    fontWeight: 600,
    color: color.black87,
    borderRadius: 8,
    border: `${color.borderColor} 2px solid`,
    marginBottom: '12px',
    minHeight: 55,
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: color.light,
    padding: '16px',
    alignItems: 'center',
    fontWeight: 600,
    color: color.black87,
    borderRadius: 8,
    border: `${color.borderColor} 2px solid`,
    marginBottom: '12px',
    minHeight: '40px',
  },
  menuRow: {
    display: 'flex',
    alignItems: 'center',
  },
  extraInfoContainer: {
    backgroundColor: color.light,
    padding: '12px',
    borderRadius: 8,
    border: `${color.borderColor} 2px solid`,
    marginBottom: '12px',
  },
  infoRow: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    'marginBottom': 24,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  itemTitle: {
    fontFamily: 'GT Walsheim',
    color: color.black38,
    fontSize: '16px',
    fontWeight: 500,
  },
  itemValue: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: '14px',
    fontWeight: 500,
    textAlign: 'right',
  },
  iconContainer: {
    width: '26px',
    height: '26px',
    marginRight: '16px',
  },
  iconImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  optionContainer: {
    marginRight: 'auto',
  },
  optionTitle: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: '16px',
    fontWeight: 500,
  },
  optionSubtitle: {
    fontFamily: 'GT Walsheim',
    marginTop: 4,
    color: color.black38,
    fontSize: '13px',
    fontWeight: 500,
  },
  tickContainer: {
    width: '22px',
    height: '22px',
    marginLeft: 'auto',
    marginRight: '12px',
  },
  tick: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  hideItem: {
    display: 'none',
  },
  footerButtonsContainer: {
    bottom: 12,
    left: 12,
    right: 12,
    position: 'fixed',
  },
  footerButton: {
    padding: '16px 16px 16px 16px',
    backgroundColor: color.buttonBackgroundSecondary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.light,
    fontWeight: 600,
    borderRadius: 6,
  },
  footerButtonSecondary: {
    marginTop: 12,
    padding: '16px 16px 16px 16px',
    backgroundColor: color.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.black87,
    fontWeight: 600,
    borderRadius: 6,
    border: `${color.borderColor} 2px solid`,
  },
  disabled: {
    padding: '16px 16px 16px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    borderRadius: 6,
    backgroundColor: color.disabled,
    color: color.black38,
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '3vh',
    paddingTop: 70,
    font: 'GT Walsheim',
  },
  imageBox: {
    display: 'flex',
    justifyContent: 'center',
    width: 64,
    height: 64,
    borderRadius: '50%',
    backgroundColor: color.light,
    position: 'relative',
    marginBottom: -32,
    marginLeft: 19,
  },
  imageStyle: {
    width: 64,
    height: 64,
    borderRadius: '50%',
  },
  card: {
    border: '1px solid #eeeeee',
    backgroundColor: '#ffffff',
    borderRadius: 8,
  },
  cardHeader: {
    backgroundColor: color.secondary,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  propertyImage: {
    height: 45,
  },
  cardBody: {
    padding: 0,
  },
  list: {
    paddingRight: 14,
  },
  primaryTextStyle: {
    color: color.dark,
    opacity: 0.38,
  },
  secondaryTextStyle: {
    color: color.dark,
    opacity: 0.87,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  backButtonStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    height: 40,
  },
  titleStyle: {
    color: color.titleColor,
  },
  paymentProviderLogoStyle: {
    width: 72,
    height: 22,
  },
});

export interface SummaryStyles extends WithStyles<typeof styles> {}

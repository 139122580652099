import * as React from 'react';
import { withStyles, Button, Typography, Drawer } from '@material-ui/core';
import { ConfirmationDrawerStyles, styles } from './ConfirmationDrawerStyle';
import { walletLabels } from '../WalletConstants';

interface PropsFromParent {
  onCancel: () => void;
  onDelete: () => void;
  open: boolean;
}

type OwnProps = PropsFromParent & ConfirmationDrawerStyles;

function confirmationDrawer(props: OwnProps) {
  const { classes } = props;
  return (
    <Drawer
      anchor="bottom"
      open={props.open}
      classes={{
        paper: classes.drawerContiner,
      }}
    >
      <div className={classes.root}>
        <div>
          <Typography className={classes.titleStyle}>{walletLabels.DELETE_DRAWER_TITLE}</Typography>
          <Typography className={classes.subTitleStyle}>
            {walletLabels.DELETE_DRAWER_SUBTITLE}
          </Typography>
        </div>
        <div className={classes.buttonContainer}>
          <Button className={classes.cancelButtonStyle} onClick={props.onCancel}>
            <Typography variant="caption" className={classes.cancelButtonText}>
              {walletLabels.CANCEL_LABEL}
            </Typography>
          </Button>
          <Button className={classes.deleteButtonStyle} onClick={props.onDelete}>
            <Typography variant="caption" className={classes.deleteButtonText}>
              {walletLabels.DELETE_BUTTON}
            </Typography>
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

const confirmationDrawerComponent = withStyles(styles)(confirmationDrawer);
export default confirmationDrawerComponent;

import React, { createRef, FC, useEffect, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { styles, PlanInformationStyles } from './PlanInformationStyles';
import { LABELS } from './PlanInformationConstants';
import {
  AddAndUpdateCartActionRequest,
  DeleteCartItemActionRequest,
  PlanResponse,
  Property,
  ServiceTypes,
  SupplierResponse,
} from '../../models/checkout/Checkout';
import closeIcon from '../../assets/close.png';
import { ApplicationState } from '../../store/RootReducers';
import { Dispatch } from 'redux';
import { CheckoutActions } from '../../store/actions/CheckoutActions';
import { connect } from 'react-redux';
import { removeFromCompare, updateAvailableServicesOnSelect } from '../../helper/CheckoutHelper';
import MainContainer from '../mainContainer/MainContainer';
import downArrow from '../../assets/downArrow.png';
import { sortSections } from './PlanInformationUtils';

interface PlanInformationProps extends PlanInformationStyles {
  plan: PlanResponse;
  serviceType: string;
  supplier: SupplierResponse;
  logo: string;
  accordian?: boolean;
  compare?: boolean;
  closePopup: () => void;
  updateCart: (data: AddAndUpdateCartActionRequest) => void;
  property: Property;
  planRefs?: any;
  updateRefsHeight?: (key: string) => void;
  compareMode?: boolean;
  deleteCartItem: (data: DeleteCartItemActionRequest) => void;
  isTradeRequest: boolean;
}

const PlanInformation: FC<PlanInformationProps> = ({
  classes,
  plan,
  serviceType,
  supplier,
  logo,
  accordian,
  compare,
  closePopup,
  updateCart,
  property,
  planRefs,
  updateRefsHeight,
  compareMode,
  deleteCartItem,
  isTradeRequest,
}) => {
  const planKey = `${plan.title}`;
  const handleTap = () => {
    let updatedAvailableServices = updateAvailableServicesOnSelect(
      !!plan.selected,
      serviceType,
      supplier.providerId,
      plan.productId,
    );
    if (!!plan.selected) {
      deleteCartItem({
        availableServices: updatedAvailableServices,
        deleteCartItemRequest: {
          propertyId: property.id,
          serviceType,
          supplierId: supplier.providerId,
          planId: plan.productId,
        },
      });
    } else {
      if (compare) {
        updatedAvailableServices = removeFromCompare(
          serviceType,
          supplier.providerId,
          plan.productId,
          updatedAvailableServices,
        );
      }
      updateCart({
        availableServices: updatedAvailableServices,
        propertyId: property.id,
      });
    }
    closePopup();
  };

  useEffect(() => {
    document.addEventListener('backbutton', () => closePopup());
    return () => document.removeEventListener('backbutton', () => null);
  }, []);

  return (
    <>
      {!accordian && (
        <div className={classes.popupHeader} style={compareMode ? { marginTop: 20 } : {}}>
          <div className={classes.popupActionContainer} onClick={() => closePopup()}>
            <img className={classes.popupAction} src={closeIcon} alt="closeIcon" />
          </div>
        </div>
      )}
      <div
        className={`${classes.planInformationContainer} ${accordian && classes.noPadding} ${
          compareMode && classes.compareScroll
        }`}
      >
        {!accordian && (
          <div className={classes.section}>
            <div className={classes.planHeader}>
              <div className={classes.logoContainer}>
                <img className={classes.logo} src={logo} alt="logo" />
              </div>
              <div className={classes.titleContainer}>
                <div className={classes.title}>{supplier.name}</div>
                <div className={classes.description}>{plan.title}</div>
              </div>
              <div className={classes.priceContainer}>
                <div className={classes.from}>{plan.pricingType}</div>
                <div className={classes.priceWrapper}>
                  <div className={classes.price}>{`$${plan.price}`}</div>
                  <div className={classes.uom}>{`/${plan.uom}`}</div>
                </div>
              </div>
            </div>
            {plan.subtext && serviceType === ServiceTypes.Electricity && (
              <div
                className={classes.infoText}
                dangerouslySetInnerHTML={{
                  __html: plan.subtext,
                }}
              />
            )}
          </div>
        )}

        {!compareMode ? (
          plan.planInformation.map((section, index) => (
            <div className={`${classes.section} ${accordian && classes.noBorder}`} key={index}>
              <div className={classes.sectionTitle}>{section.sectionTitle}</div>
              {section.content.map((content, idx) => (
                <div className={classes.textContainer} key={idx}>
                  <span className={classes.boldText}>{content.Key} :</span>
                  <div
                    className={classes.regularText}
                    dangerouslySetInnerHTML={{
                      __html: content.Value,
                    }}
                  />
                </div>
              ))}
            </div>
          ))
        ) : (
          <div className={classes.planScroll}>
            {sortSections(plan.planInformation).map((section, index) => (
              <div
                className={classes.card}
                style={index === 0 ? { marginTop: 0 } : {}}
                key={`${planKey}_${section.sectionTitle}_${index}`}
              >
                <div
                  className={`${classes.cardHeaderContainer} ${
                    planRefs[`${planKey}_${section.sectionTitle}`].height === 0 && classes.noBorder
                  } `}
                >
                  {/* add title here */}
                  <div className={classes.cardTitle}>{section.sectionTitle}</div>
                </div>
                <div
                  className={classes.content}
                  ref={planRefs[`${planKey}_${section.sectionTitle}`].ref}
                  style={{
                    maxHeight: `${planRefs[`${planKey}_${section.sectionTitle}`].height}px`,
                    opacity: planRefs[`${planKey}_${section.sectionTitle}`].height > 0 ? 1 : 0,
                    paddingBottom:
                      planRefs[`${planKey}_${section.sectionTitle}`].height > 0 ? 15 : 0,
                  }}
                >
                  {/* add content here */}
                  {section.content.map((content, idx) => (
                    <div className={classes.textContainer} key={idx}>
                      <span className={classes.boldText}>{content.Key} :</span>
                      <div
                        className={classes.regularText}
                        dangerouslySetInnerHTML={{
                          __html: content.Value,
                        }}
                      />
                    </div>
                  ))}
                </div>
                <div
                  className={classes.actionContainer}
                  onClick={() => {
                    if (updateRefsHeight) {
                      updateRefsHeight(section.sectionTitle);
                    }
                  }}
                >
                  <div className={classes.actionTitle}>
                    {planRefs[`${planKey}_${section.sectionTitle}`].height > 0
                      ? LABELS.HIDE
                      : LABELS.SHOW}
                  </div>
                  <div
                    className={`${classes.actionIconContainer} ${
                      planRefs[`${planKey}_${section.sectionTitle}`].height > 0 && classes.rotate
                    }`}
                    onClick={() => {
                      if (updateRefsHeight) {
                        updateRefsHeight(section.sectionTitle);
                      }
                    }}
                  >
                    <img className={classes.actionIcon} src={downArrow} alt="status" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {!accordian && (
          <div
            className={classes.primaryButton}
            style={
              compareMode
                ? { left: 15, right: 15, bottom: 0, position: 'absolute', width: 'unset' }
                : {}
            }
            onClick={() => handleTap()}
          >
            {plan.selected ? LABELS.REMOVE : LABELS.ADD}
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  property: state.checkout.property,
  isTradeRequest: state.checkout.isTradeRequest,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCart: (data: AddAndUpdateCartActionRequest) => dispatch(CheckoutActions.updateCart(data)),
  deleteCartItem: (data: DeleteCartItemActionRequest) =>
    dispatch(CheckoutActions.deleteCartItem(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PlanInformation));

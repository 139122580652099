/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useEffect, useMemo } from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducers';
import { RentServiceActions } from '../../store/actions/RentServiceActions';
import { connect } from 'react-redux';
import { GetRentServiceResponse } from '../../services/rentService/getRentService/GetRentServiceResponse.data';
import { routes } from '../../Routes';
import { styles, WelcomeScreenStyles } from './WelcomeScreenStyles';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';
import { rentOptOut } from '../../Constants';
import {
  bondPaymentEnabledSteps,
  LABELS,
  naxWelcomeSteps,
  paymentDisabledSteps,
  paymentEnabledSteps,
  StepItem,
  taWelcomeSteps,
} from './WelcomeScreenConstants';
import { AGENCIES, APP } from '../../helper/AppNameHelper';
import { fetchAgencyLogo, fetchAgencyName } from '../../helper/AgencyLogoHelper';
import NavBar from '../../component/navBar/NavBar';
import Powered from '../../assets/Powered-by-Sorted.svg';
import { CheckoutActions } from '../../store/actions/CheckoutActions';
import { AvailableServicesRequest } from '../../models/checkout/Checkout';
import MainContainer from '../../component/mainContainer/MainContainer';
import { useGetLeaseData } from '../../helper/RentServiceHelper';

interface WelcomeScreenProps extends WelcomeScreenStyles, RouteComponentProps<{}> {
  accessToken: string;
  getRentService: (onSuccess: (response: GetRentServiceResponse[]) => void) => void;
  getAvailableServices: (data: AvailableServicesRequest) => void;
}

const WelcomeScreen: FC<WelcomeScreenProps> = ({
  classes,
  history,
  accessToken,
  getRentService,
  getAvailableServices,
}) => {
  const leaseObj = useGetLeaseData();
  const lease = {
    initialPayments: leaseObj && leaseObj.initialPayments,
    paymentEnabled: leaseObj && leaseObj.paymentEnabled,
    lease: leaseObj,
  };
  const stepList =
    APP === AGENCIES.SORTED
      ? lease.initialPayments && lease.initialPayments.length
        ? bondPaymentEnabledSteps
        : lease.paymentEnabled
        ? paymentEnabledSteps
        : paymentDisabledSteps
      : APP === AGENCIES.NAX
      ? naxWelcomeSteps
      : taWelcomeSteps;

  useEffect(() => {
    if (accessToken && leaseObj === undefined) {
      getRentService((response) => null);
    }
  }, [accessToken]);

  useEffect(() => {
    if (leaseObj) {
      getAvailableServices({
        propertyId: `${leaseObj.property!.id}`,
        hideLoading: true,
        requiredServiceTypes: [],
      });
    }
  }, [leaseObj]);

  useEffect(() => {
    return () => {
      if (!lease.paymentEnabled) {
        sendMessageToApp(ValidMessages.intercomTagging, rentOptOut);
      }
    };
  }, []);

  return (
    <div className={classes.root}>
      <NavBar subTitle={LABELS.NAV_TITLE} subText={LABELS.NAV_SUBTITLE} />
      <MainContainer>
        <div className={classes.body}>
          <div className={classes.agencyContainer}>
            <img
              className={classes.agencyLogo}
              onError={(e) => {
                (e.target as HTMLImageElement).src = fetchAgencyLogo('SORTED')!;
              }}
              src={
                lease.lease && !!lease.lease.agency.logoUrl
                  ? lease.lease.agency.logoUrl
                  : fetchAgencyLogo(lease.lease ? lease.lease.agency.code : 'SORTED')
              }
            />
            <div className={classes.messageContainer}>
              <div className={classes.messageTitle}>
                {lease.lease ? lease.lease.agency.tradingName : LABELS.WELCOME}
              </div>
              <div className={classes.messageSubtitle}>
                {lease.lease ? LABELS.WELCOMES : LABELS.LIFE_MADE}
              </div>
            </div>
          </div>

          <div className={classes.stepContainerStyle}>
            {stepList.map((step: StepItem, index: number) => (
              <div className={classes.listItem} key={index}>
                <img className={classes.listIcon} src={step.logo} />
                <div className={classes.listInfoBox}>
                  <Typography className={classes.listTitle}>{step.title}</Typography>
                  <Typography className={classes.listMessage}>{step.message}</Typography>
                </div>
              </div>
            ))}
          </div>
          {APP !== AGENCIES.SORTED && (
            <div className={classes.poweredContainer}>
              <img src={Powered} className={classes.poweredLogo} />
            </div>
          )}
          <div className={classes.footerButtonsContainer}>
            <MainContainer>
              <div
                className={classes.footerButton}
                onClick={() => history.push(routes.property.new)}
              >
                Get started
              </div>
            </MainContainer>
          </div>
        </div>
      </MainContainer>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    accessToken: state.token.accessToken as string,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAvailableServices: (data: AvailableServicesRequest) =>
    dispatch(CheckoutActions.getAvailableServicesStart(data)),
  getRentService: (onSuccess: (response: GetRentServiceResponse[]) => void) => {
    dispatch(RentServiceActions.getRentServiceStart({ onSuccess }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(WelcomeScreen)));

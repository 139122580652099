import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { SessionTokenResponse } from '../../models/auth/SessionToken';

export class TokenService {
  // private readonly service: ApiServiceBase = new ApiService(ServiceType.checkout);
  private readonly tokenService: ApiServiceBase = new ApiService(ServiceType.token);

  public getSessionToken(token: string): Promise<SessionTokenResponse> | SessionTokenResponse {
    return this.tokenService.get<SessionTokenResponse>({
      query: {
        token,
      },
    });
  }
}

const tokenService = new TokenService();
export default tokenService;

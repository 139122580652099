import * as React from 'react';
import { Button, Typography, withStyles } from '@material-ui/core';
import { Styles, PlanButtonStyles } from './PlanButtonStyles';
import ICON_HELP from '../../../assets/help.png';

interface PropsFromParent {
  options: string[];
  label?: string;
  showHint?: boolean;
  onHintClick?: () => void;
  info?: string | string[];
  selected?: number;
  onChange: (option: number) => void;
}

type OwnProps = PropsFromParent & PlanButtonStyles;

interface State {
  selected: number;
}

class PlanButton extends React.Component<OwnProps, State> {
  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      selected: this.props.selected || 0,
    };
  }

  public render() {
    const { classes, label, info, showHint, options } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.horizontalBox}>
          <Typography className={classes.label} variant="subtitle1">
            {label}
          </Typography>
          {showHint && <img src={ICON_HELP} onClick={this.handleIconClick} />}
        </div>
        {options.length > 0 && <div className={classes.buttonBox}>{this.renderButton()}</div>}
        {Array.isArray(info) ? (
          this.renderInfoList(info)
        ) : (
          <Typography className={classes.info} variant="body2">
            {info}
          </Typography>
        )}
      </div>
    );
  }

  public renderInfoList(list: string[]) {
    const { classes } = this.props;
    return list.map((info: string, index: number) => (
      <Typography className={index === 0 ? classes.info : classes.infoList} variant="body2">
        {info}
      </Typography>
    ));
  }

  public renderButton() {
    const { classes, options } = this.props;
    return options.map((option: string, index: number) => (
      <Button
        className={`${this.toggleButtonStyle(index)} ${classes.button}`}
        key={index}
        variant="outlined"
        onClick={() => this.handleClick(index)}
      >
        <Typography
          className={`${this.toggleButtonTextStyle(index)} 
                      ${classes.buttonText}`}
        >
          {option}
        </Typography>
      </Button>
    ));
  }

  private handleClick = (index: number) => {
    this.setState({ selected: index });
    this.props.onChange(index);
  };

  private handleIconClick = () => {
    if (this.props.onHintClick) {
      this.props.onHintClick();
    }
  };

  private toggleButtonStyle = (index: number) => {
    const { classes } = this.props;
    return index === this.state.selected ? classes.buttonSelected : classes.buttonUnselected;
  };

  private toggleButtonTextStyle = (index: number) => {
    const { classes } = this.props;
    return index === this.state.selected
      ? classes.buttonTextSelected
      : classes.buttonTextUnselected;
  };
}

export default withStyles(Styles)(PlanButton);

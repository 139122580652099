import * as React from 'react';
import { Typography, withStyles, Drawer, Button, Divider } from '@material-ui/core';
import PlanButton from '../PlanButtons';
import PlanFooter from '../PlanFooter';
import { SUPPORT_LABELS, SUPPORT_MODAL_DATA } from './Constants';
import { Styles, PlanScreenStyles } from './Styles';
import { routes } from '../../../../Routes';
import { PlanDetails } from '../../../../models/plan/electricity/getPlanDetails/PlanDetails.data';

export interface FooterData {
  amount: number;
  billingInfo?: string;
  extraInfo?: string;
}

interface PropsFromRoute {
  readonly history?: any;
}

interface PropsFromParent {
  readonly handleNext: (choice: string) => void;
  readonly footerData: FooterData;
  planDetails: PlanDetails;
}

interface PlanState {
  choice: number;
  showModal: boolean;
}

type OwnProps = PlanScreenStyles & PropsFromRoute & PropsFromParent;

class SupportScreen extends React.Component<OwnProps, PlanState> {
  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      choice: SUPPORT_LABELS.options.indexOf('no'),
      showModal: false,
    };
  }

  public render() {
    const { classes, footerData } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <div className={classes.top}>
            <Typography className={classes.title} variant="h5">
              {SUPPORT_LABELS.title}
            </Typography>
            <PlanButton
              options={SUPPORT_LABELS.options}
              label={SUPPORT_LABELS.buttonsHeading}
              info={SUPPORT_LABELS.planInfo}
              showHint
              selected={SUPPORT_LABELS.options.indexOf('no')}
              onChange={this.handleOptionChange}
              onHintClick={this.handleHelpClick}
            />
          </div>
          <PlanFooter amount={footerData.amount} onButtonClick={this.handleFooterClick} />
        </div>
        {this.renderModal()}
      </div>
    );
  }

  private handleOptionChange = (choice: number) => {
    this.setState({ choice });
  };

  private handleFooterClick = () => {
    this.props.history.push(
      routes.service.plan.concession((this.props as any).match.params.propertyId),
    );
    this.props.handleNext(SUPPORT_LABELS.options[this.state.choice]);
  };

  private handleHelpClick = () => {
    this.toggleModal(true);
  };

  private toggleModal = (toggle: boolean) => {
    this.setState({ showModal: toggle });
  };

  private renderModal = () => {
    const { classes } = this.props;
    return (
      <Drawer
        anchor="bottom"
        open={this.state.showModal}
        classes={{
          paper: classes.bottomDrawerContainer,
        }}
        onClose={this.toggleModal.bind(this, false)}
      >
        <div className={classes.bottomDrawer}>
          <Typography className={classes.bottomDrawerTitle}>
            {SUPPORT_LABELS.drawerTitle}
          </Typography>
          <Divider className={classes.divider} />
          <Typography className={classes.terms}>{SUPPORT_MODAL_DATA.firstPara}</Typography>
          {this.renderModalList(SUPPORT_MODAL_DATA.list)}
          <Typography className={classes.terms}>{SUPPORT_MODAL_DATA.endPara}</Typography>
          <Button
            fullWidth
            onClick={this.toggleModal.bind(this, false)}
            className={classes.drawerButtons}
          >
            <Typography variant="body2">{SUPPORT_LABELS.drawerButton}</Typography>
          </Button>
        </div>
      </Drawer>
    );
  };

  private renderModalList = (data: string[]) => {
    const { classes } = this.props;
    return data.map((instruction: string, index: number) => (
      <Typography key={index} className={classes.termsList}>
        {instruction}
      </Typography>
    ));
  };
}

export default withStyles(Styles)(SupportScreen);

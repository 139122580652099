import { withStyles, Typography, MobileStepper } from '@material-ui/core';
import React, { FC, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import {
  AddressComponents,
  OnboardingPropertyDetails,
  PostRequest,
  SetOnboardingPropertyDetails,
} from '../../models/property/Property.data';
import { PropertyActions } from '../../store/actions/PropertyOnboardingAction';
import { ApplicationState } from '../../store/RootReducers';
import SettingUpProperty from './components/SettingUpProperty/SettingUpProperty';
import Summary from './components/Summary/Summary';
import { getNavText, PropertyOnboardingStep } from './PropertyOnboardingConstants';
import { styles, PropertyOnboardingStyles } from './PropertyOnboardingStyles';
import { checkAddress, getBtnStatus, getOnboardingStep } from './PropertyOnboardingUtils';
import Footer from '../../component/footer/FooterComponent';
import ManualEntry from './components/ManualEntry/ManualEntry';
import AddProperty from './components/AddProperty/AddProperty';
import SelectPropertyType from './components/SelectPropertyType/SelectPropertyType';
import SelectRole from './components/SelectRole/SelectRole';
import NavBar from '../../component/navBar/NavBar';
import houseAvatar from '../../assets/houses/house3.png';

interface PropertyOnboardingProps
  extends PropertyOnboardingStyles,
    RouteComponentProps<{ pageId: string }> {
  propertyOnboardingState: OnboardingPropertyDetails;
  setPropertyDetails: (data: SetOnboardingPropertyDetails) => void;
  postProperty: (data: PostRequest) => void;
}

const PropertyOnboarding: FC<PropertyOnboardingProps> = ({
  history,
  match,
  classes,
  propertyOnboardingState,
  setPropertyDetails,
  postProperty,
}) => {
  const onboardingStep = useMemo<PropertyOnboardingStep>(() => {
    return getOnboardingStep(history, match.params.pageId);
  }, [match.params.pageId]);

  const nextStep = (searchedAddress?: AddressComponents) => {
    if (!getBtnStatus(onboardingStep, propertyOnboardingState)) {
      switch (onboardingStep) {
        case PropertyOnboardingStep.AddProperty:
          if (searchedAddress) {
            checkAddress(history, searchedAddress);
          }
          break;
        case PropertyOnboardingStep.SelectPropertyType:
          history.push(`/onboarding/propertyOnboard/2`);
          break;
        case PropertyOnboardingStep.SelectRole:
          history.push(`/onboarding/propertyOnboard/4`);
          break;
        case PropertyOnboardingStep.ManualEntry:
          history.push(`/onboarding/propertyOnboard/1`);
          break;
        case PropertyOnboardingStep.Summary:
          postProperty(propertyOnboardingState as any);
          history.push(`/onboarding/propertyOnboard/loading`);
          break;
        default:
          history.push(`/onboarding/propertyOnboard/loading`);
          break;
      }
    }
  };

  const renderStep = () => {
    switch (onboardingStep) {
      case PropertyOnboardingStep.AddProperty:
        return (
          <AddProperty
            history={history}
            setPropertyDetails={(data: SetOnboardingPropertyDetails) => {
              setPropertyDetails(data);
              nextStep(data.value as AddressComponents);
            }}
            goToNextPage={() => null}
          />
        );
      case PropertyOnboardingStep.SelectPropertyType:
        return (
          <SelectPropertyType
            propertyOnboardingState={propertyOnboardingState}
            setPropertyDetails={(data: SetOnboardingPropertyDetails) => setPropertyDetails(data)}
          />
        );
      case PropertyOnboardingStep.SelectRole:
        return (
          <SelectRole
            propertyOnboardingState={propertyOnboardingState}
            setPropertyDetails={(data: SetOnboardingPropertyDetails) => setPropertyDetails(data)}
          />
        );
      case PropertyOnboardingStep.ManualEntry:
        return (
          <ManualEntry
            history={history}
            propertyOnboardingState={propertyOnboardingState}
            setPropertyDetails={(data: SetOnboardingPropertyDetails) => setPropertyDetails(data)}
            goToNextPage={() => nextStep()}
          />
        );
      case PropertyOnboardingStep.Summary:
        return <Summary propertyOnboardingState={propertyOnboardingState} />;
      case PropertyOnboardingStep.Loading:
        return <SettingUpProperty />;
      default:
        return <SettingUpProperty />;
    }
  };

  return (
    <>
      {onboardingStep !== PropertyOnboardingStep.Loading && (
        <NavBar
          subTitle={getNavText(onboardingStep).title}
          subText={getNavText(onboardingStep).subtitle}
          showRightContent
        >
          <div className={classes.houseNavContainer}>
            <img src={houseAvatar} className={classes.houseImage} />
          </div>
        </NavBar>
      )}
      {renderStep()}
      {![PropertyOnboardingStep.Loading, PropertyOnboardingStep.ManualEntry].includes(
        onboardingStep,
      ) && (
        <div className={classes.footerButtonsContainer}>
          <div
            className={`${
              getBtnStatus(onboardingStep, propertyOnboardingState)
                ? classes.disabled
                : classes.footerButton
            }`}
            onClick={() => nextStep()}
          >
            {onboardingStep === PropertyOnboardingStep.Summary ? "That's it" : 'Next'}
          </div>
          {onboardingStep === PropertyOnboardingStep.Summary && (
            <div
              className={classes.footerButtonSecondary}
              onClick={() => history.push(`/onboarding/propertyOnboard/0`)}
            >
              Back to search
            </div>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  propertyOnboardingState: state.propertyOnboarding.propertyOnboardingState,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setPropertyDetails: (data: SetOnboardingPropertyDetails) =>
    dispatch(PropertyActions.setPropertyDetails(data)),
  postProperty: (data: PostRequest) => dispatch(PropertyActions.postPropertyRequest(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(PropertyOnboarding)));

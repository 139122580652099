import { color } from '../../../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  planContainer: {
    border: `${color.borderColor} 2px solid`,
    backgroundColor: color.light,
    borderRadius: 6,
    marginTop: 15,
    overflow: 'hidden',
  },
  activePlan: {
    border: `${color.lightBlue} 2px solid`,
    backgroundColor: color.fadedBlue,
  },
  planContent: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  planHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 15,
    paddingRight: 15,
  },
  logoContainer: {
    height: '40px',
    width: '40px',
    borderRadius: 5000,
    overflow: 'hidden',
  },
  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  titleContainer: {
    marginLeft: 15,
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    paddingBottom: 3,
  },
  title: {
    fontFamily: 'GT Walsheim',
    fontSize: '13px',
    color: color.black38,
    textTransform: 'uppercase',
  },
  description: {
    fontFamily: 'GT Walsheim',
    fontSize: '17px',
    color: color.black87,
  },
  priceContainer: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  from: {
    fontFamily: 'GT Walsheim',
    fontSize: '14px',
    color: color.black38,
    opacity: 0.6,
  },
  priceWrapper: {
    display: 'flex',
    alignItems: 'baseline',
  },
  price: {
    fontFamily: 'GT Walsheim',
    fontWeight: 500,
    fontSize: '20px',
    color: color.lightBlue,
  },
  uom: {
    fontFamily: 'GT Walsheim',
    fontSize: '12px',
    color: color.lightBlue,
  },
  infoText: {
    fontFamily: 'GT Walsheim',
    fontSize: '11px',
    color: color.black38,
    marginTop: 15,
    paddingLeft: 15,
    paddingRight: 15,
    opacity: 0.6,
    lineHeight: 1.5,
  },
  actionContainer: {
    backgroundColor: color.secondary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 15,
    paddingTop: 10,
    paddingBottom: 10,
    borderTop: `${color.borderColor} 2px solid`,
    cursor: 'pointer',
  },
  actionContainerSelected: {
    borderTop: `rgba(211, 211, 211, 0.6) 2px solid`,
    backgroundColor: 'unset',
    cursor: 'pointer',
  },
  actionText: {
    fontFamily: 'GT Walsheim',
    fontSize: '16px',
    color: color.black87,
    marginLeft: 'auto',
  },
  actionIconContainer: {
    width: 24,
    height: 24,
    marginLeft: '15px',
  },
  actionIcon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  tick: {
    width: 20,
    height: 20,
    marginLeft: 15,
  },
  compareContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontFamily: 'GT Walsheim',
    color: color.black87,
    padding: '5px 10px',
    backgroundColor: color.light,
    borderRadius: 6,
    border: `${color.borderColor} 2px solid`,
    cursor: 'pointer',
  },
  activeCompare: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontFamily: 'GT Walsheim',
    color: color.light,
    padding: '5px 10px',
    backgroundColor: color.actionButton,
    borderRadius: 6,
    border: `${color.borderColor} 2px solid`,
    cursor: 'pointer',
  },
});

export interface PlanStyles extends WithStyles<typeof styles> {}

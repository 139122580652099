import { color } from '../../../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  contentContainer: {
    padding: '12px',
  },
  summaryCard: {
    backgroundColor: color.light,
    padding: '16px',
    fontWeight: 600,
    color: color.black87,
    borderRadius: 8,
    border: `${color.borderColor} 2px solid`,
    marginBottom: '12px',
  },
  address: {
    fontSize: 22,
    fontWeight: 500,
    color: color.black87,
    fontFamily: 'GT Walsheim',
  },
  address2: {
    marginTop: 6,
    fontSize: 15,
    fontWeight: 500,
    color: '#b9b9b9',
    fontFamily: 'GT Walsheim',
  },
});

export interface SummaryStyles extends WithStyles<typeof styles> {}

import { StoreAction } from '../StoreHelper';
import { GetPaymentMethodsResponse } from '../../services/payment/getPaymentMethods/GetPaymentMethods.data';

export enum WalletActionTypes {
  GetPaymentMethodsStart = 'GET_PAYMENT_METHODS_START',
  GetPaymentMethodsSuccess = 'GET_PAYMENT_METHODS_SUCCESS',
  GetPaymentMethodsError = 'GET_PAYMENT_METHODS_ERROR',
  SaveSelectedPayment = 'SAVE_SELECTED_PAYMENT',
  DeletePaymentStart = 'DELETE_PAYMENT_START',
  DeletePaymentSuccess = 'DELETE_PAYMENT_SUCCESS',
  DeletePaymenError = 'DELETE_PAYMENT_ERROR',
  SaveHeaderLabel = 'SAVE_HEADER_LABEL',
  ShowSuccessMessage = 'SHOW_SUCCESS_MESSAGE',
  HideSuccessMessage = 'HIDE_SUCCESS_MESSAGE',
}

export interface WalletErrorPayload {
  readonly error: Error | any;
}

export interface DeletePaymentRequest {
  paymentRefId: string;
  onSuccess: (response: string[]) => void;
}

export type WalletPayload =
  | string
  | DeletePaymentRequest
  | string[]
  | GetPaymentMethodsResponse[]
  | GetPaymentMethodsResponse
  | WalletErrorPayload;

export type WalletAction = StoreAction<WalletActionTypes, WalletPayload>;

export class WalletActions {
  public static getPaymentMethodsStart(): WalletAction {
    return {
      type: WalletActionTypes.GetPaymentMethodsStart,
      data: null,
    };
  }
  public static getPaymentMethodsSuccess(data: GetPaymentMethodsResponse[]): WalletAction {
    return {
      type: WalletActionTypes.GetPaymentMethodsSuccess,
      data,
    };
  }
  public static getPaymentMethodsError(data: WalletErrorPayload): WalletAction {
    return {
      type: WalletActionTypes.GetPaymentMethodsError,
      data,
    };
  }
  public static saveSelectedPayment(data: GetPaymentMethodsResponse): WalletAction {
    return {
      type: WalletActionTypes.SaveSelectedPayment,
      data,
    };
  }
  public static deletePaymentStart(data: DeletePaymentRequest): WalletAction {
    return {
      type: WalletActionTypes.DeletePaymentStart,
      data,
    };
  }
  public static deletePaymentSuccess(data: string[]): WalletAction {
    return {
      type: WalletActionTypes.DeletePaymentSuccess,
      data,
    };
  }
  public static deletePaymentError(data: WalletErrorPayload): WalletAction {
    return {
      type: WalletActionTypes.GetPaymentMethodsError,
      data,
    };
  }
  public static saveHeaderLabel(data: string): WalletAction {
    return {
      type: WalletActionTypes.SaveHeaderLabel,
      data,
    };
  }
  public static showSuccessMessage(): WalletAction {
    return {
      type: WalletActionTypes.ShowSuccessMessage,
      data: null,
    };
  }
  public static hideSuccessMessage(): WalletAction {
    return {
      type: WalletActionTypes.HideSuccessMessage,
      data: null,
    };
  }
}

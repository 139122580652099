import * as React from 'react';
import {
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  withStyles,
  Button,
  MuiThemeProvider,
} from '@material-ui/core';
import LOGO_HOME from '../../../../assets/home.svg';
import FooterComponent from '../../../footer/FooterComponent';
import {
  ServiceDetails,
  ConnectionDateDetails,
  PropertyDetails,
} from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { PhotoLink } from '../../../../services/lease/getLease/GetLeaseResponse.data';
import { DateConfirmationStyles, Styles, customThemeDatePicker } from './DateConfirmationStyle';
import { LABELS, DATE_FORMAT } from './DateConfirmationConstants';
import { parseInitialDate } from '../../../../helper/DateFormatter';
import {
  connectionDateValidationHelper,
  leaveFilter,
  DateValidationHelper,
} from '../../../../helper/MovingDateValidationHelper';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Leaves } from '../../../../models/Leaves.data';
interface OwnState {
  selectedDate: Date | string | null;
}

interface PropsFromParent {
  handleIAlreadyLiveHere: (connectionDate: string | Date) => void;
  handleNext: (connectionDate: string | Date) => void;
  serviceDetails: ServiceDetails;
  leaveList: Leaves[];
  state: string;
}

type OwnProps = DateConfirmationStyles & PropsFromParent;

class DateConfirmation extends React.Component<OwnProps, OwnState> {
  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      selectedDate: DateValidationHelper(
        parseInitialDate(
          (this.props.serviceDetails.connectionDateDetails as ConnectionDateDetails)
            .connectionDate as string,
        ) as Date,
        leaveFilter(this.props.state, this.props.leaveList) as string[],
      ),
    };
  }

  public render() {
    const { classes, serviceDetails, leaveList, state } = this.props;
    const { selectedDate } = this.state;
    const property = serviceDetails && (serviceDetails.property as PropertyDetails);
    const minDate = new Date();
    minDate.setHours(0, 0, 0, 0);
    const leaveListFiltered = leaveFilter(state, leaveList);
    const dateShown = DateValidationHelper(selectedDate as Date, leaveListFiltered as string[]);
    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <Typography variant="h5">{LABELS.movingIn}</Typography>
          <div className={classes.addressBoxStyle}>
            <div className={classes.imageBoxStyle}>
              <img src={this.handlePropertyLogo()} className={classes.imageStyle} />
            </div>
            <Card className={classes.cardStyle}>
              <CardContent className={classes.cardContentStyle}>
                <div className={classes.cardHeaderStyle} />
                <List className={classes.listStyle}>
                  <ListItem className={classes.listItemStyle}>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography variant="overline" className={classes.primaryTextStyle}>
                          {LABELS.address}
                        </Typography>
                      }
                      secondary={
                        <Typography variant="subtitle1" className={classes.secondaryTextStyle}>
                          {property.address}
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
            <div className={classes.dateContainer}>
              <MuiThemeProvider theme={customThemeDatePicker}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    label="Moving / connection date"
                    value={dateShown}
                    onChange={(value) => this.setState({ selectedDate: value })}
                    minDate={minDate}
                    format={DATE_FORMAT}
                    animateYearScrolling
                    disableOpenOnEnter
                    className={classes.datePickerStyle}
                    shouldDisableDate={(date) =>
                      connectionDateValidationHelper(date, leaveListFiltered as string[])
                    }
                  />
                </MuiPickersUtilsProvider>
              </MuiThemeProvider>
              <Typography variant="body2" className={classes.text}>
                {LABELS.connectionInfo}
              </Typography>
              <Typography variant="body2" className={classes.text}>
                {LABELS.connectionInfo2}
              </Typography>
              <Typography variant="body2" className={classes.text}>
                {LABELS.message}
              </Typography>
            </div>
          </div>
        </div>
        <FooterComponent buttonText="NEXT" onClick={this.handleSubmit}>
          <div className={classes.footer}>
            <Button
              variant="outlined"
              onClick={this.handleIAlreadyLiveHere}
              className={classes.button}
            >
              {LABELS.button}
            </Button>
          </div>
        </FooterComponent>
      </div>
    );
  }

  public handlePropertyLogo = () => {
    const { serviceDetails } = this.props;
    const property = serviceDetails.property as PropertyDetails;
    const { photos } = property;
    return (photos && photos.length !== 0 && photos[0].link) || LOGO_HOME;
  };

  private handleIAlreadyLiveHere = () => {
    this.props.handleIAlreadyLiveHere(this.state.selectedDate as Date);
  };

  private handleSubmit = () => {
    this.props.handleNext(this.state.selectedDate as Date);
  };
}

export default withStyles(Styles)(DateConfirmation);

/* eslint-disable no-case-declarations */
import { PaymentState } from '../state/PaymentState';
import {
  PaymentAction,
  PaymentActionTypes,
  SaveSelectedPaymentMethodPayload,
} from '../actions/PaymentActions';
import { GetPaymentTypeResponse } from '../../services/payment/getPaymentTypes/GetPaymentTypesResponse.data';
import { PostPaymentUUIDResponse } from '../../services/payment/postPaymentUUID/PostPaymentUUIDResponse.data';
import { GetPaymentMethodsResponse } from '../../services/payment/getPaymentMethods/GetPaymentMethods.data';
import { GetPaymentAuthKey } from '../../services/payment/getPaymentAuthKey/GetPaymentAuthKey.data';
import { PostPaymentTokenResponse } from '../../services/payment/postPaymentToken/PostPyamentToken.data';
import { GetFastPayResponse } from '../../services/payment/getFastPayIDResponse/GetFastPayIdResponse';
import { CheckoutStripeToken } from '../../services/payment/stripeCheckout/StripeCheckout';

export const initialState: PaymentState = {
  paymentTypes: [],
  loading: false,
  savedPaymentTypes: [],
  paymentMethods: [],
  selectedPaymentMethod: {
    paymentMethodId: '',
    maskedDetails: {
      paymentDetails: '',
      logo: '',
    },
  },
  paymentAuthKey: {
    authKey: '',
    status: 1,
  },
  postPaymentTokenReponse: null,
  paymentPayId: null,
  stripeCheckoutTokens: [],
};

export function paymentReducer(state = initialState, action: PaymentAction) {
  state = getPaymentTypesReducer(state, action);
  state = postPaymentTypesReducer(state, action);
  state = getPaymentMethodsReducer(state, action);
  state = getPaymentAuthKeyReducer(state, action);
  state = postPaymentTokenReducer(state, action);
  state = stripeCheckoutReducer(state, action);

  return state;
}

function getPaymentTypesReducer(state = initialState, action: PaymentAction) {
  switch (action.type) {
    case PaymentActionTypes.GetPaymentTypesStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case PaymentActionTypes.GetPaymentTypesSuccess:
      return {
        ...state,
        loading: false,
        paymentTypes: action.data as GetPaymentTypeResponse[],
      };
    case PaymentActionTypes.SaveSelectedPaymentMethod:
      return {
        ...state,
        selectedPaymentMethod: action.data as SaveSelectedPaymentMethodPayload,
      };
    default:
      return state;
  }
}

function getPaymentMethodsReducer(state = initialState, action: PaymentAction) {
  switch (action.type) {
    case PaymentActionTypes.GetPaymentMethodsStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case PaymentActionTypes.GetPaymentMethodsSuccess:
      return {
        ...state,
        loading: false,
        paymentMethods: action.data as GetPaymentMethodsResponse[],
      };
    case PaymentActionTypes.GetPaymentPayIdSuccess:
      return {
        ...state,
        loading: false,
        paymentPayId: action.data as GetFastPayResponse,
      };
    default:
      return state;
  }
}

function postPaymentTypesReducer(state = initialState, action: PaymentAction) {
  switch (action.type) {
    case PaymentActionTypes.PostPaymentMethodUUIDStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case PaymentActionTypes.PostPaymentMethodUUIDSuccess:
      const { paymentMethodResponse, paymentMethodUUID, user, id } =
        action.data as PostPaymentUUIDResponse;
      return {
        ...state,
        loading: false,
        savedPaymentTypes: [
          ...state.savedPaymentTypes,
          {
            paymentMethodUUID,
            paymentMethodResponse,
            user,
            id,
          },
        ],
      };
    default:
      return state;
  }
}

function getPaymentAuthKeyReducer(state = initialState, action: PaymentAction) {
  switch (action.type) {
    case PaymentActionTypes.GetPaymentAuthKeyStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case PaymentActionTypes.GetPaymentAuthKeySuccess:
      return {
        ...state,
        loading: false,
        paymentAuthKey: action.data as GetPaymentAuthKey,
      };
    case PaymentActionTypes.GetPaymentAuthKeyError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    case PaymentActionTypes.GetPaymentPayIdError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    default:
      return state;
  }
}

function postPaymentTokenReducer(state = initialState, action: PaymentAction) {
  switch (action.type) {
    case PaymentActionTypes.PostPaymentTokenStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case PaymentActionTypes.PostPaymentTokenSuccess:
      return {
        ...state,
        loading: false,
        postPaymentTokenReponse: action.data as PostPaymentTokenResponse,
      };
    case PaymentActionTypes.RequestFastPayIdDetails:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case PaymentActionTypes.PostPaymentTokenError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    default:
      return state;
  }
}

function stripeCheckoutReducer(state = initialState, action: PaymentAction) {
  switch (action.type) {
    case PaymentActionTypes.GET_STRIPE_CHECKOUT_TOKEN_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case PaymentActionTypes.GET_STRIPE_CHECKOUT_TOKEN_SUCCESS:
      const result =
        state.stripeCheckoutTokens !== null
          ? state.stripeCheckoutTokens.findIndex(
              (el) => el.productId === (action.data as CheckoutStripeToken).productId,
            )
          : -1;
      return {
        ...state,
        loading: false,
        stripeCheckoutTokens:
          result === -1
            ? [...state.stripeCheckoutTokens, action.data as CheckoutStripeToken]
            : state.stripeCheckoutTokens.map((el, idx) =>
                idx === result ? (action.data as CheckoutStripeToken) : el,
              ),
      };
    case PaymentActionTypes.GET_STRIPE_CHECKOUT_TOKEN_ERROR:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    default:
      return state;
  }
}

export class TypeHelper {
  public static arrayReplaceAt<T>(array: T[], index: number, newItem: T): T[] {
    if (!array) {
      return [];
    } else if (index < 0 || index >= array.length) {
      return array;
    } else {
      return [...array.slice(0, index), newItem, ...array.slice(index + 1)];
    }
  }
}

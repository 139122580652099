import React, { FC, useMemo } from 'react';
import { withStyles } from '@material-ui/core';
import { styles, ServiceTabsStyles } from './ServiceTabsStyles';
import { useCart } from '../../../../helper/CheckoutHelper';
import clock from '../../../../assets/error-bold.png';
import tick from '../../../../assets/radio-filled.png';
import tickBlack from '../../../../assets/radio-filled-black.png';
import { AvailableServicesResponse, PageName } from '../../../../models/checkout/Checkout';
import MainContainer from '../../../../component/mainContainer/MainContainer';
import { AGENCIES, APP } from '../../../../helper/AppNameHelper';

interface ServiceTabsProps extends ServiceTabsStyles {
  activeTab: number;
  setActiveTab: (value: number) => void;
  pageName: PageName;
  compare?: boolean;
  overrideCompleted?: boolean;
}

const ServiceTabs: FC<ServiceTabsProps> = ({
  classes,
  activeTab,
  setActiveTab,
  pageName,
  compare,
  overrideCompleted,
}) => {
  const { cartItems, compareItems } = useCart();

  const items = useMemo<AvailableServicesResponse[]>(() => {
    return compare ? compareItems : cartItems;
  }, [compare, cartItems, compareItems]);

  const isCompleted = (cartItem: AvailableServicesResponse) => {
    switch (pageName) {
      case PageName.CHECKOUTCONFIGURE:
        return (
          cartItem && cartItem.complete && cartItem.complete!.options && cartItem.complete!.terms
        );
      case PageName.CHECKOUTFINALLIZE:
        return (
          cartItem &&
          cartItem.complete &&
          cartItem.complete!.personalDetails &&
          cartItem.complete!.payment
        );
      case PageName.CHECKOUTCOMPARE:
        return false;
      default:
        return false;
    }
  };

  return (
    <div className={classes.serviceTabContainer}>
      <MainContainer width100 noPadding={compare} noMaxWidth={compare}>
        <div className={classes.flex}>
          {items.map((item, index) => (
            <div
              className={`${
                activeTab === index || isCompleted(item) ? classes.blueTab : classes.serviceTab
              } ${activeTab === index && classes.border}`}
              key={index}
              onClick={() => setActiveTab(index)}
            >
              <div className={classes.iconContainer}>
                <img
                  className={
                    activeTab === index || isCompleted(item) ? classes.activeIcon : classes.icon
                  }
                  alt="icon"
                  src={item.logo}
                />
              </div>
              {!compare && (
                <div className={classes.statusContainer}>
                  <img
                    className={classes.status}
                    alt="status"
                    src={
                      isCompleted(item) || (activeTab === index && overrideCompleted)
                        ? APP === AGENCIES.NAX
                          ? tickBlack
                          : tick
                        : clock
                    }
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </MainContainer>
    </div>
  );
};

export default withStyles(styles)(ServiceTabs);

import { color } from '../../../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';
import { AGENCIES, APP } from '../../../../helper/AppNameHelper';

export const styles = createStyles({
  sectionContainer: {
    backgroundColor: color.light,
    padding: 15,
  },
  sectionTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
  },
  sectionTitle: {
    marginRight: 'auto',
    fontFamily: 'GT Walsheim',
    fontSize: '20px',
    color: color.black87,
  },
  sectionIconContainer: {
    width: 22,
    height: 22,
  },
  sectionIcon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  titleContainer: {
    marginBottom: 12,
  },
  title: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: 20,
  },
  subtitle: {
    fontFamily: 'GT Walsheim',
    color: color.black38,
    fontSize: 14,
    marginTop: 6,
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: color.light,
    padding: '12px',
    alignItems: 'center',
    fontWeight: 600,
    color: color.black87,
    borderRadius: 8,
    border: `${color.borderColor} 2px solid`,
    marginBottom: '12px',
    minHeight: '40px',
  },
  menuRow: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    width: '26px',
    height: '26px',
    marginRight: '16px',
  },
  iconImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  optionContainer: {
    marginRight: 'auto',
  },
  optionTitle: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: '16px',
    fontWeight: 500,
  },
  optionSubtitle: {
    fontFamily: 'GT Walsheim',
    marginTop: 4,
    color: color.black38,
    fontSize: '13px',
    fontWeight: 500,
  },
  tickContainer: {
    width: '22px',
    height: '22px',
    marginLeft: 'auto',
    marginRight: '12px',
  },
  tick: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    filter: APP !== AGENCIES.SORTED ? 'grayscale(100%)' : '',
  },
  addNewOptionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addNewOption: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: color.light,
    padding: '16px',
    alignItems: 'center',
    borderRadius: 8,
    border: `${color.borderColor} 2px solid`,
    marginBottom: '12px',
    '&:first-child': {
      marginRight: '15px',
    },
  },
  addNewOptionText: {
    fontFamily: 'GT Walsheim',
    fontWeight: 500,
    color: color.black87,
    fontSize: '14px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  addNewPaymentFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15,
  },
  secureBoxStyle: {
    height: 56,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  secureLockStyle: {
    marginRight: 16,
  },
  secureText: {
    fontFamily: 'GT Walsheim',
    fontWeight: 500,
    color: color.black38,
    fontSize: '14px',
  },
  cancelNewOptionSave: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: color.light,
    padding: '10px 14px',
    alignItems: 'center',
    borderRadius: 8,
    border: `${color.borderColor} 2px solid`,
    fontFamily: 'GT Walsheim',
    fontWeight: 500,
    color: color.black87,
    fontSize: '14px',
    marginLeft: 'auto',
    marginRight: '12px',
    minWidth: '60px',
  },
  addNewOptionSave: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: color.lightBlue,
    padding: '10px 14px',
    alignItems: 'center',
    borderRadius: 8,
    border: `${color.lightBlue} 2px solid`,
    fontFamily: 'GT Walsheim',
    fontWeight: 500,
    color: color.light,
    fontSize: '14px',
    minWidth: '60px',
  },
  // CARD ------------------------------------------------------------ //
  inputContainer: {
    borderRadius: 8,
    border: `${color.borderColor} 2px solid`,
    padding: 12,
    marginTop: 15,
  },
  cardNumberInputStyle: {
    flexGrow: 1,
    marginTop: 15,
    borderRadius: 8,
    border: `${color.borderColor} 2px solid`,
    padding: 12,
    height: 35,
  },
  inputBoxStyle: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 15,
  },
  expiryInputStyle: {
    flex: 1,
    marginRight: 7.5,
    borderRadius: 8,
    border: `${color.borderColor} 2px solid`,
    padding: 12,
  },
  cvvInputStyle: {
    flex: 1,
    marginLeft: 7.5,
    borderRadius: 8,
    border: `${color.borderColor} 2px solid`,
    padding: 12,
  },
  icon: {
    height: 24,
    width: 24,
  },
  successContainer: {
    display: 'flex',
    backgroundColor: color.fadedBlue,
    padding: '12px',
    paddingLeft: 16,
    alignItems: 'center',
    borderRadius: 8,
    minHeight: 40,
    border: `${color.fadedBlue} 2px solid`,
  },
  successIcon: {
    width: 22,
    height: 22,
    marginRight: 12,
  },
  successText: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: 16,
  },
});

export interface PaymentSectionStyles extends WithStyles<typeof styles> {}

import * as React from 'react';
import PaymentDetailsComponent from '../../component/planSelectionPaymentDetail/PaymentDetails';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';
import { ApplicationState } from '../../store/RootReducers';
import { PaymentActions } from '../../store/actions/PaymentActions';
import { GetPaymentMethodsResponse } from '../../services/payment/getPaymentMethods/GetPaymentMethods.data';
import { RouteComponentProps } from 'react-router-dom';
import { routes } from '../../Routes';
import { ElectricityServiceActions } from '../../store/actions/ElectricityServiceActions';
import {
  ElectricityServiceDetails,
  ServiceAccount,
} from '../../services/electricityService/getElectricityService/GetElectricityService.data';
import { BroadbandServiceActions } from '../../store/actions/BroadbandServiceActions';
import { BroadbandServiceDetails } from '../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import { ServiceType } from '../services/ServiceConstants';
import { History } from 'history';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';
import { EditPaymentRequset } from '../../services/editPayment/postEditPayment/PostEditPayment.data';
import { EditPaymentActions } from '../../store/actions/EditPaymentActions';
import { createEditPaymentObject } from './addPayment/AddPaymentUtils';
import { Params } from '../../store/state/HeaderState';
import { HeaderActions } from '../../store/actions/HeaderActions';
import { BackHelper } from '../../helper/BackHelper';
import { GetPaymentType } from '../../services/payment/getPaymentTypes/GetPaymentTypes.data';
import { GetPaymentTypeResponse } from '../../services/payment/getPaymentTypes/GetPaymentTypesResponse.data';
import { PlanCommonSummaryActions } from '../../store/actions/CommonSummaryActions';
import { isNil, isUndefined } from 'lodash';
import { serviceTypeList } from './addPayment/AddPaymentConstants';

interface PropsFromState {
  paymentMethods: GetPaymentMethodsResponse[];
  accessToken: string | boolean | null | null;
  paymentTypes: GetPaymentTypeResponse[];
  providerId: string;
  electricityServiceId: number | undefined;
  serviceAccount: number | undefined;
  broadbandServiceAccountId: number | undefined;
}

interface PaymentSummary {
  paymentRefId: string;
}
interface CommonPaymentSummary {
  serviceDetails: PaymentSummary;
  step: string;
  propertyId?: string;
  serviceType?: string;
  providerId?: string;
  serviceAccountId?: string;
}

interface OwnState {
  index: number | boolean;
}

interface PropsFromDispatch {
  readonly getPaymentMethods: (onSuccess: (response: GetPaymentMethodsResponse[]) => void) => void;
  readonly saveSelectedMethod: (payment: GetPaymentMethodsResponse) => void;
  readonly postElectricityPaymentDetails: (
    propertyId: number,
    serviceAccountId: number,
    data: ElectricityServiceDetails,
    handlePaymentSuccess: () => void,
  ) => void;
  readonly postBroadbandServiceDetails: (
    paymentId: string,
    serviceAccountId: string,
    data: BroadbandServiceDetails,
    handlePaymentSuccess: () => void,
  ) => void;
  postCommonData: (serviceDetails: CommonPaymentSummary, onSuccess: () => void) => void;

  postEditPayment: (data: EditPaymentRequset, onSuccess: () => void) => void;
  saveParams: (data: Params) => void;
  getPaymentTypes: (
    data: GetPaymentType,
    isPaymentMethods: boolean,
    onSuccess: (isPaymentMethods: boolean) => void,
  ) => void;
}

interface PropsFromRoute {
  history: History;
}

type OwnProps = PropsFromState &
  PropsFromDispatch &
  PropsFromRoute &
  RouteComponentProps<{
    propertyId: string;
    serviceType: string;
    paymentRefId: string;
    serviceAccountId: string;
    agencyName: string;
  }>;
class PaymentDetailsMock extends React.Component<OwnProps, OwnState> {
  public waitForAccessToken = 0;
  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      index: false,
    };
  }

  public componentDidMount() {
    const { accessToken, getPaymentMethods } = this.props;
    this.saveUrlParams();
    if (accessToken) {
      getPaymentMethods(this.handlePaymentMethodsSuccess);
      this.waitForAccessToken = 1;
    }
  }

  public componentDidUpdate(nextProps: OwnProps) {
    const { paymentMethods, accessToken, getPaymentMethods } = this.props;
    const { paymentRefId } = this.props.match.params;
    this.saveUrlParams();
    if (nextProps.paymentMethods !== paymentMethods && paymentRefId) {
      const paymentIndex = paymentMethods.findIndex((payment) => payment.refId === paymentRefId);
      this.setState({ index: paymentIndex });
    }
    if (accessToken && this.waitForAccessToken === 0) {
      getPaymentMethods(this.handlePaymentMethodsSuccess);
      this.waitForAccessToken = 1;
    }
  }
  public saveUrlParams = () => {
    const { propertyId, paymentRefId, serviceAccountId, serviceType, agencyName } =
      this.props.match.params;
    if (!serviceAccountId && serviceType === ServiceType.Electricity) {
      const { electricityServiceId } = this.props;
      BackHelper.saveParams({
        propertyId,
        paymentRefId,
        serviceAccountId: (electricityServiceId as number).toString(),
      });
    } else if (!serviceAccountId && serviceType === ServiceType.Broadband) {
      const { broadbandServiceAccountId } = this.props;
      BackHelper.saveParams({
        propertyId,
        paymentRefId,
        serviceAccountId: (broadbandServiceAccountId as number).toString(),
      });
    } else {
      BackHelper.saveParams({
        propertyId,
        paymentRefId,
        serviceAccountId,
        agencyName,
      });
    }
  };

  public render() {
    const { paymentMethods, saveSelectedMethod, paymentTypes } = this.props;
    const { propertyId, serviceType, paymentRefId, serviceAccountId, agencyName } =
      this.props.match.params;
    const selectedPaymentIndex = paymentMethods.findIndex(
      (payment) => payment.refId === paymentRefId,
    );
    return (
      <PaymentDetailsComponent
        leaseId={''}
        {...this.props}
        propertyId={propertyId}
        serviceType={serviceType}
        serviceAccountId={serviceAccountId}
        agencyName={agencyName}
        paymentRefId={paymentRefId}
        handleNext={this.handleNext}
        index={this.state.index}
        selectedPaymentIndex={selectedPaymentIndex}
        handleIndex={this.handleIndex}
        paymentTypes={paymentTypes}
      />
    );
  }
  private handleNext = (payment: GetPaymentMethodsResponse) => {
    const { serviceType, propertyId } = this.props.match.params;
    const { saveSelectedMethod, history } = this.props;
    switch (serviceType.toLowerCase()) {
      case ServiceType.Electricity:
        this.handleElectricityServicePayment(payment);
        break;
      case ServiceType.Rent:
        saveSelectedMethod(payment);
        history.push(routes.summary.new(propertyId, serviceType));
        break;
      case ServiceType.Broadband:
        this.handleBroadbandServicePayment(payment);
        break;
      case ServiceType.EditRent:
        this.handleEditPayment(payment);
        break;
      case ServiceType.EditElectricity:
        this.handleEditPayment(payment);
        break;
      case ServiceType.EditBroadband:
        this.handleEditPayment(payment);
        break;
    }
  };

  private handleEditPayment = (payment: GetPaymentMethodsResponse) => {
    const { serviceAccountId } = this.props.match.params;
    const { postEditPayment } = this.props;
    const requestData = createEditPaymentObject(parseInt(serviceAccountId, 10), payment.refId);
    postEditPayment(requestData, this.handlePaymentSuccess);
  };

  private handlePaymentSuccess = () => {
    const { serviceType } = this.props.match.params;
    const { history } = this.props;
    switch (serviceType.toLowerCase()) {
      case ServiceType.Electricity:
        history.push(routes.setup.electricity);
        break;
      case ServiceType.Broadband:
        history.push(routes.setup.broadband);
        break;
      case ServiceType.EditRent:
        sendMessageToApp(ValidMessages.Dashboard);
        break;
      case ServiceType.EditElectricity:
        sendMessageToApp(ValidMessages.Dashboard);
        break;
      case ServiceType.EditBroadband:
        sendMessageToApp(ValidMessages.Dashboard);
        break;
    }
  };

  private handleElectricityServicePayment = (payment: GetPaymentMethodsResponse) => {
    const { propertyId, serviceType } = this.props.match.params;
    const { providerId, serviceAccount, postCommonData } = this.props;
    const paymentComplete: PaymentSummary = {
      paymentRefId: payment.refId,
    };
    const commonPaymentSummary: CommonPaymentSummary = {
      serviceDetails: paymentComplete,
      step: 'PAYMENT',
      propertyId,
      serviceType,
      providerId,
      serviceAccountId: (serviceAccount as number).toString(),
    };
    postCommonData(commonPaymentSummary, this.handlePaymentSuccess);
  };

  private handleBroadbandServicePayment = (payment: GetPaymentMethodsResponse) => {
    const { propertyId, serviceType } = this.props.match.params;
    const { providerId, serviceAccount, postCommonData } = this.props;
    const paymentComplete: PaymentSummary = {
      paymentRefId: payment.refId,
    };
    const commonPaymentSummary: CommonPaymentSummary = {
      serviceDetails: paymentComplete,
      step: 'PAYMENT',
      propertyId,
      serviceType,
      providerId,
      serviceAccountId: (serviceAccount as number).toString(),
    };
    postCommonData(commonPaymentSummary, this.handlePaymentSuccess);
  };

  private handlePaymentMethodsSuccess = (response: GetPaymentMethodsResponse[]) => {
    this.getPayments(response.length !== 0);
  };

  private getPayments(isPaymentMethods: boolean) {
    const { propertyId, serviceType } = this.props.match.params;
    const { getPaymentTypes } = this.props;
    getPaymentTypes(
      { propertyId, serviceType: serviceTypeList[serviceType.toLowerCase()] },
      isPaymentMethods,
      this.handlePaymentTypeSuccess,
    );
  }

  private handlePaymentTypeSuccess = (isPaymentMethods: boolean) => {
    const { history } = this.props;
    const { propertyId, serviceType, agencyName, paymentRefId, serviceAccountId } =
      this.props.match.params;
    if (!isPaymentMethods) {
      history.replace(
        routes.payment.list.empty(
          propertyId,
          serviceType,
          paymentRefId,
          serviceAccountId,
          agencyName,
        ),
      );
    }
  };
  private handleIndex = (index: number) => {
    this.setState({ index });
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): PropsFromDispatch {
  return {
    getPaymentMethods: (onSuccess: (response: GetPaymentMethodsResponse[]) => void) => {
      dispatch(
        PaymentActions.getPaymentMethodsStart({
          onSuccess,
        }),
      );
    },
    saveSelectedMethod: (payment: GetPaymentMethodsResponse) => {
      dispatch(PaymentActions.postPaymentTokenSuccess(payment));
    },
    postElectricityPaymentDetails: (
      propertyId: number,
      serviceAccountId: number,
      data: ElectricityServiceDetails,
      handlePaymentSuccess: () => void,
    ) => {
      dispatch(
        ElectricityServiceActions.postElectricityServiceStart({
          propertyId,
          serviceAccountId,
          data,
          onSuccess: () => {
            handlePaymentSuccess();
          },
        }),
      );
    },
    postBroadbandServiceDetails: (
      propertyId: string,
      serviceAccountId: string,
      data: BroadbandServiceDetails,
      handlePaymentSuccess: () => void,
    ) => {
      dispatch(
        BroadbandServiceActions.postBroadbandServiceStart({
          propertyId,
          serviceAccountId,
          data,
          onSuccess: () => {
            handlePaymentSuccess();
          },
        }),
      );
    },
    postCommonData: (serviceDetails: CommonPaymentSummary, onSuccess: () => void) =>
      dispatch(
        PlanCommonSummaryActions.postCommonSummaryData({
          data: serviceDetails,
          onSuccess,
        }),
      ),
    postEditPayment: (data: EditPaymentRequset, onSuccess: () => void) => {
      dispatch(EditPaymentActions.postEditPaymentStart({ data, onSuccess }));
    },
    saveParams: (data: Params) => dispatch(HeaderActions.saveParams(data)),
    getPaymentTypes: (
      data: GetPaymentType,
      isPaymentMethods: boolean,
      onSuccess: (isPaymentMethods: boolean) => void,
    ) => {
      dispatch(
        PaymentActions.getPaymentTypesStart({
          data,
          onSuccess: () => onSuccess(isPaymentMethods),
        }),
      );
    },
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    paymentMethods: state.payment.paymentMethods,
    paymentTypes: state.payment.paymentTypes,
    accessToken: state.token.accessToken,
    providerId: isUndefined(state.commonSummary.commonServicePlansSuccess.provider)
      ? state.commonSummary.commonServicePlansSuccess.serviceAccountResponse.provider
      : state.commonSummary.commonServicePlansSuccess.provider,
    electricityServiceId:
      state.electricityService &&
      state.electricityService.service &&
      !isNil(state.electricityService.service.serviceAccount)
        ? (state.electricityService.service.serviceAccount.id as number)
        : state.commonSummary.commonServicePlans.id
        ? state.commonSummary.commonServicePlans.id
        : state.commonSummary.commonServicePlans.serviceAccountResponse.id,
    serviceAccount: state.commonSummary.commonServicePlans.id
      ? state.commonSummary.commonServicePlans.id
      : state.commonSummary.commonServicePlans.serviceAccountResponse.id,
    broadbandServiceAccountId:
      state.broadbandService &&
      state.broadbandService.service &&
      ((state.broadbandService.service.serviceAccount as ServiceAccount).id as number),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetailsMock);

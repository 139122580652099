import React, { FC, useState, useEffect } from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';
import { ApplicationState } from '../../store/RootReducers';
import { ServiceType } from '../services/ServiceConstants';
import { RentServiceActions } from '../../store/actions/RentServiceActions';
import {
  GetRentServiceResponse,
  PaymentProvider,
} from '../../services/rentService/getRentService/GetRentServiceResponse.data';
import { routes } from '../../Routes';
import { PostRentServiceRequest } from '../../services/rentService/postRentService/PostRentService.data';
import { createRentServiceRequestObject, getPaymentType, LABELS } from './ConfirmationConstants';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';
import { styles, ConfirmationStyles } from './ConfirmationStyles';
import NavBar from '../../component/navBar/NavBar';
import { fetchAgencyLogo } from '../../helper/AgencyLogoHelper';
import house from '../../assets/houses/house3.png';
import { calculateCost } from '../../helper/CostHelper';
import { RentalFrequency } from '../../services/lease/getLease/GetLeaseResponse.data';
import LOGO_HOME from '../../assets/images/homescreen/home.png';
import MainContainer from '../../component/mainContainer/MainContainer';
import { useGetLeaseData } from '../../helper/RentServiceHelper';

interface PropertyConfirmationProps extends ConfirmationStyles, RouteComponentProps<{}> {
  postRentService: (data: PostRentServiceRequest, onSuccess: () => void) => void;
}

const PropertyConfirmation: FC<PropertyConfirmationProps> = ({
  classes,
  history,
  postRentService,
}) => {
  const lease = useGetLeaseData();
  const {
    property,
    paymentFrequency,
    monthlyCost,
    paymentDetails,
    serviceability,
    leaseRefId,
    agency,
    initialPayments,
  } = lease!;
  const { unitNumber, streetNumber, streetName, state, suburb, postcode } = property!;
  const address1 = `${unitNumber ? `${unitNumber}/` : ''}${streetNumber || ''} ${streetName}`;
  const address2 = `${suburb}, ${state} ${postcode}`;
  const calculatedCost = calculateCost(
    paymentFrequency as RentalFrequency,
    monthlyCost as number,
    true,
  );

  const handleNext = () => {
    const { paymentProvider } = paymentDetails!;
    if (
      !!initialPayments.length ||
      (paymentProvider && paymentProvider === PaymentProvider.SORTED)
    ) {
      // Select a paymentProvider
      history.push(routes.payment.list.saved(property!.id.toString(), ServiceType.Rent));
    } else if (paymentProvider) {
      // Add a new paymentProvider
      history.push(routes.summary.new(property!.id.toString(), ServiceType.Rent));
    } else if (serviceability && serviceability.length !== 0) {
      // There are services attached, please see them
      history.push(routes.serviceability);
    } else {
      // Go straight to setup complete
      const requestData = createRentServiceRequestObject(leaseRefId);
      postRentService(requestData, () => {
        history.push(routes.setup.base('rent', `${property!.id}`));
        sendMessageToApp(ValidMessages.intercomTagging, property!.state as string);
      });
    }
  };

  return (
    <div className={classes.root}>
      <NavBar
        subTitle={!!initialPayments.length ? LABELS.NAV_INITIAL_TITLE : LABELS.NAV_TITLE}
        subText={!!initialPayments.length ? LABELS.NAV_INITIAL_SUBTITLE : LABELS.NAV_SUBTITLE}
      />
      <MainContainer>
        <div className={classes.contentContainer}>
          <div className={classes.contentTitle}>{LABELS.PROPERTY}</div>
          <div className={classes.propertyContainer}>
            <div className={classes.messageContainer}>
              <div className={classes.messageTitle}>
                {address1.length > 18 ? `${address1.substring(0, 18)}...` : address1}
              </div>
              <div className={classes.messageSubtitle}>
                {address2.length > 18 ? `${address2.substring(0, 18)}...` : address2}
              </div>
            </div>
            <img
              className={classes.agencyLogo}
              onError={(e) => {
                (e.target as HTMLImageElement).src = fetchAgencyLogo('SORTED')!;
              }}
              src={
                lease && !!lease.agency.logoUrl
                  ? lease.agency.logoUrl
                  : fetchAgencyLogo(lease ? lease.agency.code : 'SORTED')
              }
            />
            <div className={classes.houseContainer}>
              <img className={classes.house} src={house} />
            </div>
          </div>
        </div>
      </MainContainer>
      <MainContainer>
        <div className={classes.contentContainer}>
          <div className={classes.contentTitle}>
            {!!initialPayments.length ? LABELS.INITIAL_PAYMENTS : LABELS.RENT_PAYMENTS}
          </div>
          {!!initialPayments.length ? (
            <>
              {initialPayments.map((payment, idx) => (
                <div className={classes.rentContainer}>
                  <img className={classes.rentLogo} src={LOGO_HOME} />
                  <div className={classes.messageContainer}>
                    <div className={classes.messageTitle}>{getPaymentType(payment)}</div>
                    <div className={classes.messageSubtitle}>{agency.tradingName}</div>
                  </div>
                  <div className={classes.rentTextContainer}>
                    <div className={classes.rentTitle}>${payment.amount.toFixed(2)}</div>
                    <div className={classes.rentSubtitle}>Draw date: {payment.paymentDate}</div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className={classes.rentContainer}>
              <img className={classes.rentLogo} src={LOGO_HOME} />
              <div className={classes.messageContainer}>
                <div className={classes.messageTitle}>{LABELS.RENT}</div>
                <div className={classes.messageSubtitle}>{agency.tradingName}</div>
              </div>
              <div className={classes.rentTextContainer}>
                <div className={classes.rentTitle}>{calculatedCost}</div>
                <div className={classes.rentSubtitle}>{paymentFrequency}</div>
              </div>
            </div>
          )}
        </div>
      </MainContainer>
      <div className={classes.footerButtonsContainer}>
        <MainContainer>
          <div className={classes.footerButton} onClick={() => handleNext()}>
            {LABELS.THATS_IT}
          </div>
          <div className={classes.footerButtonSecondary} onClick={() => history.goBack()}>
            {LABELS.BACK}
          </div>
        </MainContainer>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  postRentService: (data: PostRentServiceRequest, onSuccess: () => void) => {
    dispatch(
      RentServiceActions.postRentServiceStart({
        data,
        onSuccess,
      }),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(PropertyConfirmation)));

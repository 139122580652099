import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { withStyles, Dialog } from '@material-ui/core';
import { withRouter, RouteComponentProps, useLocation } from 'react-router';
import { ApplicationState } from '../../store/RootReducers';
import { Dispatch } from 'redux';
import { styles, CheckoutSelectStyles } from './CheckoutSelectStyles';
import { LABELS } from './CheckoutSelectConstants';
import {
  AvailableServicesRequest,
  AvailableServicesResponse,
  Property,
  Preopen,
  ServiceTypes,
  RemindMeLaterRequest,
} from '../../models/checkout/Checkout';
import { CheckoutActions, CheckoutActionTypes } from '../../store/actions/CheckoutActions';
import ServiceContainer from './components/ServiceContainer/ServiceContainer';
import { getQueryParams } from '../../helper/QueryHelper';
import { RentMock } from '../../services/checkoutService/CheckoutMockResponse';
import AddressHeader from '../../component/addressHeader/AddressHeader';
import SlideUp from '../../component/transitionsHelper/SlideUp';
import CheckoutFooter from '../../component/checkoutFooter/CheckoutFooter';
import InfoPopup from './components/InfoPopup/InfoPopup';
import { useCart } from '../../helper/CheckoutHelper';
import { routes } from '../../Routes';
import { getActiveServices, getPreopenedServices } from './CheckoutSelectUtils';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';
import SlideLeft from '../../component/transitionsHelper/SlideLeft';
import CheckoutCompare from '../../component/checkoutCompare/CheckoutCompare';
import { useIntercom } from 'react-use-intercom';
import noServiceIcon from '../../assets/noservices.png';
import { PopupType } from './components/ServiceContainer/ServiceContainerConstants';
import AgencyBackground from '../../component/agencyBackground/AgencyBackground';
import MainContainer from '../../component/mainContainer/MainContainer';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import LoadingServices from './components/LoadingServices/LoadingServices';

interface CheckoutSelectProps extends CheckoutSelectStyles, RouteComponentProps<{}> {
  accessToken: string | boolean | null;
  availableServices: AvailableServicesResponse[] | undefined;
  requiredServiceTypes: ServiceTypes[] | undefined;
  preopen: Preopen | undefined;
  propertyId: string | undefined;
  property: Property | undefined;
  infoPopupDismissed: boolean;
  isExternal: boolean;
  isCartOpen: boolean;
  isModalOpen: PopupType;
  loading: boolean;
  isTradeRequest: boolean;
  getAvailableServices: (data: AvailableServicesRequest) => void;
  setRequiredServiceTypes: (data: ServiceTypes[]) => void;
  setPreopen: (data: Preopen) => void;
  setCheckoutPropertyId: (data: string) => void;
  setDismissedInfoPopup: () => void;
  remindMeLater: (data: RemindMeLaterRequest) => void;
  toggleCart: () => void;
  toggleModal: (data: PopupType) => void;
  setIsLandlord: (value: boolean) => void;
  setIsTradeRequest: (value: boolean) => void;
}

const CheckoutSelect: FC<CheckoutSelectProps> = ({
  classes,
  history,
  accessToken,
  availableServices,
  requiredServiceTypes,
  preopen,
  location,
  propertyId,
  property,
  infoPopupDismissed,
  isExternal,
  isCartOpen,
  isModalOpen,
  loading,
  isTradeRequest,
  getAvailableServices,
  setRequiredServiceTypes,
  setPreopen,
  setCheckoutPropertyId,
  setDismissedInfoPopup,
  remindMeLater,
  toggleCart,
  toggleModal,
  setIsLandlord,
  setIsTradeRequest,
}) => {
  const { show } = useIntercom();
  const requiredURLServiceTypes = getQueryParams(location, 'requiredServiceTypes');
  const preopenURL = getQueryParams(location, 'preopen');
  const propertyIdURL = getQueryParams(location, 'propertyId');
  const isTradeRequestURL = getQueryParams(location, 'isTradeRequest');
  const isLandlordURL = getQueryParams(location, 'isLandlord');

  const [showCompare, setShowCompare] = useState<boolean>(false);
  const [showInfo, setShowInfo] = useState<boolean>(
    !infoPopupDismissed && requiredURLServiceTypes
      ? (requiredURLServiceTypes.split(',') as ServiceTypes[]).includes(ServiceTypes.Rent)
      : false,
  );
  const { cartCount, cartItems, compareCount } = useCart();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isTradeRequestURL) {
      setIsTradeRequest(true);
    }
  }, [isTradeRequestURL]);

  useEffect(() => {
    if (isLandlordURL) {
      setIsLandlord(true);
    }
  }, [isLandlordURL]);

  useEffect(() => {
    // Don't call api if theres data retrieved already
    const requiredServiceTypes = requiredURLServiceTypes
      ? (requiredURLServiceTypes.split(',') as ServiceTypes[])
      : [];
    if (availableServices === undefined && propertyId) {
      getAvailableServices({
        propertyId,
        hideLoading: true,
        requiredServiceTypes: requiredServiceTypes,
      });
      remindMeLater({ propertyId, flag: false });
    }
  }, [propertyId, accessToken]);

  useEffect(() => {
    if (requiredURLServiceTypes) {
      setRequiredServiceTypes(requiredURLServiceTypes.split(',') as ServiceTypes[]);
    }
  }, [requiredURLServiceTypes]);

  useEffect(() => {
    if (preopenURL) {
      try {
        setPreopen(JSON.parse(preopenURL));
      } catch (err) {
        console.log(err);
      }
    }
  }, [preopenURL]);

  useEffect(() => {
    if (propertyIdURL) {
      setCheckoutPropertyId(propertyIdURL);
    }
  }, [propertyIdURL]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (history.action === 'POP') {
        // adding current location again to stack as it gets removed and we want back button on
        // abandon cart component to redirect back to this component
        history.push(location.pathname + location.search);

        // when user hits hardware back on full screen pop up
        if (!showInfo && !showCompare && !isCartOpen && isModalOpen === PopupType.NONE) {
          if (isExternal) {
            history.push(routes.checkout.abandonCart);
          } else {
            sendMessageToApp(ValidMessages.Dashboard, { toHome: true });
          }
        }

        if (isCartOpen) {
          toggleCart();
        }

        if (isModalOpen !== PopupType.NONE) {
          toggleModal(PopupType.NONE);
        }
      }
    });

    return () => {
      // added timeout, otherwise it unregisters before listener callback
      setTimeout(() => {
        unlisten();
      }, 0);
    };
  }, [showCompare, showInfo, isCartOpen, isModalOpen, isExternal]);

  const activeServices = useMemo<AvailableServicesResponse[]>(() => {
    return getActiveServices(requiredServiceTypes, cartItems, availableServices);
  }, [requiredServiceTypes, cartItems, availableServices]);

  const preopenedServices = useMemo<Preopen>(() => {
    return getPreopenedServices(preopen, cartItems);
  }, [cartItems, preopen]);

  return (
    <div className={classes.root}>
      <AddressHeader
        address={property ? property.address : undefined}
        disabled={!!property}
        loading={loading}
      />
      <AgencyBackground />
      <MainContainer>
        <div className={classes.title}>
          {loading
            ? LABELS.LOADING_SERVICES
            : isTradeRequest
            ? LABELS.TITLE_TRADE_REQUEST
            : LABELS.TITLE}
        </div>
      </MainContainer>
      {loading ? (
        <MainContainer>
          <LoadingServices />
        </MainContainer>
      ) : (
        <>
          {requiredServiceTypes && requiredServiceTypes.includes(ServiceTypes.Rent) && (
            <ServiceContainer service={RentMock} rent history={history} />
          )}
          <div className={classes.scrollContainer}>
            {activeServices.length ? (
              <>
                {activeServices.map((serviceType, idx) => (
                  <ServiceContainer
                    isPreopened={preopenedServices.hasOwnProperty(serviceType.type)}
                    preopenedDetails={preopenedServices[serviceType.type]}
                    service={serviceType}
                    key={idx}
                    history={history}
                  />
                ))}
              </>
            ) : (
              <div className={classes.noServiceContainer}>
                <img src={noServiceIcon} className={classes.noServiceImage} />
                <div className={classes.noServiceTitleStyle}>{LABELS.NOTHINGTITLE}</div>
                <div className={classes.noServiceSubtextSyle}>{LABELS.NOTHIN}</div>
                <div
                  className={classes.contactSupportButtonStyle}
                  onClick={() => {
                    if (isExternal) {
                      show();
                    } else {
                      sendMessageToApp(ValidMessages.Chat);
                    }
                  }}
                >
                  {LABELS.CONTACT_SUPPORT}
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {/* <Dialog
        open={showInfo}
        maxWidth="md"
        fullWidth
        style={{
          zIndex: 999999,
          marginLeft: screenWidth > 500 ? (screenWidth - 550) / 2 : 0,
          marginRight: screenWidth > 500 ? (screenWidth - 550) / 2 : 0,
        }}
        TransitionComponent={SlideUp}
      >
        <InfoPopup
          closePopup={() => {
            setShowInfo(false);
            setDismissedInfoPopup();
          }}
        />
      </Dialog> */}

      <Dialog
        open={showCompare}
        fullWidth
        maxWidth="xl"
        style={{ zIndex: 999999 }}
        TransitionComponent={SlideUp}
        onClose={() => setShowCompare(false)}
      >
        <div className={classes.popupContainer}>
          <CheckoutCompare closePopup={() => setShowCompare(false)} />
        </div>
      </Dialog>
      <CheckoutFooter
        showHelp
        showCompare={!!compareCount}
        onCompare={() => setShowCompare(true)}
        onHelp={() => setShowInfo(true)}
        primaryText={LABELS.GET_STARTED}
        hidePrimary={activeServices.length === 0}
        onPrimary={() => {
          history.push(routes.checkout.configure);
        }}
        disabled={!cartCount}
        secondaryText={activeServices.length ? LABELS.REMIND : LABELS.FINISH}
        // hideSecondary={isExternal}
        onSecondary={() => {
          if (propertyId) {
            remindMeLater({ propertyId, flag: true });
          }
          if (isExternal) {
            history.push(
              `${routes.checkout.abandonCart}${!activeServices.length ? '?noService=true' : ''}`,
            );
          } else {
            sendMessageToApp(ValidMessages.Dashboard);
          }
        }}
      />
    </div>
  );
};

const loading = loadingSelector([CheckoutActionTypes.GET_AVAILABLE_SERVICES]);

const mapStateToProps = (state: ApplicationState) => ({
  loading: loading(state),
  availableServices: state.checkout.availableServices,
  requiredServiceTypes: state.checkout.requiredServiceTypes,
  preopen: state.checkout.preopen,
  propertyId: state.checkout.propertyId,
  property: state.checkout.property,
  infoPopupDismissed: state.checkout.infoPopupDismissed,
  isExternal: state.token.isExternal,
  isCartOpen: state.checkout.isCartOpen,
  isModalOpen: state.checkout.isModalOpen,
  accessToken: state.token.accessToken,
  isTradeRequest: state.checkout.isTradeRequest,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAvailableServices: (data: AvailableServicesRequest) =>
    dispatch(CheckoutActions.getAvailableServicesStart(data)),
  setRequiredServiceTypes: (data: ServiceTypes[]) =>
    dispatch(CheckoutActions.setRequiredServiceTypes(data)),
  setPreopen: (data: Preopen) => dispatch(CheckoutActions.setPreopen(data)),
  setCheckoutPropertyId: (data: string) => dispatch(CheckoutActions.setCheckoutPropertyId(data)),
  setDismissedInfoPopup: () => dispatch(CheckoutActions.dismissedInfoPopup()),
  remindMeLater: (data: RemindMeLaterRequest) => dispatch(CheckoutActions.remindMeLater(data)),
  toggleCart: () => dispatch(CheckoutActions.toggleCart()),
  toggleModal: (data: PopupType) => dispatch(CheckoutActions.toggleModal(data)),
  setIsLandlord: (value: boolean) => dispatch(CheckoutActions.setIsLandlord(value)),
  setIsTradeRequest: (value: boolean) => dispatch(CheckoutActions.setIsTradeRequest(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(CheckoutSelect)));

import { color } from '../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';
import { AGENCIES, APP } from '../../helper/AppNameHelper';

export const styles = createStyles({
  root: {
    padding: '0 16',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  logoStyle: {
    marginTop: 66,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60%',
  },
  checkIconStyle: {
    marginTop: 51,
    height: 63,
  },
  imageBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  logoBox: {
    maxHeight: 25,
    maxWidth: 150,
  },
  headingBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 43,
    marginLeft: 8,
    marginRight: 8,
  },
  headingStyle: {
    fontSize: 27.2,
    fontWeight: 500,
    color: '#1f0d33',
    fontFamily: 'GT Walsheim',
    textAlign: 'center',
  },
  subHeadingStyle: {
    fontFamily: 'GT Walsheim Regular',
    fontSize: 16.3,
    letterSpacing: 0.3,
    textAlign: 'center',
    marginTop: 16,
    color: '#1f0d33',
  },
  footer: {
    bottom: 0,
    left: 0,
    right: 0,
    padding: '0 16px 16px 16px',
    position: 'fixed',
  },
  button: {
    'height': 36,
    'background': color.buttonBackground,
    '&:hover': {
      backgroundColor: color.buttonBackground,
    },
  },
  buttonText: {
    color: color.light,
  },
  background: {
    width: '100vw',
    height: 'calc(100vh - 300px)',
    minHeight: '600px',
  },
  backgroundImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'top',
  },
  bottomSheet: {
    bottom: 0,
    left: 0,
    right: 0,
    padding: 12,
    backgroundColor: color.light,
    position: 'fixed',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },
  titleIcon: {
    width: 45,
    height: 45,
    marginRight: 12,
    // filter: APP !== AGENCIES.SORTED ? 'grayscale(100%)' : '',
  },
  title: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: '27px',
    fontWeight: 500,
  },
  description: {
    fontFamily: 'GT Walsheim Light',
    color: color.black87,
    fontSize: '15px',
    lineHeight: 1.5,
    fontWeight: 100,
    marginBottom: 180,
  },
  footerButtonsContainer: {
    bottom: 12,
    left: 12,
    right: 12,
    position: 'fixed',
  },
  footerButton: {
    padding: '16px 16px 16px 16px',
    backgroundColor: color.buttonBackgroundSecondary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.light,
    fontWeight: 600,
    borderRadius: 6,
  },
  footerButtonSecondary: {
    marginTop: 12,
    padding: '16px 16px 16px 16px',
    backgroundColor: color.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.black87,
    fontWeight: 600,
    borderRadius: 6,
    border: `${color.borderColor} 2px solid`,
  },
  disabled: {
    padding: '16px 16px 16px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    borderRadius: 6,
    backgroundColor: color.disabled,
    color: color.black38,
  },
});

export interface SetupStyles extends WithStyles<typeof styles> {}

import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './store/Store';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme/Theme';
import './theme/app.scss';
import { IntercomProvider } from 'react-use-intercom';
import { INTERCOM_APP_ID, INTERCOM_SWITCH_APP_ID } from './Constants';
import { AGENCIES, APP } from './helper/AppNameHelper';

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <MuiThemeProvider theme={theme}>
        <IntercomProvider
          appId={APP === AGENCIES.SWITCH ? INTERCOM_SWITCH_APP_ID : INTERCOM_APP_ID}
        >
          <App />
        </IntercomProvider>
      </MuiThemeProvider>
    </HashRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import * as React from 'react';
import TermsAndConditionMock from '../../../component/services/termsAndConditions/PlanSelectionTnC';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/RootReducers';
import { get } from 'lodash';
import { History } from 'history';
import { RouteComponentProps } from 'react-router-dom';
import { QuotesV3 } from '../commonServices/CommonPlanSelectionInterface';
import { ElectricityServiceDetails } from '../../../services/electricityService/getElectricityService/GetElectricityService.data';

interface OwnState {
  termsDrawer: boolean;
  planDetailsDrawer: boolean;
  personalDetailsDrawer: boolean;
}

interface PropsFromState {
  quotes?: QuotesV3;
  paymentCardRequired: boolean;
  //TODO: Resolve any
  tncData: any;
  commonSummaryAddressLookup: any;
}

export interface PropsObjectType {
  key: string;
  value: string;
}

interface PropsFromRoute {
  history: History;
}

export interface PropertyDetails {
  propertyId: string;
  serviceAccountId: string;
}

export interface ModalPlanDetails {
  title: string;
  value: string | PropsObjectType[];
}

export interface PropDetail {
  propertyId: string;
  serviceAccountId: string;
}

type OwnProps = PropsFromState &
  PropsFromRoute &
  PropDetail &
  RouteComponentProps<{
    serviceName: string;
    propertyId: string;
    providerName: string;
  }>;

class TermsAndConditionsMock extends React.Component<OwnProps, OwnState> {
  public waitForAccesstoken = 0;
  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      termsDrawer: false,
      planDetailsDrawer: false,
      personalDetailsDrawer: false,
    };
  }

  public render() {
    const { quotes, commonSummaryAddressLookup } = this.props;
    if (!quotes) return <div />;
    return (
      <TermsAndConditionMock
        linkTitle={quotes.Response.Plans[0].UiModelAttributes.Links.Value}
        concessionCard={get(this.props.tncData, 'concessionCard', null)}
        productDetail={this.props.tncData.productDetails}
        summary={
          this.props.tncData.summary
            ? this.props.tncData.summary
            : this.props.tncData.summaryDetails
        }
        paymentCardRequiresd={this.props.paymentCardRequired}
        planSummary={
          this.props.tncData.storePlanSummary
            ? this.props.tncData.storePlanSummary
            : this.props.tncData.featureDetails
        }
        history={this.props.history}
        propertyId={this.props.match.params.propertyId}
        attributes={commonSummaryAddressLookup ? commonSummaryAddressLookup.Attributes : {}}
        serviceName={this.props.match.params.serviceName}
        providerName={this.props.match.params.providerName}
        preAgreementSummary={get(quotes, 'Response.Plans.0.PreAgreementSummary', {})}
        serviceabilityDetails={get(this.props.tncData, 'serviceabilityDetails', null)}
      />
    );
  }
}
const mapStateToProps = (state: ApplicationState): PropsFromState => {
  const service: ElectricityServiceDetails = state.electricityService
    .service as ElectricityServiceDetails;
  return {
    commonSummaryAddressLookup: state.commonSummary.commonSummaryAddressLookup,
    quotes: state.commonSummary.quotes,
    paymentCardRequired: get(
      state,
      'commonSummary.storePlanSummaryData.data.paymentCardRequired',
      false,
    ),
    tncData: state.commonSummary.tncData
      ? state.commonSummary.tncData.data
      : service.serviceDetails.planDetails,
  };
};

export default connect(mapStateToProps)(TermsAndConditionsMock);

import { StoreAction } from '../StoreHelper';
import { PlanDetailsSubmit } from '../../models/plan/electricity/postPlanDetails/PlanDetailsSubmit.data';
import { PlanDetails } from '../../models/plan/electricity/getPlanDetails/PlanDetails.data';
import { PlanRequest } from '../../services/plan/getPlanDetails/GetPlanDetails.data';

export enum PlanActionTypes {
  GetPlanDetailsStart = 'GET_PLAN_DETAILS_START',
  GetPlanDetailsSuccess = 'GET_PLAN_DETAILS_SUCCESS',
  GetPlanDetailsError = 'GET_PLAN_DETAILS_ERROR',
  ToggleConcessionChoice = 'TOGGLE_CONCESSION_CHOICE',
  ToggleSupportChoice = 'TOGGLE_SUPPORT_CHOICE',
  SavePlanDetails = 'SAVE_PLAN_DETAILS',
}

export interface PlanErrorPayload {
  readonly error: Error | any;
}

export type PlanPayload =
  | PlanDetails
  | PlanErrorPayload
  | PlanDetailsSubmit
  | PlanRequest
  | boolean
  | any;

export type PlanAction = StoreAction<PlanActionTypes, PlanPayload>;

export class PlanActions {
  public static getPlanDetailsStart(data: PlanRequest): PlanAction {
    return {
      type: PlanActionTypes.GetPlanDetailsStart,
      data,
    };
  }

  public static getPlanDetailsSuccess(data: PlanDetails): PlanAction {
    return { type: PlanActionTypes.GetPlanDetailsSuccess, data };
  }

  public static getPlanDetailsError(data: PlanErrorPayload): PlanAction {
    return { type: PlanActionTypes.GetPlanDetailsError, data };
  }

  public static toggleConcession(data: boolean): PlanAction {
    return { type: PlanActionTypes.ToggleConcessionChoice, data };
  }

  public static toggleSupport(data: boolean): PlanAction {
    return { type: PlanActionTypes.ToggleSupportChoice, data };
  }

  public static saveChoosenPlan(data: PlanDetailsSubmit): PlanAction {
    return { type: PlanActionTypes.SavePlanDetails, data };
  }
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Card, Typography, withStyles } from '@material-ui/core';
import { get, isNil, isUndefined } from 'lodash';
import React, { FC, useState } from 'react';
import { sendMessageToApp, ValidMessages } from '../../../../helper/MessageHelper';
import {
  AcceptanceChecks,
  Link,
  PreAgreementSummary,
  UIAttributes,
} from '../../../services/commonServices/CommonPlanSelectionInterface';
import { styles, SelecteTermsStyles } from './SelectTermsStyles';
import tickWhite from '../../../../assets/tick-white.svg';
import { ServiceDetailsData } from '../../../../models/checkout/Checkout';
import { getTermsAndConditionsValue } from '../SelectOptions/SelectOptionsUtils';

interface SelectTermsProps extends SelecteTermsStyles {
  setTermsAndConditionsFlag: (flag: boolean) => void;
  preAgreementSummary: PreAgreementSummary;
  uiModelAttributes: UIAttributes;
  cardData?: ServiceDetailsData | null;
  isExternal: boolean;
  planLinks: Link[];
}

const SelectTerms: FC<SelectTermsProps> = ({
  classes,
  setTermsAndConditionsFlag,
  preAgreementSummary,
  uiModelAttributes,
  cardData,
  isExternal,
  planLinks,
}) => {
  const { defaultAcceptedChecks } = getTermsAndConditionsValue(cardData, preAgreementSummary);
  const [acceptedChecks, setAcceptedChecks] = useState<number[]>(defaultAcceptedChecks);
  const links =
    !isUndefined(preAgreementSummary) && !!preAgreementSummary.LinkItems.length
      ? [...preAgreementSummary.LinkItems, ...planLinks]
      : [...planLinks];

  return (
    <div style={{ marginLeft: 16, marginRight: 16, paddingTop: 15, paddingBottom: 15 }}>
      {!isNil(preAgreementSummary) ? (
        <div>
          <div
            className={classes.terms}
            dangerouslySetInnerHTML={{
              __html: get(preAgreementSummary, 'Body', ''),
            }}
          />
          {!!links.length && (
            <Card elevation={2} className={classes.detailCard}>
              {uiModelAttributes.Links.Value ? (
                <div className={classes.switchContainer}>
                  <Typography className={classes.linkHeader} variant="h6">
                    {uiModelAttributes.Links.Value}
                  </Typography>
                </div>
              ) : undefined}
              <div className={classes.seperator} />
              {!!links.length && (
                <div className={classes.section}>
                  {links.map((link: Link) => (
                    <>
                      <div className={classes.section}>
                        <div
                          key={`rate-${link.Label}`}
                          onClick={() => {
                            if (isExternal) {
                              window.open(link.Link);
                            } else {
                              sendMessageToApp(ValidMessages.OpenUrl, get(link, 'Link', ''));
                            }
                          }}
                        >
                          <Typography
                            variant="h1"
                            className={`${classes.keyText} ${classes.blueText}`}
                          >
                            {get(link, 'Label', '')}
                          </Typography>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              )}
            </Card>
          )}
        </div>
      ) : undefined}
      <div className={classes.acceptanceChecks}>
        {preAgreementSummary.AcceptanceChecks.map((item: AcceptanceChecks, index: number) => (
          <div className={classes.flexRow}>
            <Typography
              className={classes.termsSection}
              onClick={async () => {
                const updatedAcceptedChecks = [...acceptedChecks];
                if (acceptedChecks.includes(index)) {
                  const arrayIndex = updatedAcceptedChecks.indexOf(index);
                  if (arrayIndex !== -1) {
                    updatedAcceptedChecks.splice(arrayIndex, 1);
                  }
                } else {
                  updatedAcceptedChecks.push(index);
                }
                if (preAgreementSummary.AcceptanceChecks.length === updatedAcceptedChecks.length) {
                  setTermsAndConditionsFlag(true);
                } else {
                  setTermsAndConditionsFlag(false);
                }
                setAcceptedChecks(updatedAcceptedChecks);
              }}
            >
              {!acceptedChecks.includes(index) ? (
                <div className={classes.acceptTermsdefault} />
              ) : (
                <div className={classes.acceptedTerms}>
                  <img src={tickWhite} className={classes.tickImage} />
                </div>
              )}
            </Typography>
            <Typography variant="body1">
              {get(item, 'ComponentText', 'I accept the above terms')}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(SelectTerms);

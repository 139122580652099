import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceMock from '../__mocks__/ApiServiceMock';
import { AppSettings } from '../../AppSettings';
import ApiServiceBase from '../ApiServiceBase';
import { PlanSummary } from './getPlanSummary/GetPlanSummary.data';
import { PostSummaryRequest } from '../../containers/services/otherServices/OtherServicesSummary.data';
import {
  ServiceIframe,
  ServiceIframeInProgress,
} from '../../component/services/commonService/kannopiService/KanopiInterface';

const service = ServiceType.planSummary;
const serviceBff = ServiceType.bffPlanSummary;

export class PlanSummaryService {
  private readonly service: ApiServiceBase = AppSettings.currentTestSetting.testing
    ? new ApiServiceMock(service)
    : new ApiService(service);

  private readonly serviceBff: ApiServiceBase = AppSettings.currentTestSetting.testing
    ? new ApiServiceMock(serviceBff)
    : new ApiService(serviceBff);

  public getPlanSummaryDetails(serviceAccountId: string): Promise<PlanSummary> | PlanSummary {
    return this.service.get({
      route: ['serviceAccountDetails'],
      query: {
        serviceAccountId,
      },
    });
  }

  public getServiceSummaryDetails(
    serviceType: string,
    propertyId: string,
    providerName: string,
  ): Promise<PlanSummary> | PlanSummary {
    return this.service.get({
      route: ['setup-service'],
      query: {
        propertyId,
        serviceType: serviceType.toUpperCase(),
        providerName,
      },
    });
  }

  public setServiceId(
    data: ServiceIframeInProgress | ServiceIframe,
  ): Promise<PlanSummary> | PlanSummary {
    return this.service.post(
      {
        route: ['service-account', 'referral-iframe'],
      },
      data,
    );
  }

  public getServiceInfo(serviceAccountId: string): Promise<PlanSummary> | PlanSummary {
    return this.serviceBff.get({
      route: ['service-account', serviceAccountId],
    });
  }

  public postServiceSummaryDetails(
    serviceName: string,
    propertyId: string,
    body: PostSummaryRequest,
  ): Promise<PlanSummary> | PlanSummary {
    return this.service.post(
      {
        route: ['service-account', 'referral'],
      },
      {
        ...body,
        ...{
          propertyId,
          serviceType: serviceName.replace(/([A-Z])/g, ' $1').trim(),
          providerId: body.serviceDetails.providerId,
        },
      },
    );
  }
}

const planSummaryService = new PlanSummaryService();
export default planSummaryService;

import accountLogo from '../../assets/account.png';
import accountBalanceLogo from '../../assets/account-balance-wallet.png';
import thumbupLogo from '../../assets/thumb-up.png';
import incandescentLogo from '../../assets/wb-incandescent.png';
import homeLogo from '../../assets/home.png';
import { AGENCIES, APP } from '../../helper/AppNameHelper';

export const PROVIDER_MESSAGE = 'Welcomes you to';

export const NAX_PROVIDER_MESSAGE = 'Lets get you moving';

export interface StepItem {
  title: string;
  message: string;
  logo: any;
}

export const bondPaymentEnabledSteps: StepItem[] = [
  {
    title: 'Step 1',
    message: 'Set up your initial payments',
    logo: accountBalanceLogo,
  },
  {
    title: 'Step 2',
    message: 'Set up your home services',
    logo: incandescentLogo,
  },
  {
    title: 'Sorted!',
    message: "You're ready to go",
    logo: thumbupLogo,
  },
];

export const paymentEnabledSteps: StepItem[] = [
  {
    title: 'Step 1',
    message: 'Set up your rental payments',
    logo: accountBalanceLogo,
  },
  {
    title: 'Step 2',
    message: 'Set up your home services',
    logo: incandescentLogo,
  },
  {
    title: 'Sorted!',
    message: "You're ready to go",
    logo: thumbupLogo,
  },
];

export const paymentDisabledSteps = [
  {
    title: 'Step 1',
    message: 'Confirm your rental property',
    logo: accountLogo,
  },
  {
    title: 'Step 2',
    message: 'Set up your home services',
    logo: incandescentLogo,
  },
  {
    title: 'Sorted!',
    message: "You're ready to go",
    logo: thumbupLogo,
  },
];

export const naxWelcomeSteps = [
  {
    title: 'Step 1',
    message: 'Confirm your rental property',
    logo: accountLogo,
  },
  {
    title: 'Step 2',
    message: 'Set up your home services',
    logo: incandescentLogo,
  },
  {
    title: 'Step 3',
    message: 'Get moving!',
    logo: homeLogo,
  },
];

export const taWelcomeSteps = [
  {
    title: 'Step 1',
    message: 'Confirm your rental property',
    logo: accountLogo,
  },
  {
    title: 'Step 2',
    message: 'Arrange your home services',
    logo: incandescentLogo,
  },
  {
    title: 'Step 3',
    message: "You're ready to go!",
    logo: thumbupLogo,
  },
];

export const BUTTON_TEXT = 'Get started';
export const LABELS = {
  BUTTON_TEXT: 'Get started',
  NAV_TITLE: 'Home setup',
  NAV_SUBTITLE: `Let's setup your new place with ${
    APP === AGENCIES.NAX
      ? 'Nelson Alexander '
      : APP === AGENCIES.TA
      ? 'The Agency '
      : APP === AGENCIES.SWITCH
      ? 'The Switch'
      : 'Sorted'
  }`,
  WELCOME: `Welcome to ${
    APP === AGENCIES.NAX
      ? 'Nelson Alexander'
      : APP === AGENCIES.TA
      ? 'The Agency'
      : APP === AGENCIES.SWITCH
      ? 'The Switch'
      : 'Sorted'
  }`,
  WELCOMES:
    APP === AGENCIES.SWITCH
      ? 'Setup your initial payments in few easy steps!'
      : `Welcomes you to ${
          APP === AGENCIES.NAX ? 'Nelson Alexander' : APP === AGENCIES.TA ? 'The Agency' : 'Sorted'
        }!`,
  LIFE_MADE: 'Life made simple.',
};

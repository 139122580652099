import { LeaveListState } from '../state/LeaveListState';
import { LeaveListActionTypes, LeaveListAction } from '../actions/LeaveListAction';
import { Leaves } from '../../models/Leaves.data';

const initialState: LeaveListState = {
  leave: [],
  loading: false,
  error: null,
};

export function LeaveListReducer(state = initialState, action: LeaveListAction): LeaveListState {
  switch (action.type) {
    case LeaveListActionTypes.GetLeaveListStart: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case LeaveListActionTypes.GetLeaveListSuccess: {
      return {
        ...state,
        loading: false,
        error: null,
        leave: action.data as Leaves[],
      };
    }
    case LeaveListActionTypes.GetLeaveListError: {
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    }
    default:
      return state;
  }
}

import * as React from 'react';
import {
  ElectricityPlanSummary,
  BroadbandPlanSummary,
} from '../../services/planSummary/getPlanSummary/GetPlanSummary.data';
import sortedBackground from '../../assets/images/sorted-background.jpg';
import { PlanSuccessStyles, Styles } from './PlanSuccessfulSignUpStyles';
import { withStyles, Typography } from '@material-ui/core';
import { ApplicationState } from '../../store/RootReducers';
import { connect } from 'react-redux';
import { get } from 'lodash';
import FooterComponent from '../footer/FooterComponent';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';
import { QuotesV3 } from '../../containers/services/commonServices/CommonPlanSelectionInterface';
import { FC } from 'react';

interface PlanSuccessfulSignUpProps extends PlanSuccessStyles {
  quotes?: QuotesV3;
  //TODO: Resolve any
  finalData: any;
  planSummary: ElectricityPlanSummary | BroadbandPlanSummary;
}

const PlanSuccessfulSignUp: FC<PlanSuccessfulSignUpProps> = ({ classes, quotes, finalData }) => {
  const supplierBackgroundLogo = (quotes as QuotesV3).Response.Plans[0].SupplierBackgroundLogo
    ? (quotes as QuotesV3).Response.Plans[0].SupplierBackgroundLogo
    : sortedBackground;
  return (
    <div className={classes.margin}>
      {true && (
        <div>
          <img
            src={supplierBackgroundLogo}
            className={classes.serviceHeaderImg}
            alt="Service Header Image"
          />
          <div className={classes.planSuccessMessage}>
            <Typography variant="h2" className={classes.successMessage}>
              {get(quotes, 'Response.Plans.0.PostAgreementSummary.Title', finalData[1].value)}
            </Typography>
            <div
              className={classes.successSubtitle}
              dangerouslySetInnerHTML={{
                __html: get(
                  quotes,
                  'Response.Plans.0.PostAgreementSummary.Body',
                  finalData[0].value,
                ),
              }}
            />
          </div>
          <FooterComponent
            buttonText="BACK TO HOME"
            onClick={() => {
              sendMessageToApp(ValidMessages.Dashboard, { toHome: true });
            }}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  quotes: state.commonSummary.quotes,
  finalData: state.commonSummary.finalData
    ? state.commonSummary.finalData.data
    : [{ value: 'null' }, { value: 'null' }],
});

export default withStyles(Styles)(connect(mapStateToProps)(PlanSuccessfulSignUp));

import * as React from 'react';
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  withStyles,
  CardActions,
  Button,
} from '@material-ui/core';
import { Styles, OverViewStyles } from './OverViewStyle';
import {
  statusCurrent,
  statusDone,
  statusPending,
  statusInProgress,
  button,
} from '../../../containers/services/ServiceConstants';
import clockIcon from '../../../assets/clock.svg';
import deleteIcon from '../../../assets/delete.svg';
import doneIcon from '../../../assets/done.svg';
import doneRedIcon from '../../../assets/done-ta.png';
import { APP, AGENCIES } from '../../../helper/AppNameHelper';
import { isNil } from 'lodash';
import { PlanSummaryStep } from '../../../component/commonPlanSummary/PlanSummaryConstants';

interface PropsFromParent {
  //TODO: Resolve any
  overViewSteps: any;
  serviceTitle: string;
  stepStatus: string[];
  showPersonalDetail: boolean | undefined;
  //TODO: Resolve any
  planSummary: any;
  redirectTo: (stepCode: PlanSummaryStep) => void;
  handleClear?: () => void;
}
interface PropsFormRoute {
  //TODO: Resolve any
  history?: any;
}
type OwnProps = OverViewStyles & PropsFromParent & PropsFormRoute;

class OverView extends React.Component<OwnProps> {
  public render() {
    const { classes, stepStatus } = this.props;
    const onClearStatus = stepStatus.find((status) => status === statusDone);
    return (
      <div className={classes.root}>
        <div className={classes.titleStyle}>
          <Typography variant="h5">{this.props.serviceTitle}</Typography>
          {onClearStatus && (
            <Button className={classes.clearButtonStyle} onClick={this.props.handleClear}>
              <img src={deleteIcon} />
              <Typography className={classes.clear} variant="caption">
                Clear
              </Typography>
            </Button>
          )}
        </div>
        <div>
          {this.props.overViewSteps.map(
            (
              step: //TODO: Resolve any
              any,
              index: number,
            ) => {
              if (index === 2) {
                return this.renderStepCards(
                  step,
                  this.props.stepStatus[index],
                  PlanSummaryStep.PERSONAL_DETAIL_CHECK,
                );
              } else if (index === 3) {
                return this.renderStepCards(
                  step,
                  this.props.stepStatus[index],
                  PlanSummaryStep.ACCEPT_TERMS_AND_CONDITION,
                );
              } else {
                return index === 4 &&
                  !isNil(this.props.planSummary) &&
                  !this.props.planSummary.skipPaymentStep
                  ? this.renderStepCards(
                      step,
                      this.props.stepStatus[index],
                      PlanSummaryStep.PAYMENT_CHECK,
                    )
                  : index === 4
                  ? undefined
                  : this.renderStepCards(
                      step,
                      this.props.stepStatus[index],
                      index === 0
                        ? PlanSummaryStep.ADDRESS_COMPLETION
                        : PlanSummaryStep.PLAN_SELECTION,
                    );
              }
            },
          )}
        </div>
      </div>
    );
  }

  // tslint:disable-next-line:cyclomatic-complexity
  private renderStepCards = (
    //TODO: Resolve any
    stepDetails: any,
    status: string,
    stepCode: PlanSummaryStep,
  ) => {
    const { classes } = this.props;
    const cardStatus = status === statusDone;
    const stepCodeCheck = stepCode === 0;
    const statusText = cardStatus
      ? statusDone
      : !(status === statusCurrent) || stepCodeCheck
      ? statusPending
      : statusInProgress;
    const buttonText = stepCodeCheck ? button.getStarted : button.continue;
    const cardTextStyle = !(cardStatus || status === statusCurrent)
      ? classes.disableCardTextStyle
      : '';
    return (
      <Card className={classes.cardStyle}>
        <CardHeader
          title={stepDetails.title}
          className={classes.cardTitle}
          classes={{
            title: cardTextStyle,
          }}
        />
        <CardContent className={classes.cardContentStyle}>
          <Typography className={`${classes.cardSubtitle} ${cardTextStyle}`}>
            {stepDetails.description}
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActionsStyle}>
          <div className={classes.statusBoxStyle}>
            <img
              src={cardStatus ? (APP === AGENCIES.TA ? doneRedIcon : doneIcon) : clockIcon}
              className={classes.iconStyle}
            />
            <Typography
              variant="caption"
              className={cardStatus ? classes.completedStatusStyle : classes.pendingStatusStyle}
            >
              {statusText}
            </Typography>
          </div>
          <div>
            {status === statusCurrent && (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => this.props.redirectTo(stepCode)}
              >
                <Typography variant="button" className={classes.buttonText}>
                  {buttonText}
                </Typography>
              </Button>
            )}
          </div>
        </CardActions>
      </Card>
    );
  };
}

export default withStyles(Styles)(OverView);

import { store } from '../store/Store';
import { HeaderActions } from '../store/actions/HeaderActions';
import { Params } from '../store/state/HeaderState';

export class BackHelper {
  public static saveCount(count: number) {
    store.dispatch(HeaderActions.saveCount(count));
  }
  public static saveParams(params: Params) {
    store.dispatch(HeaderActions.saveParams(params));
  }
}

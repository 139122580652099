import { color } from '../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  root: {
    'paddingTop': '48px',
    'backgroundColor': color.secondary,
    'height': '100%',
    'boxSizing': 'border-box',
    'scrollbarWidth': 'none',
    'msOverflowStyle': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  scrollContainer: {
    paddingBottom: '135px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },
});

export interface CheckoutSummaryStyles extends WithStyles<typeof styles> {}

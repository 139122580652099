import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceMock from '../__mocks__/ApiServiceMock';
import { AppSettings } from '../../AppSettings';
import ApiServiceBase from '../ApiServiceBase';
import { PostOnboardingStatusRequest } from './postOnboardingStatus/PostOnboardingStatusRequest.data';

const serviceType = ServiceType.Payment;

export class SummaryService {
  private readonly service: ApiServiceBase = AppSettings.currentTestSetting.testing
    ? new ApiServiceMock(serviceType)
    : new ApiService(serviceType);

  public postOnboardingStatus(data: PostOnboardingStatusRequest) {
    return this.service.post(
      {
        route: ['onboard/updateStatus'],
      },
      data,
    );
  }
}

const summaryService = new SummaryService();
export default summaryService;

import { createStyles, WithStyles } from '@material-ui/core';
import { color } from '../../../theme/Theme';

export const Styles = createStyles({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '25px 16px 0 16px',
    marginTop: 48,
    marginBottom: 60,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
  },
  mainHeading: {
    marginBottom: '34px',
  },
  subtitle: {
    marginBottom: '15px',
  },
  list: {
    paddingTop: '18px',
    paddingBottom: '18px',
    paddingLeft: '2px',
    paddingRight: '2px',
    margineTop: '8px',
    justifyContent: 'space-between',
  },
  locationIcon: {
    width: '18px',
    height: '20px',
  },
  addressNotShownButton: {
    padding: '10px 16px',
    height: 36,
  },
  headIcon: {
    marginTop: '65px',
  },
  heading: {
    marginTop: '38px',
  },
  paragraph: {
    margin: '16px',
  },
  primaryText: {
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  secondaryText: {
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  NmiNumber: {
    fontSize: '16px',
    color: color.completed,
  },
});

export interface AddressListStyles extends WithStyles<typeof Styles> {}

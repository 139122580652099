import { color } from '../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const Styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    marginTop: 48,
  },
  serviceHeader: {
    display: 'flex',
    paddingTop: 15,
  },
  serviceHeaderText: {
    padding: 5,
  },
  serviceContent: {
    padding: '0px 15px 15px 15px',
    marginTop: 0,
  },
  serviceContentDesc: {
    padding: 15,
    marginTop: 0,
  },
  planCards: {
    height: 73,
    margin: '15px 0 15px 0',
  },
  serviceCard: {
    width: '100%',
    marginTop: -10,
    zIndex: 999,
    borderRadius: 8,
    position: 'absolute',
  },
  serviceHeaderImg: {
    marginTop: 40,
    height: 230,
    width: '100%',
  },
  serviceLogo: {
    height: 60,
    width: 60,
    marginLeft: 10,
    marginRight: 5,
    borderRadius: 30,
  },
  avatarIcon: {
    fill: 'yellow',
  },
  colorBlack: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  key: {
    fontSize: 13,
    color: 'rgba(0, 0, 0, 0.38)',
  },
  value: {
    fontFamily: 'GT Walsheim Regular',
    fontSize: 17,
  },
  list: {
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '10px',
    paddingRight: '2px',
    margineTop: '8px',
    background: '#F8F8F8',
    border: '1px solid #eee',
    borderRadius: 5,
    justifyContent: 'space-between',
  },
  locationIcon: {
    width: '18px',
    height: '20px',
  },
  primaryText: {
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.87)',
  },

  secondaryText: {
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  NmiNumber: {
    fontSize: '16px',
    color: color.completed,
  },
  addressNotShownButton: {
    height: 36,
    marginBottom: 20,
  },
  item: {
    margin: '10px 20px 20px 20px',
  },
  section: {
    borderRadius: 8,
    border: '1px solid rgba(0, 0, 0, 0.1)',
    marginBottom: 25,
  },
  sectionTitle: {
    fontFamily: 'GT Walsheim Regular',
    margin: '10px 20px',
    fontSize: 17,
  },
  infoContainer: {
    padding: 15,
    marginTop: -5,
    background: 'white',
    width: '92%',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    position: 'relative',
  },
  successMessage: {
    fontSize: 17,
    marginTop: 20,
    fontFamily: 'GT Walsheim Regular',
  },
  successSubtitle: {
    fontSize: 15,
    fontWeight: 200,
    fontStyle: 'normal',
    marginLeft: 20,
    marginRight: 20,
  },
  margin: { marginBottom: 100 },
  planSuccessMessage: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  infoHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '91%',
    position: 'absolute',
    padding: '10px 20px',
    marginTop: -70,
  },
  serviceInfoLogo: {
    height: 40,
    width: 40,
  },
  serviceInfoName: {
    color: 'white',
    fontSize: 29,
  },
  vignette: {
    boxShadow: '0 0 100px rgba(0,0,0,0.9) inset',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  providerName: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
  },
  serviceProviderLogo: {
    height: 40,
    width: 40,
    marginRight: 15,
  },
  serviceDescriptionText: {
    padding: 10,
  },
});

export interface PlanSuccessStyles extends WithStyles<typeof Styles> {}

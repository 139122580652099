import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import {
  AddAndUpdateCartRequest,
  AvailableServicesRequest,
  AvailableServicesResponse,
  CheckoutResponse,
  GetCartResponse,
  RemindMeLaterRequest,
  ServiceTypes,
} from '../../models/checkout/Checkout';
import { store } from '../../store/Store';

export class CheckoutService {
  // private readonly service: ApiServiceBase = new ApiService(ServiceType.checkout);
  private readonly cartService: ApiServiceBase = new ApiService(ServiceType.cart);

  public getAvailableServices(propertyId: string, requiredServiceTypes: string[]): Promise<GetCartResponse> | GetCartResponse {
    const { isLandlord } = store.getState().checkout;
    const requiredServices = requiredServiceTypes.map(data => data.toUpperCase())
    return this.cartService.get<GetCartResponse>({
      route: ['user'],
      query: {
        propertyId: propertyId,
        customerProfile: isLandlord ? 'LANDLORD' : undefined,
        requiredServiceTypes: requiredServices.join(',')
      },
    });
  }

  public addAndUpdateCart(
    propertyId: string,
    platform: string,
    body: AddAndUpdateCartRequest[],
  ): Promise<GetCartResponse> | GetCartResponse {
    const { isLandlord } = store.getState().checkout;
    return this.cartService.post<GetCartResponse>(
      {
        route: ['user'],
        query: {
          propertyId: propertyId,
          platform: platform,
          customerProfile: isLandlord ? 'LANDLORD' : undefined,
        },
      },
      body,
    );
  }

  public checkout(propertyId: string, checkoutServiceTypes: ServiceTypes[]): Promise<CheckoutResponse[]> | CheckoutResponse[] {
    const checkoutServices = checkoutServiceTypes.map(data => data.toUpperCase())
    return this.cartService.post<CheckoutResponse[]>(
      {
        route: ['user', 'checkout'],
        query: {
          propertyId: propertyId,
          checkoutServiceTypes: checkoutServices.join(',')
        },
      },
      {},
    );
  }

  public deleteCartItem(
    propertyId: string,
    serviceType: string,
    planId: string,
    supplierId: string,
  ): Promise<CheckoutResponse[]> | CheckoutResponse[] {
    return this.cartService.delete<CheckoutResponse[]>({
      route: ['user'],
      query: {
        propertyId,
        serviceType,
        planId,
        supplierId,
      },
    });
  }

  public remindMeLater(data: RemindMeLaterRequest) {
    return this.cartService.post(
      {
        route: ['user', 'reminder', `${data.propertyId}`],
        query: {
          flag: data.flag,
        },
      },
      {},
    );
  }
}

const checkoutService = new CheckoutService();
export default checkoutService;

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useEffect, useState, useMemo } from 'react';
import { withStyles } from '@material-ui/core';
import { styles, CartItemStyles } from './CartItemStyles';
import { AvailableServicesResponse } from '../../../../models/checkout/Checkout';
import Plan from '../../../checkoutSelect/components/Plan/Plan';
import { store } from '../../../../store/Store';
import { CheckoutActions } from '../../../../store/actions/CheckoutActions';

interface CartItemProps extends CartItemStyles {
  hideAction?: boolean;
  cartItem: AvailableServicesResponse;
  frequency?: string;
}

const CartItem: FC<CartItemProps> = ({ classes, hideAction, cartItem, frequency }) => {
  return (
    <div className={classes.cartContainer}>
      <div className={classes.serviceHeader}>
        <div className={classes.serviceTypeIconContainer}>
          <img className={classes.serviceTypeIcon} src={cartItem.logo} alt="service type" />
        </div>
        <div className={classes.serviceTypeTitle}>{cartItem.type}</div>
      </div>
      <Plan
        plan={cartItem.suppliers![0].plans[0]}
        serviceType={cartItem.type}
        supplier={cartItem.suppliers![0]}
        logo={cartItem.suppliers![0].logo}
        cartView
        hideAction={hideAction}
        handleAction={() => null}
        frequency={frequency}
      />
    </div>
  );
};

export default withStyles(styles)(CartItem);

import { TypeHelper } from '../TypeHelper';

export enum ContentCategory {
  Image,
  Text,
  Document,
  Video,
}

export enum ContentType {
  jpeg,
  png,
  bmp,
  tiff,
  heic,
  plainText,
  json,
  pdf,
  mp4,
  m4v,
  mov,
  webm,
}

export class ContentTypeHelper {
  public static readonly allContentTypes = [
    ContentType.jpeg,
    ContentType.png,
    ContentType.bmp,
    ContentType.tiff,
    ContentType.heic,
    ContentType.plainText,
    ContentType.json,
    ContentType.pdf,
    ContentType.mp4,
    ContentType.m4v,
    ContentType.mov,
    ContentType.webm,
  ];

  public static getExtensions(ctype: ContentType): string[] {
    // order is important
    return [
      ['jpg', 'jpeg'], // jpeg
      ['png'],
      ['bmp'],
      ['tiff'],
      ['heic'],
      ['txt', 'text'], // plainText
      ['json'],
      ['pdf'],
      ['mp4'],
      ['m4v'],
      ['mov'],
      ['webm'],
    ][ctype];
  }
  public static getCategory(ctype: ContentType): ContentCategory {
    // order is important
    return [
      ContentCategory.Image, // jpg
      ContentCategory.Image, // png
      ContentCategory.Image, // bpm
      ContentCategory.Image, // tiff
      ContentCategory.Image, // heic
      ContentCategory.Text,
      ContentCategory.Text,
      ContentCategory.Document,
      ContentCategory.Video,
      ContentCategory.Video,
      ContentCategory.Video,
      ContentCategory.Video,
    ][ctype];
  }

  public static getAllByCategory(cat: ContentCategory): ContentType[] {
    return ContentTypeHelper.allContentTypes.filter(
      (t) => ContentTypeHelper.getCategory(t) === cat,
    );
  }

  public static parseContentType(ctype: string): ContentType | undefined {
    switch (ctype) {
      case 'image/jpg':
      case 'image/jpeg':
        return ContentType.jpeg;
      case 'image/png':
        return ContentType.png;
      case 'image/tiff':
        return ContentType.tiff;
      case 'image/bmp':
        return ContentType.bmp;
      case 'text/plain':
        return ContentType.plainText;
      case 'text/json':
        return ContentType.json;
      case 'application/pdf':
      case 'application/x-pdf':
        return ContentType.pdf;
      default:
        return undefined;
    }
  }

  public static parseExtension(fileName: string): ContentType | undefined {
    if (!fileName) {
      return undefined;
    }
    const i = fileName.lastIndexOf('.');
    const ext = i >= 0 ? fileName.substr(i + 1) : fileName;

    if (!ext) {
      return undefined;
    }

    return ContentTypeHelper.allContentTypes.find(
      (ctype) =>
        !!ContentTypeHelper.getExtensions(ctype).find((t) =>
          TypeHelper.safeStringEquals(t, ext, true, true),
        ),
    );
  }

  public static async convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(blob);
    });
  }

  public static base64ToBlob(dataURI: string) {
    const arr: string[] = dataURI.split(',');
    const byteCharacters = atob(arr[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });
    return blob;
  }
}

import { BroadbandServiceState } from '../state/BroadbandServiceState';
import {
  BroadbandServiceAction,
  BroadbandServiceActionTypes,
} from '../actions/BroadbandServiceActions';
import { BroadbandAddressLookup } from '../../services/broadbandService/getBroadbandAddressLookup/GetBroadbandAddressLookup.data';
import { BroadbandServiceDetails } from '../../services/broadbandService/getBroadbandService/GetBroadbandService.data';
import { PropertyDetails } from '../../services/electricityService/getElectricityService/GetElectricityService.data';

const broadbandServiceInitialState: BroadbandServiceState = {
  service: undefined,
  addressLookup: [],
  error: undefined,
  loading: false,
  property: undefined,
  serviceAddress: undefined,
};

// tslint:disable-next-line:cyclomatic-complexity
export function broadbandServiceReducer(
  state = broadbandServiceInitialState,
  action: BroadbandServiceAction,
): BroadbandServiceState {
  switch (action.type) {
    case BroadbandServiceActionTypes.GetBroadbandServiceStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case BroadbandServiceActionTypes.GetBroadbandServiceSuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        service: action.data as BroadbandServiceDetails,
      };
    case BroadbandServiceActionTypes.GetBroadbandServiceError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    case BroadbandServiceActionTypes.PostBroadbandServiceStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case BroadbandServiceActionTypes.PostBroadbandServiceSuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        service: action.data as BroadbandServiceDetails,
      };
    case BroadbandServiceActionTypes.PostBroadbandServiceError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    case BroadbandServiceActionTypes.GetBroadbandServiceAddressLookupStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case BroadbandServiceActionTypes.GetBroadbandServiceAddressLookupSuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        addressLookup: action.data as BroadbandAddressLookup,
      };
    case BroadbandServiceActionTypes.GetBroadbandServiceAddressLookupError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    case BroadbandServiceActionTypes.SaveProperty:
      return {
        ...state,
        property: action.data as PropertyDetails,
      };
    case BroadbandServiceActionTypes.DeleteBroadbandServiceStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case BroadbandServiceActionTypes.DeleteBroadbandServiceSuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        service: action.data as BroadbandServiceDetails,
      };
    case BroadbandServiceActionTypes.DeleteBroadbandServiceError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    case BroadbandServiceActionTypes.GetBroadbandAddressStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case BroadbandServiceActionTypes.GetBroadbandAddressSuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        serviceAddress: action.data as PropertyDetails,
      };
    case BroadbandServiceActionTypes.GetBroadbandAddressError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    default:
      return state;
  }
}

import { StoreAction } from '../StoreHelper';
import { Leaves } from '../../models/Leaves.data';

export enum LeaveListActionTypes {
  GetLeaveListStart = 'GET_LEAVE_LIST_START',
  GetLeaveListSuccess = 'GET_LEAVE_LIST_SUCCESS',
  GetLeaveListError = 'GET_LEAVE_LIST_ERROR',
}

export type LeaveListPayload = Error | Leaves[] | number;

export type LeaveListAction = StoreAction<LeaveListActionTypes, LeaveListPayload>;

export class LeaveListActions {
  public static getLeaveListStart(data: number): LeaveListAction {
    return {
      type: LeaveListActionTypes.GetLeaveListStart,
      data,
    };
  }

  public static getLeaveListSuccess(data: Leaves[]): LeaveListAction {
    return { type: LeaveListActionTypes.GetLeaveListSuccess, data };
  }

  public static getLeaveListError(data: Error): LeaveListAction {
    return { type: LeaveListActionTypes.GetLeaveListError, data };
  }
}

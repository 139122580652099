import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceMock from '../__mocks__/ApiServiceMock';
import { GetPaymentTypeResponse } from './getPaymentTypes/GetPaymentTypesResponse.data';
import { PostPaymentUUIDResponse } from './postPaymentUUID/PostPaymentUUIDResponse.data';
import { PostPaymentUUIDRequest } from './postPaymentUUID/PostPaymentUUIDRequest.data';
import { AppSettings } from '../../AppSettings';
import ApiServiceBase from '../ApiServiceBase';
import { GetPaymentMethodsResponse } from './getPaymentMethods/GetPaymentMethods.data';
import { GetPaymentAuthKey } from './getPaymentAuthKey/GetPaymentAuthKey.data';
import {
  PostPaymentToken,
  PostPaymentTokenResponse,
} from './postPaymentToken/PostPyamentToken.data';
import { GetFastPayResponse, PayIdPayLoad } from './getFastPayIDResponse/GetFastPayIdResponse';
import {
  GetStripePaymentIntentRequest,
  GetStripePaymentIntentResponse,
} from './stripeCheckout/StripeCheckout';
import { PaymentGateway } from '../../containers/services/commonServices/CommonPlanSelectionInterface';
import { GetRefIdResponse } from '../../models/payment/PaymentRequest.data';

const service = ServiceType.Payment;

export class PaymentService {
  private readonly service: ApiServiceBase = AppSettings.currentTestSetting.testing
    ? new ApiServiceMock(service)
    : new ApiService(service);

  public getPaymentTypes(data: {
    propertyId: string;
    serviceType: string;
  }): Promise<GetPaymentTypeResponse> | GetPaymentTypeResponse {
    return this.service.get({
      route: ['branch/paymentMethods', data.propertyId, data.serviceType],
    });
  }

  public postPaymentUUID(
    data: PostPaymentUUIDRequest,
  ): Promise<PostPaymentUUIDResponse> | PostPaymentUUIDResponse {
    return this.service.post(
      {
        route: ['user/paymentMethods'],
      },
      data,
    );
  }
  public getPaymentMethods(): Promise<GetPaymentMethodsResponse> | GetPaymentMethodsResponse {
    return this.service.get({
      route: ['user/paymentMethods'],
    });
  }
  public getPaymentPayId(data: PayIdPayLoad): Promise<GetFastPayResponse> {
    const servicePayId = new ApiService(ServiceType.PaymentPayId);
    return servicePayId.post(
      {
        route: ['requestFastPayPaymentsList'],
      },
      data,
    );
  }
  public getPaymentAuthKey(
    paymentType?: PaymentGateway,
    paymentMethodType?: string,
    providerId?: string,
  ): Promise<GetPaymentAuthKey> | GetPaymentAuthKey {
    return this.service.get({
      route: ['paymentMethods/authKey'],
      query: {
        paymentType,
        paymentMethodType,
        providerId,
      },
    });
  }

  public getPaymentRefId(
    token: string,
    providerId?: string,
    transactionId?: string,
  ): Promise<GetRefIdResponse> | GetRefIdResponse {
    return this.service.get({
      route: ['paymentMethods', 'accountDetails', token],
      query: {
        providerId,
        transactionId,
      },
    });
  }

  public postPaymentToken(
    data: PostPaymentToken,
  ): Promise<PostPaymentTokenResponse> | PostPaymentTokenResponse {
    return this.service.post(
      {
        route: ['user/paymentMethods'],
      },
      data,
    );
  }
  public deletePayment(paymentRefId: string): Promise<string[]> | string[] {
    return this.service.delete({
      route: ['user/paymentMethods', paymentRefId],
    });
  }

  public getStripePaymentIntent(
    data: GetStripePaymentIntentRequest,
  ): Promise<GetStripePaymentIntentResponse> | GetStripePaymentIntentResponse {
    return this.service.post(
      {
        route: ['stripe/payment-intent'],
      },
      {
        totalAmount: data.totalAmount,
        cartItemId: data.cartItemId,
      },
    );
  }
}

const paymentService = new PaymentService();
export default paymentService;

import * as React from 'react';
import { Button, Typography, withStyles, List, ListItem, ListItemText } from '@material-ui/core';
import { BUTTON_TEXT, labels } from './TermsAndConditionsConstants';
import { Styles, TermsAndConditionsStyle } from './TermsAndConditionsStyle';
import { PropsObjectType } from '../../../containers/services/termsAndConditions/TermsAndConditions';

interface PropsFromParent {
  personalDetails: PropsObjectType[];
  togglePlanDetailsDrawer: () => boolean;
}
type OwnProps = TermsAndConditionsStyle & PropsFromParent;

class PersonalDetailsModal extends React.Component<OwnProps> {
  public render() {
    const { classes, personalDetails } = this.props;
    return (
      <div className={classes.bottomDrawer}>
        <Typography className={classes.bottomDrawerTitle}>{labels.personalCardTitle}</Typography>
        <List>
          {personalDetails &&
            personalDetails.map((data) => (
              <ListItem>
                <ListItemText
                  primary={<Typography variant="subtitle1">{data.key}</Typography>}
                  secondary={<Typography variant="caption">{data.value}</Typography>}
                />
              </ListItem>
            ))}
        </List>
        <div className={classes.drawerFooter}>
          <Button
            onClick={this.props.togglePlanDetailsDrawer}
            variant="contained"
            className={classes.drawerButtons}
          >
            <Typography variant="body2" className={classes.drawerButtonText}>
              {BUTTON_TEXT}
            </Typography>
          </Button>
        </div>
      </div>
    );
  }
}
export default withStyles(Styles)(PersonalDetailsModal);

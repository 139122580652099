import ApiServiceBase from '../ApiServiceBase';
import { ServiceType } from '../ServiceType.data';
import { AppSettings } from '../../AppSettings';
import ApiServiceMock from '../__mocks__/ApiServiceMock';
import ApiService from '../ApiService';
import { PropertyDetails } from '../electricityService/getElectricityService/GetElectricityService.data';

const serviceType = ServiceType.GetAddress;

export class AddressService {
  private readonly service: ApiServiceBase = AppSettings.currentTestSetting.testing
    ? new ApiServiceMock(serviceType)
    : new ApiService(serviceType);
  public getAddressService(propertyId: string): Promise<PropertyDetails> | PropertyDetails {
    return this.service.get({
      route: ['property', propertyId],
    });
  }
}

const addressService = new AddressService();
export default addressService;

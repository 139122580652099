/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable default-case */
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../store/RootReducers';
import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  Snackbar,
  withStyles,
} from '@material-ui/core';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { styles, PaymentSectionStyles } from './PaymentSectionStyles';
import { LABELS } from './PaymentSectionConstants';
import { GetPaymentMethodsResponse } from '../../../../services/payment/getPaymentMethods/GetPaymentMethods.data';
import {
  PaymentActions,
  PaymentErrorPayload,
  SaveSelectedPaymentMethodPayload,
} from '../../../../store/actions/PaymentActions';
import { GetPaymentType } from '../../../../services/payment/getPaymentTypes/GetPaymentTypes.data';
import { PAYMENT_LABELS } from '../../../payment/selectPayment/PaymentDetailsConstants';
import { paymentTypes as PaymentType } from '../../../../component/wallet/savedPaymentMethods/SavedPaymentMethodsConstants';
import selectedIcon from '../../../../assets/radio-filled.png';
import selectedIconBlack from '../../../../assets/radio-filled-black.png';
import radioEmpty from '../../../../assets/radio-empty.png';
import addIcon from '../../../../assets/images/payment/filled.png';
import bankIcon from '../../../../assets/images/payment/account-balance-24-px.svg';
import cardIcon from '../../../../assets/images/payment/credit-card-24-px.svg';
import calendarIcon from '../../../../assets/outlined.svg';
import errorIcon from '../../../../assets/error-outline-24-px.svg';
import lockIcon from '../../../../assets/lock-24-px.svg';
import {
  cardHolderNameMaxLength,
  cardHolderNameRegex,
  cardNumberLength,
  cardNumberPattern,
  CardType,
  cardTypeNotSupportedError,
  expiryDateError,
  expiryDateLength,
  expiryDatePattern,
  InitialCardValues,
  initialCardValues,
  invalidCardError,
  nameFormatError,
  nameMaxLengthError,
  requiredFieldError,
} from '../../../payment/addPayment/addCard/AddCardConstants';
import value from 'card-validator';
import {
  maskBankAccountDetails,
  maskCardDetails,
} from '../../../payment/addPayment/AddPaymentUtils';
import CustomInput from '../../../../component/customInput/CustomInput';
import {
  initialBankValues,
  lengths,
  accountNameRegex,
  bsbNumberRegex,
  accountNumberRegex,
  errorMessages,
  bsbValidatorPattern,
  InitialBankValues,
} from '../../../payment/addPayment/addBank/AddBankConstants';
import { CardHepler } from '../../../../helper/CardHelper';
import { dateValidator } from '../../../../helper/DateValidator';
import { renderCardIcon } from '../../../payment/addPayment/addCard/AddCardUtils';
import {
  BankAccountRequest,
  CreditCardRequest,
} from '../../../../models/payment/PaymentRequest.data';
import { SpinnerActions } from '../../../../store/actions/SpinnerActions';
import { AvailableServicesResponse, Property } from '../../../../models/checkout/Checkout';
import { getServicePaymentRules } from './PaymentSectionUtils';
import { AGENCIES, APP } from '../../../../helper/AppNameHelper';
import { PaymentGateway } from '../../../services/commonServices/CommonPlanSelectionInterface';
import tick from '../../../../assets/radio-outlined.png';

interface PaymentSectionProps extends PaymentSectionStyles {
  serviceType: string;
  property: Property;
  accessToken: string | boolean | null;
  paymentMethods: GetPaymentMethodsResponse[];
  paymentErrorState: any;
  selectedPaymentRefId: string;
  cartItem: AvailableServicesResponse;
  saveSelectedPaymentMethod: (data: SaveSelectedPaymentMethodPayload) => void;
  getPaymentMethods: (onSuccess: (response: GetPaymentMethodsResponse[]) => void) => void;
  getPaymentTypes: (
    data: GetPaymentType,
    isPaymentMethods: boolean,
    onSuccess: (isPaymentMethods: boolean) => void,
  ) => void;
  getPaymentAuthKey: (
    data: BankAccountRequest | CreditCardRequest,
    fetchPayments: () => void,
    setPaymentMethod: (refId: string) => void,
    setSucceeded: (value: boolean) => void,
    paymentGateway?: PaymentGateway,
    providerId?: string,
    transactionId?: string,
  ) => void;
  setPaymentMethod: (refId: string) => void;
  setHeightOnAddNew: () => void;
  setPaymentRefIdOnAddNew: () => void;
}

const PaymentSection: FC<PaymentSectionProps> = ({
  classes,
  serviceType,
  property,
  accessToken,
  paymentMethods,
  paymentErrorState,
  selectedPaymentRefId,
  cartItem,
  saveSelectedPaymentMethod,
  getPaymentMethods,
  getPaymentTypes,
  getPaymentAuthKey,
  setPaymentMethod,
  setHeightOnAddNew,
  setPaymentRefIdOnAddNew,
}) => {
  const paymentRules = getServicePaymentRules(cartItem);
  const selectedPaymentMethods = paymentMethods.findIndex(
    (paymentMethod) => paymentMethod.refId === selectedPaymentRefId,
  );
  const [index, setIndex] = useState<number | boolean>(
    selectedPaymentMethods !== -1 ? selectedPaymentMethods : false,
  );
  const [payment, setPayment] = useState<boolean | GetPaymentMethodsResponse>(false);
  const [succeeded, setSucceeded] = useState<boolean>(
    (!!cartItem.suppliers![0].plans[0].cartData!.paymentRefId &&
      paymentRules.paymentType === PaymentGateway.WESTPAC_QUICK) ||
      false,
  );
  const [addNew, setAddNew] = useState<boolean>(!paymentRules.saveToDB || false);
  const [newMethod, setNewMethod] = useState<PaymentType>(
    paymentRules.paymentMethods.includes(PaymentType.Bank) ? PaymentType.Bank : PaymentType.Credit,
  );

  useEffect(() => {
    if (addNew) {
      // call current height func here
      setHeightOnAddNew();
    }
  }, [addNew]);

  // Card State
  const cardExpiryValidator = CustomInput(expiryDatePattern);
  const cardNumberValidator = CustomInput(cardNumberPattern);
  const [cardType, setCardType] = useState<string>('');
  const [cardError, setCardError] = useState<boolean>(false);
  const [cardErrorValue, setCardErrorValue] = useState<string>('');
  const [cvvLength, setCvvLength] = useState<number>(3);
  const [expiryError, setExpiryError] = useState<boolean>(false);
  const [cardTypeValidation, setCardTypeValidation] = useState<boolean>(true);

  // Bank State
  const bsbNumberValidator = CustomInput(bsbValidatorPattern);
  const [accountNumberError, setAccountNumberError] = useState<boolean>(false);

  useEffect(() => {
    if (accessToken) {
      getPaymentTypes({ propertyId: property.id!.toString(), serviceType }, true, () => true);
    }
  }, []);

  useEffect(() => {
    if (paymentMethods && paymentMethods.length > 0) {
      const selectedPaymentMethodsIndex = paymentMethods.findIndex(
        (paymentMethod) => paymentMethod.refId === selectedPaymentRefId,
      );
      setIndex(selectedPaymentMethodsIndex !== -1 ? selectedPaymentMethodsIndex : false);
    }
  }, [paymentMethods]);

  const handlePaymentChange = (paymentToChange: GetPaymentMethodsResponse, idx: number) => {
    if (idx === index) {
      setIndex(false);
      setPaymentMethod('');
      return;
    }
    setPayment(paymentToChange);
    setIndex(idx);
    setAddNew(false);
    // update payment method ref id here
    setPaymentMethod(paymentToChange.refId);
  };

  const handleSetMethod = (method: PaymentType) => {
    setIndex(false);
    setNewMethod(method);
  };

  // Card methods
  const handleCardNumber = (cardValue: string) => {
    if (cardValue.length < 4) {
      setCardType('');
      setCardTypeValidation(true);
    } else if (cardType === '' && cardValue.length >= 4) {
      const { card } = value.number(cardValue);
      const type = card && (card as any).type;
      const cvvLength = card && card.code.size;
      const cardTypeValidation = type === CardType.Visa || type === CardType.MasterCard;
      setCardType(type);
      setCvvLength(cvvLength as number);
      setCardTypeValidation(cardTypeValidation);
    }
    const valid = value.number(cardValue.replace(/\s+/g, '')).isValid;
    switch (true) {
      case cardValue.length === cardNumberLength && !valid:
        setCardError(true);
        setCardErrorValue(invalidCardError);
        break;
      case !cardTypeValidation:
        setCardError(true);
        setCardErrorValue(`${cardType ? cardType : ''} ${cardTypeNotSupportedError}`);
        break;
      case cardError && cardTypeValidation:
        setCardError(false);
        setCardErrorValue('');
        break;
    }
  };

  const expiryDateValidation = (date: string) => {
    if (date.length === expiryDateLength) {
      setExpiryError(dateValidator(date, 'paymentCard'));
    } else if (expiryError) {
      setExpiryError(false);
    }
  };

  const saveNewCard = (data: InitialCardValues) => {
    const expiryDate = data.expiryDate.split('/');
    const paymentRequest: CreditCardRequest = {
      type: 'CREDITCARD',
      cardHolderName: data.cardHolderName,
      cardNumber: data.cardNumber,
      email: '',
      expiryMonth: expiryDate[0],
      expiryYear: expiryDate[1],
    };
    const maskedCardNumber = maskCardDetails(data.cardNumber.slice(-4), cardType);
    saveSelectedPaymentMethod({
      paymentMethodId: '1',
      maskedDetails: maskedCardNumber,
    });
    getPaymentAuthKey(
      paymentRequest,
      () => getPaymentMethods(() => true),
      setPaymentMethod,
      setSucceeded,
      paymentRules.paymentType,
      cartItem.suppliers![0].providerId,
      cartItem.suppliers![0].plans[0].cartData!.transactionId!,
    );
  };

  // Bank methods
  const handleAccountNumber = (accountNumber: string, setFieldValue: any) => {
    setFieldValue('accountNumber', accountNumber.replace(accountNumberRegex, ''));
    if (accountNumber.length > 10) {
      setAccountNumberError(true);
    } else if (accountNumberError) {
      setAccountNumberError(false);
    }
  };

  const saveNewBank = (data: InitialBankValues) => {
    const maskedBankAccountDetails = maskBankAccountDetails(data.accountNumber, data.bsbNumber);
    const paymentRequest: BankAccountRequest = {
      type: 'BANKACCOUNT',
      accountName: data.accountName,
      accountNumber: data.accountNumber,
      bsbNumber: data.bsbNumber,
      email: '',
    };
    saveSelectedPaymentMethod({
      paymentMethodId: '2',
      maskedDetails: maskedBankAccountDetails,
    });
    getPaymentAuthKey(
      paymentRequest,
      () => getPaymentMethods(() => true),
      setPaymentMethod,
      setSucceeded,
      paymentRules.paymentType,
      cartItem.suppliers![0].providerId,
      cartItem.suppliers![0].plans[0].cartData!.transactionId!,
    );
  };

  const renderFooter = (disabled: boolean, handleSubmit: () => void) => (
    <div className={classes.addNewPaymentFooter}>
      <div className={classes.secureBoxStyle}>
        <img src={lockIcon} className={classes.secureLockStyle} alt="lock" />
        <div className={classes.secureText}>{LABELS.SECURE_CONNECTION}</div>
      </div>
      <div className={classes.cancelNewOptionSave} onClick={() => setAddNew(false)}>
        {LABELS.CANCEL}
      </div>
      <div className={classes.addNewOptionSave} onClick={() => handleSubmit()}>
        {LABELS.SAVE}
      </div>
    </div>
  );

  return (
    <div className={classes.sectionContainer}>
      {/* <div className={classes.sectionTitleContainer}>
          <div className={classes.sectionTitle}>{LABELS.PAYMENT}</div>
        </div> */}

      {/* Saved Payment methods */}
      {succeeded ? (
        <div className={classes.successContainer}>
          <img src={tick} className={classes.successIcon} alt="" />
          <div className={classes.successText}>{LABELS.CAPTURED}</div>
        </div>
      ) : (
        <>
          {paymentRules.saveToDB &&
            paymentMethods &&
            paymentMethods.map((paymentMethod: GetPaymentMethodsResponse, idx: number) => {
              const paymentMethodDetails =
                paymentMethod.paymentMethod === PaymentType.Bank
                  ? PAYMENT_LABELS.paymentMethodDetails.Bank
                  : PAYMENT_LABELS.paymentMethodDetails.Credit.find(
                      (data) => data.cardType === paymentMethod.cardType,
                    )!;
              return (
                <div
                  onClick={() => handlePaymentChange(paymentMethod, idx)}
                  className={classes.menuItem}
                  key={idx}
                >
                  <div className={classes.iconContainer}>
                    <img src={paymentMethodDetails.logo} className={classes.iconImage} alt="" />
                  </div>
                  <div className={classes.optionContainer}>
                    <div className={classes.optionTitle}>{paymentMethodDetails.name}</div>
                    <div className={classes.optionSubtitle}>
                      {paymentMethod.paymentMethod === 'Bank'
                        ? `${paymentMethod.bsb} - ${paymentMethod.accountNumber}`
                        : paymentMethod.paymentMethod === 'Credit'
                        ? `${paymentMethod.cardNumber.replace(/\./g, '*')}`
                        : '**** **** **** 1234'}
                    </div>
                  </div>
                  <div className={classes.tickContainer}>
                    <img
                      src={idx === index ? selectedIcon : radioEmpty}
                      className={classes.tick}
                      alt=""
                    />
                  </div>
                </div>
              );
            })}
          {/* Add new */}
          {addNew ? (
            <>
              <div className={classes.addNewOptionContainer}>
                {paymentRules.paymentMethods.length > 1 ? (
                  <>
                    <div
                      className={classes.addNewOption}
                      onClick={() => handleSetMethod(PaymentType.Credit)}
                    >
                      <div className={classes.tickContainer} style={{ marginLeft: 0 }}>
                        <img
                          src={
                            newMethod === PaymentType.Credit
                              ? APP === AGENCIES.NAX
                                ? selectedIconBlack
                                : selectedIcon
                              : cardIcon
                          }
                          className={classes.tick}
                          alt="credit card"
                        />
                      </div>
                      <div className={classes.addNewOptionText}>
                        {PAYMENT_LABELS.creditCardLabel}
                      </div>
                    </div>
                    <div
                      className={classes.addNewOption}
                      onClick={() => handleSetMethod(PaymentType.Bank)}
                    >
                      <div className={classes.tickContainer} style={{ marginLeft: 0 }}>
                        <img
                          src={
                            newMethod === PaymentType.Bank
                              ? APP === AGENCIES.NAX
                                ? selectedIconBlack
                                : selectedIcon
                              : bankIcon
                          }
                          className={classes.tick}
                          alt="bank"
                        />
                      </div>
                      <div className={classes.addNewOptionText}>
                        {PAYMENT_LABELS.bankAccountLabel}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={classes.titleContainer}>
                    <div className={classes.title}>
                      {paymentRules.paymentMethods.includes(PaymentType.Credit)
                        ? PAYMENT_LABELS.creditCardLabel
                        : PAYMENT_LABELS.bankAccountLabel}
                    </div>
                    <div className={classes.subtitle}>{LABELS.PLEASE_INPUT_DETAILS}</div>
                  </div>
                )}
              </div>
              {newMethod === PaymentType.Credit ? (
                <Formik
                  initialValues={initialCardValues}
                  onSubmit={(data) => saveNewCard(data)}
                  validationSchema={Yup.object().shape({
                    cardHolderName: Yup.string()
                      .max(cardHolderNameMaxLength, nameMaxLengthError)
                      .matches(cardHolderNameRegex, nameFormatError)
                      .required(requiredFieldError),
                    cardNumber: Yup.string().min(19).required(),
                    expiryDate: Yup.string().min(5).required(),
                    cvv: Yup.string().min(cvvLength).required(),
                  })}
                  render={({ values, handleSubmit, setFieldValue, isValid, errors }) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <FormControl
                        className={classes.inputContainer}
                        style={{ marginTop: 5 }}
                        error={!!errors.cardHolderName}
                      >
                        <Input
                          name="cardHolderName"
                          placeholder="Card holder name"
                          disableUnderline
                          onChange={(event) => setFieldValue('cardHolderName', event.target.value)}
                          value={values.cardHolderName}
                        />
                        {!!errors.cardHolderName && (
                          <FormHelperText>{errors.cardHolderName}</FormHelperText>
                        )}
                      </FormControl>
                      <FormControl className={classes.cardNumberInputStyle} error={cardError}>
                        <Input
                          name="cardNumber"
                          type="text"
                          inputComponent={cardNumberValidator as any}
                          onChange={(event) => {
                            setFieldValue('cardNumber', event.target.value);
                            handleCardNumber(event.target.value);
                          }}
                          placeholder="Card number"
                          disableUnderline
                          value={values.cardNumber}
                          endAdornment={
                            <InputAdornment position="end">
                              <img src={cardError ? errorIcon : renderCardIcon(cardType)} alt="" />
                            </InputAdornment>
                          }
                        />
                        {cardErrorValue && <FormHelperText>{cardErrorValue}</FormHelperText>}
                      </FormControl>
                      <div className={classes.inputBoxStyle}>
                        <FormControl className={classes.expiryInputStyle} error={expiryError}>
                          <Input
                            name="expiryDate"
                            type="text"
                            placeholder="Expiry (MM/YY)"
                            inputComponent={cardExpiryValidator as any}
                            disableUnderline
                            onChange={(event) => {
                              expiryDateValidation(event.target.value);
                              setFieldValue('expiryDate', event.target.value);
                            }}
                            value={values.expiryDate}
                            endAdornment={
                              <InputAdornment position="end">
                                <img src={calendarIcon} alt="" />
                              </InputAdornment>
                            }
                          />
                          {expiryError && <FormHelperText>{expiryDateError}</FormHelperText>}
                        </FormControl>
                        <FormControl className={classes.cvvInputStyle}>
                          <Input
                            name="cvv"
                            placeholder="CVV"
                            disableUnderline
                            type="password"
                            onChange={(event) =>
                              setFieldValue('cvv', CardHepler.formatCvvNumber(event.target.value))
                            }
                            inputProps={{
                              maxLength: 3,
                            }}
                            value={values.cvv}
                            endAdornment={
                              <InputAdornment position="end">
                                <img src={lockIcon} alt="" />
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </div>
                      {paymentErrorState && (
                        <Snackbar message={`${paymentErrorState.error}, try again`} />
                      )}
                      {renderFooter(cardError || expiryError || !isValid, handleSubmit)}
                    </div>
                  )}
                />
              ) : (
                <Formik
                  initialValues={initialBankValues}
                  onSubmit={(data) => saveNewBank(data)}
                  validationSchema={Yup.object().shape({
                    accountName: Yup.string()
                      .required(errorMessages.required)
                      .max(lengths.accNameMax, errorMessages.accNameLength)
                      .matches(accountNameRegex, errorMessages.accName),
                    bsbNumber: Yup.string()
                      .required(errorMessages.required)
                      .matches(bsbNumberRegex, errorMessages.invalidBsb),
                    accountNumber: Yup.string()
                      .required(errorMessages.required)
                      .min(lengths.accNumMin, errorMessages.accNumMin)
                      .max(lengths.accNumMax),
                  })}
                  render={({
                    values,
                    handleChange,
                    setFieldValue,
                    handleSubmit,
                    errors,
                    touched,
                    isValid,
                  }) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <FormControl
                        className={classes.inputContainer}
                        style={{ marginTop: 5 }}
                        error={!!errors.accountName}
                      >
                        <Input
                          name="accountName"
                          type="text"
                          disableUnderline
                          placeholder="Account name"
                          onChange={(event) => setFieldValue('accountName', event.target.value)}
                          value={values.accountName}
                        />
                        {!!errors.accountName && (
                          <FormHelperText>{errors.accountName}</FormHelperText>
                        )}
                      </FormControl>
                      <FormControl
                        className={classes.inputContainer}
                        error={!!errors.bsbNumber && touched.bsbNumber}
                      >
                        <Input
                          name="bsbNumber"
                          onChange={handleChange}
                          value={values.bsbNumber}
                          inputComponent={bsbNumberValidator}
                          disableUnderline
                          placeholder="BSB"
                          endAdornment={
                            <InputAdornment position="end">
                              <img src={lockIcon} alt="" />
                            </InputAdornment>
                          }
                        />
                        <FormHelperText>
                          <ErrorMessage name="bsbNumber" />
                        </FormHelperText>
                      </FormControl>
                      <FormControl className={classes.inputContainer} error={accountNumberError}>
                        <Input
                          name="accountNumber"
                          value={values.accountNumber}
                          placeholder="Account number"
                          disableUnderline
                          onChange={(event) =>
                            handleAccountNumber(event.target.value, setFieldValue)
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <img src={lockIcon} alt="" />
                            </InputAdornment>
                          }
                        />
                        {accountNumberError && (
                          <FormHelperText>{errorMessages.accNumMax}</FormHelperText>
                        )}
                      </FormControl>
                      {paymentErrorState && (
                        <Snackbar message={`${paymentErrorState.error}, try again`} />
                      )}
                      {renderFooter(!isValid, handleSubmit)}
                    </div>
                  )}
                />
              )}
            </>
          ) : (
            <div
              className={classes.menuItem}
              style={{ marginBottom: 0 }}
              onClick={() => {
                setAddNew(true);
                // set payment ref id to null to that service
                setPaymentRefIdOnAddNew();
                setIndex(false);
              }}
            >
              <div className={classes.iconContainer}>
                <img src={addIcon} className={classes.iconImage} alt="add" />
              </div>
              <div className={classes.optionContainer}>
                <div className={classes.optionTitle}>{PAYMENT_LABELS.addNewLabel}</div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  property: state.checkout.property,
  paymentMethods: state.payment.paymentMethods,
  accessToken: state.token.accessToken,
  paymentErrorState: state.payment.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveSelectedPaymentMethod: (data: SaveSelectedPaymentMethodPayload) => {
    dispatch(PaymentActions.saveSelectedPaymentMethod(data));
  },
  getPaymentMethods: (onSuccess: (response: GetPaymentMethodsResponse[]) => void) => {
    dispatch(
      PaymentActions.getPaymentMethodsStart({
        onSuccess,
      }),
    );
  },
  getPaymentTypes: (
    data: GetPaymentType,
    isPaymentMethods: boolean,
    onSuccess: (isPaymentMethods: boolean) => void,
  ) => {
    dispatch(
      PaymentActions.getPaymentTypesStart({
        data,
        onSuccess: () => onSuccess(isPaymentMethods),
      }),
    );
  },
  getPaymentAuthKey: (
    data: BankAccountRequest | CreditCardRequest,
    fetchPayments: () => void,
    setPaymentMethod: (refId: string) => void,
    setSucceeded: (value: boolean) => void,
    paymentGateway?: PaymentGateway,
    providerId?: string,
    transactionId?: string,
  ) => {
    dispatch(
      PaymentActions.getPaymentAuthKeyStart({
        data,
        paymentGateway,
        providerId,
        onSuccess: (token: string) => {
          if (paymentGateway === PaymentGateway.WESTPAC_QUICK) {
            dispatch(
              PaymentActions.getRefIdRequest({
                token,
                providerId,
                transactionId,
                onSuccess: (refId: string) => {
                  setSucceeded(true);
                  setPaymentMethod(refId);
                },
              }),
            );
          } else {
            dispatch(
              PaymentActions.postPaymentTokenStart({
                data: { resultKey: token },
                onSuccess: () => {
                  fetchPayments();
                },
              }),
            );
          }
        },
        onError: (error: PaymentErrorPayload) => {
          dispatch(PaymentActions.getPaymentAuthKeyError(error));
          dispatch(SpinnerActions.hide());
        },
      }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PaymentSection));

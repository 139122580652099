export const LABELS = {
  PLAN: 'Service information',
  OPTIONS: 'Service options',
  TERMS: 'Terms and conditions',
  SHOW_PLAN: 'Show service information',
  SHOW_OPTIONS: 'Show options',
  SHOW_TERMS: 'Show terms',
  HIDE_PLAN: 'Hide service information',
  HIDE_OPTIONS: 'Hide options',
  HIDE_TERMS: 'Hide terms',
  BACK: 'Back',
  NEXT: 'Next',
};

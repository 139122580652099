import React, { useState, FC } from 'react';
import { get } from 'lodash';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  Typography,
  ThemeProvider,
  createMuiTheme,
  InputAdornment,
  IconButton,
} from '@material-ui/core';

import ReactHTMLParser from 'react-html-parser';
import DateFnsUtils from '@date-io/date-fns';
import calendarIcon from '../../../assets/outlined.svg';
import { PlanSelectionStyles } from './PlanSelectionStyles';
import {
  SupplierOptions,
  QuotesV3,
  SupplierOption,
} from '../../../containers/services/commonServices/CommonPlanSelectionInterface';

interface ToggleButtonsProps extends PlanSelectionStyles {
  option: SupplierOptions;
  index: number;
  activeIndex: number;
  defaultValue?: string | Date;
  getValue: (labelId: string) => void;
  callBackHandler: (
    selection: SupplierOptions | SupplierOption,
    index: number,
    date?: Date | string,
  ) => void;
  publicHolidays?: string[]; 
}

const ToggleButtons: FC<ToggleButtonsProps> = ({
  classes,
  option,
  index,
  activeIndex,
  defaultValue,
  callBackHandler,
  publicHolidays
}) => {
  const [pageNumber, setPageNumber] = useState(defaultValue || '');
  const [selectedDate, setSelectedDate] = useState(
    get(option, 'ComponentType', '') === 'Date' && defaultValue
      ? new Date(defaultValue as string)
      : '',
  );
  const [ac, setAc] = useState(0);

  if (ac !== activeIndex) {
    setAc(activeIndex);
    setPageNumber('');
    setSelectedDate('');
  }

  const footerText = (labelId: string | null, optionObj: SupplierOption[]) => {
    if (optionObj && optionObj.length) {
      const filteredObj = optionObj.filter((a: SupplierOption) => a.ProductId === labelId)[0];
      return filteredObj && filteredObj.HelpText
        ? <div className={classes.footer}>{ReactHTMLParser(filteredObj.HelpText)}</div> || ''
        : filteredObj && filteredObj.ComponentText
        ? <div className={classes.footer}>{ReactHTMLParser(filteredObj.ComponentText)}</div> || ''
        : '';
    } else {
      return '';
    }
  };

  const defaultMaterialTheme = createMuiTheme({
    spacing: 2,
    palette: {
      primary: { main: '#2d1b49' },
    },
  });

  // tslint:disable-next-line:cyclomatic-complexity
  const disableWeekends = (date: Date | null, selectionOption: SupplierOptions) => {
    let flag = 0;
    const month =
      (date as Date).getMonth() + 1 < 10
        ? '0' + ((date as Date).getMonth() + 1).toString()
        : ((date as Date).getMonth() + 1).toString();
    const checkDate =
      (date as Date).getDate() < 9
        ? (date as Date).getFullYear().toString() +
          '-' +
          month +
          '-0' +
          (date as Date).getDate().toString()
        : (date as Date).getFullYear().toString() +
          '-' +
          month +
          '-' +
          (date as Date).getDate().toString();
    if (selectionOption.PublicHolidayExclusion) {
      if (publicHolidays && publicHolidays.includes(checkDate)) {
        flag = 1;
      }
    }
    if (selectionOption.ComponentDateExcludeWeekend) {
      if ((date as Date).getDay() === 0 || (date as Date).getDay() === 6) {
        flag = 1;
      }
    }
    if (selectionOption.ComponentDateExcludeCurrentDay) {
      const today = new Date();
      let istoday = '';
      let isnextday = '';

      if (today.getDate() < 9) {
        istoday =
          today.getFullYear().toString() +
          '-' +
          `${
            today.getMonth() < 9
              ? '0' + (today.getMonth() + 1).toString()
              : (today.getMonth() + 1).toString()
          }` +
          '-0' +
          today.getDate().toString();
      } else {
        istoday =
          today.getFullYear().toString() +
          '-' +
          `${
            today.getMonth() < 9
              ? '0' + (today.getMonth() + 1).toString()
              : (today.getMonth() + 1).toString()
          }` +
          '-' +
          today.getDate().toString();
      }

      if (today.getDate() < 9) {
        isnextday =
          today.getFullYear().toString() +
          '-' +
          `${
            today.getMonth() < 9
              ? '0' + (today.getMonth() + 1).toString()
              : (today.getMonth() + 1).toString()
          }` +
          '-0' +
          (today.getDate() + 1).toString();
      } else {
        isnextday =
          today.getFullYear().toString() +
          '-' +
          `${
            today.getMonth() < 9
              ? '0' + (today.getMonth() + 1).toString()
              : (today.getMonth() + 1).toString()
          }` +
          '-' +
          (today.getDate() + 1).toString();
      }
      if (checkDate === istoday || checkDate === isnextday) {
        flag = 1;
      }
    }
    if (flag === 1) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <Typography variant="h6">{get(option, 'Heading', '')}</Typography>
      <div className={classes.seperator} />
      <Typography variant="h1" className={`${classes.headerText2} ${classes.gtwLight}`}>
        {ReactHTMLParser(get(option, 'Description', ''))}
      </Typography>
      {get(option, 'ComponentType', '') !== 'Date' ? (
        <ToggleButtonGroup className={classes.toggleContainer} value={pageNumber}>
          {option &&
            option.Options &&
            option.Options.map((selection: SupplierOption) => {
              return (
                <ToggleButton
                  classes={{ selected: classes.toggleButtonActive }}
                  value={
                    get(selection, 'ProductId', '')
                      ? get(selection, 'ProductId', '')
                      : get(selection, 'Label', '')
                  }
                  className={
                    pageNumber === get(selection, 'Label', '')
                      ? classes.toggleButtonActive
                      : classes.toggleButton
                  }
                  onClick={() => {
                    setPageNumber(get(selection, 'Label', ''));
                    callBackHandler(selection, index, get(selection, 'Label', ''));
                  }}
                >
                  {get(selection, 'Label', '')}
                </ToggleButton>
              );
            })}
        </ToggleButtonGroup>
      ) : (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ThemeProvider theme={defaultMaterialTheme}>
            <DatePicker
              clearable
              helperText={null}
              shouldDisableDate={(date) => disableWeekends(date, option)}
              error={false}
              format="yyyy-MM-dd"
              style={{ marginTop: 20, width: '100%' }}
              placeholder="Select Date"
              value={selectedDate === '' ? null : selectedDate}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <img src={calendarIcon} className={classes.imageStyle} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(date) => {
                setSelectedDate(date !== null ? date.toString() : '');
                callBackHandler(option, index, date as Date);
              }}
              maxDate={
                new Date(
                  new Date().getTime() + option.ComponentDateMaxFutureDays * 24 * 60 * 60 * 1000,
                )
              }
              minDate={new Date(Date().toString())}
              autoOk={false}
            />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      )}
      <Typography variant="h1" className={`${classes.headerText2} ${classes.gtwLight} `}>
        {footerText(pageNumber as string, option.Options)}
      </Typography>
    </div>
  );
};

export default ToggleButtons;

import { color } from '../../../../theme/Theme';
import { WithStyles, createStyles } from '@material-ui/core';

export const Styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  body: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: 70,
    padding: '0 16px 16px 16px',
  },
  top: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: '3vh',
  },
  button: {
    'height': 36,
    'borderRadius': 2,
    'background': color.primary,
    '&:hover': {
      backgroundColor: color.primary,
    },
  },
  buttonText: {
    height: 16,
    fontFamily: 'GT Walsheim',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.14,
    letterSpacing: 1.1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: color.light,
  },
});

export interface PowerScreenStyles extends WithStyles<typeof Styles> {}

import { PlanState } from '../state/PlanState';
import { PlanAction, PlanActionTypes } from '../actions/PlanActions';
import { PlanDetailsSubmit } from '../../models/plan/electricity/postPlanDetails/PlanDetailsSubmit.data';

const defaultPlanValue: PlanDetailsSubmit = {
  productDetails: {
    productName: '',
    monthlyCost: 0,
    dailyCost: 0,
    productCode: '',
    productId: '',
    productDescription: '',
    productType: 'Plan',
  },
  usage: '',
  annualUsage: '',
  distributor: '',
  termsUrl: '',
  documents: [],
  energyPricing: [],
  meterType: null,
  nextScheduledRead: null,
  connectionFee: 0,
  averageUsage: '',
  serviceClassesAvailable: null,
};

const initialState: PlanState = {
  planDetails: {},
  concessionCard: false,
  lifeSupport: false,
  plan: defaultPlanValue,
  loading: false,
  error: null,
};

export const planReducer = (state = initialState, action: PlanAction) => {
  switch (action.type) {
    case PlanActionTypes.GetPlanDetailsStart:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PlanActionTypes.GetPlanDetailsSuccess:
      return {
        ...state,
        loading: false,
        error: null,
        planDetails: action.data,
      };
    case PlanActionTypes.GetPlanDetailsError:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case PlanActionTypes.ToggleConcessionChoice:
      return {
        ...state,
        concessionCard: action.data,
      };
    case PlanActionTypes.ToggleSupportChoice:
      return {
        ...state,
        lifeSupport: action.data,
      };
    case PlanActionTypes.SavePlanDetails:
      return {
        ...state,
        plan: action.data,
      };
    default:
      return state;
  }
};

import * as React from 'react';
import TermsAndConditionsComponent from '../../../component/services/termsAndConditions/TermsAndConditions';
import {
  ElectricityServiceDetails,
  PersonalDetails,
  ElectricityPlanDetails,
  ProductDetails,
  ConcessionCardDetails,
  ServiceAccount,
} from '../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { ElectricityServiceActions } from '../../../store/actions/ElectricityServiceActions';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/RootReducers';
import { AnyAction } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import { routes } from '../../../Routes';
import { ServiceType, termsAndConditionValue } from '../ServiceConstants';
import { BackHelper } from '../../../helper/BackHelper';
import { PlanSummaryActions } from '../../../store/actions/PlanSummaryActions';
import { EnergyPricing } from '../../../services/planSummary/getPlanSummary/GetPlanSummary.data';

interface OwnState {
  termsDrawer: boolean;
  planDetailsDrawer: boolean;
  personalDetailsDrawer: boolean;
}

interface PropsFromDispatch {
  postTermsAndConditions: (
    propertyId: number,
    serviceAccountId: number,
    data: ElectricityServiceDetails,
    onSuccess: () => void,
  ) => void;
  getElectricityService: (propertyId: number) => void;
  getPlanSummary: (
    serviceAccountId: string,
    serviceSummary: string,
    onSuccess: (v1: boolean) => void,
    onError: () => void,
  ) => void;
}

interface PropsFromState {
  service: ElectricityServiceDetails;
  accessToken: string | boolean | null;
  serviceAccountId: number | undefined;
}

export interface PropsObjectType {
  key: string;
  value: string;
}

export interface ModalPlanDetails {
  title: string;
  value: string | PropsObjectType[];
}

type OwnProps = PropsFromDispatch &
  PropsFromState &
  RouteComponentProps<{
    propertyId: string;
    serviceSummary: string;
    paymentRefId: string;
  }>;

class TermsAndConditions extends React.Component<OwnProps, OwnState> {
  public waitForAccesstoken = 0;
  public constructor(props: OwnProps) {
    super(props);
    this.state = {
      termsDrawer: false,
      planDetailsDrawer: false,
      personalDetailsDrawer: false,
    };
  }

  public getElectricityServiceData = () => {
    const { serviceSummary, propertyId } = this.props.match.params;
    this.waitForAccesstoken = 1;
    if (serviceSummary && serviceSummary === ServiceType.ElectricitySummary) {
      this.props.getElectricityService(parseInt(propertyId, 10));
    }
  };

  public componentDidMount() {
    const { accessToken, serviceAccountId } = this.props;
    const { propertyId, paymentRefId } = this.props.match.params;
    if (serviceAccountId) {
      BackHelper.saveParams({
        propertyId,
        paymentRefId,
        serviceAccountId: serviceAccountId.toString(),
      });
    }
    if (accessToken) {
      this.getElectricityServiceData();
    }
  }

  public componentDidUpdate() {
    const { accessToken, serviceAccountId } = this.props;
    const { propertyId, paymentRefId } = this.props.match.params;
    if (serviceAccountId) {
      BackHelper.saveParams({
        propertyId,
        paymentRefId,
        serviceAccountId: serviceAccountId.toString(),
      });
    }
    if (accessToken && this.waitForAccesstoken === 0) {
      this.getElectricityServiceData();
    }
  }
  public render() {
    const { service } = this.props;
    const { serviceSummary } = this.props.match.params;
    const documents = service && service.serviceDetails.electricityPlanDetails!.documents;
    const { termsDrawer, planDetailsDrawer, personalDetailsDrawer } = this.state;
    if (service) {
      const cardsPersonalDetails = this.getCardsPersonalDetails();
      const modalPersonalDetails = this.getModalPersonalDetails();
      const cardsPlanDetails = this.getCardsPlanDetails();
      const modalPlanDetails = this.getModalPlanDetails();
      return (
        <TermsAndConditionsComponent
          summary={serviceSummary}
          handleSubmit={this.handleSubmit}
          serviceDetails={service && service.serviceDetails}
          cardsPersonalDetails={cardsPersonalDetails}
          modalPersonalDetails={modalPersonalDetails}
          cardsPlanDetails={cardsPlanDetails}
          modalPlanDetails={modalPlanDetails}
          planModalSubtext={termsAndConditionValue}
          documents={documents}
          termsAndConditionsUrl={
            (service.serviceDetails.electricityPlanDetails as ElectricityPlanDetails).termsUrl
          }
          toggleDrawer={this.toggleDrawer}
          termsDrawer={termsDrawer}
          planDetailsDrawer={planDetailsDrawer}
          personalDetailsDrawer={personalDetailsDrawer}
        />
      );
    } else {
      return <div />;
    }
  }
  private getCardsPersonalDetails = (): PropsObjectType[] => {
    const { service } = this.props;
    const personalDetails = service && (service.serviceDetails.personalDetails as PersonalDetails);
    const cardList = [
      { key: 'Name', value: personalDetails.name },
      { key: 'Email', value: personalDetails.email },
      { key: 'Phone', value: personalDetails.phone },
    ];
    if (personalDetails.dob) {
      cardList.push({ key: 'D.O.B', value: personalDetails.dob });
    }
    return cardList;
  };

  private getModalPersonalDetails = (): PropsObjectType[] => {
    const { service } = this.props;
    const personalDetails = service && (service.serviceDetails.personalDetails as PersonalDetails);
    const cardList = [
      { key: 'Email address', value: personalDetails.email },
      { key: 'Title', value: personalDetails.salutation as string },
      { key: 'First name', value: personalDetails.name },
      { key: 'Last name', value: personalDetails.surname },
      { key: 'Mobile number', value: personalDetails.phone },
    ];
    if (personalDetails.dob) {
      cardList.push({
        key: 'D.O.B',
        value: personalDetails.dob,
      });
    }
    return cardList;
  };
  private getCardsPlanDetails = (): PropsObjectType[] => {
    const { service } = this.props;
    const electricityPlanDetails =
      service && (service.serviceDetails.electricityPlanDetails as ElectricityPlanDetails);
    const concessionCardValue = this.handleConcessionCardValue();
    const lifeSupportValue =
      electricityPlanDetails && electricityPlanDetails.lifeSupport === false
        ? 'Not required'
        : 'Required';
    const planDetails = [
      {
        key: 'Concession card',
        value: concessionCardValue,
      },
      {
        key: 'Life support',
        value: lifeSupportValue,
      },
    ];
    if (electricityPlanDetails.usage) {
      planDetails.push({
        key: 'Plan size',
        value: `${electricityPlanDetails.usage}`,
      });
    }
    return planDetails.reverse();
  };
  private handleConcessionCardValue = () => {
    const { service } = this.props;
    const electricityPlanDetails =
      service && (service.serviceDetails.electricityPlanDetails as ElectricityPlanDetails);
    return electricityPlanDetails.concessionCard === null
      ? 'No'
      : (electricityPlanDetails.concessionCard as ConcessionCardDetails).cardType;
  };

  private getModalPlanDetails = (): ModalPlanDetails[] => {
    const { service } = this.props;
    const { energyPricing } = service.serviceDetails.electricityPlanDetails!;
    const electricityPlanDetails =
      service && (service.serviceDetails.electricityPlanDetails as ElectricityPlanDetails);
    const pricelist = [
      {
        key: 'Monthly plan',
        value: `$${(electricityPlanDetails.productDetails as ProductDetails).monthlyCost}`,
      },
      {
        key: 'Your daily rate',
        value: `$${(electricityPlanDetails.productDetails as ProductDetails).dailyCost}`,
      },
    ];
    if (electricityPlanDetails.connectionFee) {
      pricelist.push({
        key: 'Connection fee',
        value: `$${electricityPlanDetails.connectionFee}`,
      });
    } else {
      pricelist.push({
        key: 'Connection fee',
        value: 'none',
      });
    }
    const planDetails: ModalPlanDetails[] = [
      {
        title: 'Pricing',
        value: pricelist.reverse(),
      },
    ];
    if (electricityPlanDetails.averageUsage) {
      planDetails.push({
        title: 'Estimated monthly usage',
        value: `${electricityPlanDetails.averageUsage} KWh`,
      });
    }
    if (energyPricing && energyPricing.length !== 0) {
      let pricing = '';
      energyPricing.map((price: EnergyPricing) => {
        pricing += `${price.name}: ${price.amount}${price.unitOfMeasureId}\n`;
      });
      planDetails.push({
        title: 'Energy rates (exclusive of GST)',
        value: pricing,
      });
    }
    return planDetails;
  };
  private handleSubmit = () => {
    const { propertyId } = this.props.match.params;
    const { serviceAccountId } = this.props;
    const data: ElectricityServiceDetails = {
      serviceDetails: {
        acceptTermAndCondition: true,
      },
      step: 'ACCEPT_TERMS',
    };
    this.props.postTermsAndConditions(
      parseInt(propertyId, 10),
      serviceAccountId as number,
      data,
      this.onSuccess,
    );
  };

  private onSuccess = () => {
    const { propertyId } = this.props.match.params;
    BackHelper.saveCount(-1);
    this.props.history.push(routes.payment.list.saved(propertyId, ServiceType.Electricity));
  };

  private toggleDrawer = (drawerType: string, toggle: boolean) => {
    const { serviceSummary, propertyId } = this.props.match.params;
    if (
      drawerType === 'planDetailsDrawer' &&
      toggle &&
      serviceSummary === ServiceType.ElectricitySummary
    ) {
      this.props.getPlanSummary(
        propertyId,
        serviceSummary,
        this.handlePlanSummarySuccess,
        this.handlePlanSummaryError,
      );
    } else {
      this.setState((prevState) => ({
        ...prevState,
        [drawerType]: toggle,
      }));
    }
  };

  private handlePlanSummarySuccess = (v1: boolean) => {
    const { propertyId, serviceSummary } = this.props.match.params;
    if (v1) {
      this.props.history.push(routes.service.planSummary(propertyId, serviceSummary));
    }
    this.setState({ planDetailsDrawer: true });
  };
  private handlePlanSummaryError = () => {
    this.setState({ planDetailsDrawer: true });
  };
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>): PropsFromDispatch {
  return {
    postTermsAndConditions: (
      propertyId: number,
      serviceAccountId: number,
      data: ElectricityServiceDetails,
      onSuccess: () => void,
    ) => {
      dispatch(
        ElectricityServiceActions.postElectricityServiceStart({
          propertyId,
          serviceAccountId,
          data,
          onSuccess,
        }),
      );
    },
    getElectricityService: (propertyId: number) => {
      dispatch(ElectricityServiceActions.getElectricityServiceStart(propertyId));
    },
    getPlanSummary: (
      serviceAccountId: string,
      serviceSummary: string,
      onSuccess: (v1: boolean) => void,
      onError: () => void,
    ) =>
      dispatch(
        PlanSummaryActions.getPlanSummaryStart({
          serviceAccountId,
          serviceSummary,
          onSuccess,
          onError,
        }),
      ),
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  const service: ElectricityServiceDetails = state.electricityService
    .service as ElectricityServiceDetails;
  return {
    service,
    accessToken: state.token.accessToken,
    serviceAccountId:
      state.electricityService &&
      state.electricityService.service &&
      ((state.electricityService.service.serviceAccount as ServiceAccount).id as number),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);

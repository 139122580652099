import { State } from '.';
import { OnboardingPropertyDetails } from '../../models/property/Property.data';

export class PropertyOnboardingState extends State {
  public propertyOnboardingState: OnboardingPropertyDetails;
}

export const ONBOARDING_INITIAL_STATE = {
  address: {
    state: '',
    country: '',
    postcode: '',
    streetName: '',
    streetNumber: '',
    streetType: '',
    unitNumber: '',
    suburb: '',
  },
  selectedType: undefined,
  selectedClass: undefined,
};

import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceMock from '../__mocks__/ApiServiceMock';
import { AppSettings } from '../../AppSettings';
import ApiServiceBase from '../ApiServiceBase';
import { PlanRequest } from './getPlanDetails/GetPlanDetails.data';

const serviceType = ServiceType.Quote;

export class PlanService {
  private readonly service: ApiServiceBase = AppSettings.currentTestSetting.testing
    ? new ApiServiceMock(serviceType)
    : new ApiService(serviceType);
  public getPlanDetails(data: PlanRequest): any {
    return this.service.post(
      {
        route: ['quotes'],
      },
      data,
    );
  }
}

const planService = new PlanService();
export default planService;

import React, { FC, useState, useEffect } from 'react';
import { Card, Typography, withStyles, Grid, CardHeader, Drawer, Button } from '@material-ui/core';
import { Styles, OverViewStyles } from './KannopiStyle';
import ReactHTMLParser from 'react-html-parser';
import electricityIcon from '../../../../assets/images/electricity-icon.svg';
import { routes } from '../../../../Routes';
import backArrow from '../../../../assets/arrow-back-black.svg';
import tickWhite from '../../../../assets/tick-white.svg';
import FooterComponent from '../../../footer/FooterComponent';
import {
  ReferalIframePlan,
  ServiceDetails,
  ServiceIframe,
  ServiceIframeInProgress,
} from './KanopiInterface';
import {
  AcceptanceChecks,
  Link,
} from '../../../../containers/services/commonServices/CommonPlanSelectionInterface';
import { sendMessageToApp, ValidMessages } from '../../../../helper/MessageHelper';
import { get, isNil } from 'lodash';
import LogRocket from 'logrocket';

interface Props {
  propertyId: string;
  providerName: string;
  serviceName: string;
  serviceAccountId: string;
  quotes: ReferalIframePlan;
  onBtnClick: (route: string, data: ServiceIframeInProgress | ServiceIframe) => void;
}

type OwnProps = OverViewStyles & Props;

const KannopiOverview: FC<OwnProps> = ({
  quotes,
  classes,
  providerName,
  propertyId,
  serviceName,
  serviceAccountId,
  onBtnClick,
}) => {
  const [termsDrawer, setTermsDrawer] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [acceptedChecks, setAcceptedChecks] = useState<number[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (quotes && quotes.PreAgreementSummary.AcceptanceChecks.length === acceptedChecks.length) {
      setAccepted(true);
    } else {
      setAccepted(false);
    }
  }, [acceptedChecks]);

  const renderTermsDrawer = () => {
    return (
      quotes && (
        <div className={classes.bottomDrawer}>
          <img
            src={backArrow}
            onClick={() => {
              setTermsDrawer(false);
            }}
            alt="Back Icon"
          />
          <Typography className={classes.bottomDrawerTitle}>
            {quotes.PreAgreementSummary.Title}
          </Typography>

          <Typography className={classes.modalSubtitle}>
            {quotes.PreAgreementSummary.Heading}
          </Typography>

          <Typography className={classes.terms}>
            {ReactHTMLParser(quotes.PreAgreementSummary.Body)}
          </Typography>
          {quotes.PreAgreementSummary && quotes.PreAgreementSummary.LinkItems.length > 0 && (
            <Card elevation={2} className={classes.detailCard}>
              <div className={classes.switchContainer}>
                <Typography className={classes.linkHeader} variant="h6">
                  Important information
                </Typography>
              </div>
              <div className={classes.seperator} />
              {quotes.PreAgreementSummary && (
                <div className={classes.section2}>
                  {quotes.PreAgreementSummary.LinkItems.map((link: Link) => (
                    <>
                      <div className={classes.section2}>
                        <div
                          key={`rate-${link.Label}`}
                          onClick={() => {
                            sendMessageToApp(ValidMessages.OpenUrl, link.Link);
                          }}
                        >
                          <Typography
                            variant="h1"
                            className={`${classes.keyText} ${classes.blueText}`}
                          >
                            {link.Label}
                          </Typography>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              )}
            </Card>
          )}
          <div className={classes.drawerFooter}>
            <div className={classes.acceptanceChecks}>
              {quotes.PreAgreementSummary.AcceptanceChecks.map(
                (item: AcceptanceChecks, index: number) => (
                  <div className={classes.flexRow}>
                    <Typography
                      className={classes.termsSection}
                      onClick={async () => {
                        if (acceptedChecks.includes(index)) {
                          const array = [...acceptedChecks];
                          const arrayIndex = array.indexOf(index);
                          if (arrayIndex !== -1) {
                            array.splice(arrayIndex, 1);
                            await setAccepted(false);
                            await setAcceptedChecks(array);
                          }
                        } else {
                          await setAccepted(false);
                          await setAcceptedChecks([...acceptedChecks, index]);
                        }
                      }}
                    >
                      {!acceptedChecks.includes(index) ? (
                        <div className={classes.acceptTermsdefault} />
                      ) : (
                        <div className={classes.acceptedTerms}>
                          <img src={tickWhite} className={classes.tickImage} />
                        </div>
                      )}
                    </Typography>
                    <Typography variant="body1">
                      {get(item, 'ComponentText', 'I accept the above terms')}
                    </Typography>
                  </div>
                ),
              )}
            </div>

            <Button
              className={classes.button}
              fullWidth
              onClick={() => {
                setTermsDrawer(false);
                const serviceDetails: ServiceDetails = {
                  supplierId: providerName,
                  productId: quotes.Plans[0].ProductId,
                };

                const finalObj: ServiceIframe = {
                  serviceDetails,
                  step: 'CREATE',
                  propertyId,
                  serviceType: serviceName,
                  providerId: providerName,
                };

                const finalObjInProgress: ServiceIframeInProgress = {
                  serviceDetails,
                  step: 'GET',
                  propertyId,
                  serviceType: serviceName,
                  providerId: providerName,
                  serviceAccountId,
                };
                onBtnClick(
                  routes.commonService.openNewWebPage(providerName, propertyId, serviceName),
                  serviceAccountId === 'null' ? finalObj : finalObjInProgress,
                );
              }}
              disabled={!accepted}
              color={!accepted ? 'secondary' : 'inherit'}
              variant="contained"
            >
              <Typography className={!accepted ? classes.disabledButtonText : classes.buttonText}>
                {isNil(quotes.PreAgreementSummary.ButtonLabel)
                  ? 'CONTINUE'
                  : quotes.PreAgreementSummary.ButtonLabel}
              </Typography>
            </Button>
          </div>
        </div>
      )
    );
  };

  // tslint:disable-next-line:cyclomatic-complexity

  return (
    quotes && (
      <div>
        <div>
          <img
            src={quotes.SupplierBackgroundLogo}
            className={classes.serviceHeaderImg}
            alt="Service Header Image"
          />
          <Card className={classes.serviceCard}>
            <div className={classes.serviceHeader}>
              {!!quotes.SupplierLogo.length && (
                <img src={quotes.SupplierLogo} className={classes.serviceLogo1} />
              )}
              <div className={classes.serviceHeaderText}>
                <Typography variant="h5" style={{ fontSize: 21 }}>
                  {quotes.SupplierName}
                </Typography>
                <Typography variant="subtitle1">{quotes.SupplierTagLine}</Typography>
              </div>
            </div>
            <div className={classes.serviceContentDesc}>
              <Typography variant="body2" className={classes.description}>
                <span className={classes.colorBlack}>{ReactHTMLParser(quotes.Description)}</span>
                <br />
                <span className={classes.colorBlack}>
                  {ReactHTMLParser(quotes.DescriptionBanner)}
                </span>
                <br />
                {serviceAccountId !== 'null' && (
                  <div className={classes.colorBlackInprogress}>Incomplete sign up</div>
                )}
                {serviceAccountId !== 'null' && (
                  <Card
                    className={classes.planCards}
                    onClick={() => {
                      LogRocket.track('Kanopi Clicked');
                      setTermsDrawer(true);
                    }}
                  >
                    <CardHeader
                      avatar={
                        <img
                          src={quotes ? quotes.ServiceTypeLogo : electricityIcon}
                          className={classes.avatarIcon}
                        />
                      }
                      title={
                        <div>
                          <Typography variant="subtitle1" style={{ fontSize: 16 }}>
                            In Progress
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            style={{ fontSize: '1.2em', color: 'rgba(0,0,0,0.38)' }}
                          >
                            {serviceName}
                          </Typography>
                        </div>
                      }
                      action={
                        <div style={{ width: 85, marginRight: 10, marginTop: 15 }}>
                          <Grid container direction="column">
                            <Grid item xs={8} direction="row">
                              <>
                                <Typography variant="h6" style={{ color: 'rgba(0,0,0,0.5)' }}>
                                  Pending
                                </Typography>
                              </>
                            </Grid>
                          </Grid>
                        </div>
                      }
                    />
                  </Card>
                )}
              </Typography>

              <br />
              {serviceAccountId === 'null' && (
                <FooterComponent
                  buttonText={
                    quotes.SupplierIntroductionButtonLabel.length === 0
                      ? 'VIEW PLANS'
                      : quotes.SupplierIntroductionButtonLabel
                  }
                  onClick={() => {
                    LogRocket.track('Kanopi Clicked');
                    setTermsDrawer(true);
                  }}
                />
              )}
            </div>
          </Card>
        </div>
        <Drawer
          anchor="bottom"
          classes={{
            paper: classes.bottomDrawerContainer,
          }}
          open={termsDrawer}
          onClose={() => {
            setTermsDrawer(false);
          }}
        >
          {renderTermsDrawer()}
        </Drawer>
      </div>
    )
  );
};

export default withStyles(Styles)(KannopiOverview);

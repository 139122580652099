import { PostPaymentTokenResponse } from '../../services/payment/postPaymentToken/PostPyamentToken.data';
import { PaymentMethodsType } from './SummaryConstants';
import CardLogo from '../../assets/credit-card-24-px.svg';
import BankLogo from '../../assets/images/payment/bank-account.png';

export const handlePaymentDetails = (paymentResponse: PostPaymentTokenResponse) => {
  switch (paymentResponse.paymentMethod) {
    case PaymentMethodsType.Bank:
      return {
        secondaryText: `Acc ${paymentResponse.accountNumber} BSB ${paymentResponse.bsb}`,
        paymentTypeLogo: BankLogo,
      };
    case PaymentMethodsType.Credit: {
      return {
        secondaryText: `${paymentResponse.cardNumber}`,
        paymentTypeLogo: CardLogo,
      };
    }
    default:
      return {
        secondaryText: '',
        paymentTypeLogo: '',
      };
  }
};

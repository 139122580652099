import { takeEvery, put } from 'redux-saga/effects';
import { SpinnerActions } from '../actions/SpinnerActions';
import leaveListService from '../../services/leaveListService/LeaveListService';
import {
  LeaveListAction,
  LeaveListActionTypes,
  LeaveListActions,
} from '../actions/LeaveListAction';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';

export function* getLeaveListSaga(action: LeaveListAction): IterableIterator<any> {
  try {
    yield put(SpinnerActions.show());
    const response = yield leaveListService.getLeaveListService(action.data as number);

    yield put(LeaveListActions.getLeaveListSuccess(response));
  } catch (error) {
    yield put(SpinnerActions.hide());
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(LeaveListActions.getLeaveListError(error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

function* leaveListWatcher(): IterableIterator<any> {
  yield takeEvery(LeaveListActionTypes.GetLeaveListStart, (action) =>
    getLeaveListSaga({
      type: action.type,
      data: (action as LeaveListAction).data,
    }),
  );
}
export default leaveListWatcher;

import { BroadbandPlanState } from '../state/BroadbandPlanState';
import { BroadbandPlanAction, BroadbandPlanActionTypes } from '../actions/BroadbandPlanAction';

const initialState: BroadbandPlanState = {
  broadbandPlan: {},
  broadbandChoice: '',
  dataPlan: '',
  downloadPlan: '',
  voicePlan: '',
  contractPlan: '',
  modem: '',
  loading: false,
  error: null,
};

// tslint:disable-next-line:cyclomatic-complexity
export const BroadbandPlanReducer = (state = initialState, action: BroadbandPlanAction) => {
  switch (action.type) {
    case BroadbandPlanActionTypes.GetBroadbandPlanStart:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case BroadbandPlanActionTypes.GetBroadbandPlanSuccess:
      return {
        ...state,
        loading: false,
        error: null,
        broadbandPlan: action.data,
      };
    case BroadbandPlanActionTypes.GetBroadbandPlanError:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case BroadbandPlanActionTypes.SaveBroadbandChoice:
      return {
        ...state,
        broadbandChoice: action.data,
      };
    case BroadbandPlanActionTypes.SaveDataChoice:
      return {
        ...state,
        dataPlan: action.data,
      };
    case BroadbandPlanActionTypes.SaveDownloadChoice:
      return {
        ...state,
        downloadPlan: action.data,
      };
    case BroadbandPlanActionTypes.SaveModemChoice:
      return {
        ...state,
        modem: action.data,
      };
    case BroadbandPlanActionTypes.SaveVoiceChoice:
      return {
        ...state,
        voicePlan: action.data,
      };
    case BroadbandPlanActionTypes.SaveContractChoice:
      return {
        ...state,
        contractPlan: action.data,
      };
    case BroadbandPlanActionTypes.ClearPlanSelection:
      return {
        ...state,
        broadbandChoice: '',
        dataPlan: '',
        downloadPlan: '',
        voicePlan: '',
        contractPlan: '',
        modem: '',
      };
    default:
      return state;
  }
};

import { StoreAction } from '../StoreHelper';
import {
  ElectricityServiceDetails,
  PropertyDetails,
} from '../../services/electricityService/getElectricityService/GetElectricityService.data';
import {
  ElectricityAddressLookup,
  AddressLookup,
} from '../../services/electricityService/getElectricityAddressLookup/GetElectricityAddressLookup.data';

export enum ElectricityServiceActionTypes {
  GetElectricityServiceStart = 'GET_ELECTRICITY_SERVICE_START',
  GetElectricityServiceSuccess = 'GET_ELECTRICITY_SERVICE_SUCCESS',
  GetElectricityServiceError = 'GET_ELECTRICITY_SERVICE_ERROR',
  PostElectricityServiceStart = 'POST_ELECTRICITY_SERVICE_START',
  PostElectricityServiceSuccess = 'POST_ELECTRICITY_SERVICE_SUCCESS',
  PostElectricityServiceError = 'POST_ELECTRICITY_SERVICE_ERROR',
  DeleteElectricityServiceStart = 'DELETE_ELECTRICITY_SERVICE_START',
  DeleteElectricityServiceSuccess = 'DELETE_ELECTRICITY_SERVICE_SUCCESS',
  DeleteElectricityServiceError = 'DELETE_ELECTRICITY_SERVICE_ERROR',
  GetElectricityServiceAddressLookupStart = 'GET_ELECTRICITY_SERVICE_ADDRESS_LOOKUP_START',
  GetElectricityServiceAddressLookupSuccess = 'GET_ELECTRICITY_SERVICE_ADDRESS_LOOKUP_SUCCESS',
  GetElectricityServiceAddressLookupError = 'GET_ELECTRICITY_SERVICE_ADDRESS_LOOKUP_ERROR',
  SaveProperty = 'SAVE_PROPERTY',
  GetAddressStart = 'GET_ADDRESS_START',
  GetAddressSuccess = 'GET_ADDRESS_SUCCESS',
  GetAddressError = 'GET_ADDRESS_ERROR',
}

export interface ElectricityServiceErrorPayload {
  readonly error: Error | any;
}
export interface PostServicePayload {
  propertyId: number;
  serviceAccountId: number | null;
  data: ElectricityServiceDetails;
  onSuccess?: (response?: ElectricityServiceDetails) => void;
}
export interface GetElectricityAddressLookup {
  data: AddressLookup;
  onSuccess: (response: ElectricityAddressLookup) => void;
  onError: () => void;
}

export interface DeleteElectricityService {
  data: string;
  onSuccess: () => void;
}

export type ElectricityServicePayload =
  | GetElectricityAddressLookup
  | ElectricityAddressLookup
  | ElectricityServiceDetails
  | PostServicePayload
  | number
  | ElectricityServiceErrorPayload
  | PropertyDetails
  | string
  | DeleteElectricityService;

export type ElectricityServiceAction = StoreAction<
  ElectricityServiceActionTypes,
  ElectricityServicePayload
>;

export class ElectricityServiceActions {
  public static getElectricityServiceStart(data: number): ElectricityServiceAction {
    return {
      type: ElectricityServiceActionTypes.GetElectricityServiceStart,
      data,
    };
  }
  public static getElectricityServiceSuccess(
    data: ElectricityServiceDetails,
  ): ElectricityServiceAction {
    return {
      type: ElectricityServiceActionTypes.GetElectricityServiceSuccess,
      data,
    };
  }
  public static getElectricityServiceError(
    data: ElectricityServiceErrorPayload,
  ): ElectricityServiceAction {
    return {
      type: ElectricityServiceActionTypes.GetElectricityServiceError,
      data,
    };
  }
  public static getElectricityServiceAddressLookupStart(
    data: GetElectricityAddressLookup,
  ): ElectricityServiceAction {
    return {
      type: ElectricityServiceActionTypes.GetElectricityServiceAddressLookupStart,
      data,
    };
  }
  public static getElectricityServiceAddressLookupSuccess(
    data: ElectricityAddressLookup,
  ): ElectricityServiceAction {
    return {
      type: ElectricityServiceActionTypes.GetElectricityServiceAddressLookupSuccess,
      data,
    };
  }
  public static getElectricityServiceAddressLookupError(
    data: ElectricityServiceErrorPayload,
  ): ElectricityServiceAction {
    return {
      type: ElectricityServiceActionTypes.GetElectricityServiceAddressLookupError,
      data,
    };
  }
  public static saveProperty(data: PropertyDetails): ElectricityServiceAction {
    return {
      type: ElectricityServiceActionTypes.SaveProperty,
      data,
    };
  }
  public static postElectricityServiceStart(data: PostServicePayload): ElectricityServiceAction {
    return {
      type: ElectricityServiceActionTypes.PostElectricityServiceStart,
      data,
    };
  }
  public static postElectricityServiceSuccess(
    data: ElectricityServiceDetails,
  ): ElectricityServiceAction {
    return {
      type: ElectricityServiceActionTypes.PostElectricityServiceSuccess,
      data,
    };
  }
  public static postElectricityServiceError(
    data: ElectricityServiceErrorPayload,
  ): ElectricityServiceAction {
    return {
      type: ElectricityServiceActionTypes.PostElectricityServiceError,
      data,
    };
  }
  public static deleteElectricityServiceStart(
    data: DeleteElectricityService,
  ): ElectricityServiceAction {
    return {
      type: ElectricityServiceActionTypes.DeleteElectricityServiceStart,
      data,
    };
  }
  public static deleteElectricityServiceSuccess(
    data: ElectricityServiceDetails,
  ): ElectricityServiceAction {
    return {
      type: ElectricityServiceActionTypes.DeleteElectricityServiceSuccess,
      data,
    };
  }
  public static deleteElectricityServiceError(
    data: ElectricityServiceErrorPayload,
  ): ElectricityServiceAction {
    return {
      type: ElectricityServiceActionTypes.DeleteElectricityServiceError,
      data,
    };
  }
  public static getAddressStart(propertyId: string): ElectricityServiceAction {
    return {
      type: ElectricityServiceActionTypes.GetAddressStart,
      data: propertyId,
    };
  }
  public static getAddressSuccess(data: PropertyDetails): ElectricityServiceAction {
    return {
      type: ElectricityServiceActionTypes.GetAddressSuccess,
      data,
    };
  }
  public static getAddressError(data: ElectricityServiceErrorPayload): ElectricityServiceAction {
    return {
      type: ElectricityServiceActionTypes.GetAddressError,
      data,
    };
  }
}

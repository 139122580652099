import { EditPaymentState } from '../state/EditPaymentState';
import { EditPaymentAction, EditPaymentActionTypes } from '../actions/EditPaymentActions';

const editPaymentInitialState: EditPaymentState = {
  payment: null,
  loading: false,
};

export function editPaymentReducer(
  state = editPaymentInitialState,
  action: EditPaymentAction,
): EditPaymentState {
  switch (action.type) {
    case EditPaymentActionTypes.PostEditPaymentStart:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case EditPaymentActionTypes.PostEditPaymentSuccess:
      return {
        ...state,
        error: undefined,
        loading: false,
        payment: action.data,
      };
    case EditPaymentActionTypes.PostEditPaymentError:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    default:
      return state;
  }
}

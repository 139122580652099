import { createStyles, WithStyles } from '@material-ui/core';
import { color } from '../../../../../../../theme/Theme';

export const styles = createStyles({
  seperator: {
    height: 1,
    margin: 4,
    marginBottom: 12,
    background: '#eee',
  },
  gtwLight: {
    fontFamily: 'GT Walsheim Light',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  headerText2: {
    fontSize: 15,
    marginTop: 5,
  },
  toggleContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    height: 40,
    borderRadius: 2,
    margin: '10px 0px',
    boxShadow: 'none',
    borderRight: `1px solid ${color.lightBlue}`,
  },
  toggleButton: {
    flex: 1,
    color: color.lightBlue,
    borderTop: `1px solid ${color.lightBlue}`,
    borderBottom: `1px solid ${color.lightBlue}`,
    borderLeft: `1px solid ${color.lightBlue}`,
    fontSize: 15,
    height: '100%',
  },
  toggleButtonActive: {
    flex: 1,
    background: `${color.lightBlue} !important`,
    color: `#fff !important`,
    height: '100%',
  },
  imageStyle: { height: 24, width: 24, marginTop: 0 },
  footer: {
    marginBottom: 10,
  },
});

export interface ToggleButtonStyles extends WithStyles<typeof styles> {}

import { color } from '../../../theme/Theme';
import { WithStyles, createStyles } from '@material-ui/core';

export const Styles = createStyles({
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    minHeight: 150,
  },
  cardBody: {
    flex: 1,
    paddingBottom: 0,
  },
  cardTop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  image: {
    height: 20,
    width: 80,
  },
  billingInfo: {
    color: color.primary,
    opacity: 1,
  },
  extraInfo: {
    marginTop: 11,
  },
  button: {
    '&:hover': {
      backgroundColor: color.primary,
    },
    'backgroundColor': color.primary,
    'height': 36,
    'borderRadius': 2,
    'marginTop': 29,
  },
  buttonText: {
    height: 16,
    fontFamily: 'GT Walsheim',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.14,
    letterSpacing: 1.1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: color.light,
  },
});

export interface PlanFooterStyles extends WithStyles<typeof Styles> {}

import { StoreAction } from '../StoreHelper';
import { EditPaymentRequset } from '../../services/editPayment/postEditPayment/PostEditPayment.data';

export enum EditPaymentActionTypes {
  PostEditPaymentStart = 'POST_EDIT_PAYMENT_START',
  PostEditPaymentSuccess = 'POST_EDIT_PAYMENT_SUCCESS',
  PostEditPaymentError = 'POST_EDIT_PAYMENT_ERROR',
}

export interface EditPaymentErrorPayload {
  readonly error: Error | any;
}
export interface PostEditPaymentPayload {
  data: EditPaymentRequset;
  onSuccess?: () => void;
}
export type EditPaymentPayload = EditPaymentErrorPayload | PostEditPaymentPayload;

export type EditPaymentAction = StoreAction<EditPaymentActionTypes, EditPaymentPayload>;

export class EditPaymentActions {
  public static postEditPaymentStart(data: PostEditPaymentPayload): EditPaymentAction {
    return {
      type: EditPaymentActionTypes.PostEditPaymentStart,
      data,
    };
  }
  public static postEditPaymentSuccess(data: any): EditPaymentAction {
    return {
      type: EditPaymentActionTypes.PostEditPaymentSuccess,
      data,
    };
  }
  public static postEditPaymentError(data: EditPaymentErrorPayload): EditPaymentAction {
    return {
      type: EditPaymentActionTypes.PostEditPaymentError,
      data,
    };
  }
}

import * as React from 'react';
import errorIcon from '../../assets/error.svg';
import naxErrorIcon from '../../assets/nax-error.svg';
import { Typography, Button, withStyles } from '@material-ui/core';
import { color } from '../../theme/Theme';
import { sendMessageToApp, ValidMessages } from '../../helper/MessageHelper';
import { WelcomeScreenStyles, Styles } from './ServiceErrorStyles';
import { APP, AGENCIES } from '../../helper/AppNameHelper';

interface StyleProps {
  classes: WelcomeScreenStyles;
}

type OwnProps = StyleProps;
export class ServiceError extends React.Component<OwnProps, {}> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.rootContainer}>
        <div className={classes.imageContainer}>
          <img
            src={APP === AGENCIES.SORTED ? errorIcon : naxErrorIcon}
            className={classes.imageStyle}
          />
          <Typography variant="h5" className={classes.titleStyle}>
            Oops Something Went Wrong
          </Typography>
          <Typography variant="body1" className={classes.subTitleStyle}>
            Sorry, service onboarding is not supported on your app version. Please update your app.
          </Typography>
        </div>
        <div className={classes.buttonContainer}>
          {APP === AGENCIES.SORTED && (
            <Button
              fullWidth
              className={classes.chatButtonStyle}
              onClick={() => sendMessageToApp(ValidMessages.Chat)}
            >
              <Typography>CHAT</Typography>
            </Button>
          )}
          <Button
            fullWidth
            className={classes.closeButtonStyle}
            onClick={() => sendMessageToApp(ValidMessages.Dashboard)}
          >
            <Typography style={{ color: color.light }}>GO BACK</Typography>
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(Styles)(ServiceError);

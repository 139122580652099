import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../Routes';
import AppUpdateRequired from '../../containers/services/appUpdate/AppUpdate';

export default class AppUpdateLayout extends React.Component {
  public render() {
    return (
      <Switch>
        <Route
          path={routes.commonService.new(
            ':serviceName',
            ':propertyId',
            ':providerName',
            ':addressConfirmed',
            ':lookupRequired',
            ':serviceAccountId',
          )}
          exact
          component={AppUpdateRequired}
        />
        <Route
          path={routes.commonService.newOther(
            ':serviceName',
            ':propertyId',
            ':providerName',
            ':addressConfirmed',
            ':lookupRequired',
            ':serviceAccountId',
          )}
          exact
          component={AppUpdateRequired}
        />
        <Route
          path={routes.planSuccessSignUp.new(':propertyId')}
          exact
          component={AppUpdateRequired}
        />
        <Route
          path={routes.commonService.summary(':serviceName', ':propertyId', ':providerName')}
          exact
          component={AppUpdateRequired}
        />
        <Route
          path={routes.commonService.address.overview(
            ':propertyId',
            ':serviceName',
            ':addressConfirmed',
            ':lookupRequired',
            ':providerName',
            ':serviceAccountId',
          )}
          exact
          component={AppUpdateRequired}
        />
        <Route
          path={routes.commonService.address.overview(
            ':propertyId',
            ':serviceName',
            ':addressConfirmed',
            ':lookupRequired',
            ':providerName',
          )}
          exact
          component={AppUpdateRequired}
        />
        <Route
          path={routes.commonService.address.form(
            ':serviceName',
            ':propertyId',
            ':providerName',
            ':addressConfirmed',
            ':lookupRequired',
            ':serviceAccountId',
          )}
          exact
          component={AppUpdateRequired}
        />
        <Route
          path={routes.commonService.address.form(
            ':serviceName',
            ':propertyId',
            ':providerName',
            ':addressConfirmed',
            ':lookupRequired',
            ':serviceAccountId',
            ':first',
          )}
          exact
          component={AppUpdateRequired}
        />
        <Route
          path={routes.commonService.address.list(
            ':propertyId',
            ':serviceName',
            ':providerName',
            ':lookupRequired',
            ':serviceAccountId',
          )}
          exact
          component={AppUpdateRequired}
        />
        <Route
          path={routes.commonService.address.empty(
            ':propertyId',
            ':serviceName',
            ':providerName',
            ':lookupRequired',
            ':serviceAccountId',
          )}
          exact
          component={AppUpdateRequired}
        />
        <Route path={routes.checkout.select} component={AppUpdateRequired} />
      </Switch>
    );
  }
}

export const LABELS = {
  button: 'I ALREADY LIVE HERE',
  movingInfo: 'We have your moving date listed below. Is that correct? (Tap on date to edit)',
  address: 'ADDRESS',
  movingIn: 'Moving in?',
  error: 'Move In date should be at least after 2 days and Weekends are not allowed',
  connectionInfo:
    "We'll arrange for you to be connected on your moving in date. If you want to be connected on a different date, tap on the date selector.",
  connectionInfo2:
    'For connection at the property please ensure the power it turned off at the main switchboard',
  message:
    'Next day connections may incur an additional fee. For urgent requests, please chat with us now.',
};

export const DATE_FORMAT = 'dd/MM/yyyy';

export const createConnectionDateObject = (formattedDate: string, moving: boolean) => {
  return {
    serviceDetails: {
      connectionDateDetails: {
        connectionDate: formattedDate,
        moving,
        powerOn: null,
      },
    },
    step: 'CONNECTION_DATE',
  };
};

import * as Yup from 'yup';
export const states = [
  { name: 'ACT', value: 'ACT' },
  { name: 'NSW', value: 'NSW' },
  { name: 'NT', value: 'NT' },
  { name: 'QLD', value: 'QLD' },
  { name: 'SA', value: 'SA' },
  { name: 'TAS', value: 'TAS' },
  { name: 'VIC', value: 'VIC' },
  { name: 'WA', value: 'WA' },
];

export const AddressListLabels = {
  title: 'Confirm address',
  subTitle: 'Select your property below',
  buttonLabel: "MY ADDRESS ISN'T SHOWN",
  footerButtonLabel: 'NEXT',
  stepName: 'CONFIRM_ADDRESS',
};

export const streetTypes = [
  {
    name: 'Access',
    value: 'Access',
  },
  {
    name: 'Alley',
    value: 'Alley',
  },
  {
    name: 'Alleyway',
    value: 'Alleyway',
  },
  {
    name: 'Amble',
    value: 'Amble',
  },
  {
    name: 'Approach',
    value: 'Approach',
  },
  {
    name: 'Arcade',
    value: 'Arcade',
  },
  {
    name: 'Arterial',
    value: 'Arterial',
  },
  {
    name: 'Artery',
    value: 'Artery',
  },
  {
    name: 'Avenue',
    value: 'Avenue',
  },
  {
    name: 'Banan',
    value: 'Banan',
  },
  {
    name: 'Bend',
    value: 'Bend',
  },
  {
    name: 'Boardwalk',
    value: 'Boardwalk',
  },
  {
    name: 'Boulevard',
    value: 'Boulevard',
  },
  {
    name: 'Brace',
    value: 'Brace',
  },
  {
    name: 'Brae',
    value: 'Brae',
  },
  {
    name: 'Break',
    value: 'Break',
  },
  {
    name: 'Brow',
    value: 'Brow',
  },
  {
    name: 'Bypass',
    value: 'Bypass',
  },
  {
    name: 'Byway',
    value: 'Byway',
  },
  {
    name: 'Causeway',
    value: 'Causeway',
  },
  {
    name: 'Centre',
    value: 'Centre',
  },
  {
    name: 'Chase',
    value: 'Chase',
  },
  {
    name: 'Circle',
    value: 'Circle',
  },
  {
    name: 'Circuit',
    value: 'Circuit',
  },
  {
    name: 'Circus',
    value: 'Circus',
  },
  {
    name: 'Close',
    value: 'Close',
  },
  {
    name: 'Concourse',
    value: 'Concourse',
  },
  {
    name: 'Copse',
    value: 'Copse',
  },
  {
    name: 'Corner',
    value: 'Corner',
  },
  {
    name: 'Court',
    value: 'Court',
  },
  {
    name: 'Courtyard',
    value: 'Courtyard',
  },
  {
    name: 'Cove',
    value: 'Cove',
  },
  {
    name: 'Crescent',
    value: 'Crescent',
  },
  {
    name: 'Crest',
    value: 'Crest',
  },
  {
    name: 'Cross',
    value: 'Cross',
  },
  {
    name: 'Cul-de-sac',
    value: 'Cul-de-sac',
  },
  {
    name: 'Cutting',
    value: 'Cutting',
  },
  {
    name: 'Dale',
    value: 'Dale',
  },
  {
    name: 'Dip',
    value: 'Dip',
  },
  {
    name: 'Drive',
    value: 'Drive',
  },
  {
    name: 'Driveway',
    value: 'Driveway',
  },
  {
    name: 'Edge',
    value: 'Edge',
  },
  {
    name: 'Elbow',
    value: 'Elbow',
  },
  {
    name: 'End',
    value: 'End',
  },
  {
    name: 'Entrance',
    value: 'Entrance',
  },
  {
    name: 'Esplanade',
    value: 'Esplanade',
  },
  {
    name: 'Expressway',
    value: 'Expressway',
  },
  {
    name: 'Fairway',
    value: 'Fairway',
  },
  {
    name: 'Follow',
    value: 'Follow',
  },
  {
    name: 'Footway',
    value: 'Footway',
  },
  {
    name: 'Formation',
    value: 'Formation',
  },
  {
    name: 'Freeway',
    value: 'Freeway',
  },
  {
    name: 'Frontage',
    value: 'Frontage',
  },
  {
    name: 'Gap',
    value: 'Gap',
  },
  {
    name: 'Gardens',
    value: 'Gardens',
  },
  {
    name: 'Gate',
    value: 'Gate',
  },
  {
    name: 'Glade',
    value: 'Glade',
  },
  {
    name: 'Glen',
    value: 'Glen',
  },
  {
    name: 'Grange',
    value: 'Grange',
  },
  {
    name: 'Green',
    value: 'Green',
  },
  {
    name: 'Grove',
    value: 'Grove',
  },
  {
    name: 'Heights',
    value: 'Heights',
  },
  {
    name: 'Highroad',
    value: 'Highroad',
  },
  {
    name: 'Highway',
    value: 'Highway',
  },
  {
    name: 'Hill',
    value: 'Hill',
  },
  {
    name: 'Interchange',
    value: 'Interchange',
  },
  {
    name: 'Junction',
    value: 'Junction',
  },
  {
    name: 'Key',
    value: 'Key',
  },
  {
    name: 'Lane',
    value: 'Lane',
  },
  {
    name: 'Laneway',
    value: 'Laneway',
  },
  {
    name: 'Line',
    value: 'Line',
  },
  {
    name: 'Link',
    value: 'Link',
  },
  {
    name: 'Lookout',
    value: 'Lookout',
  },
  {
    name: 'Loop',
    value: 'Loop',
  },
  {
    name: 'Mall',
    value: 'Mall',
  },
  {
    name: 'Meander',
    value: 'Meander',
  },
  {
    name: 'Mews',
    value: 'Mews',
  },
  {
    name: 'Motorway',
    value: 'Motorway',
  },
  {
    name: 'Nook',
    value: 'Nook',
  },
  {
    name: 'Outlook',
    value: 'Outlook',
  },
  {
    name: 'Parade',
    value: 'Parade',
  },
  {
    name: 'Place',
    value: 'Place',
  },
  {
    name: 'Parkway',
    value: 'Parkway',
  },
  {
    name: 'Pass',
    value: 'Pass',
  },
  {
    name: 'Passage',
    value: 'Passage',
  },
  {
    name: 'Path',
    value: 'Path',
  },
  {
    name: 'Pathway',
    value: 'Pathway',
  },
  {
    name: 'Piazza',
    value: 'Piazza',
  },
  {
    name: 'Plaza',
    value: 'Plaza',
  },
  {
    name: 'Pocket',
    value: 'Pocket',
  },
  {
    name: 'Point',
    value: 'Point',
  },
  {
    name: 'Port',
    value: 'Port',
  },
  {
    name: 'Promenade',
    value: 'Promenade',
  },
  {
    name: 'Quadrant',
    value: 'Quadrant',
  },
  {
    name: 'Quays',
    value: 'Quays',
  },
  {
    name: 'Quay',
    value: 'Quay',
  },
  {
    name: 'Ramble',
    value: 'Ramble',
  },
  {
    name: 'Rest',
    value: 'Rest',
  },
  {
    name: 'Retreat',
    value: 'Retreat',
  },
  {
    name: 'Ridge',
    value: 'Ridge',
  },
  {
    name: 'Rise',
    value: 'Rise',
  },
  {
    name: 'Road',
    value: 'Road',
  },
  {
    name: 'Rotary',
    value: 'Rotary',
  },
  {
    name: 'Route',
    value: 'Route',
  },
  {
    name: 'Row',
    value: 'Row',
  },
  {
    name: 'Rue',
    value: 'Rue',
  },
  {
    name: 'Serviceway',
    value: 'Serviceway',
  },
  {
    name: 'Shunt',
    value: 'Shunt',
  },
  {
    name: 'Spur',
    value: 'Spur',
  },
  {
    name: 'Square',
    value: 'Square',
  },
  {
    name: 'Street',
    value: 'Street',
  },
  {
    name: 'Subway',
    value: 'Subway',
  },
  {
    name: 'Tarn',
    value: 'Tarn',
  },
  {
    name: 'Terrace',
    value: 'Terrace',
  },
  {
    name: 'Thoroughfare',
    value: 'Thoroughfare',
  },
  {
    name: 'Tollway',
    value: 'Tollway',
  },
  {
    name: 'Top',
    value: 'Top',
  },
  {
    name: 'Tor',
    value: 'Tor',
  },
  {
    name: 'Track',
    value: 'Track',
  },
  {
    name: 'Trail',
    value: 'Trail',
  },
  {
    name: 'Turn',
    value: 'Turn',
  },
  {
    name: 'Underpass',
    value: 'Underpass',
  },
  {
    name: 'Vale',
    value: 'Vale',
  },
  {
    name: 'Viaduct',
    value: 'Viaduct',
  },
  {
    name: 'View',
    value: 'View',
  },
  {
    name: 'Vista',
    value: 'Vista',
  },
  {
    name: 'Walk',
    value: 'Walk',
  },
  {
    name: 'Way',
    value: 'Way',
  },
  {
    name: 'Walkway',
    value: 'Walkway',
  },
  {
    name: 'Wharf',
    value: 'Wharf',
  },
  {
    name: 'Wynd',
    value: 'Wynd',
  },
];

export const validationSchema = Yup.object().shape({
  streetNumber: Yup.string().required(),
  streetName: Yup.string().required(),
  streetType: Yup.string().required(),
  suburb: Yup.string().required(),
  postcode: Yup.string().required(),
  state: Yup.string().required(),
  country: Yup.string().required(),
});

import React, { FC, useEffect, useState, useMemo } from 'react';
import { withStyles } from '@material-ui/core';
import { styles, PlanStyles } from './PlanStyles';
import { LABELS } from './PlanConstants';
import {
  AddAndUpdateCartActionRequest,
  DeleteCartItemActionRequest,
  ModifyCart,
  PlanResponse,
  Property,
  SupplierResponse,
} from '../../../../models/checkout/Checkout';
import tick from '../../../../assets/radio-filled.png';
import tickBlack from '../../../../assets/radio-filled-black.png';
import cartAdd from '../../../../assets/cartAdd.png';
import bin from '../../../../assets/bin.png';
import arrow from '../../../../assets/arrow-forward.svg';
import { ApplicationState } from '../../../../store/RootReducers';
import { Dispatch } from 'redux';
import { CheckoutActions } from '../../../../store/actions/CheckoutActions';
import { connect } from 'react-redux';
import { updateAvailableServicesOnSelect } from '../../../../helper/CheckoutHelper';
import {
  Options,
  ProviderPlan,
} from '../../../services/commonServices/CommonPlanSelectionInterface';
import { getProductDetails } from '../../../checkoutConfigure/components/SelectOptions/SelectOptionsUtils';
import { getFooterLabel } from '../../../checkoutConfigure/components/SelectedPlan/SelectedPlanUtils';
import { getFrequency } from './PlanUtils';
import { AGENCIES, APP } from '../../../../helper/AppNameHelper';

interface PlanProps extends PlanStyles {
  plan: PlanResponse;
  serviceType: string;
  supplier: SupplierResponse;
  logo: string;
  cartView?: boolean;
  hideAction?: boolean;
  showCompare?: boolean;
  property: Property;
  frequency?: string;
  handleAction: () => void;
  onSelection?: () => void;
  updateCart: (data: AddAndUpdateCartActionRequest) => void;
  deleteCartItem: (data: DeleteCartItemActionRequest) => void;
  addToCompare: (data: ModifyCart) => void;
  removeFromCompare: (data: ModifyCart) => void;
}

const Plan: FC<PlanProps> = ({
  classes,
  plan,
  serviceType,
  supplier,
  logo,
  cartView,
  hideAction,
  showCompare,
  property,
  frequency,
  handleAction,
  onSelection,
  updateCart,
  deleteCartItem,
  addToCompare,
  removeFromCompare,
}) => {
  // TODO: Types conflict here
  const { productOptions } = useMemo(() => {
    return getProductDetails(supplier.extendedData!, plan.selectedProductId);
  }, []);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const isTradeRequestSupplier = supplier.extendedData!.ServiceCategoryId === 'TradeRequest';

  const handleTap = () => {
    if (!isDragging) {
      const updatedAvailableServices = updateAvailableServicesOnSelect(
        !!plan.selected,
        serviceType,
        supplier.providerId,
        plan.productId,
      );
      if (!!plan.selected) {
        deleteCartItem({
          availableServices: updatedAvailableServices,
          deleteCartItemRequest: {
            propertyId: property.id,
            serviceType,
            supplierId: supplier.providerId,
            planId: plan.productId,
          },
        });
      } else {
        updateCart({
          availableServices: updatedAvailableServices,
          propertyId: property.id,
        });
      }

      if (!plan.selected && onSelection) {
        onSelection();
      }
    }
  };
  const toggleCompare = () => {
    const data = {
      productId: plan.productId,
      providerId: supplier.providerId,
      serviceType,
    };

    if (plan.compare) {
      removeFromCompare(data);
    } else {
      addToCompare(data);
    }
  };

  const handleTouchStart = () => {
    setIsDragging(false);
  };

  const handleTouchMove = () => {
    setIsDragging(true);
  };

  const handleTouchEnd = () => {
    if (!isDragging) {
      handleAction();
    }
  };

  return (
    <div
      onMouseDown={handleTouchStart}
      onMouseMove={handleTouchMove}
      className={`${classes.planContainer} ${plan.selected && !cartView && classes.activePlan}`}
    >
      <div
        className={classes.planContent}
        onClick={() => (!cartView ? handleTouchEnd() : undefined)}
      >
        <div className={classes.planHeader}>
          <div className={classes.logoContainer}>
            <img className={classes.logo} src={logo} alt="logo" />
          </div>
          <div className={classes.titleContainer}>
            <div className={classes.title}>{supplier.name}</div>
            <div className={classes.description}>{plan.title}</div>
          </div>
          {!!plan.price && (
            <div className={classes.priceContainer}>
              <div className={classes.from}>{plan.pricingType}</div>
              <div className={classes.priceWrapper}>
                <div className={classes.price}>{`${
                  frequency
                    ? getFooterLabel(productOptions as Options, frequency)
                    : `$${plan.price}`
                }`}</div>
                <div className={classes.uom}>{`/${
                  frequency ? getFrequency(frequency) : plan.uom
                }`}</div>
              </div>
            </div>
          )}
        </div>
        {!cartView && plan.subtext && (
          <div
            className={classes.infoText}
            dangerouslySetInnerHTML={{
              __html: plan.subtext,
            }}
          />
        )}
      </div>

      {!hideAction && (
        <div
          className={`${classes.actionContainer} ${
            plan.selected && !cartView && classes.actionContainerSelected
          }`}
          onClick={() => handleTap()}
        >
          {showCompare && (
            <div
              className={`${!!plan.compare ? classes.activeCompare : classes.compareContainer}`}
              onClick={(e) => {
                e.stopPropagation();
                toggleCompare();
              }}
            >
              {!!plan.compare ? 'Comparing' : 'Compare'}
            </div>
          )}
          <div className={classes.actionText}>
            {isTradeRequestSupplier ? LABELS.SELECT : plan.selected ? LABELS.REMOVE : LABELS.ADD}
          </div>
          {isTradeRequestSupplier ? (
            <div className={classes.actionIconContainer}>
              <img className={classes.actionIcon} src={arrow} alt="action" />
            </div>
          ) : (
            <div className={classes.actionIconContainer}>
              <img
                className={classes.actionIcon}
                src={plan.selected ? bin : cartAdd}
                alt="action"
              />
            </div>
          )}
          {!cartView && plan.selected && (
            <img
              className={classes.tick}
              src={APP === AGENCIES.NAX ? tickBlack : tick}
              alt="tick"
            />
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  property: state.checkout.property,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCart: (data: AddAndUpdateCartActionRequest) => dispatch(CheckoutActions.updateCart(data)),
  addToCompare: (data: ModifyCart) => dispatch(CheckoutActions.addToCompare(data)),
  removeFromCompare: (data: ModifyCart) => dispatch(CheckoutActions.removeFromCompare(data)),
  deleteCartItem: (data: DeleteCartItemActionRequest) =>
    dispatch(CheckoutActions.deleteCartItem(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Plan));

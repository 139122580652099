import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/RootReducers';
import { Dispatch, AnyAction } from 'redux';
import {
  ElectricityServiceDetails,
  IdDetails,
  PersonalDetails,
  ServiceAccount,
} from '../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { ElectricityServiceActions } from '../../../store/actions/ElectricityServiceActions';
import PersonalDetailsUI, {
  InitialValues,
} from '../../../component/services/personalDetails/PersonalDetails';
import { routes } from '../../../Routes';
import { History } from 'history';
import { format } from 'date-fns';
import { RouteComponentProps } from 'react-router-dom';
import { BackHelper } from '../../../helper/BackHelper';

interface PropsFromState {
  service: ElectricityServiceDetails;
  serviceAccountId: number;
}

interface PropsFromRoute {
  history: History;
}

interface PropsFromDispatch {
  postPersonalDetails: (
    propertyId: number,
    serviceAccountId: number,
    personalDetails: ElectricityServiceDetails,
    onSuccess: () => void,
  ) => void;
}

type OwnProps = PropsFromDispatch &
  PropsFromState &
  PropsFromRoute &
  RouteComponentProps<{
    propertyId: string;
    serviceSummary: string;
    paymentRefId: string;
  }>;

class PersonalDetail extends React.Component<OwnProps> {
  public componentDidMount() {
    const { propertyId, paymentRefId } = this.props.match.params;
    const { serviceAccountId } = this.props;
    BackHelper.saveParams({
      propertyId,
      paymentRefId,
      serviceAccountId: serviceAccountId.toString(),
    });
  }
  public render() {
    return (
      <PersonalDetailsUI
        personalDetails={this.props.service.serviceDetails.personalDetails as PersonalDetails}
        handleSave={this.handleSave}
      />
    );
  }

  private handleSave = (personalDetails: InitialValues) => {
    const { propertyId } = this.props.match.params;
    const { serviceAccountId } = this.props;
    const fetchedDetails = this.props.service.serviceDetails.personalDetails as PersonalDetails;
    const idDetails: IdDetails = {
      dlStateIssue: personalDetails.stateIssue,
      medicareRefNumber: personalDetails.referenceNumber,
      medicareCardColor: personalDetails.medicareCardColor,
      passportCountry: personalDetails.countryOfIssue,
    };
    const postData: ElectricityServiceDetails = {
      serviceDetails: {
        personalDetails: {
          id: fetchedDetails.id,
          name: personalDetails.firstName,
          surname: personalDetails.lastName,
          email: personalDetails.email,
          phone: personalDetails.mobile,
          salutation: personalDetails.title,
          dob: format(personalDetails.dob as Date, 'dd/MM/yyyy'),
          idType: personalDetails.idType,
          idNumber: personalDetails.idNumber,
          userType: fetchedDetails.userType,
          address: fetchedDetails.address,
          idExpiry: fetchedDetails.idExpiry,
          active: fetchedDetails.active,
          branches: fetchedDetails.branches,
          idDetails,
        },
      },
      step: 'PERSONAL_DETAILS',
    };
    BackHelper.saveCount(-1);
    this.props.postPersonalDetails(
      parseInt(propertyId, 10),
      serviceAccountId,
      postData,
      this.onSuccess,
    );
  };
  private onSuccess = () => {
    const { propertyId, serviceSummary } = this.props.match.params;
    this.props.history.push(routes.service.termsAndConditions.new(propertyId, serviceSummary));
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  const service = state.electricityService.service as ElectricityServiceDetails;
  return {
    service,
    serviceAccountId: (
      (state.electricityService.service as ElectricityServiceDetails)
        .serviceAccount as ServiceAccount
    ).id as number,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): PropsFromDispatch {
  return {
    postPersonalDetails: (
      propertyId: number,
      serviceAccountId: number,
      personalDetails: ElectricityServiceDetails,
      onSuccess: () => void,
    ) => {
      dispatch(
        ElectricityServiceActions.postElectricityServiceStart({
          propertyId,
          serviceAccountId,
          data: personalDetails,
          onSuccess,
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetail);

import { StoreAction } from '../StoreHelper';
import {
  PostRentServiceRequest,
  PostRentServiceResponse,
} from '../../services/rentService/postRentService/PostRentService.data';
import {
  GetRentServiceResponse,
  Services,
} from '../../services/rentService/getRentService/GetRentServiceResponse.data';
import { PostServiceabilityRequest } from '../../services/rentService/postServiceability/PostServiceability.data';

export enum RentServiceActionTypes {
  GetRentServiceStart = 'GET_RENT_SERVICE_START',
  GetRentServiceSuccess = 'GET_RENT_SERVICE_SUCCESS',
  GetRentServiceError = 'GET_RENT_SERVICE_ERROR',
  PostRentServiceStart = 'POST_RENT_SERVICE_START',
  PostRentServiceSuccess = 'POST_RENT_SERVICE_SUCCESS',
  PostRentServiceError = 'POST_RENT_SERVICE_ERROR',
  SaveSelectedService = 'SAVE_SELECTED_SERVICE',
  PostServiceabilityStart = 'POST_SERVICEABILITY_START',
  PostServiceabilitySuccess = 'POST_SERVICEABILITY_SUCCESS',
  PostServiceabilityError = 'POST_SERVICEABILITY_ERROR',
  SavePropertyId = 'SAVE_PROPERTY_ID',
}

export interface RentServiceErrorPayload {
  readonly error: Error | any;
}

export interface PostServicePayload {
  data: PostRentServiceRequest;
  onSuccess: () => void;
}

export interface GetRentServicePayload {
  onSuccess: (response: GetRentServiceResponse[]) => void;
}

export interface PostServiceabilityPayload {
  data: PostServiceabilityRequest[];
  leaseId: number;
  onSuccess: () => void;
}

export type RentServicePayload =
  | GetRentServiceResponse[]
  | PostServicePayload
  | PostRentServiceResponse
  | RentServiceErrorPayload
  | GetRentServicePayload
  | Services
  | number
  | PostServiceabilityPayload
  | Services[];

export type RentServiceAction = StoreAction<RentServiceActionTypes, RentServicePayload>;

export class RentServiceActions {
  public static getRentServiceStart(data: GetRentServicePayload): RentServiceAction {
    return {
      type: RentServiceActionTypes.GetRentServiceStart,
      data,
    };
  }
  public static getRentServiceSuccess(data: GetRentServiceResponse[]): RentServiceAction {
    return {
      type: RentServiceActionTypes.GetRentServiceSuccess,
      data,
    };
  }
  public static getRentServiceError(data: RentServiceErrorPayload): RentServiceAction {
    return {
      type: RentServiceActionTypes.GetRentServiceError,
      data,
    };
  }
  public static postRentServiceStart(data: PostServicePayload): RentServiceAction {
    return {
      type: RentServiceActionTypes.PostRentServiceStart,
      data,
    };
  }
  public static postRentServiceSuccess(data: PostRentServiceResponse): RentServiceAction {
    return {
      type: RentServiceActionTypes.PostRentServiceSuccess,
      data,
    };
  }
  public static postRentServiceError(data: RentServiceErrorPayload): RentServiceAction {
    return {
      type: RentServiceActionTypes.PostRentServiceError,
      data,
    };
  }
  public static saveSelectedServices(data: Services): RentServiceAction {
    return {
      type: RentServiceActionTypes.SaveSelectedService,
      data,
    };
  }
  public static postServiceabilityStart(data: PostServiceabilityPayload): RentServiceAction {
    return {
      type: RentServiceActionTypes.PostServiceabilityStart,
      data,
    };
  }
  public static postServiceabilitySuccess(data: Services[]): RentServiceAction {
    return {
      type: RentServiceActionTypes.PostServiceabilitySuccess,
      data,
    };
  }
  public static postServiceabilityError(data: RentServiceErrorPayload): RentServiceAction {
    return {
      type: RentServiceActionTypes.PostServiceabilityError,
      data,
    };
  }

  public static savePropertyId(data: number): RentServiceAction {
    return {
      type: RentServiceActionTypes.SavePropertyId,
      data,
    };
  }
}

export const processAddToken = (callBackFunction: (result: any) => void) => {
  // @ts-ignore
  CBA.ProcessAddToken({
    AuthKey: '',
    Crn1: 'john.smith@email.com.au',
    EmailAddress: 'john.smith@email.com.au',
    Type: 'CREDITCARD',
    CardHolderName: 'test',
    CardNumber: '5123456789012346',
    ExpiryMonth: '12',
    ExpiryYear: '34',
    CallbackFunction: (result: any) => callBackFunction(result),
  });
};

export const validateTokenResponse = (result: any) => {
  return result.AjaxResponseType === 0 && result.ApiResponseCode === 0
    ? { success: true, data: result.ResultKey }
    : { success: false, data: result.Errors[0].Message };
};

export const createPaymentRequestObject = (paymentRefId: string) => ({
  serviceDetails: {
    paymentRefId,
  },
  step: 'PAYMENT',
});

/// <summary>
/// Generates url: {AppSettings.service.baseUrl}/{this.serviceApi}
// DO NOT append or prepend '/' here!
export enum ServiceType {
  RefreshToken = 'refreshToken',
  Payment = 'api/v1/sorted',
  PaymentPayId = 'bff/api/payment',
  ElectricityPlan = '/api/v1/products/electricity/quote',
  BroadbandPlan = '/api/v1/products/broadband/plans',
  CommonService = 'sorted/api/v1/sorted',
  bffCommonService = 'bff/api/v1/sorted',
  Service = 'api/v1/sorted',
  AddPaymentService = 'api/payment',
  AddressLookup = 'api/v1/sorted',
  Quote = 'api/v1/sorted',
  EditPayment = 'api/v1/sorted',
  GetAddress = 'api/v1/sorted',
  planSummary = 'api/v1/sorted',
  bffPlanSummary = 'bff/api/v1/sorted',
  downloadPdf = 'api/v1/sorted/download',
  propertyOnboard = 'api/properties/add-standalone-property',
  checkout = 'checkout',
  cart = 'api/service-cart',
  token = 'guest/session',
  FileUploadOrDelete = 'api/file/upload',
}

import * as React from 'react';
import ConcessionCardForm from '../../../../component/concessionCard/concessionCardForm/ConcessionCardForm';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/RootReducers';
import { AnyAction } from 'redux';
import { ConcessionCardDetails } from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { PlanState } from '../../../../store/state/PlanState';
import { History } from 'history';
import { RouteComponentProps } from 'react-router-dom';
import { PlanDetailsSubmit } from '../../../../models/plan/electricity/postPlanDetails/PlanDetailsSubmit.data';
import { PlanActions } from '../../../../store/actions/PlanActions';
import { routes } from '../../../../Routes';
import { BackHelper } from '../../../../helper/BackHelper';

interface PropsFromState {
  plan: PlanState;
}
interface PropsFromDispatch {
  savePlan: (data: PlanDetailsSubmit) => void;
}
interface PropsFromRoute {
  history: History;
}
type OwnProps = PropsFromState &
  PropsFromDispatch &
  PropsFromRoute &
  RouteComponentProps<{ propertyId: string; type: string }>;

class ConcessionCard extends React.Component<OwnProps> {
  public render() {
    const cardType = this.props.match.params.type;
    const { propertyId } = this.props.match.params;
    return (
      <ConcessionCardForm
        {...this.props}
        handleSubmit={this.handleSubmit}
        cardType={cardType}
        propertyId={propertyId}
      />
    );
  }
  public handleSubmit = (concessionCard: ConcessionCardDetails) => {
    const { plan, lifeSupport } = this.props.plan;
    const { propertyId } = this.props.match.params;
    const data: PlanDetailsSubmit = {
      ...plan,
      lifeSupport,
      concessionCard,
    };
    this.props.savePlan(data);
    BackHelper.saveCount(-1);
    this.props.history.push(routes.service.connectionDate.saved(propertyId));
  };
}
function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>): PropsFromDispatch {
  return {
    savePlan: (data: PlanDetailsSubmit) => dispatch(PlanActions.saveChoosenPlan(data)),
  };
}

function mapStateToProps(state: ApplicationState): PropsFromState {
  return {
    plan: state.plan,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ConcessionCard);

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useRef, useState, useEffect, useMemo, RefObject } from 'react';
import { History } from 'history';
import { withStyles } from '@material-ui/core';
import { styles, SelectedPlanStyles } from './SelectedPlanStyles';
import { LABELS } from './SelectedPlanConstants';
import errorIcon from '../../../../assets/error-bold.png';
import tick from '../../../../assets/radio-filled.png';
import tickBlack from '../../../../assets/radio-filled-black.png';
import edit from '../../../../assets/edit.png';
import downArrow from '../../../../assets/downArrow.png';
import {
  AddAndUpdateCartActionRequest,
  AvailableServicesResponse,
  InitialConcessionCardValues,
  PageName,
  Property,
  ServiceDetailsData,
  ServiceTypes,
} from '../../../../models/checkout/Checkout';
import PlanInformation from '../../../../component/planInformation/PlanInformation';
import SelectOptions from '../SelectOptions/SelectOptions';
import SelectTerms from '../SelectTerms/SelectTerms';
import {
  ConcessionCard,
  FeatureDetails,
  Options,
  PlanDetailObject,
  PlanOption,
  ProductDetails,
  ProviderPlan,
  SummaryDetail,
} from '../../../services/commonServices/CommonPlanSelectionInterface';
import { get } from 'lodash';
import uuid from 'uuid';
import CheckoutFooter from '../../../../component/checkoutFooter/CheckoutFooter';
import { Dispatch } from 'redux';
import { CheckoutActions } from '../../../../store/actions/CheckoutActions';
import { ApplicationState } from '../../../../store/RootReducers';
import { connect } from 'react-redux';
import { PersonalDetails } from '../../../../services/electricityService/getElectricityService/GetElectricityService.data';
import { updateCardDataInAvailableServices } from '../../../../helper/CheckoutHelper';
import {
  getPreAgreementSummary,
  getProductDetails,
  getTermsAndConditionsValue,
  setSupplierAnswers,
} from '../SelectOptions/SelectOptionsUtils';
import { routes } from '../../../../Routes';
import {
  allQuestionsAnswered,
  checkPlanDetails,
  getFooterLabel,
  getMonthlyDefault,
  setSummary,
} from './SelectedPlanUtils';
import MainContainer from '../../../../component/mainContainer/MainContainer';
import ServiceTabs from '../ServiceTabs/ServiceTabs';
import { AGENCIES, APP } from '../../../../helper/AppNameHelper';

interface SelectedPlanProps extends SelectedPlanStyles {
  history: History;
  selectedPlan: AvailableServicesResponse;
  index: number;
  onNext: () => void;
  goBack: () => void;
  updateCart: (data: AddAndUpdateCartActionRequest) => void;
  availableServices: AvailableServicesResponse[];
  personalDetails: PersonalDetails;
  property: Property;
  setActiveTab: (tab: number) => void;
  cartItems: AvailableServicesResponse[];
  isExternal: boolean;
}

const SelectedPlan: FC<SelectedPlanProps> = ({
  classes,
  history,
  selectedPlan,
  index,
  onNext,
  goBack,
  updateCart,
  availableServices,
  personalDetails,
  property,
  setActiveTab,
  cartItems,
  isExternal,
}) => {
  const extendedData = useMemo<ProviderPlan>(() => {
    return selectedPlan && selectedPlan.suppliers![0].extendedData!;
  }, [selectedPlan]);

  // TODO: Types conflict here
  const { productOptions, extendedDataPlan } = useMemo(() => {
    return getProductDetails(extendedData, selectedPlan.suppliers![0].plans[0].selectedProductId);
  }, [extendedData, selectedPlan]);

  const [productDetails, setProductDetails] = useState<Options | {}>(productOptions);

  const cardData = useMemo<ServiceDetailsData | null | undefined>(() => {
    return selectedPlan && selectedPlan.suppliers![0].plans[0].cartData;
  }, [selectedPlan]);

  const planOptions = useMemo<PlanOption[] | undefined>(() => {
    return (extendedDataPlan as PlanDetailObject).Options.filter(
      (option: PlanOption) => option.Options.length > 1,
    );
  }, [extendedDataPlan]);

  const planLinks = get(extendedDataPlan, 'Links', []);

  const planInfoRef = useRef<HTMLDivElement>(null);
  const planOptionsRef = useRef<HTMLDivElement>(null);
  const planTermsRef = useRef<HTMLDivElement>(null);
  const [planInfoHeight, setPlanInfoHeight] = useState<number>(0);
  const [planOptionsHeight, setPlanOptionsHeight] = useState<number>(0);
  const [planTermsHeight, setPlanTermsHeight] = useState<number>(0);
  const [concessionCard, setConcessionCard] = useState<InitialConcessionCardValues | null>(null);
  const [stopSaleList, setStopSaleList] = useState<string[]>([]);
  const [frequency, setFrequency] = useState<string>(
    getMonthlyDefault(extendedDataPlan as PlanDetailObject),
  );
  const [planSummary, setPlanSummary] = useState<Map<string, FeatureDetails> | {}>({
    ...setSupplierAnswers(
      selectedPlan.suppliers![0].extendedData!.SupplierOptions,
      planOptions,
      cardData,
      cartItems,
    ).supplierAnswer,
  });
  const [isTermsConditionAccepted, setTermsAndConditionsAccepted] = useState<boolean>(
    getTermsAndConditionsValue(cardData).isTermsAndConditionsAccepted,
  );
  const [isPlanDetailsCompleted, setIsPlanDetailsCompleted] = useState<boolean>(
    checkPlanDetails(planSummary, concessionCard),
  );
  const [validate, setValidationFlag] = useState<boolean>(false);

  useEffect(() => {
    const flag = allQuestionsAnswered(planSummary, concessionCard);
    if (flag) {
      if (planOptionsHeight > 0 && !isTermsConditionAccepted) {
        toggleAccordion(planTermsHeight, planTermsRef, setPlanTermsHeight);
      }
      // toggleAccordion(planOptionsHeight, planOptionsRef, setPlanOptionsHeight);
    }
    setIsPlanDetailsCompleted(checkPlanDetails(planSummary, concessionCard));

    if (!flag && planOptionsHeight > 0) {
      setPlanOptionsHeight(planOptionsRef.current!.scrollHeight);
    }
  }, [planSummary, concessionCard]);

  useEffect(() => {
    const handleClickInside = (event: any) => {
      if (planOptionsRef.current && planOptionsRef.current.contains(event.target)) {
        setPlanOptionsHeight(planOptionsRef.current!.scrollHeight);
      }
    };

    document.addEventListener('click', handleClickInside);

    return () => {
      document.removeEventListener('click', handleClickInside);
    };
  }, []);

  useEffect(() => {
    if (!isPlanDetailsCompleted && planOptionsHeight > 0) {
      setPlanOptionsHeight(planOptionsRef.current!.scrollHeight);
    }
  }, [isPlanDetailsCompleted, planOptionsHeight]);

  useEffect(() => {
    // if (selectedPlan.type === ServiceTypes.Electricity) {
    //   setPlanInfoHeight(planInfoRef.current!.scrollHeight);
    // } else {
    //   setPlanInfoHeight(0);
    // }
    setPlanInfoHeight(planInfoRef.current!.scrollHeight);
    setPlanOptionsHeight(planOptionsRef.current!.scrollHeight);
    setPlanTermsHeight(planTermsRef.current!.scrollHeight);
  }, [selectedPlan]);

  const toggleAccordion = (
    height: number,
    ref: RefObject<HTMLDivElement>,
    setHeight: (value: number) => void,
  ) => {
    setHeight(height === 0 ? ref.current!.scrollHeight : 0);
  };

  const submitPlanDetails = () => {
    // product details
    const selectedProductDetails: ProductDetails = {
      productCode: get(productDetails, 'ProductId', ''),
      productId: get(productDetails, 'ProductId', ''),
      productName: get(productDetails, 'PlanName', ''),
      monthlyCost: get(productDetails, 'BasePriceMonthly', '0'),
      dailyCost: get(productDetails, 'BasePriceDaily', '0'),
      cost: get(productDetails, 'BasePriceMonthly', '0'),
      productType: extendedData.ProductType,
      productDescription: get(productDetails, 'Label', ''), // TODO need to update
      provider: selectedPlan.suppliers![0].name,
    };

    // features details
    const featureDetails: FeatureDetails[] = [];
    const settings =
      extendedData && extendedData.Settings && extendedData.Settings.length > 0
        ? extendedData.Settings
        : [];
    const officeSetting = settings.find((setting) => setting.Name === 'TradesOfficeId');
    const officeId = officeSetting ? officeSetting.Value : null;

    Object.keys({ ...planSummary }).map((keyName) => {
      featureDetails.push({
        ...planSummary[keyName],
        cost: planSummary[keyName].cost || null,
      });
    });
    const bodySummary: FeatureDetails = {
      type: 'postSummaryDetails',
      label: 'Body',
      labelId: 'Body',
      value: extendedData.PostAgreementSummary.Body.toString(),
      valueId: 'postSummary',
      cost: null,
    };
    const titleSummary: FeatureDetails = {
      type: 'postSummaryDetails',
      label: 'title',
      labelId: 'title',
      value: extendedData.PostAgreementSummary.Title.toString(),
      valueId: 'postSummary',
      cost: null,
    };
    if (officeId) {
      const officeDetails: FeatureDetails = {
        type: 'officeId',
        label: 'officeId',
        labelId: 'officeId',
        value: officeId,
        valueId: 'officeId',
        cost: null,
      };
      featureDetails.push(officeDetails);
    }

    featureDetails.push(bodySummary);
    featureDetails.push(titleSummary);

    // summary details
    const summaryDetails: SummaryDetail[] = setSummary(selectedProductDetails, extendedData);

    // concession card
    let concessionCardDetails: ConcessionCard | null = null;
    if (concessionCard) {
      const expiry = concessionCard.expiry!.split('/');
      concessionCardDetails = {
        name: concessionCard.name,
        cardNumber: concessionCard.cardNumber,
        cardType: concessionCard.cardType,
        expiryMonth: expiry[0],
        expiryYear: expiry[1],
      };
    }
    const transactionId =
      selectedPlan.suppliers![0].plans[0].cartData &&
      selectedPlan.suppliers![0].plans[0].cartData.transactionId
        ? selectedPlan.suppliers![0].plans[0].cartData.transactionId
        : uuid();

    const data: ServiceDetailsData = {
      lookupDetails: null,
      serviceabilityAddress: property,
      acceptTermAndCondition: isTermsConditionAccepted,
      connectionDateDetails: null,
      paymentRefId:
        selectedPlan.suppliers![0].plans[0].cartData &&
        selectedPlan.suppliers![0].plans[0].cartData.paymentRefId
          ? selectedPlan.suppliers![0].plans[0].cartData.paymentRefId
          : null,
      personalDetails,
      planDetails: {
        productDetails: selectedProductDetails,
        featureDetails,
        summaryDetails,
        attributes: {},
        transactionId: transactionId,
        concessionCard: concessionCardDetails,
        isAcceptTermAndCondition: true,
        skipPaymentStep: !(extendedDataPlan as PlanDetailObject).PaymentInformation.Required,
        paymentType: (extendedDataPlan as PlanDetailObject).PaymentInformation.PaymentGateway,
      },
      transactionId: transactionId,
    };
    const updatedAvailableServices = updateCardDataInAvailableServices(
      availableServices,
      selectedPlan.type,
      selectedPlan.suppliers![0].providerId,
      selectedPlan.suppliers![0].plans[0].productId,
      data,
    );

    updateCart({
      availableServices: updatedAvailableServices,
      propertyId: `${property.id}`,
    });
  };

  return (
    <div key={`${index}_select_plan`}>
      <ServiceTabs
        activeTab={index}
        setActiveTab={(value: number) => {
          // save data before changing active tab
          submitPlanDetails();
          setActiveTab(value);
        }}
        pageName={PageName.CHECKOUTCONFIGURE}
        key={`service_tab_${index}`}
        overrideCompleted={
          isPlanDetailsCompleted && isTermsConditionAccepted && stopSaleList.length === 0
        }
      />
      <MainContainer>
        <div className={classes.selectedPlanContainer}>
          <div className={classes.serviceHeader}>
            <div className={classes.serviceTypeIconContainer}>
              <img className={classes.serviceTypeIcon} src={selectedPlan.logo} alt="service type" />
            </div>
            <div className={classes.serviceTypeTitle}>{selectedPlan.type}</div>
          </div>
          <div className={classes.card}>
            <div className={classes.cardHeaderContainer}>
              <div className={classes.cardTitle}>{LABELS.PLAN}</div>
            </div>
            <div className={classes.planHeader}>
              <div className={classes.logoContainer}>
                <img className={classes.logo} src={selectedPlan.suppliers![0].logo} alt="logo" />
              </div>
              <div className={classes.titleContainer}>
                <div className={classes.title}>{selectedPlan.suppliers![0].name}</div>
                <div className={classes.description}>
                  {selectedPlan.suppliers![0].plans[0].title}
                </div>
              </div>
            </div>
            {/* Plan information --------------------------------------------------------------- */}
            <div
              className={classes.content}
              ref={planInfoRef}
              style={{
                maxHeight: `${planInfoHeight}px`,
                opacity: planInfoHeight > 0 ? 1 : 0,
              }}
            >
              <PlanInformation
                plan={selectedPlan.suppliers![0].plans[0]}
                serviceType={selectedPlan.type}
                supplier={selectedPlan.suppliers![0]}
                logo={selectedPlan.suppliers![0].logo}
                accordian
                closePopup={() => null}
              />
            </div>
            <div
              className={classes.actionContainer}
              onClick={() => toggleAccordion(planInfoHeight, planInfoRef, setPlanInfoHeight)}
            >
              <div className={classes.actionTitle}>
                {planInfoHeight > 0 ? LABELS.HIDE_PLAN : LABELS.SHOW_PLAN}
              </div>
              <div
                className={`${classes.actionIconContainer} ${planInfoHeight > 0 && classes.rotate}`}
              >
                <img className={classes.actionIcon} src={downArrow} alt="status" />
              </div>
            </div>
          </div>
          {/* Plan options --------------------------------------------------------------- */}
          <div
            className={`${classes.card} ${validate && !isPlanDetailsCompleted ? 'errorCard' : ''}`}
          >
            <div
              className={`${classes.cardHeaderContainer} ${
                planOptionsHeight === 0 && classes.noBorder
              } `}
            >
              <div className={classes.cardTitle}>{LABELS.OPTIONS}</div>
              <div className={classes.cardIconContainer}>
                <img
                  className={classes.cardIcon}
                  src={
                    isPlanDetailsCompleted
                      ? APP === AGENCIES.NAX
                        ? tickBlack
                        : tick
                      : planTermsHeight > 0
                      ? errorIcon
                      : errorIcon
                  }
                  alt="status"
                />
              </div>
            </div>
            <div
              className={classes.content}
              ref={planOptionsRef}
              style={{
                maxHeight: `${planOptionsHeight}px`,
                opacity: planOptionsHeight > 0 ? 1 : 0,
              }}
            >
              <SelectOptions
                setPlanSummary={setPlanSummary}
                selectedSupplierOptions={selectedPlan.suppliers![0].extendedData!.SupplierOptions}
                planOptions={planOptions}
                setConcessionCard={setConcessionCard}
                stopSaleList={stopSaleList}
                setStopSaleList={setStopSaleList}
                cardData={cardData}
                cartItems={cartItems}
                extendedDataPlan={extendedDataPlan as PlanDetailObject}
                setProductDetails={setProductDetails}
                key={`${selectedPlan.suppliers![0].providerId}`}
                publicHolidays={extendedData.PublicHolidays}
              />
            </div>
            <div
              className={classes.actionContainer}
              onClick={() =>
                toggleAccordion(planOptionsHeight, planOptionsRef, setPlanOptionsHeight)
              }
            >
              <div className={classes.actionTitle}>
                {planOptionsHeight > 0 ? LABELS.HIDE_OPTIONS : LABELS.SHOW_OPTIONS}
              </div>
              <div
                className={`${classes.actionIconContainer} ${
                  planOptionsHeight > 0 && classes.rotate
                }`}
              >
                <img className={classes.actionIcon} src={downArrow} alt="status" />
              </div>
            </div>
          </div>

          {/* Plan terms --------------------------------------------------------------- */}
          <div
            className={`${classes.card} ${
              validate && !isTermsConditionAccepted ? 'errorCard' : ''
            }`}
          >
            <div
              className={`${classes.cardHeaderContainer} ${
                planTermsHeight === 0 && classes.noBorder
              } `}
            >
              <div className={classes.cardTitle}>{LABELS.TERMS}</div>
              <div className={classes.cardIconContainer}>
                <img
                  className={classes.cardIcon}
                  src={
                    isTermsConditionAccepted ? (APP === AGENCIES.NAX ? tickBlack : tick) : errorIcon
                  }
                  alt="status"
                />
              </div>
            </div>
            <div
              className={classes.content}
              ref={planTermsRef}
              style={{
                maxHeight: `${planTermsHeight}px`,
                opacity: planTermsHeight > 0 ? 1 : 0,
              }}
            >
              <SelectTerms
                setTermsAndConditionsFlag={(flag: boolean) => {
                  setTermsAndConditionsAccepted(flag);
                  if (flag) {
                    toggleAccordion(planTermsHeight, planTermsRef, setPlanTermsHeight);
                    if (planInfoHeight > 0) {
                      toggleAccordion(planInfoHeight, planInfoRef, setPlanInfoHeight);
                    }
                  }
                }}
                preAgreementSummary={getPreAgreementSummary(
                  selectedPlan.suppliers![0].extendedData!.PreAgreementSummary,
                  extendedDataPlan as PlanDetailObject,
                )}
                uiModelAttributes={selectedPlan.suppliers![0].extendedData!.UiModelAttributes}
                cardData={cardData}
                isExternal={isExternal}
                planLinks={planLinks}
              />
            </div>
            <div
              className={classes.actionContainer}
              onClick={() => toggleAccordion(planTermsHeight, planTermsRef, setPlanTermsHeight)}
            >
              <div className={classes.actionTitle}>
                {planTermsHeight > 0 ? LABELS.HIDE_TERMS : LABELS.SHOW_TERMS}
              </div>
              <div
                className={`${classes.actionIconContainer} ${
                  planTermsHeight > 0 && classes.rotate
                }`}
              >
                <img className={classes.actionIcon} src={downArrow} alt="status" />
              </div>
            </div>
          </div>
        </div>
      </MainContainer>
      <CheckoutFooter
        primaryText={LABELS.NEXT}
        onPrimary={() => {
          submitPlanDetails();
          onNext();
        }}
        disabled={
          !(isPlanDetailsCompleted && isTermsConditionAccepted && stopSaleList.length === 0)
        }
        secondaryText={LABELS.BACK}
        onSecondary={() => {
          submitPlanDetails();
          goBack();
        }}
        key={`select_plan_footer_${index}`}
        showPricing={
          true && selectedPlan.suppliers![0].extendedData!.ServiceCategoryId !== 'TradeRequest'
        }
        footerPriceLabel={getFooterLabel(productDetails as Options, frequency)}
        footerPricing={(extendedDataPlan as PlanDetailObject).FooterPricing}
        frequency={frequency}
        setFrequency={(value: string) => setFrequency(value)}
        handleValidation={() => {
          setValidationFlag(true);
          setTimeout(() => {
            setValidationFlag(false);
          }, 5000);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  availableServices: state.checkout.availableServices,
  personalDetails: state.checkout.personalDetails,
  property: state.checkout.property,
  isExternal: state.token.isExternal,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCart: (data: AddAndUpdateCartActionRequest) => dispatch(CheckoutActions.updateCart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SelectedPlan));

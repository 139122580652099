import { color } from '../../../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  input: {
    width: '95%',
    padding: '10px 0px',
  },
  inputGrid: {
    width: '90%',
    padding: '10px 0px',
  },
  inputContainer: {
    padding: '20px 0px 20px 20px',
  },
  footerButtonsContainer: {
    bottom: 12,
    left: 12,
    right: 12,
    position: 'fixed',
  },
  footerButton: {
    padding: '16px 16px 16px 16px',
    backgroundColor: color.buttonBackgroundSecondary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.light,
    fontWeight: 600,
    borderRadius: 6,
  },
});

export interface ManualEntryStyles extends WithStyles<typeof styles> {}

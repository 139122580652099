import bankLogo from '../../../assets/images/payment/bank-account.png';
import cardLogo from '../../../assets/credit-card-24-px.svg';
import visaIcon from '../../../assets/group-82.svg';
import masterCardIcon from '../../../assets/mc-symbol.svg';
import { MatchParams, serviceTypeList } from './AddPaymentConstants';
import { GetRentServiceResponse } from '../../../services/rentService/getRentService/GetRentServiceResponse.data';

export interface MaskDetails {
  paymentDetails: string;
  logo: any;
}

export const maskBankAccountDetails = (accountNumber: string, bsbNumber: string): MaskDetails => {
  return {
    paymentDetails: `Acc ${accountNumber.toString().slice(0, 4)} ** BSB ${bsbNumber.slice(
      0,
      3,
    )} **`,
    logo: bankLogo,
  };
};

export const maskCardDetails = (cardNumber: string, cardType: string): MaskDetails => {
  return {
    paymentDetails: `Card **** **** **** ${cardNumber.slice(-4)}`,
    logo: renderCardIcon(cardType),
  };
};

const renderCardIcon = (cardType: string) => {
  switch (cardType) {
    case 'visa':
      return visaIcon;
    case 'mastercard':
      return masterCardIcon;
    default:
      return cardLogo;
  }
};

export const createEditPaymentObject = (serviceAccountId: number, paymentRefId: string) => {
  return {
    paymentRefId,
    serviceAccountId,
  };
};

export const getAgencyName = (
  matchParams: MatchParams,
  rentService: GetRentServiceResponse | undefined,
): string => {
  const { serviceType, agencyName } = matchParams;

  if (serviceTypeList[serviceType] !== 'rent') {
    return 'sorted services';
  } else {
    return rentService && rentService.agency
      ? rentService.agency.tradingName
      : agencyName.replace(/_/g, ' ');
  }
};

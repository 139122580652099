import { color } from '../../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const Styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 16px 0 16px',
    height: '100vh',
  },
  header: {
    marginTop: 65,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '4vh',
    font: 'GT Walsheim',
  },
  imageBox: {
    display: 'flex',
    justifyContent: 'center',
    width: 64,
    height: 64,
    borderRadius: '50%',
    backgroundColor: color.light,
    position: 'relative',
    marginBottom: -32,
    marginLeft: 19,
  },
  imageStyle: {
    width: 64,
    height: 64,
    borderRadius: '50%',
  },
  card: {
    border: '1px solid #eeeeee',
    backgroundColor: '#ffffff',
    borderRadius: 8,
  },
  cardHeader: {
    backgroundColor: color.secondary,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  propertyImage: {
    height: 10,
    marginRight: 15,
    padding: 5,
  },
  cardBody: {
    padding: '20px 0px 0px 0px',
  },
  primaryTextStyle: {
    color: color.dark,
    opacity: 0.38,
  },
  secondaryTextStyle: {
    color: color.dark,
    opacity: 0.87,
  },
  buttonBox: {
    bottom: 0,
    left: 0,
    right: 0,
    padding: 16,
    position: 'fixed',
  },
  button: {
    height: 36,
    background: color.primary,
  },
  buttonText: {
    color: color.light,
  },
  infoText: {
    fontSize: 12,
    padding: '10px 5px',
    marginBottom: 64,
  },
  titleStyle: {
    color: color.titleColor,
  },
});

export interface SummaryStyles extends WithStyles<typeof Styles> {}

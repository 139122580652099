import { color } from '../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  container: {},
  imageContainer: {
    width: '100%',
    height: 300,
  },
  image: {
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
    height: '100%',
  },
  dotsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: 5000,
    backgroundColor: color.borderColor,
    margin: 4,
  },
  dotActive: {
    width: 8,
    height: 8,
    borderRadius: 5000,
    backgroundColor: color.black87,
    transform: 'scale(1.2)',
    margin: 4,
  },
  content: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    color: color.black87,
    fontFamily: 'GT Walsheim',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 24,
    marginBottom: 16,
  },
  text: {
    color: color.black87,
    fontFamily: 'GT Walsheim Light',
    textAlign: 'center',
    fontSize: 14,
    maxWidth: 400,
  },
  buttonsContainer: {
    marginTop: 40,
    display: 'flex',
    maxWidth: 350,
    width: '100%',
  },
  secondaryButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 40,
    color: color.black87,
    textAlign: 'center',
    fontFamily: 'GT Walsheim',
    fontSize: 15,
    border: `${color.borderColor} 2px solid`,
    borderRadius: 6,
    marginRight: 15,
    cursor: 'pointer',
  },
  primaryButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 40,
    color: color.light,
    textAlign: 'center',
    fontFamily: 'GT Walsheim',
    fontSize: 15,
    border: `${color.buttonBackgroundSecondary} 2px solid`,
    backgroundColor: color.buttonBackgroundSecondary,
    borderRadius: 6,
    cursor: 'pointer',
  },
});

export interface HowItWorksStyles extends WithStyles<typeof styles> {}

import * as React from 'react';
import AppUpdate from '../../../component/services/appUpdate/AppUpdate';
import { sendMessageToApp, ValidMessages } from '../../../helper/MessageHelper';

class AppUpdateRequired extends React.Component<{}, {}> {
  public render() {
    return <AppUpdate handleSubmit={this.handleSubmit} />;
  }
  private handleSubmit = () => {
    sendMessageToApp(ValidMessages.Dashboard);
  };
}

export default AppUpdateRequired;

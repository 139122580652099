import { color } from '../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  root: {
    backgroundColor: color.secondary,
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 48px)',
  },
  contentContainer: {
    marginTop: 12,
    backgroundColor: color.light,
    padding: 12,
    borderRadius: 8,
  },
  contentTitle: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: '20px',
    fontWeight: 500,
    marginBottom: 12,
  },
  propertyContainer: {
    display: 'flex',
    backgroundColor: color.light,
    justifyContent: 'space-between',
    padding: '12px',
    alignItems: 'center',
    fontWeight: 600,
    color: color.black87,
    borderRadius: 8,
    border: `${color.borderColor} 2px solid`,
    marginBottom: '12px',
  },
  messageContainer: {},
  messageTitle: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: '20px',
    fontWeight: 500,
  },
  messageSubtitle: {
    fontFamily: 'GT Walsheim',
    marginTop: 4,
    color: color.black38,
    fontSize: '14px',
    fontWeight: 500,
  },
  agencyLogo: {
    width: '55px',
    height: '55px',
    borderRadius: 5000,
    backgroundColor: color.light,
    marginLeft: 'auto',
    marginRight: 12,
  },
  houseContainer: {
    width: '55px',
    height: '55px',
  },
  house: {
    width: '100%',
    height: '100%',
    objectPosition: 'center',
    objectFit: 'contain',
  },
  rentLogo: {
    width: '55px',
    height: '55px',
    borderRadius: 5000,
    backgroundColor: color.light,
    marginRight: 12,
  },
  rentTextContainer: {
    marginLeft: 'auto',
  },
  rentTitle: {
    fontFamily: 'GT Walsheim',
    color: color.black87,
    fontSize: '20px',
    fontWeight: 500,
    textAlign: 'right',
  },
  rentSubtitle: {
    fontFamily: 'GT Walsheim',
    marginTop: 4,
    color: color.black38,
    fontSize: '14px',
    fontWeight: 500,
    textAlign: 'right',
  },
  rentContainer: {
    display: 'flex',
    backgroundColor: color.light,
    justifyContent: 'space-between',
    padding: '12px',
    alignItems: 'center',
    fontWeight: 600,
    color: color.black87,
    borderRadius: 8,
    border: `${color.borderColor} 2px solid`,
    marginBottom: '12px',
  },
  footerButtonsContainer: {
    bottom: 12,
    left: 12,
    right: 12,
    position: 'fixed',
  },
  footerButton: {
    padding: '16px 16px 16px 16px',
    backgroundColor: color.buttonBackgroundSecondary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.light,
    fontWeight: 600,
    borderRadius: 6,
  },
  footerButtonSecondary: {
    marginTop: 12,
    padding: '16px 16px 16px 16px',
    backgroundColor: color.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.black87,
    fontWeight: 600,
    borderRadius: 6,
    border: `${color.borderColor} 2px solid`,
  },
});

export interface ConfirmationStyles extends WithStyles<typeof styles> {}

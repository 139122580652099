import { color } from '../../../../theme/Theme';
import { createStyles, WithStyles } from '@material-ui/core';

export const styles = createStyles({
  selectOptionsContainer: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  planContainer: {
    padding: 10,
  },
  planCard: {
    margin: 10,
    padding: '10px 15px',
    borderRadius: 10,
    borderRight: '4px solid lightblue',
  },
  expandedCost: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputStyle: {
    marginRight: 8,
  },
  slideContainer: {
    padding: '0 10px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 20,
  },
  inputs: {
    width: '100%',
    marginTop: 10,
  },
  cardStyle: {
    marginTop: 20,
  },
  stepper: {
    background: 'none',
    float: 'right',
    marginRight: 25,
    marginTop: 0,
  },
  imageStyle: { height: 24, width: 24, marginTop: 0 },
  arrowImage: { height: 10, width: 10 },
  arrow: { display: 'flex', justifyContent: 'center' },
  dotActive: {
    background: color.primary,
  },
  dot: {
    border: '1px solid white',
  },
  detailCard: {
    minHeight: 50,
    padding: 15,
    margin: 10,
    marginTop: 10,
    marginBottom: 18,
    borderRadius: 10,
  },
  planDetailText: {
    marginTop: 30,
    marginLeft: 10,
  },
  planCardHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 5px 0px 5px',
  },
  serviceLogo: {
    width: 30,
    height: 30,
    marginRight: 15,
  },
  serviceLogo1: {
    width: 25,
    height: 25,
    marginRight: 15,
  },
  headerText1: {
    fontSize: 17,
    lineHeight: 1,
    marginTop: 15,
    marginBottom: 5,
  },
  gtwLight: {
    fontFamily: 'GT Walsheim Light',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  headerText2: {
    fontSize: 15,
    marginTop: 5,
    marginBottom: 10,
  },
  infoText: {
    fontSize: 12,
    marginTop: 10,
    color: color.black38,
  },
  footer: {
    marginTop: 10,
  },
  planCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  blueText: {
    color: color.lightBlue,
    textDecoration: 'underline',
  },
  keyValueContainer: {
    display: 'flex',
    margin: '10px 0',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  keyValueContainerColumn: {
    display: 'flex',
    margin: '10px 0',
    flexDirection: 'column',
  },
  keyText: {
    fontSize: 18,
    marginRight: 10,
  },
  linkHeader: {
    paddingTop: 10,
    paddingLeft: 0,
  },
  keyTextColumn: {
    fontSize: 18,
    marginRight: 10,
    marginBottom: 6,
  },
  value: {
    fontSize: 16,
    marginRight: 10,
  },
  valueText: {
    fontSize: 16,
  },
  cardType: {
    margin: '20px 0px',
  },
  seperator: {
    height: 1,
    margin: 4,
    marginBottom: 12,
    background: '#eee',
  },
  section: {
    marginBottom: 8,
  },
  switchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  switchSection: {
    display: 'flex',
    alignItems: 'center',
  },
  selectPlanCard: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 65,
    padding: 20,
    borderRadius: 15,
  },
  providerLogo: {
    height: 40,
    width: 40,
  },
  planRoot: {
    background: '#fafafa',
  },
  selectPlanText: {
    fontSize: 17,
  },
  headerCard: {
    width: '88%',
    minHeight: 50,
    padding: 15,
    margin: 10,
    marginTop: 30,
    marginBottom: 18,
    borderRadius: 10,
  },
  toggleContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    height: 40,
    borderRadius: 2,
    margin: '10px 0px',
    boxShadow: 'none',
    borderRight: `1px solid ${color.lightBlue}`,
  },
  cardToggleContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    height: 40,
    borderRadius: 2,
    margin: '10px 0px',
    boxShadow: 'none',
    borderRight: `1px solid ${color.lightBlue}`,
  },
  collapsedCost: {
    fontFamily: 'GT Walsheim Light',
    fontSize: 20,
    color: color.lightBlue,
  },
  toggleButton: {
    flex: 1,
    color: color.lightBlue,
    borderTop: `1px solid ${color.lightBlue}`,
    borderBottom: `1px solid ${color.lightBlue}`,
    borderLeft: `1px solid ${color.lightBlue}`,
    fontSize: 15,
    height: '100%',
  },
  toggleButtonActive: {
    flex: 1,
    background: `${color.lightBlue} !important`,
    color: `#fff !important`,
    height: '100%',
  },
  fixedCard: {
    padding: '15px 0px',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  button: {
    height: 45,
    width: '100%',
    background: color.buttonBackground,
    '&:hover': {
      backgroundColor: color.buttonBackground,
    },
  },
  buttonText: {
    color: color.light,
  },
  disabledButtonText: {
    color: color.secondary300,
  },
  expand: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonfolded: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonFolded: {
    height: 45,
    width: '35%',
    background: color.buttonBackground,
    '&:hover': {
      backgroundColor: color.buttonBackground,
    },
  },
  bottomCard: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 20px',
    alignItems: 'center',
  },
  costKey: {
    fontSize: 14,
    color: 'grey',
  },
  costAmount: {
    fontSize: 28,
    color: color.lightBlue,
    marginBottom: 10,
  },
  bottomText: {
    padding: '0px 20px',
  },
  selectFrequency: {
    height: 30,
    width: 130,
  },
  cardContainerStyle: {
    borderRadius: 6,
    border: `${color.borderColor} 2px solid`,
    marginTop: 15,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    backgroundColor: color.light,
  },
  selectedCardContainerStyle: {
    border: `${color.lightBlue} 2px solid`,
    backgroundColor: color.fadedBlue,
    borderRadius: 6,
    marginTop: 15,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
  },
});

export interface SelectOptionsStyles extends WithStyles<typeof styles> {}

import * as React from 'react';
import {
  FormControl,
  InputLabel,
  Input,
  withStyles,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { styles, InputStyles } from './InputStyles';

interface PropsFromParent {
  label: string;
  value: string;
  haveIcon?: boolean;
  icon?: any;
}

type OwnProps = PropsFromParent & InputStyles;

function input(props: OwnProps) {
  const { classes, label, value, haveIcon, icon } = props;
  return (
    <FormControl className={classes.root}>
      <InputLabel className={classes.labelStyle}>{label}</InputLabel>
      <Input
        type="text"
        value={value}
        disabled
        disableUnderline={true}
        className={classes.inputStyle}
        classes={{
          disabled: classes.textStyle,
          formControl: classes.textFormStyle,
        }}
      />
      <ListItemSecondaryAction>
        {haveIcon && <img src={icon} className={classes.logoStyle} />}
      </ListItemSecondaryAction>
    </FormControl>
  );
}

const inputComponent = withStyles(styles)(input);
export default inputComponent;

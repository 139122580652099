import { combineReducers } from 'redux';
import { paymentReducer } from './reducers/PaymentReducer';
import { PaymentState } from './state/PaymentState';
import LeaseReducer from './reducers/LeaseReducer';
import { LeaseState } from './state/LeaseState';
import { SpinnerState } from './state/SpinnerState';
import { spinnerReducer } from './reducers/SpinnerReducer';
import { planReducer } from './reducers/PlanReducer';
import { PlanState } from './state/PlanState';
import { BroadbandPlanReducer } from './reducers/BroadbandPlanReducer';
import { BroadbandPlanState } from './state/BroadbandPlanState';
import { ElectricityServiceState } from './state/ElectricityServiceState';
import { electricityServiceReducer } from './reducers/ElectricityServiceReducer';
import { ConnectionDateReducer } from './reducers/ConnectionDateReducer';
import { ConnectionDateState } from './state/ConnectionDateState';
import { broadbandServiceReducer } from './reducers/BroadbandServiceReducer';
import { BroadbandServiceState } from './state/BroadbandServiceState';
import { rentServiceReducer } from './reducers/RentServiceReducer';
import { RentServiceState } from './state/RentServiceState';
import { walletReducer } from './reducers/WalletReducer';
import { WalletState } from './state/WalletState';
import { editPaymentReducer } from './reducers/EditPaymentReducer';
import { EditPaymentState } from './state/EditPaymentState';
import { headerReducer } from './reducers/HeaderReducer';
import { LeaveListState } from './state/LeaveListState';
import { LeaveListReducer } from './reducers/LeaveListReducer';
import { tokenReducer } from './reducers/TokenReducer';
import { planSummaryReducer } from './reducers/PlanSummaryReducer';
import { PlanSummaryState } from './state/PlanSummaryState';
import { propertyOnboardingReducer } from './reducers/PropertyOnboardingReducer';
import { PropertyOnboardingState } from './state/PropertyOnboardingState';
import { CommonSummaryState } from './state/CommonSummaryState';
import { commonSummaryReducer } from './reducers/CommonSummaryReducer';
import { TokenState } from './state/TokenState';
import { HeaderState } from './state/HeaderState';
import { CheckoutState } from './state/CheckoutState';
import { checkoutReducer } from './reducers/CheckoutReducer';
import { LoadingReducer } from './reducers/LoadingReducer';
import { ErrorReducer } from './reducers/ErrorReducer';
import { ErrorState } from './state/ErrorState';
import { LoadingState } from './state/LoadingState';
import { FileUploadState } from './state/FileUploadState';
import { fileUploadReducer } from './reducers/FileUploadReducer';

export interface ApplicationState {
  payment: PaymentState;
  lease: LeaseState;
  spinner: SpinnerState;
  plan: PlanState;
  broadbandPlan: BroadbandPlanState;
  electricityService: ElectricityServiceState;
  connectionDate: ConnectionDateState;
  broadbandService: BroadbandServiceState;
  rentService: RentServiceState;
  wallet: WalletState;
  editPayment: EditPaymentState;
  headerParams: HeaderState;
  leaveList: LeaveListState;
  token: TokenState;
  planSummary: PlanSummaryState;
  commonSummary: CommonSummaryState;
  propertyOnboarding: PropertyOnboardingState;
  checkout: CheckoutState;
  loadingState: LoadingState;
  errorState: ErrorState;
  fileUploadState: FileUploadState;
}

const rootReducer = combineReducers<ApplicationState>({
  leaveList: LeaveListReducer,
  payment: paymentReducer,
  lease: LeaseReducer,
  spinner: spinnerReducer,
  plan: planReducer,
  broadbandPlan: BroadbandPlanReducer,
  electricityService: electricityServiceReducer,
  broadbandService: broadbandServiceReducer,
  connectionDate: ConnectionDateReducer,
  rentService: rentServiceReducer,
  wallet: walletReducer,
  editPayment: editPaymentReducer,
  headerParams: headerReducer,
  token: tokenReducer,
  planSummary: planSummaryReducer,
  commonSummary: commonSummaryReducer,
  propertyOnboarding: propertyOnboardingReducer,
  checkout: checkoutReducer,
  loadingState: LoadingReducer,
  errorState: ErrorReducer,
  fileUploadState: fileUploadReducer,
});

export default rootReducer;

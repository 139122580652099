export enum LogLevel {
  None,
  Errors,
  Debug,
  Trace,
}

//setting baseUrl
//if env vars have been set by envconsul it will use that otherwise it will use dev url
const BaseUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://###CONTAINER_SORTED_SERVICE_URL###/sorted'
    : 'https://sorted-app-bff.uat.sortedservices.com/sorted';

export interface TestSettings {
  readonly testing: boolean;
  readonly logLevel: LogLevel;
}

const logRocketGroup = BaseUrl.includes('prodau')
  ? '###LOG_ROCKET_PROD###'
  : '###LOG_ROCKET_UAT###';

const SortedWebUrl = BaseUrl.includes('prodau')
  ? 'https://sorted-web.prodau.sortedservices.com/'
  : 'https://sortedweb.uat.sortedservices.com/';

export class AppSettings {
  public static readonly server = {
    baseUrl: BaseUrl, // DO NOT end with '/'!
    mockServices: false, // test use mocks by using jest.mock(), this is for manual testing
    sortedWebUrl: SortedWebUrl,
  };

  public static readonly logging = {
    logLevel: LogLevel.Trace,
  };

  public static initTesting() {
    AppSettings.logging.logLevel = AppSettings.testSettings.logLevel;

    this.testSettings = {
      ...this.testSettings,
      testing: true,
    };
  }

  public static get currentTestSetting(): TestSettings {
    return AppSettings.testSettings;
  }

  private static testSettings: TestSettings = {
    testing: false,
    logLevel: LogLevel.Debug,
  };

  public static readonly logRocket = {
    group: logRocketGroup,
  };
}

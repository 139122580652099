export const primaryAndSecondaryAddress = (address: string) => {
  const fullAddressArray = address.split(' ');
  let firstHalf;
  const text = ['', ''];
  const remainder = fullAddressArray.length % 2;
  switch (remainder) {
    case 0: {
      firstHalf = fullAddressArray.length / 2;
      break;
    }
    case 1: {
      firstHalf = (fullAddressArray.length + 1) / 2;
      break;
    }
    default: {
      firstHalf = 1;
    }
  }
  for (let i = 0; i < firstHalf; i++) {
    text[0] = `${text[0]}${fullAddressArray[i]} `;
  }
  for (let i = firstHalf; i < fullAddressArray.length; i++) {
    text[1] = `${text[1]}${fullAddressArray[i]} `;
  }
  return text;
};
